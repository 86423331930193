'use strict';
import moment from 'moment';
import PeerHelpInboxTemplate from './peer-help-inbox.html';

export class PeerHelpInboxController {
  /**
   * @ngInject
   */
  constructor(mdPanelRef, deferred) {
    this._mdPanelRef = mdPanelRef;
    this._deferred = deferred;
  }

  get hasHelpers() {
    return Object.keys(this.helpers).length;
  }

  get hasHelpHistory() {
    return this.helpHistory.length > 0;
  }

  peer(userId) {
    return this.peers.get(userId);
  }

  questionNumber(questionId) {
    return this.assignment.questionNumberForId(questionId);
  }

  /**
   * @param request {HelpRequest}
   * @returns {string}
   */
  formatHelpHistoryItem(request) {
    let helper = this.peers.get(request.helperId);
    let helpee = this.peers.get(request.helpeeId);
    let questionNumber = this.assignment.questionNumberForId(
      request.questionId,
    );
    return `${helper.name} helped ${helpee.name} on question #${questionNumber}`;
  }

  /**
   * @param time {moment}
   * @returns {string}
   */
  formatTime(time) {
    return moment().to(time);
  }

  goToFeedbackQuestion(userId, questionId, event) {
    this._deferred.resolve({
      userId,
      questionId,
      event,
    });
    this._mdPanelRef.hide();
  }

  /**
   * @param $mdPanel
   * @param $q
   * @param $event
   * @param user {User}
   * @param helpHistory {HelpRequest[]}
   * @param helpers {Object.<string, Helpers>}
   * @param peers {Map.<string, User>}
   * @param assignment {Assignment}
   * @return {Promise.<{userId: string, questionId: string, event: object}>}
   */
  static show(
    $mdPanel,
    $q,
    $event,
    user,
    helpHistory,
    helpers,
    peers,
    assignment,
  ) {
    const position = $mdPanel
      .newPanelPosition()
      .relativeTo($event.currentTarget)
      .addPanelPosition(
        $mdPanel.xPosition.ALIGN_START,
        $mdPanel.yPosition.ALIGN_TOPS,
      );

    const deferred = $q.defer();

    $mdPanel.open({
      position,
      animation: $mdPanel
        .newPanelAnimation()
        .openFrom($event.currentTarget)
        .duration(100)
        .withAnimation($mdPanel.animation.SCALE),
      template: PeerHelpInboxTemplate,
      controller: PeerHelpInboxController,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      panelClass: 'peer-help-inbox',
      locals: {
        deferred,
        user,
        helpHistory,
        helpers,
        peers,
        assignment,
      },
      onRemoving: () => {
        deferred.reject();
      },
    });

    return deferred.promise;
  }
}
