'use strict';

import Size from '../../model/ui/size';
import SvgCanvas from '../../model/ui/svg-canvas';
import { PDFJS, PDFWorker } from 'pdfjs-dist';
import EXIF from 'exif-js';

class ImageJob {
  constructor(url) {
    this.url = url;
    this.started = false;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  /**
   * @returns {Promise}
   */
  run() {
    if (!this.started) {
      this.started = true;

      let image = new Image();
      let imageLoaded = new Promise((resolve) => {
        image.onload = () => {
          resolve(image);
        };
      });
      image.src = this.url;

      return imageLoaded
        .then(() => this._extractExif(image))
        .then((orientation) => {
          this.resolve({
            image: image,
            orientation: orientation,
          });
        });
    }

    return this.promise;
  }

  static get DefaultOrientation() {
    return 1;
  }

  /**
   * @param image {Image}
   * @returns {Promise.<number>}
   * @private
   */
  _extractExif(image) {
    return new Promise((resolve) => {
      EXIF.getData(image, function () {
        let orientation =
          EXIF.getTag(this, 'Orientation') | ImageJob.DefaultOrientation;
        // Images with orientation exif info need orientation adjustments
        if (orientation === 9) {
          orientation = 8;
        } else if (orientation === 7) {
          orientation = 6;
        }

        resolve(orientation);
      });
    });
  }
}

/**
 * Service for editing and importing images
 */
export default class ImageEditService {
  constructor($document, $q, $log) {
    'ngInject';

    this.$document = $document;
    this.$q = $q;
    this.$log = $log;
    this._pdfjs = PDFJS;
    this._pdfjs.workerSrc = PDFWorker;

    this._canvasFitScale = 0.9;
    this._canvasFitSize = new Size(
      SvgCanvas.INITIAL_WIDTH * this._canvasFitScale,
      SvgCanvas.INITIAL_HEIGHT * this._canvasFitScale,
    );

    /** @type {Map.<string, Promise.<{image: Image, orientation: int}>>} */
    this._imageCache = new Map();
    /** @type {string[]} */
    this._imageAge = [];
    /** @type {ImageJob[]} */
    this._imageQueue = [];
  }

  /**
   * @returns {Size}
   */
  get canvasFitSize() {
    return this._canvasFitSize;
  }

  /**
   * @param blob {Blob}
   * @param urlMethod {function}
   * @returns {Promise}
   * @private
   */
  _fromBlob(blob, urlMethod) {
    return this._blobToDataUrl(blob).then((dataURL) => {
      return urlMethod(dataURL);
    });
  }

  _blobToDataUrl(blob) {
    const deferred = this.$q.defer();
    var reader = new FileReader();
    reader.onload = (event) => {
      deferred.resolve(event.target.result);
    };
    reader.onerror = (err) => {
      deferred.reject(err);
    };
    reader.readAsDataURL(blob);
    return deferred.promise;
  }

  /**
   * Imports a PDF to an array of images - one image per page
   * @param url {string}
   * @returns {Promise.<PDFDocumentProxy>}
   */
  pdfFromUrl(url) {
    return this.$q.resolve(this._pdfjs.getDocument(url));
  }

  /**
   * @param blob {Blob}
   * @returns {Promise.<PDFDocumentProxy>}
   */
  pdfFromFile(blob) {
    return this._fromBlob(blob, (url) => this.pdfFromUrl(url));
  }

  /**
   * @param pdf {PDFDocumentProxy}
   */
  releasePdf(pdf) {
    pdf.clean();
    pdf.destroy();
  }

  /**
   * Returns a promise which resolves when the image is loaded, or resolves immediately if the image is already loaded
   * @param image {Image}
   * @returns {Promise.<Image>}
   */
  imageLoaded(image) {
    const deferred = this.$q.defer();

    if (!(image.src && image.complete)) {
      image.onload = () => {
        deferred.resolve(image);
      };
      image.onerror = () => {
        deferred.reject();
      };
    } else {
      deferred.resolve(image);
    }
    return deferred.promise;
  }

  /**
   * @param url {string}
   * @returns {Promise<Image>}
   */
  imageFromUrl(url) {
    const image = new Image();
    const result = this.imageLoaded(image);
    image.src = url;

    return result;
  }

  /**
   *
   * @param blob {Blob}
   * @returns {Promise.<Image>}
   */
  imageFromFile(blob) {
    return this._fromBlob(blob, (url) => this.imageFromUrl(url));
  }

  /**
   *
   * @param canvas {HTMLCanvasElement}
   * @returns {Promise.<Image>}
   */
  imageFromCanvas(canvas) {
    return this.imageFromUrl(this.dataURLFromCanvas(canvas));
  }

  /**
   * @param canvas {HTMLCanvasElement}
   * @returns {string}
   */
  dataURLFromCanvas(canvas) {
    return canvas.toDataURL('image/png');
  }

  /**
   * @param pdf {PDFDocumentProxy}
   * @param pageNum {int}
   * @returns {Promise.<String>}
   */
  dataUrlFromPdfPage(pdf, pageNum) {
    return pdf
      .getPage(pageNum)
      .then((page) => {
        const viewport = page.getViewport(1);

        const fit = this.canvasFitSize.fit(
          new Size(viewport.width, viewport.height),
        );
        return this.newCanvas(fit.width, fit.height);
      })
      .then((canvas) => {
        return this.drawPdfPage(pdf, pageNum, canvas);
      })
      .then((canvas) => {
        return this.dataURLFromCanvas(canvas);
      });
  }

  imageFromPdfPage(pdf, pageNum) {
    return this.dataUrlFromPdfPage(pdf, pageNum).then((url) =>
      this.imageFromUrl(url),
    );
  }

  /**
   * @param pdf {PDFDocumentProxy}
   * @param pageNum {int}
   * @param canvas {HTMLCanvasElement}
   * @returns {Promise.<HTMLCanvasElement>}
   */
  drawPdfPage(pdf, pageNum, canvas) {
    return pdf
      .getPage(pageNum)
      .then((page) => {
        const viewport = page.getViewport(1);
        const scale = new Size(canvas.width, canvas.height).fitScale(
          new Size(viewport.width, viewport.height),
        );

        return page.render({
          canvasContext: canvas.getContext('2d'),
          viewport: page.getViewport(scale),
        });
      })
      .then(() => canvas);
  }

  /**
   * @param [width] {number}
   * @param [height] {number}
   * @returns {HTMLCanvasElement}
   */
  newCanvas(width, height) {
    const canvas = this.$document[0].createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    return canvas;
  }

  /**
   * @param image {Image}
   * @param canvas {HTMLCanvasElement}
   * @param offset {Point} Top left of image on canvas
   * @param [scale] {number}
   */
  drawImage(image, canvas, offset, scale) {
    const ctx = canvas.getContext('2d');
    const size = new Size(image.width, image.height).times(scale || 1);
    ctx.drawImage(image, offset.x, offset.y, size.width, size.height);
  }

  /**
   * Returns a cropped version of the supplied image using the supplied size and offset
   *
   * @param image {Image}
   * @param offset {Point}
   * @param size {Size}
   * @param [scale] {number}
   * @returns {String}
   */
  crop(image, offset, size, scale) {
    const canvas = this.newCanvas(size.width, size.height);

    this.drawImage(image, canvas, offset.times(-1), scale);

    return this.dataURLFromCanvas(canvas);
  }

  /**
   * @param dataURI {string}
   * @returns {Blob}
   */
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  /**
   * @param url {string}
   * @return {Promise}
   */
  objectUrlToBlob(url) {
    let deferred = this.$q.defer();

    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status === 200) {
        let originalBlob = this.response;
        // originalBlob is now the blob that the object URL pointed to.
        deferred.resolve(originalBlob);
      }
    };
    xhr.send();

    return deferred.promise;
  }

  /**
   * @param url {string}
   * @returns {Promise.<{image: Image, orientation: int}>}
   */
  loadImage(url) {
    if (!this._imageCache.has(url)) {
      // Remove LRU
      while (
        this._imageAge.length >= 100 &&
        !this._imageQueue.find((x) => x.url === this._imageAge[0])
      ) {
        const removing = this._imageAge.shift();
        this._imageCache.delete(removing);
      }

      this._imageAge.push(url);
      const job = new ImageJob(url);
      this._imageQueue.push(job);
      this._imageCache.set(url, job.promise);
      this._touchImageQueue();
    }

    return this._imageCache.get(url);
  }

  _touchImageQueue() {
    if (this._imageQueue.length > 0 && !this._imageQueue[0].started) {
      const callback = () => {
        this._imageQueue.shift();
        this._touchImageQueue();
      };

      this._imageQueue[0]
        .run()
        .then(() => {
          callback();
        })
        .catch((err) => {
          callback();
          this.$log.warn('#_touchImageQueue catch', err);
        });
    }
  }
}
