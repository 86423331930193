'use strict';

export default class AudioRecorder {
  constructor($q) {
    this.$q = $q;

    this._mediaRecorder = null;
    this._mediaConstraints = {
      audio: true,
      video: false,
    };
  }

  init() {
    return this._getUserMedia().then((stream) => {
      this._promise = this._initRecorder(stream);
    });
  }

  /**
   * Uses optimal browser api based on availability
   * @returns {Promise.<Blob>}
   * @private
   */
  _getUserMedia() {
    if (navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(this._mediaConstraints);
    }

    return this.$q((resolve, reject) => {
      this.setGetUserMedia();

      navigator.getUserMedia(
        this._mediaConstraints,
        (stream) => resolve(stream),
        (err) => reject(err),
      );
    });
  }

  /**
   * @param stream {Blob}
   * @returns {Promise.<Blob>}
   * @private
   */
  _initRecorder(stream) {
    return this.$q((resolve) => {
      this._mediaRecorder = new MediaRecorder(stream);

      var chunks = [];
      this._mediaRecorder.ondataavailable = function (e) {
        chunks.push(e.data);
      };

      this._mediaRecorder.onstop = function () {
        var audioBlob = new Blob(chunks, { type: 'audio/webm' });
        resolve(audioBlob);
      };
    });
  }

  /**
   * @returns {Promise}
   */
  start() {
    if (this._mediaRecorder) {
      this._mediaRecorder.start();
      return this.$q.resolve();
    }
    return this.$q.reject();
  }

  /**
   * @returns {Promise}
   */
  save() {
    if (this._mediaRecorder) {
      this._mediaRecorder.stop();
      return this._promise;
    }
    return this.$q.reject();
  }

  setGetUserMedia() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
  }

  static get isSupported() {
    return (
      !!navigator.getUserMedia ||
      !!navigator.webkitGetUserMedia ||
      !!navigator.mozGetUserMedia ||
      !!navigator.msGetUserMedia
    );
  }

  destroy() {
    this._removeRecordingIcon();
  }

  _removeRecordingIcon() {
    if (
      this._mediaRecorder &&
      angular.isFunction(this._mediaRecorder.stream.getTracks)
    ) {
      this._mediaRecorder.stream.getTracks()[0].stop();
    }
  }
}
