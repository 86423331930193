'use strict';

import Codec from './codec';
import OrderTracking from '../domain/order-tracking';

/**
 * Encodes/Decodes an order
 */
export default class OrderTrackingCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {OrderTracking}
   * @return {object}
   */
  encode(value) {
    return {
      id: value.id,
      user_id: value.userId,
      order_id: value.orderId,
      created: value.created,
      tracking_id: value.trackingId,
      source: value.source,
    };
  }

  /**
   * @param value {Object}
   * @return {OrderTracking}
   */
  decode(value) {
    if (value) {
      return new OrderTracking(
        value.id,
        value.user_id,
        value.order_id,
        value.created,
        value.tracking_id,
        value.source,
      );
    } else {
      return null;
    }
  }
}
