'use strict';

import Codec from './codec';
import CoTeacher from '../domain/co-teacher';

/**
 * Encodes/decodes co-teacher information
 */
export default class CoTeacherCodec extends Codec {
  /**
   * @param value {CoTeacher}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        roster_id: value.rosterId,
        coteacher_id: value.coteacherId,
        coteacher_email: value.coteacherEmail,
        last_accessed: value.lastAccessed.toISOString(),
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {CoTeacher}
   */
  decode(value) {
    if (value) {
      return new CoTeacher(
        value.id,
        value.roster_id,
        value.coteacher_email,
        value.coteacher_id,
        value.last_accessed,
      );
    }

    return null;
  }
}
