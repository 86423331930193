'use strict';

/**
 * Command class captures a change to an element, implements undo/redo functionality per element type
 */
export default class Change {
  /**
   * @param elementId {string}
   * @param factory {function(string, object)} A function that creates a new element from an id and snapshot
   * @param [previousState] {object} Falsy value indicates element was created
   * @param [deleted] {boolean} True indicates element was deleted
   */
  constructor(elementId, factory, previousState, deleted) {
    this._elementId = elementId;
    this._factory = factory;
    this._previousState = previousState;
    this._deleted = deleted;
  }

  /**
   * @returns {string}
   */
  get elementId() {
    return this._elementId;
  }

  /**
   * @returns {boolean}
   */
  get created() {
    return !this._previousState;
  }

  /**
   * @returns {boolean}
   */
  get deleted() {
    return this._deleted;
  }

  /**
   * The state of the element before this change
   * @returns {object}
   */
  get previousState() {
    return this._previousState;
  }

  /**
   * @returns {Function}
   */
  get factory() {
    return this._factory;
  }

  /**
   * Undoes the change on the target element, returns a Change object reflecting the change made
   * @param question {AssignmentQuestion}
   * @param [undoCreate] {Function}
   * @param [undoRemove] {Function}
   * @param [undoChange] {Function}
   * @return {Change}
   */
  undo(question, undoCreate, undoRemove, undoChange) {
    /** @type {Element} */
    let element = question.elements.valueForId(this.elementId);
    let previous = element ? element.snapshot() : undefined;

    if (this.created) {
      undoCreate(question, element);

      return new Change(this.elementId, this._factory, previous, true);
    } else if (this.deleted) {
      let newElement = this._factory(this.elementId, this.previousState);
      undoRemove(question, newElement);

      return new Change(this.elementId, this._factory);
    } else {
      element.merge(this.previousState, true);
      undoChange(question, element);

      return new Change(this.elementId, this.factory, previous);
    }
  }
}
