import ProTrialConversionOverlayTemplate from './pro-trial-conversion-overlay.html';
import ProTrialConstants from '../../model/util/pro-trial-util';

export default class ProTrialConversionOverlayController {
  /**
   * @ngInject
   */
  constructor(
    $mdDialog,
    $scope,
    AnalyticsService,
    BreadcrumbService,
    handleAdminEmail,
    trialCountdown,
    contract,
    orderId,
  ) {
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    this._handleAdminEmail = handleAdminEmail;
    this._trialCountdown = trialCountdown;
    this._contract = contract;
    this._orderId = orderId;
  }

  static show($mdDialog, handleAdminEmail, trialCountdown, contract, orderId) {
    return $mdDialog.show({
      controller: ProTrialConversionOverlayController,
      template: ProTrialConversionOverlayTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        handleAdminEmail,
        trialCountdown,
        contract,
        orderId,
      },
    });
  }

  get contract() {
    return this._contract;
  }

  getTrialCountdown() {
    return this._trialCountdown();
  }

  close() {
    this._analyticsService.proTrialConversionOverlayDismissed();
    this.$mdDialog.hide();
  }

  openEmailDialogToAdmin() {
    this._handleAdminEmail(ProTrialConstants.PRO_TRIAL_CONVERSION_OVERLAY);
  }

  goToOrder() {
    const orderId = this._orderId;
    this._breadcrumbService.go('root.order-detail', {
      orderId,
    });
    this.$mdDialog.hide();
  }
}
