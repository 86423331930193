'use strict';

import Codec from './codec';
import OrderPlan from '../domain/order-plan';

/**
 * Encodes/Decodes an order plan
 */
export default class OrderPlanCodec extends Codec {
  constructor() {
    super();
  }

  // The order plans are read-only from web services so no need for encoding
  encode() {}

  /**
   * @param value {Object}
   * @return {OrderPlan|null}
   */
  decode(value) {
    if (value) {
      return new OrderPlan(
        value.id,
        value.description,
        value.price,
        value.available,
      );
    } else {
      return null;
    }
  }
}
