'use strict';

import Snap from 'snapsvg-cjs';
import Element from './element';

export default class Line extends Element {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param points {Array.<Point>}
   * @param [color] {string}
   * @param [width] {number}
   */
  constructor(id, metadata, points, color, width) {
    super(id, Line.type, metadata);
    this._points = points || [];
    this._color = color || '#111111';
    this._width = width || 2;
    this._pendingErase = false;
  }

  static get type() {
    return 'line';
  }

  static get PendingEraseColor() {
    return '#F3F3F3';
  }

  get pendingEraseColor() {
    return Line.PendingEraseColor;
  }

  /**
   * @returns {Array.<Point>}
   */
  get points() {
    return this._points;
  }

  /**
   * @param value {Array.<Point>}
   */
  set points(value) {
    this._trackChange(() => {
      this._points = value;
    });
  }

  /**
   * @returns {string}
   */
  get color() {
    return this._color;
  }

  /**
   * @param value {string}
   */
  set color(value) {
    this._trackChange(() => {
      this._color = value;
    });
  }

  /**
   * @returns {number}
   */
  get width() {
    return this._width;
  }

  /**
   * @param value {number}
   */
  set width(value) {
    this._trackChange(() => {
      this._width = value;
    });
  }

  /**
   * @returns {number}
   */
  get strokeOpacity() {
    return this._strokeOpacity;
  }

  /**
   * @param value {number}
   */
  set strokeOpacity(value) {
    this._strokeOpacity = value;
  }

  /**
   * @returns {boolean}
   */
  get pendingErase() {
    return this._pendingErase;
  }

  /**
   * @param value {boolean}
   */
  set pendingErase(value) {
    this._pendingErase = value;
    this.tryUpdate();
  }

  createElement(root) {
    this._line = root;
  }

  update() {
    if (this._line.select('path')) {
      this._line.select('path').remove();
    }

    Line.draw(
      this._line,
      this.points,
      this.color,
      this.width,
      this.pendingErase,
      this.pendingEraseColor,
    );
  }

  static draw(
    root,
    points,
    color,
    width,
    pendingErase,
    pendingEraseColor = '#F3F3F3',
    pointerEvents,
  ) {
    root.path(Line.svgPathFormat(points)).attr({
      stroke: pendingErase ? pendingEraseColor : color,
      strokeWidth: width,
      strokeLinecap: 'round',
      fill: 'none',
      pointerEvents: pointerEvents || 'none',
    });
  }

  static testDraw(points, color) {
    color = color || '#111111';
    let svg = angular.element('svg')[0];
    let root = new Snap(svg);
    Line.draw(root, points, color, '4px', false);
  }

  /**
   * Snap.js requires "SVG path string format": https://www.w3.org/TR/SVG/paths.html#PathData
   * @param value {Array.<Point>}
   */
  static svgPathFormat(pts) {
    // Formats dots
    if (pts.length === 1) {
      return `M${pts[0].x},${pts[0].y} L${pts[0].x},${pts[0].y}`;
    }
    // Formats straight and curved lines
    return pts.reduce((accum, pt) => {
      if (accum.length === 0) {
        return `M${pt.x},${pt.y} ${pts.length > 2 ? 'R' : 'L'}`;
      }
      return (accum += `${pt.x},${pt.y} `);
    }, '');
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {Line}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._points = other._points || this._points;
    this._color = other._color || this._color;
    this._width = other._width || this._width;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _points: this._points,
      _color: this._color,
      _width: this._width,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {Line}
   */
  fromSnapshot(id, snapshot) {
    return new Line(
      id,
      snapshot._metadata,
      snapshot._points,
      snapshot._color,
      snapshot._width,
    );
  }
}
