'use strict';

export default class NotificationShardKey {
  /**
   * Encapsulates the value used for notification sharding
   * @param name {string} Name of the shard key
   * @param value {string} Value of the shard key
   */
  constructor(name, value) {
    this._name = name;
    this._value = value;
  }

  /**
   * The name of the field used for sharding
   * @returns {string}
   */
  get name() {
    return this._name;
  }

  /**
   * The value used for sharding
   * @returns {string}
   */
  get value() {
    return this._value;
  }

  toString() {
    return `${this._name}=${angular.isUndefined(this._value) ? 'undefined' : this._value}`;
  }
}
