'use strict';

import ErrorDialogController from '../error-dialog/error-dialog.controller';
import { ShareWorkflowSteps } from '../../services/mixpanel/mixpanel.service';
import Validation from '../../model/util/validation';
import { AssignmentAccess } from '../../model/domain/assignment';
import ShareDialogTemplate from './share-dialog.html';
import { Locations } from '../../services/mixpanel/mixpanel.service';

export class ShareDialogStates {
  static get TEACHER_OR_STUDENT() {
    return 'teacher-or-student';
  }

  static get TEACHER() {
    return 'teacher';
  }

  static get EMAIL_SUCCESS_ASSIGNMENT() {
    return 'email-success-assignment';
  }

  static get EMAIL_SUCCESS_ASSIGNMENT_WORK() {
    return 'email-success-assignment-work';
  }

  static get LOADING() {
    return 'loading';
  }

  static get SHARE_STUDENT_WORK() {
    return 'share-student-work';
  }
}

export default class ShareDialogController {
  constructor(
    $mdDialog,
    $mdToast,
    $location,
    $window,
    clipboard,
    AssignmentService,
    AuthService,
    $state,
    assignment,
    location,
    studentName,
    assignmentWorkId,
    AnalyticsService,
    BreadcrumbService,
    CacheService,
    SharedWorksService,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$location = $location;
    this.$window = $window;
    this._clipboard = clipboard;

    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {SharedWorksService} */
    this._sharedWorksService = SharedWorksService;

    this._errorDialog = ErrorDialogController.show;

    this.$state = $state;
    this.clipboardIsSupported = clipboard.supported;
    this.assignment = assignment;
    this.shareWithTeacher = true;
    this._location = location;

    this.studentName = studentName;
    this.assignmentWorkId = assignmentWorkId;

    this.emailRegex = Validation.EmailRegex;

    this.emails = '';
    this.error = false;
    this.state = ShareDialogStates.TEACHER;

    this.mdrRecipientList = [];
    this.mdrPeersAvailable = [];

    this.hasShowShareModalFlag = this.$state?.params?.showShareModal ?? false;
    if (this.hasShowShareModalFlag) {
      this.customTitle = '🎉 Great Job! ';
    }

    if (this._authService.isLoggedIn) {
      if (this._location === Locations.ASSIGNMENT_WORK_QUESTION) {
        this.state = ShareDialogStates.SHARE_STUDENT_WORK;
      } else {
        this.state = ShareDialogStates.TEACHER_OR_STUDENT;
      }
    }

    this.linkBuilder(this.assignment.id, false);
    if (this.assignment.isFolder) {
      this.linkBuilder(this.assignment.id, true);
    }

    if (this._location === Locations.ASSIGNMENT_WORK_QUESTION) {
      this._analyticsService.shareWorkDialogOpened(
        Locations.ASSIGNMENT_WORK_QUESTION,
      );
    } else {
      this._analyticsService.shareAssignmentWorkflow(
        this.assignment.id,
        this.assignment.isFolder,
        this.assignment.folder ? true : false,
        this._location,
        ShareWorkflowSteps.STARTED,
      );
    }

    this.parentFolderObj = undefined;
    this.parentFolderId = this.assignment.folder;
    this.parentFolderName = undefined;

    this.showShareFolderCheckBox = false;
    this.switchToFolderAssignment = false;

    this.shareAssignmentMessageBody = this.shareAssignmentEmailBody(
      this.assignment.name,
    );

    // if nested assignment then capture parent folder information
    if (this.parentFolderId && !this.assignment.isFolder) {
      this.showShareFolderCheckBox = true;

      this._cacheService
        .getAssignment(this.parentFolderId)
        .then((parentFolderData) => {
          this.parentFolderObj = parentFolderData;
          this.parentFolderName = parentFolderData.name;
        });
    }

    // if folder, by default show the share with colleagues option
    if (this.assignment.isFolder) {
      this.chooseTeacher();
    }
  }

  /**
   * show the dialog
   * @param  {$mdDialog}  $mdDialog
   * @param  {assignment} assignment
   * @param  {string} location
   * @return {Promise}
   */
  static show(
    $mdDialog,
    assignment,
    location,
    studentName = null,
    assignmentWorkId = null,
  ) {
    let config = {
      controller: ShareDialogController,
      template: ShareDialogTemplate,
      controllerAs: 'ctrl',
      autoWrap: false,
      clickOutsideToClose: true,
      focusOnOpen: false,
      locals: {
        assignment,
        location,
        studentName,
        assignmentWorkId,
      },
    };

    return $mdDialog.show(config);
  }

  get currentUserIsAssignmentOwner() {
    return this._authService.currentUserId === this.assignment.ownerId;
  }

  /**
   * builds share link - individual assignment or folder
   * @param assignmentId {string}
   * @param isFolderLink {boolean}
   */
  linkBuilder(assignmentId, isFolderLink) {
    let host = this.$location.host();
    let port = '';
    // If we're not on the default http or https ports, we'll include the port in the link
    if (!(this.$location.port() === 80 || this.$location.port() === 443)) {
      port = `:${this.$location.port()}`;
    }

    let path = `/public/assignments/${assignmentId}`;
    if (isFolderLink) {
      path = `/public/folder/${assignmentId}`;
    }
    this._link = `${host}${port}${path}`;
  }

  shareAssignmentReferralUrl(assignmentName) {
    let shareReferralLink = `https://${this._link}?utm_source=${this._authService.currentUserId}&utm_medium=email&utm_campaign=assignment_share`;
    let shareReferralLinkHtml = `<br><p>View <a href="${shareReferralLink}">${assignmentName}</a></p>`;
    return shareReferralLinkHtml;
  }

  shareAssignmentEmailBody(assignmentName) {
    let defaultBody = `Hi,\nI'm using "${assignmentName}" with my students on Classkick. Click the link to copy it into your account or join for free if you don't have an account yet!\nView https://${this._link}`;
    return defaultBody;
  }

  shareAssignmentEmailBodyHtml(assignmentName) {
    let htmlBody = `
      <p>Hi,</p>
      <p>I'm using "<strong>${assignmentName}</strong>" with my students on Classkick. Click the link to copy it into your account or join for free if you don't have an account yet!</p>
      ${this.shareAssignmentReferralUrl(assignmentName)}
    `;
    return htmlBody;
  }

  /**
   * generates assignment or folder link on toggle
   */
  toggleParentChildAssignment() {
    this.linkBuilder(this.assignment.id, false);
    this.shareAssignmentMessageBody = this.shareAssignmentEmailBody(
      this.assignment.name,
    );
    if (this.switchToFolderAssignment) {
      this.linkBuilder(this.parentFolderId, true);
      this.shareAssignmentMessageBody = this.shareAssignmentEmailBody(
        this.parentFolderName,
      );
    }
  }

  /**
   * checks if single assignment is shared or entire folder
   * @return {string, boolean} assignmentId and if the assignment is a folder
   */
  identifyAssignmentOrFolderToShare() {
    let assignmentId = this.assignment.id;
    let assignmentName = this.assignment.name;
    let isAssignmentFolder = this.assignment.isFolder;
    let belongsToFolder = this.assignment.folder ? true : false;

    // ensure folder and all its assignments are made public
    if (!this.switchToFolderAssignment && isAssignmentFolder) {
      this._assignmentService.makePublic(this.assignment);
    }

    if (this.switchToFolderAssignment) {
      assignmentId = this.parentFolderObj.id;
      assignmentName = this.parentFolderObj.name;
      isAssignmentFolder = this.parentFolderObj.isFolder;
      belongsToFolder = this.parentFolderObj.folder ? true : false;

      this._assignmentService.makePublic(this.parentFolderObj);
      this._analyticsService.sendEvent({
        eventTag: 'switchToShareFolder_submit',
        properties: {
          assignmentId: assignmentId,
          isFolder: isAssignmentFolder,
          belongsToFolder: belongsToFolder,
        },
      });
    }

    return [assignmentId, assignmentName, isAssignmentFolder, belongsToFolder];
  }

  /**
   * close the dialog. rejects the promise returned from the show method
   */
  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * transition to the Share with Colleagues views. Also sets the assignment
   * to public.
   */
  chooseTeacher() {
    if (this.assignment.access !== 'public' && !this.assignment.isFolder) {
      this.setAssignmentToPublic()
        .then(() => {
          this.state = ShareDialogStates.TEACHER;
        })
        .catch(() => {
          this.cancel();
          this._errorDialog(
            this.$mdDialog,
            'Uh oh! Something went wrong setting your assignment to public',
            'Check your connection and try again',
          );
        });
    } else {
      this.state = ShareDialogStates.TEACHER;
    }
    this._analyticsService.shareAssignmentWorkflow(
      this.assignment.id,
      this.assignment.isFolder,
      this.assignment.folder ? true : false,
      this._location,
      ShareWorkflowSteps.WITH_COLLEAGUES,
    );
  }

  /**
   * set the assignment ot public.
   * * @return {Promise}
   */
  setAssignmentToPublic() {
    this.state = ShareDialogStates.LOADING;
    this.assignment.access = AssignmentAccess.PUBLIC;

    if (this.assignment.isFolder) {
      return this._assignmentService.makePublic(this.assignment);
    } else {
      return this._assignmentService.updateWithNoDateModification(
        this.assignment,
      );
    }
  }

  /**
   * choose the Share with Student option. links to the Edit Assignment view,
   * scrolled to the roster list.
   */
  chooseStudent() {
    this.cancel();
    this._breadcrumbService.go(
      'root.account.assignment-rosters',
      { assignmentId: this.assignment.id },
      true,
    );
    this._analyticsService.shareAssignmentWorkflow(
      this.assignment.id,
      this.assignment.isFolder,
      this.assignment.folder ? true : false,
      this._location,
      ShareWorkflowSteps.WITH_STUDENTS,
    );
  }

  /**
   * open public assignment view in a new tab
   */
  preview() {
    this.$window.open(`https://${this.link}`, '_blank');
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = this.emails.split(/[,;]/);
    if (emails.length > 10) {
      return false;
    }

    emails = emails.concat(this.mdrRecipientList).filter((email) => email);
    let isValid;
    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  get isEmailBodyValid() {
    if (this.shareAssignmentMessageBody !== '') {
      return true;
    }
    return false;
  }

  /**
   *
   * @param text {string}
   * @returns html converted text
   */
  convertCustomEmailBodyTextToHtml(text) {
    let link = `https://${this._link}`;

    // Escape special regex characters in the link
    let escapedLink = link.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regex pattern to match "View " followed by the exact link
    let viewLinkPattern = new RegExp('View ' + escapedLink, 'g');

    // First, remove any "View [link]" patterns
    // We will adding back a html friendly link
    let cleanedText = text.replace(viewLinkPattern, '');

    // Then convert newlines to <br> tags
    let textWithBrTags = cleanedText.replace(/\n/g, '<br>');

    return textWithBrTags;
  }

  /**
   *
   * @param assignmentName {string}
   * @returns body of the email, if default then use defaultHtml else append share url with referral link.
   */
  getEmailBody(assignmentName) {
    if (
      this.shareAssignmentMessageBody ===
      this.shareAssignmentEmailBody(assignmentName)
    ) {
      this.shareAssignmentMessageBody =
        this.shareAssignmentEmailBodyHtml(assignmentName);
    } else {
      this.shareAssignmentMessageBody = `${this.convertCustomEmailBodyTextToHtml(this.shareAssignmentMessageBody)}${this.shareAssignmentReferralUrl(assignmentName)}`;
    }
    return this.shareAssignmentMessageBody;
  }

  /**
   * after one last validation, split the emails in the list into an array
   * and hit the web services endpoint to send emails. Shows a toast if that
   * call fails.
   */
  sendEmails() {
    if (this.isEmailListValid && this.isEmailBodyValid) {
      this.state = ShareDialogStates.LOADING;

      let [assignmentId, assignmentName, isAssignmentFolder, belongsToFolder] =
        this.identifyAssignmentOrFolderToShare();

      this._analyticsService.shareAssignmentWorkflow(
        assignmentId,
        isAssignmentFolder,
        belongsToFolder,
        this._location,
        ShareWorkflowSteps.VIA_EMAIL,
      );
      let emailList = this.emails.split(/[,;]/);
      emailList = emailList
        .concat(this.mdrRecipientList)
        .filter((email) => email);

      this.shareAssignmentMessageBody = this.getEmailBody(assignmentName);

      this._assignmentService
        .shareAssignment(
          assignmentId,
          emailList,
          this.shareAssignmentMessageBody,
        )
        .then(() => {
          this.state = ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT;
        })
        .catch(() => {
          this._errorDialog(
            this.$mdDialog,
            'Whoops! There was an error sharing this assignment.',
            'Please try again and if you continue to have issues send us an email at support@classkick.com.',
          );
        });
    }
  }

  setEmailValidity(scope) {
    scope.emailShareForm.emails.$setValidity('pattern', this.isEmailListValid);
  }

  setEmailBodyValidity(scope) {
    scope.emailShareForm.bodyInput.$setValidity(
      'pattern',
      this.isEmailBodyValid,
    );
  }

  setEmailValidityForSharedWork(scope) {
    scope.emailShareWorkForm.emails.$setValidity(
      'pattern',
      this.isEmailListValid,
    );
  }

  /**
   * selects the text in the input
   */
  select() {
    angular.element('#link-input').select();
  }

  /**
   * copies the link to the users clipboard
   */
  copy() {
    if (this.clipboardIsSupported) {
      this._clipboard.copyText(this.fullLinkUrl);
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .textContent('Link copied to clipboard')
          .position('bottom right'),
      );
    }
    this.select();
    let [assignmentId, assignmentName, isAssignmentFolder, belongsToFolder] =
      this.identifyAssignmentOrFolderToShare();
    this._analyticsService.shareAssignmentWorkflow(
      assignmentId,
      isAssignmentFolder,
      belongsToFolder,
      this._location,
      ShareWorkflowSteps.VIA_LINK,
    );
  }

  get isTeacherOrStudent() {
    return this.state === ShareDialogStates.TEACHER_OR_STUDENT;
  }

  get isTeacherSharingStudentWork() {
    return this.state === ShareDialogStates.SHARE_STUDENT_WORK;
  }

  get isTeacher() {
    return this.state === ShareDialogStates.TEACHER;
  }

  get isEmailSuccessAssignment() {
    return this.state === ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT;
  }

  get isEmailSuccessAssignmentWork() {
    return this.state === ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT_WORK;
  }

  get isLoading() {
    return this.state === ShareDialogStates.LOADING;
  }

  get link() {
    return this._link;
  }

  get fullLinkUrl() {
    return `https://${this.link}`;
  }

  get socialMediaTextPreview() {
    return `I created an assignment called "${this.assignment.name}" with Classkick! Check it out here:`;
  }

  /* TODO: These socials are broken after the upgrade to React. */
  addSocialMediaButtons() {
    angular.element('.share-dialog .social-media').jsSocials({
      url: this.fullLinkUrl,
      showLabel: false,
      showCount: false,
      shares: ['facebook', 'twitter', 'pinterest'],
      text: this.socialMediaTextPreview,
      on: {
        click: this._handleSocialClick.bind(this),
      },
    });
  }

  _handleSocialClick(event) {
    let target = event.target;
    let className = target.className;

    let match = className.match(/(google|pinterest|facebook|twitter)/);
    let socialMediaPlatform = match && match[0];
    let step;

    if (socialMediaPlatform === 'google') {
      step = ShareWorkflowSteps.VIA_GOOGLE_PLUS;
    } else if (socialMediaPlatform === 'pinterest') {
      step = ShareWorkflowSteps.VIA_PINTEREST;
    } else if (socialMediaPlatform === 'facebook') {
      step = ShareWorkflowSteps.VIA_FACEBOOK;
    } else if (socialMediaPlatform === 'twitter') {
      step = ShareWorkflowSteps.VIA_TWITTER;
    }

    if (step) {
      this._analyticsService.shareAssignmentWorkflow(
        this.assignment.id,
        this.assignment.isFolder,
        this.assignment.folder ? true : false,
        this._location,
        step,
      );
    }
  }

  addToSharedAssignmentsPage() {
    this.setAssignmentToPublic()
      .then(() => {
        this.state = ShareDialogStates.TEACHER;
        this._analyticsService.shareAssignmentWorkflow(
          this.assignment.id,
          this.assignment.isFolder,
          this.assignment.folder ? true : false,
          this._location,
          ShareWorkflowSteps.PUBLIC_PAGE,
        );
        return this._cacheService.getUser();
      })
      .then((user) => {
        this.cancel();
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent(
              `${this.assignment.name} has been added to your Shared Assignments Page`,
            )
            .position('bottom right'),
        );
        this._breadcrumbService.go('root.account.nav.public-assignments-list', {
          userId: user.email || user.username,
        });
      });
  }

  shareAssignmentWork() {
    if (this.isEmailListValid) {
      this.state = ShareDialogStates.LOADING;
      let emailList = this.emails.replace(/\s+/g, '').split(/[,;]/);
      emailList = emailList
        .concat(this.mdrRecipientList)
        .filter((email) => email);
      this._sharedWorksService
        .shareAssignmentWork(this.assignmentWorkId, emailList)
        .then(() => {
          this.state = ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT_WORK;
        })
        .catch(() => {
          this._errorDialog(
            this.$mdDialog,
            "Whoops! There was an error sharing this student's assignment.",
            'Please try again and if you continue to have issues send us an email at support@classkick.com.',
          );
        });
    }
  }
}
