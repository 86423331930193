import $exceptionHandler from './$exception-handler';
import moment from 'moment';

export function config($logProvider, environment, $provide) {
  'ngInject';

  moment.suppressDeprecationWarnings = true;

  $provide.decorator('$exceptionHandler', $exceptionHandler);

  // Enable log
  $logProvider.debugEnabled(environment.debugLogging);
}
