'use strict';

import { UserRoles } from '../../model/domain/user';
import { AccountCreationStep } from '../../services/mixpanel/mixpanel.service';
import ErrorCodes from '../../model/domain/error-codes';
import { AuthType } from '../../services/auth/auth.service';

export default class AccountCleverController {
  constructor(
    $stateParams,
    $window,
    $state,
    AuthService,
    ToolbarService,
    BreadcrumbService,
    StorageService,
    AnalyticsService,
    CleverService,
    ClassCodeService,
    RosterService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$window = window;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CleverService} */
    this._cleverService = CleverService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {RosterService} */
    this._rosterService = RosterService;

    this._error = undefined;

    this._ckRedirect = this._storageService.ckRedirect;
    let { state, token } = $stateParams;

    let extractedState = state && angular.fromJson(state);

    if (extractedState) {
      this._classCode = extractedState.classCode;
    }

    if (token) {
      this._init(token);
    }
  }

  /**
   * @return {string}
   */
  get error() {
    return this._error;
  }

  /**
   * @return {string}
   */
  get sharedWorkPath() {
    return this._storageService.sharedWorkPath;
  }

  /**
   * @return {string | undefined}
   */
  get sharedWorkId() {
    if (this.sharedWorkPath) {
      return this.sharedWorkPath.split('/')[5];
    }
    return undefined;
  }

  /**
   * @return {boolean}
   */
  get isLoggedInAndRedirectingToLogin() {
    return this._authService.isLoggedIn && this._ckRedirect.to === 'root.login';
  }

  /**
   * @param token
   */
  _init(token) {
    this._cleverService
      .getInfo(token)
      .then((cleverInfo) => {
        if (cleverInfo.exists) {
          return this._loginUser(cleverInfo);
        } else {
          return this._signupUser(cleverInfo);
        }
      })
      .catch((error) => {
        if (error.code === ErrorCodes.INVITE_CODE_OR_CLASS_CODE_REQUIRED) {
          this.goToLogin();
        } else if (error.data.code === ErrorCodes.CLEVER_EMAIL_NOT_SET) {
          this._error =
            "Uh oh! There doesn't appear to be an email associated with the requested Clever account.";
        } else {
          this._cleverService.goToLogin();
        }
      });
  }

  /**
   * @param cleverInfo {CleverInfo}
   * @return {Promise}
   */
  _loginUser(cleverInfo) {
    return this._authService
      .authUserWithClever(cleverInfo)
      .then((user) => {
        if (user && user.isStudent) {
          return this._addStudentToRoster();
        }
      })
      .then(() => {
        return this._loginSuccess();
      });
  }

  _addStudentToRoster() {
    if (this._classCode) {
      return this._classCodeService
        .get(this._classCode)
        .then((classCodeData) => {
          return this._rosterService
            .addSelf(classCodeData.rosterId)
            .catch(() => {
              /* this will clear out the class code information so we do not we attempt to
               retrieve student work for someone that does not belong to a roster */
              this._storageService.ckRedirect = null;
              this._ckRedirect = null;
            });
        });
    }
  }

  _loginSuccess() {
    this._toolbarService.reset();
    this._breadcrumbService.clear();
    // Detect whether our redirect url is to clever to avoid redirect loops
    let redirectIsToClever = !!(
      this._ckRedirect &&
      this._ckRedirect.url &&
      this._ckRedirect.url.includes('clever.com')
    );

    const path = this.sharedWorkPath;
    this._storageService.sharedWorkPath = '';

    if (path) {
      this._storageService.ckRedirect = null;
      return this._breadcrumbService.goUrl(path, true);
    }

    if (
      this._ckRedirect &&
      !redirectIsToClever &&
      !this.isLoggedInAndRedirectingToLogin
    ) {
      this._ckRedirect.redirect(this._breadcrumbService);
    } else {
      this._breadcrumbService.go('root.account.home', {}, true);
    }
    this._storageService.ckRedirect = null;
  }

  /**
   * @param cleverInfo {CleverInfo}
   * @return {Promise}
   */
  _signupUser(cleverInfo) {
    return this._authService
      .signUpWithClever(cleverInfo, this._classCode)
      .then(() => {
        this._analyticsService.createAccountProgress(
          AccountCreationStep.STARTED,
        );

        const path = this.sharedWorkPath;
        this._storageService.sharedWorkPath = '';
        if (path) {
          this._storageService.sharedWorkSignUpAuthType = AuthType.CLEVER;
          this._storageService.ckRedirect = null;
          return this._breadcrumbService.goUrl(path, true);
        } else {
          this._breadcrumbService.go(
            cleverInfo.userType === UserRoles.STUDENT
              ? 'root.signup-student-name'
              : 'root.signup-teacher-name',
            {
              firstName: cleverInfo.firstName,
              lastName: cleverInfo.lastName,
            },
            true,
          );
        }
      });
  }

  goToLogin() {
    this._breadcrumbService.go('root.login', {}, true);
  }
}
