'use strict';

import HexColors from '../../css-constants';
import AudioRecorder from './audio-recorder';
import AudioTimer from './audio-timer';
import MimeTypes from '../../model/domain/mime-types';
import AudioDirectiveTemplate from './audio-capture.html';

export default function AudioCaptureDirective() {
  'ngInject';

  return {
    restrict: 'E',
    // The output is specified via an `ng-model` attribute. Format is {Blob}
    require: 'ngModel',
    scope: {},
    template: AudioDirectiveTemplate,
    link: AudioDirectiveController.link,
    controller: AudioDirectiveController,
    controllerAs: 'ctrl',
  };
}

/**
 * Dialog to add audio elements
 */
class AudioDirectiveController {
  constructor(
    $scope,
    $q,
    $timeout,
    $interval,
    MediaService,
    AssignmentTrackingService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.$interval = $interval;

    this._ngModel = null;
    /** @type {File|undefined} */
    this.capturedAudioFile = undefined;
    this.supportedMimeTypes = MimeTypes.AudioSupport().join(',');

    /** @type{MediaService} */
    this._mediaService = MediaService;
    /** @type{AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;

    this._currentState = this.SAVING;

    if (AudioRecorder.isSupported) {
      /** @type{AudioTimer} */
      this._audioTimer = new AudioTimer($interval);
      /** @type{AudioRecorder} */
      this._audioRecorder = new AudioRecorder($q);

      this._audioRecorder
        .init()
        .then(() => {
          this._currentState = this.START;
          this.$timeout(() => {}, 0);
        })
        .catch(() => {
          this._currentState = this.BLOCKED;
          this.$timeout(() => {}, 0);
        });
    } else {
      this._currentState = this.NOT_SUPPORTED;
    }

    $scope.$on('$destroy', () => this._destroy());
  }

  /**
   * the link function is run after the constructor for the controller
   * @param scope {$scope}
   * @param element {object}
   * @param attrs {object}
   * @param ngModel {*}
   */
  static link(scope, element, attrs, ngModel) {
    /** @type {AudioDirectiveController} */
    const ctrl = scope.ctrl;
    ctrl.ngModel = ngModel;
  }

  /**
   * @param value {NgModelController}
   */
  set ngModel(value) {
    this._ngModel = value;
  }

  /**
   * @param value {Blob}
   */
  set ngModelValue(value) {
    this._ngModel.$setViewValue(value);
  }

  /**
   * @returns {string}
   */
  get START() {
    return 'start';
  }

  /**
   * @returns {string}
   */
  get RECORDING() {
    return 'recording';
  }

  /**
   * @returns {string}
   */
  get SAVING() {
    return 'saving';
  }

  /**
   * @returns {string}
   */
  get NOT_SUPPORTED() {
    return 'not_supported';
  }

  /**
   * @returns {string}
   */
  get BLOCKED() {
    return 'blocked';
  }

  /**
   * @returns {string}
   */
  get FILE_TOO_LARGE() {
    return 'file_too_large';
  }

  /**
   * @returns {number}
   */
  get MAX_FILE_BYTES() {
    return 10000000;
  }

  get time() {
    return this._audioTimer && this._audioTimer.time;
  }

  start() {
    this._audioRecorder.start().then(() => {
      this._audioTimer.start();
      this._currentState = this.RECORDING;
    });
  }

  save() {
    this._audioRecorder.save().then((audioBlob) => {
      this.ngModelValue = audioBlob;
      this._currentState = this.START;
      this._audioTimer.stop();
    });
  }

  get audioStyle() {
    return {
      color:
        this._currentState === this.RECORDING
          ? HexColors.CK_GREEN
          : HexColors.CK_HEADER_GREY,
    };
  }

  /**
   * @returns {string}
   */
  get currentState() {
    return this._currentState;
  }

  /**
   * @param value {string}
   */
  set currentState(value) {
    this._currentState = value;
  }

  openFileSelect() {
    angular.element('.import-audio-input').click();
  }

  audioFileChanged() {
    const form = angular.element('.import-audio-form');
    if (form.length > 0) {
      angular.element('.import-audio-form')[0].reset();
    }

    if (
      this.capturedAudioFile &&
      this.capturedAudioFile.size < this.MAX_FILE_BYTES
    ) {
      this.ngModelValue = this.capturedAudioFile;
      this._currentState = this.START;
    } else {
      this._currentState = this.FILE_TOO_LARGE;
    }
  }

  _destroy() {
    if (this._audioRecorder) {
      this._audioRecorder.destroy();
      this._audioRecorder = null;
    }

    if (this._audioTimer) {
      this._audioTimer.stop();
    }
  }
}
