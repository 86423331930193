/* eslint-disable angular/log, no-console */

'use strict';

import StaticService from '../../services/static/static.service';

/**
 * A pipe to firebase. Leverages FirebaseObject and ModelMapping to allow simplified reading and writing
 * of FirebaseObject properties.
 */
export default class FirebaseConnection {
  /**
   * @param firebaseInstanceId {string} the id of the firebase instance for this object's data
   * @param FirebaseService {FirebaseService} used to subscribe to firebase events
   * @param instance {FirebaseObject} An instance of the mapped object to keep up to date with firebase
   * @param mapping {ModelMapping} Metadata describing how this._instance maps to the firebase schema
   * @param [triggerApply] {boolean}
   * @param [traceTag] {string} Trace logging tag for firebase activity
   */
  constructor(
    firebaseInstanceId,
    FirebaseService,
    instance,
    mapping,
    triggerApply = false,
    traceTag = '',
  ) {
    if (angular.isUndefined(FirebaseService)) {
      throw new Error('FirebaseService must be defined');
    }
    if (angular.isUndefined(instance)) {
      throw new Error('instance must be defined');
    }
    if (angular.isUndefined(mapping)) {
      throw new Error('mapping must be defined');
    }

    this._firebaseInstanceId = firebaseInstanceId;
    this._firebaseService = FirebaseService;
    this._instance = instance;
    this._mapping = mapping;
    this._apply = triggerApply;
    this._traceTag = traceTag;
    this._firebaseRefs = new Map();

    this._hasRead = false;

    this._mapping.urlTemplateToPropertyMap.forEach((property) => {
      let url = this._mapping.fullUrlForProperty(this._instance, property);
      let propertyFirebase = this._firebaseService.ref(
        url,
        this._firebaseInstanceId,
      );
      this._firebaseRefs.set(url, propertyFirebase);
    });
  }

  /**
   * subscribes to each of the properties where the connectionIndicator() method returns truthy
   */
  start() {
    this._logTrace('FirebaseConnection.start');
    this._mapping.urlTemplateToPropertyMap.forEach((property) => {
      let url = this._mapping.fullUrlForProperty(this._instance, property);
      let ref = this._firebaseRefs.get(url);

      if (property.connectIndicator(this._instance)) {
        if (property.isCollection) {
          ref.on('child_added', this.onChildAdded, this);
          ref.on('child_removed', this.onChildRemoved, this);
          ref.on('child_changed', this.onChildChanged, this);
        } else {
          ref.on('value', this.onValueChanged, this);
        }
      }
    });
  }

  /**
   * stops all subscriptions for all properties
   */
  stop() {
    this._logTrace('FirebaseConnection.stop');
    this._firebaseRefs.forEach((propertyFirebase) => {
      propertyFirebase.off('child_added', this.onChildAdded, this);
      propertyFirebase.off('child_removed', this.onChildRemoved, this);
      propertyFirebase.off('child_changed', this.onChildChanged, this);
      propertyFirebase.off('value', this.onValueChanged, this);
    });
  }

  /**
   * Loads the data once
   * @returns {Promise}
   */
  loadOnce() {
    this._logTrace('FirebaseConnection.loadOnce');
    let promises = {};
    this._mapping.urlTemplateToPropertyMap.forEach((property) => {
      let url = this._mapping.fullUrlForProperty(this._instance, property);
      if (!url) {
        throw new Error(
          'FirebaseConnection reference must be different than the base reference',
        );
      }

      let ref = this._firebaseService.ref(url, this._firebaseInstanceId);
      if (property.isCollection) {
        promises[url] = ref
          .parent()
          .once('value')
          .then((dataSnapshot) => {
            dataSnapshot.forEach((snapshot) => {
              property._onAdded(
                this._instance,
                property.codec.decode(snapshot.val(), snapshot.key),
              );
            });
          });
      } else {
        promises[url] = ref.once('value').then((dataSnapshot) => {
          const value = property.codec.decode(
            dataSnapshot.val(),
            dataSnapshot.key,
          );
          property._set(this._instance, value);
          return value;
        });
      }
    });

    return this._firebaseService.$q.all(promises);
  }

  /**
   * @returns {ModelMapping}
   */
  get mapping() {
    return this._mapping;
  }

  /**
   * Gets the firebase ref for a given property mapping
   * @param property {PropertyMapping}
   * @returns {Firebase}
   */
  firebaseForProperty(property) {
    return this._firebaseRefs.get(
      this._mapping.fullUrlForProperty(this._instance, property),
    );
  }

  /**
   * Sets a value for a given simple mapping
   * @param property {FirebasePropertyMapping}
   * @param value {object}
   */
  setValue(property, value) {
    this._logTrace(
      `FirebaseConnection.setValue property: ${angular.toJson(property)}, value: ${angular.toJson(value)}`,
    );
    let firebase = this.firebaseForProperty(property);
    firebase.set(property.codec.encode(value), this.logError);
  }

  /**
   * Removes the value at a given simple mapping
   * @param property {FirebasePropertyMapping}
   */
  removeValue(property) {
    this._logTrace(
      `FirebaseConnection.removeValue property: ${angular.toJson(property)}`,
    );
    let firebase = this.firebaseForProperty(property);
    firebase.remove(this.logError);
  }

  /**
   * Adds a new child to the end of a firebase collection
   * @param property {FirebaseCollectionMapping}
   * @param child {object}
   */
  createChild(property, child) {
    this._logTrace(
      `FirebaseConnection.createChild property: ${angular.toJson(property)}`,
    );
    let firebase = this.firebaseForProperty(property);
    firebase.push(property.codec.encode(child), this.logError);
  }

  /**
   * Removes the given child from a firebase collection
   * @param property {FirebaseCollectionMapping}
   * @param child {object}
   */
  removeChild(property, child) {
    this._logTrace(
      `FirebaseConnection.removeChild property: ${angular.toJson(property)}`,
    );
    let firebase = this.firebaseForProperty(property);
    return firebase.child(child.id).remove(this.logError);
  }

  /**
   * Puts the given child into a firebase collection. This will add the child if it is not already
   * present, or update the existing one if it is
   * @param property {FirebaseCollectionMapping}
   * @param child {object}
   */
  upsertChild(property, child) {
    this._logTrace(
      `FirebaseConnection.upsertChild property: ${angular.toJson(property)}`,
    );
    let firebase = this.firebaseForProperty(property);
    return firebase
      .child(child.id)
      .set(property.codec.encode(child), this.logError);
  }

  /**
   * Looks up the PropertyMapping for a given data snapshot
   * @param snapshot {DataSnapshot}
   * @param needParent {boolean}
   * @returns {PropertyMapping}
   */
  propertyForSnapshot(snapshot, needParent) {
    let firebase = this._firebaseService.attachExtras(
      snapshot.ref,
      this._firebaseService.instanceIdToUrl(this._firebaseInstanceId),
    );
    if (needParent) {
      firebase = firebase.$parent();
    }
    let uri = decodeURIComponent(firebase.$uri());
    return this._mapping.propertyForFullUrl(this._instance, uri);
  }

  /**
   * Callback method executed when data is updated on an active FirebasePropertyMapping
   * @param snapshot {DataSnapshot}
   * @private
   */
  onValueChanged(snapshot) {
    if (!this._hasRead) {
      this._hasRead = true;
    }

    /** @type {FirebasePropertyMapping} */
    let property = this.propertyForSnapshot(snapshot);
    this._logTrace(
      `FirebaseConnection.onValueChanged val: ${angular.toJson(snapshot.val())}, key: ${angular.toJson(snapshot.key)}`,
    );
    property._set(
      this._instance,
      property.codec.decode(snapshot.val(), snapshot.key),
    );
    this.apply();
  }

  /**
   * Callback method executed when a child is added to an active FirebaseCollectionMapping
   * @param snapshot {DataSnapshot}
   * @param prevChildKey {string}
   * @private
   */
  onChildAdded(snapshot, prevChildKey) {
    if (!this._hasRead) {
      this._hasRead = true;
    }

    /** @type {FirebaseCollectionMapping} */
    let property = this.propertyForSnapshot(snapshot, true);
    this._logTrace(
      `FirebaseConnection.onChildAdded: ${angular.toJson(snapshot)}`,
    );
    property._onAdded(
      this._instance,
      property.codec.decode(snapshot.val(), snapshot.key),
      prevChildKey,
    );
    this.apply();
  }

  /**
   * Callback method executed when a child is removed from an active FirebaseCollectionMapping
   * @param removedSnapshot {DataSnapshot}
   * @private
   */
  onChildRemoved(removedSnapshot) {
    if (!this._hasRead) {
      this._hasRead = true;
    }

    /** @type {FirebaseCollectionMapping} */
    let property = this.propertyForSnapshot(removedSnapshot, true);
    this._logTrace(
      `FirebaseConnection.onChildRemoved: ${angular.toJson(removedSnapshot)}`,
    );
    property._onRemoved(
      this._instance,
      property.codec.decode(removedSnapshot.val(), removedSnapshot.key),
    );
    this.apply();
  }

  /**
   * Callback method executed when a child is modified in an active FirebaseCollectionMapping
   * @param snapshot {DataSnapshot}
   * @param prevChildKey {string}
   * @private
   */
  onChildChanged(snapshot, prevChildKey) {
    /** @type {FirebaseCollectionMapping} */
    let property = this.propertyForSnapshot(snapshot, true);
    this._logTrace(
      `FirebaseConnection.onChildChanged: ${angular.toJson(snapshot)}`,
    );
    property._onChanged(
      this._instance,
      property.codec.decode(snapshot.val(), snapshot.key),
      prevChildKey,
    );
    this.apply();
  }

  /**
   * Triggers the digest cycle if necessary
   */
  apply() {
    if (this._apply) {
      this._firebaseService.triggerApply();
    }
  }

  /**
   * Sets up the firebase onDisconnect.set() function.
   * @param property {FirebaseCollectionMapping}
   * @param value {object} The value to set on disconnect
   */
  onDisconnectSet(property, value) {
    let firebase = this.firebaseForProperty(property);
    firebase.onDisconnect().cancel();
    firebase.onDisconnect().set(value);
  }

  /**
   * Sets up the firebase onDisconnect.remove() function.
   * @param property {FirebaseCollectionMapping}
   */
  onDisconnectRemove(property) {
    let firebase = this.firebaseForProperty(property);
    firebase.onDisconnect().cancel();
    firebase.onDisconnect().remove();
  }

  /**
   * @param error {Error}
   */
  logError(error) {
    !!error && StaticService.get.$log.error(error);
  }

  _logTrace(msg) {
    this._firebaseService.logTrace(this._traceTag, msg);
  }
}
