'use strict';

import { UserProperties } from '../../model/domain/user';
import platform from 'platform';

/**
 * Allows posting to google forms (https://gist.github.com/unforswearing/7b01591138c7d3ca27f1b50182573233)
 */
export default class GoogleFormService {
  constructor($location, constants, $http, environment) {
    'ngInject';

    this._environment = environment;
    this.$location = $location;
    this.$http = $http;

    this._host = $location.host();
    this._platform = platform.description;
    this._version = constants.version;
  }

  /**
   * @returns {string}
   */
  get STUDENT_FEEDBACK_ID() {
    return '1avJdMjXrY56a71Kg7Cj913f5ToauMhXA03sK3VY_XVE';
  }

  /**
   * @returns {string}
   */
  get TEACHER_FEEDBACK_ID() {
    return '1vINV6bEqF7smNpCFk3MtTkQ4NtNZYc6S5CH9MWD69qU';
  }

  /**
   * @returns {string}
   */
  get DELETE_REASON_ID() {
    return '1oSpPziYI0zDASaEEZYayVdwrZ3EspxLwtFT9rVXWTvk';
  }

  /**
   * @return {string}
   */
  get CANCEL_SUBSCRIPTION_REASON_ID() {
    return '1BN44MbOops-zPianTuH2u_rf9AIhQu5pC0JUhXvTm40';
  }

  /**
   * @returns {string}
   */
  get location() {
    return [this._host, this.$location.url()].join(' ');
  }

  /**
   * Log student feedback to a google form
   *
   * @param teacherEmail {string}
   * @param feedback {string}
   * @returns {Promise}
   */
  studentFeedback(teacherEmail, feedback) {
    return this._post(this.STUDENT_FEEDBACK_ID, {
      'entry.1850468403': feedback,
      'entry.733385003': teacherEmail,
      'entry.755902075': this.location,
      'entry.339612821': this._platform,
      'entry.496736929': this._version,
    });
  }

  /**
   * @param text {string}
   * @param email {string}
   * @param [user] {User}
   * @param [source] {string}
   * @returns {Promise}
   */
  sendTeacherFeedback(text, email, user, source) {
    let name = user && user.name;
    let jobTitle =
      user && user.properties && user.properties[UserProperties.JOB_TITLE];
    let school = user && user.school;
    let studentAge =
      user && user.properties && user.properties[UserProperties.STUDENT_AGE];

    return this._post(this.TEACHER_FEEDBACK_ID, {
      'entry.570244920': text,
      'entry.1171669438': source || 'n/a',
      'entry.390531121': email || 'support@classkick.com',
      'entry.867689693': name || email || 'n/a',
      'entry.1413261050': jobTitle || 'n/a',
      'entry.2134405296': school || 'n/a',
      'entry.79933580': platform.description || 'n/a',
      'entry.2012391473': studentAge || 'n/a',
    });
  }

  /**
   * Log account delete reason to a google form
   *
   * @param user {User}
   * @param reason {string}
   * @returns {Promise}
   */
  sendDeleteReason(user, reason) {
    return this._post(this.DELETE_REASON_ID, {
      'entry.1564290030': reason,
      'entry.1101783008': user.name,
      'entry.1461305748': user.email,
      'entry.1267441565': user.isTeacher ? 'teacher' : 'student',
    });
  }

  /**
   * Log cancel subscription reason to a google form
   *
   * @param user {User}
   * @param subscription {Subscription}
   * @param reason {string}
   * @returns {Promise}
   */
  sendCancelSubscriptionReason(user, subscription, reason) {
    return this._post(this.CANCEL_SUBSCRIPTION_REASON_ID, {
      'entry.719056737': user.name,
      'entry.1797035728': user.email,
      'entry.72751111': user.id,
      'entry.1213307834': subscription.id,
      'entry.712072495': subscription.contractId,
      'entry.1143721576': reason,
    });
  }

  /**
   * @param id {string}
   * @param params {Object} key-value pairs for form
   * @returns {Promise}
   * @private
   */
  _post(id, params) {
    let url = this._environment.serverUrlBase + '/v1/google-form/submit';

    return this.$http.post(url, {
      id: id,
      params: params,
    });
  }
}
