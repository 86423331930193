'use strict';

import LazyVar from '../util/lazy-var';

/**
 * Collects and maintains data for the shared work view
 */
export default class SharedWorkSessionData {
  /**
   * @param assignmentOwner {SharedWorkUser}
   * @param student {SharedWorkUser}
   * @param assignment {Assignment}
   * @param $q
   */
  constructor(assignmentOwner, student, assignment, $q) {
    this._assignmentOwner = assignmentOwner;
    this._student = student;
    this._assignment = assignment;
    this.$q = $q;

    /**
     * Lazily populated array of obects containing the question, question index, and question number
     * This is used to render question tiles
     * @type {LazyVar.<{question: AssignmentQuestion, index: int, number: int}[]>}
     */
    this._questionIndices = new LazyVar();
  }

  /**
   * @return {Assignment}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @return {SharedWorkUser}
   */
  get assignmentOwner() {
    return this._assignmentOwner;
  }

  /**
   * @return {SharedWorkUser}
   */
  get student() {
    return this._student;
  }

  /**
   * @returns {{question: AssignmentQuestion, index: int, number: int}[]}
   */
  get questions() {
    if (this.assignment) {
      return this._questionIndices.value(() => {
        return this.assignment.questions.map((value, index) => {
          return {
            question: value,
            index: index,
            number: index + 1,
          };
        });
      });
    }

    return [];
  }

  /**
   * Creates a new SharedWorkSessionData from the minimum necessary parameters
   *
   * @param assignmentOwner {SharedWorkUser}
   * @param student {SharedWorkUser}
   * @param assignment {Assignment}
   * @param $q
   * @returns {Promise.<SessionData>}
   */
  static fetch(assignmentOwner, student, assignmentWork, $q) {
    const sharedWorkData = new SharedWorkSessionData(
      assignmentOwner,
      student,
      assignmentWork.assignment,
      $q,
    );
    return $q
      .all(sharedWorkData)
      .then(() => {
        return sharedWorkData;
      })
      .catch((error) => {
        return error;
      });
  }
  /**
   * @param user {User}
   * @return {string}
   */
  realOrAnonNameForStudent(user) {
    return user.name;
  }
  /**
   * @returns {Assignment}
   */
}
