'use strict';

import { HelpInboxType } from '../../components/help-inbox/help-inbox.directive';
import StudentSessionData from '../../model/domain/student-session-data';
import { FeedbackMenuController } from '../../components/feedback-menu/feedback-menu.controller';

/**
 * Allows anonymous students to view all of the questions on an assignment
 */
export default class AnonStudentAssignmentOverviewController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $scope,
    $state,
    $stateParams,
    $mdDialog,
    $mdPanel,
    CacheService,
    BreadcrumbService,
    AuthService,
    StudentCacheService,
    StudentAssignmentService,
  ) {
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;

    this._feedbackMenu = FeedbackMenuController.show;

    this._model = this._studentAssignmentService.getStudentAssignmentOverview(
      HelpInboxType.STUDENT,
    );

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._model.destroy());

    this.init();
  }

  /**
   * @return {StudentAssignmentOverview}
   */
  get model() {
    return this._model;
  }

  get formattedClassCode() {
    return `${this.$stateParams.classCode.substr(0, 3)} ${this.$stateParams.classCode.substr(3)}`;
  }

  get username() {
    return this.$stateParams.name;
  }

  init() {
    this._authService
      .authAnonStudent(this.$stateParams.classCode, this.$stateParams.name)
      .then(({ classCode }) => {
        return this._studentCacheService.getOrCreateWorkForSelf(
          classCode.assignmentId,
          classCode.rosterId,
        );
      })
      .then((assignmentWork) => {
        return this._model.init(assignmentWork);
      })
      .catch((error) => {
        if (error.message === StudentSessionData.singleDeviceLoginError) {
          StudentSessionData.launchSingleDeviceErrorDialog(
            this.$mdDialog,
            false,
          )
            .then(() => {
              this.goBack();
            })
            .catch(() => {
              this.reload();
            });
        } else if (error.message === StudentSessionData.assignmentHiddenError) {
          StudentSessionData.launchAssignmentHiddenErrorDialog(
            this.$mdDialog,
            false,
          )
            .then(() => {
              this.goBack();
            })
            .catch(() => {
              this.reload();
            });
        }
        this._error = error;
      });
  }

  /**
   * Reloads this controller by navigating to the same state and passing reload as true
   */
  reload() {
    this.$state.go(
      'root.anon-student-assignment-overview',
      {
        classCode: this.$stateParams.classCode,
        name: this.$stateParams.name,
      },
      {
        reload: true,
      },
    );
  }

  /**
   * @param questionId {string}
   */
  goToQuestion(questionId) {
    this._breadcrumbService.go(
      'root.anon-student-assignment-work',
      {
        classCode: this.$stateParams.classCode,
        name: this.$stateParams.name,
        question:
          this.model.data.assignmentWork.indexForQuestionId(questionId) + 1,
      },
      true,
    );
  }

  /**
   * @param request {HelpRequest}
   */
  goToFeedback(request) {
    this._breadcrumbService.go('^.anon-student-assignment-feedback', {
      classCode: this.$stateParams.classCode,
      name: this.$stateParams.name,
      request: request,
    });
  }

  goBack() {
    this._cacheService.clearUserStatusDetails();
    this._breadcrumbService.goBack('root.login');
  }

  /**
   * Logs the user out and sends the user to the account login page
   */
  logOut() {
    this._authService.signOut();
  }

  get hasExportStudentWork() {
    return this._authService.authData.isPro;
  }

  openFeedbackMenu(ev) {
    this._feedbackMenu(
      this.$mdPanel,
      this.$q,
      ev,
      this.model.data.questionFeedbackList,
      this.model.data.assignmentWork,
    ).then(({ questionId }) => {
      this.goToQuestion(questionId);
    });
  }
}
