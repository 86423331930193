'use strict';

import Sorts from '../../model/domain/sorts';

const StudentAssignmentItemSorts = {};

StudentAssignmentItemSorts.SORT_NAME_ASC = (a, b) => {
  return (
    Sorts.BOOLEAN_TRUE_FIRST(a.isPromoted, b.isPromoted) ||
    Sorts.NAME_ASC(a.name, b.name)
  );
};
StudentAssignmentItemSorts.SORT_NAME_DESC = (a, b) => {
  return (
    Sorts.BOOLEAN_TRUE_FIRST(a.isPromoted, b.isPromoted) ||
    Sorts.NAME_DESC(a.name, b.name)
  );
};
StudentAssignmentItemSorts.SORT_SCORE_ASC = (
  /** @type {StudentAssignmentItem} */ a,
  /** @type {StudentAssignmentItem} */ b,
) => {
  let aTotalPercentage = a.totalPercentage;
  let bTotalPercentage = b.totalPercentage;

  return (
    Sorts.BOOLEAN_TRUE_FIRST(a.isPromoted, b.isPromoted) ||
    Sorts.NUMERIC_UNDEFINED_ASC(aTotalPercentage, bTotalPercentage)
  );
};
StudentAssignmentItemSorts.SORT_SCORE_DESC = (
  /** @type {StudentAssignmentItem} */ a,
  /** @type {StudentAssignmentItem} */ b,
) => {
  let aTotalPercentage = a.totalPercentage;
  let bTotalPercentage = b.totalPercentage;

  return (
    Sorts.BOOLEAN_TRUE_FIRST(a.isPromoted, b.isPromoted) ||
    Sorts.NUMERIC_UNDEFINED_DESC(aTotalPercentage, bTotalPercentage)
  );
};
StudentAssignmentItemSorts.SORT_FEEDBACK_ASC = (
  /** @type {StudentAssignmentItem} */ a,
  /** @type {StudentAssignmentItem} */ b,
) => {
  let aFeedbackCount = a.feedbackCount;
  let bFeedbackCount = b.feedbackCount;

  return (
    Sorts.BOOLEAN_TRUE_FIRST(a.isPromoted, b.isPromoted) ||
    Sorts.NUMERIC_UNDEFINED_ASC(aFeedbackCount, bFeedbackCount)
  );
};
StudentAssignmentItemSorts.SORT_FEEDBACK_DESC = (
  /** @type {StudentAssignmentItem} */ a,
  /** @type {StudentAssignmentItem} */ b,
) => {
  let aFeedbackCount = a.feedbackCount;
  let bFeedbackCount = b.feedbackCount;

  return (
    Sorts.BOOLEAN_TRUE_FIRST(a.isPromoted, b.isPromoted) ||
    Sorts.NUMERIC_UNDEFINED_DESC(aFeedbackCount, bFeedbackCount)
  );
};

export class StudentAssignmentItem {
  /**
   * @param assignmentOrWork {Assignment|AssignmentWork}
   * @param roster {Roster|undefined}
   * @param assignmentRoster {AssignmentRoster}
   * @param onAssignmentRosterUpdated {Function}
   * @param notificationService {NotificationService}
   * @param assignmentWorkService {AssignmentWorkService}
   * @param feedbackService {FeedbackService}
   */
  constructor(
    assignmentOrWork,
    roster,
    assignmentRoster,
    onAssignmentRosterUpdated,
    notificationService,
    assignmentWorkService,
    feedbackService,
  ) {
    this._assignment = assignmentOrWork;
    this._roster = roster;
    this._assignmentRoster = assignmentRoster;
    this._onAssignmentRosterUpdated = onAssignmentRosterUpdated;
    this._notificationService = notificationService;
    this._assignmentWorkService = assignmentWorkService;
    this._feedbackService = feedbackService;

    this._showStudentScores = this._assignmentRoster
      ? this._assignmentRoster.showStudentScores
      : false;
    this._hideStudentWork = this._assignmentRoster
      ? this._assignmentRoster.hideStudentWork
      : false;

    this._assignmentRosterNotification =
      this._notificationService.getAssignmentRosterNotification(
        this._assignment.isWork
          ? this._assignment.assignment
          : this._assignment,
        this.rosterId,
        false,
      );
    this._assignmentRosterNotification.metadata &&
      this._assignmentRosterNotification.metadata.subscribe(
        this._handleAssignmentRosterMetadataUpdated,
        this,
      );

    this._init();
  }

  _init() {
    if (this.isWork) {
      this._workQuestionSet =
        this._assignmentWorkService.getAssignmentWorkQuestionSet(
          this._assignment,
        );
      this._questionFeedbackList =
        this._feedbackService.getQuestionFeedbackList(
          this._assignment.ownerId,
          this._assignment,
        );
    }
  }

  /**
   * @param data {AssignmentRosterChange}
   */
  _handleAssignmentRosterMetadataUpdated(data) {
    if (this.isWork && this._assignment.classCode) {
      this._assignment.classCode.showStudentScores = data.showStudentScores;
      this._assignment.classCode.hideStudentWork = data.hideStudentWork;
    }

    if (this._assignmentRoster) {
      this._assignmentRoster.showStudentScores = data.showStudentScores;
      this._assignmentRoster.hideStudentWork = data.hideStudentWork;
    }

    this._showStudentScores = data.showStudentScores;
    this._hideStudentWork = data.hideStudentWork;

    this._onAssignmentRosterUpdated();
  }

  start() {
    this._assignmentRosterNotification.start();

    if (this.isWork) {
      this._workQuestionSet.start();
      this._questionFeedbackList.start();
    }
  }

  stop() {
    this._assignmentRosterNotification.stop();

    if (this.isWork) {
      this._workQuestionSet.stop();
      this._questionFeedbackList.stop();
    }
  }

  /**
   * @return {boolean}
   */
  get showStudentScores() {
    return this._showStudentScores;
  }

  /**
   * @return {boolean}
   */
  get hideStudentWork() {
    return this._hideStudentWork;
  }

  /**
   * @return {string}
   */
  get assignmentId() {
    return this.isWork ? this._assignment.assignment.id : this._assignment.id;
  }

  /**
   * @return {string}
   */
  get rosterId() {
    if (this._roster) {
      return this._roster.id;
    } else {
      return this.isWork ? this._assignment.rosterId : '';
    }
  }

  /**
   *
   * @returns {boolean}
   */
  get isPromoted() {
    const assignment = this.isWork
      ? this._assignment.assignment
      : this._assignment;
    return assignment.promotedRosters.includes(this.rosterId);
  }

  /**
   * @returns {string}
   */
  get name() {
    return this.isWork
      ? this._assignment.assignment.name
      : this._assignment.name;
  }

  /**
   * @returns {boolean}
   */
  get isWork() {
    return this._assignment.isWork;
  }

  /**
   * @returns {moment}
   */
  get lastUpdated() {
    return this.isWork
      ? this._assignment.assignment.lastModified
      : this._assignment.lastModified;
  }

  /**
   * @returns {string}
   */
  get lastUpdatedDisplay() {
    return this.lastUpdated.fromNow();
  }

  /** Score **/

  /**
   * @returns {string}
   */
  get studentTotalScoreDisplay() {
    let score = this.showStudentScores ? this.studentTotalScore : 0;
    let potential = this.showStudentScores ? this.totalPotentialPoints : 0;

    return score === 0 && potential === 0 ? '' : `${score}/${potential} Pts`;
  }

  /**
   * @returns {number}
   */
  get studentTotalScore() {
    if (this.isWork && this._workQuestionSet) {
      return this._workQuestionSet.hasScore
        ? this._workQuestionSet.totalPoints
        : '--';
    } else {
      return 0;
    }
  }

  /**
   * @return {number}
   */
  get totalPotentialPoints() {
    let assignment = this.isWork
      ? this._assignment.assignment
      : this._assignment;
    return assignment.totalPotentialPoints();
  }

  /**
   * Used for sorting by grade
   * @return {number}
   */
  get totalPercentage() {
    return this.isWork && this.showStudentScores
      ? this._workQuestionSet.totalPercentage
      : undefined;
  }

  /**
   * @returns {Assignment|AssignmentWork}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @param value {Assignment|AssignmentWork}
   */
  set assignment(value) {
    this._assignment = value;

    // When a StudentAssignmentItem transitions from an Assignment to AssignmentWork, we want to
    // call _init again to set up the proper notifications
    this._init();
  }

  /**
   * @return {QuestionFeedbackList}
   */
  get questionFeedbackList() {
    return this._questionFeedbackList;
  }

  /**
   * @return {undefined|number}
   */
  get feedbackCount() {
    return (
      this.questionFeedbackList && this.questionFeedbackList.feedbacks.size
    );
  }
}

/**
 *
 */
export default class StudentAssignmentFolderItem {
  /**
   * @param roster {Roster}
   * @param rosterOwner {User}
   * @param assignments {StudentAssignmentItem[]}
   * @param active  {boolean}
   * @param [open] {boolean}
   */
  constructor(roster, rosterOwner, assignments, active, open = false) {
    this._roster = roster;
    this._rosterOwner = rosterOwner;
    this._assignments = assignments;
    this._active = active;

    this._id = this._setAssignmentFolderId(roster, active);
    const rosterOwnerName = this._rosterOwner
      ? `${this._rosterOwner.name} - `
      : '';
    this._title = roster
      ? `${rosterOwnerName}${this._roster.name}`
      : 'Old Work';

    this.open = open;
    this._maxHeight = '1000px';
  }

  /**
   * @param roster {Roster}
   * @param rosterOwners {Map.<string, User>}
   * @param assignments {Map.<string, Assignment>}
   * @param works {Map.<string, AssignmentWork>}
   * @param assignmentRosters {Map.<string, AssignmentRoster>}
   * @param onAssignmentRosterUpdated {Function}
   * @param open {boolean}
   * @param notificationService {NotificationService}
   * @param assignmentWorkService {AssignmentWorkService}
   * @param feedbackService {FeedbackService}
   *
   * @returns {StudentAssignmentFolderItem}
   */
  static fromData({
    roster,
    rosterOwner,
    unstartedAssignments,
    works,
    assignmentRosters,
    onAssignmentRosterUpdated,
    open = false,
    notificationService,
    assignmentWorkService,
    feedbackService,
    activeWork,
  }) {
    // Create assignment items for each student work
    const studentAssignmentItems = new Map(
      works.map((w) => {
        let assignmentRoster = assignmentRosters.get(
          w.assignmentId + roster.id,
        );
        return [
          w.assignment.id,
          new StudentAssignmentItem(
            w,
            roster,
            assignmentRoster,
            onAssignmentRosterUpdated,
            notificationService,
            assignmentWorkService,
            feedbackService,
          ),
        ];
      }),
    );

    // If this is the active work folder, create assignment items for any unstarted assignments
    if (activeWork) {
      unstartedAssignments.forEach((a) => {
        let assignmentRoster = assignmentRosters.get(a.id + roster.id);
        studentAssignmentItems.set(
          a.id,
          new StudentAssignmentItem(
            a,
            roster,
            assignmentRoster,
            onAssignmentRosterUpdated,
            notificationService,
            assignmentWorkService,
            feedbackService,
          ),
        );
      });
    }

    return new StudentAssignmentFolderItem(
      roster,
      rosterOwner,
      [...studentAssignmentItems.values()],
      activeWork,
      open,
    );
  }

  static orphaned(
    works,
    assignmentRosters,
    onAssignmentRosterUpdated,
    folderStatus = {},
    notificationService,
    assignmentWorkService,
    feedbackService,
  ) {
    return new StudentAssignmentFolderItem(
      null,
      null,
      works.map((w) => {
        let assignmentRoster = assignmentRosters.get(
          w.assignmentId + w.rosterId,
        );
        return new StudentAssignmentItem(
          w,
          undefined,
          assignmentRoster,
          onAssignmentRosterUpdated,
          notificationService,
          assignmentWorkService,
          feedbackService,
        );
      }),
      true,
      !!folderStatus['orphaned'],
    );
  }

  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get assignments() {
    return this._assignments;
  }

  get active() {
    return this._active;
  }

  /**
   * Returns all of the assignments that are not currently hidden
   * @return {StudentAssignmentItem[]}
   */
  get visibleAssignments() {
    return this.assignments.filter((assignment) => !assignment.hideStudentWork);
  }

  set open(value) {
    if (value) {
      this.assignments.forEach((assignment) => {
        assignment.start();
      });
    } else {
      this.assignments.forEach((assignment) => {
        assignment.stop();
      });
    }
    this._open = value;
  }

  get open() {
    return this._open;
  }

  get closed() {
    return !this.open;
  }

  toggleOpen() {
    this.open = !this._open;
  }

  get height() {
    return this.closed ? '0' : this._maxHeight;
  }

  set maxHeight(value) {
    this._maxHeight = value;
  }

  /**
   * @returns {Roster}
   */
  get roster() {
    return this._roster;
  }

  _setAssignmentFolderId(roster, active) {
    if (roster) {
      return active ? roster.id : `${roster.id}-inactive`;
    } else {
      return 'orphaned';
    }
  }

  /**
   * Sorts the assignments in this folder
   * @param compareFunc {function(StudentAssignmentItem, StudentAssignmentItem)}
   */
  sort(compareFunc) {
    this.assignments.sort(compareFunc);
  }

  /**
   * @param search {string}
   * @returns {StudentAssignmentFolderItem|null}
   */
  filter(search) {
    const searchTerm = search.toLocaleLowerCase();

    if (this.title.toLocaleLowerCase().includes(searchTerm)) {
      return new StudentAssignmentFolderItem(
        this._roster,
        this._rosterOwner,
        this._assignments,
        this._active,
        true,
      );
    }

    const filteredAssignments = this._assignments.filter((a) =>
      a.name.toLocaleLowerCase().includes(searchTerm),
    );

    if (filteredAssignments.length > 0) {
      return new StudentAssignmentFolderItem(
        this._roster,
        this._rosterOwner,
        filteredAssignments,
        this._active,
        true,
      );
    }

    return null;
  }

  /**
   * @returns {(function(StudentAssignmentFolderItem, StudentAssignmentFolderItem))}
   */
  static get SORT_NAME_ASC() {
    return StudentAssignmentItemSorts.SORT_NAME_ASC;
  }

  /**
   * @returns {(function(StudentAssignmentFolderItem, StudentAssignmentFolderItem))}
   */
  static get SORT_NAME_DESC() {
    return StudentAssignmentItemSorts.SORT_NAME_DESC;
  }

  /**
   * @returns {(function(StudentAssignmentFolderItem, StudentAssignmentFolderItem))}
   */
  static get SORT_SCORE_ASC() {
    return StudentAssignmentItemSorts.SORT_SCORE_ASC;
  }

  /**
   * @returns {(function(StudentAssignmentFolderItem, StudentAssignmentFolderItem))}
   */
  static get SORT_SCORE_DESC() {
    return StudentAssignmentItemSorts.SORT_SCORE_DESC;
  }

  /**
   * @returns {(function(StudentAssignmentFolderItem, StudentAssignmentFolderItem))}
   */
  static get SORT_FEEDBACK_ASC() {
    return StudentAssignmentItemSorts.SORT_FEEDBACK_ASC;
  }

  /**
   * @returns {(function(StudentAssignmentFolderItem, StudentAssignmentFolderItem))}
   */
  static get SORT_FEEDBACK_DESC() {
    return StudentAssignmentItemSorts.SORT_FEEDBACK_DESC;
  }
}
