'use strict';

import { Section } from './toolbar-section';
import { ToolbarItem } from './toolbar-item';
import LazyVar from '../../model/util/lazy-var';

/**
 * Knows how to collapse the toolbar
 */
export default class ToolbarModel {
  /**
   * @param sections {Section[]}
   */
  constructor(sections) {
    this._sections = sections;
    /** @type {Section[]} */
    this._prioritySections = sections
      .slice()
      .sort(Section.SORT_PRIORITY_ASC)
      .filter((x) => x.priority >= 0);
    this._hasCollapsedSections = false;

    /** @type {MoreSection} */
    this._moreSection = this._sections.find(
      (x) => x.type === Section.TYPE_MORE,
    );

    this._displayItems = new LazyVar();
  }

  /**
   * @returns {Section[]}
   */
  get sections() {
    return this._sections;
  }

  /**
   * @param value {boolean}
   * @private
   */
  set _set_hasCollapsedSections(value) {
    this._hasCollapsedSections = value;
    if (this._moreSection) {
      if (this.hasCollapsedSections) {
        if (!this._moreSection.hasContent) {
          this._moreSection.hasContent =
            this._prioritySections.findIndex(
              (x) => x.collapsedItems.length > 0,
            ) >= 0;
        }
      } else {
        this._moreSection.hasContent = false;
      }
    }
  }

  /**
   * @returns {boolean}
   */
  get hasCollapsedSections() {
    return this._hasCollapsedSections;
  }

  /**
   * @param viewWidth {number}
   */
  sizeToFit(viewWidth) {
    this._prioritySections.forEach((x) => (x.collapsed = false));
    this._set_hasCollapsedSections = false;
    this._collapse(this._prioritySections, this.sections, viewWidth);
    this._displayItems.clear();
  }

  /**
   * @param remaining {Section[]}
   * @param all {Section[]}
   * @param viewWidth {number}
   * @private
   */
  _collapse(remaining, all, viewWidth) {
    if (remaining.length === 0) {
      return;
    }
    if (all.reduce((a, v) => a + v.displayWidth, 0) < viewWidth) {
      return;
    }
    remaining[0].collapsed = true;
    this._set_hasCollapsedSections = true;
    return this._collapse(remaining.slice(1), all, viewWidth);
  }

  /**
   * @returns {{ICON_BUTTON: string, TEXT_BUTTON: string, RENDERED_BUTTON: string, DIVIDER: string, SELECT: string, POINTS: string, FLEX_SPACE: string}}
   */
  get TYPES() {
    return ToolbarItem.TYPES;
  }

  /**
   * @returns {ToolbarItem[]}
   */
  get displayItems() {
    return this._displayItems.value(() => {
      return this.sections.reduce((a, s) => {
        return s.visibility ? a.concat(s.displayItems) : a;
      }, []);
    });
  }

  clear() {
    this._displayItems.clear();
  }

  /**
   * @param item {ToolbarItem}
   */
  isButton(item) {
    return (
      item.type === this.TYPES.ICON_BUTTON ||
      item.type === this.TYPES.TEXT_BUTTON ||
      item.type === this.TYPES.RENDERED_BUTTON
    );
  }
}
