'use strict';

import HelpArticleService from '../../services/help-article/help-article.service';
import {
  HelpArticleCategoriesDisplayMap,
  HelpArticleSectionsDisplayMap,
} from '../../model/domain/help-article';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import Sorts from '../../model/domain/sorts';
import { TeacherHelpCenterViews } from '../../services/mixpanel/mixpanel.service';

export default class HelpArticleController {
  /**
   * @ngInject
   */
  constructor(
    $scope,
    $state,
    $stateParams,
    $mdDialog,
    hotkeys,
    StaticService,
    CacheService,
    AuthService,
    HelpArticleService,
    BreadcrumbService,
    AnalyticsService,
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this._hotkeys = hotkeys;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {HelpArticleService} */
    this._helpArticleService = HelpArticleService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {StaticService} */
    this._staticService = StaticService;

    this._displayingInIpadApp = this._staticService.isClasskickIPadApp;

    this._error = undefined;
    this._mode = this.ViewMode;

    this._articleId = this.$stateParams.articleId;
    this._article = undefined;
    this._articleTitle = '';
    this._articleBody = '';
    this._articlePriority = 0;
    this._sectionArticles = [];
    this._nextArticle = undefined;

    this._confirmDialog = ConfirmDialogController.show;

    if (this._authService.authData && this._authService.authData.isAdmin) {
      this._initHotkeys(this.$scope, this._hotkeys);
    }
    this.init();
  }

  get mode() {
    return this._mode;
  }

  set mode(value) {
    this._mode = value;
  }

  get EditMode() {
    return 'edit_mode';
  }

  get ViewMode() {
    return 'view_mode';
  }

  init() {
    if (!this._articleId) {
      this._error = new Error('Article id is not defined');
      return;
    }

    this._cacheService
      .getArticle(this._articleId)
      .then((article) => {
        this._article = article;
        this._articleTitle = this._article.title;
        this._articleBody = this._article.body;
        this._articlePriority = this._article.priority;

        return this._cacheService.getArticles();
      })
      .then((articles) => {
        // Get all the section articles and sort them by priority
        let sortedSectionArticles = articles
          .filter((a) => a.sectionId === this._article.sectionId)
          .sort((a, b) => Sorts.NUMERIC_UNDEFINED_ASC(a.priority, b.priority));

        // Get the index of the current article within the sorted section array
        let articleIndex = sortedSectionArticles.findIndex(
          (a) => a.id === this._article.id,
        );

        this._sectionArticles = sortedSectionArticles.filter(
          (a) => a.id !== this._article.id,
        );

        // We use the article index here because once we have removed the current article from the
        // 'articles section' array, the next article will now be at the index of the current article
        this._nextArticle =
          this._sectionArticles[articleIndex] || this._sectionArticles[0];

        this._analyticsService.teacherHelpCenter(
          TeacherHelpCenterViews.Article,
          this._articleId,
        );
      });
  }

  _initHotkeys(scope, hotkeys) {
    let editCombo = this._staticService.isMac ? 'command+e' : 'ctrl+e';

    hotkeys
      .bindTo(scope)
      .add({
        combo: editCombo,
        description: 'Edit article',
        callback: () => {
          if (this.mode === this.ViewMode) {
            this.mode = this.EditMode;
          } else {
            this.saveArticle();
          }
        },
      })
      .add({
        combo: 'backspace',
        description: 'Delete',
        callback: () => {
          if (this._article) {
            this._confirmDialog(
              this.$mdDialog,
              'Are you sure you want to delete this article?',
              '',
            )
              .then(() => {
                return this._helpArticleService.delete(this._article.id);
              })
              .then(() => {
                this.goToHelpCenter();
              });
          }
        },
      });
  }

  saveArticle() {
    this._article.title = this._articleTitle;
    this._article.body = this._articleBody;
    this._article.priority = this._articlePriority;

    this._helpArticleService.update(this._article).then(() => {
      this.mode = this.ViewMode;
    });
  }

  get loading() {
    return !this.error && !this.article;
  }

  /**
   * @return {undefined|Error}
   */
  get error() {
    return this._error;
  }

  /**
   * @return {HelpArticle|undefined}
   */
  get article() {
    return this._article;
  }

  /**
   * @return {string}
   */
  get articleTitle() {
    return this._articleTitle;
  }

  /**
   * @param value {string}
   */
  set articleTitle(value) {
    this._articleTitle = value;
  }

  /**
   * @return {string}
   */
  get articleBody() {
    return this._articleBody;
  }

  /**
   * @param value {string}
   */
  set articleBody(value) {
    this._articleBody = value;
  }

  /**
   * @return {number}
   */
  get articlePriority() {
    return this._articlePriority;
  }

  /**
   * @param value {number}
   */
  set articlePriority(value) {
    this._articlePriority = value;
  }

  goToHelpCenter() {
    this.$state.go('root.help');
  }

  returnToClasskick() {
    this.$state.go('root.account.home');
  }

  get categoryDisplay() {
    return (
      this._article && HelpArticleCategoriesDisplayMap[this._article.categoryId]
    );
  }

  get sectionDisplay() {
    return (
      this._article && HelpArticleSectionsDisplayMap[this._article.sectionId]
    );
  }

  goToCategory() {
    this.$state.go('root.help-category', {
      categoryId: this._article.categoryId,
    });
  }

  goToSection() {
    this.$state.go('root.help-section', {
      sectionId: this._article.sectionId,
    });
  }

  goToArticle(articleId) {
    this.$state.go('root.help-article', {
      articleId,
    });
  }

  get sectionArticles() {
    return this._sectionArticles;
  }

  get nextArticle() {
    return this._nextArticle;
  }

  get hideOutsideLinks() {
    return this._displayingInIpadApp;
  }
}
