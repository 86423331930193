'use strict';

export default class CampaignService {
  constructor(environment, HttpService, $q) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    this.$q = $q;
  }

  /**
   * @return {Promise}
   */
  getCampaignInfo(utmObj) {
    return this._httpService.post(this._uri('/v1/campaign'), utmObj);
  }

  /**
   * Returns a string that specifies a uri given a path.
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
