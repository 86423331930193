'use strict';

import MessageCodec from '../../model/codec/message-codec';

export default class MessageService {
  constructor(HttpService, $q, environment, AuthService, $log) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this._environment = environment;

    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {AuthService} */
    this._authService = AuthService;

    /** @type {MessageCodec} */
    this._messageCodec = new MessageCodec();
  }

  getReceivedMessagesForCurrentUser() {
    return this.getReceivedMessagesForUser(this._authService.authData.id);
  }

  /**
   * Gets all the messages a user received given their Id.
   * @param userId
   * @returns {*}
   */
  getReceivedMessagesForUser(userId) {
    return this._httpService
      .authGet(this._uri(`/v1/users/${userId}/received-messages`))
      .then((data) => {
        return data.messages.map((message) => {
          return this._messageCodec.decode(message);
        });
      });
  }

  getSentMessagesForCurrentUser() {
    return this.getSentMessagesForUser(this._authService.authData.id);
  }

  /**
   * Gets all the messages a user sent given the userId
   * @param userId
   * @returns {*}
   */
  getSentMessagesForUser(userId) {
    return this._httpService
      .authGet(this._uri(`/v1/users/${userId}/sent-messages`))
      .then((data) => {
        return data.messages.map((message) => {
          return this._messageCodec.decode(message);
        });
      });
  }

  /**
   * Deletes the message given the id.
   * @param messageId
   * @returns {Promise.<T>}
   */
  delete(messageId) {
    return this._httpService.authDelete(this._uri(`/v1/messages/${messageId}`));
  }

  /**
   * Marks the message as seen given the id.
   * @param messageId
   * @returns {Promise.<T>}
   */
  markAsSeen(messageId) {
    return this._httpService.authPost(
      this._uri(`/v1/messages/${messageId}/mark-as-seen`),
      {},
    );
  }

  /**
   * Get a message given the id
   * @param messageId
   * @returns {Message}
   */
  get(messageId) {
    return this._httpService
      .authGet(this._uri(`/v1/messages/${messageId}`))
      .then((data) => {
        return this._messageCodec.decode(data);
      });
  }

  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
