'use strict';

import moment from 'moment';
import JSEvent from '../util/js-event';
import LazyVar from '../util/lazy-var';

/**
 * Contains and manages the collection of unread messages for a user
 */
export default class MessageSet {
  /**
   * @param messages {Message[]}
   * @param notification {UserNewMessageNotification}
   * @param messageService {MessageService}
   */
  constructor(messages, notification, messageService) {
    /** @type {Message[]} */
    this._messages = messages.filter((x) => !x.deletedDate);
    /** @type {UserNewMessageNotification} */
    this._notification = notification;
    /** @type {MessageService} */
    this._messageService = messageService;

    /** @type {LazyVar.<boolean>} */
    this._hasUnseenMessages = new LazyVar();
    /** @type {JSEvent} */
    this._updated = new JSEvent(this);

    this._notification.updated.subscribe(this._messageAdded, this);
  }

  /**
   * Starts the contained notification
   */
  start() {
    this._notification.start();
  }

  /**
   * Stops the contained notification
   */
  stop() {
    this._notification.stop();
  }

  /**
   * @returns {Message[]}
   */
  get messages() {
    return this._messages;
  }

  /**
   * @returns {JSEvent.<{change: {string}, message: {Message}}>}
   */
  get updated() {
    return this._updated;
  }

  /**
   * @returns {boolean}
   */
  get hasUnseenMessages() {
    return this._hasUnseenMessages.value(() => {
      return this._messages.findIndex((x) => !x.seenDate) >= 0;
    });
  }

  /**
   * @param id {string}
   * @returns {Promise.<*>}
   */
  delete(id) {
    return this._messageService.delete(id).then(() => {
      const index = this.messages.findIndex((x) => x.id === id);

      if (index >= 0) {
        const message = this._messages[index];
        this._messages.splice(index, 1);
        this._hasUnseenMessages.clear();
        this._raiseUpdated(this.DELETE, message);
      }
    });
  }

  /**
   * @param id {string}
   * @returns {Promise.<*>}
   */
  markAsSeen(id) {
    return this._messageService.markAsSeen(id).then(() => {
      const index = this.messages.findIndex((x) => x.id === id);

      if (index >= 0) {
        this._messages[index].seenDate = moment();
        this._hasUnseenMessages.clear();
        this._raiseUpdated(this.UPDATE, this._messages[index]);
      }
    });
  }

  /**
   * Triggered when notifications are updated from Firebase.
   * @param newMessage
   *
   * @private
   */
  _messageAdded(newMessage) {
    if (newMessage) {
      this._messages.push(newMessage);
      this._hasUnseenMessages.set(true);
      this._raiseUpdated(this.ADD, newMessage);
    }
  }

  /**
   * @param change {string}
   * @param message {Message}
   * @private
   */
  _raiseUpdated(change, message) {
    this.updated.raise({
      change: change,
      message: message,
    });
  }

  destroy() {
    this.stop();
    this._notification.updated.unsubscribe(this._messageAdded, this);
  }

  get ADD() {
    return 'add';
  }

  get DELETE() {
    return 'delete';
  }

  get UPDATE() {
    return 'update';
  }
}
