'use strict';

import StudentAccountHelpDialogController from '../student-account-help-dialog/student-account-help-dialog.controller';
import ShareWithStudentDialogTemplate from './share-with-student-dialog.html';
/**
 * Dialog to support sharing classcodes with students
 */
export default class ShareWithStudentDialogController {
  constructor(
    $mdDialog,
    $window,
    $location,
    $mdToast,
    $rootScope,
    clipboard,
    AnalyticsService,
    assignment,
    roster,
    classCode,
    pro,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$location = $location;
    this.$mdToast = $mdToast;
    this.$rootScope = $rootScope;
    this._clipboard = clipboard;
    this._firstTimeClickedWelcomeDialog = true;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    /** @type {Assignment} */
    this.assignment = assignment;
    /** @type {Roster} */
    this.roster = roster;
    /** @type {ClassCode} */
    this.classCode = classCode;
    /** @type {string} */
    this.studentLink = classCode.studentUrl($location);
    /** @type {ProInfo} */
    this.pro = pro;

    this._showStudentAccountHelpDialog =
      StudentAccountHelpDialogController.show;
  }

  /**
   *
   * @param $mdDialog
   * @param assignment {Assignment}
   * @param roster {Roster}
   * @param classCode {ClassCode}
   * @param pro {ProInfo}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, assignment, roster, classCode, pro) {
    return $mdDialog.show({
      controller: ShareWithStudentDialogController,
      template: ShareWithStudentDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        assignment,
        roster,
        classCode,
        pro,
      },
      focusOnOpen: false,
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  copy() {
    this._clipboard.copyText(this.studentLink);

    angular.element('#link-input').select();

    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent('Link copied to clipboard')
        .position('bottom right'),
    );

    this._analyticsService.classCodeLinkCopied(
      this.roster.id,
      this.assignment.id,
    );
  }

  /**
   * Copy link for the first time after registration when dialog pops up upon landing  in WCV Page.
   * Will be removed if KICK-1374 or PR #2081 gets rollbacked.
   */
  copyOnRegistration() {
    this._clipboard.copyText(this.studentLink);

    angular.element('#link-input').select();

    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent('Link copied to clipboard')
        .position('bottom right'),
    );

    this._analyticsService.classCodeLinkCopiedAfterRegistration(
      this.roster.id,
      this.assignment.id,
      this._firstTimeClickedWelcomeDialog,
    );
    this._firstTimeClickedWelcomeDialog = false;
  }

  openNewTab() {
    const url = this.studentLink;
    this.$window.open(url, '_blank'); // Opens the URL in a new tab
    this._analyticsService.openNewTabAfterRegistration(
      this.roster.id,
      this.assignment.id,
      this._firstTimeClickedWelcomeDialog,
    );
    this._firstTimeClickedWelcomeDialog = false;
  }

  openStudentAccountHelpDialog() {
    this._showStudentAccountHelpDialog(this.$mdDialog, this.pro);
  }

  get showDialog() {
    return this.$rootScope.showDialog;
  }

  hideDisplayNewTeacherDialog() {
    this._analyticsService.closeWelcomeDialogAfterRegistration(
      this.roster.id,
      this.assignment.id,
      this._firstTimeClickedWelcomeDialog,
    );
    this._firstTimeClickedWelcomeDialog = false;
    this.$rootScope.showDialog = false;
  }
}
