'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import JsonCodec from './json-codec';
import ManipulativeImageParent from '../ui/elements/manipulative-image-parent';

/**
 * Encodes/Decodes a manipulative image parent element
 */
export default class ManipulativeImageParentCodec extends Codec {
  constructor() {
    super();
    this._jsonCodec = new JsonCodec();
  }

  /**
   * @param value {ManipulativeImageParent}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, () => {
      let transform = this._jsonCodec.encode({
        scale: value.scale,
        rotation: value.rotation,
      });

      return {
        id: value.id,
        type: value.type,
        image_url: value.url,
        size: ElementCodec.sizeCodec.encode(value.imageSize),
        center: ElementCodec.pointCodec.encode(value.location),
        transform: transform,
      };
    });
  }

  /**
   * @param value {object}
   * @param id {string}
   * @returns {ManipulativeImageParent}
   */
  decode(value, id) {
    return ElementCodec.preDecode(value, (metadata, size, location, center) => {
      let transform = this._jsonCodec.decode(value.data.transform);

      return new ManipulativeImageParent(
        id,
        metadata,
        center,
        size,
        transform.scale,
        transform.rotation,
        value.data.image_url,
      );
    });
  }
}
