'use strict';

export default function CkDraggableDirective() {
  'ngInject';
  return {
    restrict: 'A',
    scope: {
      ckDragstart: '&?',
      ckDragend: '&?',
      ckDragover: '&?',
      ckDragleave: '&?',
      ckDrop: '&?',
    },
    link: (scope, element, attr) => {
      element.attr('draggable', !!scope.ckDragstart);

      if (scope.ckDragstart) {
        scope.$watch('ckDragstart', () => {
          element.bind('dragstart', (event) => {
            scope.ckDragstart({
              event: event.originalEvent,
            });
          });
        });
      }

      if (scope.ckDragend) {
        scope.$watch('ckDragend', () => {
          element.bind('dragend', (event) => {
            scope.ckDragend({
              event,
            });
          });
        });
      }

      scope.$watch('ckDragover', () => {
        element.bind('dragover', (event) => {
          if (scope.ckDragover) {
            // The ckDragover function must call event.preventDefault() if this element is to be "droppable"
            scope.ckDragover({
              event,
            });
          } else {
            // If there isn't a ckDragover function, return false to ensure element remains "droppable"
            return false;
          }
        });
      });

      if (scope.ckDragleave) {
        scope.$watch('ckDragleave', () => {
          element.bind('dragleave', (event) => {
            scope.ckDragleave({
              event,
            });
          });
        });
      }

      if (scope.ckDrop) {
        scope.$watch('ckDrop', () => {
          element.bind('drop', (event) => {
            scope.ckDrop({
              event: event.originalEvent,
            });
          });
        });
      }
    },
  };
}
