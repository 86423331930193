'use strict';

import Codec from './codec';
import ChildOrganizationCount from '../domain/child-organizations-count';

/**

 Encodes/Decodes organization total associated with a parent org
 */
export default class ChildOrganizationCountCodec extends Codec {
  constructor() {
    super();
  }
  /**

   @param value {ChildOrganizationCount}
   @return {{count: number }}
   */
  encode(value) {
    return {
      count: value.count,
    };
  }

  /**

   @param value {Object}
   @return {ChildOrganizationCount}
   */
  decode(value) {
    if (value) {
      return new ChildOrganizationCount(value.count);
    }
  }
}
