import Order from '../../model/domain/order';
import OrderCodec from '../../model/codec/order-codec';
import OrderPlanCodec from '../../model/codec/order-plan-codec';
import OrderCouponCodec from '../../model/codec/order-coupon-codec';
import OrderPriceCodec from '../../model/codec/order-price-codec';
import OrderTrackingCodec from '../../model/codec/order-tracking-codec';

const FEATURE_CHART_TEMPLATE = 'SHARE_PRICING_PAGE_CHART_ID';
export default class OrderService {
  /**
   * @ngInject
   */
  constructor($q, $log, HttpService, environment) {
    this.$q = $q;
    this.$log = $log;

    /** @type {HttpService} */
    this._httpService = HttpService;

    this._environment = environment;

    this._orderCodec = new OrderCodec();
    this._orderPlanCodec = new OrderPlanCodec();
    this._orderCouponCodec = new OrderCouponCodec();
    this._orderPriceCodec = new OrderPriceCodec();
    this._orderTrackingCodec = new OrderTrackingCodec();
  }

  /**
   * Gets an order
   * @param orderId
   * @return {Promise.<Order>}
   */
  get(orderId) {
    return this.$q
      .all({
        order: this._get(orderId),
      })
      .then(({ order }) => {
        return this.getDetailsForOrder(order);
      });
  }

  _get(orderId) {
    return this._httpService
      .get(this._uri(`/v1/orders/${orderId}`))
      .then((data) => {
        return this._orderCodec.decode(data);
      });
  }

  /**
   * @param organizationId {string}
   * @param plan {string}
   * @param start {moment}
   * @param end {moment}
   * @param renewal {boolean}
   * @param quantity {number}
   * @param couponIds {string[]}
   * @param creatorId {string}
   * @param creatorName {string}
   * @param creatorEmail {string}
   * @return {Promise<Order>}
   */
  getOrCreateOrder(
    organizationId,
    plan,
    start,
    end,
    renewal,
    quantity,
    couponIds,
    creatorId,
    creatorName,
    creatorEmail,
  ) {
    return this.$q
      .all({
        order: this._getOrCreateOrder(
          organizationId,
          plan,
          start,
          end,
          renewal,
          quantity,
          couponIds,
          creatorId,
          creatorName,
          creatorEmail,
        ),
      })
      .then(({ order }) => {
        return this.getDetailsForOrder(order);
      });
  }

  /**
   * @param organizationId {string}
   * @param plan {string}
   * @param start {moment}
   * @param end {moment}
   * @param renewal {boolean}
   * @param quantity {number}
   * @param couponIds {string[]}
   * @param creatorId {string}
   * @param creatorName {string}
   * @param creatorEmail {string}
   * @return {Promise<Order>}
   */
  _getOrCreateOrder(
    organizationId,
    plan,
    start,
    end,
    renewal,
    quantity,
    couponIds,
    creatorId,
    creatorName,
    creatorEmail,
  ) {
    return this._httpService
      .post(this._uri('/v1/orders'), {
        organization_id: organizationId,
        plan,
        start: start.toISOString(),
        end: end.toISOString(),
        renewal,
        quantity,
        coupons: couponIds,
        creator_id: creatorId,
        creator_name: creatorName,
        creator_email: creatorEmail,
      })
      .then((data) => {
        return this._orderCodec.decode(data);
      });
  }

  /**
   * Updates an order
   *
   * @param order {Order}
   * @returns {Promise.<T>}
   */
  update(order) {
    return this._httpService.put(
      this._uri(`/v1/orders/${order.id}`),
      this._orderCodec.encode(order),
    );
  }

  /**
   * Cancels an order
   *
   * @param orderId {string}
   * @return {Promise}
   */
  cancel(orderId) {
    return this._httpService.post(
      this._uri(`/v1/orders/${orderId}/cancel`),
      {},
    );
  }

  /**
   * Shares order with others via email
   *
   * @param quoteId {string} the order id to share
   * @param replyTo {string} who is sending the message
   * @param to {string[]} who is being sent the message
   * @param cc {string[]} who is being CC'd
   * @param subject {string} the subject of the email
   * @param body {string} the body of the email
   * @param fromName {string|undefined} the from custom name
   * @param extra {object|undefined} extra custom arguments sent
   * @return {Promise<T>}
   */
  share(
    quoteId,
    replyTo,
    to,
    cc,
    subject,
    body,
    fromName = undefined,
    extra = undefined,
    bcc = undefined,
  ) {
    // Remove duplicate emails in cc and to using the removeDuplicates function
    const { cc: uniqueCc, to: uniqueTo } = this.removeDuplicates(cc, to);

    return this._httpService
      .post(this._v2uri('/v2/salesbuddy/share-quote-email'), {
        quote_id: quoteId,
        reply_to: replyTo,
        from_name: fromName,
        to: uniqueTo,
        cc: uniqueCc,
        subject,
        body,
        extra: extra,
        bcc,
      })
      .then((data) => {
        return data;
      });
  }

  /**
   * Shares order with others via email
   *
   * @param replyTo {string} the order id to share
   * @param quoteId {string} who is sending the message
   * @param to {string[]} who is being sent the message
   * @param cc {string[]} who is being CC'd
   * @param fromName {string|undefined} the from custom name
   * @param subject {string}
   * @param body {string} the body of the email
   * @return {Promise<T>}
   */
  shareFeatureChart(
    quoteId,
    replyTo,
    to,
    cc,
    fromName = undefined,
    subject,
    body,
  ) {
    // Remove duplicate emails in cc and to using the removeDuplicates function
    const { cc: uniqueCc, to: uniqueTo } = this.removeDuplicates(cc, to);

    return this._httpService
      .post(this._v2uri('/v2/salesbuddy/dynamic-email'), {
        cc: uniqueCc,
        to: uniqueTo,
        reply_to: replyTo,
        from_name: fromName,
        template: FEATURE_CHART_TEMPLATE,
        template_data: {
          name: fromName,
          subject: subject,
          quoteId: quoteId,
          body: body,
        },
      })
      .then((data) => {
        return data;
      });
  }

  removeDuplicates(cc, to) {
    const additional = 'pro@classkick.com';

    // Remove duplicate emails in cc and to
    try {
      cc = [...new Set(cc)];
      to = [...new Set(to)];
    } catch (_) {
      /* Set does not work in browser, try old school way to remove duplicate emails */
      cc = cc.filter((value, index, array) => array.indexOf(value) === index);
      to = to.filter((value, index, array) => array.indexOf(value) === index);
    }

    if (!cc.includes(additional)) {
      cc.push(additional);
    }
    return { cc, to };
  }

  /**
   * @param order {Order}
   * @param [stripeToken] {string}
   * @return {Promise}
   */
  submit(order, stripeToken) {
    return this._httpService.post(this._uri(`/v1/orders/${order.id}/submit`), {
      ...this._orderCodec.encode(order),
      stripe_token: stripeToken,
    });
  }

  /**
   * Gets all orders for an organization
   * @param organizationId {string}
   * @return {Promise<Order[]>}
   */
  getForOrganization(organizationId) {
    return this.$q
      .all({
        orders: this._getForOrganization(organizationId),
        details: this._getDetails(organizationId),
      })
      .then(({ orders, details }) => {
        return orders.map((order) => {
          order.activePlans = details.plans;
          order.activeCoupons = details.coupons;
          return order;
        });
      });
  }

  /**
   * Gets the active order for an organization
   * TODO: move to backend so we're not getting all orders
   * @param organizationId {string}
   * @return {Promise<Order>}
   */
  async getActiveOrder(organizationId) {
    const orders = await this.getForOrganization(organizationId);
    return Order.findActiveOrder(orders);
  }

  /**
   * Gets all orders for an organization
   * @param organizationId {string}
   * @return {Promise<Order[]>}
   */
  _getForOrganization(organizationId) {
    return this._httpService
      .get(this._uri(`/v1/organizations/${organizationId}/orders`))
      .then((data) => {
        return data.orders.map((order) => this._orderCodec.decode(order));
      });
  }

  /**
   * @param [order] {Order}
   * @return {Promise<{plans: OrderPlan[], coupons: OrderCoupon[]}>}
   */
  getDetailsForOrder(order) {
    return this._getDetails(order.organizationId, order.id)
      .then((details) => {
        order.activePlans = details.plans;
        order.activeCoupons = details.coupons;
        order.customQuote = details.custom_quote;
        return order;
      })
      .catch((err) => {
        throw err;
      });
  }

  _getDetails(orgId, orderId) {
    let path = '/v1/orders/details';
    if (orgId) {
      path += `/${orgId}`;
    }
    if (orderId) {
      path += `?orderId=${orderId}`;
    }
    return this._httpService.get(this._uri(path)).then((data) => {
      let custom_quote = {};
      try {
        custom_quote = JSON.parse(data.custom_quote);
      } catch (err) {
        // Do nothing, problem parsing the custom quote string as a json
        this.$log.warn(err);
      }
      return {
        plans: data.plans.map((plan) => this._orderPlanCodec.decode(plan)),
        coupons: data.coupons.map((coupon) =>
          this._orderCouponCodec.decode(coupon),
        ),
        custom_quote: custom_quote,
      };
    });
  }

  /**
   * Gets the yearly pricing (e.g. "this year," "next year," and "in two years")
   * @param orgType {string}
   * @param renewal {boolean}
   * @param virtual {boolean}
   * @param date {moment}
   * @return {Promise<OrderPrice[]>}
   */
  getPrices(orgType, renewal, virtual, date) {
    return this._httpService
      .get(
        this._uri(
          `/v1/orders/prices?org_type=${orgType}&renewal=${renewal}&virtual=${virtual}&date=${date.toISOString()}`,
        ),
      )
      .then((results) => {
        return results.prices.map((price) =>
          this._orderPriceCodec.decode(price),
        );
      });
  }

  /**
   * Gets the coupon ids for an order based on entered criteria (e.g. org type, renewing, etc.)
   * @param orgType {string}
   * @param renewal {boolean}
   * @param financialAid {boolean}
   * @param virtual {boolean}
   * @param start {moment}
   * @param end {moment}
   * @param [now] {moment}
   * @return {Promise<string[]>}
   */
  getCouponIds(orgType, renewal, financialAid, virtual, start, end, now) {
    return this._httpService
      .get(
        this._uri(
          `/v1/orders/coupons?org_type=${orgType}&renewal=${renewal}&financial_aid=${financialAid}&virtual=${virtual}&start=${start.toISOString()}&end=${end.toISOString()}&date=${now.toISOString()}`,
        ),
      )
      .then((results) => results.coupons);
  }

  /**
   * Send tracking information for multiple targets
   *
   * @param orderId {string} - the order ID (required)
   * @param trackingId {string} - the action being tracked, eg. 'send-button-click' (optional)
   * @param userId {string} - the user's ID (optional)
   * @param source {string} - the page the user was on before the action took place, eg. 'order_page' (optional)
   * @param targets {array} - the email address(es) to which the referral email is being sent (optional)
   * @return {Promise<string[]>}
   */
  track({ orderId, trackingId, userId, source, targets }) {
    let itemsToTrack = this.formatItemsToTrack(
      trackingId,
      userId,
      source,
      targets,
    );

    return this._httpService.post(this._uri(`/v1/orders/${orderId}/track`), {
      order_trackings: itemsToTrack,
    });
  }

  /**
   * Send multiple types of tracking information for multiple targets
   *
   * {
   *   trackingId {string} - the action being tracked, eg. 'send-button-click' (optional)
   *   userId {string} - the user's ID (optional)
   *   source {string} - the page the user was on before the action took place, eg. 'order_page' (optional)
   *   targets {array} - the email address(es) to which the referral email is being sent (optional)
   * }
   * @param orderId {string} - the order ID (required)
   * @param trackingInfo {array[{orderId:string, trackingId:string, userId: string, source:string, targets: array}]}
   * @return {Promise<string[]>}
   */
  trackAll(orderId, trackingInfo) {
    let itemsToTrack = [];
    trackingInfo.forEach((info) => {
      this.formatItemsToTrack(
        info.trackingId,
        info.userId,
        info.source,
        info.targets,
      ).forEach((track) => itemsToTrack.push(track));
    });

    return this._httpService.post(this._uri(`/v1/orders/${orderId}/track`), {
      order_trackings: itemsToTrack,
    });
  }

  formatItemsToTrack(trackingId, userId, source, targets) {
    let templateObj = {
      tracking_id: trackingId,
      target: null,
      source,
      user_id: userId,
    };
    if (targets.length) {
      return targets.map((target) => {
        return {
          ...templateObj,
          target,
        };
      });
    } else {
      return [templateObj];
    }
  }

  getTrackingDetails(orderId) {
    return this._httpService
      .get(this._uri(`/v1/orders/${orderId}/track`))
      .then((data) => {
        return data.map((event) => this._orderTrackingCodec.decode(event));
      });
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }

  /**
   * Uses the v2 backend base
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _v2uri(path) {
    return `${this._environment.serverv2UrlBase}${path}`;
  }
}
