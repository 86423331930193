'use-strict';

import moment from 'moment';

import AuthService from '../../services/auth/auth.service';
import CacheService from '../../services/cache/cache.service';
import ContractService from '../../services/contract/contract.service';
import BreadcrumbService from '../../services/breadcrumb-service/breadcrumb.service';
import OrganizationService from '../../services/organization/organization.service';
import AnalyticsService from '../../services/analytics/analytics.service';
import OrderService from '../../services/order/order.service';
import StaticContentService from '../../services/static/static-content.service';
import { OrderManager } from '../../model/domain/order-manager';
import { OrganizationUsageStats } from '../../model/domain/organization-usage-stats';
import IncentiveAssignmentLimitV1Controller from '../../components/incentive-assignment-limit-v1-dialog/incentive-assignment-limit-v1-dialog.controller';
import AdminInvitesTeachersDialogController from '../../components/admin-invites-teachers-dialog/admin-invites-teachers-dialog.controller';
import { Locations } from '../../../app/services/mixpanel/mixpanel.service';

/**
 * @param {AuthService} AuthService
 * @param {CacheService} CacheService
 * @param {ContractService} ContractService
 * @param {BreadcrumbService} BreadcrumbService
 * @param {OrganizationService} OrganizationService
 * @param {AnalyticsService} AnalyticsService
 * @param {OrderService} OrderService
 * @param {StaticContentService} StaticContentService
 */
export default class MetricListController {
  constructor(
    $q,
    $filter,
    $location,
    $mdSidenav,
    $log,
    $mdDialog,
    AuthService,
    CacheService,
    ContractService,
    BreadcrumbService,
    OrganizationService,
    AnalyticsService,
    OrderService,
    StaticContentService,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$mdSidenav = $mdSidenav;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this.$q = $q;
    this.$log = $log;
    this.$filter = $filter;
    this.$location = $location;
    /** @type {OrderManager} */
    this._orderManager = new OrderManager(
      $q,
      $log,
      $filter,
      $mdDialog,
      $location,
      AuthService,
      OrderService,
      OrganizationService,
      BreadcrumbService,
      CacheService,
      ContractService,
    );

    this._incentiveAssignmentLimitV1 =
      IncentiveAssignmentLimitV1Controller.show;
    this._adminInvitesTeachersDialog =
      AdminInvitesTeachersDialogController.show;

    this._user = this._authService.authData;

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
        location: Locations.METRICS,
        userId: this._user.id,
        isAdmin: this._user.isAdmin,
      },
    });
  }

  get errorMessage() {
    return 'Sorry, there was a problem loading the metrics for this organization';
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  /**
   * @returns {boolean}
   */
  isUserAdminOfAnyContract() {
    return this._user.isAdminOfAnyContract();
  }

  /**
   * @param {string} organizationId
   * @param {OrganizationUsageStats} usageStats
   * @param {Order} order
   * @returns {boolean}
   */
  shareMetrics(organizationId, usageStats, order) {
    this._analyticsService.emailMetrics(organizationId, order);
    this._orderManager.shareMetrics(
      order,
      undefined,
      undefined,
      Locations.METRICS,
      false,
      usageStats,
    );
  }

  /**
   * @param {Organization} organization
   */
  inviteUsers(organization) {
    this._incentiveAssignmentLimitV1(
      Locations.METRICS,
      this.$mdDialog,
      this._user,
      [organization],
    );
  }

  /**
   * @param {Organization} organization
   */
  nudgeTeachers(organization) {
    this._adminInvitesTeachersDialog(
      this.$mdDialog,
      organization && organization.id,
      Locations.METRICS,
    );
  }

  trackMetricsAllSchoolsTableSort(sort) {
    this._analyticsService.metricsAllSchoolsTableSorted(sort);
  }

  /**
   * @returns {string}
   */
  get statsTimePeriod() {
    // stats data received from the data warehouse resets every July 1 <current_year>

    let academicMonth = '07';
    let academicDay = '01';
    let thisYear = moment().year();
    let previousYear = moment().subtract(1, 'years').year();

    if (moment().isAfter(`${thisYear}-${academicMonth}-${academicDay}`)) {
      return moment(`${thisYear}-${academicMonth}-${academicDay}`).format(
        'MMM Do YYYY',
      );
    } else {
      return moment(`${previousYear}-${academicMonth}-${academicDay}`).format(
        'MMM Do YYYY',
      );
    }
  }

  goToWatchTutorialVideo() {
    this._staticContentService.goToGettingStartedVideoTwo();
  }
}
