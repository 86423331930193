'use strict';

export default class Validation {
  static get EmailPattern() {
    return '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  }

  static get EmailRegex() {
    return new RegExp(Validation.EmailPattern);
  }

  static get EmailMaxSize() {
    return 190;
  }

  static get EmailMinSize() {
    return 5;
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static hasAtSign(str) {
    return str && str.indexOf('@') > -1;
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static isValidEmailLength(str) {
    return (
      str &&
      str.length >= Validation.EmailMinSize &&
      str.length <= Validation.EmailMaxSize
    );
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static isValidEmailFormat(str) {
    return str && Validation.EmailRegex.test(str);
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static isValidEmail(str) {
    return (
      Validation.isValidEmailLength(str) && Validation.isValidEmailFormat(str)
    );
  }

  static get UsernamePattern() {
    return '^[a-zA-Z0-9][^@]*$';
  }

  static get InviteCodePattern() {
    return '^[a-zA-Z\\d]+$';
  }

  static get InviteCodeRegex() {
    return new RegExp(Validation.InviteCodePattern);
  }

  static get UsernameRegex() {
    return new RegExp(Validation.UsernamePattern);
  }

  static get UsernameMaxSize() {
    return 36;
  }

  static get UsernameMinSize() {
    return 2;
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static isValidUsernameLength(str) {
    return (
      str &&
      str.length >= Validation.UsernameMinSize &&
      str.length <= Validation.UsernameMaxSize
    );
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static isValidUsernameFormat(str) {
    return str && Validation.UsernameRegex.test(str);
  }

  /**
   * @param str {string}
   * @returns {boolean}
   */
  static isValidUsername(str) {
    return (
      Validation.isValidUsernameLength(str) &&
      Validation.isValidUsernameFormat(str)
    );
  }

  /**
   * @param str {string}
   * @returns {string | null}
   */
  static validateEmail(str) {
    if (!Validation.isValidEmailFormat(str)) {
      return 'email-format';
    } else if (!Validation.isValidEmailLength(str)) {
      return 'email-length';
    }
    return null;
  }

  /**
   * @param str {string}
   * @returns {string | null}
   */
  static validateUsername(str) {
    if (!Validation.isValidUsernameFormat(str)) {
      return 'username-format';
    } else if (!Validation.isValidUsernameLength(str)) {
      return 'username-length';
    }
    return null;
  }

  /**
   * @param str {string}
   * @returns {string | null}
   */
  static validateUsernameOrEmail(str) {
    if (Validation.hasAtSign(str)) {
      return Validation.validateEmail(str);
    } else {
      return Validation.validateUsername(str);
    }
  }

  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static get UserFirstNameMaxSize() {
    return 100;
  }

  static get UserLastNameMaxSize() {
    return 100;
  }

  /**
   * @param str {string}
   * @return {boolean}
   */
  static validateChatMessage(str) {
    return str.length <= Validation.ChatMessageMaxSize;
  }

  static get ChatMessageMaxSize() {
    return 2000;
  }
}
