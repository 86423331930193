'use strict';

import Debouncer from '../../model/util/debouncer';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import MoveQuestionDialogController from './move-question-dialog.controller';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';
import SaveStates from '../../components/saving-indicator/save-states';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';
import CkAnimations from '../../model/util/ck-animations';
import Assignment from '../../model/domain/assignment';
import AssignmentManager from '../../model/domain/assignment-manager';
import ShareOrderDialogController from '../../components/share-order-dialog/share-order-dialog.controller';
import Contract from '../../model/domain/contract';
import Order from '../../model/domain/order';
import ReferralDialogController from '../../components/referral-dialog/referral-dialog.controller';
import ShareDialogController from '../../components/share-dialog/share-dialog.controller';
import { ABTest } from '../../services/ab-test/ab-test-service';
import PreviewStudentUtil from '../../model/util/preview-student-util';

let access = [
  {
    value: undefined,
    option: 'Private - Only your students can access',
  },
  {
    value: 'public',
    option: 'Shared - Colleagues can view and make a copy',
  },
];

export default class AssignmentEditController {
  constructor(
    $document,
    $filter,
    $location,
    $log,
    $q,
    $stateParams,
    $window,
    BreadcrumbService,
    $mdDialog,
    $scope,
    $mdToast,
    CacheService,
    AssignmentService,
    AuthService,
    AnalyticsService,
    GradeExportService,
    AnswerExportService,
    ExportService,
    StorageService,
    PlatformHeaderService,
    OrderService,
    OrganizationService,
  ) {
    'ngInject';

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {GradeExportService} */
    this._gradeExportService = GradeExportService;
    /** @type {AnswerExportService} */
    this._answerExportService = AnswerExportService;
    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;

    this.$document = $document;
    this.$filter = $filter;
    this.$location = $location;
    this.$log = $log;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$q = $q;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$window = $window;

    this._confirmDialog = ConfirmDialogController.show;
    this._moveQuestionDialog = MoveQuestionDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._helpDialog = ViewHelpDialogController.show;

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      this._gradeExportService,
      this._answerExportService,
      this._analyticsService,
      this._exportService,
    );

    this._assignmentId = $stateParams.assignmentId;
    this._lastRosterId = $stateParams.lastRosterId;
    this.focusName = false;
    this.showShareModal = this.$stateParams.showShareModal ? true : false;

    this._platformHeaderService.setAssignmentId(this._assignmentId);

    /** @type {Roster[]} */
    this._rosters = undefined;
    /** @type {Assignment} */
    this._assignment = undefined;
    this._userSchools = null;

    this._changeDebouncer = new Debouncer(
      1000,
      5000,
      () => this._commit(),
      $scope,
      $window,
    );

    this._assignmentSheetConfigMap = new Map();

    this.name = '';
    this.description = '';
    this.access = access[0];
    this.grades = [];
    this.subjects = [];
    this.contracts = [];
    this.schoolsInDistrict = null;

    this.saveState = null;
    this.questionScores = [];
    this.accessChanged = false;

    this.privateAccessConfirmationMessage =
      'Are you sure you want to set this assignment to private? <br> <br> Anyone you shared it with previously will no longer be able to view your copy.';

    this._moveQuestionManager = new MoveQuestionManager(this);
    this._shareOrderDialog = ShareOrderDialogController.show;
    this._referralDialog = ReferralDialogController.show;
    this._shareDialog = ShareDialogController.show;

    $scope.$on('$destroy', () => this._destroy());

    if (this._authService.authData) {
      /** @type {boolean} */
      this.isStudent = this._authService.authData.isStudent;
      /** @type {boolean} */
      this.isTeacher = this._authService.authData.isTeacher;
      /** @type {boolean} */
      this.isFreeUser = this._authService.authData.isFree;
      /** @type {boolean} */
      this.isProUser = this._authService.authData.isPro;
    }

    this._init();
  }

  _init() {
    this.$q
      .all({
        user: this._cacheService.getUser(),
        assignment: this._cacheService.getAssignmentForUser(this._assignmentId),
        rosters: this._cacheService.getRostersForUser(),
        proInfo: this._cacheService.getProInfo(),
        contracts: this._cacheService.getContracts(false, this.isTeacher),
        schools: this._cacheService.getSchools(false),
      })
      .then(({ assignment, rosters, user, proInfo, contracts, schools }) => {
        this._initData(assignment, rosters);

        this.focusName = this.$stateParams.isNew ? 'select' : false;

        this._user = user;
        this._proInfo = proInfo;
        this.activeContract = Contract.ActiveContract(contracts);
        this.school = schools.find(
          (school) =>
            this.activeContract && school.contractId === this.activeContract.id,
        );
        this._userSchools = schools;
        this.contracts = contracts;

        this.showContextualShare(assignment);

        if (this.school) {
          this._orderService
            .getForOrganization(this.school.id)
            .then((orders) => {
              this.activeOrder = Order.findActiveOrder(orders);
            });
          if (this.school.parentOrganizationId) {
            this.$q
              .all({
                districtOrders: this._orderService.getForOrganization(
                  this.school.parentOrganizationId,
                ),
                schoolsInDistrict:
                  this.school.parentOrganizationId &&
                  this._cacheService.getChildOrganizationCount(
                    this.school.parentOrganizationId,
                  ),
              })
              .then(({ districtOrders, schoolsInDistrict }) => {
                this.activeDistrictOrder =
                  Order.findActiveOrder(districtOrders);
                this.schoolsInDistrict = schoolsInDistrict.count;
              });
          }
        }
      })
      .catch((err) => {
        this._error = err;
        this.$log.error('error', err);
        return this.$q.reject(err);
      });
  }

  /**
   * @param assignment {Assignment}
   * @param [rosters] {Map<Roster>}
   */
  _initData(assignment, rosters) {
    this._assignment = assignment;

    this.name = this._assignment.name;
    this.description = this._assignment.brief;
    this.access = access.find((x) => x.value === this._assignment.access);
    this.grades = this._assignment.grades.filter((grade) => {
      return Assignment.Grades.some((stockGrade) => stockGrade === grade);
    });
    this.subjects = this._assignment.subjects.filter((subject) => {
      return Assignment.Subjects.some(
        (stockSubject) => stockSubject === subject,
      );
    });
    this.questionScores = this._assignment.questions.map((q) => q.points);

    if (rosters) {
      this._rosters = this._assignment.rosters
        .map((id) => rosters.get(id))
        .filter((roster) => {
          return angular.isDefined(roster);
        });
    }

    this._assignmentSheetConfigMap.clear();
  }

  _destroy() {
    this._platformHeaderService.setAssignmentId(undefined);
  }

  /**
   * @return {MoveQuestionManager}
   */
  get moveQuestionManager() {
    return this._moveQuestionManager;
  }

  /**
   * * Checks if the Contextual Share Assignment Modal was seen on assignment creation
   * @param assignment {string}
   */
  hasSeenContextualShare(assignment) {
    if (this._storageService.getContextualShareSeen(assignment.id)) {
      return true;
    } else {
      this._storageService.setContextualShareSeen(assignment.id, true);
      return false;
    }
  }

  /**
   * * Displays Share Assignment Modal if flag is set
   * @param assignment {string}
   */
  showContextualShare(assignment) {
    if (!this.hasSeenContextualShare(assignment) && this.showShareModal) {
      this._shareDialog(this.$mdDialog, assignment, Locations.ASSIGNMENT_EDIT);
      this._analyticsService.sendEvent({
        eventTag: 'contextualDialog_shareAssignmentSeen',
        properties: {
          assignmentId: assignment.id,
          belongsToFolder: assignment.folder ? true : false,
          location: Locations.ASSIGNMENT_EDIT,
        },
      });
    }
  }

  warnPrivateAccess(field) {
    if (this.access.value !== 'public') {
      this._confirmDialog(this.$mdDialog, this.privateAccessConfirmationMessage)
        .then(() => {
          this.fieldUpdated(field);
        })
        .catch(() => {
          this.access = access.find((x) => x.value === 'public');
        });
    } else {
      this.fieldUpdated(field);
    }
  }

  fieldUpdated(field) {
    if (field) {
      field.$touched = true;
    }

    var valid =
      this.$scope.metadataForm.$valid && this.$scope.questionScores.$valid;

    this._changeDebouncer.tick();
    this._changeDebouncer.valid = valid;

    if (valid) {
      this.saveState = this.UNSAVED;
    } else {
      this.saveState = this.SAVE_ERROR;
    }
  }

  /**
   * Calls $state.go, but pops up an intermediate dialog if there is outstanding invalid work.
   *
   * @param to {string}
   * @param [params] {object}
   * @param [back] {boolean}
   * @param [replace] {boolean}
   */
  navigate(to, params, back, replace) {
    if (this.saveState === this.SAVE_ERROR) {
      this._confirmDialog(
        this.$mdDialog,
        '<p>Some of your changes have not been saved.</p><div>Are you sure you want to leave?</div>',
      ).then(() => {
        this._go(to, params, back, replace);
      });
    } else {
      this._changeDebouncer.flush();
      this._go(to, params, back, replace);
    }
  }

  _go(to, params, back, replace) {
    if (back) {
      this._breadcrumbService.goBack(
        to,
        params,
        angular.isUndefined(replace) ? true : replace,
      );
    } else {
      this._breadcrumbService.go(to, params, replace);
    }
  }

  navToEditQuestion(questionId) {
    this.navigate('root.account.assignment-question', {
      assignmentId: this.assignment.id,
      questionId: questionId,
    });
  }

  navToAssignmentRosters() {
    this.navigate(
      'root.account.assignment-rosters',
      {
        assignmentId: this._assignmentId,
      },
      undefined,
      true,
    );
  }

  navToSessionWork(rosterId) {
    if (!rosterId) {
      rosterId = this._lastRosterId || this.defaultRosterId;
    }

    this.navigate(
      'root.account.session.work',
      {
        assignmentId: this.assignment.id,
        rosterId: rosterId,
      },
      undefined,
      true,
    );

    if (rosterId) {
      this._analyticsService.tappedViewStudentWork(
        this._assignmentId,
        Locations.ASSIGNMENT_EDIT_ROSTER,
      );
    } else {
      this._analyticsService.tappedViewStudentWork(
        this._assignmentId,
        Locations.ASSIGNMENT_EDIT_TOP,
      );
    }
  }

  /**
   * @return {string}
   */
  get defaultRosterId() {
    return this.rosters && this.rosters[0] && this.rosters[0].id;
  }

  /**
   * Commits any changes to the metadata of this assignment
   * @private
   */
  _commit() {
    this.accessChanged =
      this._assignment.access !== this.access.value ? true : false;

    this._assignment.name = this.name;
    this._assignment.access = this.access.value;
    this._assignment.grades = this.grades;
    this._assignment.subjects = this.subjects;
    this._assignment.brief = this.description;

    const questionUpdates = this._assignment.questions.map((q, index, arr) => {
      if (q.points === this.questionScores[index]) {
        return null;
      }

      this.questionScores[index] = q.points;
      const beforeQuestionId =
        index < arr.length - 1 ? arr[index + 1].id : null;
      return this._assignmentService.updateQuestion(
        this._assignment.id,
        q,
        beforeQuestionId,
      );
    });

    this.$q
      .all(
        questionUpdates.concat([
          this._cacheService.updateAssignmentForUser(this._assignment),
        ]),
      )
      .then(() => {
        this.saveState = this.SAVED;

        if (this.accessChanged) {
          if (this._assignment.access === 'public') {
            this.$mdToast.show(
              this.$mdToast
                .simple()
                .textContent(
                  `${this.name} has been added to your Shared Assignments Page`,
                )
                .position('bottom right'),
            );
          } else {
            this.$mdToast.show(
              this.$mdToast
                .simple()
                .textContent(`${this.name} has been set to private`)
                .position('bottom right'),
            );
          }
        }
      })
      .catch((err) => {
        this.saveState = this.SAVE_ERROR;
        throw err;
      });
  }

  /**
   * @returns {Assignment}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @returns {Array.<Roster>}
   */
  get rosters() {
    return this._rosters;
  }

  get error() {
    return this._error;
  }

  get loading() {
    return !this.assignment && !this.error;
  }

  get gradeOptions() {
    return Assignment.Grades;
  }

  get subjectOptions() {
    return Assignment.Subjects;
  }

  get accessOptions() {
    return access;
  }

  get SAVED() {
    return SaveStates.SAVED;
  }

  get UNSAVED() {
    return SaveStates.UNSAVED;
  }

  get SAVE_ERROR() {
    return SaveStates.SAVE_ERROR;
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._proInfo;
  }

  /**
   * @return {boolean}
   */
  get hasGradeExport() {
    return this.pro && this.pro.hasGradeExport;
  }

  /**
   * @return {boolean}
   */
  get hasFoldersFeature() {
    return this.pro && this.pro.hasFoldersFeature;
  }

  /**
   * @return {boolean}
   */
  get hasCopiedQuestions() {
    return this._storageService.copyQuestionsRequest;
  }

  /**
   * @return {string}
   */
  get assignmentEdit() {
    return Locations.ASSIGNMENT_EDIT;
  }

  /**
   * @return {boolean}
   */
  get isFreeTrial() {
    return this.activeContract && this.activeContract.isTrial;
  }

  /**
   * @return {boolean}
   */
  get isProSchool() {
    return this.activeContract && this.activeContract.isProSchool;
  }

  /**
   * @return {boolean}
   */
  get isProDistrict() {
    return this.activeContract && this.activeContract.isProDistrict;
  }

  assignmentSheetConfigForIndex(index) {
    if (!this._assignmentSheetConfigMap.has(index)) {
      this._assignmentSheetConfigMap.set(index, {
        readonly: true,
        thumbnail: true,
        index: index,
        target: this.assignment,
      });
    }

    return this._assignmentSheetConfigMap.get(index);
  }

  /**
   * @return {boolean}
   */
  isACoTeacher() {
    return (
      this._authService.isACoTeacher() &&
      this._authService.coTeacherAuthData &&
      this._authService.coTeacherAuthData.id !==
        (this.assignment && this.assignment.ownerId)
    );
  }

  // ---------- Dialogs ------------------------------------------

  chooseMoveQuestion(question) {
    this._moveQuestionDialog(this.$mdDialog, this.assignment, question.id).then(
      (beforeQuestionId) => {
        this.moveQuestion(question, beforeQuestionId);
      },
    );
  }

  // ---------- Assignment Modification Methods ------------------

  chooseCopyQuestion(question) {
    this._storageService.copyQuestionsRequest = {
      from_assignment_id: this._assignment.id,
      question_ids: [question.id],
    };

    var index = this._assignment.indexForQuestionId(question.id);
    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent(`Slide ${index + 1} copied to the clipboard`)
        .position('bottom right'),
    );
  }

  chooseCopyAllQuestions() {
    this._storageService.copyQuestionsRequest = {
      from_assignment_id: this._assignment.id,
      question_ids: this._assignment.questions.map((q) => q.id),
    };

    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent('All slides copied to the clipboard')
        .position('bottom right'),
    );
  }

  choosePasteAllQuestions(question) {
    if (!this.hasCopiedQuestions) {
      return;
    }

    let { from_assignment_id, question_ids } =
      this._storageService.copyQuestionsRequest;
    let questionAfterThis = this.assignment.questionAfter(question.id);
    let beforeQuestionId = questionAfterThis && questionAfterThis.id;
    let plural = question_ids.length > 1 ? 's' : '';
    let promise = this._modify(() =>
      this._assignmentService.duplicateQuestionsAcrossAssigments(
        from_assignment_id,
        this.assignment.id,
        question_ids,
        beforeQuestionId,
      ),
    )
      .then(() => {
        // Resets the session data cache to ensure assignment works have right questions
        this._cacheService.clearSessionData();
        // show success toast
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent(
              `${question_ids.length} slide${plural} pasted into ${this.assignment.name}`,
            )
            .position('bottom right'),
        );
      })
      .catch(() => {
        // show failure toast
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent('something went wrong')
            .position('bottom right'),
        );
      });

    this.loadingDialog('.assignment-edit .questions', promise);
  }

  share() {
    this._changeDebouncer.flush();
    this._assignmentManager.share(this.assignment).catch(() => {
      this.access = access.find((x) => x.value === this._assignment.access);
    });
  }

  shareAssignment() {
    this._analyticsService.newShareButtonClicked(this.assignment.id);
    this.share();
  }

  exportContentToPDF() {
    this._assignmentManager.exportContentToPDF(this.assignment);
  }

  /**
   * @param assignment {Assignment}
   */
  moveAssignmentToFolder(assignment) {
    this._assignmentManager.moveAssignmentToFolder(this.assignment);
  }

  /**
   * Duplicates an assignment
   */
  duplicate() {
    this._assignmentManager.checkAssignmentLimit(
      Locations.ASSIGNMENT_EDIT_NAV,
      this.assignment.id,
      () => {
        this._assignmentManager.duplicate(
          this.assignment.id,
          this.assignment.name,
        );
      },
    );
  }

  /**
   * Deletes an assignment
   */
  delete() {
    this._assignmentManager.delete(this.assignment).then(() => {
      this.navigate('root.account.home', { source: '' }, false, true);
    });
  }

  /**
   * @param [selector] {string} A string specifying an <md-content> dom element
   * @param [promise] {Promise}
   */
  loadingDialog(selector, promise) {
    this._loadingDialog(this.$mdDialog, promise, this.$document, selector);
  }

  /**
   * Executes a change and then reloads the assignment
   * @param f {function} Function which returns a
   */
  _modify(f) {
    this._changeDebouncer.flush();

    this.saveState = this.UNSAVED;

    var promise = f();

    if (!(promise && angular.isFunction(promise.then))) {
      promise = this.$q.resolve();
    }

    return promise
      .then(() => {
        return this._cacheService.getAssignmentForUser(
          this._assignmentId,
          true,
        );
      })
      .then((assignment) => {
        this._initData(assignment);
        this.saveState = this.SAVED;
      })
      .catch((err) => {
        this.saveState = this.SAVE_ERROR;
        throw err;
      });
  }

  addQuestion() {
    let promise = this._modify(() =>
      this._assignmentService.addQuestion(this.assignment.id),
    ).then(() => {
      // Resets the session data cache to ensure assignment works have right questions
      this._cacheService.clearSessionData();
    });

    this.loadingDialog('.assignment-edit .questions', promise);
  }

  deleteQuestion(question) {
    let questionNumber = this._assignment.questionNumberForId(question.id);
    let deleteMessage = `All work for all of the students on slide ${questionNumber} will be deleted. This cannot be undone. Are you sure you want to continue?`;

    this._confirmDialog(this.$mdDialog, deleteMessage).then(() => {
      let promise = this._modify(() =>
        this._assignmentService.removeQuestion(this.assignment.id, question.id),
      ).then(() => {
        // Resets the session data cache to ensure assignment works have right questions
        this._cacheService.clearSessionData();
      });

      this.loadingDialog('.assignment-edit .questions', promise);
    });
  }

  duplicateQuestion(question) {
    let beforeQuestion = this.assignment.questionAfter(question.id);
    this.loadingDialog(
      '.assignment-edit .questions',
      this._modify(() => {
        return this._assignmentService.duplicateQuestion(
          this.assignment.id,
          question.id,
          beforeQuestion ? beforeQuestion.id : null,
        );
      }),
    );
  }

  /**
   * @param question {AssignmentQuestion}
   * @param beforeQuestionId {string}
   */
  moveQuestion(question, beforeQuestionId) {
    this.loadingDialog(
      '.assignment-edit .questions',
      this._modify(() => {
        return this._assignmentService.updateQuestion(
          this.assignment.id,
          question,
          beforeQuestionId,
        );
      }),
    );
  }

  exportGrades() {
    this._assignmentManager.exportGrades(
      this.assignment,
      this._rosters,
      this.pro,
    );
  }

  exportAnswers() {
    this._assignmentManager.exportAnswers(
      this.assignment,
      this._rosters,
      this.pro,
    );
  }

  showHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.AssignmentEdit);
  }

  get showHelpMessage() {
    if (!this._showHelpMessage && this._user) {
      this._showHelpMessage = this._user.isNewUser;
    }
    return this._showHelpMessage;
  }

  makeAddSlidePulse() {
    CkAnimations.makeElementPulse(
      '.assignment-edit .questions-container .add-question',
    );
  }

  makeEditSlidePulse() {
    CkAnimations.makeElementPulse(
      '.assignment-edit .questions-container .question-shell .question',
    );
  }

  makeAssignRosterPulse() {
    CkAnimations.makeElementPulse('.assignment-edit .nav-toggle .middle');
  }

  openStudentPreview() {
    PreviewStudentUtil.openStudentPreview({
      analyticsService: this._analyticsService,
      pageLocation: Locations.ASSIGNMENT_EDIT_NAV,
      assignment: this._assignment,
      assignmentService: this._assignmentService,
      loadingDialog: this._loadingDialog,
      $mdDialog: this.$mdDialog,
      $location: this.$location,
      $window: this.$window,
    });
  }
}

class MoveQuestionManager {
  /**
   * @param ctrl {AssignmentEditController}
   */
  constructor(ctrl) {
    this._ctrl = ctrl;
    this._draggingOverQuestionId = undefined;
    this._draggingOverQuestionSide = undefined;
  }

  /**
   * @param event {jQuery.Event}
   * @param questionId {string}
   */
  onDragStart(event, questionId) {
    // Set the drag image
    let element = angular.element(`#${questionId} .question`);
    event.dataTransfer.setDragImage(
      element[0],
      event.offsetX - 12,
      event.offsetY - 12,
    );

    // Remember what question is being dragged
    event.dataTransfer.setData('classkick/questionId', questionId);
  }

  /**
   * @param event {jQuery.Event}
   * @param questionId {string}
   */
  onDragOver(event, questionId) {
    event.preventDefault();

    let side = this._determineSide(event.originalEvent);

    if (
      this._draggingOverQuestionId === questionId &&
      this._draggingOverQuestionSide === side
    ) {
      return;
    } else {
      this._draggingOverQuestionId = questionId;
      this._draggingOverQuestionSide = side;

      this._addBarTo(side, questionId);
    }
  }

  /**
   * @param event {jQuery.Event}
   * @param questionId {string}
   */
  onDragLeave(event, questionId) {
    if (this._draggingOverQuestionId === questionId) {
      this._draggingOverQuestionId = undefined;
    }

    this._removeBarFrom(questionId);
  }

  /**
   * @param event {jQuery.Event}
   * @param targetQuestion {AssignmentQuestion}
   */
  onDrop(event, targetQuestion) {
    let sourceQuestionId = event.dataTransfer.getData('classkick/questionId');
    let sourceQuestion = this._ctrl.assignment.questions.find(
      (question) => question.id === sourceQuestionId,
    );

    let targetQuestionIndex = this._ctrl.assignment.indexForQuestionId(
      targetQuestion.id,
    );
    let nextQuestionIndex = targetQuestionIndex + 1;
    let nextQuestion =
      this._ctrl.assignment.questionForIndex(nextQuestionIndex);

    let side = this._determineSide(event);

    if (sourceQuestionId !== targetQuestion.id && side === 'left') {
      this._ctrl.moveQuestion(sourceQuestion, targetQuestion.id);
    } else if (
      nextQuestion &&
      sourceQuestionId !== nextQuestion.id &&
      side === 'right'
    ) {
      this._ctrl.moveQuestion(sourceQuestion, nextQuestion.id);
    } else if (!nextQuestion && side === 'right') {
      this._ctrl.moveQuestion(sourceQuestion, null);
    }

    // Makes sure the placement UI is removed
    this._removeBarFrom(targetQuestion.id);
  }

  /**
   * @return {string}
   */
  _determineSide(event) {
    return event.offsetX < this.thumbnailMiddleX ? 'left' : 'right';
  }

  /**
   * @param side {string} left | right
   * @param questionId {string}
   */
  _addBarTo(side, questionId) {
    let question = angular.element(`#${questionId} .move-question-bar`);

    if (side === 'right') {
      question.removeClass('left');
      question.addClass('right');
    } else {
      question.removeClass('right');
      question.addClass('left');
    }
  }

  _removeBarFrom(questionId) {
    let question = angular.element(`#${questionId} .move-question-bar`);
    question.removeClass('left');
    question.removeClass('right');
  }

  get thumbnailMiddleX() {
    if (!this._thumbnailMiddleX) {
      let question = angular.element('.question');
      this._thumbnailMiddleX = question.outerWidth() / 2;
    }
    return this._thumbnailMiddleX;
  }
}
