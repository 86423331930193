'use strict';

import Organization, {
  OrganizationTypes,
} from '../../model/domain/organization';
import UpdateUserSchoolDialogTemplate from './update-user-school-dialog.html';

export default class UpdateUserSchoolDialogController {
  /**
   * @ngInject
   */
  constructor(
    $mdDialog,
    GooglePlacesService,
    OrganizationService,
    IPStackService,
    title,
    explanation,
    defaultOrganization,
    preventClose,
  ) {
    this.$mdDialog = $mdDialog;

    /** @type {GooglePlacesService} */
    this._googlePlacesService = GooglePlacesService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {IPStackService} */
    this._ipStackService = IPStackService;

    this._state = this.JoinSchool;
    this._loading = false;

    this._schoolName = defaultOrganization ? defaultOrganization.name : '';
    this._school = defaultOrganization;

    this.cleverSelected = false;

    this._newSchoolLocationDisplay = '';
    this._newSchoolLocation = undefined;

    this._title = title;
    this._explanation = explanation;
    this._preventClose = preventClose;
    this.organizationType = OrganizationTypes.UserDefinedOrganizationTypes[0];
  }

  /**
   * @return {Array}
   */
  get organizationOptions() {
    return OrganizationTypes.UserDefinedOrganizationTypes;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  get JoinSchool() {
    return 'join_school';
  }

  get AddSchool() {
    return 'add_school';
  }

  /**
   * @return {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @return {string}
   */
  get title() {
    return this._title;
  }

  /**
   * @return {string}
   */
  get explanation() {
    return this._explanation;
  }

  /**
   * @return {boolean}
   */
  get preventClose() {
    return this._preventClose;
  }

  /**
   * @return {string}
   */
  get schoolName() {
    return this._schoolName;
  }

  /**
   * @param value {string}
   */
  set schoolName(value) {
    this._schoolName = value;
  }

  /**
   * @return {undefined|Organization}
   */
  get school() {
    return this._school;
  }

  /**
   * @param value {undefined|Organization}
   */
  set school(value) {
    this._school = value;
  }

  /**
   * @return {string}
   */
  get newSchoolLocationDisplay() {
    return this._newSchoolLocationDisplay;
  }

  /**
   * @param value {string}
   */
  set newSchoolLocationDisplay(value) {
    this._newSchoolLocationDisplay = value;
  }

  /**
   * @return {undefined|GooglePlacePrediction}
   */
  get newSchoolLocation() {
    return this._newSchoolLocation;
  }

  /**
   * @param value {undefined|GooglePlacePrediction}
   */
  set newSchoolLocation(value) {
    this._newSchoolLocation = value;
  }

  /**
   * fieldUpdate to perform extra validation checks
   *
   * @param field - information about the field
   * @param form - the form
   * @param skip
   */
  fieldUpdated(field, form, skip) {
    if (skip) {
      return;
    }

    field.$touched = true;

    this.cleverSelected = (this.school || {}).hasCleverProvider === true;
    if (this.cleverSelected) {
      form.schoolNameInput.$setValidity('md-clever-org', false);
    } else {
      form.schoolNameInput.$setValidity('md-clever-org', true);
    }

    if (form.$valid) {
      return true;
    } else {
      return false;
    }
  }

  joinSchool(scope) {
    if (this.cleverSelected || scope.joinSchoolForm.$invalid) {
      return;
    }

    this._loading = true;

    this.$mdDialog.hide(this.school);
  }

  addSchool(scope) {
    // Check that the input form is valid
    if (scope.enterSchoolDetailsForm.$invalid) {
      return;
    }

    this._loading = true;

    this._createSchool(this.newSchoolLocation.placeId, this.schoolName).then(
      (organization) => {
        this.$mdDialog.hide(organization);
      },
    );
  }

  /**
   * @param placeId {string}
   * @param schoolName {string}
   * @return {Promise<Organization>}
   */
  _createSchool(placeId, schoolName) {
    return this._googlePlacesService.getPlaceDetails(placeId).then((place) => {
      return this._organizationService.create(
        OrganizationTypes.School,
        schoolName,
        place.lat,
        place.lng,
        place.zip,
        place.city,
        place.state,
        place.country,
        this.organizationType.value,
      );
    });
  }

  /**
   * @param search {string}
   * @return {Array|Promise<GooglePlacePrediction[]>}
   */
  autocompleteLocation(search) {
    if (!search || search.length < 2) {
      return [];
    } else {
      this._loadingAutocompleteLocation = true;
      return this._googlePlacesService.getPredictions(search).then((result) => {
        this._loadingAutocompleteLocation = false;
        return result;
      });
    }
  }

  get loadingAutocompleteLocation() {
    return this._loadingAutocompleteLocation;
  }

  /**
   * @param search {string}
   * @return {Promise<Organization[]>}
   */
  autocompleteSchool(search) {
    this._loadingAutocompleteSchool = true;
    return this._ipStackService
      .getLocation()
      .then((location) => {
        return this._organizationService.search(
          OrganizationTypes.School,
          location.lat,
          location.lng,
          search,
        );
      })
      .then((organizations) => {
        this._loadingAutocompleteSchool = false;
        // Hack: Adds an extract empty object for the 'add new school' button
        return [...organizations, {}];
      });
  }

  get loadingAutocompleteSchool() {
    return this._loadingAutocompleteSchool;
  }

  isOrganization(obj) {
    return obj instanceof Organization;
  }

  /**
   * Launches update user school dialog
   * @param $mdDialog
   * @param title {string}
   * @param [explanation] {string}
   * @param [defaultOrganization] {Organization}
   * @param [preventClose] {boolean}
   */
  static show(
    $mdDialog,
    title,
    explanation,
    defaultOrganization,
    preventClose = false,
  ) {
    return $mdDialog.show({
      controller: UpdateUserSchoolDialogController,
      template: UpdateUserSchoolDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: !preventClose,
      locals: {
        title,
        explanation,
        defaultOrganization,
        preventClose,
      },
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
