'use strict';
import Codec from './codec';
import CourseCodec from './course-codec';
import SessionCodec from './session-codec';
import ClassCodec from './class-codec';
import GradebookRoster from '../domain/gradebook-roster';

/**
 * Encodes/decodes Gradebook roster information
 */
export default class GradebookRosterCodec extends Codec {
  constructor() {
    super();

    this._sessionCodec = new SessionCodec();
    this._courseCodec = new CourseCodec();
    this._classCodec = new ClassCodec();
  }

  /**
   * @param value {GradebookRoster}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        session: this._sessionCodec.encode(value.session),
        course: this._courseCodec.encode(value.course),
        class: this._classCodec.encode(value.class),
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookRoster}
   */
  decode(value) {
    if (value) {
      return new GradebookRoster(value.session, value.course, value.class);
    }

    return null;
  }
}
