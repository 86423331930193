'use strict';

import Element from './element';
import Point from '../point';
import Size from '../size';
import HexColors from '../../../css-constants';
import MultipleChoiceOptionControl from '../multiple-choice-option-control';
import StaticService from '../../../services/static/static.service';

export default class MultipleChoiceChild extends Element {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param center {Point}
   * @param size {Size}
   * @param options {{string, {string, string|number}}}
   * @param answer {string}
   */
  constructor(id, metadata, center, size, options, answer) {
    super(id, MultipleChoiceChild.type, metadata);

    this._location = center || new Point(100, 200);
    this._size = size || new Size(280, 84);
    this._padding = 20;
    this._options = options;
    this._answer = answer;
  }

  static get type() {
    return 'mchoice_child';
  }

  get isChildManipulative() {
    return true;
  }

  get answerDisplay() {
    return this.answer || '--';
  }

  get options() {
    return this._options;
  }

  get answer() {
    return this._answer;
  }

  set answer(value) {
    this._trackChange(() => {
      this._answer = value;
    });
  }

  get isWork() {
    return this.assignment && this.assignment.isWork;
  }

  get classCode() {
    // Multiple choice child should only be able to be rendered from an assignment work, so this should be okay
    return this.assignment && this.assignment.classCode;
  }

  get showStudentScores() {
    return this.classCode && this.classCode.showStudentScores;
  }

  createElement(root) {
    this._base = root.group();
    this._interactive = root.group();
    this._background = this._base.rect(0, 0, 0, 0);
    this._pointValue = this._base.text(0, 0, '');
    this._renderedOptions = new Map();

    if (this.classCode && this.classCode.showStudentScoresUpdated) {
      this.classCode.showStudentScoresUpdated.subscribe(
        this._showStudentScoresUpdated,
        this,
      );
    }
  }

  update(root, editable, active) {
    this._background.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      ry: 4,
      fill: HexColors.BACKGROUND_GREY,
      stroke: HexColors.CK_HEADER_GREY,
    });

    let pointValue = this._options
      .filter((option) => option.correct)
      .map((option) => option.points)
      .pop();

    this._pointValue.attr({
      x: this.location.x + 8,
      y: this.location.y + 19,
      style: this.textStyle,
      text: angular.isNumber(pointValue)
        ? pointValue === 1
          ? `${pointValue} Pt`
          : `${pointValue} Pts`
        : '',
    });

    const columnDivisor = this._options.length;

    let colWidth = (this.width - this._padding - this._padding) / columnDivisor;

    this._options.forEach((option, index) => {
      let id = `${this.id}-${index}`;
      let renderedOption = this._renderedOptions.get(id);

      if (!renderedOption) {
        renderedOption = new MultipleChoiceOptionControl(id, this, option);
        renderedOption.render(
          this._interactive,
          editable,
          active,
          this.canvas,
          this.assignment,
          this.viewerMetadata,
          this.thumbnail,
          this.moreOptionsManager,
        );
        this._renderedOptions.set(id, renderedOption);
      }

      renderedOption.location = new Point(
        this.location.x + colWidth * index + this._padding,
        this.location.y + this.height * 0.25,
      );

      renderedOption.tryUpdate();
    });
  }

  get textStyle() {
    return `
      pointer-events:none;
      font-family:GothamRoundedMedium;
      dominant-baseline:ideographic;
      line-height:115%;
      font-size:13px;
      fill: ${HexColors.CK_HEADER_GREY};
    `;
  }

  _showStudentScoresUpdated() {
    this.tryUpdate();
  }

  remove() {
    if (this.classCode) {
      this.classCode.showStudentScoresUpdated.unsubscribe(
        this._showStudentScoresUpdated,
        this,
      );
    }
    super.remove();
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {MultipleChoiceChild}
   */
  merge(other) {
    if (!angular.equals(other._options, this._options)) {
      this._renderedOptions = new Map();
    }

    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;
    this._options = other._options || this._options;
    this._answer = other._answer;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _options: this._options,
      _answer: this._answer,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {MultipleChoiceChild}
   */
  fromSnapshot(id, snapshot) {
    return new MultipleChoiceChild(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._options,
      snapshot._answer,
    );
  }
}
