'use strict';

import Codec from './codec';
import CopyQuestionsRequest from '../domain/copy-questions-request';

export default class CopyAssignmentRequestCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {CopyQuestionsRequest}
   * @returns {{from_assignment_id: string, question_ids: Array.<string>}}
   */
  encode(value) {
    return {
      from_assignment_id: value.from_assignment_id,
      question_ids: value.question_ids,
    };
  }

  /**
   * @param value {object}
   * @returns {CopyQuestionsRequest}
   */
  decode(value) {
    if (value) {
      return new CopyQuestionsRequest(
        value.from_assignment_id,
        value.question_ids,
      );
    }
    return null;
  }
}
