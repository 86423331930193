'use strict';

import Assignment, { GradeRanges } from '../../model/domain/assignment';
import VirtualCollection from '../../model/domain/virtual-collection';
import GradeRange from '../../model/domain/grade-range';
import Sorts from '../../model/domain/sorts';
import CopyAssignmentRequest from '../../model/domain/copy-assignment-request';
import CkRedirect from '../../model/domain/ck-redirect';
import { Locations } from '../../services/mixpanel/mixpanel.service';
import SignupDialogController from '../../components/signup-dialog/signup-dialog';
import AssignmentManager from '../../model/domain/assignment-manager';

let gradeRangeDictionary = {
  'PreK-2nd': new GradeRange('PreK-2nd', GradeRanges.PreKThroughSecondGrade),
  '3rd-5th': new GradeRange('3rd-5th', GradeRanges.ThirdThroughFifth),
  '6th-8th': new GradeRange('6th-8th', GradeRanges.SixthThroughEighth),
  '9th-12th': new GradeRange('9th-12th', GradeRanges.NinthThroughTwelfth),
  College: new GradeRange('College', GradeRanges.College),
};

export default class AssignmentLibraryController {
  constructor(
    $q,
    $state,
    $mdDialog,
    $mdSidenav,
    $mdToast,
    UserService,
    BreadcrumbService,
    AuthService,
    StorageService,
    AnalyticsService,
    CacheService,
    AssignmentService,
    GradeExportService,
    AnswerExportService,
    ExportService,
    environment,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.$mdSidenav = $mdSidenav;
    this.$mdToast = $mdToast;

    /** @type {UserService} */
    this._userService = UserService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {GradeExportService} */
    this._gradeExportService = GradeExportService;
    /** @type {AnswerExportService} */
    this._answerExportService = AnswerExportService;
    /** @type {ExportService} */
    this._exportService = ExportService;

    this._showSignupDialog = SignupDialogController.show;

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      this._gradeExportService,
      this._answerExportService,
      this._analyticsService,
      this._exportService,
    );

    this._environment = environment;
    this._loading = true;
    this._assignmentSheetConfigMap = new Map();
    this._virtualAssignments = undefined;
    this._assignments = undefined;
    this._subject = this._storageService.assignmentLibrarySubject
      ? this._storageService.assignmentLibrarySubject
      : undefined;
    this._subjectOptions = Assignment.Subjects;
    this._gradeRange = this._storageService.assignmentLibraryGradeRange
      ? gradeRangeDictionary[this._storageService.assignmentLibraryGradeRange]
      : undefined;
    this._query = this._storageService.assignmentLibraryQuery
      ? this._storageService.assignmentLibraryQuery
      : '';
    this._selectedLibraryOption = null;
    this._analyticsService.assignmentLibraryOpened();
    this._error = null;
    this._proInfo = null;

    if (!this.isAuthenticated) {
      this._breadcrumbService.go('root.public-assignments-library');
    } else {
      this._breadcrumbService.go('root.account.nav.public-assignments-library');
    }

    this.init();
  }

  /**
   * Loads a user's assignments and all the help requests for each assignment,
   * then configures notifications for help request updates
   */
  init() {
    this.$q
      .all({
        currentUser: this.isAuthenticated && this._userService.getUser(),
        publicAssignmentsByUser: this._userService.getPublicAssignments(
          this._environment.assignmentLibraryAccount,
        ),
        organizations:
          this.isAuthenticated &&
          this._cacheService.getOrganizations(true, true),
        proInfo: this.isAuthenticated && this._cacheService.getProInfo(),
      })
      .then(
        ({ currentUser, publicAssignmentsByUser, organizations, proInfo }) => {
          this._currentUser = currentUser;
          this._proInfo = proInfo;
          this._organizations =
            this._getActiveSchoolsAndDistrictsByAlphabeticalOrder(
              organizations,
            );
          this._libraryOptions = this._setLibraryOptions();
          this._selectedLibraryOption =
            this._setLibraryOptionFromStorageService();
          this._assignments =
            this._selectedLibraryOption && this._selectedLibraryOption._id !== 0
              ? this.handleLibrarySelect()
              : publicAssignmentsByUser.assignments;
          this._virtualAssignments = new VirtualCollection(
            this._assignments,
            50,
          );
          this._subjectOptions = [
            ...new Set(
              this.assignments &&
                this._assignments
                  .map((assignment) =>
                    assignment.subjects.map((subject) => subject.trim()),
                  )
                  .flat()
                  .sort(),
            ),
          ];
          this.resetDisplayAssignments();
          if (this.selectedLibraryOption._id === 0) {
            this._loading = false;
          }
        },
      )
      .catch((error) => {
        this._error = error;
        this._loading = false;
      });
  }

  get currentUser() {
    return this._currentUser;
  }

  get organizations() {
    return this._organizations;
  }

  set selectedLibraryOption(value) {
    this._selectedLibraryOption = value;
    this._storageService.assignmentLibrarySelection = value;
  }

  get selectedLibraryOption() {
    return this._selectedLibraryOption;
  }

  set libraryOptions(value) {
    this._libraryOptions = value;
  }

  get libraryOptions() {
    return this._libraryOptions;
  }

  get currentUsername() {
    return this.currentUser && this.currentUser.name;
  }

  get isAuthenticated() {
    return this._authService.isLoggedIn;
  }

  /**
   * @return {string}
   */
  get username() {
    return this.currentUser && this.currentUser.name;
  }

  /**
   * @return {Assignment[]}
   */
  get assignments() {
    return this._assignments;
  }

  /**
   * @return {VirtualCollection}
   */
  get virtualAssignments() {
    return this._virtualAssignments;
  }

  /**
   * @return {GradeRange[]}
   */
  get gradeRangeOptions() {
    return Object.values(gradeRangeDictionary);
  }

  /**
   * @return {string[]}
   */
  get subjectOptions() {
    return this._subjectOptions;
  }

  /**
   * @return {undefined|string}
   */
  get subject() {
    return this._subject;
  }

  /**
   * @param value {string}
   */
  set subject(value) {
    this._storageService.assignmentLibrarySubject = value;
    this._subject = value;
  }

  get ALL_SUBJECTS() {
    return 'all_subjects';
  }

  /**
   * @return {undefined|GradeRange}
   */
  get gradeRange() {
    return this._gradeRange;
  }

  /**
   * @param value {undefined|GradeRange}
   */
  set gradeRange(value) {
    this._storageService.assignmentLibraryGradeRange = value.name
      ? value.name
      : value;
    this._gradeRange = value;
  }

  get ALL_GRADES() {
    return 'all_grades';
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (
      (this._storageService.lastSeenTrialConversionBanner &&
        this._storageService.lastSeenTrialConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenRenewalConversionBanner &&
        this._storageService.lastSeenRenewalConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenAssignmentNotificationBanner &&
        this._storageService.lastSeenAssignmentNotificationBanner.showBanner ===
          true)
    );
  }

  /**
   *
   */
  resetDisplayAssignments() {
    this._storageService.assignmentLibraryQuery = this._query;

    if (this.assignments) {
      this._virtualAssignments.fullCollection = this._assignments
        .filter((assignment) => {
          return (
            this.checkSubject(this.subject, assignment) &&
            this.checkGrade(this._gradeRange, assignment) &&
            this.checkQuery(this.query, assignment)
          );
        })
        .sort((assignmentA, assignmentB) => {
          let nameA = assignmentA.name.toLowerCase();
          let nameB = assignmentB.name.toLowerCase();
          if (nameA > nameB) {
            return 1;
          } else if (nameA < nameB) {
            return -1;
          } else {
            return 0;
          }
        });
    }
  }

  checkSubject(currentSubject, assignment) {
    return (
      !currentSubject ||
      currentSubject === this.ALL_SUBJECTS ||
      assignment.subjects.some((subject) => subject === currentSubject)
    );
  }

  checkGrade(currentGradeRange, assignment) {
    return (
      !currentGradeRange ||
      currentGradeRange === this.ALL_GRADES ||
      assignment.grades.some((grade) => currentGradeRange.within(grade))
    );
  }

  checkQuery(currentQuery, assignment) {
    if (currentQuery.length > 0) {
      let lowerCaseQuery = currentQuery.toLowerCase();
      let target = `${assignment.name}${assignment.brief}`;
      return target.toLowerCase().indexOf(lowerCaseQuery) > -1;
    }
    return true;
  }

  get query() {
    return this._query;
  }

  set query(value) {
    this._query = value;
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading && !this._error;
  }

  /**
   * @returns {Error}
   */
  get error() {
    return this._error;
  }

  goToAssignment(assignment) {
    const path = this.isAuthenticated
      ? 'root.account.nav.public-assignment'
      : 'root.public-assignment';
    this._breadcrumbService.go(path, { assignmentId: assignment.id });
  }

  goHome() {
    this._breadcrumbService.goBack('root.account.home', {}, true);
  }

  goToLogin() {
    this._breadcrumbService.go('root.account-login');
  }

  /**
   * @param assignment {Assignment}
   * @return {*}
   */
  assignmentSheetConfigForAssignment(assignment) {
    if (assignment && assignment.id) {
      if (!this._assignmentSheetConfigMap.has(assignment.id)) {
        this._assignmentSheetConfigMap.set(assignment.id, {
          readonly: true,
          thumbnail: true,
          index: 0,
          target: assignment,
        });
      }
      return this._assignmentSheetConfigMap.get(assignment.id);
    }
  }

  _getPublicAssignmentsForOrg(id) {
    this._assignmentService
      .getPublicAssignmentsByOrganizationId(id)
      .then((assignments) => {
        this._assignments = assignments;
        this._virtualAssignments = new VirtualCollection(this._assignments, 50);
        this._displaySubjectOptionsFromAssignmentList();
        this._error = null;
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
        this._resetAssignmentLibraryViewForErrors();
      });
  }

  _getPublicAssignmentsForDistrict(id) {
    this._assignmentService
      .getPublicAssignmentsByDistrictId(id)
      .then((assignments) => {
        this._assignments = assignments;
        this._virtualAssignments = new VirtualCollection(this._assignments, 50);
        this._displaySubjectOptionsFromAssignmentList();
        this._error = null;
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
        this._resetAssignmentLibraryViewForErrors();
      });
  }

  _getClasskickAssignmentLibrary() {
    this._userService
      .getPublicAssignments(this._environment.assignmentLibraryAccount)
      .then((results) => {
        this._assignments = results.assignments;
        this._virtualAssignments = new VirtualCollection(this._assignments, 50);
        this._displaySubjectOptionsFromAssignmentList();
        this._error = null;
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
        this._resetAssignmentLibraryViewForErrors();
      });
  }

  _getActiveSchoolsAndDistrictsByAlphabeticalOrder(organizations) {
    return (
      organizations &&
      organizations
        .filter(
          (org) =>
            (org.contract && org.contract.isExpired === false) ||
            org.type === 'district',
        )
        .sort((a, b) => Sorts.NAME_ASC(a.name, b.name))
    );
  }

  _setLibraryOptions() {
    return this.isAuthenticated
      ? [{ name: 'Public Library', _id: 0 }, ...this._organizations]
      : [
          { name: 'Public Library', _id: 0 },
          {
            name: "Click here to sign in to Classkick to view your pro school's library",
            _id: 1,
          },
        ];
  }

  _setLibraryOptionFromStorageService() {
    const libraryOptionIndex = this._getIndexInLibraryOptions();
    if (
      this.isAuthenticated &&
      this._storageService.assignmentLibrarySelection &&
      libraryOptionIndex !== -1
    ) {
      return this.libraryOptions[libraryOptionIndex];
    } else if (this.isAuthenticated && this.libraryOptions.length > 1) {
      this._storageService.assignmentLibrarySelection = this.libraryOptions[1];
      return this.libraryOptions[1];
    } else {
      this._storageService.assignmentLibrarySelection = this.libraryOptions[0];
      return this.libraryOptions[0];
    }
  }

  _getIndexInLibraryOptions() {
    return this.libraryOptions.findIndex((option) => {
      if (this._storageService.assignmentLibrarySelection) {
        return (
          option._id === this._storageService.assignmentLibrarySelection._id
        );
      }
    });
  }

  _resetAssignmentLibraryViewForErrors() {
    this._assignments = [];
    this._virtualAssignments = new VirtualCollection(this._assignments, 50);
    this._loading = false;
  }

  _displaySubjectOptionsFromAssignmentList() {
    this._subjectOptions = [
      ...new Set(
        this.assignments &&
          this._assignments
            .map((assignment) =>
              assignment.subjects.map((subject) => subject.trim()),
            )
            .flat()
            .sort(),
      ),
    ];
  }

  handleLibrarySelect() {
    this._loading = true;
    this.trackDropdownSelected();
    if (this.selectedLibraryOption) {
      if (this.selectedLibraryOption._id === 0) {
        return this._getClasskickAssignmentLibrary();
      }

      if (this.selectedLibraryOption._id === 1) {
        return this.goToLogin();
      }
      if (
        this.selectedLibraryOption &&
        this.selectedLibraryOption.type === 'district'
      ) {
        this._getPublicAssignmentsForDistrict(this.selectedLibraryOption.id);
      } else {
        this._getPublicAssignmentsForOrg(this.selectedLibraryOption.id);
      }
    }
  }

  isPermissionAuthError() {
    return !!(
      this.error &&
      this.error.message ===
        'Insufficient permissions to access requested resource'
    );
  }

  getOrgPlaceholderText() {
    return this.isAuthenticated
      ? 'Organizations'
      : 'Sign in to Classkick to view your school’s library';
  }

  trackDropdownSelected() {
    this._analyticsService.assignmentLibraryDropdownSelected(
      this.selectedLibraryOption._id,
    );
  }

  trackDropdownOpened() {
    this._analyticsService.assignmentLibraryDropdownOpened(
      this.selectedLibraryOption._id,
    );
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  /**
   * Bring up the signup dialog. When the button is clicked, go to the login page
   * with the optional redirect params set to return here after login.
   */
  showSignupDialog() {
    this._showSignupDialog(this.$mdDialog).then((stateName) => {
      this._storageService.ckRedirect = new CkRedirect(
        'root.account.nav.public-assignments-library',
        {},
      );
      this.$state.go(stateName, {
        referral: Locations.ASSIGNMENT_LIBRARY,
      });
    });
  }

  copyAssignment(assignment) {
    this._analyticsService.copyFromAssignmentLibrary(
      assignment.id,
      this.selectedLibraryOption && this.selectedLibraryOption._id,
    );
    const copyRequest = new CopyAssignmentRequest(
      assignment.id,
      assignment.name,
    );
    if (this._authService.isLoggedIn) {
      if (copyRequest.isValid) {
        this._assignmentManager.checkAssignmentLimit(
          Locations.ASSIGNMENT_LIBRARY,
          assignment.id,
          () => {
            this.duplicateAssignment(assignment);
          },
        );
      }
    } else {
      this.showSignupDialog();
    }
  }

  duplicateAssignment(assignment) {
    this._assignmentManager
      .duplicateNoRedirect(assignment.id, assignment.name)
      .then(() => {
        this.showCopyToastMessage(assignment);
        this._storageService.copyAssignmentRequest = null;
      });
  }

  showCopyToastMessage(assignment) {
    this.$mdToast.show(
      this.$mdToast
        .simple(`${assignment.name} added to your assignment library`)
        .action(' ')
        .actionKey('x')
        .position('bottom right')
        .toastClass('copy-assignment-toast')
        .hideDelay(2000),
    );
  }
}
