'use strict';

export const DEFAULT_ASSIGNMENT_LIMIT = 20;

/**
 * Replace
 * (this.user && this.user.assignmentsLimit) || User.DefaultAssignmentsLimit
 */

/**
 * Application Configuration
 */
export class AppConfiguration {
  constructor(config) {
    this._config = config || {};
  }

  /**
   * @TODO should check between the unlimited or hardcoded assignment limit...?
   *
   * @returns {*|number}
   */
  get assignmentLimit() {
    return this._config['assignmentLimit'] || DEFAULT_ASSIGNMENT_LIMIT;
  }
}
