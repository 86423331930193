'use strict';

import { MessageActions } from '../../model/domain/message';

export default class UserNotificationListItem {
  constructor(message) {
    this._message = message;
  }

  /**
   * @returns {Message}
   */
  get message() {
    return this._message;
  }

  get callToActionTitle() {
    for (let action of this._message.actions) {
      if (
        action[MessageActions.TYPE] === MessageActions.ACCEPT_ASSIGNMENT_SHARE
      ) {
        return 'PREVIEW';
      }
    }
    return 'OK';
  }

  get deleteUserNotificationTitle() {
    return 'DELETE';
  }
}
