'use strict';

import Control from './control';
import Point from './point';
import Size from './size';
import GestureManager from './gesture-manager';
import JSEvent from './../util/js-event';
import HexColors from '../../css-constants';

export default class ResizeHandle extends Control {
  /**
   * @param target {Control}
   * @param xScale {number}
   * @param yScale {number}
   * @param heightScale {number}
   * @param widthScale {number}
   */
  constructor(target, xScale, yScale, widthScale, heightScale) {
    super(
      target.id + '-handle' + xScale + yScale + widthScale + heightScale,
      'resize',
    );

    this._target = target;
    this._xScale = xScale;
    this._yScale = yScale;
    this._widthScale = widthScale;
    this._heightScale = heightScale;

    this._gestureManager = new GestureManager(target, target.canvas);
    this._gestureManager._dragThreshold = 0;

    this._resizeStarted = new JSEvent(this);
    this._resizeComplete = new JSEvent(this);
  }

  /**
   * @private
   */
  _dragStartHandler() {
    this._startWidth = this._target.width;
    this._startHeight = this._target.height;
    this.resizeStarted.raise({});
  }

  /**
   * @param data
   * @private
   */
  _dragMoveHandler(data) {
    this._target.resize(
      this._startWidth + data.delta.x * this._widthScale,
      this._startHeight + data.delta.y * this._heightScale,
    );
    this._target.location = new Point(
      data.controlStart.x +
        Math.min(
          data.delta.x * this._xScale,
          this._startWidth - this._target.minWidth,
        ),
      data.controlStart.y +
        Math.min(
          data.delta.y * this._yScale,
          this._startHeight - this._target.minHeight,
        ),
    );
  }

  /**
   * @param data
   * @private
   */
  _dragEndHandler(data) {
    this.resizeComplete.raise({
      size: this._target.size,
      location: this._target.location,
      oldSize: new Size(this._startWidth, this._startHeight),
      oldLocation: data.controlStart,
    });
  }

  createElement(root) {
    this._circle = root.circle(0, 0, 1);
    this._gestureManager.start(this._circle.node);
    this._gestureManager.dragStart.subscribe(this._dragStartHandler, this);
    this._gestureManager.dragMove.subscribe(this._dragMoveHandler, this);
    this._gestureManager.dragEnd.subscribe(this._dragEndHandler, this);
  }

  update() {
    this._circle.attr({
      fill: '#25C582',
      cursor: 'pointer',
      cx: this.location.x,
      cy: this.location.y,
      r: this.width / 2,
    });
  }

  warnBeforeDeletion() {
    this._circle.attr({
      fill: HexColors.CK_WARN,
    });
  }

  remove() {
    this._gestureManager.stop();
    this.resizeStarted.clear();
    this.resizeComplete.clear();
    super.remove();
  }

  /**
   * Fired when a resize gesture ends
   *
   * {
   *  size: {Size},
   *  location: {Point},
   *  oldSize: {Size},
   *  oldLocation: {Point}
   * }
   * @returns {JSEvent}
   */
  get resizeComplete() {
    return this._resizeComplete;
  }

  /**
   * @returns {JSEvent}
   */
  get resizeStarted() {
    return this._resizeStarted;
  }
}
