import moment from 'moment';

export default class GradebookAssignment {
  /**
   * @param id {string}
   * @param title {string}
   * @param scaledPoints {number}
   * @param dueDate {string}
   */
  constructor(id, title, scaledPoints, dueDate) {
    this._id = id;
    this._title = title;
    this._scaledPoints = scaledPoints;
    this._dueDate = moment(dueDate);
  }

  get id() {
    return this._id;
  }
  get title() {
    return this._title;
  }
  get scaledPoints() {
    return this._scaledPoints;
  }
  get dueDate() {
    return this._dueDate;
  }
}
