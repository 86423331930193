'use strict';

/**
 * Allows students with an anonymous log-in to give feedback to peers
 */
export default class AnonStudentAssignmentFeedbackController {
  /**
   * @ngInject
   */
  constructor(
    $scope,
    $stateParams,
    StudentCacheService,
    BreadcrumbService,
    StorageService,
    AuthService,
    StudentAssignmentService,
  ) {
    this.$stateParams = $stateParams;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type{BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type{StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;

    this._model = this._studentAssignmentService.getStudentAssignmentFeedback();

    // add saving indicator
    $scope.$on('$destroy', () => this._model.destroy());

    this.init();
  }

  /**
   * @return {StudentAssignmentFeedback}
   */
  get model() {
    return this._model;
  }

  init() {
    this._authService
      .authAnonStudent(this.$stateParams.classCode, this.$stateParams.name)
      .then(({ classCode }) => {
        return this._studentCacheService.getOrCreateWorkForSelf(
          classCode.assignmentId,
          classCode.rosterId,
        );
      })
      .then((assignmentWork) => {
        return this._model.init(assignmentWork);
      });
  }

  goBack() {
    this._storageService.feedbackHelpRequest = null;
    this._breadcrumbService.goBack('^.anon-student-assignment-work', {
      classCode: this.$stateParams.classCode,
      name: this.$stateParams.name,
    });
  }

  /**
   * @return {boolean}
   */
  get isStudentAuthed() {
    return this._authService.isLoggedIn;
  }
}
