'use strict';

import JSEvent from '../../model/util/js-event';

/**
 * Takes care of initializing the app
 */
export default class BootstrapService {
  constructor(
    $q,
    $rootScope,
    $timeout,
    $http,
    environment,
    $window,
    $location,
    $log,
    PlatformHeaderService,
    FirebaseService,
    BreadcrumbService,
    ImageEditService,
    AuthService,
    GoogleAnalyticsService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$location = $location;
    this.$log = $log;
    this.$http = $http;

    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {GoogleAnalyticsService} */
    this._googleAnalyticsService = GoogleAnalyticsService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;

    this._environment = environment;
    this._state = BootstrapService.STATE_LOADING;

    this._whenReadyDeferred = $q.defer();
    this._whenReady = this._whenReadyDeferred.promise;

    this._stateChanged = new JSEvent(this);
    this._anonMaintenanceCallback = (snapshot) => {
      this._maintenanceCallback(snapshot);
    };
    this._isDisplayingSystemDowntime = false;

    this._setUpOnBeforeUnload();
    this.$rootScope.$on('$destroy', () => this._destroy());
  }

  /**
   * Initializes the app, retries until successful
   * @returns {Promise.<T>}
   */
  init() {
    this._updateState(BootstrapService.STATE_LOADING);

    this._googleAnalyticsService.init();
    this._initAppStoreBanner();

    return this._connect()
      .then(() => {
        this._onConnectedSuccess();
      })
      .catch((error) => {
        this._onConnectedFailure(error);
      });
  }

  get stateChanged() {
    return this._stateChanged;
  }

  _maintenanceCallback(snapshot) {
    if (snapshot.exists()) {
      let isBlocking = snapshot.val()['is_blocking'];

      if (isBlocking && this.state !== BootstrapService.STATE_MAINTENANCE) {
        this._updateState(BootstrapService.STATE_MAINTENANCE);
        this._authService.signOut();
      } else if (
        !isBlocking &&
        this.state === BootstrapService.STATE_MAINTENANCE
      ) {
        // Force a reload of the entire application
        this._reloadApp();
      }
    }
  }

  _reloadApp() {
    this.$window.location.reload();
  }

  _startListeningForMaintenance() {
    // Listen for Firebase to tell us if we are down for maintenance
    return this._firebaseService
      .ref(
        'universal_message/system_downtime',
        this._firebaseService.UniversalMessageInstanceId,
      )
      .on('value', this._anonMaintenanceCallback);
  }

  _stopListeningForMaintenance() {
    // Remove listener from Firebase
    return this._firebaseService
      .ref(
        'universal_message/system_downtime',
        this._firebaseService.UniversalMessageInstanceId,
      )
      .off('value', this._anonMaintenanceCallback);
  }

  /**
   * @private
   */
  _deferredRetry() {
    this.$timeout(() => {
      this.init();
    }, this._environment.retryMs || 60000);
  }

  /**
   * @param state {string}
   * @param [errorMessage] {string}
   * @private
   */
  _updateState(state, errorMessage) {
    if (this._state === state) {
      return;
    }
    this._state = state;
    this._errorMessage = errorMessage || '';
    this._stateChanged.raise({ state: this._state });
    this.$rootScope.$broadcast(BootstrapService.EVENT_STATE, this);
  }

  /**
   * @returns {Promise}
   * @private
   */
  _connect() {
    return this.info.then(() => {
      return this._firebaseService.init(this.info, this._authService.authData);
    });
  }

  _onConnectedSuccess() {
    this.$log.info('BootstrapService', 'ready');

    // Reset cached auth data so that it's pulled fresh from storage service
    // An empty value may be cached before the session data has transferred
    this._authService._authData.clear();
    this._updateState(BootstrapService.STATE_READY);
    this._whenReadyDeferred.resolve();
    this._startListeningForMaintenance();
  }

  _onConnectedFailure(error) {
    this._updateState(BootstrapService.STATE_ERROR, error.message);
    this._deferredRetry();

    let exception =
      this._googleAnalyticsService.formatExceptionDescriptionError(error);
    this._googleAnalyticsService.sendException(
      `on connected failed | ${exception}`,
    );

    this.$log.error(error);
  }

  /**
   * @private
   */
  _initAppStoreBanner() {
    var meta = this.$window.document.createElement('meta');
    meta.name = 'apple-itunes-app';
    meta.content = `app-id=909904332, affiliate-data=at=1000lqto&ct=ioswebbanner app-argument=${this.$window.location.href}`;
    this.$window.document.getElementsByTagName('head')[0].appendChild(meta);
  }

  _setUpOnBeforeUnload() {
    const callbacks = new Set();

    let onBeforeUnload = (event) => {
      var ret = undefined;
      callbacks.forEach((entry) => {
        ret = ret || entry(event);
      });

      return ret;
    };

    onBeforeUnload.add = function (callback) {
      callbacks.add(callback);
    };

    onBeforeUnload.remove = function (callback) {
      callbacks.delete(callback);
    };

    this.$window.onbeforeunload = onBeforeUnload;
  }

  /**
   * Returns the result of /info from the web services
   * @returns {Promise.<object>}
   */
  get info() {
    if (this._gotInfo) {
      return this._gotInfo;
    }
    let url = this._environment.serverUrlBase + '/info';
    let config = this._platformHeaderService.addHeaders(url, null);
    var promise = this.$http.get(url, config);

    // if this promise succeeds, cache it
    promise
      .then(() => {
        this._gotInfo = promise;
      })
      .catch((err) => {
        this.$log.debug('get info error:', err);
      });

    return promise;
  }

  /**
   * @returns {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @returns {boolean}
   */
  get ready() {
    return this.state === BootstrapService.STATE_READY;
  }

  /**
   * @return {Promise}
   */
  get whenReady() {
    return this._whenReady;
  }

  /**
   * @returns {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  _destroy() {
    this._stopListeningForMaintenance();
  }

  /**
   * @returns {string}
   */
  static get EVENT_STATE() {
    return 'BootstrapService.state';
  }
  /**
   * @returns {string}
   */
  static get STATE_READY() {
    return 'ready';
  }
  /**
   * @returns {string}
   */
  static get STATE_LOADING() {
    return 'loading';
  }
  /**
   * @returns {string}
   */
  static get STATE_ERROR() {
    return 'error';
  }
  /**
   * @returns {string}
   */
  static get STATE_MAINTENANCE() {
    return 'maintenance';
  }
}
