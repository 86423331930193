'use strict';

import StaticService from '../../services/static/static.service';

/**
 * An abstract collection base class which is observable by a CollectionObserver
 */
export default class ObservableCollection {
  constructor() {
    /** @type {Array.<CollectionObserver>} */
    this._observers = [];
  }

  /**
   * iterates this collection. callback may return true to short circuit the iteration
   * @param callback {function(value, id)} calls the callback with each child of the collection in order
   */
  forEach(callback) {
    throw new Error('not implemented: ' + callback);
  }

  /**
   * Subscribes to this collection. #onAdded will be called on each of the values currently in the collection
   *
   * @param observer {CollectionObserver}
   */
  subscribe(observer) {
    this._observers.push(observer);
    this.forEach((value) => observer.onAdded(value, this));
    return observer;
  }

  /**
   * Unsubscribes from this collection
   *
   * @param observer {CollectionObserver}
   */
  unsubscribe(observer) {
    this._observers = this._observers.filter((x) => x !== observer);
    return observer;
  }

  /**
   * Raises the event with the specified args and source
   *
   * @param value {object} The new list element
   * @param oldValue {object} The old list element
   * @param add {boolean} The method to call
   * @param remove {boolean} The method to call
   * @param change {boolean} The method to call
   */
  _raise(value, oldValue, add, remove, change) {
    this._observers.forEach((observer) => {
      try {
        if (add) {
          observer.onAdded(value, this);
        } else if (remove) {
          observer.onRemoved(oldValue, this);
        } else if (change) {
          observer.onChanged(value, oldValue, this);
        }
      } catch (ex) {
        StaticService.get.$log.error('callback error', ex, observer);
      }
    });
  }

  /**
   * Clears all listeners for this list
   */
  clear() {
    this._observers = [];
  }

  /**
   * protected method to be used by subclasses - calls the onAdded callback of the observer
   * @param value {object}
   * @protected
   */
  _onAdded(value) {
    this._raise(value, null, true, false, false);
  }

  /**
   * protected method to be used by subclasses - calls the onRemoved callback of the observer
   * @param value {object}
   * @protected
   */
  _onRemoved(value) {
    this._raise(null, value, false, true, false);
  }

  /**
   * protected method to be used by subclasses - calls the onChanged callback of the observer
   * @param value {object}
   * @param oldValue {object}
   * @protected
   */
  _onChanged(value, oldValue) {
    this._raise(value, oldValue, false, false, true);
  }
}
