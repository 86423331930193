'use strict';

import { HelpInboxType } from '../../components/help-inbox/help-inbox.directive';
import StudentSessionData from '../../model/domain/student-session-data';
import { FeedbackMenuController } from '../../components/feedback-menu/feedback-menu.controller';

/**
 * Allows students with a log-in to work on an assignment
 */
export default class StudentAccountAssignmentWorkController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $scope,
    $state,
    $stateParams,
    $mdDialog,
    $mdPanel,
    CacheService,
    BreadcrumbService,
    StudentCacheService,
    StudentAssignmentService,
  ) {
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;

    this._error = undefined;
    this._model = this._studentAssignmentService.getStudentAssignmentWork(
      HelpInboxType.STUDENT_ACCOUNT,
    );
    this._loading = false;

    this._feedbackMenu = FeedbackMenuController.show;

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._model.destroy());

    this.init().then(() => {
      this._loading = false;
    });
  }

  static get QUESTION_IS_NOT_ON_ASSIGNMENT_ERROR() {
    return 'is not in assignment';
  }

  /**
   * @return {StudentAssignmentWork}
   */
  get model() {
    return this._model;
  }

  /**
   * @return {boolean}
   */
  get loading() {
    return this._loading;
  }

  init() {
    return this._studentCacheService
      .getUserWork(this.$stateParams.assignmentWorkId, false)
      .then((assignmentWork) => {
        return this._model.initCoreData(
          assignmentWork,
          this.$stateParams.questionId,
        );
      })
      .then((assignmentWork) => {
        this._loading = true; // eager load the first assignment page, wait for .init to finish
        return this._model.initSubscriptionData(
          assignmentWork,
          this.$stateParams.questionId,
        );
      })
      .catch((error) => {
        if (error.message === StudentSessionData.singleDeviceLoginError) {
          StudentSessionData.launchSingleDeviceErrorDialog(this.$mdDialog, true)
            .then(() => {
              this.goBack();
            })
            .catch(() => {
              this.$state.reload();
            });
        } else if (error.message === StudentSessionData.assignmentHiddenError) {
          StudentSessionData.launchAssignmentHiddenErrorDialog(
            this.$mdDialog,
            true,
          )
            .then(() => {
              this.goBack();
            })
            .catch(() => {
              this.$state.reload();
            });
        }
        if (
          error.message.includes(
            StudentAccountAssignmentWorkController.QUESTION_IS_NOT_ON_ASSIGNMENT_ERROR,
          )
        ) {
          this.$state.go(this.$state.current.name, {
            assignmentWorkId: this.$stateParams.assignmentWorkId,
            questionId: null,
          });
        }
        this._error = error;
      });
  }

  /**
   * @param questionId {string}
   */
  goToQuestion(questionId) {
    this._breadcrumbService.go(
      'root.account.student-assignment-work',
      {
        assignmentWorkId: this.model.assignmentWorkId,
        questionId,
      },
      true,
    );
  }

  /**
   * Navigates to overview of all slides
   */
  goToOverview() {
    this._breadcrumbService.go(
      'root.account.student-assignment-overview',
      {
        assignmentWorkId: this.model.assignmentWorkId,
      },
      true,
    );
  }

  /**
   * @param request {HelpRequest}
   */
  goToFeedback(request) {
    this._breadcrumbService.go('^.student-assignment-feedback', {
      assignmentWorkId: this.model.assignmentWorkId,
      request: request,
    });
  }

  goBack() {
    this._cacheService.clearUserStatusDetails();
    this._breadcrumbService.goBack('root.account.nav.student-assignments-list');
  }

  get name() {
    let data = this.model && this.model.subscriptionData;
    let user = data && data.user;
    return user && user.name;
  }

  openFeedbackMenu(ev) {
    this._feedbackMenu(
      this.$mdPanel,
      this.$q,
      ev,
      this.model.subscriptionData.questionFeedbackList,
      this.model.subscriptionData.assignmentWork,
    ).then(({ questionId }) => {
      this.goToQuestion(questionId);
    });
  }
}
