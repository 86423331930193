import MoveAssignmentDialog from '../../components/move-assignment-dialog/move-assignment-dialog.controller';
import ShareDialogController from '../../components/share-dialog/share-dialog.controller';
import {
  Locations,
  PaywallSources,
} from '../../services/mixpanel/mixpanel.service';
import AssignmentExport from './assignment-export';
import AssignmentExportDialogController from '../../components/assignment-export-dialog/assignment-export-dialog.controller';
import Assignment from './assignment';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ContextualPaywallDialogController, {
  ContextualPaywalls,
} from '../../components/contextual-paywall-dialog/contextual-paywall-dialog.controller';
import RosterSelectionDialogController from '../../components/roster-selection-dialog/roster-selection-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import AssignmentsLimitPaywallDialogController from '../../components/assignments-limit-paywall-dialog/assignments-limit-paywall-dialog.controller';
import User, { UserRoles } from './user';
import { ElementIntents } from '../../model/domain/element-metadata';
import AssignmentSelectionDialogController from '../../components/assignment-selection-dialog/assignment-selection-dialog.controller';
import StaticService from '../../services/static/static.service';

export default class AssignmentManager {
  constructor(
    $q,
    $mdDialog,
    $mdToast,
    CacheService,
    AssignmentService,
    BreadcrumbService,
    GradeExportService,
    AnswerExportService,
    AnalyticsService,
    ExportService,
  ) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    this._cacheService = CacheService;
    this._assignmentService = AssignmentService;
    this._breadcrumbService = BreadcrumbService;
    this._gradeExportService = GradeExportService;
    this._answerExportService = AnswerExportService;
    this._analyticsService = AnalyticsService;
    this._exportService = ExportService;

    this._shareDialog = ShareDialogController.show;
    this._showExportAssignmentDialog = AssignmentExportDialogController.show;
    this._moveAssignmentDialog = MoveAssignmentDialog.show;
    this._loadingDialog = LoadingDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;
    this._showAssignmentSelectionDialog =
      AssignmentSelectionDialogController.show;
    this._showRosterSelectionDialog = RosterSelectionDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._assignmentsLimitPaywallDialog =
      AssignmentsLimitPaywallDialogController.show;
  }

  /**
   * @param newAssignment {object}
   * @param [questionCount] {number}
   * @return {Promise<Assignment>}
   */
  create(newAssignment, questionCount) {
    return this._assignmentService
      .create(newAssignment, questionCount)
      .then((assignment) => {
        return this._cacheService.addAssignmentForUser(assignment);
      });
  }

  /**
   * @param assignment {Assignment}
   * @return {Promise}
   */
  share(assignment) {
    return this._shareDialog(
      this.$mdDialog,
      assignment,
      Locations.ASSIGNMENT_EDIT,
    );
  }

  /**
   * @param assignment   {Assignment}
   * @param showMetadata {boolean}
   */
  exportContentToPDF(assignment, showMetadata = false) {
    var assignmentExport = new AssignmentExport(assignment);
    assignmentExport.showMetadata = showMetadata;
    this._showExportAssignmentDialog(
      this.$mdDialog,
      [assignmentExport],
      ElementIntents.CONTENT,
      UserRoles.TEACHER,
    );
  }

  /**
   * Converts map of works and users, and classCode and assignment into AssignmentExports
   * @param workForStudent {Map.<string, Assignment|AssignmentWork>}
   * @param users {User[]}
   * @param classCode {ClassCode}
   * @param assignment {Assignment}
   * @param pro {ProInfo}
   */
  exportWorkToPDF(workForStudent, users, classCode, assignment, pro) {
    if (!pro.hasExportStudentWork) {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.StudentWorkExport,
        PaywallSources.STUDENT_EXPORT,
      );
    } else {
      let assignmentExport = this._exportService.createAssignmentExports(
        workForStudent,
        users,
        classCode,
        assignment,
      );
      this._showExportAssignmentDialog(
        this.$mdDialog,
        assignmentExport,
        ElementIntents.FEEDBACK,
        UserRoles.TEACHER,
      );
    }
  }

  /**
   * Converts map of works, user, and array of virtual assignments into AssignmentExports
   * @param worksForSingleStudent {Map.<string, AssignmentWork>}
   * @param user {User}
   * @param virtualAssignments {StudentOverviewAssignmentItem[]}
   */
  exportOverviewWorkToPDF(worksForSingleStudent, user, virtualAssignments) {
    let assignmentExport = this._exportService.createOverviewAssignmentExports(
      worksForSingleStudent,
      user,
      virtualAssignments,
    );
    this._showExportAssignmentDialog(
      this.$mdDialog,
      assignmentExport,
      ElementIntents.WORK,
      UserRoles.TEACHER,
    );
  }

  /**
   * @param assignment {Assignment}
   */
  moveAssignmentToFolder(assignment) {
    this._cacheService.getAssignmentsForUser().then((assignments) => {
      let folders = Array.from(assignments.values()).filter((item) => {
        return (
          item.isFolder &&
          (assignment.folder ? assignment.folder !== item.id : true)
        );
      });

      this._moveAssignmentDialog(this.$mdDialog, folders, assignment).then(
        (folder) => {
          return this._moveAssignmentToFolder(assignment, folder);
        },
      );
    });
  }

  /**
   * @param assignment {Assignment}
   * @param folder {Assignment}
   * @return {Promise}
   */
  _moveAssignmentToFolder(assignment, folder) {
    assignment.folder =
      MoveAssignmentDialog.RootDirectory === folder.id ? undefined : folder.id;
    return this._cacheService.updateAssignmentForUser(assignment).then(() => {
      this._toast(`${assignment.name} has been moved to ${folder.name}`);
    });
  }

  /**
   * Duplicates an assignment
   * @param assignmentId {string}
   * @param oldName {string}
   * @param [replace] {boolean}
   */
  duplicate(assignmentId, oldName, replace = true) {
    let newName = `${oldName} copy`;
    if (newName.length > Assignment.ASSIGNMENT_TITLE_MAX_LENGTH) {
      newName = oldName;
    }

    const promise = this._assignmentService.duplicate(assignmentId, newName);

    this._loadingDialog(this.$mdDialog, promise);

    promise
      .then((assignment) => {
        return this._cacheService.addAssignmentForUser(assignment);
      })
      .then((newAssignment) => {
        this._breadcrumbService.go(
          'root.account.assignment',
          { assignmentId: newAssignment.id },
          replace,
        );
      });
  }

  /**
   * Duplicates an assignment without redirecting user to another page
   * @param assignmentId {string}
   * @param oldName {string}
   */
  duplicateNoRedirect(assignmentId, oldName) {
    let newName = `${oldName} copy`;
    if (newName.length > Assignment.ASSIGNMENT_TITLE_MAX_LENGTH) {
      newName = oldName;
    }

    const promise = this._assignmentService.duplicate(assignmentId, newName);

    this._loadingDialog(this.$mdDialog, promise);

    return promise
      .then((assignment) => {
        return this._cacheService
          .addAssignmentForUser(assignment)
          .then((assignment) => {
            return assignment;
          });
      })
      .catch((error) => {
        StaticService.get.$log.error(error);
      });
  }

  /**
   * Deletes an assignment
   * @param assignment {Assignment}
   */
  delete(assignment) {
    let deleteMessage = `Are you sure you want to delete ${assignment.name}?`;
    let secondaryDeleteMessage =
      'Once you delete this assignment, you will not have a way to view the work your students have completed.';

    return this._confirmDialog(
      this.$mdDialog,
      deleteMessage,
      secondaryDeleteMessage,
    )
      .then(() => {
        let promise = this._cacheService.deleteAssignment(assignment.id);

        this._loadingDialog(this.$mdDialog, promise);

        return promise;
      })
      .then(() => {
        this._toast(`Deleted ${assignment.name}`);
      });
  }

  /**
   * @param message {string}
   */
  _toast(message) {
    let config = this.$mdToast
      .simple()
      .textContent(message)
      .position('bottom right');
    this.$mdToast.show(config);
  }

  /**
   * @param assignment {Assignment}
   * @param rosters {Roster[]}
   * @param pro {ProInfo}
   */
  exportGrades(assignment, rosters, pro) {
    if (!pro.hasGradeExport) {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.GradeExport,
        PaywallSources.GRADE_EXPORT,
      );
    } else {
      this._showRosterSelectionDialog(this.$mdDialog, rosters).then(
        (rosterIdsToExport) => {
          return this._exportGrades(rosters, rosterIdsToExport, assignment);
        },
      );
    }
  }

  /**
   * @param rosters {Roster[]}
   * @param rosterIdsToExport {string[]}
   * @param assignment {Assignment}
   * @return {Promise}
   */
  _exportGrades(rosters, rosterIdsToExport, assignment) {
    let promise = this._gradeExportService.byAssignment(
      rosters,
      rosterIdsToExport,
      assignment,
    );
    this._loadingDialog(this.$mdDialog, promise);
    return promise
      .then(() => {
        this._analyticsService.assignmentGradeExported();
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          `Whoops! There was an error exporting grades for ${assignment.name}`,
          'Please try again and if the issue persists reach out to support@classkick.com',
        );
      });
  }

  /**
   * @param assignments {Assignment[]}
   * @param studentAssignmentOverviewItemsMap {Map<string, StudentOverviewAssignmentItem>}
   * @param roster {Roster}
   * @param user {User}
   */
  exportGradesForSingleStudent(
    assignments,
    studentAssignmentOverviewItemsMap,
    roster,
    user,
  ) {
    this._showAssignmentSelectionDialog(this.$mdDialog, assignments).then(
      (selectedAssignmentIds) => {
        this._selectedAssignments = selectedAssignmentIds;
        return this._exportGradesForSingleStudent(
          this._selectedAssignments,
          studentAssignmentOverviewItemsMap,
          roster,
          user,
        );
      },
    );
  }

  /**
   * @param assignmentIdsToExport {string[]}
   * @param studentAssignmentOverviewItemsMap {Map<string, StudentOverviewAssignmentItem>}
   * @param roster {Roster}
   * @param user {User}
   */
  _exportGradesForSingleStudent(
    assignmentIdsToExport,
    studentAssignmentOverviewItemsMap,
    roster,
    user,
  ) {
    this._gradeExportService.byStudent(
      assignmentIdsToExport,
      studentAssignmentOverviewItemsMap,
      roster,
      user,
    );
  }

  /**
   * @param assignment {Assignment}
   * @param rosters {Roster[]}
   * @param pro {ProInfo}
   */
  exportAnswers(assignment, rosters, pro) {
    if (!pro.hasGradeExport) {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.AnswerExport,
        PaywallSources.ANSWER_EXPORT,
      );
    } else {
      this._showRosterSelectionDialog(
        this.$mdDialog,
        rosters,
        'Select Roster Answers to Export',
      ).then((rosterIdsToExport) => {
        return this._exportAnswers(rosters, rosterIdsToExport, assignment);
      });
    }
  }

  /**
   * @param rosters {Roster[]}
   * @param rosterIdsToExport {string[]}
   * @param assignment {Assignment}
   * @return {Promise}
   */
  _exportAnswers(rosters, rosterIdsToExport, assignment) {
    let promise = this._answerExportService.byAssignment(
      rosters,
      rosterIdsToExport,
      assignment,
    );
    this._loadingDialog(this.$mdDialog, promise);
    return promise
      .then(() => {
        this._analyticsService.assignmentAnswerExported();
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          `Whoops! There was an error exporting answers for ${assignment.name}`,
          'Please try again and if the issue persists reach out to support@classkick.com',
        );
      });
  }

  /**
   * @param assignments {Assignment[]}
   * @param studentAssignmentOverviewItemsMap {Map<string, StudentOverviewAssignmentItem>}
   * @param roster {Roster}
   * @param user {User}
   */
  exportAnswersForSingleStudent(
    assignments,
    studentAssignmentOverviewItemsMap,
    roster,
    user,
  ) {
    this._showAssignmentSelectionDialog(this.$mdDialog, assignments, true).then(
      (selectedAssignmentIds) => {
        this._selectedAssignments = selectedAssignmentIds;
        return this._exportAnswersForSingleStudent(
          this._selectedAssignments,
          studentAssignmentOverviewItemsMap,
          roster,
          user,
        );
      },
    );
  }

  /**
   * @param assignmentIdsToExport {string[]}
   * @param studentAssignmentOverviewItemsMap {Map<string, StudentOverviewAssignmentItem>}
   * @param roster {Roster}
   * @param user {User}
   */
  _exportAnswersForSingleStudent(
    assignmentIdsToExport,
    studentAssignmentOverviewItemsMap,
    roster,
    user,
  ) {
    this._answerExportService.byStudent(
      assignmentIdsToExport,
      studentAssignmentOverviewItemsMap,
      roster,
      user,
    );
  }

  /**
   * Checks if user is above assignment limit
   * @param clickedFrom {string}
   * @param assignmentId {string}
   * @param f {Function}
   */
  checkAssignmentLimit(clickedFrom, assignmentId = null, f) {
    return this.$q
      .all({
        pro: this._cacheService.getProInfo(false),
        total: this._getAssignmentsTotal(),
        assignmentsInFolder: this._getAssignmentsInFolderTotal(assignmentId),
        user: this._cacheService.getUser(),
      })
      .then(({ pro, total, user, assignmentsInFolder }) => {
        this._cacheService.getAppConfig().then((config) => {
          let assignmentsLimit = config.assignmentLimit;
          let willBeOverLimit =
            (total + assignmentsInFolder + 1) / assignmentsLimit > 1;

          this._analyticsService.assignmentLimitChecked(
            assignmentId,
            total + assignmentsInFolder,
          );

          if (!pro.hasUnlimitedAssignments && willBeOverLimit) {
            this.openAssignmentLimitPaywall(
              total,
              assignmentsLimit,
              clickedFrom,
              false,
              assignmentId,
            );
          } else {
            f();
          }
        });
      });
  }

  _getAssignmentsTotal() {
    return this._cacheService
      .getAssignmentsForUser(false)
      .then((assignmentsMap) => {
        const assignments = Array.from(
          assignmentsMap,
          (tuple) => tuple[1],
        ).filter((assignment) => !assignment.isFolder);

        return assignments.length;
      });
  }

  _getAssignmentsInFolderTotal(assignmentId) {
    if (assignmentId) {
      return this._assignmentService
        .getNestedPublicFolder(assignmentId)
        .then((assignmentsMap) => {
          const assignmentsInFolder = assignmentsMap.filter(
            (assignment) => !assignment.isFolder,
          );
          return assignmentsInFolder.length;
        });
    } else {
      return 0;
    }
  }

  /**
   * @param assignmentsUsedCount {number}
   * @param assignmentsLimit {number}
   * @param clickedFrom {string}
   * @param isDisabledAssignment {boolean}
   * @param assignmentId {string | null }
   */
  openAssignmentLimitPaywall(
    assignmentsUsedCount,
    assignmentsLimit,
    clickedFrom,
    isDisabledAssignment = false,
    assignmentId = null,
  ) {
    return this._assignmentsLimitPaywallDialog(
      this.$mdDialog,
      assignmentsUsedCount,
      assignmentsLimit,
      clickedFrom,
      isDisabledAssignment,
      assignmentId,
    );
  }
}
