'use strict';

import Codec from './codec';
import GradebookRosterLink from '../domain/gradebook-roster-link';
import GradebookRosterCodec from './gradebook-roster-codec';
import CourseCodec from './course-codec';
import SessionCodec from './session-codec';
import ClassCodec from './class-codec';

/**
 * Encodes/decodes Gradebook integration status
 */
export default class GradebookRosterLinkCodec extends Codec {
  constructor() {
    super();

    this._sessionCodec = new SessionCodec();
    this._courseCodec = new CourseCodec();
    this._classCodec = new ClassCodec();
    this._gradebookRosterCodec = new GradebookRosterCodec();
  }

  /**
   * @param value {GradebookRosterLink}
   * @returns {object}
   */
  encode(value) {
    const selectedLink =
      (value.selectedLink &&
        this._gradebookRosterCodec.encode(value.selectedLink)) ||
      null;
    const sessions = value.sessions.map((session) =>
      this._gradebookRosterCodec.encode(session),
    );
    const courses = value.courses.map((course) =>
      this._gradebookRosterCodec.encode(course),
    );
    const classes = value.classes.map((classInfo) =>
      this._gradebookRosterCodec.encode(classInfo),
    );
    if (value) {
      return {
        selected_link: selectedLink,
        sessions,
        courses,
        classes,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookRosterLink}
   */
  decode(value) {
    if (value) {
      const selectedLink =
        (value.selected_link &&
          this._gradebookRosterCodec.decode(value.selected_link)) ||
        null;
      const sessions = value.sessions.map((session) =>
        this._sessionCodec.decode(session),
      );
      const courses = value.courses.map((course) =>
        this._courseCodec.decode(course),
      );
      const classes = value.classes.map((classInfo) =>
        this._classCodec.decode(classInfo),
      );

      return new GradebookRosterLink(selectedLink, sessions, courses, classes);
    }

    return null;
  }
}
