'use strict';

import {
  Locations,
  PaywallSources,
} from '../../services/mixpanel/mixpanel.service';
import ChooseRosterDialogController from './choose-roster-dialog.controller';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ShareWithStudentDialogController from '../../components/share-with-student-dialog/share-with-student-dialog.controller';
import AssignmentRosterItem from './assignment-roster-item';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';
import AssignmentManager from '../../model/domain/assignment-manager';
import CkAnimations from '../../model/util/ck-animations';
import ShareOrderDialogController from '../../components/share-order-dialog/share-order-dialog.controller';
import Contract from '../../model/domain/contract';
import Order from '../../model/domain/order';
import ReferralDialogController from '../../components/referral-dialog/referral-dialog.controller';
import { ABTest } from '../../services/ab-test/ab-test-service';
import PreviewStudentUtil from '../../model/util/preview-student-util';
import Sorts from '../../model/domain/sorts';
import GradeExportDialogController from '../../components/grade-export-dialog/grade-export-dialog.controller';
import ContextualPaywallDialogController, {
  ContextualPaywalls,
} from '../../components/contextual-paywall-dialog/contextual-paywall-dialog.controller';

export default class AssignmentRostersController {
  /**
   * @ngInject
   */
  constructor(
    $filter,
    $location,
    $log,
    $mdDialog,
    $mdToast,
    $q,
    $scope,
    $window,
    $stateParams,
    CacheService,
    HelpRequestService,
    BreadcrumbService,
    AssignmentService,
    AnalyticsService,
    RosterService,
    AnswerExportService,
    GradeExportService,
    ExportService,
    PlatformHeaderService,
    AuthService,
    OrderService,
    OrganizationService,
    StorageService,
  ) {
    this.$filter = $filter;
    this.$location = $location;
    this.$log = $log;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$q = $q;
    this.$stateParams = $stateParams;
    this.$window = $window;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AnswerExportService} */
    this._answerExportService = AnswerExportService;
    /** @type {GradeExportService} */
    this._gradeExportService = GradeExportService;
    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      this._gradeExportService,
      this._answerExportService,
      this._analyticsService,
      this._exportService,
    );

    /** @type {Roster[]} */
    this._rosters = undefined;
    /** @type {Assignment} */
    this._assignment = undefined;
    /** @type {Array.<ClassCode>} */
    this._classCodes = undefined;
    this._userSchools = null;
    this.contracts = [];
    this.schoolsInDistrict = null;

    this._assignmentId = $stateParams.assignmentId;
    this._lastRosterId = $stateParams.lastRosterId;

    this._platformHeaderService.setAssignmentId(this._assignmentId);

    this._chooseRosterDialog = ChooseRosterDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._shareWithStudentDialog = ShareWithStudentDialogController.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;

    this._shareOrderDialog = ShareOrderDialogController.show;
    this._referralDialog = ReferralDialogController.show;
    this._gradeExportDialog = GradeExportDialogController.show;

    $scope.$on('$destroy', () => this._destroy());

    if (this._authService.authData) {
      /** @type {boolean} */
      this.isStudent = this._authService.authData.isStudent;
      /** @type {boolean} */
      this.isTeacher = this._authService.authData.isTeacher;
      /** @type {boolean} */
      this.isFreeUser = this._authService.authData.isFree;
      /** @type {boolean} */
      this.isProUser = this._authService.authData.isPro;
    }

    this._init();
  }

  _init() {
    this.$q
      .all({
        user: this._cacheService.getUser(),
        assignment: this._cacheService.getAssignmentForUser(this._assignmentId),
        rosters: this._cacheService.getRostersForUser(true),
        classCodes: this._cacheService.getClassCodesForUserAssignment(
          this._assignmentId,
        ),
        helpRequests: this._helpRequestService.getHelpRequestSetForAssignment(
          this._authService.currentUserId,
          this._assignmentId,
        ),
        proInfo: this._cacheService.getProInfo(),
        contracts: this._cacheService.getContracts(false, this.isTeacher),
        schools: this._cacheService.getSchools(false),
      })
      .then(
        ({
          user,
          assignment,
          classCodes,
          rosters,
          helpRequests,
          proInfo,
          contracts,
          schools,
        }) => {
          const ownedRosters = new Map();
          rosters.forEach((roster, key) => {
            if (roster.ownerId === user.id) {
              ownedRosters.set(key, roster);
            }
          });

          this._initData(assignment, classCodes, ownedRosters, helpRequests);

          this._user = user;
          this._proInfo = proInfo;
          this.activeContract = Contract.ActiveContract(contracts);
          this.school = schools.find(
            (school) =>
              this.activeContract &&
              school.contractId === this.activeContract.id,
          );
          this._userSchools = schools;
          this.contracts = contracts;

          if (
            this._storageService.edlinkCode ||
            this._storageService.edlinkError
          ) {
            this.openGradebookExport();
          }

          if (this.school) {
            this._orderService
              .getForOrganization(this.school.id)
              .then((orders) => {
                this.activeOrder = Order.findActiveOrder(orders);
              });

            if (this.school.parentOrganizationId) {
              this.$q
                .all({
                  districtOrders: this._orderService.getForOrganization(
                    this.school.parentOrganizationId,
                  ),
                  schoolsInDistrict:
                    this.school.parentOrganizationId &&
                    this._cacheService.getChildOrganizationCount(
                      this.school.parentOrganizationId,
                    ),
                })
                .then(({ districtOrders, schoolsInDistrict }) => {
                  this.activeDistrictOrder =
                    Order.findActiveOrder(districtOrders);
                  this.schoolsInDistrict = schoolsInDistrict.count;
                });
            }
          }
        },
      )
      .catch((err) => {
        this._error = err;
        this.$log.error('error', err);
        return this.$q.reject(err);
      });
  }

  /**
   *
   * @param assignment {Assignment}
   * @param classCodes {Array.<ClassCode>}
   * @param rosters {Map.<string, Roster>}
   * @param [helpRequests] {HelpRequestSet}
   * @private
   */
  _initData(assignment, classCodes, rosters, helpRequests) {
    if (classCodes) {
      this._classCodes = classCodes;
    }
    if (rosters) {
      this._rosterMap = rosters;
    }
    if (helpRequests) {
      if (helpRequests !== this._helpRequests) {
        if (this._helpRequests) {
          this._helpRequests.stop();
        }
        helpRequests.start();
      }
      this._helpRequests = helpRequests;
    }

    this._assignment = assignment;

    this.name = this._assignment.name;

    this._rosters = this._assignment.rosters
      .map((id) => this._rosterMap.get(id))
      .filter((roster) => {
        return angular.isDefined(roster);
      })
      .sort((a, b) =>
        Sorts.NAME_ASC(a.name.toLowerCase(), b.name.toLowerCase()),
      );

    this._rosterItems = this._rosters.map((roster) => {
      return new AssignmentRosterItem(
        roster,
        this.classCodeForRoster(roster.id),
        this._handleRosterUpdated.bind(this),
        this._handleClassCodeUpdated.bind(this),
        this._analyticsService,
      );
    });

    this._rosterHelpRequests = new Map(
      this._rosters.map((roster) => [
        roster.id,
        this._helpRequests.createChild((x) => x.rosterId === roster.id),
      ]),
    );
  }

  _destroy() {
    this._platformHeaderService.setAssignmentId(undefined);
  }

  get error() {
    return this._error;
  }

  get loading() {
    return !this.assignment && !this.error;
  }

  /**
   * @returns {Assignment}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @returns {Array.<Roster>}
   */
  get rosters() {
    return this._rosters;
  }

  /**
   * @return {AssignmentRosterItem[]}
   */
  get rosterItems() {
    return this._rosterItems;
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._proInfo;
  }

  /**
   * @return {boolean}
   */
  get showHelpMessage() {
    return this._rosters && this._rosters.length === 1 && this.isNewUser;
  }

  /**
   * @return {undefined|boolean}
   */
  get isNewUser() {
    if (!this._isNewUser) {
      this._isNewUser = this._user && this._user.isNewUser;
    }
    return this._isNewUser;
  }

  /**
   * @return {boolean}
   */
  get hasGradeExport() {
    return this.pro && this.pro.hasGradeExport;
  }

  /**
   * @return {boolean}
   */
  get hasFoldersFeature() {
    return this.pro && this.pro.hasFoldersFeature;
  }

  /**
   * @return {string}
   */
  get assignmentAssign() {
    return Locations.ASSIGNMENT_ASSIGN;
  }

  /**
   * @return {boolean}
   */
  get isFreeTrial() {
    return this.activeContract && this.activeContract.isTrial;
  }

  /**
   * @return {boolean}
   */
  get isProSchool() {
    return this.activeContract && this.activeContract.isProSchool;
  }

  /**
   * @return {boolean}
   */
  get isProDistrict() {
    return this.activeContract && this.activeContract.isProDistrict;
  }

  //--------------- Navigation methods -------------------------

  goHome() {
    this._breadcrumbService.goBack('root.account.home', {}, true);
  }

  goEdit() {
    this._breadcrumbService.go(
      'root.account.assignment',
      {
        assignmentId: this._assignmentId,
        lastRosterId: this._lastRosterId,
      },
      true,
    );
  }

  goToWork(rosterId) {
    if (!rosterId) {
      rosterId = this._lastRosterId || this.defaultRosterId;
    }

    this._breadcrumbService.go(
      'root.account.session.work',
      {
        assignmentId: this._assignmentId,
        rosterId: rosterId,
      },
      true,
    );

    if (rosterId) {
      this._analyticsService.tappedViewStudentWork(
        this._assignmentId,
        Locations.ASSIGNMENT_EDIT_ROSTER,
      );
    } else {
      this._analyticsService.tappedViewStudentWork(
        this._assignmentId,
        Locations.ASSIGNMENT_EDIT_TOP,
      );
    }
  }

  /**
   * @return {string}
   */
  get defaultRosterId() {
    return this.rosters && this.rosters[0] && this.rosters[0].id;
  }

  chooseAddRoster() {
    this._chooseRosterDialog(
      this.$mdDialog,
      this.$q,
      this._rosterMap,
      this.assignment,
      this.pro,
    ).then((result) => {
      this.addRoster(result.id, result.isNew);
    });
  }

  addRoster(rosterId, refreshRosters) {
    this._loadingDialog(
      this.$mdDialog,
      this._modify(
        () =>
          this._assignmentService.addOrUpdateRoster(
            this.assignment.id,
            rosterId,
            false,
          ),
        true,
        refreshRosters,
      ),
    );
  }

  classCodeForRoster(rosterId) {
    return this._classCodes.find((x) => x.rosterId === rosterId);
  }

  shareWithStudentDialog(roster) {
    const classCode = this.classCodeForRoster(roster.id);
    this._shareWithStudentDialog(
      this.$mdDialog,
      this.assignment,
      roster,
      classCode,
      this.pro,
    );
    this._analyticsService.classCodeDialogOpened(
      roster.id,
      this._assignmentId,
      Locations.ASSIGNMENT_EDIT,
    );
  }

  /**
   * @param rosterId {string}
   * @return {Map<HelpRequestSet>}
   */
  helpRequestsForRoster(rosterId) {
    return this._rosterHelpRequests && this._rosterHelpRequests.get(rosterId);
  }

  removeRoster(rosterId) {
    if (rosterId === this._lastRosterId) {
      this._lastRosterId = '';
    }

    this._loadingDialog(
      this.$mdDialog,
      this._modify(
        () =>
          this._assignmentService.removeRoster(this.assignment.id, rosterId),
        true,
        false,
      ),
    );
  }

  /**
   * @param item {AssignmentRosterItem}
   */
  _handleClassCodeUpdated(item) {
    this._assignmentService
      .addOrUpdateRoster(
        this._assignment.id,
        item.id,
        item.classCode.showStudentScores,
        item.classCode.lockStudentWork,
        item.classCode.hideStudentWork,
        item.classCode.allowPdf,
      )
      .catch((error) => {
        this.$log.error(error);
      });
  }

  /**
   * @param roster {Roster}
   */
  _handleRosterUpdated(roster) {
    this._rosterService.update(roster).catch((error) => {
      this.$log.error(error);
    });
  }

  /**
   * Executes a change and then reloads the assignment
   * @param f {function} Function which returns a
   * @param refreshClassCodes {boolean}
   * @param refreshRosters {boolean}
   * @private
   */
  _modify(f, refreshClassCodes, refreshRosters) {
    var promise = f();

    if (!(promise && angular.isFunction(promise.then))) {
      promise = this.$q.resolve();
    }

    return promise
      .then(() => {
        return this.$q.all({
          assignment: this._cacheService.getAssignmentForUser(
            this._assignmentId,
            true,
          ),
          classCodes: refreshClassCodes
            ? this._cacheService.getClassCodesForUserAssignment(
                this._assignmentId,
                true,
              )
            : false,
          rosters: refreshRosters
            ? this._cacheService.getRostersForUser(true)
            : false,
        });
      })
      .then((data) => {
        this._initData(data.assignment, data.classCodes, data.rosters);
      })
      .catch((err) => {
        throw err;
      });
  }

  share() {
    this._assignmentManager.share(this.assignment);
  }

  exportContentToPDF() {
    this._assignmentManager.exportContentToPDF(this.assignment);
  }

  moveAssignmentToFolder() {
    this._assignmentManager.moveAssignmentToFolder(this.assignment);
  }

  /**
   * Duplicates an assignment
   */
  duplicate() {
    this._assignmentManager.checkAssignmentLimit(
      Locations.ASSIGNMENT_ASSIGN_NAV,
      this.assignment.id,
      () => {
        this._assignmentManager.duplicate(
          this.assignment.id,
          this.assignment.name,
        );
      },
    );
  }

  /**
   * Deletes an assignment
   */
  delete() {
    this._assignmentManager.delete(this.assignment).then(() => {
      this._breadcrumbService.goBack('root.account.home', {}, true);
    });
  }

  exportGrades() {
    this._assignmentManager.exportGrades(
      this.assignment,
      this._rosters,
      this.pro,
    );
  }

  exportAnswers() {
    this._assignmentManager.exportAnswers(
      this.assignment,
      this._rosters,
      this.pro,
    );
  }

  makeFirstRosterPulse() {
    CkAnimations.makeElementPulse(
      '.assignment-rosters .list-item:not(.list-button)',
    );
  }

  showHelp() {
    return this._showHelp(ViewHelps.AssignmentRosters);
  }

  showAllowPeerHelpersHelp() {
    return this._showHelp(ViewHelps.AllowPeerHelpers);
  }

  showShowStudentScoresHelp() {
    return this._showHelp(ViewHelps.ShowStudentScores);
  }

  showLockAssignmentHelp() {
    return this._showHelp(ViewHelps.LockAssignment);
  }

  showHideAssignmentHelp() {
    return this._showHelp(ViewHelps.HideAssignment);
  }

  _showHelp(value) {
    this._helpDialog(this.$mdDialog, value);
  }

  openStudentPreview() {
    PreviewStudentUtil.openStudentPreview({
      analyticsService: this._analyticsService,
      pageLocation: Locations.ASSIGNMENT_ASSIGN_NAV,
      assignment: this._assignment,
      assignmentService: this._assignmentService,
      loadingDialog: this._loadingDialog,
      $mdDialog: this.$mdDialog,
      $location: this.$location,
      $window: this.$window,
    });
  }

  openGradebookExport(rosterId) {
    if (!this.hasGradeExport) {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.GradebookIntegration,
        PaywallSources.GRADEBOOK_INTEGRATION,
      );
    } else {
      this._storageService.ckRosterId = rosterId;
      this._storageService.ckAssignmentId = this._assignmentId;
      this._analyticsService.gradebookOpened('assignmentAssign');
      return this._gradeExportDialog(this.$mdDialog);
    }
  }
}
