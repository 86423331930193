'use strict';

export default class MediaService {
  constructor(environment, HttpService, FirebaseService) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
  }

  /**
   * @param file {Blob}
   * @param intent {string}
   * @param elementListId {string}
   * @returns {Promise.<{mediaLink: string, newElementId: string}>}
   */
  create(file, intent, elementListId) {
    let newElementId = this._firebaseService.newId();
    let formData = new FormData();
    formData.append('file', file);
    formData.append('intent', intent);
    formData.append('element_list_id', elementListId);

    return this._httpService
      .authPost(
        `${this._environment.serverUrlBase}/v1/media/${newElementId}`,
        formData,
        {
          headers: {
            'Content-Type': undefined,
          },
          transformRequest: angular.identify,
        },
      )
      .then((data) => {
        return {
          mediaLink: data.media_link,
          newElementId: newElementId,
        };
      });
  }

  /**
   * @param elementId {string}
   * @param mediaLink {string}
   * @param elementListId {string}
   * @returns {Promise.<T>}
   */
  delete(elementId, mediaLink, elementListId) {
    return this._httpService.authDelete(
      `${this._environment.serverUrlBase}/v1/media/${elementId}`,
      {
        data: {
          media_link: mediaLink,
          element_list_id: elementListId,
        },
      },
    );
  }

  /**
   * @param elementId {string}
   * @param mediaLink {string}
   * @param elementListId {string}
   * @returns {Promise.<T>}
   */
  restore(elementId, mediaLink, elementListId) {
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/media/${elementId}/restore`,
      {
        media_link: mediaLink,
        element_list_id: elementListId,
      },
    );
  }

  /**
   * @param elementId {string}
   * @param mediaLink {string}
   * @param elementListId {string}
   * @returns {Promise.<{mediaLink: string}>}
   */
  copy(elementId, mediaLink, elementListId) {
    return this._httpService
      .authPost(
        `${this._environment.serverUrlBase}/v1/media/${elementId}/copy`,
        {
          media_link: mediaLink,
          element_list_id: elementListId,
        },
      )
      .then((data) => {
        return data.media_link;
      });
  }
}
