import SlideBackground from './elements/slide-background';
import SlideForeground from './elements/slide-foreground';
import Sticker from './elements/sticker';
import { ElementIntents } from '../domain/element-metadata';

export default class SvgCanvasLayers {
  /**
   * @param snap {Snap}
   */
  constructor(snap) {
    this._snap = snap;

    this._root = undefined;
    this._background = undefined;
    this._content = undefined;
    this._work = undefined;
    this._sticker = undefined;
    this._foreground = undefined;
  }

  get root() {
    if (!this._root) {
      this._root = this._snap.select('.root');
    }
    return this._root;
  }

  get background() {
    if (!this._background) {
      this._background = this._snap.select('.background');
    }
    return this._background;
  }

  get content() {
    if (!this._content) {
      this._content = this._snap.select('.root .content');
    }
    return this._content;
  }

  get work() {
    if (!this._work) {
      this._work = this._snap.select('.root .work');
    }
    return this._work;
  }

  get sticker() {
    if (!this._sticker) {
      this._sticker = this._snap.select('.root .sticker');
    }
    return this._sticker;
  }

  get foreground() {
    if (!this._foreground) {
      this._foreground = this._snap.select('.foreground');
    }
    return this._foreground;
  }

  /**
   * @param element {Element}
   */
  pickLayer(element) {
    if (element.type === SlideBackground.type) {
      return this.background;
    } else if (element.type === SlideForeground.type) {
      return this.foreground;
    } else if (element.type === Sticker.type) {
      return this.sticker;
    } else if (
      element.metadata.intent === ElementIntents.WORK ||
      element.metadata.intent === ElementIntents.FEEDBACK
    ) {
      return this.work;
    } else if (element.metadata.intent === ElementIntents.CONTENT) {
      return this.content;
    }
  }

  /**
   * Clears the contents of this canvas by clearing each layer
   */
  clear() {
    this.background.clear();
    this.sticker.clear();
    this.content.clear();
    this.work.clear();
    this.foreground.clear();
  }
}
