'use strict';

import Trig from '../util/trig';

export default class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  /**
   * @param offset {left: number, top: number}
   * @returns {Point}
   */
  static fromOffset(offset) {
    return new Point(offset.left, offset.top);
  }

  /**
   * @param point {Point}
   * @returns {Point}
   */
  plus(point) {
    return new Point(this.x + point.x, this.y + point.y);
  }

  /**
   * @param point {Point}
   * @returns {Point}
   */
  minus(point) {
    return new Point(this.x - point.x, this.y - point.y);
  }

  /**
   * Creates a new point which is scaled relative to the original and the origin
   * @param multiplier {number}
   * @returns {Point}
   */
  times(multiplier) {
    return new Point(this.x * multiplier, this.y * multiplier);
  }

  /**
   * @param point {Point}
   * @returns {boolean}
   */
  equals(point) {
    return this.x === point.x && this.y === point.y;
  }

  /**
   * @param point {Point}
   * @returns {number}
   */
  clockwiseAngle(point) {
    return Trig.toDeg(Trig.clockwiseAngle(this, point));
  }

  /**
   * Returns the distance between two points
   * @param {Point}
   * @returns {Number}
   */
  getDist(point) {
    let dx = this.x - point.x;
    let dy = this.y - point.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  /**
   * Rotates a point clockwise about the origin by the specified number of degrees
   * @param degrees {number}
   * @returns {Point}
   */
  rotate(degrees) {
    let rad = Trig.toRad(degrees);
    let cos = Math.cos(rad);
    let sin = Math.sin(rad);

    return new Point(this.x * cos - this.y * sin, this.y * cos + this.x * sin);
  }
}
