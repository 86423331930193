'use strict';

import ActiveHelper from '../../model/domain/active-helper';
import HelpRequestNotification from '../../model/domain/help-request-notification';
import RosterNotification from '../../model/domain/roster-notification';
import UserStatusNotification from '../../model/domain/user-status-notification';
import AssignmentStatusNotification from '../../model/domain/assignment-notification';
import AssignmentWorkNotification from '../../model/domain/assignment-work-notification';
import UserNewMessageNotification from '../../model/domain/user-new-message-notification';
import HelpersNotification from '../../model/domain/helpers-notification';
import UserStatusEditor from '../../model/domain/user-status-editor';
import UserRosterNotification from '../../model/domain/user-roster-notification';
import AssignmentRosterNotification from '../../model/domain/assignment-roster-notification';
import AccountNotification from '../../model/domain/account-notification';

export default class NotificationService {
  constructor(FirebaseService, AuthService, NotificationShardKeyFactory) {
    'ngInject';

    this._firebaseService = FirebaseService;
    this._authService = AuthService;
    this._notificationShardKeyFactory = NotificationShardKeyFactory;
  }

  /**
   * Returns notification object for help requests associated with an assignment
   *
   * @param assignmentOwnerId {string}
   * @param assignmentId {string}
   * @param [showInitialNotification] {boolean}
   * @returns {HelpRequestNotification}
   */
  getHelpRequestNotificationForAssignment(
    assignmentOwnerId,
    assignmentId,
    showInitialNotification,
  ) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forHelpRequestForAssignment(
        assignmentOwnerId,
      );
    return HelpRequestNotification.forAssignment(
      notificationShardKey,
      assignmentId,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   * Returns notification object for help requests associated with a roster
   *
   * @param rosterOwnerId {string}
   * @param rosterId {string}
   * @param [showInitialNotification] {boolean}
   * @returns {HelpRequestNotification}
   */
  getHelpRequestNotificationForRoster(
    rosterOwnerId,
    rosterId,
    showInitialNotification,
  ) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forHelpRequestForRoster(rosterOwnerId);
    return HelpRequestNotification.forRoster(
      notificationShardKey,
      rosterId,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   * Returns notification object for help requests associated with an assignment and roster
   *
   * @param assignmentOwnerId {string}
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param [showInitialNotification] {boolean}
   * @returns {HelpRequestNotification}
   */
  getHelpRequestNotificationForPeers(
    assignmentOwnerId,
    assignmentId,
    rosterId,
    showInitialNotification,
  ) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forHelpRequestForPeers(
        assignmentOwnerId,
      );
    return HelpRequestNotification.forPeers(
      notificationShardKey,
      assignmentId,
      rosterId,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   * Returns a notification object for updates to a roster
   *
   * @param roster {Roster}
   * @returns {RosterNotification}
   */
  getRosterNotification(roster) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forRoster(roster);
    return new RosterNotification(
      notificationShardKey,
      roster.id,
      this._firebaseService,
    );
  }

  /**
   * Returns a notification object for updates to a user's status
   *
   * @param userId {string}
   * @param [showInitialNotification] {boolean}
   * @returns {UserStatusNotification}
   */
  getUserStatusNotification(userId, showInitialNotification) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forUserStatus(userId);
    return new UserStatusNotification(
      notificationShardKey,
      userId,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   * Returns a notification object for updates to an assignment
   *
   * @param assignment {Assignment}
   * @param [showInitialNotification] {boolean}
   * @returns {AssignmentStatusNotification}
   */
  getAssignmentStatusNotification(assignment, showInitialNotification) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forAssignment(assignment);
    return new AssignmentStatusNotification(
      notificationShardKey,
      assignment.id,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   * Returns a notification object for updates to the scores on a user's assignment work questions
   *
   * @param assignmentId {string}
   * @param assignmentWork {AssignmentWork}
   * @param [showInitialNotification] {boolean}
   * @returns {AssignmentWorkNotification}
   */
  getAssignmentWorkNotification(assignmentWork, showInitialNotification) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forAssignmentWork(assignmentWork);
    return new AssignmentWorkNotification(
      notificationShardKey,
      assignmentWork.assignmentId,
      assignmentWork.id,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   *
   * @param assignment {Assignment}
   * @param rosterId
   * @param showInitialNotification
   * @returns {AssignmentRosterNotification}
   */
  getAssignmentRosterNotification(
    assignment,
    rosterId,
    showInitialNotification,
  ) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forAssignmentRoster(assignment);
    return new AssignmentRosterNotification(
      notificationShardKey,
      assignment.id,
      rosterId,
      this._firebaseService,
      showInitialNotification,
    );
  }

  /**
   * Returns a notification object for updates to a user's messages. Currently the UI implementation of these messages
   * is the user's Notifications tab.
   *
   * @returns {UserNewMessageNotification}
   */
  getNewMessageNotificationForCurrentUser() {
    let userId = this._authService.authData.id;
    let notificationShardKey =
      this._notificationShardKeyFactory.forUserNewMessage(userId);
    return new UserNewMessageNotification(
      notificationShardKey,
      userId,
      this._firebaseService,
      false,
    );
  }

  /**
   * @param assignment {Assignment}
   * @param helpeeId {string}
   * @returns {HelpersNotification}
   */
  getHelpersNotification(assignment, helpeeId) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forQuestionHelpers(assignment);
    return new HelpersNotification(
      notificationShardKey,
      helpeeId,
      this._firebaseService,
    );
  }

  /**
   * Gets an object for writing the status for the specified user
   * @param userId {string}
   * @returns {UserStatusEditor}
   */
  getUserStatusEditor(userId) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forUserStatus(userId);
    return new UserStatusEditor(
      notificationShardKey,
      userId,
      this._firebaseService,
    );
  }

  /**
   * Creates an ActiveHelper object for the given helper
   * @param assignment {Assignment}
   * @param helper {Helper}
   * @returns {ActiveHelper}
   */
  getActiveHelper(assignment, helper) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forQuestionHelpers(assignment);
    return new ActiveHelper(
      notificationShardKey,
      helper,
      this._firebaseService,
    );
  }

  /**
   * @param userId {string}
   * @returns {UserRosterNotification}
   */
  getUserRosterNotification(userId) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forUserRoster(userId);
    return new UserRosterNotification(
      notificationShardKey,
      userId,
      this._firebaseService,
    );
  }

  /**
   * @param userId {string}
   * @returns {AccountNotification}
   */
  getAccountNotification(userId) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forAccount(userId);
    return new AccountNotification(
      notificationShardKey,
      userId,
      this._firebaseService,
    );
  }
}
