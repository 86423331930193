export default class GradebookIntegrationStatus {
  /**
   * @param isLinked {boolean}
   * @param isLinkActive {boolean}
   * @param numberOfInvites {number}
   * @param minimumWaitTime {number}
   * @param lmsEmail {string}
   * @param previousAdminEmail {string | null}
   */
  constructor(
    isLinked,
    isLinkActive,
    numberOfInvites,
    minimumWaitTime,
    lmsEmail,
    previousAdminEmail,
  ) {
    this._isLinked = isLinked;
    this._isLinkActive = isLinkActive;
    this._numberOfInvites = numberOfInvites;
    this._minimumWaitTime = minimumWaitTime;
    this._lmsEmail = lmsEmail;
    this._previousAdminEmail = previousAdminEmail;
  }

  get isLinked() {
    return this._isLinked;
  }
  get isLinkActive() {
    return this._isLinkActive;
  }
  get numberOfInvites() {
    return this._numberOfInvites;
  }

  get minimumWaitTime() {
    return this._minimumWaitTime;
  }

  get lmsEmail() {
    return this._lmsEmail;
  }
  get previousAdminEmail() {
    return this._previousAdminEmail;
  }
}
