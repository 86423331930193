'use strict';

/**
 * Contains either the teacher or student portion of one question/page within an assignment
 */
export default class AssignmentQuestion {
  /**
   * @param id {string} the assignment question id
   * @param elementList {ElementList}
   * @param [points] {number}
   * @param [title] {string|null} the title of the question (optional)
   */
  constructor(id, elementList, points = null, title = null) {
    this._id = id;
    this._elements = elementList;
    this._points = points;
    this._title = title;
  }

  /**
   * @returns {boolean}
   */
  get isWork() {
    return false;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get elementListId() {
    return this._elements.id;
  }

  /**
   * @return {string}
   */
  get elementListDb() {
    return this._elements.firebaseInstanceId;
  }

  /**
   * @returns {FirebaseCollection.<Element>}
   */
  get elements() {
    return this._elements.elements;
  }

  /**
   * @return {Promise.<Number>}
   */
  get elementCount() {
    return this._elements.elementCount;
  }

  /**
   * @return {Promise<>}
   */
  get allElements() {
    return this._elements.allElements;
  }

  /**
   * @returns {number}
   */
  get points() {
    return this._points;
  }

  /**
   * @param value {number}
   */
  set points(value) {
    this._points = value;
  }

  /**
   * @returns {string}
   */
  get title() {
    return this._title;
  }

  /**
   * @param element
   */
  remoteAdd(element) {
    if (!element.metadata) {
      throw new Error('no metadata found');
    }

    // This must be called to ensure elements are received in the correct order
    this._elements.start();
    return this._elements.remoteAdd(element);
  }

  /**
   * @param element {Element}
   */
  saveElement(element) {
    if (!element.metadata) {
      throw new Error('no metadata found');
    }

    // This must be called to ensure elements are received in the correct order
    // TODO: Figure out a way around this
    this._elements.start();
    return this._elements.saveElement(element);
  }

  /**
   * @param element {Element}
   */
  removeElement(element) {
    return this._elements.removeElement(element);
  }

  start() {
    this._elements.start();
  }

  stop() {
    this._elements.stop();
  }

  /**
   * Merges another instance of this AssignmentQuestion into
   * this AssignmentQuestion, preserving any firebase data
   * cached therein.
   *
   * @param target {AssignmentQuestion}
   * @returns {AssignmentQuestion}
   */
  mergeFrom(target) {
    if (target.id === this.id) {
      this.stop();
      this._elements = target._elements;
    }
    return this;
  }
}
