'use strict';

export default class FirebaseServiceUtils {
  /**
   * Attaches several extra methods to a Firebase ref
   * @param ref {firebase.database.Reference} a firebase ref
   * @param $q {$q} the angular promise service
   * @param urlBase {string} the base url for the firebase instance
   * @returns {firebase.database.Reference}
   */
  attachExtras(ref, $q, urlBase) {
    ref.$parent = this.$parent($q, urlBase);
    ref.$child = this.$child($q, urlBase);
    ref.$once = this.$once($q);
    ref.$uri = this.$uri(urlBase);
    return ref;
  }

  // Gets a parent with the extra methods attached
  $parent($q, urlBase) {
    // this. is a FirebaseServiceUtils
    var _this = this;
    return function () {
      // this. is a Firebase
      return _this.attachExtras(this.parent, $q, urlBase);
    };
  }

  // Gets a child with the extra methods attached
  $child($q, urlBase) {
    // this. is a FirebaseServiceUtils
    var _this = this;
    return function (uri) {
      // this. is a Firebase
      return _this.attachExtras(this.child(uri), $q, urlBase);
    };
  }

  // Creates a function which wraps the firebase once() method
  // as a promise. The resulting value is the firebase snapshot
  $once($q) {
    return function (event) {
      // this. is a Firebase
      return $q((resolve, reject) => {
        this.once(event, (snapshot, error) => {
          if (error) {
            return reject(error);
          }

          resolve(snapshot);
        });
      });
    };
  }

  // Returns the firebase uri - the protocol, server, and port will be stripped off
  $uri(urlBase) {
    return function () {
      // this. is a Firebase
      return this.toString().replace(urlBase, '');
    };
  }
}
