'use strict';

import Codec from './codec';

/**
 * Encodes json from javascript values
 */
export default class JsonCodec extends Codec {
  /**
   * @param value {object|Array|boolean|number|string}
   * @returns {string}
   */
  encode(value) {
    return angular.toJson(value);
  }

  /**
   * @param value {string}
   * @returns {object|Array|boolean|number|string}
   */
  decode(value) {
    return angular.fromJson(value);
  }
}
