'use strict';

import { PaywallSources } from '../../services/mixpanel/mixpanel.service';
import ViewHelpDialogController, {
  ViewHelps,
} from '../view-help-dialog/view-help-dialog.controller';
import ContextualPaywallDialogController, {
  ContextualPaywalls,
} from '../contextual-paywall-dialog/contextual-paywall-dialog.controller';
import StudentAccountHelpDialogTemplate from './student-account-help-dialog.html';

export default class StudentAccountHelpDialogController {
  /**
   * @ngInject
   */
  constructor($q, $mdDialog, pro) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;

    this._pro = pro;

    this._helpDialog = ViewHelpDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;
  }

  get pro() {
    return this._pro;
  }

  set pro(value) {
    this._pro = value;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  learnMorePortfolio() {
    if (this.pro.hasStudentAccounts && !this.pro.isTrial) {
      this._helpDialog(this.$mdDialog, ViewHelps.StudentAccountPortfolio);
    } else {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.StudentAccounts,
        PaywallSources.STUDENT_ACCOUNTS_HELP_DIALOG,
      );
    }
  }

  learnMoreBasic() {
    this._helpDialog(this.$mdDialog, ViewHelps.StudentAccountBasic);
  }

  /**
   * @param $mdDialog
   * @param pro {ProInfo}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, pro) {
    return $mdDialog.show({
      controller: StudentAccountHelpDialogController,
      template: StudentAccountHelpDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      locals: {
        pro,
      },
    });
  }
}
