'use strict';

import moment from 'moment';
import Notification from './notification';
import JSEvent from '../util/js-event';
import NotificationShardKey from './notification-shard-key.js';

class AssignmentStatusChange {
  /**
   * @param change {string}
   * @param questionId {string}
   * @param beforeQuestionId {string}
   * @param time {string}
   * @param points {string}
   */
  constructor(change, questionId, beforeQuestionId, time, points) {
    this._change = change;
    this._questionId = questionId;
    this._beforeQuestionId = beforeQuestionId;
    this._time = moment(time);
    this._points = points;
  }

  static apply(obj) {
    if (obj) {
      return new AssignmentStatusChange(
        obj.change,
        obj.question_id,
        obj.before_question_id,
        obj.t,
        obj.points,
      );
    }
    return null;
  }

  /**
   * @returns {string}
   */
  get change() {
    return this._change;
  }

  /**
   * @returns {string}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @returns {string}
   */
  get beforeQuestionId() {
    return this._beforeQuestionId;
  }

  /**
   * @returns {moment}
   */
  get time() {
    return this._time;
  }

  /**
   * @return {string|*}
   */
  get points() {
    return this._points;
  }
}

export default class AssignmentStatusNotification extends Notification {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param assignmentId {string}
   * @param FirebaseService {FirebaseService}
   * @param [showInitialNotification] {boolean}
   */
  constructor(
    notificationShardKey,
    assignmentId,
    FirebaseService,
    showInitialNotification = true,
  ) {
    super(
      Notification.V3,
      notificationShardKey,
      `assignments/${assignmentId}/status`,
      FirebaseService,
      showInitialNotification,
      'AssignmentStatusNotification',
    );

    this._questionAdded = new JSEvent(this);
    this._questionRemoved = new JSEvent(this);
    this._questionUpdated = new JSEvent(this);
    this._assignmentUpdated = new JSEvent(this);
    this._assignmentDeleted = new JSEvent(this);

    this._logTrace('cstr complete');
  }

  static get QUESTION_ADDED() {
    return 'questionAdded';
  }

  static get QUESTION_REMOVED() {
    return 'questionRemoved';
  }

  static get QUESTION_UPDATED() {
    return 'questionUpdated';
  }

  static get ASSIGNMENT_UPDATED() {
    return 'metadata';
  }

  static get ASSIGNMENT_DELETED() {
    return 'assignmentDeleted';
  }

  /**
   * @returns {JSEvent}
   */
  get questionAdded() {
    return this._questionAdded;
  }

  /**
   * @returns {JSEvent}
   */
  get questionRemoved() {
    return this._questionRemoved;
  }

  /**
   * @returns {JSEvent}
   */
  get questionUpdated() {
    return this._questionUpdated;
  }

  /**
   * @returns {JSEvent}
   */
  get assignmentUpdated() {
    return this._assignmentUpdated;
  }

  /**
   * @returns {JSEvent}
   */
  get assignmentDeleted() {
    return this._assignmentDeleted;
  }

  /**
   * @returns {AssignmentStatusChange|null}
   */
  get value() {
    return this._value;
  }

  _changed(value) {
    this._value = AssignmentStatusChange.apply(value);
    if (this._value) {
      this._logTrace(
        '_changed change=' +
          this._value.change +
          ', questionId=' +
          this._value.question_id,
      );
      if (this._value.change === AssignmentStatusNotification.QUESTION_ADDED) {
        this._questionAdded.raise(this._value);
      } else if (
        this._value.change === AssignmentStatusNotification.QUESTION_REMOVED
      ) {
        this._questionRemoved.raise(this._value);
      } else if (
        this._value.change === AssignmentStatusNotification.QUESTION_UPDATED
      ) {
        this._questionUpdated.raise(this._value);
      } else if (
        this._value.change === AssignmentStatusNotification.ASSIGNMENT_UPDATED
      ) {
        this._assignmentUpdated.raise(this._value);
      } else if (
        this._value.change === AssignmentStatusNotification.ASSIGNMENT_DELETED
      ) {
        this._assignmentDeleted.raise(this._value);
      }
    }
  }
}
