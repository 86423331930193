'use strict';

import LoadingDialog from '../../components/loading-dialog/loading-dialog.controller';
import { SessionDataCodes } from '../../model/domain/session-data';
import { PeerHelpInboxController } from '../../components/peer-help-inbox/peer-help-inbox.controller';
import StudentAccountHelpDialogController from '../../components/student-account-help-dialog/student-account-help-dialog.controller';
import { StudentInfoMenuController } from '../../components/student-info-menu/student-info-menu.controller';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';

export default class SessionWatchController {
  constructor(
    $q,
    $scope,
    $state,
    $stateParams,
    $document,
    $timeout,
    $mdDialog,
    $mdPanel,
    $window,
    CacheService,
    BreadcrumbService,
    hotkeys,
    PlatformHeaderService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this._window = angular.element($window);

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;

    this._assignmentId = $stateParams.assignmentId;
    this._rosterId = $stateParams.rosterId;

    this._platformHeaderService.setAssignmentId(this._assignmentId);
    this._platformHeaderService.setRosterId(this._rosterId);

    this._error = null;

    /** @type {SessionData} */
    this._sessionData = null;

    this._loadingDialog = LoadingDialog.show;
    this._showPeerHelpInbox = PeerHelpInboxController.show;

    this._scrollSpacerWidth = '0px';
    this._nullMethod = () => {};

    this._scrollTop = 0;
    this._scrollLeft = 0;
    this._scrollItems = 3;
    this._scrollPeriod = 300;

    this._showStudentInfoMenu = StudentInfoMenuController.show;
    this._showStudentAccountHelpDialog =
      StudentAccountHelpDialogController.show;
    this._helpDialog = ViewHelpDialogController.show;

    this._anonOnResize = () => this._debounceApply();

    $scope.$on('$destroy', () => this._destroy());

    this._initHotkeys($scope, hotkeys);
    this._init();
  }

  _initHotkeys(scope, hotkeys) {
    hotkeys
      .bindTo(scope)
      .add({
        combo: 'left',
        description: 'Scroll Left',
        callback: () => {
          if (this.canScrollLeft) {
            this.scrollLeft();
          }
        },
      })
      .add({
        combo: 'right',
        description: 'Scroll Right',
        callback: () => {
          if (this.canScrollRight) {
            this.scrollRight();
          }
        },
      });
  }
  _init() {
    this._cacheService
      .getSessionData(this._assignmentId, this._rosterId, false, true)
      .then((data) => {
        this._sessionData = data;

        if (!this.data.isComplete) {
          if (!this.data.hasRosters) {
            this._error = new Error(
              'There are no rosters associated with this assignment.',
            );
            this._error.noRosters = true;
            this._sessionData = null;
            return undefined;
          } else {
            this.goToNewRoster(this.data.assignmentRosters[0].id);
            this._sessionData = null;
            return undefined;
          }
        } else if (this.data.teacher.sessionViewShowWork) {
          this.goToWorkView();
          this._sessionData = null;
          return undefined;
        }

        this._sessionData.updated.subscribe(this._onSessionDataUpdated, this);

        this.data.configureSortFromStorage();

        this._fixFFLayout();
        this._initScrollSync();
      })
      .catch((err) => {
        this._error = err;
        throw err;
      });
  }

  _destroy() {
    this._platformHeaderService.setAssignmentId(undefined);
    this._platformHeaderService.setRosterId(undefined);
    this._window.off('resize', this._anonOnResize);
  }

  _initScrollSync() {
    this._window.on('resize', this._anonOnResize);

    this._scrollLeftButton = angular.element(
      '.session-watch .body-head .horizontal-scroll-button',
    );
    this._mainScroller = angular.element(
      '.session-watch .body-head .horizontal-scroll',
    );
    this._studentScroller = angular.element(
      '.session-watch .body-content .horizontal-scroll',
    );
    this._verticalScroller = angular.element('.session-watch .body-content');

    this._anonOnHScroll = this._onHScroll.bind(this);
    this._anonOnVScroll = this._onVScroll.bind(this);

    this._mainScroller.on('scroll', this._anonOnHScroll);
    this._studentScroller.on('scroll', this._anonOnHScroll);
    this._verticalScroller.on('scroll', this._anonOnVScroll);

    this.$timeout(() => {}, 0, false).then(() => {
      return this.$timeout(() => {
        this._scrollSpacerWidth = `${this._studentScroller.outerWidth() - this._mainScroller.width() - this._scrollLeftButton.width()}px`;
      });
    });
  }

  _fixFFLayout() {
    this.$timeout(() => {}, 0, false).then(() => {
      return this.$timeout(() => {
        // Firefox hack #firefoxSux
        let studentContent = angular.element(
          '.session-watch .body-content .horizontal-scroll-content',
        );
        let studentContentHeight = studentContent.outerHeight();
        this.studentContentHeight = `${studentContentHeight}px`;

        let verticalScrollerHeight = this._verticalScroller.outerHeight();
        let classCodeElem = angular.element(
          '.session-watch .body-content .class-code-tip',
        );
        let studentInfoHeight = Math.max(
          verticalScrollerHeight,
          studentContentHeight + classCodeElem.height(),
        );
        this.studentInfoHeight = `${studentInfoHeight}px`;

        this._cellWidth = angular
          .element('.session-watch .body-head .question-cell-header')
          .outerWidth(true);
      });
    });
  }

  //--------------- Scrolling methods -------------------------

  _debounceApply() {
    if (this._debounceApplyPromise) {
      this.$timeout.cancel(this._debounceApplyPromise);
    }
    this._debounceApplyPromise = this.$timeout(this._nullMethod, 200);
  }

  _onVScroll(event) {
    let target = angular.element(event.target);
    this._scrollTop = target.scrollTop();
  }

  _scrollVertical(top) {
    this._verticalScroller.scrollTop(top);
  }

  _onHScroll(event) {
    let target = angular.element(event.target);
    if (target.scrollLeft() !== this._scrollLeft) {
      this._scrollHorizontal(target.scrollLeft());
      this._debounceApply();
    }
  }

  _scrollHorizontal(left) {
    this._scrollLeft = left;
    this._mainScroller.scrollLeft(left);
    this._studentScroller.scrollLeft(left);

    // CKW-1112 - If it didn't work just try again. Couldn't figure out root cause
    // Setting _studentScroller.scrollLeft just doesn't work sometimes
    if (
      this._studentScroller.scrollLeft() === 0 &&
      this._studentScroller.scrollLeft() !== left
    ) {
      this.$timeout(() => this._scrollHorizontal(left), 10, false);
    }
  }

  _animateScrollHorizontal(left) {
    this._mainScroller.animate({ scrollLeft: left }, this._scrollPeriod);
  }

  get scrollSpacerWidth() {
    return this._scrollSpacerWidth;
  }

  get canScrollLeft() {
    return this._scrollLeft > 0;
  }

  get cannotScrollLeft() {
    return !this.canScrollLeft;
  }

  scrollLeft() {
    let scroll = Math.max(
      0,
      this._mainScroller.scrollLeft() - this._scrollItems * this._cellWidth,
    );
    let offset = scroll % this._cellWidth;
    if (offset !== 0) {
      scroll = scroll - offset + this._cellWidth;
    }
    this._animateScrollHorizontal(scroll);
  }

  get canScrollRight() {
    return this._scrollLeft <= this.scrollWidth - 2;
  }

  get cannotScrollRight() {
    return !this.canScrollRight;
  }

  scrollRight() {
    let scroll = Math.min(
      this.scrollWidth,
      this._mainScroller.scrollLeft() + this._scrollItems * this._cellWidth,
    );
    let offset = scroll % this._cellWidth;
    if (scroll !== this.scrollWidth && offset !== 0) {
      scroll = scroll - offset;
    }
    this._animateScrollHorizontal(scroll);
  }

  get scrollWidth() {
    if (this._mainScroller) {
      return this._mainScroller[0].scrollWidth - this._mainScroller.width();
    } else {
      return 0;
    }
  }

  get scrollHeight() {
    if (this._verticalScroller) {
      return (
        this._verticalScroller[0].scrollHeight - this._verticalScroller.height()
      );
    } else {
      return 0;
    }
  }

  //--------------- Data methods -------------------------

  /**
   * @returns {SessionData}
   */
  get data() {
    return this._sessionData;
  }

  /**
   * @param event {{code: string}}
   * @private
   */
  _onSessionDataUpdated(event) {
    const { code, userIsNowOnline, userIsLoggingOnForFirstTime } = event;

    if (code === SessionDataCodes.ROSTER_MEMBER) {
      this.data.studentSortDebounce.tick();
      this._fixFFLayout();
    } else if (
      code === SessionDataCodes.STUDENT_HIDE_OFFLINE ||
      code === SessionDataCodes.STUDENT_HIDE_UNSTARTED
    ) {
      this._fixFFLayout();
    } else if (
      code === SessionDataCodes.STUDENT_STATUS &&
      (userIsNowOnline || userIsLoggingOnForFirstTime)
    ) {
      this.data.studentSortDebounce.tick();
    }

    this._debounceApply();
  }

  get error() {
    return this._error;
  }

  get loaded() {
    return !this.error && !this.loading;
  }

  get loading() {
    return !this.data && !this.error;
  }

  //-------------------- Navigate Methods ------------------------------

  /**
   * @param question {AssignmentQuestion}
   * @param event
   */
  goToEditQuestion(question, event) {
    event.stopPropagation();

    this._breadcrumbService.go('root.account.assignment-question', {
      assignmentId: this._assignmentId,
      questionId: question.id,
    });
  }

  goToWorkView() {
    this._breadcrumbService.go(
      'root.account.session.work',
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: this._rosterId,
      },
      true,
    );
  }

  goToNewRoster(rosterId) {
    this._breadcrumbService.go(
      this.$state.current.name,
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: rosterId,
      },
      true,
    );
  }

  goToRosterEdit() {
    this._breadcrumbService.go('root.account.nav.roster', {
      rosterId: this._rosterId,
    });
  }

  goToFeedbackQuestion(userId, questionId, $event) {
    if (this.data.workForStudent(userId)) {
      this._navToFeedbackQuestion(userId, questionId);
    } else {
      let promise = this.data.fetchDataForStudent(userId);

      this._loadingDialog(
        this.$mdDialog,
        promise,
        this.$document,
        '.session-watch .body-content',
        $event,
      ).then(() => {
        this._navToFeedbackQuestion(userId, questionId);
      });
    }
  }

  _navToFeedbackQuestion(userId, questionId) {
    this._breadcrumbService.go('root.account.session.watch.feedback', {
      assignmentId: this._assignmentId,
      questionId: questionId,
      assignmentWorkId: this.data.workForStudent(userId).id,
    });
  }

  openPeerHelpInbox($event, user) {
    let helpHistory = this.data.studentHelpHistorySortedChronologically(
      user.id,
    );
    let helpers = this.data.studentActiveHelpers(user.id);

    this._showPeerHelpInbox(
      this.$mdPanel,
      this.$q,
      $event,
      user,
      helpHistory,
      helpers,
      this.data.students,
      this.data.assignment,
    ).then(({ userId, questionId, event }) => {
      this.goToFeedbackQuestion(userId, questionId, event);
    });
  }

  showStudentInfoMenu(event, user) {
    this._showStudentInfoMenu(
      this.$mdPanel,
      this.$q,
      event,
      user,
      this.data.studentLastSeen(user.id),
      this.data.pro,
      this._rosterId,
    );
  }

  openStudentAccountHelpDialog() {
    this._showStudentAccountHelpDialog(this.$mdDialog, this.data.pro);
  }

  openGivingFeedbackHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.GivingFeedback);
  }
}
