import UserSlideBackgroundCodec from '../../model/codec/user-slide-background-codec';

export default class SlideBackgroundService {
  /**
   * @ngInject
   */
  constructor(FirebaseService, AuthService) {
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {AuthService} */
    this._authService = AuthService;

    this._userSlideBackgroundCodec = new UserSlideBackgroundCodec();
  }

  get StockSlideBackgroundIdentifier() {
    return 'stock_slide_background_identifier';
  }

  getStockUrls() {
    return this._firebaseService
      .ref(
        `slide_backgrounds/${this.StockSlideBackgroundIdentifier}`,
        this._firebaseService.DefaultInstanceId,
      )
      .$once('value')
      .then((snapshot) => {
        return snapshot.exists()
          ? this._formatUserSlideBackgrounds(snapshot.val())
          : [];
      });
  }

  getCustomUrls() {
    return this._firebaseService
      .ref(
        `slide_backgrounds/${this._authService.currentUserId}`,
        this._firebaseService.DefaultInstanceId,
      )
      .$once('value')
      .then((snapshot) => {
        return snapshot.exists()
          ? this._formatUserSlideBackgrounds(snapshot.val())
          : [];
      });
  }

  _formatUserSlideBackgrounds(obj) {
    let result = [];
    angular.forEach(obj, (value, id) => {
      result.push(this._userSlideBackgroundCodec.decode(value, id));
    });
    return result;
  }

  /**
   * @param value {UserSlideBackground}
   * @return {firebase.Promise<void>}
   */
  setCustomUrl(value) {
    return this._firebaseService
      .ref(
        `slide_backgrounds/${this._authService.currentUserId}/${value.id}`,
        this._firebaseService.DefaultInstanceId,
      )
      .update(this._userSlideBackgroundCodec.encode(value));
  }

  deleteCustomUrl(id) {
    return this._firebaseService
      .ref(
        `slide_backgrounds/${this._authService.currentUserId}/${id}`,
        this._firebaseService.DefaultInstanceId,
      )
      .remove();
  }
}
