'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import { FillInTheBlankAnswerCodec } from './fill-in-the-blank-parent-codec';
import FillInTheBlankChild from '../ui/elements/fill-in-the-blank-child';
import TextColorArrayCodec from './text-color-array-codec';

export default class FillInTheBlankChildCodec extends Codec {
  constructor() {
    super();
    this._fillInTheBlankAnswerCodec = new FillInTheBlankAnswerCodec();
    this._colorsCodec = new TextColorArrayCodec();
  }

  /**
   * @param value {FillInTheBlankChild}
   * @return {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, (size, center) => {
      return {
        type: value.type,
        size,
        center,
        answers: angular.toJson(
          value.answers.map((answer) =>
            this._fillInTheBlankAnswerCodec.encode(answer),
          ),
        ),
        font_size: value.fontSize,
        colors: this._colorsCodec.encode(value.colorSpans),
        answer: value.answer,
        format: value.format,
      };
    });
  }

  /**
   * @param value {object}
   * @param id {string}
   * @return {Element}
   */
  decode(value, id) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      return new FillInTheBlankChild(
        id,
        metadata,
        location,
        size,
        angular
          .fromJson(value.data.answers)
          .map((answer) => this._fillInTheBlankAnswerCodec.decode(answer)),
        value.data.font_size,
        value.data.colors && this._colorsCodec.decode(value.data.colors),
        value.data.answer,
        value.data.format,
      );
    });
  }
}
