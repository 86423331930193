'use strict';

import Subscription from '../domain/subscription';
import Codec from './codec';

/**
 * Encodes/Decodes a subscription
 */
export default class SubscriptionCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {Subscription}
   * @return {object|null}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        owner_id: value.ownerId,
        contract_id: value.contractId,
        status: value.status,
        provider: value.provider,
        active: value.active,
        created: value.created && value.created.toISOString(),
        updated: value.updated && value.updated.toISOString(),
        subscription_plan: value.subscriptionPlan,
        coupon: value.coupon,
      };
    }
    return null;
  }

  /**
   * @param value {object}
   * @return {Subscription}
   */
  decode(value) {
    if (value) {
      return new Subscription(
        value.id,
        value.owner_id,
        value.contract_id,
        value.status,
        value.provider,
        value.active,
        value.created,
        value.updated,
        value.subscription_plan,
        value.coupon,
      );
    }
    return null;
  }
}
