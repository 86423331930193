import moment from 'moment';
import Order from './order';
import { OrganizationTypes } from './organization';
import { OrderPlans } from './order-plan';
import CreateOrderDialogController from '../../components/create-order-dialog/create-order-dialog.controller';
import ShareOrderDialogController from '../../components/share-order-dialog/share-order-dialog.controller';
import ReserveLockOrderDialogController from '../../components/reserve-lock-order-dialog/reserve-lock-order-dialog.controller';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';
import Contract from './contract';

export class OrderManager {
  constructor(
    $q,
    $log,
    $filter,
    $mdDialog,
    $location,
    AuthService,
    OrderService,
    OrganizationService,
    BreadcrumbService,
    CacheService,
    ContractService,
  ) {
    this.$q = $q;
    this.$log = $log;
    this.$filter = $filter;
    this.$mdDialog = $mdDialog;
    this.$location = $location;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ContractService} */
    this._contractService = ContractService;

    this._createOrderDialog = CreateOrderDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._shareOrderDialog = ShareOrderDialogController.show;
    this._reserveLockOrderDialog = ReserveLockOrderDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this.organizationContractId = null;
  }

  shareSchoolOrder(
    user,
    school,
    orders = [],
    activeContract = {},
    decemberDeal = false,
    clickedFrom,
    isActiveFreeTrial = false,
  ) {
    const promise = this.getOrCreateSchoolOrder(user, school, orders);
    promise
      .then((order) => {
        this.order = order;
        if (this.organizationContractId) {
          this._contractService
            .get(this.organizationContractId)
            .then((contract) => {
              this.contract = contract;
              const freeTrialStatus = !!(
                this.contract &&
                this.contract.isTrial &&
                !this.contract.isExpired
              );
              this.shareOrder(
                order,
                user,
                this.contract,
                clickedFrom,
                freeTrialStatus,
              );
            })
            .catch(() => {
              this.shareOrder(
                this.order,
                user,
                activeContract,
                clickedFrom,
                isActiveFreeTrial,
              );
            });
        } else {
          this.shareOrder(
            order,
            user,
            activeContract,
            clickedFrom,
            isActiveFreeTrial,
          );
        }
      })
      .catch((error) => {
        this.$log.error(error);
      });
  }

  /**
   * Called when user clicks "buy now" or "get instant quote" for school
   * @param [user]
   * @param [school]
   * @param [orders]
   */
  startSchoolOrder(user, school, orders = [], contract, isRenewal = false) {
    this.getOrCreateSchoolOrder(user, school, orders, isRenewal).then(
      (order) => {
        if (order) {
          this._goToOrder(order.id);
        }
      },
    );
  }

  /**
   * Called when generates a quote in nav bar for school
   * @param [user]
   * @param [school]
   * @param [orders]
   */
  generateSchoolOrder(user, school, orders = [], contract, isRenewal = false) {
    return this.getOrCreateSchoolOrder(user, school, orders, isRenewal).then(
      (order) => {
        if (order) {
          return order;
        }
      },
    );
  }

  /**
   * @param [user] {User|undefined}
   * @param [school] {Organization|undefined}
   * @param [orders] {Order[]|undefined}
   * @return {Promise<Order>}
   */
  getOrCreateSchoolOrder(user, school, orders = [], isRenewal) {
    let userId = user ? user.id : '';
    let name = user ? user.name : '';
    let email = user ? user.email : '';
    let isTeacher = user && user.isTeacher;

    // if the user is logged in and they have all the required info (e.g valid school, email, name)
    if (isTeacher && school) {
      const contractStartDate = Order.getDefaultStart(orders);
      const contractEndDate = Order.calculateContractEndDate(contractStartDate);
      return this._getOrCreateOrder(
        school,
        this._getPlanId(school.type, school.virtual),
        contractStartDate,
        contractEndDate,
        isRenewal ? isRenewal : Order.hasAlreadyPurchasedThisYear(orders),
        school.virtual,
        1,
        userId,
        name,
        email,
      );
    }
    // if the user is logged in and they are not member of school OR if the user is logged out or doesn't have an account
    else {
      return this._createOrderDialog(
        this.$mdDialog,
        OrganizationTypes.School,
        1,
        name,
        email,
      ).then(
        ({
          organization,
          quantity,
          name,
          email,
          start,
          end,
          renewal,
          virtual,
        }) => {
          this.organizationContractId = organization.contractId;
          return this._getOrCreateOrder(
            organization,
            this._getPlanId(organization.type, virtual),
            start,
            end,
            renewal,
            virtual,
            quantity,
            userId,
            name,
            email,
          );
        },
      );
    }
  }

  // ----------- District Logic ------------

  /**
   * Called when user clicks "buy now" or "get instant quote" for district
   * @param user
   * @param quantity
   */
  startDistrictOrder(user, quantity) {
    this.getOrCreateDistrictOrder(user, quantity).then((order) => {
      if (order) {
        this._goToOrder(order.id);
      }
    });
  }

  getOrCreateDistrictOrder(user, quantity, district, orders) {
    const userId = user ? user.id : '';
    const name = user ? user.name : '';
    const email = user ? user.email : '';
    const isTeacher = user ? user.isTeacher : false;

    // if the user is logged in and they have all the required info (e.g valid school, email, name)
    if (isTeacher && district) {
      const contractStartDate = Order.getDefaultStart(orders);
      const contractEndDate =
        Order.calculateContractEndDate2Years(contractStartDate);
      return this._getOrCreateOrder(
        district,
        this._getPlanId(district.type, district.virtual),
        contractStartDate,
        contractEndDate,
        Order.hasAlreadyPurchasedThisYear(orders),
        district.virtual,
        quantity,
        userId,
        name,
        email,
      );
    } else {
      return this._createOrderDialog(
        this.$mdDialog,
        OrganizationTypes.District,
        quantity,
        name,
        email,
      ).then(
        ({
          organization,
          quantity,
          name,
          email,
          start,
          end,
          renewal,
          virtual,
        }) => {
          return this._getOrCreateOrder(
            organization,
            this._getPlanId(organization.type, virtual),
            start,
            end,
            renewal,
            virtual,
            quantity,
            userId,
            name,
            email,
          );
        },
      );
    }
  }
  // -------------- Common Methods -----------

  /**
   * @param order {Order}
   * @param [from] {User}
   * @param [decemberDeal] {Boolean}
   * @return {*}
   */
  shareOrder(
    order,
    from,
    activeContract,
    clickedFrom,
    isActiveFreeTrial = false,
  ) {
    return this.$q
      .all({
        order,
        organization: this._organizationService.get(order.organizationId),
        user: this._authService.isLoggedIn && this._cacheService.getUser(),
      })
      .then(({ order, organization, user }) => {
        return this.$q.all({
          order,
          organization: organization,
          user: user,
          contracts: this._authService.isLoggedIn
            ? this._cacheService.getContracts(false, user.isTeacher)
            : undefined,
        });
      })
      .then(({ order, organization, user, contracts }) => {
        let schoolContract = activeContract;
        if (contracts) {
          schoolContract = Contract.LastActiveContract(contracts);
        } else if (organization.contractId) {
          schoolContract = this._contractService
            .get(organization.contractId)
            .catch(() => {
              return undefined;
            });
        }
        return this.$q.all({
          order,
          organization: organization,
          user: user,
          schoolContract: schoolContract,
          mdrAdmins: this._organizationService.getMdrAdmins(
            order.organizationId,
          ),
        });
      })
      .then(({ order, organization, user, schoolContract, mdrAdmins }) => {
        if (user) {
          from = user;
        }

        let body = ShareOrderDialogController.formatDefaultMessage(
          order.id,
          organization.type,
          this.$location,
        );

        if (isActiveFreeTrial) {
          let currentOrderPrice = Order.getCurrentOrderPricePerUnit(order);

          if (currentOrderPrice < 1999) {
            body =
              ShareOrderDialogController.formatOrderEmailForFreeTrialSchools(
                order.id,
                user && user.name,
                this.$location,
                this.$filter('currency')(currentOrderPrice, '$', 0),
              );
          } else {
            body =
              ShareOrderDialogController.formatOrderEmailForFreeTrialSchoolsOver1999(
                order.id,
                user && user.name,
                this.$location,
                this.$filter('currency')(currentOrderPrice, '$', 0),
              );
          }
        }

        this._shareOrderDialog(
          this.$mdDialog,
          from,
          order,
          from && from.email ? from.email : '',
          undefined,
          ShareOrderDialogController.DefaultTitle,
          body,
          schoolContract,
          false,
          clickedFrom,
          mdrAdmins,
        );
      })
      .catch((error) => {
        this.$log.error(error);
        this._errorDialog(
          this.$mdDialog,
          'Whoops, it looks like there was an issue forwarding this info',
          'Please try again and if you continue to have issues send us an email at support@classkick.com',
        );
      });
  }

  /**
   * @param order {Order}
   * @param [from] {User}
   * @param activeContract
   * @param clickedFrom
   * @param isActiveFreeTrial
   * @param organizationUsageStats {OrganizationUsageStats}
   * @return {*}
   */
  shareMetrics(
    order,
    from,
    activeContract,
    clickedFrom,
    isActiveFreeTrial = false,
    organizationUsageStats = null,
  ) {
    return this.$q
      .all({
        order,
        user: this._authService.isLoggedIn && this._cacheService.getUser(),
      })
      .then(({ order, user }) => {
        if (user) {
          from = user;
        }

        let body;

        if (order) {
          body = ShareOrderDialogController.formatEmailForMetricsWithQuote(
            user && user.name,
            order.id,
            this.$location,
            organizationUsageStats,
          );
        } else {
          body = ShareOrderDialogController.formatEmailForMetricsOnly(
            user && user.name,
            this.$location,
            organizationUsageStats,
          );
        }

        this._shareOrderDialog(
          this.$mdDialog,
          from,
          order,
          from && from.email ? from.email : '',
          undefined,
          ShareOrderDialogController.MetricsTitle,
          body,
          activeContract,
          false,
          clickedFrom,
        );
      })
      .catch((error) => {
        this.$log.error(error);
        this._errorDialog(
          this.$mdDialog,
          'Whoops, it looks like there was an issue forwarding this info',
          'Please try again and if you continue to have issues send us an email at support@classkick.com',
        );
      });
  }

  /**
   * @param orderId {String}
   * @param [from] {User}
   * @param clickedFrom
   * @return {*}
   */
  shareFeatures(orderId, from, clickedFrom) {
    return this.$q
      .all({
        user: this._authService.isLoggedIn && this._cacheService.getUser(),
      })
      .then(({ user }) => {
        if (user) {
          from = user;
        }

        let body = ShareOrderDialogController.formatShareFeatureChart(
          orderId,
          user && user.name,
          this.$location,
        );

        this._shareOrderDialog(
          this.$mdDialog,
          from,
          undefined,
          from && from.email ? from.email : '',
          undefined,
          ShareOrderDialogController.FeatureChartTitle,
          body,
          undefined,
          false,
          clickedFrom,
        );
      })
      .catch((error) => {
        this.$log.error(error);
        this._errorDialog(
          this.$mdDialog,
          'Whoops, it looks like there was an issue forwarding this info',
          'Please try again and if you continue to have issues send us an email at support@classkick.com',
        );
      });
  }

  reservePrice(order, clickedFrom, from = undefined) {
    return this.$q
      .all({
        order,
        organization: this._organizationService.get(order.organizationId),
        user: this._authService.isLoggedIn && this._cacheService.getUser(),
      })
      .then(({ order, organization, user }) => {
        this._reserveLockOrderDialog(
          this.$mdDialog,
          user,
          order,
          user && user.email ? user.email : !from ? '' : from,
          clickedFrom,
          organization,
        );
      })
      .catch((error) => {
        this.$log.error(error);
        this._errorDialog(
          this.$mdDialog,
          'Whoops, it looks like there was an issue reserving your price',
          'Please try again and if you continue to have issues send us an email at support@classkick.com',
        );
      });
  }

  /**
   * Gets or creates an order for an organization
   *
   * @param organization {Organization}
   * @param plan {string}
   * @param start {moment}
   * @param end {moment}
   * @param renewal {boolean}
   * @param virtual {boolean}
   * @param quantity {number}
   * @param userId {string}
   * @param username {string}
   * @param email {string}
   * @return {void | Promise<Order>}
   */
  _getOrCreateOrder(
    organization,
    plan,
    start,
    end,
    renewal,
    virtual,
    quantity,
    userId,
    username,
    email,
  ) {
    let promise = this._orderService
      .getCouponIds(
        organization.type,
        renewal,
        false,
        virtual,
        start,
        end,
        moment(),
      )
      .then((couponIds) => {
        return this._orderService.getOrCreateOrder(
          organization.id,
          plan,
          start,
          end,
          renewal,
          quantity || 1,
          organization.isDistrict || !renewal ? couponIds : [],
          userId,
          username,
          email,
        );
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          `Whoops, it looks like there was an issue creating your ${organization.type}'s order`,
          'Please try again and if you continue to have issues send us an email at support@classkick.com',
        );
      });

    this._loadingDialog(this.$mdDialog, promise);

    return promise;
  }

  _goToOrder(orderId) {
    this._breadcrumbService.go('root.order-detail', {
      orderId,
    });
  }

  _getPlanId(orgType, virtual) {
    if (orgType === OrganizationTypes.School && virtual) {
      return OrderPlans.PrometheusVirtualV7;
    } else if (orgType === OrganizationTypes.School && !virtual) {
      return OrderPlans.PrometheusV7;
    } else if (orgType === OrganizationTypes.District && virtual) {
      return OrderPlans.InvincibleVirtualV7;
    } else if (orgType === OrganizationTypes.District && !virtual) {
      return OrderPlans.InvincibleV7;
    }
  }
}
