'use strict';

import { SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';
import CancelSubscriptionDialogTemplate from './cancel-subscription-dialog.html';
/**
 * Dialog to confirm canceling a classkick pro subscription
 */
export default class CancelSubscriptionDialogController {
  constructor(
    $mdDialog,
    GoogleFormService,
    AnalyticsService,
    user,
    subscription,
    contract,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;

    /** @type {GoogleFormService} */
    this._googleFormService = GoogleFormService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    /** @type {User} */
    this._user = user;
    /** @type {Subscription} */
    this._subscription = subscription;
    /** @type {Contract} */
    this._contract = contract;

    /** @type {string[]} */
    this._cancelOptions = [
      "I don't like Classkick",
      "I can't afford it",
      'My school upgraded to Pro',
      'Other',
    ];
    /** @type {string} */
    this._cancelReason = '';
  }

  /**
   * @return {string[]}
   */
  get cancelOptions() {
    return this._cancelOptions;
  }

  /**
   * @return {string}
   */
  get cancelReason() {
    return this._cancelReason;
  }

  /**
   * @param value {string}
   */
  set cancelReason(value) {
    this._cancelReason = value;
  }

  /**
   * @param $mdDialog
   * @param user {User}
   * @param subscription {Subscription}
   * @param contract {Contract}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, user, subscription, contract) {
    return $mdDialog.show({
      controller: CancelSubscriptionDialogController,
      template: CancelSubscriptionDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        user,
        subscription,
        contract,
      },
      focusOnOpen: false,
    });
  }

  /**
   *
   * @param search {string} the search term
   * @param array {string[]} the original array of options
   * @returns {string[]}
   */
  autocompleteFilter(search, array) {
    if (!search) {
      return array;
    }
    return array.filter((value) =>
      value.toLowerCase().includes(search.toLowerCase()),
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  cancelSubscription() {
    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.CANCEL,
      this._subscription.subscriptionPlan,
      this._contract.planType,
    );
    this._googleFormService.sendCancelSubscriptionReason(
      this._user,
      this._subscription,
      this._cancelReason,
    );
    this.$mdDialog.hide();
  }
}
