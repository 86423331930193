'use strict';

import LoadingDialogTemplate from './loading-dialog.html';

/**
 * Shows a loading dialog, optionally over a specific section of the page
 */
export default class LoadingDialogController {
  /**
   * @param $mdDialog
   * @param closeOnComplete {promise} A promise, when when completed will close the dialog
   * @param [$document] {$document} Required if specifiying a selector
   * @param [selector] {string} A selector specifying an <md-content> element
   * @param [event] {DOM mouse event}
   */
  static show($mdDialog, closeOnComplete, $document, selector, event) {
    // If the 'closeOnComplete' promise resolves before the dialog has completed its setup, the dialog will sometimes
    // hang. To fix, we 'hide' the dialog when on the second of the following two events: 'onShowing' is called or
    // 'closeOnComplete' resolves.
    let showing = false,
      resolved = false;

    let promise = $mdDialog.show({
      parent: selector
        ? angular.element($document[0].querySelector(selector))
        : undefined,
      autoWrap: false,
      targetEvent: event,
      controller: LoadingDialogController,
      template: LoadingDialogTemplate,
      escapeToClose: false,
      onShowing: () => {
        // If 'closeOnComplete' has already resolved, hide the dialog
        if (resolved) {
          $mdDialog.hide();
        } else {
          showing = true;
        }
      },
    });

    if (closeOnComplete) {
      return closeOnComplete.finally(() => {
        // If 'onShowing' has already been called, hide the dialog
        if (showing) {
          return $mdDialog.cancel();
        } else {
          resolved = true;
        }
      });
    }
  }
}
