import ProTrialExtensionOverlayTemplate from './pro-trial-extension-overlay.html';
export default class ProTrialExtensionOverlayController {
  /**
   * @ngInject
   */
  constructor($mdDialog, $scope, organizationName) {
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;

    this._organizationName = organizationName;
  }

  static show($mdDialog, organizationName) {
    return $mdDialog.show({
      controller: ProTrialExtensionOverlayController,
      template: ProTrialExtensionOverlayTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        organizationName,
      },
    });
  }

  get organizationName() {
    return this._organizationName;
  }

  close() {
    this.$mdDialog.hide();
  }
}
