'use strict';

import ClassCodeCodec from '../../model/codec/class-code-codec';

/**
 * Service for retrieving class codes
 */
export default class ClassCodeService {
  constructor(HttpService, AuthService, environment) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {AuthService} */
    this._authService = AuthService;

    this._classCodeCodec = new ClassCodeCodec();
  }

  /**
   * @returns {Promise.<Array.<ClassCode>>}
   */
  getForCurrentUserAssignment(assignmentId) {
    return this.getForUserAssignment(
      this._authService.authData.id,
      assignmentId,
    );
  }

  /**
   * @param userId {string}
   * @returns {Promise.<Array.<ClassCode>>}
   */
  getForUserAssignment(userId, assignmentId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/users/${userId}/class-codes/${assignmentId}`,
      )
      .then((data) => {
        return data.class_codes.map((code) => {
          return this._classCodeCodec.decode(code);
        });
      });
  }

  /**
   * @param classCode {string}
   * @returns {Promise.<ClassCode>}
   */
  get(classCode) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/class-codes/${classCode}`)
      .then((data) => {
        return this._classCodeCodec.decode(data);
      });
  }

  getForAssignmentAndRoster(assignmentId, rosterId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/assignments/${assignmentId}/rosters/${rosterId}/class-code`,
      )
      .then((data) => {
        return this._classCodeCodec.decode(data['class_code']);
      });
  }

  /**
   * Gets the usernames associated with a classcode
   *
   * @param classCode {string}
   * @returns {Promise.<{names: Array.<string>, allowNewMembers: boolean}>}
   */
  getUsernames(classCode) {
    return this._httpService
      .get(
        `${this._environment.serverUrlBase}/v1/class-codes/${classCode}/usernames`,
      )
      .then((data) => {
        return {
          names: data.names,
          allowNewMembers: data.allow_new_members,
          pro: data.pro,
          hideStudentWork: data.hide_student_work,
        };
      });
  }
}
