'use strict';

import Base64 from 'base-64';

/**
 * Controller for the password reset page
 */
export default class PasswordResetController {
  constructor(
    $log,
    $scope,
    $stateParams,
    moment,
    AuthService,
    BreadcrumbService,
  ) {
    'ngInject';

    this.$log = $log;
    this.$scope = $scope;
    this._breadcrumbService = BreadcrumbService;
    this._token = $stateParams.token;
    this._moment = moment;
    /** @type {AuthService} */
    this._authService = AuthService;

    var result = this.validateToken(this._token);

    this.state = result.valid ? 'form' : 'error';
    this._info = result.info;
    this.errorMessage = result.message;
  }

  goToLogin() {
    this._breadcrumbService.go('root.account-login', {}, true);
  }

  /**
   * Parses and validates the provided token. Returns invalid in the case that:
   * - The token has an invalid format
   * - The token has expired
   *
   * Returned object:
   *   valid: indicates whether token is valid
   *   message: if invalid, contains error message
   *   info: contains token body as object if it could be parsed
   *
   * @param token {string} A JWT
   * @returns {{valid: boolean, message: string, info: object}}
   */
  validateToken(token) {
    var splits = token.split('.');
    if (splits.length !== 3) {
      return {
        valid: false,
        message:
          'Sorry, but it looks like your reset link got messed up somehow (invalid format)',
      };
    }

    var info = null;
    try {
      info = angular.fromJson(Base64.decode(splits[1]));
    } catch (err) {
      /* nothing */
    }

    if (!info) {
      return {
        valid: false,
        message:
          'Sorry, but it looks like your reset link got messed up somehow',
      };
    }

    var expires = this._moment(info.exp * 1000);

    if (this._moment().isAfter(expires)) {
      return {
        valid: false,
        message:
          'Sorry, but it looks like your reset link expired on ' +
          expires.format('MMMM Do YYYY, h:mm a'),
        info: info,
      };
    }

    return {
      valid: true,
      info: info,
    };
  }

  get hasInfo() {
    return !!this._info;
  }

  /**
   * @returns {string} containing a regex mathing the value in this.password
   */
  get passwordRegex() {
    if (this.password) {
      return '^' + this.password + '$';
    }
    return '^.*$';
  }

  /**
   * @returns {string} the user's name
   */
  get username() {
    return this._info.name;
  }

  /**
   * Sends a password reset email to the user described by the provided token
   */
  sendEmail() {
    this.loadingMessage = 'your email is being sent';
    this.state = 'loading';

    this._authService
      .requestPasswordReset(this._info.email)
      .then(() => {
        this.finishedMessage =
          'Your email is sent, it should be arriving any moment';
        this.state = 'finished';
      })
      .catch(() => {
        this.state = 'error';
        this.errorMessage =
          'Sorry, we had some trouble sending your email. Please try again in a few minutes.';
      });
  }

  /**
   * Submits the request to reset the password
   */
  submit() {
    this.loadingMessage = 'Your new password is being set';
    this.state = 'loading';

    this._authService
      .resetPassword(this._token, this.password)
      .then(() => {
        this.finishedMessage = 'Your new password is ready to go';
        this.state = 'finished';
      })
      .catch(() => {
        this.state = 'error';
        this.errorMessage =
          'Sorry, we had some trouble setting your password. Please try again in a few minutes.';
      });
  }

  get isForm() {
    return this.state === 'form';
  }

  get isLoading() {
    return this.state === 'loading';
  }

  get isError() {
    return this.state === 'error';
  }

  get isFinished() {
    return this.state === 'finished';
  }
}
