'use strict';

import Control from './control';
import GestureManager from './gesture-manager';
import HexColors from '../../css-constants';
import Size from './size';
import { ElementIntents } from '../domain/element-metadata';
import { UserRoles } from '../domain/user';

export default class MultipleChoiceOptionControl extends Control {
  constructor(id, target, option) {
    super(id, 'answer-handle');

    this._target = target;
    this._correct = option.correct;
    this._points = option.points;
    this._answer = option.answer;
    this._size = new Size(44, 44);
  }

  createElement(root, editable) {
    this._base = root.group();
    this._background = this._base.circle(0, 0, 0);
    this._textbox = this._base.text(0, 0, '');
    this._interactive = this._base.circle(0, 0, 0);

    if (editable) {
      this._gestureManager = new GestureManager(
        this._target,
        this._target.canvas,
      );
      this._gestureManager.start(this._interactive.node);
      this._gestureManager.click.subscribe(this._clickHandler, this);
    }
  }

  update(root, editable) {
    let attr = {
      cx: this.location.x + this.width / 2,
      cy: this.location.y + this.height / 2,
      r: this.width * 0.5,
      'pointer-events': editable ? 'auto' : 'none',
    };

    this._textbox.attr({
      x: attr.cx,
      y: attr.cy,
      text: this._answer,
      style: `font-family:GothamRoundedMedium;dominant-baseline:middle;fill:${this.textColor};font-size:16px;text-anchor:middle;pointer-events:none;`,
    });

    this._background.attr({
      ...attr,
      fill: this.backgroundColor,
      stroke: this.strokeColor,
    });

    this._interactive.attr({
      ...attr,
      fill: 'transparent',
      stroke: 'transparent',
      cursor: editable ? 'pointer' : 'default',
    });
  }

  get textColor() {
    return this.showCorrect || this.showSelected
      ? 'white'
      : HexColors.CK_HEADER_GREY;
  }

  get strokeColor() {
    if (this.showCorrect) {
      return HexColors.CK_GRADING_MEETS;
    } else if (this.showIncorrect) {
      return HexColors.CK_GRADING_BELOW;
    }
    return HexColors.CK_HEADER_GREY;
  }

  get backgroundColor() {
    if (this.showCorrect) {
      return HexColors.CK_GRADING_MEETS;
    } else if (this.showIncorrect) {
      return HexColors.CK_GRADING_BELOW;
    } else if (this.showSelected) {
      return HexColors.CK_HEADER_GREY;
    }
    return HexColors.BACKGROUND_GREY;
  }

  get showCorrect() {
    return this._canShowCorrectness && this._isSelected && this._correct;
  }

  get showIncorrect() {
    return (
      this._canShowCorrectness && this._isSelected && this._correct === false
    );
  }

  get _canShowCorrectness() {
    return (
      (this.viewerIntent === ElementIntents.WORK && this.showStudentScores) ||
      (this.viewerIntent === ElementIntents.FEEDBACK &&
        this.viewerRole === UserRoles.TEACHER) ||
      (this.viewerIntent === ElementIntents.VIEW &&
        this.viewerRole === UserRoles.TEACHER) ||
      (this.thumbnail &&
        this.isWork &&
        this.viewerIntent &&
        this.viewerIntent !== ElementIntents.WORK)
    );
  }

  get showSelected() {
    return (
      this._isSelected ||
      (this.thumbnail && !this.isWork && this._correct) ||
      (this.viewerIntent === ElementIntents.CONTENT && this._correct)
    );
  }

  get _isSelected() {
    return (
      !!this._answer &&
      !!this.selectedAnswer &&
      this._answer === this.selectedAnswer
    );
  }

  get selectedAnswer() {
    return this._target && this._target.answer;
  }

  get showStudentScores() {
    return this._target && this._target.showStudentScores;
  }

  get isWork() {
    return this._target && this._target.isWork;
  }

  _clickHandler() {
    this._target.answer = this._answer;
  }
}
