'use strict';

import AddProStudentsDialogController from '../add-pro-students-dialog/add-pro-students-dialog.controller';
import AddAnonStudentDialog from '../add-anon-student-dialog/add-anon-student-dialog.controller';
import { PaywallSources } from '../../services/mixpanel/mixpanel.service';
import { RosterStudentAccountPreferenceOptions } from '../../model/domain/roster';
import ProInfoDialogController from '../pro-info-dialog/pro-info-dialog.controller';
import StudentAccountChoiceDialogTemplate from './student-account-choice-dialog.html';

export default class StudentAccountChoiceDialogController {
  /**
   * @ngInject
   */
  constructor($q, $mdDialog, RosterService, pro, roster, rosterMembers) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;

    /** @type {RosterService} */
    this._rosterService = RosterService;

    this._pro = pro;
    this._roster = roster;
    this._rosterMembers = rosterMembers;
    this._rememberSelection = false;

    this._showAddProStudentsDialog = AddProStudentsDialogController.show;
    this._showAddStudentDialog = AddAnonStudentDialog.show;
    this._proInfoDialog = ProInfoDialogController.show;
  }

  get pro() {
    return this._pro;
  }

  set pro(value) {
    this._pro = value;
  }

  get roster() {
    return this._roster;
  }

  set roster(value) {
    this._roster = value;
  }

  get rememberSelection() {
    return this._rememberSelection;
  }

  set rememberSelection(value) {
    this._rememberSelection = value;
  }

  selectBasic() {
    if (this.rememberSelection) {
      this._roster.studentAccountPreference =
        RosterStudentAccountPreferenceOptions.BASIC;
      this._rosterService.update(this._roster);
    }

    this._showAddStudentDialog(
      this.$mdDialog,
      this.roster,
      this._rosterMembers,
      this.pro,
    );
  }

  selectPortfolio() {
    if (this.pro.isPro) {
      if (this.rememberSelection) {
        this._roster.studentAccountPreference =
          RosterStudentAccountPreferenceOptions.PORTFOLIO;
        this._rosterService.update(this._roster);
      }

      this._showAddProStudentsDialog(
        this.$mdDialog,
        this.$q,
        this.pro,
        this.roster,
        this._rosterMembers,
      );
    } else {
      this._proInfoDialog(
        this.$mdDialog,
        PaywallSources.STUDENT_ACCOUNTS_ADD_STUDENTS_DIALOG,
      );
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * @param $mdDialog
   * @param pro {ProInfo}
   * @param roster {Roster}
   * @param rosterMembers {Set.<User>}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, pro, roster, rosterMembers) {
    return $mdDialog.show({
      controller: StudentAccountChoiceDialogController,
      template: StudentAccountChoiceDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false,
      locals: {
        pro,
        roster,
        rosterMembers,
      },
    });
  }
}
