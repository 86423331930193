'use strict';

import CkRedirect from '../../model/domain/ck-redirect';
import Backoff from 'backoff-promise';

export class HttpRetry {
  constructor(delay = 1000, max = 4, factor = 2, shouldRetry = () => {}) {
    this._delay = delay;
    this._max = max;
    this._factor = factor;
    this._shouldRetry = shouldRetry;
  }

  /**
   * @return {Backoff}
   */
  get backoff() {
    if (!this._backoff) {
      this._backoff = new Backoff(this._delay, 10000, this._factor, this._max);
    }
    return this._backoff;
  }

  /**
   * @param func {Function}
   * @return {Promise}
   */
  run(func) {
    return this.backoff.run(() => func(), this._shouldRetry);
  }
}

/**
 * Wraps $http service with error handling and optional authentication
 */
export default class HttpService {
  constructor(
    $http,
    $q,
    $state,
    $stateParams,
    $log,
    AuthService,
    BootstrapService,
    StorageService,
    PlatformHeaderService,
  ) {
    'ngInject';

    /** @type {$http} */
    this.$http = $http;
    /** @type {$q} */
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$log = $log;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BootstrapService} */
    this._bootstrapService = BootstrapService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
  }

  /**
   * @returns {boolean}
   * @private
   */
  get _ready() {
    return this._bootstrapService.ready;
  }

  /**
   * If not ready, returns a failed promise. If ready, executes the contained function
   * @param f {function}
   * @returns {Promise.<T>}
   * @private
   */
  _assertReady(f) {
    if (!this._ready) {
      return this.$q.reject(new Error('Waiting for bootstrap to complete'));
    }

    return f();
  }

  /**
   * @param url {string}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  authGet(url, config, retry) {
    return this.get(url, this._addAuthHeader(config), retry);
  }

  /**
   * @param url {string}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  optionalAuthGet(url, config, retry) {
    if (this._authService.isLoggedIn) {
      return this.get(url, this._addAuthHeader(config), retry);
    } else {
      return this.get(url, config, retry);
    }
  }

  /**
   *
   * @param url {string}
   * @param data {*}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  authPost(url, data, config, retry) {
    return this.post(url, data, this._addAuthHeader(config), retry);
  }

  /**
   *
   * @param url {string}
   * @param data {*}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  authPut(url, data, config, retry) {
    return this.put(url, data, this._addAuthHeader(config), retry);
  }

  /**
   *
   * @param url {string}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  authDelete(url, config, retry) {
    return this.delete(url, this._addAuthHeader(config), retry);
  }

  /**
   *
   * @param url {string}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise}
   */
  get(url, config, retry) {
    return this._assertReady(() => {
      config = this._platformHeaderService.addHeaders(url, config);
      return this._retryIfNeeded(this.$http.get.bind(this, url, config), retry)
        .then((response) => response.data)
        .catch((response) => this._handleErrorResponse(response));
    });
  }

  /**
   * @param url {string}
   * @param data {*}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  post(url, data, config, retry) {
    return this._assertReady(() => {
      config = this._platformHeaderService.addHeaders(url, config);
      return this._retryIfNeeded(
        this.$http.post.bind(this, url, data, config),
        retry,
      )
        .then((response) => response.data)
        .catch((response) => this._handleErrorResponse(response));
    });
  }

  /**
   *
   * @param url {string}
   * @param data {*}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  put(url, data, config, retry) {
    return this._assertReady(() => {
      config = this._platformHeaderService.addHeaders(url, config);
      return this._retryIfNeeded(
        this.$http.put.bind(this, url, data, config),
        retry,
      )
        .then((response) => response.data)
        .catch((response) => this._handleErrorResponse(response));
    });
  }

  /**
   *
   * @param url {string}
   * @param [config] {*}
   * @param [retry] {HttpRetry}
   * @returns {Promise.<T>}
   */
  delete(url, config, retry) {
    return this._assertReady(() => {
      config = this._platformHeaderService.addHeaders(url, config);
      return this._retryIfNeeded(
        this.$http.delete.bind(this, url, config),
        retry,
      )
        .then((response) => response.data)
        .catch((response) => this._handle404OrErrorResponse(response));
    });
  }

  /**
   * @param func {Function}
   * @param retry {HttpRetry|undefined}
   * @return {Promise}
   */
  _retryIfNeeded(func, retry) {
    if (retry) {
      return retry.run(func);
    } else {
      return func();
    }
  }

  /**
   * @param config {undefined|*}
   * @returns {*}
   * @private
   */
  _addAuthHeader(config) {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = this._authService.authHeader;
    return config;
  }

  /**
   * Massages a 404 into an undefined data response. Otherwise delegates to _handleErrorResponse
   * @param response {{data: String|Object, status: number, headers: function([headerName]), config: Object, statusText: string}}
   * @returns {*}
   * @private
   */
  _handle404OrErrorResponse(response) {
    if (response.status === 404) {
      return null;
    } else {
      return this._handleErrorResponse(response);
    }
  }

  /**
   * Handles an error response
   * @param response {{data: String|Object, status: number, headers: function([headerName]), config: Object, statusText: string}}
   * @returns {Promise} rejected with Error object
   * @private
   */
  _handleErrorResponse(response) {
    return HttpService.handleErrorResponse(
      response,
      this.$q,
      this.$log,
      this.$state,
      this.$stateParams,
      this._authService,
      this._storageService,
    );
  }

  /**
   * Handles an error response
   * @param response {{data: String|Object, status: number, headers: function([headerName]), config: Object, statusText: string}}
   * @param $q,
   * @param $log
   * @param $state
   * @param $stateParams
   * @param authService {AuthService}
   * @param storageService {StorageService}
   * @returns {Promise} rejected with Error object
   */
  static handleErrorResponse(
    response,
    $q,
    $log,
    $state,
    $stateParams,
    authService,
    storageService,
  ) {
    if (!response.data) {
      response.data = {};
    }

    let error = new Error(response.data.message || response.statusText);
    error.data = response.data;
    error.code = response.data.code;
    error.status = response.status;
    error.statusText = response.statusText;
    error.headers = (x) => response.headers(x);
    error.config = response.config;

    /* when user is on shared-work and receive an auth error,
    we need to display error message first instead of redirecting
    to main login page with no error message */
    if (response.status === 401 && $state.current.name !== 'root.shared-work') {
      $log.error('Unauthorized token detected, redirecting to login');
      storageService.ckRedirect = new CkRedirect(
        $state.current.name,
        $stateParams,
      );
      authService.signOut();
    }
    return $q.reject(error);
  }
}
