'use strict';

import Codec from './codec';
import Contract from '../domain/contract';

/**
 * Codec for contracts
 */
export default class ContractCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {object}
   * @returns {Contract}
   */
  decode(value) {
    return new Contract(
      value.id,
      value.name,
      value.plan,
      value.created,
      value.expires,
      value.is_trial,
      value.price_paid,
    );
  }

  /**
   * @param value {Contract}
   * @returns {{id: string, name: string, plan: string, created: string, expires: string, is_trial: boolean}}
   */
  encode(value) {
    return {
      id: value.id,
      name: value.name,
      plan: value.plan,
      created: value.created && value.created.toISOString(),
      expires: value.expires && value.expires.toISOString(),
      is_trial: value.isTrial,
      price_paid: value.pricePaid,
    };
  }
}
