export default function CkIndicatorCircleDirective() {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      ckIndicatorCircle: '=',
    },
    link: ($scope, $element) => {
      let indicatorCircle;

      $scope.$watch('ckIndicatorCircle', (value) => {
        if (value) {
          indicatorCircle =
            indicatorCircle ||
            angular.element('<span class="ck-indicator-circle"></i></span>');
          $element.append(indicatorCircle);
        }
      });
    },
  };
}
