'use strict';

import UserSticker from '../../model/domain/user-sticker';
import { StickerSources } from '../../services/mixpanel/mixpanel.service';
import ViewHelpDialogController, {
  ViewHelps,
} from '../view-help-dialog/view-help-dialog.controller';
import SelectStickerMenuTemplate from './select-sticker-menu.html';
import AddNewStickerDialogController from '../add-new-sticker-dialog/add-new-sticker-dialog.controller';
import ImagePicker from '../image-picker-dialog/image-picker-dialog.controller';

export class SelectStickerMenuController {
  /**
   * @ngInject
   */
  constructor(
    $mdDialog,
    AnalyticsService,
    mdPanelRef,
    deferred,
    stickers,
    source,
  ) {
    this.$mdDialog = $mdDialog;

    this._analyticsService = AnalyticsService;
    this._mdPanelRef = mdPanelRef;
    this._deferred = deferred;
    this._stickers = stickers;

    this._helpDialog = ViewHelpDialogController.show;
    this._addNewStickerDialog = AddNewStickerDialogController.show;
    this._showImagePicker = ImagePicker.show;

    this._analyticsService.stickerSidenavOpened(source);
  }

  get defaultStickerImageUrl() {
    return UserSticker.BLANK_STICKER_URL;
  }

  /**
   * @return {UserSticker[]}
   */
  get stickers() {
    return this._stickers;
  }

  /**
   * @param value {UserSticker[]}
   */
  set stickers(value) {
    this._stickers = value;
  }

  select(sticker, event) {
    this._deferred.resolve({ sticker });
    this._mdPanelRef.hide();
  }

  showStickerWandHelp() {
    this.cancel();
    this._helpDialog(this.$mdDialog, ViewHelps.PlaceStickerWand);
  }

  openAddNewStickerDialog() {
    this._addNewStickerDialog(this.$mdDialog);
  }

  openStickerImageSelector() {
    this._showImagePicker(
      this.$mdDialog,
      this._deferred,
      this.defaultStickerImageUrl,
      true,
    ).then((selectedStickerImageUrl) => {
      this.selectedStickerImageUrl = selectedStickerImageUrl || '';
    });
    this._mdPanelRef.hide();
  }

  cancel() {
    this._deferred.reject();
    this._mdPanelRef.hide();
  }

  /**
   * @param $mdPanel
   * @param $q
   * @param $event
   * @param stickers {UserSticker[]}
   * @param [source] {string} where the sidenav is being opened from - session_question
   * @return {Promise}
   */
  static show(
    $mdPanel,
    $q,
    $event,
    stickers,
    source = StickerSources.SESSION_QUESTION,
  ) {
    const position = $mdPanel
      .newPanelPosition()
      .relativeTo($event.currentTarget)
      .addPanelPosition(
        $mdPanel.xPosition.ALIGN_START,
        $mdPanel.yPosition.ALIGN_TOPS,
      );

    const deferred = $q.defer();

    $mdPanel.open({
      position,
      animation: $mdPanel
        .newPanelAnimation()
        .openFrom($event.currentTarget)
        .duration(100)
        .withAnimation($mdPanel.animation.SCALE),
      template: SelectStickerMenuTemplate,
      controller: SelectStickerMenuController,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      panelClass: 'select-sticker-menu',
      locals: {
        deferred,
        stickers,
        source,
      },
    });

    return deferred.promise;
  }
}
