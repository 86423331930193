'use strict';

import ChooseAssignmentDialogTemplate from './choose-assignment-dialog.html';

/**
 * Dialog to support adding an assignment to a roster
 */
export default class ChooseAssignmentDialogController {
  constructor(
    $mdDialog,
    $scope,
    $q,
    $log,
    CacheService,
    assignments,
    deferred,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.$q = $q;
    this.$log = $log;

    /** @type {Assignment[]} */
    this._assignments = assignments.filter(
      (assignment) => !assignment.isFolder,
    );
    /** @type {$q.defer} */
    this._deferred = deferred;
  }

  /**
   * @param $mdDialog
   * @param $q
   * @param assignments {Assignment[]}
   * @param [completion] {$q.defer}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, $q, assignments, completion) {
    // We're using a deferred promise because we know that this dialog can be closed without
    // the 'choose assignment' process actually being completed. By working with a promise other
    // than the $mdDialog.show one, we can have more control over things.
    if (!completion) {
      completion = $q.defer();
    }

    $mdDialog.show({
      controller: ChooseAssignmentDialogController,
      template: ChooseAssignmentDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        assignments,
        deferred: completion,
      },
    });

    return completion.promise;
  }

  /**
   * @return {Assignment[]}
   */
  get assignments() {
    return this._assignments;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * Resolves the promise with the assignment indicating the assignment to add
   *
   * @param assignment {Assignment}
   */
  confirm(assignment) {
    this.$mdDialog.hide();
    this._deferred.resolve(assignment);
  }
}
