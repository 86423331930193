'use strict';

import Element from './element';
import Size from '../size';
import Point from '../point';

export default class SlideForeground extends Element {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param hex {string}
   * @param url {string}
   * @param size {Size}
   * @param location {Point}
   */
  constructor(id, metadata, hex, url, size, location) {
    super(id, SlideForeground.type, metadata);
    this._hex = hex;
    this._url = url;
    this._size = size || new Size(1008, 2004);
    this._location = location || new Point(504, 1002);
  }

  static get type() {
    return 'slide_fg';
  }

  get hex() {
    return this._hex;
  }

  set hex(value) {
    this._trackChange(() => {
      this._hex = value;
      this._url = undefined;

      this._resetImage();
    });
  }

  get url() {
    return this._url;
  }

  set url(value) {
    this._trackChange(() => {
      this._hex = undefined;
      this._url = value;

      this._resetImage();
    });
  }

  /**
   * @return {string}
   */
  get optimizedUrl() {
    const newUrl = this.isStockUrl
      ? this.url.replace('.png', '.svg')
      : this.url;

    if (angular.isUndefined(newUrl)) {
      return newUrl;
    }
    if (newUrl.includes('https://www.googleapis.com/download/')) {
      return newUrl.replace(
        'https://www.googleapis.com/download/',
        'https://storage.googleapis.com/',
      );
    } else if (newUrl.includes('https://www.googleapis.com/storage')) {
      return newUrl.replace(
        'https://www.googleapis.com/',
        'https://storage.googleapis.com/',
      );
    } else {
      return newUrl;
    }
  }

  /**
   * @return {boolean}
   */
  get isStockUrl() {
    return (
      this.url &&
      (this.url.startsWith(
        'https://www.googleapis.com/download/storage/v1/b/slide-backgrounds',
      ) || // TODO re-visit these when we have defined foregrounds
        this.url.startsWith(
          'https://www.googleapis.com/storage/v1/b/slide-backgrounds',
        ))
    );
  }

  _resetImage() {
    if (this._image) {
      this._image.remove();
    }
    this._image = undefined;
  }

  createElement(root, editable) {
    this._root = root;
    this._background = this._root.rect(0, 0, 0, 0);
    this._image = undefined;
    this._editable = editable;
  }

  update(root, editable) {
    this._editable = editable;

    this._background.attr({
      width: 1004,
      height: 2008,
      fill: this._hex || 'transparent',
      pointerEvents: 'none',
    });

    let optimizedUrl = this.optimizedUrl;

    if (!this._image) {
      this._image = this._root.image(optimizedUrl, 0, 0, 0, 0);
    }

    this._image.attr({
      width: 1004,
      height: 2008,
      preserveAspectRatio: 'xMidYMin',
      url: optimizedUrl,
      pointerEvents: 'none',
    });
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {SlideForeground}
   * @param forceUpdate {boolean|undefined} true if used in undo/redo
   */
  merge(other, forceUpdate) {
    this._metadata = other._metadata || this._metadata;
    this._hex = forceUpdate ? other._hex : other._hex || this._hex;
    this._url = forceUpdate ? other._url : other._url || this._url;
    this._location = other._location || this._location;
    this._size = other._size || this._size;

    let hexAndUrlAreDefined =
      angular.isDefined(this._hex) && angular.isDefined(this._url);

    if (hexAndUrlAreDefined && angular.isUndefined(other.hex)) {
      this._hex = undefined;
      this._resetImage();
    } else if (hexAndUrlAreDefined && angular.isUndefined(other.url)) {
      this._url = undefined;
      this._resetImage();
    } else if (forceUpdate || !this._editable) {
      this._resetImage();
    }

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _hex: this._hex,
      _url: this._url,
      _location: this._location,
      _size: this._size,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {SlideForeground}
   */
  fromSnapshot(id, snapshot) {
    return new SlideForeground(
      id,
      snapshot._metadata,
      snapshot._hex,
      snapshot._url,
      snapshot._location,
      snapshot._size,
    );
  }
}
