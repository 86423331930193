'use strict';

/**
 * Redirects once bootstrap is ready
 */
export default class LoadingController {
  /**
   * @param $timeout
   * @param AuthService {AuthService}
   * @param BootstrapService {BootstrapService}
   * @param BreadcrumbService {BreadcrumbService}
   * @ngInject
   */
  constructor($timeout, AuthService, BootstrapService, BreadcrumbService) {
    if (BootstrapService.ready) {
      $timeout().then(() => {
        if (AuthService.isLoggedIn) {
          BreadcrumbService.go('root.account.home');
        } else {
          BreadcrumbService.go('root.login');
        }
      });
    }
  }
}
