import { cn } from "@/lib/classnames"

interface LinkProps {
  children: React.ReactNode
  className?: string
  href: string
}

export function Link({ children, className, ...props }: LinkProps) {
  return (
    <a
      className={cn("tw-text-sm tw-normal-case tw-text-primary", className)}
      {...props}
    >
      {children}
    </a>
  )
}
