export default class MdrOrganization {
  constructor(
    pid,
    institution_name,
    address,
    city,
    state,
    zipcode,
    zip4 = '',
    country,
    institution_type,
    enrollment,
    geo_latitude,
    geo_longitude,
  ) {
    this._pid = pid;
    this._institutionName = institution_name;
    this._address = address;
    this._city = city;
    this._state = state;
    this._zipcode = zipcode;
    this._zip4 = zip4;
    this._country = country;
    this._institutionType = institution_type;
    this._enrollment = enrollment;
    this._latitude = geo_latitude;
    this._longitude = geo_longitude;
  }

  /**
   * @return {number}
   */
  get pid() {
    return this._pid;
  }

  /**
   * @return {string}
   */
  get institutionName() {
    return this._institutionName;
  }

  /**
   * @return {string}
   */
  get address() {
    return this._address;
  }

  /**
   * @return {string}
   */
  get city() {
    return this._city;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @return {string}
   */
  get zipcode() {
    return this._zipcode;
  }

  /**
   * @return {string}
   */
  get zip4() {
    return this._zip4;
  }

  /**
   * @return {string}
   */
  get country() {
    return this._country;
  }

  /**
   * @return {string}
   */
  get institutionType() {
    return this._institutionType;
  }

  /**
   * @return {number}
   */
  get enrollment() {
    return this._enrollment;
  }

  /**
   * @return {number}
   */
  get latitude() {
    return this._latitude;
  }

  /**
   * @return {number}
   */
  get longitude() {
    return this._longitude;
  }
}
