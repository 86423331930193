'use strict';

import HelpRequestCodec from '../../model/codec/help-request-codec';
import HelpRequestSet from '../../model/domain/help-request-set';
import ChatMessage, { ChatMessageTypes } from '../../model/domain/chat-message';
import moment from 'moment';

/**
 * Manages help requests
 */
export default class HelpRequestService {
  constructor(
    $q,
    HttpService,
    environment,
    NotificationService,
    FirebaseService,
    AuthService,
  ) {
    'ngInject';

    /** @type {$q} */
    this.$q = $q;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {environment} */
    this._environment = environment;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {HelpRequestCodec} */
    this._helpRequestCodec = new HelpRequestCodec();
  }

  /**
   * Gets a help request by help request id
   *
   * @param helpRequestId {string}
   * @returns {Promise.<HelpRequest>}
   */
  get(helpRequestId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/help-requests/${helpRequestId}`,
      )
      .then((response) => this._helpRequestCodec.decode(response));
  }

  /**
   * Creates a help request
   *
   * @param rosterId {string}
   * @param assignmentId {string}
   * @param questionId {string}
   * @param requestType {string}
   * @returns {Promise.<HelpRequest>}
   */
  create(rosterId, assignmentId, questionId, requestType) {
    return this._httpService
      .authPost(`${this._environment.serverUrlBase}/v1/help-requests`, {
        roster_id: rosterId,
        assignment_id: assignmentId,
        question_id: questionId,
        request_type: requestType,
      })
      .then((response) => this._helpRequestCodec.decode(response));
  }

  /**
   * Cancels a help request
   *
   * @param helpRequestId {string}
   */
  cancel(helpRequestId) {
    return this._httpService
      .authPost(
        `${this._environment.serverUrlBase}/v1/help-requests/${helpRequestId}/cancel`,
        {},
      )
      .then((response) => this._helpRequestCodec.decode(response));
  }

  /**
   * Resolves a help request
   *
   * @param helpRequestId {string}
   */
  resolve(helpRequestId) {
    return this._httpService
      .authPost(
        `${this._environment.serverUrlBase}/v1/help-requests/${helpRequestId}/resolve`,
        {},
      )
      .then((response) => this._helpRequestCodec.decode(response));
  }

  /**
   * Gets all help requests for specified assignment
   *
   * @param assignmentId {string}
   * @returns {Promise.<Array<HelpRequest>>}
   */
  getHelpRequestsForAssignment(assignmentId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/help-requests/assignments/${assignmentId}`,
      )
      .then((response) => {
        return response.help_requests.map((helpRequest) => {
          return this._helpRequestCodec.decode(helpRequest);
        });
      });
  }

  /**
   * Gets all help requests for specified roster
   *
   * @param rosterId {string}
   * @returns {Promise.<Array<HelpRequest>>}
   */
  getHelpRequestsForRoster(rosterId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/help-requests/rosters/${rosterId}`,
      )
      .then((response) => {
        return response.help_requests.map((helpRequest) => {
          return this._helpRequestCodec.decode(helpRequest);
        });
      });
  }

  /**
   * Gets all help requests for related assignment and roster
   *
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param [includeResolved] {boolean}
   * @returns {Promise.<Array<HelpRequest>>}
   */
  getHelpRequestsForPeers(assignmentId, rosterId, includeResolved = false) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/help-requests/assignments/${assignmentId}/rosters/${rosterId}`,
        {
          params: {
            includeResolved: includeResolved,
          },
        },
      )
      .then((response) => {
        return response.help_requests.map((helpRequest) => {
          return this._helpRequestCodec.decode(helpRequest);
        });
      });
  }

  /**
   * @param assignmentOwnerId {string}
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param [includeResolved] {boolean}
   * @returns {Promise.<HelpRequestSet>}
   */
  getHelpRequestSetForPeers(
    assignmentOwnerId,
    assignmentId,
    rosterId,
    includeResolved = false,
  ) {
    return this.getHelpRequestsForPeers(
      assignmentId,
      rosterId,
      includeResolved,
    ).then((helpRequests) => {
      let notification =
        this._notificationService.getHelpRequestNotificationForPeers(
          assignmentOwnerId,
          assignmentId,
          rosterId,
          false,
        );
      notification.helpRequestService = this;

      const filterFunc = includeResolved
        ? HelpRequestSet.FILTER_FUNC_ALL
        : HelpRequestSet.FILTER_FUNC_UNRESOLVED;
      return new HelpRequestSet(helpRequests, notification, filterFunc);
    });
  }

  /**
   * @param assignmentOwnerId {string}
   * @param assignmentId {string}
   * @returns {Promise.<HelpRequestSet>}
   */
  getHelpRequestSetForAssignment(assignmentOwnerId, assignmentId) {
    return this.getHelpRequestsForAssignment(assignmentId).then(
      (helpRequests) => {
        let notification =
          this._notificationService.getHelpRequestNotificationForAssignment(
            assignmentOwnerId,
            assignmentId,
            false,
          );
        notification.helpRequestService = this;

        return new HelpRequestSet(
          helpRequests,
          notification,
          HelpRequestSet.FILTER_FUNC_UNRESOLVED,
        );
      },
    );
  }

  /**
   * @param rosterOwnerId
   * @param rosterId
   * @returns {Promise.<HelpRequestSet>}
   */
  getHelpRequestSetForRoster(rosterOwnerId, rosterId) {
    return this.getHelpRequestsForRoster(rosterId).then((helpRequests) => {
      let notification =
        this._notificationService.getHelpRequestNotificationForRoster(
          rosterOwnerId,
          rosterId,
          false,
        );
      notification.helpRequestService = this;

      return new HelpRequestSet(
        helpRequests,
        notification,
        HelpRequestSet.FILTER_FUNC_UNRESOLVED,
      );
    });
  }

  /**
   * @param rosterOwnerId
   * @param rosterId
   * @param studentId
   * @returns {Promise.<HelpRequestSet>}
   */
  getHelpRequestSetForRosterAndStudentId(rosterOwnerId, rosterId, studentId) {
    return this.getHelpRequestsForRoster(rosterId).then((helpRequests) => {
      let matchingRequest = helpRequests.filter(
        (helpRequest) =>
          helpRequest.helpeeId === studentId &&
          helpRequest.rosterId === rosterId,
      );

      let notification =
        this._notificationService.getHelpRequestNotificationForRoster(
          rosterOwnerId,
          rosterId,
          false,
        );
      notification.helpRequestService = this;

      return new HelpRequestSet(
        matchingRequest,
        notification,
        HelpRequestSet.FILTER_FUNC_UNRESOLVED,
      );
    });
  }

  /**
   * @param helpRequestSets {HelpRequestSet[]}
   */
  combineHelpRequestSets(helpRequestSets) {
    return HelpRequestSet.combine(helpRequestSets);
  }

  /**
   * @param request {HelpRequest}
   * @param question {AssignmentWorkQuestion}
   * @param user {User}
   */
  lowerHand(request, question, user) {
    this.resolve(request.id).then(() => {
      let message = new ChatMessage(
        this._firebaseService.newId(),
        ChatMessageTypes.HAND_DOWN,
        user.id,
        user.name,
        '',
        moment(),
      );
      return question.messages.save(message);
    });
  }
  generateHelpRequestSetByAssignmentIdAndRosterId(
    helpRequests,
    assignmentOwnerId,
    rosterId,
  ) {
    let notification =
      this._notificationService.getHelpRequestNotificationForPeers(
        assignmentOwnerId,
        helpRequests[0].assignmentId,
        rosterId,
        false,
      );
    notification.helpRequestService = this;
    return new HelpRequestSet(
      helpRequests,
      notification,
      HelpRequestSet.FILTER_FUNC_UNRESOLVED,
    );
  }
}
