'use strict';

export default class ErrorController {
  constructor($log, $timeout, $q, $state, $stateParams) {
    'ngInject';

    this.$log = $log;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
  }
}
