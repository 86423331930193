'use strict';

import CkSwitchTemplate from './ck-switch.component.html';

class CkSwitchController {
  constructor() {}

  get active() {
    return this._active;
  }

  set active(value) {
    this._active = value;
  }
}

export default {
  bindings: {
    active: '=',
  },
  transclude: true,
  template: CkSwitchTemplate,
  controller: CkSwitchController,
  controllerAs: 'ctrl',
};
