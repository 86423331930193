'use strict';

import FirebaseObject from '../firebase-mapping/firebase-object';
import ModelMapping from '../firebase-mapping/model-mapping';
import FirebasePropertyMapping from '../firebase-mapping/firebase-property-mapping';
import HelperCodec from '../codec/helper-codec';
import NotificationShardKey from './notification-shard-key.js';

let mapping = new ModelMapping(
  '/notifications/v3/users/{helpeeId}/helpers/{helperId}',
  {
    update: new FirebasePropertyMapping(
      '',
      () => {
        return true;
      },
      (instance) => {
        return instance._value;
      },
      (instance, value) => {
        instance._value = value;
      },
      new HelperCodec(),
    ),
  },
);

export default class ActiveHelper extends FirebaseObject {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param helper {Helper}
   * @param FirebaseService {FirebaseService}
   */
  constructor(notificationShardKey, helper, FirebaseService) {
    let firebaseInstanceId =
      FirebaseService.firebaseNotificationInstanceIdForShardKey(
        notificationShardKey,
      );
    let traceTag = `ActiveHelper[instance=${firebaseInstanceId}, ${notificationShardKey}]`;

    super(
      new Map().set('helpeeId', helper.helpeeId).set('helperId', helper.id),
      mapping,
      firebaseInstanceId,
      FirebaseService,
      undefined,
      true,
      traceTag,
    );

    this._helper = helper;
    this._firebaseService = FirebaseService;

    this._logTrace('cstr complete');
  }

  start() {
    this._logTrace('start');
    super.start();
    this._firebaseService.connectedEvent.subscribe(this._onConnected, this);
    this._setupOnDisconnect();
    return this;
  }

  stop() {
    this._logTrace('stop');
    this._setStatusOffline();
    this._firebaseService.connectedEvent.unsubscribe(this._onConnected, this);
    super.stop();
    return this;
  }

  _setupOnDisconnect() {
    this.firebase.onDisconnectSet(mapping.properties.update, null);
  }

  _onConnected(data) {
    if (data && data.connected) {
      this._logTrace('_onConnected');
      this._setupOnDisconnect();
      this.setHelper(this._helper);
    }
  }

  /**
   * Removes helper from helper list
   */
  _setStatusOffline() {
    this._logTrace('_setStatusOffline');
    this.firebase.removeValue(mapping.properties.update);
  }

  /**
   * Adds helper to helper list
   */
  setHelper() {
    this._logTrace('setHelper');
    this._helper.resetTime();
    this.firebase.setValue(mapping.properties.update, this._helper);
  }
}
