'use strict';

import StudentAssignmentFeedback from '../../model/domain/student-assignment-feedback';
import StudentAssignmentOverview from '../../model/domain/student-assignment-overview';
import StudentAssignmentWork from '../../model/domain/student-assignment-work';

/**
 * This service creates StudentAssignment* objects in a way that can be mocked in tests
 */
export default class StudentAssignmentService {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $timeout,
    $state,
    $stateParams,
    $mdDialog,
    AssignmentWorkService,
    CacheService,
    AssignmentTrackingService,
    BreadcrumbService,
    StorageService,
    StudentCacheService,
    ToolbarService,
    AnalyticsService,
    ExportService,
    FeedbackService,
    PlatformHeaderService,
    ClassCodeService,
  ) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;

    this._assignmentWorkService = AssignmentWorkService;
    this._cacheService = CacheService;
    this._assignmentTrackingService = AssignmentTrackingService;
    this._breadcrumbService = BreadcrumbService;
    this._storageService = StorageService;
    this._studentCacheService = StudentCacheService;
    this._toolbarService = ToolbarService;
    this._analyticsService = AnalyticsService;
    this._exportService = ExportService;
    this._feedbackService = FeedbackService;
    this._platformHeaderService = PlatformHeaderService;
    this._classCodeService = ClassCodeService;
  }

  /**
   * @return {StudentAssignmentFeedback}
   */
  getStudentAssignmentFeedback() {
    return new StudentAssignmentFeedback(
      this.$q,
      this.$timeout,
      this.$stateParams,
      this._assignmentWorkService,
      this._cacheService,
      this._assignmentTrackingService,
      this._breadcrumbService,
      this._storageService,
      this._studentCacheService,
      this._toolbarService,
      this._feedbackService,
    );
  }

  /**
   * @param helpInboxType {string}
   * @return {StudentAssignmentOverview}
   */
  getStudentAssignmentOverview(helpInboxType) {
    return new StudentAssignmentOverview(
      this.$timeout,
      this.$state,
      this.$stateParams,
      this.$mdDialog,
      this._studentCacheService,
      this._cacheService,
      this._breadcrumbService,
      this._exportService,
      this._platformHeaderService,
      helpInboxType,
    );
  }

  /**
   * @param helpInboxType {string}
   * @param [pager] {PageTraverser}
   * @return {StudentAssignmentWork}
   */
  getStudentAssignmentWork(helpInboxType, pager) {
    return new StudentAssignmentWork(
      this.$timeout,
      this.$state,
      this.$mdDialog,
      this._studentCacheService,
      this._assignmentTrackingService,
      this._cacheService,
      this._analyticsService,
      this._assignmentWorkService,
      this._exportService,
      this._feedbackService,
      this._platformHeaderService,
      this._classCodeService,
      helpInboxType,
      pager,
    );
  }
}
