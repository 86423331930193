'use strict';

import '../../controllers/user-notifications-list/user-notifications-list.controller';
import UserNotificationDialogTemplate from './user-notification-dialog.html';

/**
 * Dialog to show user notifications
 */
export default class UserNotificationDialogController {
  constructor($mdDialog, userNotificationListItem, parentController) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.userNotificationListItem = userNotificationListItem;

    /** @type UserNotificationsListController **/
    this.parentController = parentController;
  }

  /**
   * Shows the notification dialog
   */
  static show($mdDialog, userNotificationListItem, parentController) {
    return $mdDialog.show({
      controller: UserNotificationDialogController,
      template: UserNotificationDialogTemplate,
      controllerAs: 'ctrl',
      locals: {
        userNotificationListItem: userNotificationListItem,
        parentController: parentController,
      },
      clickOutsideToClose: true,
    });
  }

  /**
   * Shows dialog with custom message
   *
   * @param $mdDialog
   * @param title
   * @param message
   * @param ariaLabel
   * @param ok
   * @returns {*}
   */
  static customShow(
    $mdDialog,
    title = '',
    message = '',
    ariaLabel = '',
    ok = 'Ok',
    theme = 'alert',
  ) {
    return $mdDialog.show(
      $mdDialog
        .alert()
        .parent(angular.element('.root-div'))
        .clickOutsideToClose(true)
        .title(title)
        .htmlContent(message)
        .ariaLabel(ariaLabel)
        .theme(theme)
        .ok(ok),
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * Call to action
   */
  callToAction() {
    this.parentController.callToAction(this.userNotificationListItem);
    this.$mdDialog.hide();
  }

  /**
   * Remove notification list item from the notification list
   */
  removeUserNotificationListItem() {
    this.parentController.removeUserNotificationListItem(
      this.userNotificationListItem,
    );
    this.$mdDialog.hide();
  }
}
