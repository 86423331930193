export default class GoogleAnalyticsService {
  /**
   * @ngInject
   */
  constructor($window, $rootScope, $transitions, $log, $location, environment) {
    this.$window = $window;
    this.$rootScope = $rootScope;
    this.$transitions = $transitions;
    this.$log = $log;
    this.$location = $location;
    this._environment = environment;

    this._initialized = false;
  }

  init() {
    if (this._initialized) {
      return;
    }

    if (this._environment.googleAnalyticsIds) {
      //initializes GA tracking
      this.initializeGoogleAnalytics();

      //initializes Google tag manager - to be used by marketing, product, or growth team to create specific events
      // or conversion tracking without needing the eng team to make changes in the codebase
      this.initializeGoogleTagManager();
    }
    this._initialized = true;
  }

  initializeGoogleTagManager() {
    const googleTagManagerScript = angular.element(
      "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KKGS6R7');</script>",
    );
    googleTagManagerScript.prependTo('head');
    const noScript = angular.element('<noscript></noscript>');
    const iframe = angular.element(
      '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKGS6R7" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
    );
    iframe.prependTo(noScript);
    noScript.prependTo('body');
  }

  initializeGoogleAnalytics() {
    let host = this.$window.location.hostname;
    this._gaId = this._environment.googleAnalyticsIds[host];
    const googleTagManagerScript = angular.element(
      `<script async src="https://www.googletagmanager.com/gtag/js?id=${this._gaId}"></script>`,
    );
    googleTagManagerScript.appendTo('html');
    const gtagFunction = angular.element(
      '<script>\n' +
        '  window.dataLayer = window.dataLayer || [];\n' +
        '  function gtag(){dataLayer.push(arguments);}\n' +
        "  gtag('js', new Date());\n" +
        '</script>',
    );
    googleTagManagerScript.appendTo('html');
    gtagFunction.appendTo('html');

    if (this._gaId && this.$window.gtag) {
      this.$window.gtag('config', this._gaId);

      let dereg = this.$transitions.onSuccess({}, () => {
        this.$window.gtag('event', 'page_view', {
          page_path: this.$location.path(),
        });
      });

      this.$rootScope.$on('$destroy', dereg);

      this.$log.debug('google analytics configured');
    }
  }

  /**
   * @param exDescription {string}
   * @param exFatal {string}
   */
  sendException(description, fatal = false) {
    if (
      this._initialized &&
      this.$window.gtag &&
      this._environment.googleAnalyticsIds
    ) {
      this.$window.gtag('event', 'exception', {
        description,
        fatal,
      });
    }
  }

  /**
   * @param error {Error}
   * @return {string}
   */
  formatExceptionDescriptionError(error) {
    return error ? angular.toJson(error) : 'no description';
  }
}
