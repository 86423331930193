'use strict';

import moment from 'moment';
import { FileUtil } from '../util/file-util';

export default class AssignmentExport {
  constructor(assignment, user, classCode, questionDataURLs, showScore = true) {
    this._assignment = assignment;
    this._user = user;
    this._classCode = classCode;
    this._questionDataURLs = questionDataURLs;
    this._showScore = showScore;
  }

  /**
   * @return {Assignment|AssignmentWork}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @return {User}
   */
  get user() {
    return this._user;
  }

  /**
   * @return {string[]}
   */
  get questionDataURLs() {
    return this._questionDataURLs;
  }

  /**
   * @param value {string[]}
   */
  set questionDataURLs(value) {
    this._questionDataURLs = value;
  }

  /**
   * @param value {boolean}
   */
  set showMetadata(value) {
    this._showMetadata = value;
  }

  get assignmentName() {
    if (this._assignment.isWork) {
      return this._assignment.assignment.name;
    }
    return this._assignment.name;
  }

  /**
   * @return {ClassCode}
   */
  get classCode() {
    return this._classCode;
  }

  /**
   * @return {boolean}
   */
  get isWork() {
    return this.assignment.isWork;
  }

  /**
   * @return {AssignmentQuestion[]}
   */
  get questions() {
    return this.isWork
      ? this.assignment.assignment.questions
      : this.assignment.questions;
  }

  /**
   * @return {string}
   */
  get exportingMessage() {
    return this.user
      ? `Exporting ${this.user.name}'s work to PDF`
      : `Exporting ${this.assignment.name} to PDF`;
  }

  /**
   * @return {Assignment}
   */
  get content() {
    return this.isWork ? this.assignment.assignment : this.assignment;
  }

  /**
   * @return {AssignmentWork}
   */
  get work() {
    if (this.isWork) {
      return this.assignment;
    } else {
      return '';
    }
  }

  /**
   * @return {string[]}
   */
  get scoreDisplays() {
    return this.content.questions.map((question, index) => {
      if (this.work) {
        let workQuestion = this.work.questionForIndex(index);
        return workQuestion.isGraded
          ? `Points: ${workQuestion.points} / ${question.points}`
          : '';
      }
    });
  }

  /**
   * @return {string}
   */
  get assignmentDisplay() {
    return `Assignment: ${this.content.name}`;
  }

  /**
   * @return {string}
   */
  get studentDisplay() {
    return this.user ? `Student: ${this.user.name}` : '';
  }

  /**
   * @return {string}
   */
  get classCodeDisplay() {
    return this.classCode ? `Class Code: ${this.classCode.format()}` : '';
  }

  /**
   * @return {string}
   */
  get fileName() {
    let fileName = this.content.name;

    if (this.user) {
      fileName = `${fileName} - ${this.user.name}.pdf`;
    } else {
      fileName = `${fileName}.pdf`;
    }

    return FileUtil.removeForbiddenCharacters(fileName);
  }

  /**
   * @return {string}
   */
  get zipFileName() {
    let date = moment().format('YYYY-MM-DD');
    return FileUtil.removeForbiddenCharacters(
      `${this.content.name} ${date}.zip`,
    );
  }

  /**
   * @return {boolean}
   */
  get showScore() {
    return this._showScore;
  }

  /**
   * @return {boolean}
   */
  get showMetadata() {
    return this._showMetadata;
  }
}
