'use strict';

import {
  SubscriptionCoupons,
  SubscriptionPlans,
} from '../../model/domain/subscription';
import { SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';
import { ContractPlans } from '../../model/domain/contract';

export default class PaymentSuccessController {
  /**
   * @ngInject
   */
  constructor(
    $log,
    $q,
    $stateParams,
    BreadcrumbService,
    CacheService,
    StaticContentService,
    AnalyticsService,
  ) {
    this.$log = $log;
    this.$stateParams = $stateParams;
    this.$q = $q;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._loading = true;
    this._nextCharge = undefined;

    this.init();
  }

  init() {
    this._cacheService
      .getSubscriptions(true)
      .then((subscriptions) => {
        this._subscription = subscriptions.find(
          (subscription) => subscription.id === this.subscriptionId,
        );
        this._nextCharge = this.subscription.nextCharge;
        this._loading = false;

        this._analyticsService.subscriptionFunnel(
          SubscriptionFunnel.COMPLETE,
          this.subscriptionPlan,
          ContractPlans.PRO_CLASSROOM,
        );
      })
      .catch((error) => {
        this._error = error;
        this.$log.error(error.message);
      });
  }

  /**
   * @returns {string}
   */
  get subscriptionId() {
    return this.$stateParams.subscriptionId;
  }

  /**
   * @returns {Subscription}
   */
  get subscription() {
    return this._subscription;
  }

  get subscriptionPlan() {
    return this.subscription && this.subscription.subscriptionPlan;
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.home');
  }

  goHome() {
    this._breadcrumbService.go('root.account.home', {}, true);
  }

  goToGettingStartedWithPro() {
    this._staticContentService.goToGettingStartedTutorialVideo();
  }

  goToWatchTutorialVideo() {
    this._staticContentService.goToPortfolioStudentTutorialVideo();
  }

  get loading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  get planPrice() {
    return `$${this.subscriptionPlan === SubscriptionPlans.NiagaraAnnualV7 ? '399' : '33.99'}`;
  }

  get annualOrMonthly() {
    return this.subscriptionPlan === SubscriptionPlans.NiagaraAnnualV7
      ? 'Annual'
      : 'Monthly';
  }

  get nextCharge() {
    return this._nextCharge;
  }

  get planDiscount() {
    if (
      this.coupon &&
      this.coupon === SubscriptionCoupons.NiagaraAnnualLegacyV1
    ) {
      return '-$36';
    } else if (
      this.coupon &&
      this.coupon === SubscriptionCoupons.NiagaraMonthlyLegacyV1
    ) {
      return '-$6';
    } else {
      return '--';
    }
  }

  get coupon() {
    return this.subscription && this.subscription.coupon;
  }

  get planPriceDiscount() {
    return this.subscriptionPlan === SubscriptionPlans.NiagaraMonthlyV7
      ? this.monthlyPriceDiscount
      : this.yearlyPriceDiscount;
  }

  /**
   * @return {string}
   */
  get monthlyPriceDiscount() {
    return '$4.99';
  }

  /**
   * @return {string}
   */
  get yearlyPriceDiscount() {
    return '$60';
  }

  get totalPrice() {
    if (this.coupon) {
      return this.planPriceDiscount;
    } else {
      return this.planPrice;
    }
  }
}
