'use strict';

import moment from 'moment';
import AnimalNames from './animal-names';
import bearBlue from '../../../assets/images/animals/bear-blue.svg';
import bearGreen from '../../../assets/images/animals/bear-green.svg';
import bearOrange from '../../../assets/images/animals/bear-orange.svg';
import bearPurple from '../../../assets/images/animals/bear-purple.svg';
import bearRed from '../../../assets/images/animals/bear-red.svg';
import bearYellow from '../../../assets/images/animals/bear-yellow.svg';
import bookwormBlue from '../../../assets/images/animals/bookworm-blue.svg';
import bookwormGreen from '../../../assets/images/animals/bookworm-green.svg';
import bookwormOrange from '../../../assets/images/animals/bookworm-orange.svg';
import bookwormPurple from '../../../assets/images/animals/bookworm-purple.svg';
import bookwormRed from '../../../assets/images/animals/bookworm-red.svg';
import bookwormYellow from '../../../assets/images/animals/bookworm-yellow.svg';
import chickenBlue from '../../../assets/images/animals/chicken-blue.svg';
import chickenGreen from '../../../assets/images/animals/chicken-green.svg';
import chickenOrange from '../../../assets/images/animals/chicken-orange.svg';
import chickenPurple from '../../../assets/images/animals/chicken-purple.svg';
import chickenRed from '../../../assets/images/animals/chicken-red.svg';
import chickenYellow from '../../../assets/images/animals/chicken-yellow.svg';
import dogBlue from '../../../assets/images/animals/dog-blue.svg';
import dogGreen from '../../../assets/images/animals/dog-green.svg';
import dogOrange from '../../../assets/images/animals/dog-orange.svg';
import dogPurple from '../../../assets/images/animals/dog-purple.svg';
import dogRed from '../../../assets/images/animals/dog-red.svg';
import dogYellow from '../../../assets/images/animals/dog-yellow.svg';
import giraffeBlue from '../../../assets/images/animals/giraffe-blue.svg';
import giraffeGreen from '../../../assets/images/animals/giraffe-green.svg';
import giraffeOrange from '../../../assets/images/animals/giraffe-orange.svg';
import giraffePurple from '../../../assets/images/animals/giraffe-purple.svg';
import giraffeRed from '../../../assets/images/animals/giraffe-red.svg';
import giraffeYellow from '../../../assets/images/animals/giraffe-yellow.svg';
import pigBlue from '../../../assets/images/animals/pig-blue.svg';
import pigGreen from '../../../assets/images/animals/pig-green.svg';
import pigOrange from '../../../assets/images/animals/pig-orange.svg';
import pigPurple from '../../../assets/images/animals/pig-purple.svg';
import pigRed from '../../../assets/images/animals/pig-red.svg';
import pigYellow from '../../../assets/images/animals/pig-yellow.svg';
import rabbitBlue from '../../../assets/images/animals/rabbit-blue.svg';
import rabbitGreen from '../../../assets/images/animals/rabbit-green.svg';
import rabbitOrange from '../../../assets/images/animals/rabbit-orange.svg';
import rabbitPurple from '../../../assets/images/animals/rabbit-purple.svg';
import rabbitRed from '../../../assets/images/animals/rabbit-red.svg';
import rabbitYellow from '../../../assets/images/animals/rabbit-yellow.svg';
import robotBlue from '../../../assets/images/animals/robot-blue.svg';
import robotGreen from '../../../assets/images/animals/robot-green.svg';
import robotOrange from '../../../assets/images/animals/robot-orange.svg';
import robotPurple from '../../../assets/images/animals/robot-purple.svg';
import robotRed from '../../../assets/images/animals/robot-red.svg';
import robotYellow from '../../../assets/images/animals/robot-yellow.svg';

/**
 * Request types refer to the acceptable request types
 */
export class HelpRequestTypes {
  static get CHECK() {
    return 'check';
  }

  static get HELP() {
    return 'help';
  }

  static get CHAT() {
    return 'chat';
  }

  static get ALL() {
    return [
      HelpRequestTypes.HELP,
      HelpRequestTypes.CHECK,
      HelpRequestTypes.CHAT,
    ];
  }
}

export default class HelpRequest {
  /**
   * Contains information for a help request
   *
   * @param id {string} the help request id
   * @param helpeeId {string} the helpee id
   * @param [rosterId] {string|null} the roster id
   * @param [assignmentId] {string|null} the assignment id
   * @param questionId {string} the question id
   * @param requestType {string} the type of request (help or check)
   * @param created {string} when the help request was created
   * @param [resolved] {string} when the help request was resolved
   * @param [helperId] {string} the id of the user that's helping
   * @param [helperRole] {string} the role of the user that's helping (teacher, student, etc.)
   */
  constructor(
    id,
    helpeeId,
    rosterId,
    assignmentId,
    questionId,
    requestType,
    created,
    resolved,
    helperId,
    helperRole,
  ) {
    this._id = id;
    this._helpeeId = helpeeId;
    this._rosterId = rosterId;
    this._assignmentId = assignmentId;
    this._questionId = questionId;
    this._requestType = requestType;
    this._created = created ? moment(created) : undefined;
    this._resolved = resolved ? moment(resolved) : undefined;
    this._helperId = helperId;
    this._helperRole = helperRole;
    this._animalName = null;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get helpeeId() {
    return this._helpeeId;
  }

  /**
   * @returns {string}
   */
  get rosterId() {
    return this._rosterId;
  }

  /**
   * @returns {string}
   */
  get assignmentId() {
    return this._assignmentId;
  }

  /**
   * @returns {string}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @returns {string}
   */
  get requestType() {
    return this._requestType;
  }

  /**
   * @return {boolean}
   */
  get isHelp() {
    return this.requestType === HelpRequestTypes.HELP;
  }

  /**
   * @return {boolean}
   */
  get isCheck() {
    return this.requestType === HelpRequestTypes.CHECK;
  }

  /**
   * @return {boolean}
   */
  get isChat() {
    return this.requestType === HelpRequestTypes.CHAT;
  }

  /**
   * @returns {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @returns {moment}
   */
  get resolved() {
    return this._resolved;
  }

  /**
   * @param value {moment}
   */
  set resolved(value) {
    this._resolved = value;
  }

  /**
   * @returns {string}
   */
  get helperId() {
    return this._helperId;
  }

  /**
   * @returns {string}
   */
  get helperRole() {
    return this._helperRole;
  }

  /**
   * @returns {AnimalName}
   */
  get animalName() {
    if (!this._animalName) {
      this._animalName = AnimalNames.generate(this._id);
    }
    return this._animalName;
  }

  get anonAnimalNameForHelpee() {
    let animalName = this.animalName;
    return animalName && animalName.display;
  }

  get anonAnimalFileName() {
    let animalName = this.animalName;
    return animalName && animalName.file;
  }

  get anonAnimalImage() {
    const animalsImages = {
      'bear-blue': bearBlue,
      'bear-green': bearGreen,
      'bear-orange': bearOrange,
      'bear-purple': bearPurple,
      'bear-red': bearRed,
      'bear-yellow': bearYellow,
      'bookworm-blue': bookwormBlue,
      'bookworm-green': bookwormGreen,
      'bookworm-orange': bookwormOrange,
      'bookworm-purple': bookwormPurple,
      'bookworm-red': bookwormRed,
      'bookworm-yellow': bookwormYellow,
      'chicken-blue': chickenBlue,
      'chicken-green': chickenGreen,
      'chicken-orange': chickenOrange,
      'chicken-purple': chickenPurple,
      'chicken-red': chickenRed,
      'chicken-yellow': chickenYellow,
      'dog-blue': dogBlue,
      'dog-green': dogGreen,
      'dog-orange': dogOrange,
      'dog-purple': dogPurple,
      'dog-red': dogRed,
      'dog-yellow': dogYellow,
      'giraffe-blue': giraffeBlue,
      'giraffe-green': giraffeGreen,
      'giraffe-orange': giraffeOrange,
      'giraffe-purple': giraffePurple,
      'giraffe-red': giraffeRed,
      'giraffe-yellow': giraffeYellow,
      'pig-blue': pigBlue,
      'pig-green': pigGreen,
      'pig-orange': pigOrange,
      'pig-purple': pigPurple,
      'pig-red': pigRed,
      'pig-yellow': pigYellow,
      'rabbit-blue': rabbitBlue,
      'rabbit-green': rabbitGreen,
      'rabbit-orange': rabbitOrange,
      'rabbit-purple': rabbitPurple,
      'rabbit-red': rabbitRed,
      'rabbit-yellow': rabbitYellow,
      'robot-blue': robotBlue,
      'robot-green': robotGreen,
      'robot-orange': robotOrange,
      'robot-purple': robotPurple,
      'robot-red': robotRed,
      'robot-yellow': robotYellow,
    };
    return animalsImages[this.animalName.file];
  }
}
