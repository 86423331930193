export default class ProTrialConstants {
  /**
   * @returns {string}
   */
  static get NO_ACTION() {
    return 'noAction';
  }

  /**
   * @returns {string}
   */
  static get BENEFITS_BANNER() {
    return 'benefitsBanner';
  }

  /**
   * @returns {string}
   */
  static get REFER_PEER() {
    return 'referPeer';
  }

  /**
   * @returns {string}
   */
  static get PRO_TRIAL_OVERLAY() {
    return 'proTrialOverlay';
  }

  /**
   * @returns {string}
   */
  static get PRO_TRIAL_CONVERSION_OVERLAY() {
    return 'proTrialConversionOverlay';
  }
}
