'use strict';

import Codec from './codec';
import ContractMember from '../domain/contract-member';

/**
 * Codec for contracts
 */
export default class ContractMemberCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {object}
   * @returns {ContractMember}
   */
  decode(value) {
    return new ContractMember(value.contract_id, value.admin);
  }

  /**
   * @param value {ContractMember}
   * @returns {{contractId: string, admin: boolean}}
   */
  encode(value) {
    return {
      contract_id: value.contractId,
      admin: value.admin,
    };
  }
}
