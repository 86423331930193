'use strict';

import PropertyMapping from './property-mapping';

/**
 * Represents the mapping of a FirebaseObject collection property to a FirebaseCollection
 */
export default class FirebaseCollectionMapping extends PropertyMapping {
  /**
   * @param uriTemplate {string} relative uri snippet, may include curly brace delineated replacements for {id} values
   * @param listenIndicator {function(instance)} callback used to indicate whether to subscribe to this property of the given instance
   * @param getter {function(instance)} callback used to retrieve the firebase collection to bind with this mapped location
   * @param [codec] {codec} used to encode/decode the values between the client and firebase
   */
  constructor(uriTemplate, listenIndicator, getter, codec) {
    super(uriTemplate, listenIndicator, codec);

    this._getter = getter;
  }

  /**
   * indicates that this mapping represents a collection
   * @returns {boolean}
   */
  get isCollection() {
    return true;
  }

  /**
   * gets the FirebaseCollection for the given instance
   * @param instance {FirebaseObject}
   * @returns {FirebaseCollection}
   */
  get(instance) {
    return this._getter(instance);
  }

  /**
   * Used by FirebaseConnection when a value is added to the FirebaseCollection for the given instance
   * @param instance {FirebaseObject}
   * @param child {*}
   * @param prevChildKey {string}
   * @protected
   */
  _onAdded(instance, child, prevChildKey) {
    this.get(instance)._remoteAdd(child, prevChildKey);
  }

  /**
   * Used by FirebaseConnection when a value is removed from the FirebaseCollection for the given instance
   * @param instance {FirebaseObject}
   * @param child {*}
   * @protected
   */
  _onRemoved(instance, child) {
    this.get(instance)._remoteRemove(child);
  }

  /**
   * Used by FirebaseConnection when a value is modified in the FirebaseCollection for the given instance
   * @param instance {FirebaseObject}
   * @param child {*}
   * @param prevChildKey {string}
   * @protected
   */
  _onChanged(instance, child, prevChildKey) {
    this.get(instance)._remoteChange(child, prevChildKey);
  }
}
