'use strict';
import Codec from './codec';
import Session from '../domain/session';

/**
 * Encodes/decodes Gradebook session information
 */
export default class SessionCodec extends Codec {
  /**
   * @param value {Session}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        name: value.name,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {Session}
   */
  decode(value) {
    if (value) {
      return new Session(value.id, value.name);
    }

    return null;
  }
}
