'use strict';

import ModelMapping from '../firebase-mapping/model-mapping';
import ChatMessageCodec from '../codec/chat-message-codec';
import FirebaseCollectionMapping from '../firebase-mapping/firebase-collection-mapping';
import FirebaseObject from '../firebase-mapping/firebase-object';
import FirebaseCollection from '../firebase-mapping/firebase-collection';
import ChatMessageReadCodec from '../codec/chat-message-read-codec';

let mapping = new ModelMapping('/chats/{id}', {
  messages: new FirebaseCollectionMapping(
    '/msgs',
    (instance) => {
      return instance._listen;
    },
    (instance) => {
      return instance.messages;
    },
    new ChatMessageCodec(),
  ),
  messagesRead: new FirebaseCollectionMapping(
    '/msg_read',
    (instance) => {
      return instance._listen;
    },
    (instance) => {
      return instance.messagesRead;
    },
    new ChatMessageReadCodec(),
  ),
});

export default class ChatMessageList extends FirebaseObject {
  /**
   * @param id {string}
   * @param firebaseInstanceId {string} the firebase instance id for this chat list's data
   * @param FirebaseService {FirebaseService}
   * @param [listen] {boolean}
   */
  constructor(id, firebaseInstanceId, FirebaseService, listen = true) {
    var traceTag =
      'ChatMessageList[instance=' + firebaseInstanceId + ', id=' + id + ']';

    super(
      new Map().set('id', id),
      mapping,
      firebaseInstanceId,
      FirebaseService,
      listen,
      undefined,
      traceTag,
    );

    this._messages = new FirebaseCollection();
    this._messagesRead = new FirebaseCollection();

    this._logTrace('cstr complete');
  }

  /**
   * @returns {string}
   */
  get id() {
    return this.ids.get('id');
  }

  /**
   * @returns {FirebaseCollection.<ChatMessage>}
   */
  get messages() {
    return this._messages;
  }

  /**
   * @return {FirebaseCollection.<ChatMessageRead>}
   */
  get messagesRead() {
    return this._messagesRead;
  }

  /**
   * @param chatMessage {ChatMessage}
   */
  save(chatMessage) {
    this._logTrace('save id=' + chatMessage.id);
    if (this._listen && !this.messages.has(chatMessage.id)) {
      this.messages._remoteAdd(chatMessage);
    }
    return this.firebase.upsertChild(
      this.mapping.properties.messages,
      chatMessage,
    );
  }

  /**
   * @param chatMessage {ChatMessage}
   */
  remove(chatMessage) {
    this._logTrace('remove id=' + chatMessage.id);
    return this.firebase.removeChild(
      this.mapping.properties.messages,
      chatMessage,
    );
  }

  /**
   * @param chatMessageRead {ChatMessageRead}
   */
  markRead(chatMessageRead) {
    this._logTrace('markRead id=' + chatMessageRead.id);
    this.messagesRead._remoteAdd(chatMessageRead);
    return this.firebase.upsertChild(
      this.mapping.properties.messagesRead,
      chatMessageRead,
    );
  }
}
