import HexColors from '../../css-constants';

export class Placement {
  get cursor() {
    throw new Error('not-implemented-cursor');
  }

  /**
   * @param root {Snap} the root Snap.svg group
   * @param initial {Point}
   * @param current {boolean}
   */
  create(root, initial, current) {
    throw new Error(
      'not-implemented ' +
        root +
        ', initial=' +
        initial +
        ', current=' +
        current,
    );
  }

  /**
   * @param root {Snap} the root Snap.svg group
   * @param initial {Point}
   * @param current {boolean}
   */
  update(root, initial, current) {
    throw new Error(
      'not-implemented ' +
        root +
        ', initial=' +
        initial +
        ', current=' +
        current,
    );
  }

  remove() {
    throw new Error('not-implemented-remove');
  }
}

export class DefaultPlacement extends Placement {
  get cursor() {
    return 'crosshair';
  }

  /**
   * @param root
   * @param initial
   * @param current
   */
  create(root, initial, current) {
    this._rect = root.rect(0, 0, 0, 0).attr({
      fill: 'transparent',
      stroke: HexColors.CK_GREEN,
      strokeWidth: 2,
    });
  }

  /**
   * @param root
   * @param initial
   * @param current
   */
  update(root, initial, current) {
    let x = initial.x < current.x ? initial.x : current.x;
    let y = initial.y < current.y ? initial.y : current.y;
    let width = Math.abs(initial.x - current.x);
    let height = Math.abs(initial.y - current.y);

    this._rect.attr({
      x,
      y,
      width,
      height,
    });
  }

  remove() {
    this._rect.remove();
  }
}
