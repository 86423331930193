'use strict';

import Codec from './codec';
import LibraryAssignment from '../domain/library-assignment';
import AssignmentQuestion from '../domain/assignment-question';
import ElementList from '../domain/element-list';

/**
 * Encodes/Decodes a library assignment
 */
export default class LibraryAssignmentCodec extends Codec {
  constructor(FirebaseService) {
    super();
    this._firebaseService = FirebaseService;
  }

  encode() {
    return null;
  }

  /**
   * @param value {object}
   * @returns {LibraryAssignment|null}
   */
  decode(value) {
    if (value) {
      return new LibraryAssignment(
        value.id,
        value.name,
        value.owner_id,
        value.brief,
        value.access,
        value.last_modified,
        value.questions.map((question) => this.decodeQuestion(question)),
        value.owner_name ? value.owner_name : undefined,
        value.subjects,
        value.grades,
        value.question_count,
        value.tags,
      );
    }
    return null;
  }

  /**
   * @param question {id: <string>, element_list_id: {string}, element_list_db: {string}, point_value: <number>}
   * @return {AssignmentQuestion}
   */
  decodeQuestion(question) {
    return new AssignmentQuestion(
      question.id,
      new ElementList(
        question.element_list_id,
        question.element_list_db,
        this._firebaseService,
        'AssignmentQuestionElementList',
      ),
      question.point_value,
      question.title,
    );
  }
}
