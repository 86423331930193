'use strict';

import {
  Actions,
  PaywallSources,
  SubscriptionFunnel,
} from '../../services/mixpanel/mixpanel.service';
import AssignmentsLimitPaywallDialogTemplate from './assignments-limit-paywall-dialog.html';
import IncentiveAssignmentLimitV1Controller from '../../components/incentive-assignment-limit-v1-dialog/incentive-assignment-limit-v1-dialog.controller';
import limit5 from '../../../assets/images/assignments-limit/limit_5.svg';
import limit10 from '../../../assets/images/assignments-limit/limit_10.svg';
import limit15 from '../../../assets/images/assignments-limit/limit_15.svg';
import limit20 from '../../../assets/images/assignments-limit/limit_20.svg';
import limit25 from '../../../assets/images/assignments-limit/limit_25.svg';
import limit30 from '../../../assets/images/assignments-limit/limit_30.svg';
import limit35 from '../../../assets/images/assignments-limit/limit_35.svg';
import limit40 from '../../../assets/images/assignments-limit/limit_40.svg';
import limit45 from '../../../assets/images/assignments-limit/limit_45.svg';
import limit50 from '../../../assets/images/assignments-limit/limit_50.svg';
import limit55 from '../../../assets/images/assignments-limit/limit_55.svg';
import limit60 from '../../../assets/images/assignments-limit/limit_60.svg';
import limit65 from '../../../assets/images/assignments-limit/limit_65.svg';
import limit70 from '../../../assets/images/assignments-limit/limit_70.svg';
import limit75 from '../../../assets/images/assignments-limit/limit_75.svg';
import limit80 from '../../../assets/images/assignments-limit/limit_80.svg';
import limit85 from '../../../assets/images/assignments-limit/limit_85.svg';
import limit90 from '../../../assets/images/assignments-limit/limit_90.svg';
import limit95 from '../../../assets/images/assignments-limit/limit_95.svg';
import limit100 from '../../../assets/images/assignments-limit/limit_100.svg';
import { DEFAULT_ASSIGNMENT_LIMIT } from '../../model/domain/app-configuration';

export default class AssignmentsLimitPaywallDialogController {
  /**
   * @ngInject
   */
  constructor(
    $mdDialog,
    $q,
    BreadcrumbService,
    AnalyticsService,
    CacheService,
    StaticContentService,
    LogRocketService,
    used,
    limit,
    clickedFrom,
    isDisabledAssignment,
    assignmentId,
  ) {
    this.$mdDialog = $mdDialog;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    /** @type {CacheService} */
    this._cacheService = CacheService;

    this.$q = $q;

    this._analyticsService = AnalyticsService;
    this._staticContentService = StaticContentService;
    this._logRocketService = LogRocketService;

    this._used = used;
    this._limit = limit;
    this._clickedFrom = clickedFrom;
    this._isDisabledAssignment = isDisabledAssignment;
    this._assignmentId = assignmentId;
    this._percentage = Math.round((this._used / this._limit) * 100);
    this._percentageToNearest5 =
      Math.min(Math.ceil(this._percentage / 5) * 5, 100) || 5;

    this._incentiveAssignmentLimitV1 =
      IncentiveAssignmentLimitV1Controller.show;

    /** @type {AppConfiguration} */
    this._appConfig = {};
    /** @type {User} */
    this._user = null;
    this._userSchools = null;

    this._cacheService
      .getAppConfig()
      .then((config) => (this._appConfig = config));

    this._analyticsService.openOrCloseAssignmentPaywall(
      this._clickedFrom,
      Actions.OPENED,
      this.used,
      this._isDisabledAssignment,
      this._assignmentId,
    );

    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.PAYWALL,
      undefined,
      undefined,
      PaywallSources.ASSIGNMENTS_LIMIT_PAYWALL_DIALOG,
    );
  }

  get percentageToNearest5() {
    return this._percentageToNearest5;
  }

  get percentageImage() {
    const limitImages = {
      5: limit5,
      10: limit10,
      15: limit15,
      20: limit20,
      25: limit25,
      30: limit30,
      35: limit35,
      40: limit40,
      45: limit45,
      50: limit50,
      55: limit55,
      60: limit60,
      65: limit65,
      70: limit70,
      75: limit75,
      80: limit80,
      85: limit85,
      90: limit90,
      95: limit95,
      100: limit100,
    };
    return limitImages[this.percentageToNearest5];
  }

  /**
   * Check if the user already received assignment-limit-v1 incentive
   *
   * @returns {boolean}
   */
  get receivedAssignmentLimitV1Incentive() {
    // Hacky hack - check if the user's assignment limit is greater than the default
    return this._appConfig.assignmentLimit > DEFAULT_ASSIGNMENT_LIMIT;
  }

  get used() {
    return this._used;
  }

  get limit() {
    return this._limit;
  }

  get title() {
    return 'You Have Reached The Limit For The Basic Plan.';
  }

  get bottomMessage() {
    return 'Upgrade to one of our Classkick Pro plans to get unlimited assignments as well as tons of additional Pro features!';
  }

  get isDisabledAssignment() {
    return this._isDisabledAssignment;
  }

  /**
   * Launches assignment limit paywall dialog
   * @param $mdDialog
   * @param used {number} the number of assignments the current user has used
   * @param limit {number} the number of assignments the current user is limited to
   */
  static show(
    $mdDialog,
    used,
    limit,
    clickedFrom,
    isDisabledAssignment = false,
    assignmentId = null,
  ) {
    return $mdDialog.show({
      controller: AssignmentsLimitPaywallDialogController,
      template: AssignmentsLimitPaywallDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      locals: {
        used,
        limit,
        clickedFrom,
        isDisabledAssignment,
        assignmentId,
      },
    });
  }

  cancel() {
    this._analyticsService.openOrCloseAssignmentPaywall(
      this._clickedFrom,
      Actions.CLOSED,
      this.used,
      this._isDisabledAssignment,
      this._assignmentId,
    );
    this.$mdDialog.cancel();
  }

  goToTeacherPage() {
    this._analyticsService.clickAssignmentLimitPaywallButtonOrLink(
      'proTeacher',
      this.used,
      this._isDisabledAssignment,
      this._assignmentId,
    );
    this.$mdDialog.hide();
    this._staticContentService.goToTeacherOrderPage(
      this._logRocketService.initialized,
    );
  }

  goToOrderPage() {
    this._analyticsService.clickAssignmentLimitPaywallButtonOrLink(
      'proSchool',
      this.used,
      this._isDisabledAssignment,
      this._assignmentId,
    );
    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.LEARN_MORE,
      undefined,
      undefined,
      PaywallSources.ASSIGNMENTS_LIMIT_PAYWALL_DIALOG,
    );

    this.$mdDialog.hide();
    this._staticContentService.goToOrdersPage(
      this._logRocketService.initialized,
    );
  }

  openIncentiveAssignmentLimitV1() {
    this.$q
      .all({
        user: this._cacheService.getUser(),
        userSchools: this._cacheService.getSchools(false),
      })
      .then(({ user, userSchools }) => {
        this._incentiveAssignmentLimitV1(
          'assignmentLimitPaywallDialog',
          this.$mdDialog,
          user,
          userSchools,
        );
      });
  }
}
