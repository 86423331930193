'use strict';

import SaveStates from './save-states';
import SavingIndicatorDirectiveTemplate from './saving-indicator.html';

export default function SavingIndicatorDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      saveState: '=',
    },
    template: SavingIndicatorDirectiveTemplate,
    link: SavingIndicatorDirectiveController.link,
    controller: SavingIndicatorDirectiveController,
    controllerAs: 'ctrl',
  };
}

export class SavingIndicatorDirectiveController {
  /**
   * @ngInject
   */
  constructor($timeout) {
    this.$timeout = $timeout;
    this._fadeCopyTimeout = undefined;
    this._saveState = '';
  }

  get saveState() {
    return this._saveState;
  }

  set saveState(value) {
    if (this._fadeCopyTimeout) {
      this.$timeout.cancel(this._fadeCopyTimeout);
    }

    if (value === this.SAVED) {
      this._fadeCopyTimeout = this.$timeout(() => {
        this._saveState = undefined;
        this._fadeCopyTimeout = undefined;
      }, 3000);
    }

    this._saveState = value;
  }

  get SAVED() {
    return SaveStates.SAVED;
  }

  get UNSAVED() {
    return SaveStates.UNSAVED;
  }

  get SAVE_ERROR() {
    return SaveStates.SAVE_ERROR;
  }

  get SAVE_ERROR_STUDENT_DUPLICATE() {
    return SaveStates.SAVE_ERROR_STUDENT_DUPLICATE;
  }

  get ANSWER_SAVED() {
    return SaveStates.ANSWER_SAVED;
  }

  /**
   * @return {string}
   */
  get saveMessage() {
    if (this.saveState === this.SAVED) {
      return 'Saved';
    } else if (this.saveState === this.UNSAVED) {
      return 'Saving...';
    } else if (this.saveState === this.SAVE_ERROR) {
      return 'Unable to save';
    } else if (this.saveState === this.SAVE_ERROR_STUDENT_DUPLICATE) {
      return 'Unable to save, students with the identical names';
    } else if (this.saveState === this.ANSWER_SAVED) {
      return 'Answer saved';
    } else {
      return '';
    }
  }

  static link(scope, element, attrs, ctrl) {
    scope.$watch('saveState', (value) => {
      ctrl.saveState = value;
    });
  }
}
