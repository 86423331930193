'use strict';

import Debouncer from '../../model/util/debouncer';
import SaveStates from '../../components/saving-indicator/save-states';
import { UserProperties } from '../../model/domain/user';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import { AuthType } from '../../services/auth/auth.service';
import Validation from '../../model/util/validation';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import NavController from '../nav/nav.controller';
import DeleteAccountDialogController from '../../components/delete-account-dialog/delete-account-dialog.controller';
import UpdateUserSchoolDialogController from '../../components/update-user-school-dialog/update-user-school-dialog.controller';
import AssignmentsLimitPaywallDialogController from '../../components/assignments-limit-paywall-dialog/assignments-limit-paywall-dialog.controller';
import CancelSubscriptionDialogController from '../../components/cancel-subscription-dialog/cancel-subscription-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import SelectMdrOrgDialogController from '../../components/select-mdr-org-dialog/select-mdr-org-dialog.controller';
import Sorts from '../../model/domain/sorts';
import {
  Locations,
  SubscriptionFunnel,
} from '../../services/mixpanel/mixpanel.service';
import { ABTest } from '../../services/ab-test/ab-test-service';
import { DEFAULT_ASSIGNMENT_LIMIT } from '../../model/domain/app-configuration';
import { SubscriptionPlans } from '../../model/domain/subscription';

export default class UserProfileController {
  constructor(
    $q,
    $state,
    $stateParams,
    $scope,
    $rootScope,
    AuthService,
    $mdSidenav,
    $window,
    $log,
    $mdToast,
    $mdDialog,
    $document,
    CacheService,
    StudentCacheService,
    InviteCodeService,
    SubscriptionService,
    BreadcrumbService,
    OrganizationService,
    StaticContentService,
    LogRocketService,
    AnalyticsService,
    UserService,
    StorageService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdSidenav = $mdSidenav;
    this.$window = $window;
    this.$log = $log;
    this.$mdToast = $mdToast;
    this.$mdDialog = $mdDialog;
    this.$document = $document;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {InviteCodeService} */
    this._inviteCodeService = InviteCodeService;
    /** @type {SubscriptionService} */
    this._subscriptionService = SubscriptionService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._loadingDialog = LoadingDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._deleteAccountDialog = DeleteAccountDialogController.show;
    this._mdrOrgDialog = SelectMdrOrgDialogController.show;

    this._state = this.$stateParams.tab || UserProfileController.BasicInfoTab;
    this.saveState = null;

    /** @type {string[]} */
    this.prefixOptions = UserProperties.PREFIX_OPTIONS;
    /** @type {string[]} */
    this.jobTitleOptions = UserProperties.JOB_TITLE_OPTIONS;
    /** @type {string[]} */
    this.subjectOptions = UserProperties.SUBJECT_OPTIONS;
    /** @type {string[]} */
    this.studentAgeOptions = UserProperties.STUDENT_AGE_OPTIONS;
    /** @type {string[]} */
    this.referralOptions = UserProperties.REFERRAL_OPTIONS;

    /** @type {string} */
    this.oldPassword = '';
    /** @type {string} */
    this.newPassword = '';

    /** @type {string} */
    this.prefix = null;
    /** @type {string} */
    this.firstName = null;
    /** @type {string} */
    this.lastName = null;
    /** @type {string} */
    this.username = null;
    /** @type {string} */
    this.email = null;
    /** @type {string} */
    this.jobTitle = null;
    /** @type {string} */
    this.referral = null;
    /** @type {string} */
    this.studentAge = null;
    /** @type {string} */
    this.subject = null;
    /** @type {string} */

    /** @type {AppConfiguration} */
    this._appConfig = null;
    /** @type {User} */
    this._user = null;
    /** @type {Contract[]} */
    this._contracts = null;
    /** @type {Subscription[]} */
    this._subscriptions = null;
    /** @type {Organization[]} */
    this._schools = null;
    /** @type {Organization[]} */
    this._unverifiedSchools = null;
    /** @type {number} */
    this._assignmentsUsedCount = 0;

    this._inviteCodePattern = Validation.InviteCodePattern;
    this._inviteCodeRegex = Validation.InviteCodeRegex;
    this._inviteCode = '';
    this._inviteCodeError = false;
    this._loading = true;

    this._updateUserSchoolDialog = UpdateUserSchoolDialogController.show;
    this._assignmentsLimitPaywallDialog =
      AssignmentsLimitPaywallDialogController.show;
    this._cancelSubscriptionDialog = CancelSubscriptionDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    this._usernameError = null;
    this._emailError = null;
    this._addedNewPassword = false;

    $scope.$on('$destroy', () => this._destroy());
    if ($window) {
      this._anonBeforeUnload = (event) => this._onBeforeUnload(event);
      $window.onbeforeunload.add(this._anonBeforeUnload);
    }

    this.init();

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
        location: 'userProfile',
      },
    });
  }

  init() {
    this._loading = true;
    this._debouncer = new Debouncer(
      3000,
      5000,
      () => {
        this._commit();
      },
      this.$scope,
      this.$window,
    );

    if (this.isACoTeacher()) {
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService
        .processTokenResult(
          this._authService.coTeacherAuthData.token,
          this._storageService.rememberMe,
        )
        .then(() => {
          this._initialUserProfileData();
        });
    } else {
      return this._initialUserProfileData();
    }
  }

  _initialUserProfileData() {
    this.$q
      .all({
        user: this._cacheService.getUser(true),
        proInfo: this._cacheService.getProInfo(),
        subscriptions: this._cacheService.getSubscriptions(),
        schools: this._cacheService.getSchools(),
        unverifiedSchools: this._cacheService.getUnverifiedOrganizations(true),
        assignmentLimit20Segment: this._cacheService.getTestSegment(
          ABTest.AssignmentLimit20,
        ),
      })
      .then(
        ({
          user,
          proInfo,
          subscriptions,
          schools,
          unverifiedSchools,
          assignmentLimit20Segment,
        }) => {
          this.user = user;
          this._proInfo = proInfo;
          this._contracts = proInfo.contracts;
          this._subscriptions = subscriptions;
          this._schools = this._getAdminsForSchools(schools);
          this._unverifiedSchools = new Map(
            unverifiedSchools.map((school) => [school.id, school]),
          );
          this.isAssignmentLimit20Segment = assignmentLimit20Segment;

          // Get application configuration
          this._cacheService
            .getAppConfig()
            .then((config) => (this._appConfig = config));

          if (this.isTeacher && this._proInfo.isFree) {
            this._initPlans();
          }
          this._loading = false;
        },
      )
      .catch((error) => {
        this.error = error;
        this.$log.error(error.message);
        this._loading = false;
      });
  }

  _initPlans() {
    this._cacheService.getAssignmentsForUser(false).then((assignmentsMap) => {
      const assignments = Array.from(
        assignmentsMap,
        (tuple) => tuple[1],
      ).filter((assignment) => !assignment.isFolder);

      this._assignmentsUsedCount = assignments.length;
    });
  }

  _onBeforeUnload() {
    this._commit();
  }

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  static get BasicInfoTab() {
    return 'info';
  }

  static get SchoolTab() {
    return 'school';
  }

  static get PlanTab() {
    return 'plan';
  }

  get viewingInfo() {
    return this._state === UserProfileController.BasicInfoTab;
  }

  get viewingSchool() {
    return this._state === UserProfileController.SchoolTab;
  }

  get viewingPlan() {
    return this._state === UserProfileController.PlanTab;
  }

  goToProfileInfo() {
    this.$state.go(this.$state.current.name, {
      tab: UserProfileController.BasicInfoTab,
    });
    this._state = UserProfileController.BasicInfoTab;
  }

  goToProfileSchool() {
    this.$state.go(this.$state.current.name, {
      tab: UserProfileController.SchoolTab,
    });
    this._state = UserProfileController.SchoolTab;
  }

  goToProfilePlan() {
    this.$state.go(this.$state.current.name, {
      tab: UserProfileController.PlanTab,
    });
    this._state = UserProfileController.PlanTab;
  }

  /**
   * Indicates if initial load has completed
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @return {number}
   */
  get assignmentsUsedCount() {
    return this._assignmentsUsedCount;
  }

  /**
   * @return {number}
   */
  get assignmentsLimit() {
    return (
      (this._appConfig && this._appConfig.assignmentLimit) ||
      DEFAULT_ASSIGNMENT_LIMIT
    );
  }

  get assignmentsUsedWidth() {
    let assignmentsUsedPercentage = Math.round(
      (this.assignmentsUsedCount / this.assignmentsLimit) * 100,
    );
    // The overall width is 500px, so determine the percent that should be "used"
    return Math.min(assignmentsUsedPercentage, 100) * 5;
  }

  openAssignmentsLimitPaywallDialog() {
    return this._assignmentsLimitPaywallDialog(
      this.$mdDialog,
      this.assignmentsUsedCount,
      this.assignmentsLimit,
      Locations.PROFILE_PLAN,
    );
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (
      (this._storageService.lastSeenTrialConversionBanner &&
        this._storageService.lastSeenTrialConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenRenewalConversionBanner &&
        this._storageService.lastSeenRenewalConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenAssignmentNotificationBanner &&
        this._storageService.lastSeenAssignmentNotificationBanner.showBanner ===
          true)
    );
  }

  /**
   * @return {Contract[]}
   */
  get contracts() {
    return this._contracts;
  }

  get activeContracts() {
    return this._contracts.filter((contract) => !contract.isExpired);
  }

  get expiredContracts() {
    return this._contracts.filter((contract) => contract.isExpired);
  }

  /**
   * @return {boolean}
   */
  get hasContract() {
    return this._contracts && this.activeContracts.length === 1;
  }

  /**
   * @return {boolean}
   */
  get hasContracts() {
    return this._contracts && this.activeContracts.length > 1;
  }

  /**
   * @return {boolean}
   */
  get showTrialExplanationText() {
    return (
      this._contracts.some(
        (contract) => contract.isTrial === true && !contract.isExpired,
      ) && this.isTeacher
    );
  }

  /**
   * @return {Subscription[]}
   */
  get subscriptions() {
    return this._subscriptions;
  }

  /**
   * @param contractId {string}
   * @return {boolean}
   */
  isGrandfatheredPlan(contractId) {
    let subscription = this._subscriptions.find(
      (subscription) => subscription.contractId === contractId,
    );
    if (
      subscription.subscriptionPlan !== SubscriptionPlans.NiagaraAnnualV7 &&
      subscription.subscriptionPlan !== SubscriptionPlans.NiagaraMonthlyV7
    ) {
      return true;
    }
  }

  /**
   * @param contractId {string}
   * @return {boolean}
   */
  contractHasSubscription(contractId) {
    return this._subscriptions.some(
      (subscription) =>
        subscription.contractId === contractId && subscription.active === true,
    );
  }

  /**
   * @param contract {Contract}
   */
  updatePaymentInfo(contract) {
    let subscription = this._subscriptions.find(
      (subscription) => subscription.contractId === contract.id,
    );
    this._breadcrumbService.go('root.account.update-payment', {
      subscriptionPlan: subscription.subscriptionPlan,
    });
  }

  /**
   * @param contract {Contract}
   */
  cancelSubscription(contract) {
    let subscription = this._subscriptions.find(
      (subscription) => subscription.contractId === contract.id,
    );

    this._cancelSubscriptionDialog(
      this.$mdDialog,
      this._user,
      subscription,
      contract,
    ).then(() => {
      let promise = this._subscriptionService
        .cancel(subscription.id)
        .then(() => {
          return this._cacheService.getSubscriptions(true);
        })
        .then((subscriptions) => {
          this._subscriptions = subscriptions;
          this.showToast('Subscription cancelled');
        })
        .catch(() => {
          this._errorDialog(
            this.$mdDialog,
            'There was a problem canceling your subscription please reach out to support@classkick.com',
            '',
          );
        });

      this._loadingDialog(this.$mdDialog, promise);
    });
  }

  /**
   * @param contract {Contract}
   * @return {string}
   */
  recurringStatusDisplay(contract) {
    if (contract.isExpired) {
      return `Expired ${contract.expiresDisplay}`;
    } else if (contract.plan === 'niagara') {
      if (this.contractHasSubscription(contract.id)) {
        return `Renews ${contract.subscriptionRenewsDisplay}`;
      } else {
        return `Canceled. Expires ${contract.expiresDisplay}`;
      }
    } else {
      return `Expires ${contract.expiresDisplay}`;
    }
  }

  /**
   * @return {Organization[]}
   */
  get schools() {
    return this._schools;
  }

  /**
   * @return {boolean}
   */
  get hasSchool() {
    return this._schools && this._schools.length > 0;
  }

  /**
   * @return {boolean}
   */
  get hasSchools() {
    return this._schools && this._schools.length > 1;
  }

  changeSchool() {
    this._updateUserSchoolDialog(this.$mdDialog, 'Change Organization').then(
      (school) => this._changeSchool(school),
    );
  }

  _changeSchool(school) {
    this.saveState = this.UNSAVED;

    // if user is already a member of the school, skip change school logic
    if (this._schools.some((s) => s.id === school.id)) {
      this.saveState = this.SAVED;
      return;
    }

    let promise = this._leaveNonAdminSchools()
      .then(() => {
        return this._organizationService.addSelf(school.id);
      })
      .then((token) => {
        this._cacheService.reset();
        return this._authService.processTokenResult(
          token,
          this._authService.rememberMe,
        );
      })
      .then(() => {
        this.$state.reload();
      })
      .catch(() => {
        this.saveState = this.SAVE_ERROR;
      });

    this._loadingDialog(this.$mdDialog, promise);
  }

  _leaveNonAdminSchools() {
    let nonAdminSchools = this._schools.filter(
      (school) => !school.contractId || !school.admin,
    );
    return this.$q.all(
      nonAdminSchools.map((school) =>
        this._organizationService.removeSelf(school.id),
      ),
    );
  }

  /**
   * @returns {User}
   */
  get user() {
    return this._user;
  }

  /**
   * @param user {User}
   */
  set user(user) {
    if (user.displayName && user.displayName.includes(' ')) {
      this.prefix = user.displayName.split(' ')[0];
    }

    // md-autocomplete fields are somewhat touchy about the kinds of fields they work with.
    // Dropdowns seem to function best with null as the initial condition
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.username = user.username;
    this.email = user.email;
    this.jobTitle = user.jobTitle || null;
    this.referral = user.referral || null;
    this.studentAge = user.studentAge;
    this.subject = user.subject || null;

    this._user = user;
  }

  fieldUpdated(field, form, skip) {
    if (skip) {
      return;
    }

    field.$touched = true;

    let valid = form.$valid;

    if (!this.email && !this.username) {
      this._emailError = this.isStudent
        ? 'You must set an email or username'
        : 'You must set an email';
      form.email.$setValidity('required', true);
    }

    this._debouncer.tick();
    this._debouncer.valid = valid;

    if (valid) {
      this.saveState = this.UNSAVED;
    } else {
      this.saveState = this.SAVE_ERROR;
    }
  }

  changePassword(form) {
    let promise = this._authService
      .updatePassword(this.oldPassword, this.newPassword)
      .then((success) => {
        if (success) {
          this.showToast('Your password was updated successfully!');
          this.resetUpdatePasswordForm(form);
        } else {
          form.oldPassword.$setValidity('invalid-password', false);
        }
      });

    return this._loadingDialog(
      this.$mdDialog,
      promise,
      this.$document,
      '.user-profile .update-password-form',
    );
  }

  resetAddPasswordForm(form) {
    this.newPassword = '';
    this.showNewPassword = false;
    form.newPassword.$setValidity('required', true);
    form.$setUntouched();
    form.$setPristine();
  }

  addPassword(form) {
    let promise = this._userService
      .setPassword(this.user.email, this.newPassword)
      .then((success) => {
        if (success) {
          this.showToast('Your password was added successfully!');
          this.resetAddPasswordForm(form);
          this.addedNewPassword = true;
          this._analyticsService.addedNewPassword(this.user.id);
        } else {
          form.newPassword.$setValidity('invalid-password', false);
        }
      });

    return this._loadingDialog(
      this.$mdDialog,
      promise,
      this.$document,
      '.user-profile .add-password-form',
    );
  }

  resetUpdatePasswordForm(form) {
    this.oldPassword = '';
    this.newPassword = '';
    this.showNewPassword = false;
    form.oldPassword.$setValidity('required', true);
    form.oldPassword.$setValidity('invalid-password', true);
    form.newPassword.$setValidity('required', true);
    form.$setUntouched();
    form.$setPristine();
  }

  oldPasswordUpdated(form) {
    form.oldPassword.$setValidity('invalid-password', true);
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  resetInviteCodeForm(proInviteForm) {
    this._inviteCode = '';
    proInviteForm.inviteCode.$setValidity('invalid-code', true);
    proInviteForm.inviteCode.$setValidity('too-long', true);
    proInviteForm.inviteCode.$setValidity('pattern', true);
    proInviteForm.$setUntouched();
    proInviteForm.$setPristine();
    this._inviteCodeError = false;
  }

  inviteCodeUpdated(proInviteForm) {
    proInviteForm.inviteCode.$setValidity('invalid-code', true);
    proInviteForm.inviteCode.$setValidity('too-long', true);
    proInviteForm.inviteCode.$setValidity('pattern', true);
    this._inviteCodeError = false;

    this._inviteCode = this._inviteCode
      .replace(/[\s]/g, '')
      .slice(0, 6)
      .toUpperCase();

    if (!this._inviteCode) {
      return;
    }

    if (this._inviteCode.length > 5) {
      proInviteForm.inviteCode.$setValidity('too-long', false);
      this._inviteCodeError = true;
    } else if (!this._inviteCodeRegex.test(this._inviteCode)) {
      proInviteForm.inviteCode.$setValidity('pattern', false);
      this._inviteCodeError = true;
    }
  }

  enterProInvite(proInviteForm) {
    this._inviteCodeError = false;
    proInviteForm.inviteCode.$setValidity('invalid-code', true);
    let promise = this._inviteCodeService
      .submit(this.inviteCode)
      .then((token) => {
        return this._authService.processTokenResult(
          token,
          this._authService.rememberMe,
        );
      })
      .then(() => {
        this._cacheService.reset();
        this.showToast('You have been added to the organization successfully!');
        this.resetInviteCodeForm(proInviteForm);
        this._updateLeftNav();
        this.$state.reload();
      })
      .catch((err) => {
        this._inviteCodeError = true;
        proInviteForm.inviteCode.$setValidity('invalid-code', false);
        this.$log.error(err);
      });

    this._loadingDialog(
      this.$mdDialog,
      promise,
      this.$document,
      '.user-profile .pro-invite-form',
    );
  }

  deleteAccountDialog() {
    if (this.isTeacher) {
      this._deleteAccountDialog(this.$mdDialog, this.user);
    } else {
      let promise = this._studentCacheService.getUserAssignmentsAndWorks(true);

      this._loadingDialog(this.$mdDialog, promise);

      promise.then(({ works }) => {
        if (
          this._worksStarted(works) >
          DeleteAccountDialogController.AssignmentsWorkedOnThreshold
        ) {
          this._confirmDialog(
            this.$mdDialog,
            "Uh oh. It looks like you've already done quite a bit of work. If you are sure you want to delete your account, please email support@classkick.com",
            undefined,
            true,
          );
        } else {
          this._deleteAccountDialog(this.$mdDialog, this.user);
        }
      });
    }
  }

  /**
   * @param works {Map<AssignmentWork>}
   * @return {number}
   */
  _worksStarted(works) {
    return Array.from(works.values()).filter((work) => work.hasStarted).length;
  }

  /**
   * The invite code entered by the teacher
   * @returns {string}
   */
  get inviteCode() {
    return this._inviteCode;
  }

  /**
   * Sets the invite code entered by the teacher
   * @param value {string}
   */
  set inviteCode(value) {
    this._inviteCode = value;
  }

  /**
   *
   * @returns {boolean}
   */
  get inviteCodeError() {
    return this._inviteCodeError;
  }

  /**
   *
   * @returns {boolean}
   */
  get emailError() {
    return this._emailError;
  }

  /**
   *
   * @returns {boolean}
   */
  get usernameError() {
    return this._usernameError;
  }

  /**
   * The pattern used for validation on the input.
   * @returns {string}
   */
  get inviteCodePattern() {
    return this._inviteCodePattern;
  }

  _commit() {
    this._usernameError = false;
    this._emailError = false;
    this.user.displayName = this.prefix
      ? `${this.prefix} ${this.lastName}`
      : null;
    this.user.firstName = this.firstName;
    this.user.lastName = this.lastName;
    this.user.username = this.username;
    this.user.email = this.email;
    this.user.jobTitle = this.jobTitle;
    this.user.referral = this.referral;
    this.user.studentAge = this.studentAge;
    this.user.subject = this.subject;

    return this._cacheService
      .updateUser(this.user)
      .then(() => {
        this.saveState = this.SAVED;
        this._updateLeftNav();
      })
      .catch((err) => {
        if (err.data && err.data.message) {
          if (err.data.message.indexOf('Duplicate entry') !== -1) {
            this._emailError = 'Email is already in use';
          } else if (err.data.message.indexOf('well-formed') !== -1) {
            this._emailError = 'Not a valid email address';
          }
        }
        this.$log.error(err);
        this.saveState = this.SAVE_ERROR;
      });
  }

  get SAVED() {
    return SaveStates.SAVED;
  }

  get UNSAVED() {
    return SaveStates.UNSAVED;
  }

  get SAVE_ERROR() {
    return SaveStates.SAVE_ERROR;
  }

  /**
   *
   * @param search {string} the search term
   * @param array {string[]} the original array of options
   * @returns {string[]}
   */
  autocompleteFilter(search, array) {
    if (!search) {
      return array;
    }
    return array.filter((value) =>
      value.toLowerCase().includes(search.toLowerCase()),
    );
  }

  /**
   * @return {boolean}
   */
  get isTeacher() {
    return this._authService.authData && this._authService.authData.isTeacher;
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this._authService.authData && this._authService.authData.isStudent;
  }

  /**
   * Returns whether the teacher's auth types includes password authing.
   */
  get isPasswordAuthed() {
    return this.user.authTypes.includes(AuthType.PASSWORD);
  }

  /**
   * Returns whether the teacher's auth types includes Google auth.
   */
  get isGoogleAuthed() {
    return this.user.authTypes.includes(AuthType.GOOGLE);
  }

  /**
   * Returns whether the teacher's auth types includes Clever auth.
   */
  get isCleverAuthed() {
    return this.user.authTypes.includes(AuthType.CLEVER);
  }

  get isCleverImported() {
    return this.user.authTypes.includes(AuthType.CLEVERIMPORT);
  }

  /**
   * @return {boolean}
   */
  get addedNewPassword() {
    return this._addedNewPassword;
  }

  /**
   * Sets value for if a new password was added
   * @param value {boolean}
   */
  set addedNewPassword(value) {
    this._addedNewPassword = value;
  }

  _destroy() {
    this._debouncer.destroy();
  }
  /**
   * @param message {string}
   */
  showToast(message) {
    this.$mdToast.show(
      this.$mdToast.simple().textContent(message).position('bottom right'),
    );
  }

  _updateLeftNav() {
    this.$rootScope.$broadcast(NavController.UPDATE_LEFT_NAV, {});
  }

  _getAdminsForSchools(schools) {
    schools.sort((a, b) => Sorts.NAME_ASC(a.name, b.name));

    return schools.map((school) => {
      if (school.contractId) {
        this._cacheService
          .getUsersForContract(school.contractId)
          .then((users) => {
            return Array.from(users.values()).filter((user) => {
              return (
                user.contractMembers &&
                user.contractMembers.length > 0 &&
                user.contractMembers[0].admin === true
              );
            });
          })
          .then((admins) => {
            school.adminMembers = admins;
          });
      }
      return school;
    });
  }

  removeSelfFromOrganization(organizationId) {
    this._organizationService
      .removeSelf(organizationId)
      .then(() =>
        this._cacheService
          .getSchools(true)
          .then(
            (schools) => (this._schools = this._getAdminsForSchools(schools)),
          )
          .then(() =>
            this._cacheService.getProInfo(true).then((proInfo) => {
              this._contracts = proInfo.contracts;
              this.$state.reload();
            }),
          ),
      )
      .catch(() => {
        this.saveState = this.SAVE_ERROR;
      });
  }

  addSelfToOrganization() {
    this._updateUserSchoolDialog(this.$mdDialog, 'Join Organization').then(
      (school) => this._addSelfToOrganization(school),
    );
  }

  _addSelfToOrganization(school) {
    this.saveState = this.UNSAVED;

    let promise = this._organizationService
      .addSelf(school.id)
      .then((token) => {
        this._cacheService.reset();
        return this._authService.processTokenResult(
          token,
          this._authService.rememberMe,
        );
      })
      .then(() => {
        this.$state.reload();
      })
      .catch(() => {
        this.saveState = this.SAVE_ERROR;
      });

    this._loadingDialog(this.$mdDialog, promise);
  }

  displayAdminTitle(admins) {
    if (admins.length) {
      return admins.length === 1 ? 'Admin' : 'Admins';
    } else {
      return 'No Admins';
    }
  }

  get unverifiedSchools() {
    return this._unverifiedSchools;
  }

  schoolIsUnverified(school) {
    return !!this.unverifiedSchools.get(school.id);
  }

  showVerifyMdr(school) {
    this._mdrOrgDialog(
      this.$mdDialog,
      school,
      this.unverifiedSchools,
      this.user.id,
      true,
    ).then((schoolWasVerified) => {
      if (schoolWasVerified) {
        this.unverifiedSchools.delete(school.id);
      }
    });
  }

  goToOrderPage() {
    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.LEARN_MORE,
      undefined,
      undefined,
      Locations.PROFILE_PLAN,
    );
    this._staticContentService.goToOrdersPage(
      this._logRocketService.initialized,
    );
  }

  showAddPassword() {
    return !!(
      !this.isPasswordAuthed &&
      this.isTeacher &&
      !this.addedNewPassword &&
      this.user.email
    );
  }

  assignmentLimitText() {
    if (
      this.assignmentsUsedCount > this.assignmentsLimit &&
      this.isAssignmentLimit20Segment
    ) {
      return `${this.assignmentsLimit} out of ${this.assignmentsUsedCount} Assignments Available`;
    }
    return `${this.assignmentsUsedCount} of ${this.assignmentsLimit} Assignments Used`;
  }

  /**
   * @return {boolean}
   */
  isACoTeacher() {
    return this._authService.isACoTeacher();
  }
}
