'use strict';

import Notification from './notification';
import JSEvent from '../util/js-event';
import Message from '../../model/domain/message';

export default class UserNewMessageNotification extends Notification {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param userId {string}
   * @param FirebaseService {FirebaseService}
   * @param showInitialNotification {boolean}
   */
  constructor(
    notificationShardKey,
    userId,
    FirebaseService,
    showInitialNotification,
  ) {
    super(
      Notification.V3,
      notificationShardKey,
      `users/${userId}/messages`,
      FirebaseService,
      showInitialNotification,
      'UserNewMessageNotification',
    );

    this._updated = new JSEvent(this);
    this._value = null;

    this._logTrace('cstr complete');
  }

  get updated() {
    return this._updated;
  }

  _changed(value) {
    if (angular.isDefined(value)) {
      this._value = Message.apply(value);
      this._logTrace('_changed senderId=' + this._value.senderId);
      this._updated.raise(this._value);
    }
  }

  get value() {
    return this._value;
  }
}
