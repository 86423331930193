import AddNewStickerDialog from './add-new-sticker-dialog.html';
import ImagePickerDialogController from '../image-picker-dialog/image-picker-dialog.controller';
import UserSticker from '../../model/domain/user-sticker';
import StickerManager from '../../services/toolbar/sticker-manager';
import ErrorDialogController from '../error-dialog/error-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';

export default class AddNewStickerDialogController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $log,
    $mdDialog,
    CacheService,
    ImageEditService,
    MediaService,
    AuthService,
    AnalyticsService,
    stickerUrl,
  ) {
    this.$q = $q;
    this.$log = $log;
    this.$mdDialog = $mdDialog;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;
    /** @type {MediaService} */
    this._mediaService = MediaService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    /** @type {StickerManager} */
    this._stickerManager = new StickerManager(
      this.$q,
      this.$log,
      this._cacheService,
      this._imageEditService,
      this._mediaService,
      this._authService,
    );

    this._showImagePicker = ImagePickerDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    this._stickerUrl = stickerUrl;
    this._stickerScore = null;
    this._stickerText = '';

    this._init();
  }

  get stickerUrl() {
    return this._stickerUrl;
  }

  set stickerUrl(value) {
    this._stickerUrl = value;
  }

  get stickerScore() {
    return this._stickerScore;
  }

  set stickerScore(value) {
    this._stickerScore = value;
  }

  get stickerText() {
    return this._stickerText;
  }

  set stickerText(value) {
    this._stickerText = value;
  }

  get defaultStickerImageUrl() {
    return UserSticker.BLANK_STICKER_URL;
  }

  _init() {
    return this._stickerManager.init();
  }

  stickerScoreMinus() {
    if (this.stickerScore === 0) {
      this.stickerScore = null;
    } else if (this.stickerScore >= 1) {
      this.stickerScore -= 1;
    }
  }

  stickerScorePlus() {
    if (this.stickerScore === null) {
      this.stickerScore = 0;
    } else if (this.stickerScore) {
      this.stickerScore += 1;
    } else {
      this.stickerScore = 1;
    }
  }

  openStickerImageSelector() {
    this._showImagePicker(
      this.$mdDialog,
      this.$q.defer(),
      this.defaultStickerImageUrl,
      true,
    ).then((selectedStickerImageUrl) => {
      this.stickerUrl = selectedStickerImageUrl || '';
    });
  }
  saveSticker() {
    if (!this._stickerManager.selected) {
      this._stickerManager.selected = new UserSticker(
        null,
        '',
        null,
        this.stickerUrl,
        null,
        [],
      );
    }
    this._stickerManager.selectedImageUrl = this.stickerUrl;
    this._stickerManager.selectedText = this.stickerText;
    this._stickerManager.selectedScore = this.stickerScore;

    return this._stickerManager
      .saveSelected()
      .then((sticker) => {
        this._analyticsService.stickerSaved(
          Locations.ASSIGNMENT_WORK,
          sticker.imageType,
          sticker.imageName,
        );
        angular.element('.stickers')[0].click();
        this.close();
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          'Uh oh! An error occurred',
          'Please try saving your sticker again',
        );
      });
  }

  close() {
    this.$mdDialog.hide();
  }

  static show($mdDialog, stickerUrl) {
    return $mdDialog.show({
      controller: AddNewStickerDialogController,
      template: AddNewStickerDialog,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        stickerUrl,
      },
    });
  }
}
