'use strict';

import Codec from './codec';

export default class ColorCodec extends Codec {
  /**
   * Encodes a hex or rgba color to a 6 or 8 digit hex color
   *
   * @param value {string}
   * @returns {string}
   */
  encode(value) {
    if (this.isHex6(value)) {
      return value;
    }

    return this.rgbaToHex8(value);
  }

  /**
   * Decodes a web color from a 6 or 8 digit hex color
   *
   * @param value {string}
   * @returns {string}
   */
  decode(value) {
    if (this.isHex6(value)) {
      return value;
    }

    return this.hex8ToRgba(value);
  }

  isHex6(value) {
    return value.match(/^#[\da-fA-F]{6}$/);
  }

  isHex8(value) {
    return value.match(/^#[\da-fA-F]{8}$/);
  }

  hex8ToRgba(value) {
    let values = value.match(
      /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})$/,
    );
    if (values) {
      return `rgba(${this.hexToDecimal(values[1])},${this.hexToDecimal(values[2])},${this.hexToDecimal(values[3])},${this.hexToDecimal(values[4]) / 256})`;
    }

    return 'rgba(0, 10, 0, 1)';
  }

  rgbaToHex8(value) {
    let values = value.match(/(\d+)[, ]+(\d+)[, ]+(\d+)[, ]+(\d*\.?\d+\.?\d*)/);
    if (values) {
      return `#${this.intStringToHex(values[1])}${this.intStringToHex(values[2])}${this.intStringToHex(values[3])}${this.zeroPad(Math.round(parseFloat(values[4]) * 256).toString(16))}`;
    }

    return '#000a00ff';
  }

  rgbaOverrideAlpha(value, alpha = 0.3) {
    let values = value.match(/(\d+)[, ]+(\d+)[, ]+(\d+)[, ]+(\d*\.?\d+\.?\d*)/);
    if (values) {
      return `rgba(${values[1]}, ${values[2]}, ${values[3]}, ${alpha})`;
    }

    return 'rgba(0, 10, 0, 1)';
  }

  hex6ToRgba(value, alpha = 0.3) {
    let values = value.match(
      /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})$/,
    );
    if (values) {
      return `rgba(${this.hexToDecimal(values[1])}, ${this.hexToDecimal(values[2])}, ${this.hexToDecimal(values[3])}, ${alpha})`;
    }

    return 'rgba(0, 10, 0, 1)';
  }

  hexToDecimal(value) {
    return parseInt(value, 16);
  }

  intStringToHex(value) {
    return this.zeroPad(parseInt(value).toString(16));
  }

  zeroPad(value) {
    if (value.length === 1) {
      return '0' + value;
    }
    return value;
  }
}
