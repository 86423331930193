import Features from './features';

/**
 * Combines features and trial info to inform the pro indicators/paywalls we show to the current user
 */
export default class ProInfo {
  constructor(features, contracts) {
    this._features = features;
    this._contracts = contracts;
    this._isPro = undefined;
    this._isTrial = undefined;
    this._isTrialExpired = undefined;
    this._activeTrialContract = undefined;
  }

  /**
   * @return {Set<string>}
   */
  get features() {
    return this._features;
  }

  /**
   * @return {Contract[]}
   */
  get contracts() {
    return this._contracts;
  }

  get isFree() {
    return !this.isPro && !this.isTrial;
  }

  get isPro() {
    if (angular.isUndefined(this._isPro)) {
      this._isPro = this._contracts.some(
        (contract) => !contract.isExpired && contract.isPro,
      );
    }
    return this._isPro;
  }

  get isTrial() {
    if (angular.isUndefined(this._isTrial)) {
      this._isTrial = this._contracts.some(
        (contract) => !contract.isExpired && contract.isTrial,
      );
    }
    return this._isTrial;
  }

  get activeTrialContract() {
    if (angular.isUndefined(this._activeTrialContract)) {
      this._activeTrialContract = this._contracts.find(
        (contract) => !contract.isExpired && contract.isTrial,
      );
    }
    return this._activeTrialContract;
  }

  get isTrialExpired() {
    if (angular.isUndefined(this._isTrialExpired)) {
      this._isTrialExpired = this._contracts.some(
        (contract) => contract.isExpired && contract.isTrial,
      );
    }
    return this._isTrialExpired;
  }

  get hasFoldersFeature() {
    return this._features.has(Features.FOLDERS);
  }

  get hasGradeExport() {
    return this._features.has(Features.GRADE_EXPORT);
  }

  get hasStudentAccounts() {
    return this._features.has(Features.STUDENT_ACCOUNTS);
  }

  get hasUnlimitedAssignments() {
    return this._features.has(Features.UNLIMITED_ASSIGNMENTS);
  }

  get hasExportStudentWork() {
    return this._features.has(Features.EXPORT_STUDENT_WORK);
  }
}
