'use strict';

import UserListItem from '../../model/domain/user-list-item';
import Debouncer from '../../model/util/debouncer';
import RosterLockDialogController from '../../components/roster-lock-dialog/roster-lock-dialog.controller';
import ColorMenuController from '../../components/color-menu/color-menu.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import Roster, {
  RosterStudentAccountPreferenceOptions,
} from '../../model/domain/roster';
import SaveStates from '../../components/saving-indicator/save-states';
import LoadingDialog from '../../components/loading-dialog/loading-dialog.controller';
import AddAnonStudentDialog from '../../components/add-anon-student-dialog/add-anon-student-dialog.controller';
import ErrorDialog from '../../components/error-dialog/error-dialog.controller';
import AddProStudentsDialogController from '../../components/add-pro-students-dialog/add-pro-students-dialog.controller';
import AssignmentSelectionDialogController from '../../components/assignment-selection-dialog/assignment-selection-dialog.controller';
import ProInfoDialogController from '../../components/pro-info-dialog/pro-info-dialog.controller';
import {
  Locations,
  PaywallSources,
  TeacherType,
} from '../../services/mixpanel/mixpanel.service';
import StudentAccountChoiceDialogController from '../../components/student-account-choice-dialog/student-account-choice-dialog.controller';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { Colors } from '../../model/domain/colors';
import { parseFullName } from 'parse-full-name';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';
import AssignmentRosterItem from '../assignment-rosters/assignment-roster-item';
import Sorts from '../../model/domain/sorts';
import AssignmentManager from '../../model/domain/assignment-manager';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ChooseAssignmentDialogController from '../../components/choose-assignment-dialog/choose-assignment-dialog.controller';
import ShareWithStudentDialogController from '../../components/share-with-student-dialog/share-with-student-dialog.controller';
import ContextualPaywallDialogController, {
  ContextualPaywalls,
} from '../../components/contextual-paywall-dialog/contextual-paywall-dialog.controller';
import moment from 'moment';
import { ABTest } from '../../services/ab-test/ab-test-service';
import { DEFAULT_ASSIGNMENT_LIMIT } from '../../model/domain/app-configuration';
import InviteCoTeacherDialogController from '../../components/invite-co-teacher-dialog/invite-co-teacher-dialog.controller';
import GradeExportDialogController from '../../components/grade-export-dialog/grade-export-dialog.controller';

/**
 * Defines the columns in the roster edit table
 */
export class RosterEditTableColumns {
  static get FIRST_NAME() {
    return 'first_name';
  }

  static get LAST_NAME() {
    return 'last_name';
  }

  static get NAME() {
    return 'name';
  }

  static get ACCOUNT_TYPE() {
    return 'account_type';
  }

  static get EMAIL() {
    return 'email';
  }

  static get STATUS() {
    return 'status';
  }

  static get HELPS() {
    return 'helps';
  }
}

export class RosterCoTeacherColumns {
  static get EMAIL() {
    return 'email';
  }

  static get LAST_ACCESSED() {
    return 'last_accessed';
  }
}

export class AssignmentRosterColumns {
  static get ASSIGNMENT() {
    return 'assignment';
  }

  static get HELPS() {
    return 'helps';
  }
}

export class RosterEditState {
  static get STUDENTS() {
    return 'students';
  }

  static get ASSIGNMENTS() {
    return 'assignments';
  }

  static get CO_TEACHERS() {
    return 'co-teachers';
  }
}

export default class RosterEditController {
  constructor(
    $q,
    $mdToast,
    $scope,
    $state,
    $window,
    $mdSidenav,
    $timeout,
    $stateParams,
    $mdPanel,
    $mdDialog,
    $document,
    $log,
    RosterService,
    NotificationService,
    AssignmentService,
    HelpRequestService,
    CacheService,
    UserService,
    AnalyticsService,
    BreadcrumbService,
    ContractService,
    CsvService,
    AssignmentWorkService,
    AuthService,
    ClassCodeService,
    StorageService,
    CoTeacherService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$mdToast = $mdToast;
    this.$timeout = $timeout;
    this.$mdSidenav = $mdSidenav;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdPanel = $mdPanel;
    this.$mdDialog = $mdDialog;
    this.$document = $document;
    this.$log = $log;

    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {CsvService} */
    this._csvService = CsvService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {CoTeacherService} */
    this._coTeacherService = CoTeacherService;

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      undefined,
      undefined,
      this._analyticsService,
      undefined,
    );

    this._saveMessage = '';
    this._lockRosterExplanation = Roster.LOCK_ROSTER_EXPLANATION;
    this._lockDeviceExplanation = Roster.LOCK_DEVICE_EXPLANATION;

    //configures the initial behavior of the student table
    this._orderBy = RosterEditTableColumns.FIRST_NAME;
    this._isAscending = true;

    //configures the initial behavior of the assignment roster table
    this._isAscendingAssignmentRosters = null;
    this._orderByForAssignmentRosters = null;

    //configures the initial behavior of the co-teacher table
    this._isAscendingRosterCoTeachers = null;
    this._orderByForRosterCoTeachers = null;

    /** @type {boolean} */
    this.isFreeUser = this._authService.authData.isFree;
    this._roster = null;
    this._assignments = null;
    this._rosterNotification = null;
    this._helpRequestSet = null;
    /** @type {UserListItem} */
    this._list = {};
    this._loading = true;
    this._errorMessage = null;
    this._helpRequestsByAssignmentId = new Map();
    this._helpRequestSetByAssignmentId = new Map();
    this._assignmentRosterItems = [];
    this._activeBasicAssignments = null;
    this._coTeachers = [];
    this._state = RosterEditState.ASSIGNMENTS;

    this._showLoadingDialog = LoadingDialog.show;
    this._showAddStudentDialog = AddAnonStudentDialog.show;
    this._showAddProStudentsDialog = AddProStudentsDialogController.show;
    this._showAssignmentSelectionDialog =
      AssignmentSelectionDialogController.show;
    this._showErrorDialog = ErrorDialog.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._lockRosterDialog = RosterLockDialogController.show;
    this._proInfoDialog = ProInfoDialogController.show;
    this._showStudentAccountChoice = StudentAccountChoiceDialogController.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._chooseAssignmentDialog = ChooseAssignmentDialogController.show;
    this._shareWithStudentDialog = ShareWithStudentDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;
    this._inviteCoTeacherDialog = InviteCoTeacherDialogController.show;
    this._gradeExportDialog = GradeExportDialogController.show;

    this.focusName = false;
    this.colorList = Colors.ROSTER_COLORS;
    this.selectedColor = null;

    this._saveRoster = new Debouncer(
      1000,
      5000,
      () => {
        if (this._roster.name.length > 0) {
          this._rosterService
            .update(this._roster)
            .then(() => {
              this._cacheService.updateRosterForUser(this._roster);
              this._saveMessage = this.SAVED;
              this.saveMessageTimeout();
            })
            .catch(() => {
              this._saveMessage = this.SAVE_ERROR;
            });
        } else {
          this._saveMessage = this.SAVE_ERROR;
        }
      },
      $scope,
      $window,
    );

    this._saveName = new Debouncer(
      2500,
      5000,
      /** @param user {User}*/ (user) => {
        if (user.displayName && user.displayName.length > 0) {
          const isDuplicate = this._isDuplicateDisplayName(user, this._list);
          if (isDuplicate) {
            this._saveMessage = this.SAVE_ERROR_STUDENT_DUPLICATE;
            return;
          }
          this._userService
            .updateAnonStudent(this._roster.id, user.id, user.displayName)
            .then(() => {
              this._cacheService.updateUserForRosterUsers(
                this._roster.id,
                user,
              );
              this._saveMessage = this.SAVED;
              this.saveMessageTimeout();
            })
            .catch(() => {
              this._saveMessage = this.SAVE_ERROR;
            });
        } else {
          this._saveMessage = this.SAVE_ERROR;
        }
      },
      $scope,
      $window,
    );

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._destroy());

    this.init();
  }

  init() {
    this.$q
      .all({
        roster: this._rosterService.get(this.$stateParams.rosterId),
      })
      .then(({ roster }) => {
        this._roster = roster;
        if (roster.ownerId !== this._authService.authData.id) {
          return this._coTeacherService
            .getAccessForRoster(this.roster.id)
            .then(() => {
              return this._initializeAllData();
            });
        } else {
          return this._initializeAllData();
        }
      })
      .catch((err) => {
        this.$log.error(err);
        this._errorMessage = 'Sorry, there was a problem loading this roster';
      });
  }

  _initializeAllData() {
    const getFresh =
      this.roster.coteacherAccess &&
      this.isACoTeacher() &&
      this._authService.coTeacherAuthData.id !== this.roster.ownerId
        ? true
        : false;
    this.$q
      .all({
        user: this._cacheService.getUser(getFresh),
        assignments: this._cacheService.getAssignmentsForUser(getFresh),
        users: this._cacheService.getRosterUsers(
          this.$stateParams.rosterId,
          true,
        ),
        helpRequestSet: this._helpRequestService.getHelpRequestSetForRoster(
          this._authService.currentUserId,
          this.$stateParams.rosterId,
        ),
        proInfo: this._cacheService.getProInfo(),
        activeBasicAssignments:
          this._cacheService.getActiveAssignmentsForBasicUsers(getFresh),
        assignmentsForRoster: this._cacheService.getAssignmentsByRoster(
          this.$stateParams.rosterId,
          true,
        ),
        assignmentLimit20Segment: this._cacheService.getTestSegment(
          ABTest.AssignmentLimit20,
        ),
        coTeachers: this._cacheService.getCoTeachersByRoster(
          this.$stateParams.rosterId,
          true,
        ),
      })
      .then((result) => {
        this._user = result.user;
        this._assignments = result.assignments;
        this._users = result.users;
        this._helpRequestSet = result.helpRequestSet;
        this._assignmentsForRoster = result.assignmentsForRoster;
        this._activeBasicAssignments = result.activeBasicAssignments;
        this.assignmentLimitCountdown =
          this._user.getAssignmentLimitCountdown(moment());
        this._isAssignmentLimit20Segment = result.assignmentLimit20Segment;
        this._coTeachers = result.coTeachers;
        this._proInfo = result.proInfo;
        this.selectedColor = this.colorList.find(
          (color) =>
            color.hex.toUpperCase() === this._roster.color.toUpperCase(),
        );
        this._users.forEach((user) => this._createUserItem(user));
        this._rosterNotification =
          this._notificationService.getRosterNotification(this._roster);
        this._rosterNotification.userAdded.subscribe((change) =>
          this._handleUserAdded(change),
        );
        this._rosterNotification.userRemoved.subscribe((change) =>
          this._handleUserRemoved(change),
        );
        this._rosterNotification.userUpdated.subscribe((change) =>
          this._handleUserUpdated(change),
        );
        this._rosterNotification.start();

        if (
          this._storageService.edlinkCode ||
          this._storageService.edlinkError
        ) {
          this.openGradebookExport(this._storageService.ckAssignmentId);
        }

        // call user configuration after pro info
        this._cacheService
          .getAppConfig()
          .then((config) => (this._appConfig = config));

        //listening for all types roster help requests changes so assignment help requests can get updated
        this._helpRequestSet.notifier.created.subscribe((ev) =>
          this._handleRosterHelpRequestChange(ev),
        );
        this._helpRequestSet.notifier.resolved.subscribe((ev) =>
          this._handleRosterHelpRequestChange(ev),
        );
        this._helpRequestSet.notifier.canceled.subscribe((ev) =>
          this._handleRosterHelpRequestChange(ev),
        );

        this._helpRequestSet.requests.map((request) => {
          if (this._helpRequestsByAssignmentId.has(request.assignmentId)) {
            this._helpRequestsByAssignmentId.set(request.assignmentId, [
              ...this._helpRequestsByAssignmentId.get(request.assignmentId),
              request,
            ]);
          } else {
            this._helpRequestsByAssignmentId.set(request.assignmentId, [
              request,
            ]);
          }
        });

        const helpRequestPromises = [];
        this._helpRequestsByAssignmentId.forEach((requestGroup) => {
          helpRequestPromises.push(
            this._generateAllHelpRequestSetsByAssignmentId(requestGroup),
          );
        });

        //creating all assignment roster items associated with roster
        Promise.all(helpRequestPromises).then(() => {
          this._assignmentsForRoster.forEach((assignment) => {
            const isDisabledAssignment =
              this._authService.authData.isFree &&
              this._isAssignmentLimit20Segment &&
              this.assignmentLimitCountdown < 0 &&
              this._activeBasicAssignments &&
              !this._activeBasicAssignments.get(assignment.id);

            const rosterItem = new AssignmentRosterItem(
              this._roster,
              assignment.classCode,
              this._handleRosterUpdated.bind(this),
              this._handleClassCodeUpdated.bind(this),
              this._analyticsService,
              isDisabledAssignment,
              assignment.id,
            );
            this._assignmentRosterItems.push(rosterItem);
          });

          this.setOrToggleAssignmentName();
        });

        this._loading = false;

        this.focusName = this.$stateParams.isNew ? 'select' : false;
      })
      .catch((err) => {
        this.$log.error(err);
        this._errorMessage = 'Sorry, there was a problem loading this roster';
      });
  }

  _destroy() {
    this._loading = true;
    if (this._rosterNotification) {
      this._rosterNotification.stop();
    }
    if (this._list) {
      angular.forEach(this._list, (userListItem) => {
        userListItem.stop();
      });
    }
  }

  /**
   * @param user {User}
   * @private
   */
  _createUserItem(user) {
    if (!this._list[user.id]) {
      let userNotification =
        this._notificationService.getUserStatusNotification(user.id);
      this._list[user.id] = new UserListItem(
        user,
        userNotification,
        this._helpRequestSet,
        this._assignments,
        this._assignmentService,
      ).start();
    }
    return this._list[user.id];
  }

  /**
   * @param user {User}
   * @param list {UserListItem}
   * @private
   */
  _isDuplicateDisplayName(user, list) {
    const currentStudents = Object.entries(list);
    const isDuplicate = currentStudents.find(
      (studentInfo) =>
        studentInfo[1].user.displayName &&
        studentInfo[1].user.displayName.toLowerCase() ===
          user.displayName.toLowerCase() &&
        studentInfo[0] !== user.id,
    );
    return isDuplicate ? true : false;
  }

  /**
   * @param user {User}
   */
  editUser(user) {
    this._loading = true;
    if (!user.userIsAnonymous) {
      this._editProUser(user.id);
    } else {
      this._breadcrumbService.go('root.account.basic-user', {
        userId: user.id,
        rosterId: this.$stateParams.rosterId,
      });
    }
  }

  /**
   * @param userId {String}
   * @private
   */
  _editProUser(userId) {
    this._cacheService
      .getUsersForContracts()
      .then((contractIdToUsers) => {
        const contractId = this._userIsInContracts(contractIdToUsers, userId);
        if (contractId) {
          return contractId;
        } else {
          return this._cacheService
            .getUsersForContracts(true)
            .then((secondTryWithFreshData) => {
              return this._userIsInContracts(secondTryWithFreshData, userId);
            });
        }
      })
      .then((contractId) => {
        if (!contractId) {
          this._loading = false;
          this._confirmDialog(
            this.$mdDialog,
            "Sorry, that user isn't a member of your organization.",
            undefined,
            true,
          );
          return;
        }

        this._breadcrumbService.go('root.account.contract-user', {
          contractId: contractId,
          userId: userId,
        });
      })
      .catch((err) => {
        this._loading = false;
        this._confirmDialog(
          this.$mdDialog,
          'Sorry, there was a problem loading this user.',
          undefined,
          true,
        );
        this.$log.error(err);
      });
  }

  /**
   * @param user {User}
   */
  goToStudentOverview(user) {
    if (
      this.roster.coteacherAccess &&
      this.isACoTeacher() &&
      this._authService.coTeacherAuthData.id !== this.roster.ownerId
    ) {
      return;
    }

    this._breadcrumbService.go('root.account.student-overview', {
      userId: user.id,
      rosterId: this.$stateParams.rosterId,
    });
  }

  /**
   *
   * @param contractsUsers {Map.<String, Map.<String, User>>}
   * @param userId {string}
   * @return {string}
   * @private
   */
  _userIsInContracts(contractsUsers, userId) {
    return Array.from(contractsUsers.keys()).find((contractId) =>
      contractsUsers.get(contractId).has(userId),
    );
  }

  /**
   * @param change {RosterChange}
   * @private
   */
  _handleUserAdded(change) {
    this._cacheService
      .getRosterUser(this.$stateParams.rosterId, change.userId, true)
      .then((user) => {
        return this._createUserItem(user);
      })
      .then((userListItem) => {
        this.$timeout(
          () => {
            let userListItemElement = angular.element(
              `#${userListItem.user.id}`,
            );

            if (userListItemElement) {
              scrollIntoViewIfNeeded(userListItemElement[0], false);
              userListItemElement.addClass('highlight');

              this.$timeout(() => {
                userListItemElement.removeClass('highlight');
              }, 4000);
            }
          },
          0,
          false,
        );
      });
  }

  /**
   * @param change {RosterChange}
   * @private
   */
  _handleUserUpdated(change) {
    this._cacheService
      .getRosterUser(this.$stateParams.rosterId, change.userId, true)
      .then((user) => {
        return this._createUserItem(user);
      });
  }

  /**
   * @param change {RosterChange}
   * @private
   */
  _handleUserRemoved(change) {
    delete this._list[change.userId];
  }

  /** To check if they are currently authed in as another teacher or
   * if their original auth data owns the roster
   * (important for hiding auth token changes that appear midway during page loads)
   * @return {boolean}
   */
  isACoTeacher() {
    return (
      this._authService.isACoTeacher() ||
      (this._authService.coTeacherAuthData &&
        this._authService.coTeacherAuthData.id !==
          (this.roster && this.roster.ownerId))
    );
  }

  /**
   * @returns {string} explanation of locking devices
   */
  get lockDeviceExplanation() {
    return this._lockDeviceExplanation;
  }

  /**
   *
   * @returns {string} explanation of locking roster
   */
  get lockRosterExplanation() {
    return this._lockRosterExplanation;
  }

  /**
   * @returns {Roster}
   */
  get roster() {
    return this._roster;
  }

  /**
   * @returns {object.<string, UserListItem>}
   */
  get list() {
    return this._list;
  }

  /**
   * @returns {boolean}
   */
  get hasRequests() {
    return this._helpRequestSet && this._helpRequestSet.total > 0;
  }

  /**
   * Indicates if initial load has completed
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @return {null|string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  /**
   * indicates if currently selected column should be ascending or descending
   * @returns {boolean}
   */
  get isAscending() {
    return this._isAscending;
  }

  /**
   * indicates when to show carrot next to the name column in table header
   * @returns {boolean}
   */
  get showNameCarrot() {
    return (
      this._orderBy === RosterEditTableColumns.FIRST_NAME ||
      this._orderBy === RosterEditTableColumns.LAST_NAME
    );
  }

  /**
   * indicates when to show carrot next to the account type column in table header
   * @returns {boolean}
   */
  get showAccountTypeCarrot() {
    return this._orderBy === RosterEditTableColumns.ACCOUNT_TYPE;
  }

  /**
   * indicates when to show carrot next to the email column in table header
   * @returns {boolean}
   */
  get showEmailCarrot() {
    return this._orderBy === RosterEditTableColumns.EMAIL;
  }

  /**
   * indicates when to show carrot next to the status column in table header
   * @returns {boolean}
   */
  get showStatusCarrot() {
    return this._orderBy === RosterEditTableColumns.STATUS;
  }

  /**
   * indicates when to show carrot next to the help request column in table header
   * @returns {boolean}
   */
  get showHelpsCarrot() {
    return this._orderBy === RosterEditTableColumns.HELPS;
  }

  /**
   * Title of name column
   * @returns {string}
   */
  get nameSortOption() {
    let firstOrLast = 'First Name';
    if (this._orderBy === RosterEditTableColumns.LAST_NAME) {
      firstOrLast = 'Last Name';
    }
    return firstOrLast;
  }

  /**
   * message to show when in the process of saving or when item is saved
   * @returns {string}
   */
  get saveMessage() {
    return this._saveMessage;
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (
      (this._storageService.lastSeenTrialConversionBanner &&
        this._storageService.lastSeenTrialConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenRenewalConversionBanner &&
        this._storageService.lastSeenRenewalConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenAssignmentNotificationBanner &&
        this._storageService.lastSeenAssignmentNotificationBanner.showBanner ===
          true)
    );
  }

  get state() {
    return this._state;
  }

  get isStudentsView() {
    return this.state === RosterEditState.STUDENTS;
  }

  togglePeerHelp() {
    this.roster.allowPeerHelp = !this.roster.allowPeerHelp;
    this._saveRoster.tick();
  }

  deleteRoster() {
    let deleteMessage = `WAIT! Are you sure you would like to delete ${this.roster.name}? Once deleted, ALL WORK for the students on this roster will also be deleted, and this CAN NOT be recovered.`;

    this._confirmDialog(this.$mdDialog, deleteMessage).then(() => {
      this._cacheService.deleteRoster(this.roster.id).then(() => {
        this.goBack();
      });
    });
  }

  /**
   * handles the logic to change the focus and order of columns
   *
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggle(colName, shouldAscend) {
    if (this._orderBy !== colName) {
      this._orderBy = colName;
      this._isAscending = shouldAscend;
    } else {
      this._isAscending = !this._isAscending;
    }
  }

  /**
   * sets the list order to the name column and/or toggles ascending/descending
   * order is First Name Asc (default) > First Name Desc > Last Name Asc > Last Name Desc
   */
  setOrToggleName() {
    if (
      RosterEditTableColumns.FIRST_NAME === this._orderBy &&
      this._isAscending
    ) {
      this.setOrToggle(RosterEditTableColumns.FIRST_NAME, false);
    } else if (
      RosterEditTableColumns.FIRST_NAME === this._orderBy &&
      !this._isAscending
    ) {
      this.setOrToggle(RosterEditTableColumns.LAST_NAME, true);
    } else if (
      RosterEditTableColumns.LAST_NAME === this._orderBy &&
      this._isAscending
    ) {
      this.setOrToggle(RosterEditTableColumns.LAST_NAME, false);
    } else {
      this.setOrToggle(RosterEditTableColumns.FIRST_NAME, true);
    }
  }

  /**
   * sets the list order to the account type column and/or toggles ascending/descending
   */
  setOrToggleAccountType() {
    this.setOrToggle(RosterEditTableColumns.ACCOUNT_TYPE, true);
  }

  /**
   * sets the list order to the email column and/or toggles ascending/descending
   */
  setOrToggleEmail() {
    this.setOrToggle(RosterEditTableColumns.EMAIL, true);
  }

  /**
   * sets the list order to the status column and/or toggles ascending/descending
   */
  setOrToggleStatus() {
    this.setOrToggle(RosterEditTableColumns.STATUS, true);
  }

  /**
   * sets the list order to the helps column and/or toggles ascending/descending
   */
  setOrToggleHelps() {
    this.setOrToggle(RosterEditTableColumns.HELPS, false);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  orderBy() {
    let orderBy = this._orderBy;
    let isAscending = this._isAscending;
    return function (item) {
      if (RosterEditTableColumns.FIRST_NAME === orderBy) {
        return item.user.name.toLowerCase();
      } else if (RosterEditTableColumns.LAST_NAME === orderBy) {
        return item.user.lastName
          ? item.user.lastName.toLowerCase()
          : parseFullName(item.user.name).last &&
              parseFullName(item.user.name).last.toLowerCase();
      } else if (RosterEditTableColumns.ACCOUNT_TYPE === orderBy) {
        return item.user.userIsAnonymous;
      } else if (RosterEditTableColumns.EMAIL === orderBy) {
        if (item.user.userIsAnonymous) {
          return isAscending ? '!' : '~';
        } else {
          return item.user.email
            ? item.user.email.toLowerCase()
            : item.user.username.toLowerCase();
        }
      } else if (RosterEditTableColumns.HELPS === orderBy) {
        return item.helpRequests.total;
      } else if (RosterEditTableColumns.STATUS === orderBy) {
        if (item.status && !item.status.online) {
          return isAscending ? '!' : '~';
        }
        return item.displayStatus.toLowerCase();
      }
    };
  }

  /**
   * Saves the roster's current state
   * @param field
   */
  save(field) {
    if (field) {
      field.$touched = true;
    }

    this._saveMessage = this.UNSAVED;
    this._saveRoster.tick();
  }

  /**
   * Removes user from roster
   *
   * @param user {User} the user to remove
   */
  removeMember(user) {
    let removeMessage = `Are you sure you want to remove ${user.name} from this roster?`;
    let secondaryRemoveMessage = `
        Note: This will not delete ${user.name}'s account.
        To delete their account, have them log in, go to their profile, and click "delete account"
    `;

    this._confirmDialog(
      this.$mdDialog,
      removeMessage,
      user.userIsAnonymous ? '' : secondaryRemoveMessage,
    ).then(() => {
      this._rosterService
        .removeMember(this.$stateParams.rosterId, user.id)
        .then(() => {
          delete this._list[user.id];
          this._cacheService.removeUserForRosterUsers(this.roster.id, user);
        });
    });
  }

  /**
   * Saves changes made to anon student names
   *
   * @param user {user}
   * @param field {HTMLInputElement}
   */
  saveName(user, field) {
    field.$touched = true;
    this._saveMessage = this.UNSAVED;
    this._saveName.tick(user);
  }

  showColorMenu() {
    ColorMenuController.show(
      this.$q,
      this.$mdPanel,
      this.$mdMedia,
      '#ck-color-menu',
    ).then((color) => {
      this._roster.color = color.hex;
      this.save();
    });
  }

  displaySelectedColor() {
    let color;

    if (this.selectedColor) {
      color = this.selectedColor.hex;
    } else if (this.roster && this.roster.color) {
      color = this.roster.color;
    } else {
      color = this.colorList[0].hex;
    }
    return {
      'background-color': color,
    };
  }

  getStyle(color) {
    return {
      'background-color': color.hex,
      border: color.name === 'white' ? '1px solid #eeeeef' : '',
    };
  }

  selectColor() {
    this._roster.color = this.selectedColor.hex;
    this.save();
  }

  showLockMenu() {
    this._lockRosterDialog(
      this.$q,
      this.$mdDialog,
      this.$document,
      this._roster,
      this._lockRosterExplanation,
      this._lockDeviceExplanation,
    ).then(() => this.save());
  }

  saveMessageTimeout() {
    this.$timeout(() => {
      this._saveMessage = '';
    }, 3000);
  }

  navigate(state) {
    if (this._saveMessage === this.SAVE_ERROR) {
      this._confirmDialog(
        this.$mdDialog,
        '<p>Some of your changes have not been saved.</p><div>Are you sure you want to leave?</div>',
      ).then(() => {
        this.$state.go(state);
      });
    } else {
      this.$state.go(state);
    }
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.nav.rosters');
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  get SAVED() {
    return SaveStates.SAVED;
  }

  get UNSAVED() {
    return SaveStates.UNSAVED;
  }

  get SAVE_ERROR() {
    return SaveStates.SAVE_ERROR;
  }

  get SAVE_ERROR_STUDENT_DUPLICATE() {
    return SaveStates.SAVE_ERROR_STUDENT_DUPLICATE;
  }

  /**
   * The number of students needed to not show the help message under the students list
   * @returns {number}
   */
  get STUDENTS_HELPER_LIMIT() {
    return 7;
  }

  addNewStudent() {
    if (angular.isUndefined(this._roster.studentAccountPreference)) {
      this._showStudentAccountChoice(
        this.$mdDialog,
        this.pro,
        this.roster,
        new Set(this.usersOnRoster),
      );
    } else if (
      this._roster.studentAccountPreference ===
      RosterStudentAccountPreferenceOptions.PORTFOLIO
    ) {
      this._showAddProStudentsDialog(
        this.$mdDialog,
        this.$q,
        this.pro,
        this.roster,
        this.usersOnRoster,
      );
    } else if (
      this._roster.studentAccountPreference ===
      RosterStudentAccountPreferenceOptions.BASIC
    ) {
      this._showAddStudentDialog(
        this.$mdDialog,
        this.roster,
        this.usersOnRoster,
        this.pro,
      );
    }
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._proInfo;
  }

  /**
   * @return {boolean}
   */
  get hasGradeExport() {
    return this.pro && this.pro.hasGradeExport;
  }

  /**
   * Returns an array of objects with keys for the assignment and all related works
   * @param assignments {Assignment[]}
   * @param rosterId {string}
   * @return {Promise.<{assignment: Assignment, works: AssignmentWork[]}[]>}
   */
  _worksForSelectedAssignments(assignments, rosterId) {
    let result = assignments.map((assignment) => {
      return this._assignmentWorkService
        .getAllForAssignmentRoster(assignment, rosterId)
        .then((works) => ({ assignment, works }));
    });
    return this.$q.all(result);
  }

  /**
   * Returns all users on roster in an array sorted by each user's name
   * @return {User[]}
   */
  get usersOnRoster() {
    return Object.keys(this._list)
      .map((key) => this._list[key])
      .map((item) => item.user)
      .sort((userA, userB) => userA.name.localeCompare(userB.name));
  }

  goToStudentsView() {
    return (this._state = RosterEditState.STUDENTS);
  }

  //--------------------------------- Assignment Roster -------------------------------------------------
  /**
   * indicates if currently selected column should be ascending or descending for assignment rosters table
   * @returns {boolean}
   */
  get isAscendingAssignmentRosters() {
    return this._isAscendingAssignmentRosters;
  }

  /**
   * indicates when to show carrot next to the assignment name in the in table header for assignment roster section
   * @returns {boolean}
   */
  get showCarrotForAssignmentName() {
    return (
      this._orderByForAssignmentRosters === AssignmentRosterColumns.ASSIGNMENT
    );
  }

  /**
   * indicates when to show carrot next to the help request column in the in table header for assignment roster section
   * @returns {boolean}
   */
  get showCarrotForHelpRequestsByAssignment() {
    return this._orderByForAssignmentRosters === AssignmentRosterColumns.HELPS;
  }

  /**
   * @return {AssignmentRosterItem[]}
   */
  get assignmentRosterItems() {
    return this._assignmentRosterItems;
  }

  /**
   * @return {Map.<string, HelpRequest>}
   */
  get helpRequestSetByAssignmentId() {
    return this._helpRequestSetByAssignmentId;
  }

  /**
   * @return {Map.<string, Assignment>}
   */
  get assignmentsForRoster() {
    return this._assignmentsForRoster;
  }

  /**
   * @return {number}
   */
  get assignmentsUsedCount() {
    return [...this._assignments.values()].filter(
      (assignment) => !assignment.isFolder,
    ).length;
  }

  /**
   * @return {number}
   */
  get assignmentsLimit() {
    return (
      (this._appConfig && this._appConfig.assignmentLimit) ||
      DEFAULT_ASSIGNMENT_LIMIT
    );
  }

  get isAssignmentsView() {
    return this.state === RosterEditState.ASSIGNMENTS;
  }

  /**
   * sets the list order to the assignment name column and/or toggles ascending/descending
   */
  setOrToggleAssignmentName() {
    this.setOrToggleAssignmentRoster(AssignmentRosterColumns.ASSIGNMENT, true);
  }

  /**
   * sets the list order to the assignment help requests column and/or toggles ascending/descending
   */
  setOrToggleAssignmentHelpRequests() {
    this.setOrToggleAssignmentRoster(AssignmentRosterColumns.HELPS, true);
  }

  /**
   * handles the logic to change the focus and order of columns for assignment roster table
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggleAssignmentRoster(colName, shouldAscend) {
    if (this._orderByForAssignmentRosters !== colName) {
      this._orderByForAssignmentRosters = colName;
      this._isAscendingAssignmentRosters = shouldAscend;
    } else {
      this._isAscendingAssignmentRosters = !this._isAscendingAssignmentRosters;
    }
    this._applySort();
  }

  /**
   * Applies sorting order for assignment roster section
   */
  _applySort() {
    let orderByFunction = this.assignmentRosterOrderBy(
      this.assignmentsForRoster,
      this.helpRequestSetByAssignmentId,
    );
    let sort = (a, b) => {
      let result;
      let propertyA = orderByFunction(a);
      let propertyB = orderByFunction(b);
      if (propertyA > propertyB) {
        result = 1;
      } else if (propertyA === propertyB) {
        result = 0;
      } else {
        result = -1;
      }
      return this._isAscendingAssignmentRosters ? result : -result;
    };

    this._assignmentRosterItems.sort(sort);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  assignmentRosterOrderBy(assignmentsForRoster, helpRequestSetMap) {
    let orderBy = this._orderByForAssignmentRosters;
    return function (rosterItem) {
      const name = assignmentsForRoster.get(rosterItem.assignmentId).name;
      if (AssignmentRosterColumns.ASSIGNMENT === orderBy) {
        return name ? name.toLowerCase() : '';
      } else if (AssignmentRosterColumns.HELPS === orderBy) {
        const count =
          helpRequestSetMap.get(rosterItem.assignmentId) &&
          helpRequestSetMap.get(rosterItem.assignmentId).total;
        return count ? count : 0;
      }
    };
  }

  showAllowPeerHelpersHelp() {
    return this._showHelp(ViewHelps.AllowPeerHelpers);
  }

  showShowStudentScoresHelp() {
    return this._showHelp(ViewHelps.ShowStudentScores);
  }

  showLockAssignmentHelp() {
    return this._showHelp(ViewHelps.LockAssignment);
  }

  showHideAssignmentHelp() {
    return this._showHelp(ViewHelps.HideAssignment);
  }

  _showHelp(value) {
    this._helpDialog(this.$mdDialog, value);
  }

  /**
   * updates roster with any changes
   * @param roster {Roster}
   */
  _handleRosterUpdated(roster) {
    this._rosterService.update(roster).catch((error) => {
      this.$log.error(error);
    });
  }

  /**
   * update roster settings associated with class code
   * @param item {AssignmentRosterItem}
   * @param assignmentId {string}
   */
  _handleClassCodeUpdated(item, assignmentId) {
    this._assignmentService
      .addOrUpdateRoster(
        assignmentId,
        item.id,
        item.classCode.showStudentScores,
        item.classCode.lockStudentWork,
        item.classCode.hideStudentWork,
        item.classCode.allowPdf,
      )
      .catch((error) => {
        this.$log.error(error);
      });
  }

  /**
   * Generates help requests sets by assignment and activates listener for it
   * @param helpRequests {HelpRequest[]}
   */
  _generateAllHelpRequestSetsByAssignmentId(helpRequests) {
    const helpRequestSet =
      this._helpRequestService.generateHelpRequestSetByAssignmentIdAndRosterId(
        helpRequests,
        this._authService.currentUserId,
        this.roster.id,
      );

    helpRequestSet.start();
    return this._helpRequestSetByAssignmentId.set(
      helpRequests[0].assignmentId,
      helpRequestSet,
    );
  }

  /**
   * Removes an assignment from the roster
   * @param assignmentRoster {AssignmentRosterItem}
   */
  removeAssignment(assignmentRoster) {
    const assignmentId = assignmentRoster.assignmentId;

    this._analyticsService.removeAssignmentFromRoster(
      Locations.ROSTER_DETAILS_LIST,
      assignmentId,
      this.roster.id,
    );

    this._cacheService
      .removeAssignmentFromRoster(assignmentId, assignmentRoster.id)
      .then(() => {
        const newList = this._assignmentRosterItems.filter(
          (rosterItem) => rosterItem.assignmentId !== assignmentId,
        );
        this._assignmentRosterItems = newList;
        this._helpRequestsByAssignmentId.delete(assignmentId);
        this._helpRequestSetByAssignmentId.get(assignmentId) &&
          this._helpRequestSetByAssignmentId.get(assignmentId).stop();
        this._helpRequestSetByAssignmentId.delete(assignmentId);
        return this._cacheService
          .getAssignmentsByRoster(this.roster.id)
          .then((assignmentsForRoster) => {
            this._assignmentsForRoster = assignmentsForRoster;
          });
      });
  }

  /**
   * Opens assignment list dialog to select assignment to add to roster
   */
  addAssignment() {
    let sortedAssignments = [];

    if (
      this._isAssignmentLimit20Segment &&
      this._activeBasicAssignments &&
      this.assignmentLimitCountdown < 0
    ) {
      const unassignedAssignments = Array.from(
        this._activeBasicAssignments.values(),
      ).filter(
        (assignment) =>
          !this.assignmentsForRoster.has(assignment.id) &&
          assignment.ownerId === this._authService.authData.id,
      );
      sortedAssignments = unassignedAssignments.sort((a, b) =>
        Sorts.NAME_ASC(a.name.toLowerCase(), b.name.toLowerCase()),
      );
    } else {
      const unassignedAssignments = Array.from(
        this._assignments.values(),
      ).filter(
        (assignment) =>
          !this.assignmentsForRoster.has(assignment.id) &&
          assignment.ownerId === this._authService.authData.id,
      );
      sortedAssignments = unassignedAssignments.sort((a, b) =>
        Sorts.NAME_ASC(a.name.toLowerCase(), b.name.toLowerCase()),
      );
    }
    this._chooseAssignmentDialog(
      this.$mdDialog,
      this.$q,
      sortedAssignments,
    ).then((assignment) => {
      this._analyticsService.addAssignmentToRoster(
        Locations.ROSTER_DETAILS_LIST,
        assignment && assignment.id,
        this.roster.id,
      );
      this._addAssignmentToRoster(this.roster.id, assignment);
    });
  }

  /**
   * Adds an assignment from the roster and update cache service with updates
   * @param rosterId {string}
   * @param assignment {Assignment}
   */
  _addAssignmentToRoster(rosterId, assignment) {
    this._assignmentService
      .addOrUpdateRoster(assignment.id, rosterId, false)
      .then((classCode) => {
        this._assignmentsForRoster.set(assignment.id, assignment);
        const rosterItem = new AssignmentRosterItem(
          this._roster,
          classCode,
          this._handleRosterUpdated.bind(this),
          this._handleClassCodeUpdated.bind(this),
          this._analyticsService,
          this._activeBasicAssignments &&
            !this._activeBasicAssignments.get(assignment.id),
          assignment.id,
        );

        this._assignmentRosterItems.push(rosterItem);
        this._orderByForAssignmentRosters = null;
        this._isAscendingAssignmentRosters = null;
        this.setOrToggleAssignmentName();

        return this.$q
          .all({
            assignmentsForRoster: this._cacheService.getAssignmentsByRoster(
              this.roster.id,
              true,
            ),
            assignment: this._cacheService.getAssignmentForUser(
              assignment.id,
              true,
            ),
            classCodes: this._cacheService.getClassCodesForUserAssignment(
              assignment.id,
              true,
            ),
          })
          .then(({ assignmentsForRoster }) => {
            this._assignmentsForRoster = assignmentsForRoster;
            let config = this.$mdToast
              .simple()
              .textContent(`${assignment.name} added to ${this.roster.name}`)
              .position('bottom right');
            this.$mdToast.show(config);
          });
      });
  }

  /**
   * Attaches a new listener for help requests if we are not already listening to that assignment
   * @param helpRequest {HelpRequest}
   */
  _handleRosterHelpRequestChange(helpRequest) {
    if (!this._helpRequestsByAssignmentId.has(helpRequest.assignmentId)) {
      this._helpRequestsByAssignmentId.set(helpRequest.assignmentId, [
        helpRequest,
      ]);
      this._generateAllHelpRequestSetsByAssignmentId([helpRequest]);
    }
  }

  /**
   * Goes to view work page for the roster and assignment
   * @param assignmentId {string}
   */
  goToWork(assignmentId) {
    if (this.roster.coteacherAccess) {
      const teacherType =
        this.isACoTeacher() &&
        this._authService.coTeacherAuthData.id !== this.roster.ownerId
          ? TeacherType.CO_TEACHER
          : TeacherType.PRIMARY;
      const userId = this._authService.coTeacherAuthData
        ? this._authService.coTeacherAuthData.id
        : this._authService.authData && this._authService.authData.id;
      this._analyticsService.openViewWorkForCoTeachingRoster(
        this.roster.id,
        userId,
        teacherType,
      );
    }
    this._analyticsService.goToAssignmentViewWork(
      Locations.ROSTER_DETAILS_LIST,
      assignmentId,
      this.roster.id,
    );
    this._breadcrumbService.go('root.account.session.work', {
      assignmentId,
      rosterId: this.roster.id,
    });
  }

  /**
   * Share the class code with student
   * @param assignmentRosterItem {AssignmentRosterItem}
   */
  shareWithStudentDialog(assignmentRosterItem) {
    const classCode = assignmentRosterItem.classCode;
    this._shareWithStudentDialog(
      this.$mdDialog,
      assignmentRosterItem.classCode.classCode,
      this.roster,
      classCode,
      this.pro,
    );
    this._analyticsService.classCodeDialogOpened(
      assignmentRosterItem.id,
      assignmentRosterItem.assignmentId,
      Locations.ROSTER_DETAILS_LIST,
    );
  }

  /**
   * Go to assignment or open paywall if it is a disabled assignment
   * @param assignmentRosterItem {AssignmentRosterItem}
   */
  goToAssignmentOrOpenPaywall(assignmentRosterItem) {
    if (assignmentRosterItem.disabled) {
      this._analyticsService.clickOnDisabledAssignment(
        this.assignmentsUsedCount,
        assignmentRosterItem.assignmentId,
      );
      return this.openAssignmentLimitPaywall(
        this.assignmentsUsedCount,
        this.assignmentsLimit,
        true,
        assignmentRosterItem.assignmentId,
      );
    }
    return this.goToWork(assignmentRosterItem.assignmentId);
  }

  /**
   * Open assignment limit paywall
   * @param assignmentsUsedCount {number}
   * @param assignmentsLimit {number}
   * @param clickedFrom {string}
   * @param isDisabledAssignment {boolean}
   * @param assignmentId {string}
   */
  openAssignmentLimitPaywall(
    assignmentsUsedCount,
    assignmentsLimit,
    isDisabledAssignment = false,
    assignmentId = null,
  ) {
    return this._assignmentManager.openAssignmentLimitPaywall(
      assignmentsUsedCount,
      assignmentsLimit,
      Locations.ROSTER_DETAILS_LIST,
      isDisabledAssignment,
      assignmentId,
    );
  }

  goToAssignmentsView() {
    return (this._state = RosterEditState.ASSIGNMENTS);
  }

  //--------------------------------- Co-Teachers -------------------------------------------------

  /**
   * @return {CoTeacher[]}
   */
  get coTeachers() {
    return this._coTeachers;
  }

  /**
   * indicates if currently selected column should be ascending or descending for roster co-teacher table
   * @returns {boolean}
   */
  get isAscendingRosterCoTeachers() {
    return this._isAscendingRosterCoTeachers;
  }

  /**
   * indicates when to show carrot next to the email in the in table header for co-teacher table
   * @returns {boolean}
   */
  get showCarrotForCoTeacherEmail() {
    return this._orderByForRosterCoTeachers === RosterCoTeacherColumns.EMAIL;
  }

  /**
   * indicates when to show carrot next to last accessed in the in table header for co-teacher table
   * * @returns {boolean}
   */
  get showCarrotForCoTeacherLastAccessed() {
    return (
      this._orderByForRosterCoTeachers === RosterCoTeacherColumns.LAST_ACCESSED
    );
  }

  get isCoTeachersView() {
    return this.state === RosterEditState.CO_TEACHERS;
  }

  get coTeachingHeaderMessage() {
    if (this._user && this._user.name) {
      return `You are co-teaching as ${this._user.name}. The work you do will appear under the name ${this._user.name}.`;
    }
    return '';
  }

  /**
   * sets the list order to the email column and/or toggles ascending/descending in co-teacher section
   */
  setOrToggleCoTeacherEmail() {
    this.setOrToggleCoTeacher(RosterCoTeacherColumns.EMAIL, true);
  }

  /**
   * sets the list order to the last accessed column and/or toggles ascending/descending in co-teacher section
   */
  setOrToggleCoTeacherLastAccessed() {
    this.setOrToggleCoTeacher(RosterCoTeacherColumns.LAST_ACCESSED, true);
  }

  /**
   * handles the logic to change the focus and order of columns for co-teacher table
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggleCoTeacher(colName, shouldAscend) {
    if (this._orderByForRosterCoTeachers !== colName) {
      this._orderByForRosterCoTeachers = colName;
      this._isAscendingRosterCoTeachers = shouldAscend;
    } else {
      this._isAscendingRosterCoTeachers = !this._isAscendingRosterCoTeachers;
    }
    this._applySortForCoTeachers();
  }

  _applySortForCoTeachers() {
    let orderByFunction = this.rosterCoTeacherOrderBy();
    let sort = (a, b) => {
      let result;
      let propertyA = orderByFunction(a);
      let propertyB = orderByFunction(b);
      if (propertyA > propertyB) {
        result = 1;
      } else if (propertyA === propertyB) {
        result = 0;
      } else {
        result = -1;
      }
      return this._isAscendingRosterCoTeachers ? result : -result;
    };

    this._coTeachers.sort(sort);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  rosterCoTeacherOrderBy() {
    let orderBy = this._orderByForRosterCoTeachers;
    return function (coTeacher) {
      if (RosterCoTeacherColumns.EMAIL === orderBy) {
        return coTeacher.coteacherEmail
          ? coTeacher.coteacherEmail.toLowerCase()
          : '';
      } else if (RosterCoTeacherColumns.LAST_ACCESSED === orderBy) {
        return coTeacher.lastAccessed ? coTeacher.lastAccessed : 0;
      }
    };
  }

  showCoTeacherHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.CoTeacher);
  }

  inviteCoTeacher() {
    this._inviteCoTeacherDialog(this.$mdDialog, this.roster.id).then(() => {
      this._cacheService
        .getCoTeachersByRoster(this.roster.id, true)
        .then((coTeachers) => {
          this._coTeachers = coTeachers;
          this._applySortForCoTeachers();
        })
        .catch((error) => {
          this._showErrorDialog(
            this.$mdDialog,
            'Uh oh! An error occurred',
            error && error.message,
          );
        });
    });
  }

  goToCoTeachersView() {
    return (this._state = RosterEditState.CO_TEACHERS);
  }

  removeTeacherFromCoTeaching(coTeachingId) {
    this._coTeacherService
      .deleteCoTeacherFromRoster(coTeachingId)
      .then(() => {
        return this._cacheService
          .getCoTeachersByRoster(this.$stateParams.rosterId, true)
          .then((coTeachers) => {
            this._coTeachers = coTeachers;
          });
      })
      .catch((error) => {
        this._showErrorDialog(
          this.$mdDialog,
          'Uh oh! An error occurred',
          error && error.message,
        );
      });
  }

  //--------------------------------- Export Grades Paywall -------------------------------------------------

  /**
   * Opens the assignment selection dialog to begin the export grades process
   */
  exportGrades() {
    if (!this.hasGradeExport) {
      this._proInfoDialog(this.$mdDialog, PaywallSources.ROSTER_GRADE_EXPORT);
    } else {
      this._showAssignmentSelectionDialog(this.$mdDialog, [
        ...this.assignmentsForRoster.values(),
      ])
        .then((selectedAssignmentIds) => {
          this._selectedAssignments = selectedAssignmentIds.map(
            (assignmentId) => this._assignments.get(assignmentId),
          );

          let promise = this._worksForSelectedAssignments(
            this._selectedAssignments,
            this.$stateParams.rosterId,
          );
          this._showLoadingDialog(this.$mdDialog, promise);
          return promise;
        })
        .then((worksByAssignment) => {
          this._csvService.exportGradesByRoster(
            worksByAssignment,
            this.usersOnRoster,
            this._roster.name,
          );
          this._analyticsService.rosterGradeExported();
        });
    }
  }
  openGradebookExport(assignmentId) {
    if (!this.hasGradeExport) {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.GradebookIntegration,
        PaywallSources.GRADEBOOK_INTEGRATION,
      );
    } else {
      this._storageService.ckRosterId = this.$stateParams.rosterId;
      this._storageService.ckAssignmentId = assignmentId;
      this._analyticsService.gradebookOpened('rosterEdit');
      return this._gradeExportDialog(this.$mdDialog);
    }
  }
}
