'use strict';

import ShareDialogController from '../../components/share-dialog/share-dialog.controller';
import SignupDialogController from '../../components/signup-dialog/signup-dialog';
import { AssignmentSheetMetadata } from '../../components/assignment-sheet/assignment-sheet.directive';
import { Locations } from '../../services/mixpanel/mixpanel.service';
import { UserRoles } from '../../model/domain/user';
import { ElementIntents } from '../../model/domain/element-metadata';
import CkRedirect from '../../model/domain/ck-redirect';
import CopyAssignmentRequest from '../../model/domain/copy-assignment-request';
import AssignmentManager from '../../model/domain/assignment-manager';

export default class PublicAssignmentController {
  constructor(
    $document,
    $log,
    $mdDialog,
    $mdPanel,
    $mdToast,
    $q,
    $state,
    $stateParams,
    $window,
    AnalyticsService,
    AssignmentService,
    AuthService,
    BreadcrumbService,
    CacheService,
    StorageService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.$log = $log;
    this.$mdPanel = $mdPanel;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$window = $window;
    this.$document = $document;

    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._showSignupDialog = SignupDialogController.show;
    this._shareDialog = ShareDialogController.show;
    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      undefined,
      undefined,
      this._analyticsService,
      undefined,
    );

    this.showAccountButton = this._authService.isLoggedIn;

    if (this.showAccountButton) {
      this._cacheService.getUser().then((user) => {
        this.userName = user.name;
      });
    }

    this._configMap = new Map();

    this.name = '';
    this.description = '';

    this._assignmentId = $stateParams.assignmentId;
    this._organizationIdFromPublicAssignmentLibrary =
      this._storageService.assignmentLibrarySelection &&
      this._storageService.assignmentLibrarySelection._id;

    if (!this.isAuthenticated) {
      this._breadcrumbService.go('root.public-assignment', {
        assignmentId: $stateParams.assignmentId,
      });
    } else {
      this._breadcrumbService.go('root.account.nav.public-assignment', {
        assignmentId: $stateParams.assignmentId,
      });
    }

    this._init();
  }

  _init() {
    return this._assignmentService
      .get(this._assignmentId)
      .then((assignment) => {
        this.assignment = assignment;
        this.name = assignment.name;
        this.description = assignment.brief;

        this._analyticsService.publicAssignmentViewed(
          this._assignmentId,
          this._organizationIdFromPublicAssignmentLibrary,
          this.isFromAssignmentLibrary,
        );
      })
      .catch((err) => {
        this.error = err;
      });
  }

  get isAuthenticated() {
    return this._authService.isLoggedIn;
  }

  assignmentSheetConfig(index) {
    if (angular.isNumber(index) && this.assignment) {
      let result = this._configMap.get(index);
      if (!result) {
        let config = new AssignmentSheetMetadata(
          this.assignment,
          this.assignment.questionIdForIndex(index),
          true,
          true,
          this.assignment.ownerId,
          UserRoles.TEACHER,
          ElementIntents.CONTENT,
        );

        this._configMap.set(index, config);
        return config;
      } else {
        return result;
      }
    }

    return null;
  }

  /**
   * If logged in, go to the user's assignment list and copy the assignment there.
   * Otherwise, bring up the signup dialog.
   */
  copyAssignment() {
    this._storageService.copyAssignmentRequest = new CopyAssignmentRequest(
      this._assignmentId,
      this.assignment.name,
    );
    if (this._authService.isLoggedIn) {
      this._assignmentManager.checkAssignmentLimit(
        Locations.PUBLIC_ASSIGNMENT,
        this._assignmentId,
        () => {
          this._breadcrumbService.go('root.account.nav.assignments', {});
        },
      );
    } else {
      this.showSignupDialog();
    }

    this._analyticsService.publicAssignmentCopyClicked(
      this._assignmentId,
      this._organizationIdFromPublicAssignmentLibrary,
      this.isFromAssignmentLibrary,
    );
  }

  /**
   * Bring up the signup dialog. When the button is clicked, go to the login page
   * with the optional redirect params set to return here after login.
   */
  showSignupDialog() {
    this._showSignupDialog(this.$mdDialog).then((stateName) => {
      this._storageService.ckRedirect = new CkRedirect(
        'root.account.nav.assignments',
        {},
      );

      this.$state.go(stateName, {
        referral: Locations.PUBLIC_ASSIGNMENT,
      });
    });
  }

  /**
   * open youtube video in new tab.
   */
  whatIsClasskick() {
    this.$window.open('https://www.youtube.com/watch?v=l5vza3HTW5I', '_blank');
  }

  /**
   * show the share dialog
   */
  showShareDialog() {
    this._shareDialog(
      this.$mdDialog,
      this.assignment,
      Locations.PUBLIC_ASSIGNMENT,
    );
  }

  /**
   * navigate to the given state, with given state params
   * @param  {string} stateName
   * @param  {object} stateParams
   */
  navigate(stateName, stateParams) {
    this._breadcrumbService.go(stateName, stateParams);
  }

  navToQuestion(questionId) {
    const path = this.isAuthenticated
      ? 'root.account.nav.public-assignment-question'
      : 'root.public-assignment-question';

    this._breadcrumbService.go(path, {
      assignmentId: this._assignmentId,
      questionId: questionId,
    });
  }

  navToLogin() {
    this._breadcrumbService.go('root.account-login', {});
  }

  get loading() {
    return !this.assignment && !this.error;
  }

  /**
   * Boolean that indicates whether the breadcrumb service can provide a path back
   * @return Boolean
   */
  get canGoBack() {
    return this._breadcrumbService.canGoBack;
  }

  get isFromAssignmentLibrary() {
    const stack = this._breadcrumbService.stack;
    if (
      stack.length &&
      (stack[stack.length - 1].to ===
        'root.account.nav.public-assignments-library' ||
        stack[stack.length - 1].to === 'root.public-assignments-library')
    ) {
      return true;
    }
    return false;
  }

  /**
   * Uses the breadcrumb service to go back.
   */
  goBack() {
    const stack = this._breadcrumbService.stack;
    if (
      stack[stack.length - 1].to === 'root.public-assignment-question' ||
      stack[stack.length - 1].to ===
        'root.account.nav.public-assignment-question'
    ) {
      this._breadcrumbService.go(
        stack[stack.length - 3].to,
        stack[stack.length - 3].params,
        true,
      );
    } else {
      this._breadcrumbService.goBack('root.account.home');
    }
  }
}
