'use strict';

import FirebaseServiceUtils from './firebase-service-utils';
import JSEvent from '../../model/util/js-event';
import FirebaseInstance from './firebase-instance';
import CryptoUtils from '../../model/util/crypto-utils';
import JSBI from 'jsbi';
import NotificationShardKey from '../../model/domain/notification-shard-key.js';

export default class FirebaseService {
  constructor(
    firebase,
    $log,
    $q,
    $timeout,
    environment,
    GoogleAnalyticsService,
  ) {
    'ngInject';

    this._firebase = firebase;
    this._firebaseServiceUtils = new FirebaseServiceUtils();

    this.$log = $log;
    this.$q = $q;
    this.$timeout = $timeout;

    this._environment = environment;

    /** @type {GoogleAnalyticsService} */
    this._googleAnalyticsService = GoogleAnalyticsService;

    this._instances = new Map();
    this._defaultInstanceId = undefined;
    this._notificationShardCount = undefined;
    this._universalMessageInstanceId = undefined;
    this._universalMessageInstance = undefined;
    this._firebaseApp = undefined;
    this._isConnected = false;

    this._connectedEvent = new JSEvent(this);
  }

  get DefaultInstanceId() {
    return this._defaultInstanceId;
  }

  get NotificationShardCount() {
    return this._notificationShardCount;
  }

  get UniversalMessageInstanceId() {
    return this._universalMessageInstanceId;
  }

  /**
   * Gets a firebase notification instance ID given a shard key
   * @param notificationShardKey {NotificationShardKey}
   * @returns {string}
   */
  firebaseNotificationInstanceIdForShardKey(notificationShardKey) {
    try {
      let shardKey = notificationShardKey.value;
      let instanceNumber =
        JSBI.toNumber(
          JSBI.remainder(
            CryptoUtils.djb2Hash(shardKey),
            JSBI.BigInt(this.NotificationShardCount),
          ),
        ) + 1;
      let instanceString = ('00' + instanceNumber).slice(-2);
      let firebaseInstanceId =
        this.DefaultInstanceId + '-notification-shard-' + instanceString;
      this.logTrace(
        'FirebaseService',
        `firebaseNotificationInstanceIdForShardKey ${notificationShardKey}, instance=${firebaseInstanceId}`,
      );
      return firebaseInstanceId;
    } catch (error) {
      this.$log.error(error);
      return this.DefaultInstanceId + '-notification-error';
    }
  }

  /**
   * Initializes the firebase service, called by BootstrapService
   * @param info {object}
   * @param authData {UserTokenInfo | null}
   * @returns {Promise.<object>}
   */
  init(info, authData) {
    return info.then((value) => {
      return this._configure(value, authData);
    });
  }

  /**
   * Configures the firebase url base and sets up the initial firebase refs
   * @param info {object}
   * @param authData {UserTokenInfo | null}
   * @returns {Promise.<object>}
   * @private
   */
  _configure(info, authData) {
    let self = this;

    let getInfoValue = function (key, defaultVal) {
      let value = info.data[key];
      if (angular.isDefined(value)) {
        return value;
      } else if (angular.isDefined(defaultVal)) {
        self.$log.error(
          `The key "${key}" was not found in info. Using default value: ${defaultVal}`,
        );
        return defaultVal;
      } else {
        throw new Error(`The required key "${key}" was not found in info!`);
      }
    };

    try {
      let defaultNotificationShards =
        this._environment.defaultFirebaseNotificationShards;

      this._defaultInstanceId = getInfoValue('firebase-default');
      this._notificationShardCount = getInfoValue(
        'firebase-notification-shards',
        defaultNotificationShards,
      );

      // Select a random notification shard to listen to for universal_message
      let instanceNumber =
        Math.floor(Math.random() * this.NotificationShardCount) + 1;
      let instanceString = ('00' + instanceNumber).slice(-2);
      this._universalMessageInstanceId =
        this.DefaultInstanceId + '-notification-shard-' + instanceString;
      this.logTrace(
        'FirebaseService',
        `_configure, UniversalMessageInstanceId=${this._universalMessageInstanceId}`,
      );

      // If the app has not already been initialized
      if (!this.isFirebaseAppInitialized) {
        // NOTE: This should never be called twice, doing so breaks the app
        this._firebaseApp = this._firebase.initializeApp({
          databaseURL: this.instanceIdToUrl(this._defaultInstanceId),
          authDomain: getInfoValue('auth-domain'),
          apiKey: getInfoValue('api-key'),
          projectId: getInfoValue('project-id'),
        });
      } else {
        this._googleAnalyticsService.sendException('avoided duplicate init');
        // I don't think we're going to see the issue where #initializeApp is called twice anymore
        // because we're only initializing one app, but JIC we should get the app so rest of code works.
        this._firebaseApp = this._firebase.apps.find(
          (app) => app.name === '[DEFAULT]',
        );
      }
    } catch (error) {
      this._googleAnalyticsService.sendException(error.message);
      throw error;
    }

    this._universalMessageInstance = this._getOrCreateInstance(
      this._universalMessageInstanceId,
    );

    return this.auth(authData).then(() => info);
  }

  /**
   * Gets or creates a firebase instance by id
   * @param id {string} the firebase instance id
   * @return {unknown}
   * @private
   */
  _getOrCreateInstance(id) {
    if (!this._instances.has(id)) {
      this._instances.set(id, this._createInstance(id));
    }

    return this._instances.get(id);
  }

  /**
   * @param id {string}
   * @return {FirebaseInstance}
   */
  _createInstance(id) {
    this.logTrace(
      '!!FIREBASE CONNECT!!',
      `FirebaseService.createInstance id=${id}`,
    );

    let isDefaultInstance = id === this._defaultInstanceId;
    let instanceUrl = this.instanceIdToUrl(id);
    let database = this._firebaseApp.database(
      isDefaultInstance ? '' : instanceUrl,
    );
    let baseRef = this.attachExtras(database.ref(), instanceUrl);

    return new FirebaseInstance(
      id,
      baseRef,
      this._onConnectionChange.bind(this),
    );
  }

  /**
   * @return {boolean}
   */
  get isFirebaseAppInitialized() {
    return this._firebase.apps.some((app) => {
      return app.name === '[DEFAULT]';
    });
  }

  /**
   * Always auths firebase. Attempts to auth with a user's token if available. If that fails or a
   * token is unavailable, auths anonymously.
   *
   * @param authData {UserTokenInfo}
   * @returns {Promise}
   */
  auth(authData) {
    if (authData) {
      return this._authUser(authData);
    } else {
      // If no auth data, auth anon so user can still view shared assignments
      return this._authAnonymously();
    }
  }

  /**
   * @param authData {UserTokenInfo}
   * @return {Promise}
   */
  _authUser(authData) {
    return this._getCurrentUser()
      .then((user) => {
        // if the database connection doesn't have a current user or the user is anonymous
        // or not the expected user, use the custom token to sign in to firebase
        if (!user || user.isAnonymous || user.uid !== authData.id) {
          return this._firebase
            .auth()
            .signInWithCustomToken(authData.firebaseToken);
        } else {
          return this.$q.resolve();
        }
      })
      .then(() => {
        this.$log.info('FirebaseService', '#auth', authData.id);
      })
      .catch((error) => {
        this.$log.error(error);

        let exception =
          this._googleAnalyticsService.formatExceptionDescriptionError(error);
        let message = `fb custom auth failed | ${exception}`;
        this._logError(message);

        return this._authAnonymously();
      });
  }

  /**
   * Gets the current firebase user
   * @return {Promise}
   * @private
   */
  _getCurrentUser() {
    let deferred = this.$q.defer();

    const unsubscribe = this._firebase.auth().onAuthStateChanged(
      (user) => {
        unsubscribe();
        deferred.resolve(user);
      },
      (error) => deferred.reject(error),
    );

    return deferred.promise;
  }

  /**
   * Auths to firebase anonymously
   * @returns {Promise.<object>}
   */
  _authAnonymously() {
    return this._firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        this.$log.info('FirebaseService', '#auth', 'anon');
      })
      .catch((error) => {
        this.$log.error(error);

        let exception =
          this._googleAnalyticsService.formatExceptionDescriptionError(error);
        let message = `fb anon auth failed | ${exception}`;
        this._logError(message);

        return this.$q.reject(error);
      });
  }

  _onConnectionChange() {
    let previouslyConnected = this._isConnected;

    // Is every currently active instance connected
    this._isConnected = [...this._instances.values()].every(
      (instance) => instance.connected,
    );

    if (previouslyConnected !== this._isConnected) {
      this.$log.info('FirebaseService', `connected=${this._isConnected}`);
      this.connectedEvent.raise({ connected: this._isConnected }, this);
    }
  }

  /**
   * indicates whether the client has a good connection to firebase
   * @returns {boolean}
   */
  get connected() {
    return this._isConnected;
  }

  /**
   * @returns {JSEvent}
   */
  get connectedEvent() {
    return this._connectedEvent;
  }

  /**
   * Attaches extra functionality to a firebase ref. See firebase-service-utils
   * @param ref {firebase.database.Reference}
   * @param instanceUrl {string}
   * @returns {firebase.database.Reference}
   */
  attachExtras(ref, instanceUrl) {
    return this._firebaseServiceUtils.attachExtras(ref, this.$q, instanceUrl);
  }

  /**
   * @param url {string}
   * @param instanceId {string}
   * @return {firebase.database.Reference}
   */
  ref(url, instanceId) {
    let instance = this._getOrCreateInstance(instanceId);
    return instance.ref(url);
  }

  /**
   * Returns the new firebase id for an element
   * @return {string}
   */
  newId() {
    // Calling push() without an arg means nothing is actually written to the reference location.
    // We just use this operation to trigger the generation of a new key value.
    // ref: https://firebase.google.com/docs/reference/js/database.md#push
    return this._universalMessageInstance.ref().child('newId').push().key;
  }

  /**
   * @param id {string} the firebase instance id to convert to an instance url
   * @return {string} the firebase instance url
   */
  instanceIdToUrl(id) {
    return `https://${id}.firebaseio.com`;
  }

  triggerApply() {
    this.$timeout(() => {});
  }

  /**
   * Logs the passed in message to the console if firebaseDebugLogging is enabled.
   * @param tag {string}
   * @param message {string}
   */
  logTrace(tag, message) {
    if (this._environment.firebaseTraceLogging === 'true') {
      this.$log.debug(tag + ': ' + message);
    }
  }

  /**
   * Logs the passed in message to the console and google analytics
   * @param message {string}
   */
  _logError(message) {
    this.$log.error(message);
    this._googleAnalyticsService.sendException(message);
  }
}
