'use strict';

import Codec from './codec';
import OrderPlan from '../domain/order-plan';
import { OrderPrice } from '../domain/order-price';
import OrderPlanCodec from './order-plan-codec';
import OrderCouponCodec from './order-coupon-codec';

/**
 * Encodes/Decodes an order price
 */
export default class OrderPriceCodec extends Codec {
  constructor() {
    super();

    this._orderPlanCodec = new OrderPlanCodec();
    this._orderCouponCodec = new OrderCouponCodec();
  }

  // The order plans are read-only from web services so no need for encoding
  encode() {}

  /**
   * @param value {Object}
   * @return {OrderPrice|null}
   */
  decode(value) {
    if (value) {
      return new OrderPrice(
        value.start,
        value.end,
        this._orderPlanCodec.decode(value.plan),
        this._orderCouponCodec.decode(value.coupon),
      );
    } else {
      return null;
    }
  }
}
