'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';

import MultipleChoiceParent, {
  MultipleChoiceOption,
} from '../ui/elements/multiple-choice-parent';
import Size from '../ui/size';
import StaticService from '../../services/static/static.service';

/**
 * Encodes/Decodes a multiple choice
 */
export default class MultipleChoiceParentCodec extends Codec {
  constructor() {
    super();

    this._multipleChoiceOptionCodec = new MultipleChoiceOptionCodec();
  }

  /**
   * @param value {object}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, (size, center) => {
      return {
        id: value.id,
        type: value.type,
        center: center,
        size: size,
        options: angular.toJson(
          value.options.map((option) =>
            this._multipleChoiceOptionCodec.encode(option),
          ),
        ),
      };
    });
  }

  /**
   * @param value {object}
   * @param id {string}
   * @returns {Element}
   */
  decode(value, id) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      const options = angular
        .fromJson(value.data.options)
        .map((option) => this._multipleChoiceOptionCodec.decode(option));
      const chosenSize = new Size(40 + options.length * 48, size.height);

      return new MultipleChoiceParent(
        id,
        metadata,
        location,
        chosenSize,
        options,
      );
    });
  }
}

export class MultipleChoiceOptionCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {MultipleChoiceOption}
   * @returns {Object}
   */
  encode(value) {
    return {
      answer: value.answer,
      points: value.points,
      correct: value.correct,
    };
  }

  /**
   *
   * @param value<Object>
   * @returns {MultipleChoiceOption}
   */
  decode(value) {
    return new MultipleChoiceOption(value.answer, value.points, value.correct);
  }
}
