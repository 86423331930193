'use strict';

import JSEvent from '../../model/util/js-event';

export default class WorkQuestionSet {
  /**
   * @param assignmentWork {AssignmentWork}
   * @param assignmentWorkNotification {AssignmentWorkNotification}
   */
  constructor(assignmentWork, assignmentWorkNotification) {
    this._assignmentWork = assignmentWork;
    this._assignmentWorkNotification = assignmentWorkNotification;

    /** @type {undefined|number} */
    this._totalPointsCache = undefined;
    /** @type {undefined|number} */
    this._totalPercentageCache = undefined;
    /** @type {undefined|boolean} */
    this._hasScoreCache = undefined;
    this._updated = new JSEvent(this);
  }

  /**
   * Starts and subscribes to embedded notification
   */
  start() {
    this._assignmentWorkNotification.scoreUpdated.subscribe(
      this._handleUpdated,
      this,
    );
    this._assignmentWorkNotification.start();
  }

  /**
   * Stops and unsubscribes from embedded notification
   */
  stop() {
    this._assignmentWorkNotification.scoreUpdated.unsubscribe(
      this._handleUpdated,
      this,
    );
    this._assignmentWorkNotification.stop();
  }

  /**
   * @returns {AssignmentWork}
   */
  get assignmentWork() {
    return this._assignmentWork;
  }

  /**
   * @param value {AssignmentWork}
   */
  set assignmentWork(value) {
    this._assignmentWork = value;
  }

  /**
   * @returns {Map.<string, AssignmentWorkQuestion>}
   */
  get questions() {
    return this._assignmentWork.questions;
  }

  /**
   * @returns {AssignmentWorkNotification}
   */
  get assignmentWorkNotification() {
    return this._assignmentWorkNotification;
  }

  /**
   * @returns {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  /**
   * @return {boolean}
   */
  get hasScore() {
    if (angular.isUndefined(this._hasScoreCache)) {
      this._hasScoreCache = this._assignmentWork.hasScore;
    }
    return this._hasScoreCache;
  }

  /**
   * @return {number}
   */
  get totalPoints() {
    if (angular.isUndefined(this._totalPointsCache)) {
      this._totalPointsCache = this._assignmentWork.totalPoints();
    }
    return this._totalPointsCache;
  }

  /**
   * Lazily calculates score percentage so we can sort assignments by grade
   * @return {number}
   */
  get totalPercentage() {
    if (angular.isUndefined(this._totalPercentageCache)) {
      let total = this.totalPoints;
      let score = this._assignmentWork.assignment.totalPotentialPoints();

      if (total === 0 && score === 0) {
        this._totalPercentageCache = 0;
      } else {
        this._totalPercentageCache = total / score;
      }
    }
    return this._totalPercentageCache;
  }

  /**
   * @param ev {AssignmentWorkChange}
   */
  _handleUpdated(ev) {
    let question = this.questions.get(ev.questionId);
    question.points = ev.score;
    question.answer = ev.answer;
    question.startedAt = ev.startedAt;
    this._totalPointsCache = undefined;
    this._totalPercentageCache = undefined;
    this._hasScoreCache = undefined;
    this._updated.raise(ev);
  }
}
