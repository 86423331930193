'use strict';

import moment from 'moment';
import HelpRequestSet from './help-request-set';
import {
  HelpInboxType,
  HelpInboxMetadata,
} from '../../components/help-inbox/help-inbox.directive';
import JSEvent from '../util/js-event';
import GradeUtils from '../util/grading-utils';
import LazyVar from '../util/lazy-var';
import StaticService from '../../services/static/static.service';
import { AssignmentSheetMetadata } from '../../components/assignment-sheet/assignment-sheet.directive';
import { StatusActivities } from './user-status-editor';
import { UserRoles } from './user';
import Helper from './helper';
import Sorts from './sorts';
import Debouncer from '../util/debouncer';
import HexColors from '../../css-constants';
import AnimalNames from './animal-names';
import Sticker from '../ui/elements/sticker';
import SlideForeground from '../ui/elements/slide-foreground';
import { StickerSources } from '../../services/mixpanel/mixpanel.service';
import ElementMetadata, { ElementIntents } from './element-metadata';
import Point from '../ui/point';
import CkAnimations from '../util/ck-animations';
import { AssignmentThumbnailMetadata } from '../../components/assignment-thumbnail/assignment-thumbnail.directive';
import { parseFullName } from 'parse-full-name';
import { renderMathInElement } from 'mathlive';
import { FitbAnswerTypes } from '../../components/assignment-toolbar/assignment-toolbar.directive';

export class SessionDataCodes {
  /**
   * Indicates a member was added to or removed from the main roster
   * @returns {string}
   */
  static get ROSTER_MEMBER() {
    return 'roster-member';
  }

  /**
   * When student sort is updated
   * @returns {string}
   */
  static get STUDENT_SORT() {
    return 'student-sort';
  }

  /**
   * When "hide offline students" is toggled
   * @return {string}
   */
  static get STUDENT_HIDE_OFFLINE() {
    return 'student-hide-offline';
  }

  /**
   * When "hide students who haven't started" is toggled
   * @return {string}
   */
  static get STUDENT_HIDE_UNSTARTED() {
    return 'student-hide-unstarted';
  }

  /**
   * When a students status is updated
   * @return {string}
   */
  static get STUDENT_STATUS() {
    return 'student-status';
  }

  /**
   * Unspecified other update
   * @returns {string}
   */
  static get MISC() {
    return 'misc';
  }

  /**
   * When bulk update is selected
   * @return {string}
   */
  static get BULK_UPDATE() {
    return 'bulk-update';
  }
}

export class StudentSortOptions {
  static get NameAsc() {
    return 'Students A-Z';
  }

  static get NameDesc() {
    return 'Students Z-A';
  }

  static get LastNameAsc() {
    return 'Last Name A-Z';
  }

  static get LastNameDesc() {
    return 'Last Name Z-A';
  }

  static get GradesDesc() {
    return 'Grades High to Low';
  }

  static get GradesAsc() {
    return 'Grades Low to High';
  }
}

/**
 * Class implements infinite scroll for md-virtual-repeat
 * https://material.angularjs.org/1.1.0/demo/virtualRepeat
 *
 * This implementation simply adds a delay, which fixes issues with using
 * md-virtual-repeat inside an ng-show on Firefox and Safari.
 */
export class VirtualRepeatStudents {
  constructor(sortedStudents, $timeout) {
    this.$timeout = $timeout;
    this._sortedStudents = sortedStudents;
    this._hasFetchStarted = false;
    this._hasFetchFinished = false;
  }

  set sortedStudents(value) {
    this._sortedStudents = value;
  }

  getLength() {
    return this._sortedStudents.length;
  }

  getItemAtIndex(index) {
    if (!this._hasFetchFinished) {
      if (!this._hasFetchStarted) {
        this.$timeout(() => {
          this._hasFetchFinished = true;
        }, 50);
        this._hasFetchStarted = true;
      }

      return null;
    }

    return this._sortedStudents[index];
  }
}

/**
 * Collects and maintains data for the session views
 */
export default class SessionData {
  /**
   * @param teacher {User}
   * @param assignment {Assignment}
   * @param roster {Roster}
   * @param students {Map.<string, User>} userId -> User
   * @param userRosters {Map.<string, Roster>} rosterId -> Roster
   * @param userClassCodes {ClassCode[]}
   * @param assignmentHelpRequests {HelpRequestSet}
   * @param allHelpRequests {HelpRequestSet}
   * @param works {Map.<string, AssignmentWork>} assignmentWorkId -> AssignmentWork
   * @param proInfo {ProInfo}
   * @param stickers {UserSticker[]}
   * @param $q
   * @param $timeout
   * @param userService {UserService}
   * @param assignmentWorkService {AssignmentWorkService}
   * @param notificationService {NotificationService}
   * @param cacheService {CacheService}
   * @param assignmentService {AssignmentService}
   * @param storageService {StorageService}
   * @param helpRequestService {HelpRequestService}
   * @param feedbackService {FeedbackService}
   * @param firebaseService {FirebaseService}
   * @param analyticsService {AnalyticsService}
   */
  constructor(
    teacher,
    assignment,
    roster,
    students,
    userRosters,
    userClassCodes,
    assignmentHelpRequests,
    allHelpRequests,
    works,
    proInfo,
    stickers,
    $q,
    $timeout,
    userService,
    assignmentWorkService,
    notificationService,
    cacheService,
    assignmentService,
    storageService,
    helpRequestService,
    feedbackService,
    firebaseService,
    analyticsService,
  ) {
    this._updated = new JSEvent(this);

    this._teacher = teacher;
    this._assignment = assignment;
    this._roster = roster;
    this._students = students;
    this._userRosters = userRosters;
    this._userClassCodes = userClassCodes;
    this._assignmentHelpRequests = assignmentHelpRequests;
    this._allHelpRequests = allHelpRequests;
    this._works = works;
    this._proInfo = proInfo;
    this._stickers = stickers;
    this._placingSticker = undefined;
    this._placingStop = undefined;
    this._bulkUpdateOption = undefined;

    this.$q = $q;
    this.$timeout = $timeout;
    this._userService = userService;
    this._assignmentWorkService = assignmentWorkService;
    this._notificationService = notificationService;
    this._cacheService = cacheService;
    this._assignmentService = assignmentService;
    this._storageService = storageService;
    this._helpRequestService = helpRequestService;
    this._feedbackService = feedbackService;
    this._firebaseService = firebaseService;
    this._analyticsService = analyticsService;

    this._assignmentSheetConfigs = new Map();
    this._assignmentThumbnailConfigs = new Map();
    this._assignmentWorkThumbnailConfigs = new Map();

    this._sessionQuestionNumber = undefined;

    this._started = false;

    /**
     * All the rosters that are linked to the given assignment
     * @type {Roster[]}
     */
    this._assignmentRosters = this._assignment.rosters
      .map((id) => this._userRosters.get(id))
      .filter((roster) => !!roster);

    /**
     * The sorted array of students
     * @type {User[]}
     */
    this._sortedStudents = [];
    this._virtualRepeatSortedStudents = new VirtualRepeatStudents(
      [],
      this.$timeout,
    );

    /**
     * An array of anonymous student to assign to students
     * @type {Array}
     */
    this._anonStudentNames = [];
    this._anonStudentNamesMap = new Map();

    /**
     * Lazily populated array of obects containing the question, question index, and question number
     * This is used to render question tiles
     * @type {LazyVar.<{question: AssignmentQuestion, index: int, number: int}[]>}
     */
    this._questionIndices = new LazyVar();

    /**
     * Map of user status notifications by student id
     * @type {Map.<string, UserStatusNotification>} userId -> UserStatusNotification
     */
    this._studentStatuses = new Map();

    /**
     * Map of active helpers by student id
     * @type {Map.<string, QuestionHelpers>} userId -> AssignmentWork
     * @private
     */
    this._activeHelpers = new Map();

    /**
     * Map of work question sets by student id
     * @type {Map.<string, WorkQuestionSet>} userId -> WorkQuestionSet
     */
    this._workQuestionSets = new Map();

    /**
     * Options for sorting the students, takes two Users
     * @type {{name: string, sort: function}[]}
     */
    this._studentSortOptions = [
      {
        name: StudentSortOptions.NameAsc,
        sort: (a, b) => {
          return Sorts.NAME_ASC(
            this.realOrAnonNameForStudent(a),
            this.realOrAnonNameForStudent(b),
          );
        },
      },
      {
        name: StudentSortOptions.NameDesc,
        sort: (a, b) => {
          return Sorts.NAME_DESC(
            this.realOrAnonNameForStudent(a),
            this.realOrAnonNameForStudent(b),
          );
        },
      },
      {
        name: StudentSortOptions.LastNameAsc,
        sort: (a, b) => {
          if (
            Sorts.NAME_ASC(
              this.realOrAnonLastNameForStudent(a),
              this.realOrAnonLastNameForStudent(b),
            ) === 0
          ) {
            return Sorts.NAME_ASC(
              this.realOrAnonNameForStudent(a),
              this.realOrAnonNameForStudent(b),
            );
          }
          return Sorts.NAME_ASC(
            this.realOrAnonLastNameForStudent(a),
            this.realOrAnonLastNameForStudent(b),
          );
        },
      },
      {
        name: StudentSortOptions.LastNameDesc,
        sort: (a, b) => {
          if (
            Sorts.NAME_DESC(
              this.realOrAnonLastNameForStudent(a),
              this.realOrAnonLastNameForStudent(b),
            ) === 0
          ) {
            return Sorts.NAME_DESC(
              this.realOrAnonNameForStudent(a),
              this.realOrAnonNameForStudent(b),
            );
          }
          return Sorts.NAME_DESC(
            this.realOrAnonLastNameForStudent(a),
            this.realOrAnonLastNameForStudent(b),
          );
        },
      },
      {
        name: StudentSortOptions.GradesDesc,
        sort: (a, b) => {
          return this.studentTotalScore(a.id) < this.studentTotalScore(b.id)
            ? 1
            : -1;
        },
      },
      {
        name: StudentSortOptions.GradesAsc,
        sort: (a, b) => {
          return this.studentTotalScore(a.id) < this.studentTotalScore(b.id)
            ? -1
            : 1;
        },
      },
    ];

    this._studentSortDebounce = new Debouncer(1000, 3000, () =>
      this.sortStudents(),
    );

    if (this.isComplete) {
      /**
       * The roster notification for this roster
       * @type {RosterNotification}
       */
      this._rosterNotification =
        notificationService.getRosterNotification(roster);

      /**
       * The class code for this session
       * @type {ClassCode}
       */
      this._classCode = this._userClassCodes.find(
        (cc) => cc.rosterId === roster.id,
      );

      /**
       * All unresolved help requests for this session, indexed by helpee + question ids
       * @type {HelpRequestSet}
       */
      this._openHelpRequests = this._allHelpRequests.createChild(
        HelpRequestSet.FILTER_FUNC_UNRESOLVED,
        HelpRequestSet.INDEX_FUNC_HELPEE_QUESTION,
      );

      /**
       * Map of resolved help requests within this session by student id
       * @type {Map.<string, HelpRequestSet>}
       */
      this._studentHelpRequestHistory = new Map(
        [...this.students.values()].map((student) => [
          student.id,
          this._helpRequestHistoryForStudent(student.id),
        ]),
      );

      /**
       * Map of open help requests for each of the assignment's roster by roster id
       * @type {Map.<string, HelpRequestSet>}
       */
      this._assignmentHelpRequestsByRoster = new Map(
        this._assignmentRosters.map((roster) => [
          roster.id,
          this._assignmentHelpRequestChild(roster),
        ]),
      );

      /**
       * Map of assignment works for all students by student id
       * @type {Map.<string, AssignmentWork>} userId -> AssignmentWork
       */
      this._worksByStudent = new Map(
        [...this._works.values()].map((x) => [x.ownerId, x]),
      );

      /**
       * Metadata for rendering the help inbox
       * @type {HelpInboxMetadata}
       */
      this._helpInboxMetadata = new HelpInboxMetadata(
        this._openHelpRequests,
        this._students,
        this._worksByStudent,
        HelpInboxType.TEACHER,
      );

      this.studentSort = this._studentSortOptions[2];
    }

    this._init();
  }

  //------------------------ Set Up and Tear Down -------------------------------

  /**
   * @private
   */
  _init() {
    if (this.isComplete) {
      this._rosterNotification.userAdded.subscribe(
        this._onStudentAddedToRoster,
        this,
      );
      this._rosterNotification.userRemoved.subscribe(
        this._onStudentRemovedFromRoster,
        this,
      );
      this._rosterNotification.start();

      this._openHelpRequests.created.subscribe(this._onMiscUpdate, this);
      this._openHelpRequests.resolved.subscribe(this._onMiscUpdate, this);
      this._openHelpRequests.canceled.subscribe(this._onMiscUpdate, this);
      this._allHelpRequests.start();

      this._assignmentHelpRequests.start();

      this.classCode.allowPdfUpdated.subscribe(this._handleAllowPdf, this);

      // Initialize Works and WorkQuestionSets for all existing works
      this._works.forEach((work) => this._configureStudentWork(work, false));

      // Initialize UserStatusNotifications for all students on roster
      this._studentStatuses = new Map(
        [...this._students.keys()].map((id) => {
          const status = this._notificationService.getUserStatusNotification(
            id,
            true,
          );
          status.updated.subscribe(this._onStudentStatusUpdated, this);
          status.start();
          return [id, status];
        }),
      );

      // Initialize QuestionHelpers for all students on roster
      this._activeHelpers = new Map(
        [...this._students.keys()].map((id) => {
          const helpers = this._userService.getPeerQuestionHelpers(
            this._assignment,
            id,
          );
          helpers.updated.subscribe(this._onMiscUpdate, this);
          helpers.start();
          return [id, helpers];
        }),
      );
    }
  }

  /**
   * Stops all firebase stuff, unsubscribes from all notifications
   */
  destroy() {
    if (this.isComplete) {
      this._rosterNotification.userAdded.unsubscribe(
        this._onStudentAddedToRoster,
        this,
      );
      this._rosterNotification.userRemoved.unsubscribe(
        this._onStudentRemovedFromRoster,
        this,
      );
      this._rosterNotification.stop();

      this._allHelpRequests.stop();
      this._allHelpRequests.created.unsubscribe(this._onMiscUpdate, this);
      this._allHelpRequests.resolved.unsubscribe(this._onMiscUpdate, this);
      this._allHelpRequests.canceled.unsubscribe(this._onMiscUpdate, this);

      this._assignmentHelpRequests.stop();

      this.classCode.allowPdfUpdated.unsubscribe(this._handleAllowPdf, this);

      for (let /** @type {UserStatusNotification} */ status of this._studentStatuses.values()) {
        status.updated.unsubscribe(this._onStudentStatusUpdated, this);
        status.stop();
      }
      for (let /** @type {QuestionHelpers} */ helpers of this._activeHelpers.values()) {
        helpers.updated.unsubscribe(this._onMiscUpdate, this);
        helpers.stop();
      }

      this.assignment.questions.forEach((question) => question.stop());

      this._works.forEach((work) => this._configureStudentWork(work, true));
    }
  }

  /**
   * Creates a new SessionData from the minimum necessary parameters
   *
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param $q
   * @param $timeout
   * @param cacheService {CacheService}
   * @param helpRequestService {HelpRequestService}
   * @param assignmentWorkService {AssignmentWorkService}
   * @param userService {UserService}
   * @param notificationService {NotificationService}
   * @param assignmentService {AssignmentService}
   * @param storageService {StorageService}
   * @param feedbackService {FeedbackService}
   * @param firebaseService {FirebaseService}
   * @param analyticsService {AnalyticsService}
   *
   * @returns {Promise.<SessionData>}
   */
  static fetch(
    assignmentId,
    rosterId,
    $q,
    $timeout,
    cacheService,
    helpRequestService,
    assignmentWorkService,
    userService,
    notificationService,
    assignmentService,
    storageService,
    feedbackService,
    firebaseService,
    analyticsService,
  ) {
    return $q
      .all({
        assignment: cacheService.getAssignmentForUser(assignmentId),
        rosters: cacheService.getRostersForUser(),
        classCodes: cacheService.getClassCodesForUserAssignment(assignmentId),
        teacher: cacheService.getUser(),
        proInfo: cacheService.getProInfo(),
        stickers: cacheService.getStickersForUser(),
      })
      .then((data) => {
        const next = {
          assignment: data.assignment,
          userRosters: data.rosters,
          userClassCodes: data.classCodes,
          teacher: data.teacher,
          proInfo: data.proInfo,
          stickers: data.stickers,
        };

        if (rosterId) {
          next.roster = next.userRosters.get(rosterId);
          next.rosterMembers = cacheService.getRosterUsers(rosterId, true);
          next.allHelpRequests = helpRequestService.getHelpRequestSetForPeers(
            next.assignment.ownerId,
            assignmentId,
            rosterId,
            true,
          );
          next.assignmentHelpRequests =
            helpRequestService.getHelpRequestSetForAssignment(
              data.assignment.ownerId,
              assignmentId,
            );
          next.works = cacheService.getRosterWorks(
            next.assignment,
            rosterId,
            true,
          );
        }

        return $q.all(next);
      })
      .then((data) => {
        if (!data.roster) {
          return new SessionData(
            data.teacher,
            data.assignment,
            null,
            null,
            data.userRosters,
            data.userClassCodes,
            undefined,
            undefined,
            undefined,
            data.proInfo,
          );
        }

        return new SessionData(
          data.teacher,
          data.assignment,
          data.roster,
          data.rosterMembers,
          data.userRosters,
          data.userClassCodes,
          data.assignmentHelpRequests,
          data.allHelpRequests,
          data.works,
          data.proInfo,
          data.stickers,
          $q,
          $timeout,
          userService,
          assignmentWorkService,
          notificationService,
          cacheService,
          assignmentService,
          storageService,
          helpRequestService,
          feedbackService,
          firebaseService,
          analyticsService,
        );
      });
  }

  //--------------------- Helper Methods ------------------------------

  /**
   * This should be called whenever the session's data may have been updated
   *
   * @returns Promise.<SessionData>
   */
  refreshData() {
    return this.$q
      .all({
        assignment: this._cacheService.getAssignmentForUser(
          this.assignment.id,
          true,
        ),
        rosters: this._cacheService.getRostersForUser(),
        classCodes: this._cacheService.getClassCodesForUserAssignment(
          this.assignment.id,
        ),
        students: this._cacheService.getRosterUsers(this.roster.id),
      })
      .then((data) => {
        this._assignment = data.assignment.mergeFrom(this._assignment);
        this._userRosters = data.rosters;
        this._roster = data.rosters.get(this._roster.id) || this._roster;
        this._students = data.students;
        this.sortStudents();
        this._questionIndices.clear();

        this._assignmentRosters = this._assignment.rosters
          .map((id) => this._userRosters.get(id))
          .filter((roster) => !!roster);
        for (let roster of this._assignmentRosters) {
          if (!this._assignmentHelpRequestsByRoster.has(roster.id)) {
            this._assignmentHelpRequestsByRoster.set(
              roster.id,
              this._assignmentHelpRequestChild(roster),
            );
          }
        }

        this._userClassCodes = data.classCodes;

        return this._cacheService.getRosterWorks(
          this.assignment,
          this.roster.id,
          true,
        );
      })
      .then((works) => {
        if (this._started) {
          this.assignment.questions.forEach((q) => q.start());
        }

        works.forEach((work) => this._configureStudentWork(work, false));
        return this;
      });
  }

  /**
   * Gets data for a student and adds it to this collection if not already present
   *
   * @param userId {string}
   * @returns {Promise.<SessionData>}
   */
  fetchDataForStudent(userId) {
    return this.$q
      .all({
        user:
          this.student(userId) ||
          this._userService.getStudentOnRoster(this.roster.id, userId),

        // HACK -- $timeout(1000) is to avoid race conditions between the client who is logging in to this work for the first time
        // and the teacher who is detecting that the client is logging in. If both create a new work at the same time we get two;
        // this is bad. Putting transactions around this operation on the server will allow us to get rid of this
        // hack.
        work:
          this.workForStudent(userId) ||
          this.$timeout(() => {}, 1000, false).then(() =>
            this._assignmentWorkService.getOrCreateForOther(
              this._assignment,
              this.roster.id,
              userId,
            ),
          ),
      })
      .then((data) => {
        if (!this._students.has(userId)) {
          this._students.set(userId, data.user);
        }

        if (!this._studentStatuses.has(userId)) {
          let status = this._notificationService.getUserStatusNotification(
            userId,
            true,
          );
          status.updated.subscribe(this._onStudentStatusUpdated, this);
          this._studentStatuses.set(userId, status);
          status.start();
        }

        if (!this._activeHelpers.has(userId)) {
          let helpers = this._userService.getPeerQuestionHelpers(
            this._assignment,
            userId,
          );
          this._activeHelpers.set(userId, helpers);
          helpers.updated.subscribe(this._onMiscUpdate, this);
          helpers.start();
        }

        if (!this._studentHelpRequestHistory.has(userId)) {
          this._studentHelpRequestHistory.set(
            userId,
            this._helpRequestHistoryForStudent(userId),
          );
        }

        this._configureStudentWork(data.work, false);

        return this;
      })
      .catch((err) => {
        StaticService.get.$log.error(err);
        return err;
      });
  }

  /**
   * Creates a HelpRequestSet for the help requests on a roster
   *
   * @param roster
   * @returns {HelpRequestSet}
   * @private
   */
  _assignmentHelpRequestChild(roster) {
    return this._assignmentHelpRequests.createChild(
      (x) => x.rosterId === roster.id,
    );
  }

  /**
   * Sets up (or destroys) a student's work data
   *
   * @param work {AssignmentWork}
   * @param destroy {boolean}
   * @private
   */
  _configureStudentWork(work, destroy) {
    const existingWork = this._works.get(work.id);
    // Need to shut down current value
    if (destroy && existingWork) {
      existingWork.questions.forEach((question) => {
        question.stop();
        question.messages.stop();
      });
      this._works.delete(work.id);
      this._worksByStudent.delete(work.ownerId);
    }
    // Need to merge into existing value
    else if (existingWork) {
      work.mergeFrom(existingWork, true);
    }

    let workSet = this._workQuestionSets.get(work.ownerId);
    // Need to shut down current value
    if (destroy && workSet) {
      workSet.stop();
      workSet.updated.unsubscribe(this._onWorkSetUpdated, this);
      this._workQuestionSets.delete(work.ownerId);
    }
    // Need to merge into existing value
    else if (workSet) {
      workSet.assignmentWork = work;
    }
    // Need to create one
    else if (!destroy) {
      workSet = this._assignmentWorkService.getAssignmentWorkQuestionSet(work);
      workSet.updated.subscribe(this._onWorkSetUpdated, this);
      workSet.start();
    }

    // Clear out any assignment sheet and/or thumbnail configs associated with the given works
    work.questions.forEach((question) => {
      this._assignmentSheetConfigs.delete(
        this._assignmentSheetConfigIndex(work.ownerId, question.id),
      );
      this._assignmentThumbnailConfigs.delete(
        this._assignmentThumbnailConfigIndex(work.assignmentId, question.id),
      );
      this._assignmentWorkThumbnailConfigs.delete(
        this._assignmentWorkThumbnailConfigIndex(work.ownerId, question.id),
      );
    });
    if (existingWork) {
      existingWork.questions.forEach((question) => {
        this._assignmentSheetConfigs.delete(
          this._assignmentSheetConfigIndex(work.ownerId, question.id),
        );
        this._assignmentThumbnailConfigs.delete(
          this._assignmentThumbnailConfigIndex(work.assignmentId, question.id),
        );
        this._assignmentWorkThumbnailConfigs.delete(
          this._assignmentWorkThumbnailConfigIndex(work.ownerId, question.id),
        );
      });
    }

    if (!destroy) {
      this._works.set(work.id, work);
      this._worksByStudent.set(work.ownerId, work);

      this._workQuestionSets.set(work.ownerId, workSet);
    }
  }

  /**
   * @param event {AssignmentWorkChange}
   * @param source {WorkQuestionSet}
   */
  _onWorkSetUpdated(event, source) {
    let ownerId = source.assignmentWork.ownerId;
    if (event.startedAt && event.questionId && ownerId) {
      this._assignmentWorkThumbnailConfigs.delete(
        this._assignmentWorkThumbnailConfigIndex(ownerId, event.questionId),
      );
    }
  }

  /**
   * Adds student data to this instance when the roster notification
   * indicates a student has been added
   *
   * @param event {{userId: string}}
   * @private
   */
  _onStudentAddedToRoster(event) {
    this.fetchDataForStudent(event.userId).then(() => {
      this._raiseUpdated(SessionDataCodes.ROSTER_MEMBER);
    });
  }

  /**
   * Removes student data from this instance when the roster notification
   * indicates a student has been removed
   *
   * @param event {{userId: string}}
   * @private
   */
  _onStudentRemovedFromRoster(event) {
    if (this._students.has(event.userId)) {
      this._students.delete(event.userId);
    }

    const work = this.workForStudent(event.userId);
    if (work) {
      this._configureStudentWork(work, true);
    }

    if (this._studentStatuses.has(event.userId)) {
      this._studentStatuses.get(event.userId).stop();
      this._studentStatuses.delete(event.userId);
    }

    if (this._activeHelpers.has(event.userId)) {
      this._activeHelpers.get(event.userId).stop();
      this._activeHelpers
        .get(event.userId)
        .updated.unsubscribe(this._onMiscUpdate, this);
      this._activeHelpers.delete(event.userId);
    }

    if (this._studentHelpRequestHistory.has(event.userId)) {
      this._studentHelpRequestHistory.delete(event.userId);
    }

    this._raiseUpdated(SessionDataCodes.ROSTER_MEMBER);
  }

  /**
   * Fetches student data when a student comes online according to their status
   * notification. This fills the data gap in the following scenario:
   *
   * - Roster has students
   * - Assignment is added to roster
   * - Teacher goes to Session View
   * - SessionData is populated with student data, but no works exist
   * - Student signs in with class code, thus creating a new assignment work
   *
   * This method runs in at the end of that scenario to fetch and populate the newly
   * created work in this instance.
   *
   * @param status {UserStatus}
   * @private
   */
  _onStudentStatusUpdated(status) {
    let promise = this.$q.resolve({});

    let userStatus = this.studentStatus(status.userId);
    let userOldStatus = userStatus && userStatus.oldStatus;
    let userIsOnCurrentAssignment = status.assignmentId === this.assignment.id;
    let userIsNowOnline =
      !userOldStatus || userOldStatus.online !== status.online;

    let userIsLoggingOnForFirstTime =
      status.online &&
      userIsOnCurrentAssignment &&
      !this.studentHasStarted(status.userId);

    if (userIsLoggingOnForFirstTime) {
      promise = this.fetchDataForStudent(status.userId);
    }

    promise.then(() => {
      this._raiseUpdated(SessionDataCodes.STUDENT_STATUS, {
        userIsNowOnline,
        userIsLoggingOnForFirstTime,
      });
    });
  }

  /**
   * Generates a new help request history set for a student
   *
   * @param userId {string} user id
   * @returns {HelpRequestSet}
   * @private
   */
  _helpRequestHistoryForStudent(userId) {
    return this._allHelpRequests.createChild(
      (request) =>
        !!request.resolved &&
        this.students.get(request.helpeeId) &&
        this.students.get(request.helperId) &&
        request.helpeeId !== request.helperId &&
        (request.helpeeId === userId || request.helperId === userId),
    );
  }

  /**
   * Raises the updated event with the supplied code
   *
   * @param code {string}
   * @param payload {object}
   * @private
   */
  _raiseUpdated(code, payload = {}) {
    this._updated.raise({
      code: code,
      ...payload,
    });
  }

  /**
   * Raises the updated event with the code MISC
   *
   * @private
   */
  _onMiscUpdate() {
    this._raiseUpdated(SessionDataCodes.MISC);
  }

  //--------------------- Session Question View Config --------------------------------

  /**
   * @return {undefined|string}
   */
  get sessionQuestionNumber() {
    return this._sessionQuestionNumber;
  }

  set sessionQuestionNumber(value) {
    this._sessionQuestionNumber = value;
  }

  get ViewAllSlides() {
    return 'view-all-slides';
  }

  //--------------------- Basic Info --------------------------------

  /**
   * @returns {User}
   */
  get teacher() {
    return this._teacher;
  }

  /**
   * @returns {boolean}
   */
  get isComplete() {
    return !!this._roster;
  }

  get numOfStudents() {
    return this._students?.size ?? 0;
  }

  /**
   * Indicates that some data was updated, other than roster membership
   * @returns {JSEvent.<{code: string}>} Code is a SessionDataCode
   */
  get updated() {
    return this._updated;
  }

  /**
   * Starts assignment and work data from firebase
   */
  start() {
    this._started = true;

    this.assignment.questions.forEach((question) => question.start());
    this._works.forEach((work) => this._configureStudentWork(work, false));
  }

  //--------------- Assignment Info

  /**
   * @returns {Assignment}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @returns {string}
   */
  get assignmentName() {
    if (this.assignment) {
      return this.assignment.name;
    } else {
      return 'Loading...';
    }
  }

  /**
   * @returns {{question: AssignmentQuestion, index: int, number: int}[]}
   */
  get questions() {
    if (this.assignment) {
      return this._questionIndices.value(() => {
        return this.assignment.questions.map((value, index) => {
          return {
            question: value,
            index: index,
            number: index + 1,
          };
        });
      });
    }

    return [];
  }

  /**
   * @param questionId
   * @returns {number}
   */
  questionNumber(questionId) {
    return this.assignment.questionNumberForId(questionId);
  }

  //--------------- Roster Info

  /**
   * @returns {Roster|null}
   */
  get roster() {
    return this._roster;
  }

  /**
   * @return {boolean}
   */
  get rosterIsEmpty() {
    return this.numOfStudents === 0;
  }

  /**
   * @returns {RosterNotification}
   */
  get rosterNotification() {
    return this._rosterNotification;
  }

  /**
   * @returns {boolean}
   */
  get hasRosters() {
    return !!this.assignmentRosters && this.assignmentRosters.length > 0;
  }

  /**
   * @param rosterId {string}
   * @returns {Roster}
   */
  rosterById(rosterId) {
    return this._userRosters.get(rosterId);
  }

  /**
   * @return {Map<string, Roster>}
   */
  get userRosters() {
    return this._userRosters;
  }

  /**
   * @returns {Roster[]}
   */
  get assignmentRosters() {
    return this._assignmentRosters;
  }

  /**
   * @returns {ClassCode}
   */
  get classCode() {
    return this._classCode;
  }

  /**
   * @param {ClassCode} value
   */
  set classCode(value) {
    this._classCode = value;
  }

  /**
   * @return {boolean}
   */
  get showStudentScores() {
    return this.classCode.showStudentScores;
  }

  /**
   * @param value {boolean}
   */
  set showStudentScores(value) {
    this._updateClassCodeMetadata(
      value,
      this.lockStudentWork,
      this.hideStudentWork,
      this.classCode.allowPdf,
    );
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    return this.classCode.lockStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set lockStudentWork(value) {
    this._updateClassCodeMetadata(
      this.showStudentScores,
      value,
      this.hideStudentWork,
      this.allowPdf,
    );
  }

  /**
   * @return {boolean}
   */
  get hideStudentWork() {
    return this.classCode.hideStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set hideStudentWork(value) {
    this._updateClassCodeMetadata(
      this.showStudentScores,
      this.lockStudentWork,
      value,
      this.allowPdf,
    );
  }

  /**
   * @return {boolean}
   */
  get allowPdf() {
    return this.classCode.allowPdf;
  }

  /**
   * @param value {boolean}
   */
  set allowPdf(value) {
    this._updateClassCodeMetadata(
      this.showStudentScores,
      this.lockStudentWork,
      this.hideStudentWork,
      value,
    );
  }

  /**
   * @param value {boolean}
   */
  _handleAllowPdf(value) {
    this.coreData.classCode.allowPdf = value;
  }

  /**
   * @param showStudentScores {boolean}
   * @param lockStudentWork {boolean}
   * @param hideStudentWork {boolean}
   * @param allowPdf {boolean}
   */
  _updateClassCodeMetadata(
    showStudentScores,
    lockStudentWork,
    hideStudentWork,
    allowPdf,
  ) {
    this._assignmentService
      .addOrUpdateRoster(
        this.assignment.id,
        this.roster.id,
        showStudentScores,
        lockStudentWork,
        hideStudentWork,
        allowPdf,
      )
      .then((classCode) => {
        this._classCode = classCode;
        return this._cacheService.getClassCodesForUserAssignment(
          this.assignment.id,
          true,
        );
      })
      .then((classCodes) => {
        this._userClassCodes = classCodes;
      })
      .catch((err) => {
        StaticService.get.$log.error(err);
      });
  }

  /**
   * @returns {ClassCode[]}
   */
  get classCodes() {
    return this._userClassCodes;
  }

  /**
   * @return {boolean}
   */
  get hideUnstartedThumbnails() {
    let result = this._storageService.hideUnstartedThumbnails;

    if (result === null || angular.isUndefined(result)) {
      return true;
    } else {
      return result;
    }
  }

  /**
   * @param value {boolean}
   */
  set hideUnstartedThumbnails(value) {
    this._storageService.hideUnstartedThumbnails = value;
  }

  /**
   * @return {boolean}
   */
  get hideOfflineStudents() {
    return this._storageService.hideOfflineStudents;
  }

  /**
   * @param value {boolean}
   */
  set hideOfflineStudents(value) {
    this._storageService.hideOfflineStudents = value;
    this.sortStudents();
    this._raiseUpdated(SessionDataCodes.STUDENT_HIDE_OFFLINE);
  }

  /**
   * @return {boolean}
   */
  get hideUnstartedAssignmentWorks() {
    return this._storageService.hideUnstartedAssignmentWorks;
  }

  /**
   * @param value {boolean}
   */
  set hideUnstartedAssignmentWorks(value) {
    this._storageService.hideUnstartedAssignmentWorks = value;
    this.sortStudents();
    this._raiseUpdated(SessionDataCodes.STUDENT_HIDE_UNSTARTED);
  }

  get hideStudentNames() {
    return this._storageService.hideStudentNames;
  }

  set hideStudentNames(value) {
    this._storageService.hideStudentNames = value;
    this.sortStudents();
  }

  /**
   * @return {string}
   */
  get sessionId() {
    return `${this.assignment.id}${this.hasRosters && this.roster.id}`;
  }

  /**
   * @param user {User}
   * @return {string}
   */
  realOrAnonNameForStudent(user) {
    if (this.hideStudentNames) {
      return this.anonNameForStudent(user);
    } else {
      return user.name;
    }
  }

  /**
   * @param user {User}
   * @return {string}
   */
  realOrAnonLastNameForStudent(user) {
    if (this.hideStudentNames) {
      return parseFullName(this.anonNameForStudent(user)).last;
    } else {
      return user.lastName || parseFullName(user.name).last;
    }
  }

  /**
   * @param user {User}
   * @return {string}
   */
  anonNameForStudent(user) {
    if (!this._anonStudentNamesMap.has(user.id)) {
      let hashId = `${user.id}${this.sessionId}`;
      let combinationIndex = AnimalNames.hashFunc(
        hashId,
        this.anonStudentNames.length,
      );
      let animalName = this.anonStudentNames.splice(combinationIndex, 1)[0];
      this._anonStudentNamesMap.set(user.id, animalName.displayWithoutArticle);
    }

    return this._anonStudentNamesMap.get(user.id);
  }

  get anonStudentNames() {
    if (this._anonStudentNames.length === 0) {
      this._anonStudentNames =
        AnimalNames.combinationsForHelperWithoutColors.slice();
    }
    return this._anonStudentNames;
  }

  //-------------------- User Data ------------------------------------

  /**
   * @returns {Array.<{name: string, sort: function}>}
   */
  get studentSortOptions() {
    return this._studentSortOptions;
  }

  /**
   * @returns {{name: string, sort: function}}
   */
  get studentSort() {
    return this._studentSort;
  }

  /**
   * @param value {{name: string, sort: Function}}
   */
  set studentSort(value) {
    this._studentSort = value;
    this.sortStudents();
    this._raiseUpdated(SessionDataCodes.STUDENT_SORT);
  }

  /**
   * Sorts the students
   */
  sortStudents() {
    this._sortedStudents = this.filterStudents([
      ...this.students.values(),
    ]).sort(this.studentSort.sort);
    this._virtualRepeatSortedStudents.sortedStudents = this._sortedStudents;
  }

  /**
   * Returns an array of sorted, but unfiltered students
   * @return {User[]}
   */
  get sortedAndUnfilteredStudents() {
    return [...this.students.values()].sort(this.studentSort.sort);
  }

  /**
   * @return {Debouncer}
   */
  get studentSortDebounce() {
    return this._studentSortDebounce;
  }

  /**
   * Filters students by online status when enabled
   * @param students {User[]}
   * @return {User[]}
   */
  filterStudents(students) {
    if (!this.hideOfflineStudents && !this.hideUnstartedAssignmentWorks) {
      return students;
    } else {
      return students.filter((student) => {
        if (this.hideOfflineStudents && this.hideUnstartedAssignmentWorks) {
          return (
            this.studentIsOnline(student.id) &&
            this.studentHasStarted(student.id)
          );
        } else if (this.hideOfflineStudents) {
          return this.studentIsOnline(student.id);
        } else if (this.hideUnstartedAssignmentWorks) {
          return this.studentHasStarted(student.id);
        }
      });
    }
  }

  get filteredStudentsCount() {
    if (this.students) {
      return this.numOfStudents - this._sortedStudents.length;
    } else {
      return undefined;
    }
  }

  get showClassCodeHelpText() {
    return this.filteredStudentsCount <= 2 && this.numOfStudents < 4;
  }

  get showFeedbackHelpText() {
    return this.filteredStudentsCount <= 2 && this.numOfStudents >= 4;
  }

  get showStudentsAreHiddenHelpText() {
    return this.filteredStudentsCount > 2;
  }

  showHiddenStudents() {
    this.hideOfflineStudents = false;
    this.hideUnstartedAssignmentWorks = false;
  }

  configureSortFromStorage() {
    let configuredSort = this.studentSortOptions.find(
      (x) => x.name === this._storageService.sessionSort,
    );
    if (configuredSort) {
      this.studentSort = configuredSort;
    }
  }

  /**
   * @param userId {string}
   * @returns {User}
   */
  student(userId) {
    return this._students.get(userId);
  }

  /**
   * @returns {Map.<string, User>}
   */
  get students() {
    return this._students;
  }

  /**
   * @returns {Array.<User>}
   */
  get sortedStudents() {
    return this._sortedStudents;
  }

  /**
   * HACK: Makes virtualization for Session Work view
   * on Firefox and Safari work properly :(
   *
   * Special container for md-virtual-repeat md-on-demand.
   * @returns {VirtualRepeatStudents}
   */
  get virtualRepeatSortedStudents() {
    return this._virtualRepeatSortedStudents;
  }
  /**
   * @param userId {string}
   * @returns {UserStatusNotification}
   */
  studentStatus(userId) {
    return this._studentStatuses.get(userId);
  }

  /**
   * @param userId {string}
   * @returns {boolean}
   */
  studentIsOnline(userId) {
    let userStatus = this.studentStatus(userId);
    return (
      userStatus &&
      userStatus.status &&
      userStatus.status.online &&
      userStatus.status.assignmentId === this.assignment.id
    );
  }

  /**
   * @param userId
   * @return {boolean}
   */
  studentHasStarted(userId) {
    let studentWork = this.workForStudent(userId);
    return angular.isDefined(studentWork) && !!studentWork.hasStarted;
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @return {boolean}
   */
  studentHasStartedQuestion(userId, questionId) {
    let studentWork = this.workForStudent(userId);
    let studentWorkQuestion =
      angular.isDefined(studentWork) && studentWork.questionForId(questionId);

    return studentWorkQuestion && !!studentWorkQuestion.startedAt;
  }

  /**
   *
   * @param userId {string}
   * @returns {boolean}
   */
  studentIsHelpingPeer(userId) {
    let userStatus = this.studentStatus(userId);
    return (
      this.studentIsOnline(userId) &&
      userStatus.status.activity === StatusActivities.HELPING
    );
  }

  /**
   * @param userId {string}
   * @returns {string}
   */
  studentLastSeen(userId) {
    let userStatus = this.studentStatus(userId);
    if (userStatus && userStatus.status) {
      const isOnline = this.studentIsOnline(userId);
      return isOnline
        ? 'Currently Online'
        : `Last seen ${this.formatTime(userStatus.status.time)}`;
    } else {
      return '';
    }
  }

  /**
   * @param time {moment}
   * @returns {string}
   */
  formatTime(time) {
    return moment().to(time);
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {boolean}
   */
  studentIsCurrentLocation(userId, questionId) {
    let userStatus = this.studentStatus(userId);
    return (
      this.studentIsOnline(userId) &&
      userStatus.status &&
      userStatus.status.questionId === questionId &&
      userStatus.status.activity === StatusActivities.WORKING
    );
  }

  /**
   * @returns {int}
   */
  get studentLoggedInCount() {
    let ct = 0;
    if (this._students) {
      for (let id of this._students.keys()) {
        if (this.studentIsOnline(id)) {
          ct++;
        }
      }
    }
    return ct;
  }

  //--------------------- Help Request Data ---------------------------

  /**
   * @returns {HelpRequestSet}
   */
  get allHelpRequests() {
    return this._allHelpRequests;
  }

  /**
   * @returns {HelpRequestSet}
   */
  get openHelpRequests() {
    return this._openHelpRequests;
  }

  /**
   * @returns {boolean}
   */
  get hasOpenHelpRequests() {
    return this._openHelpRequests && this._openHelpRequests.size > 0;
  }

  /**
   * @returns {HelpRequestSet}
   */
  get assignmentHelpRequests() {
    return this._assignmentHelpRequests;
  }

  /**
   * @param rosterId
   * @returns {HelpRequestSet}
   */
  assignmentHelpRequestsForRoster(rosterId) {
    return (
      this._assignmentHelpRequestsByRoster &&
      this._assignmentHelpRequestsByRoster.get(rosterId)
    );
  }

  /**
   * @returns {HelpInboxMetadata}
   */
  get helpInboxMetadata() {
    return this._helpInboxMetadata;
  }

  /**
   * @param helpeeId {string}
   * @param questionId {string}
   * @returns {HelpRequest}
   */
  findOpenHelpRequest(helpeeId, questionId) {
    return this.openHelpRequests.get(
      HelpRequestSet._INDEX_HELPEE_QUESTION(helpeeId, questionId),
    );
  }

  /**
   * @param helpeeId {string}
   * @param questionId {string}
   * @returns {boolean}
   */
  hasOpenHelpRequestOrHelpers(helpeeId, questionId) {
    return (
      this.studentHasPeerHelpersForQuestion(helpeeId, questionId) ||
      this.hasOpenHelpRequest(helpeeId, questionId)
    );
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {string|undefined}
   */
  findOpenHelpRequestType(userId, questionId) {
    if (this.studentHasActiveHelpersForQuestion(userId, questionId)) {
      return 'active-helper';
    }

    let request = this.findOpenHelpRequest(userId, questionId);
    return request ? request.requestType : '';
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {boolean}
   */
  hasOpenHelpRequest(userId, questionId) {
    return !!this.findOpenHelpRequest(userId, questionId);
  }

  /**
   * @param userId {string}
   * @returns {Array.<HelpRequest>}
   */
  studentHelpHistory(userId) {
    return this._studentHelpRequestHistory.get(userId).requests;
  }

  /**
   * @param userId {string}
   * @returns {Array.<HelpRequest>}
   */
  studentHelpHistorySortedChronologically(userId) {
    return this.studentHelpHistory(userId).sort(
      (currentRequest, nextRequest) => {
        if (currentRequest.resolved.isSame(nextRequest.resolved)) {
          return 0;
        }
        return currentRequest.resolved.isBefore(nextRequest.resolved) ? 1 : -1;
      },
    );
  }

  /**
   * @param userId {string}
   * @returns {boolean}
   */
  studentHasHelpHistory(userId) {
    let history = this.studentHelpHistory(userId);
    return history && history.length > 0;
  }

  /**
   * Lowers any raised hands for student on a question
   * @param studentId {string}
   * @param workQuestion {AssignmentWorkQuestion}
   * @param teacher {User}
   */
  checkForHands(studentId, workQuestion, teacher) {
    let request = this.findOpenHelpRequest(studentId, workQuestion.id);

    if (request && (request.isCheck || request.isHelp)) {
      this._helpRequestService.lowerHand(request, workQuestion, teacher);
    }
  }

  //---------------------- Active Helpers ------------------------------

  /**
   * @returns {Map.<string, QuestionHelpers>}
   */
  get activeHelpers() {
    return this._activeHelpers;
  }

  /**
   * @param userId
   * @returns {QuestionHelpers}
   */
  activeHelpersForStudent(userId) {
    return this._activeHelpers.get(userId);
  }

  /**
   * @param userId {string}
   * @returns {boolean}
   */
  studentHasActiveHelpers(userId) {
    return this.activeHelpersForStudent(userId).hasHelpers;
  }

  /**
   * @param userId
   * @param questionId
   * @returns {boolean}
   */
  studentHasActiveHelpersForQuestion(userId, questionId) {
    let activeHelpers = this.activeHelpersForStudent(userId);
    let questionHelpers = activeHelpers && activeHelpers.helpers[questionId];
    return questionHelpers && questionHelpers.list.length > 0;
  }

  /**
   * @param userId
   * @param questionId
   * @returns {Helpers|boolean}
   */
  studentHasPeerHelpersForQuestion(userId, questionId) {
    const activeHelpers = this.activeHelpersForStudent(userId);
    const questionHelpers = activeHelpers && activeHelpers.helpers[questionId];

    return (
      questionHelpers &&
      questionHelpers.list.filter((x) => x !== activeHelpers.selfHelper)
        .length > 0
    );
  }

  /**
   * @param userId {string}
   * @returns {Object.<string, Helpers>}
   */
  studentActiveHelpers(userId) {
    const activeHelpers = this.activeHelpersForStudent(userId);
    if (this.studentIsHelpingPeer(userId)) {
      const user = this.student(userId);
      const status = this.studentStatus(userId).status;
      activeHelpers.selfHelper = new Helper(
        user.id,
        user.name,
        UserRoles.STUDENT,
        status.helpeeId,
        status.assignmentId,
        status.questionId,
        status.time.valueOf(),
      );
    } else {
      activeHelpers.selfHelper = null;
    }

    return activeHelpers.helpers;
  }

  /**
   * @param userId {string}
   * @returns {boolean}
   */
  studentHasActiveHelpOrHistory(userId) {
    return (
      this.studentHasHelpHistory(userId) || this.studentHasActiveHelpers(userId)
    );
  }

  /**
   * @param userId {string}
   * @return {number}
   */
  studentActiveHelpOrHistoryCount(userId) {
    let helpHistory = this.studentHelpHistory(userId);
    let helpHistoryCount = helpHistory ? helpHistory.length : 0;
    let helpers = this.activeHelpersForStudent(userId);
    return helpHistoryCount + helpers.helpersHelpCount;
  }

  //---------------------- Stop Students -----------------------------

  /**
   * @return {SlideForeground|undefined}
   */
  get placingStop() {
    return this._placingStop;
  }

  /**
   * @param value {SlideForeground}
   */
  set placingStop(value) {
    this._placingStop = value;
  }

  /**
   * @param work {AssignmentWork}
   * @param studentId {string}
   * @param questionId {string}
   * @param stop {SlideForeground}
   */
  placeStop(work, studentId, questionId, stop) {
    let workQuestion = work.questionForId(questionId);

    let slideForeground = new SlideForeground(
      this._firebaseService.newId(),
      stop.metadata,
      stop.hex,
      stop.url,
    );

    if (!workQuestion.startedAt) {
      workQuestion.startedAt = moment();
      this._assignmentWorkService.updateQuestion(
        work.assignmentId,
        work.id,
        workQuestion,
      );
    }

    workQuestion.saveElement(slideForeground);
  }

  /**
   * @param work {AssignmentWork}
   * @param questionId {string}
   * @param stop {SlideBackground}
   */
  removeStop(work, questionId, stop) {
    let workQuestion = work.questionForId(questionId);

    workQuestion.removeElement(stop);
  }

  //---------------------- Bulk Updates -----------------------------

  /**
   * @return {string}
   */
  get bulkUpdateOption() {
    return this._bulkUpdateOption;
  }

  /**
   * @param value {string}
   */
  set bulkUpdateOption(value) {
    this._bulkUpdateOption = value;
    this._raiseUpdated(SessionDataCodes.BULK_UPDATE, {
      type: value,
    });
  }

  //---------------------- Stickers and Grading -----------------------------

  /**
   * @return {UserSticker[]}
   */
  get stickers() {
    return this._stickers;
  }

  /**
   * @return {UserSticker|undefined}
   */
  get placingSticker() {
    return this._placingSticker;
  }

  /**
   * @param value {UserSticker}
   */
  set placingSticker(value) {
    this._placingSticker = value;
  }

  /**
   * @param work {AssignmentWork}
   * @param studentId {string}
   * @param questionId {string}
   * @param sticker {UserSticker}
   * @param [source] {string}
   */
  placeSticker(
    work,
    studentId,
    questionId,
    sticker,
    source = StickerSources.SESSION_QUESTION,
  ) {
    let workQuestion = work.questionForId(questionId);

    this.$q
      .all([
        this.createSticker(this.teacher.id, workQuestion),
        this.addScore(work.assignmentId, work.id, workQuestion, sticker.score),
      ])
      .then(() => {
        this.checkForHands(studentId, workQuestion, this.teacher);
        this._feedbackService.give(
          studentId,
          work,
          questionId,
          this.teacher.id,
          this.teacher.name,
        );
        this._analyticsService.stickerPlaced(
          source,
          sticker.imageType,
          sticker.imageName,
        );
      });
  }

  /**
   * @param assignmentId {string}
   * @param assignmentWorkId {string}
   * @param workQuestion {AssignmentWorkQuestion}
   * @param score {number}
   * @return {boolean|Promise}
   */
  addScore(assignmentId, assignmentWorkId, workQuestion, score) {
    let newScore = workQuestion.addScore(score);
    return (
      newScore !== workQuestion.points &&
      this._assignmentWorkService.updateQuestionScore(
        newScore,
        workQuestion,
        assignmentId,
        assignmentWorkId,
      )
    );
  }

  /**
   * @param work {AssignmentWork}
   * @param studentId {string}
   * @param questionId {string}
   * @param newScore {number}
   * @return {Promise<any | never>}
   */
  updateScore(work, studentId, questionId, newScore) {
    let workQuestion = work.questionForId(questionId);
    return this._assignmentWorkService
      .updateQuestionScore(newScore, workQuestion, work.assignmentId, work.id)
      .then(() => {
        this.checkForHands(studentId, workQuestion, this.teacher);
        this._feedbackService.give(
          studentId,
          work.id,
          questionId,
          this.teacher.id,
          this.teacher.name,
        );
      });
  }

  createSticker(teacherId, workQuestion) {
    let position = this._getPlacementPosition(
      workQuestion.elements,
      Sticker.InitialStickerPosition,
    );

    let sticker = new Sticker(
      this._firebaseService.newId(),
      new ElementMetadata(
        teacherId,
        UserRoles.TEACHER,
        ElementIntents.FEEDBACK,
      ),
      position,
      undefined,
      this.placingSticker.text,
      this.placingSticker.imageUrl,
      this.placingSticker.score,
      false,
    );

    return workQuestion.saveElement(sticker);
  }

  /**
   * @param elements {FirebaseCollection.<Element>}
   * @param position {Point}
   * @return {Point}
   */
  _getPlacementPosition(elements, position) {
    if (this._positionIsClear(elements, position)) {
      return position;
    } else {
      let newPosition = position.plus(new Point(0, 50));
      return this._getPlacementPosition(elements, newPosition);
    }
  }

  /**
   * @param elements {FirebaseCollection.<Element>}
   * @param position {Point}
   * @return {boolean}
   */
  _positionIsClear(elements, position) {
    return !elements.some((element) => {
      return element.type === Sticker.type && element.location.y === position.y;
    });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @return {object}
   */
  studentQuestionAnswer(userId, questionId) {
    if (!this.hasWorkForStudent(userId)) {
      return undefined;
    }

    /** @type {AssignmentWork} */
    let assignmentWorkForStudent = this.workForStudent(userId);
    let question = assignmentWorkForStudent.questionForId(questionId);

    return question && question.answer;
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @return {string}
   */
  studentQuestionAnswerValue(userId, questionId) {
    let answer = this.studentQuestionAnswer(userId, questionId);
    return answer && answer.value;
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @return {string}
   */
  studentQuestionAnswerClass(userId, questionId) {
    const answer = this.studentQuestionAnswer(userId, questionId);
    const correct = answer && answer.correct;
    const value = answer && answer.value;
    const isScientificAnswer =
      answer &&
      answer.format &&
      answer.format === FitbAnswerTypes.SCIENTIFIC.value;
    let className = '';

    if (!answer || !value) {
      className = 'hide';
    } else if (correct === true) {
      className = 'correct';
    } else if (correct === false) {
      className = 'incorrect';
    } else {
      className = 'no-correct';
    }
    return `${className} ${isScientificAnswer ? 'scientific' : ''}`;
  }

  _displayMathAnswer(userId, questionId, answerValue) {
    const answerElement = document.querySelectorAll(
      `#question-answer-${userId}-${questionId} > span`,
    )[0];
    if (answerElement) {
      answerElement.innerHTML = `$$ ${answerValue} $$`;
      renderMathInElement(answerElement);
    }
  }

  displayStudentAnswer(userId, questionId) {
    let answer = this.studentQuestionAnswer(userId, questionId);
    const answerValue = (answer && answer.value) || '';
    if (
      answer &&
      answer.format &&
      answer.format === FitbAnswerTypes.SCIENTIFIC.value
    ) {
      this._displayMathAnswer(userId, questionId, answerValue);
    } else {
      return this.studentQuestionAnswerValue(userId, questionId);
    }
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {number}
   */
  studentQuestionScore(userId, questionId) {
    if (!this.hasWorkForStudent(userId)) {
      return undefined;
    }

    /** @type {AssignmentWork} */
    let assignmentWorkForStudent = this.workForStudent(userId);
    let questionWork = assignmentWorkForStudent.questionForId(questionId);
    let studentScore = questionWork && questionWork.points;

    return angular.isNumber(studentScore) ? studentScore : undefined;
  }

  /**
   * Displays student's score on a specific question in readable format
   *
   * @param userId {string}
   * @param questionId {string}
   * @return {string|number}
   */
  studentQuestionScoreDisplay(userId, questionId) {
    let studentScore = this.studentQuestionScore(userId, questionId);
    return angular.isNumber(studentScore) ? studentScore : '';
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {string}
   */
  studentQuestionScoreBackgroundColor(userId, questionId) {
    let score = this.studentQuestionScore(userId, questionId);
    let total = this.assignment.questionForId(questionId).points;

    if (score === 0 && total === 0) {
      return HexColors.CK_HEADER_GREY;
    }

    return GradeUtils.colorForScore(score / total);
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {string}
   */
  studentQuestionScoreColor(userId, questionId) {
    let score = this.studentQuestionScore(userId, questionId);
    let total = this.assignment.questionForId(questionId).points;

    if (score === 0 && total === 0) {
      return HexColors.CK_ELEMENT_WHITE;
    }

    return HexColors.CK_ELEMENT_BLACK;
  }

  /**
   * @param userId {string}
   * @returns {number}
   */
  studentTotalScore(userId) {
    let questionSet = this.workQuestionSet(userId);
    return questionSet ? questionSet.totalPoints : 0;
  }

  /**
   * @param userId {string}
   * @returns {string}
   */
  studentTotalScoreDisplay(userId) {
    let total = this.studentTotalScore(userId);
    let score = this._assignment.totalPotentialPoints();

    if (total === 0 && score === 0) {
      return '';
    }
    return `${total}/${score} Pts`;
  }

  //---------------------- Assignment Works-----------------------------

  /**
   * @returns {Map.<string, WorkQuestionSet>}
   */
  get workQuestionSets() {
    return this._workQuestionSets;
  }

  /**
   * @param userId {string}
   * @returns {WorkQuestionSet}
   */
  workQuestionSet(userId) {
    return this._workQuestionSets.get(userId);
  }

  /**
   * Map of works by work id
   * @returns {Map.<string, AssignmentWork>}
   */
  get works() {
    return this._works;
  }

  /**
   * @param id
   * @returns {AssignmentWork|undefined}
   */
  workById(id) {
    return this._works.get(id);
  }

  /**
   * Map of works by user id
   * @return {Map.<string, AssignmentWork>}
   */
  get worksByStudent() {
    return this._worksByStudent;
  }

  /**
   * @param userId
   * @returns {AssignmentWork|undefined}
   */
  workForStudent(userId) {
    return this._worksByStudent.get(userId);
  }

  /**
   * @param userId {string}
   * @returns {boolean}
   */
  hasWorkForStudent(userId) {
    return this._worksByStudent.has(userId);
  }

  /**
   * @param user {User}
   * @param questionId {string}
   * @returns {string}
   */
  formatTooltipMessageForQuestionWork(user, questionId) {
    return `Click to see ${user.name}'s work on slide ${this.assignment.questionNumberForId(questionId)}`;
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {string}
   * @private
   */
  _assignmentSheetConfigIndex(userId, questionId) {
    return `${userId}|${questionId}`;
  }

  /**
   * @param assignmentId {string}
   * @param questionId {string}
   * @returns {string}
   * @private
   */
  _assignmentThumbnailConfigIndex(assignmentId, questionId) {
    return `${assignmentId}|${questionId}`;
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {string}
   * @private
   */
  _assignmentWorkThumbnailConfigIndex(userId, questionId) {
    return `${userId}|${questionId}`;
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @returns {AssignmentSheetMetadata|null}
   */
  assignmentSheetConfig(userId, questionId) {
    if (
      !this.isComplete ||
      (this.hideUnstartedThumbnails &&
        !this.studentHasStartedQuestion(userId, questionId))
    ) {
      return null;
    }

    const index = this._assignmentSheetConfigIndex(userId, questionId);
    if (!this._assignmentSheetConfigs.has(index)) {
      this._assignmentSheetConfigs.set(
        index,
        new AssignmentSheetMetadata(
          this.workForStudent(userId) || this.assignment,
          questionId,
          true,
          true,
          null,
          UserRoles.TEACHER,
          ElementIntents.FEEDBACK,
          null,
          0.25,
        ),
      );
    }

    return this._assignmentSheetConfigs.get(index);
  }

  /**
   * Returns a config for the bottom layer, which shows a particular slide in the assignment
   * @param userId {string}
   * @param questionId {string}
   * @returns {AssignmentThumbnailMetadata|null}
   */
  assignmentThumbnailConfig(userId, questionId) {
    if (
      !this.isComplete ||
      (this.hideUnstartedThumbnails &&
        !this.studentHasStartedQuestion(userId, questionId))
    ) {
      return null;
    }

    const index = this._assignmentThumbnailConfigIndex(
      this.assignment.id,
      questionId,
    );
    if (!this._assignmentThumbnailConfigs.has(index)) {
      this._assignmentThumbnailConfigs.set(
        index,
        new AssignmentThumbnailMetadata(
          this.assignment,
          questionId,
          null,
          UserRoles.TEACHER,
          ElementIntents.FEEDBACK,
          0.25,
          1,
          AssignmentThumbnailMetadata.NonManipulativeParents,
        ),
      );
    }

    return this._assignmentThumbnailConfigs.get(index);
  }

  /**
   * Returns the config for the top layer, which shows either the assignment work or an assignment with the manipulative parents
   * @param userId {string}
   * @param questionId {string}
   * @returns {AssignmentThumbnailMetadata|null}
   */
  assignmentWorkThumbnailConfig(userId, questionId) {
    if (
      !this.isComplete ||
      (this.hideUnstartedThumbnails &&
        !this.studentHasStartedQuestion(userId, questionId))
    ) {
      return null;
    }

    const work = this.workForStudent(userId);
    const index = this._assignmentWorkThumbnailConfigIndex(userId, questionId);
    if (!this._assignmentWorkThumbnailConfigs.has(index)) {
      let workExistsButStudentHasNotStartedQuestion =
        work && !this.studentHasStartedQuestion(userId, questionId);
      let elementsToShow = work
        ? AssignmentThumbnailMetadata.NonManipulativeParents
        : AssignmentThumbnailMetadata.ManipulativeParents;

      this._assignmentWorkThumbnailConfigs.set(
        index,
        new AssignmentThumbnailMetadata(
          work || this.assignment,
          questionId,
          null,
          UserRoles.TEACHER,
          ElementIntents.FEEDBACK,
          workExistsButStudentHasNotStartedQuestion ? 1 : 0.25,
          workExistsButStudentHasNotStartedQuestion ? 0.25 : 1,
          elementsToShow,
        ),
      );
    }

    return this._assignmentWorkThumbnailConfigs.get(index);
  }

  //---------------------- Pro Features -----------------------------

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._proInfo;
  }

  get features() {
    return this.pro && this.pro.features;
  }

  //---------------------- Session Animations -----------------------------

  makeClassCodePulse() {
    CkAnimations.makeElementPulse('.session .md-button.class-code');
  }

  makeClassCodePulseAfterRegistration() {
    CkAnimations.makeElementPulse('.session .md-button.class-code', true);
  }

  stopClassCodePulseAfterRegistration() {
    CkAnimations.makeElementPulse('.session .md-button.class-code', false);
  }

  makeRosterSelectPulse() {
    CkAnimations.makeElementPulse(
      '.session .select-roster md-select .md-select-value',
    );
  }
}
