'use strict';

export default class SvgCanvas {
  constructor() {}

  static get INITIAL_WIDTH() {
    return 1004;
  }

  static get INITIAL_HEIGHT() {
    return 2008;
  }

  /**
   * @return {Point}
   */
  get absolutePosition() {
    throw new Error('Must implement absolutePosition getter method');
  }

  /**
   * @return {number}
   */
  get inverseScaleFactor() {
    throw new Error('Must implement inverseScaleFactor getter method');
  }

  /**
   * @param position {Point}
   * @returns {Point}
   */
  scale(position) {
    throw new Error('Must implement scale method');
  }

  /**
   * @returns {jQuery}
   */
  get hiddenTextInput() {
    throw new Error('Must implement hiddenTextInput getter method');
  }
}
