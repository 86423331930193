'use strict';

import Codec from './codec';
import Order from '../domain/order';

/**
 * Encodes/Decodes an order
 */
export default class OrderCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {Order}
   * @return {object}
   */
  encode(value) {
    return {
      id: value.id,
      quote: value.quote,
      organization_id: value.organizationId,
      plan: value.planId,
      start: value.start && value.start.toISOString(),
      end: value.end && value.end.toISOString(),
      renewal: value.renewal,
      financial_aid: value.financialAid,
      quantity: value.quantity,
      coupons: value.couponIds,
      status: value.status,
      creator_id: value.creatorId,
      creator_name: value.creatorName,
      creator_email: value.creatorEmail,
      created: value.created && value.created.toISOString(),
      expires: value.expires && value.expires.toISOString(),
      billing_name: value.billingName,
      billing_email: value.billingEmail,
      billing_phone: value.billingPhone,
      point_email: value.pointEmail,
      point_id: value.pointId,
      purchase_order: value.purchaseOrder,
    };
  }

  /**
   * @param value {Object}
   * @return {Order|null}
   */
  decode(value) {
    if (value) {
      return new Order(
        value.id,
        value.quote,
        value.organization_id,
        value.plan,
        value.start,
        value.end,
        value.renewal,
        value.financial_aid,
        value.quantity,
        value.coupons,
        value.status,
        value.creator_id,
        value.creator_name,
        value.creator_email,
        value.created,
        value.expires,
        value.billing_name,
        value.billing_email,
        value.billing_phone,
        value.point_email,
        value.point_id,
        value.purchase_order,
      );
    } else {
      return null;
    }
  }
}
