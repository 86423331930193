'use strict';

import AiAssistantDirectiveTemplate from './ai-assistant-capture.html';
import StaticService from '../../services/static/static.service';

export default function AiAssistantCaptureDirective() {
  'ngInject';

  return {
    restrict: 'E',
    // The output is specified via an `ng-model` attribute. Format is {Blob}
    require: 'ngModel',
    scope: {},
    template: AiAssistantDirectiveTemplate,
    link: AiAssistantDirectiveController.link,
    controller: AiAssistantDirectiveController,
    controllerAs: 'ctrl',
  };
}

/**
 * Dialog to add AI Assisted Text Element
 */
class AiAssistantDirectiveController {
  constructor($scope, environment, HttpService) {
    'ngInject';

    this._ngModel = null;
    this._httpService = HttpService;
    this._environment = environment;
    this._currentState = this.START;
    this._prompt = '';
  }

  /**
   * the link function is run after the constructor for the controller
   * @param scope {$scope}
   * @param element {object}
   * @param attrs {object}
   * @param ngModel {*}
   */
  static link(scope, element, attrs, ngModel) {
    /** @type {AiAssistantDirectiveController} */
    const ctrl = scope.ctrl;
    ctrl.ngModel = ngModel;
  }

  get aiGeneratorPrompt() {
    return this._prompt;
  }

  set aiGeneratorPrompt(value) {
    this._prompt = value;
  }

  /**
   * @param value {Object}
   */
  set ngModel(value) {
    this._ngModel = value;
  }

  /**
   * @param value {Object}
   */
  set ngModelValue(value) {
    this._ngModel.$setViewValue(value);
  }

  /**
   * @returns {string}
   */
  get currentState() {
    return this._currentState;
  }

  /**
   * @param value {string}
   */
  set currentState(value) {
    this._currentState = value;
  }

  /**
   * @returns {string}
   */
  get START() {
    return 'start';
  }

  get ERROR() {
    return 'error';
  }

  /**
   * @returns {string}
   */
  get SAVING() {
    return 'saving';
  }

  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }

  submit() {
    if (!this.isEmptyPrompt()) {
      this.contentToGenerate = this._prompt;
      this._currentState = this.SAVING;

      this._httpService
        .authPost(this._uri('/v1/assistant/completion'), {
          prompt: this.contentToGenerate,
        })
        .then((response) => {
          this.ngModelValue = {
            type: 'textbox',
            element: response.content.choices[0].text.trim(),
          };
          this._currentState = this.START;
        })
        .catch((err) => {
          StaticService.get.$log.error(
            'AI Assistant Disabled:',
            err.status,
            err.code,
            err.message,
          );
          this.ngModelValue = {
            disabled: true,
          };
          this._currentState = this.ERROR;
        });
    }
  }

  isEmptyPrompt() {
    return angular.isUndefined(this._prompt) || this._prompt === '';
  }
}
