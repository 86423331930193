/*global Papa:false, saveAs:false, moment:false, JSZip:false */
'use strict';

import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import moment from 'moment';
import NewContractStudentCodec from '../../model/codec/new-contract-student-codec';
import { Helpers } from '../../model/domain/question-helpers';
import AssignmentWorkQuestion from '../../model/domain/assignment-work-question';
import FillInTheBlankParent from '../../model/ui/elements/fill-in-the-blank-parent';
import FillInTheBlankChild from '../../model/ui/elements/fill-in-the-blank-child';
import MultipleChoiceParent from '../../model/ui/elements/multiple-choice-parent';
import MultipleChoiceChild from '../../model/ui/elements/multiple-choice-child';
import { FileUtil } from '../../model/util/file-util';
import Sorts from '../../model/domain/sorts';

class CSVHeaders {
  static get FirstName() {
    return 'first_name';
  }

  static get LastName() {
    return 'last_name';
  }

  static get Id() {
    return 'id';
  }

  static get Password() {
    return 'password';
  }

  static get All() {
    return [
      CSVHeaders.FirstName,
      CSVHeaders.LastName,
      CSVHeaders.Id,
      CSVHeaders.Password,
    ];
  }
}

export default class CsvService {
  /**
   * @ngInject
   */
  constructor($q) {
    this.$q = $q;

    this._newContractStudentCodec = new NewContractStudentCodec();
  }

  /**
   * @param originalFile {File}
   * @return {Promise.<NewContractStudent[]>}
   */
  extractCSVData(originalFile) {
    return this.$q((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (ev) => {
        let students = this._makeQuotesDumb(ev.target.result);
        students = Papa.parse(students, { header: true }).data.filter(
          (x) => x.id || x.first_name || x.last_name,
        );

        if (
          students[0] &&
          !Object.prototype.hasOwnProperty.call(students[0], CSVHeaders.Id)
        ) {
          let error = new Error(
            'Please include an "id" header in your .CSV file.',
          );
          reject(error);
        }

        students = this._convertHeadersToLowercase(students);

        let duplicates = this._getDuplicates(students);
        if (duplicates.length > 0) {
          let error = new Error(this._createDuplicateError(duplicates));
          reject(error);
        }

        students = students.map((student) =>
          this._newContractStudentCodec.decode(student),
        );

        resolve(students);
      };
      reader.readAsText(originalFile);
    });
  }

  /**
   * @param str {string}
   * @return {string}
   */
  _makeQuotesDumb(str) {
    return str.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
  }

  /**
   * @param [students] {object[]}
   * @return {object[]}
   */
  _convertHeadersToLowercase(students) {
    return students.map((student) => {
      Object.keys(student).forEach((key) => {
        let lowerCaseKey = key.toLowerCase();
        student[lowerCaseKey] = student[key];
      });

      let newStudentObj = {};
      CSVHeaders.All.forEach((header) => {
        newStudentObj[header] = angular.isDefined(student[header])
          ? `${student[header]}`.trim()
          : '';
      });
      return newStudentObj;
    });
  }

  /**
   * @param students {object[]}
   * @return {object[]}
   */
  _getDuplicates(students) {
    let numberOfUsesForEachId = students
      .map((student) => {
        return {
          id: student.id.toLowerCase(),
          count: 1,
        };
      })
      .reduce((a, b) => {
        a[b.id] = (a[b.id] || 0) + b.count;
        return a;
      }, {});

    let result = [];
    angular.forEach(numberOfUsesForEachId, (count, name) => {
      if (count > 1) {
        result.push(name);
      }
    });
    return result;
  }

  /**
   * @param duplicates {object[]}
   * @return {string}
   */
  _createDuplicateError(duplicates) {
    let conjugation = duplicates.length > 1 ? 's are' : ' is';
    let listOfDuplicates = Helpers.formatHelpersList(duplicates);
    return `The following id${conjugation} duplicated in your .CSV file: ${listOfDuplicates}`;
  }

  /**
   * @param students {NewContractStudent[]}
   */
  downloadCsv(students, error = false) {
    let csvFile;
    const usersData = students.map((s) =>
      this._newContractStudentCodec.encode(s),
    );
    const csvData = [Papa.unparse(usersData, { cast: false, header: true })];
    const date = moment().format('YYYY-MM-DD');
    if (error) {
      csvFile = new File(csvData, `students not added ${date}.csv`, {
        type: 'text/csv;charset=utf-8',
      });
    } else {
      csvFile = new File(csvData, `students added ${date}.csv`, {
        type: 'text/csv;charset=utf-8',
      });
    }
    saveAs(csvFile);
  }

  /**
   * @param assignment {Assignment}
   * @param userRosterMaps {{members: Map.<User>, roster: Roster}[]}
   * @param works {AssignmentWork[]}
   */
  exportAnswersByAssignment(assignment, userRosterMaps, works) {
    let fields = ['student', 'first name', 'last name', 'roster'];
    let answerKey = ['ANSWER KEY', '', '', ''];

    return this.$q
      .all(assignment.questions.map((q) => q.allElements))
      .then((elementsByQuestion) => {
        elementsByQuestion.forEach((elements, index) => {
          let questionAnswers = elements.filter(
            (element) =>
              element.type === FillInTheBlankParent.type ||
              element.type === MultipleChoiceParent.type,
          );
          let slideNumber = (index + 1).toString();

          // Creates the headers fields for each element
          if (questionAnswers.length > 1) {
            fields = fields.concat(
              questionAnswers.map((element, index) => {
                let letter = String.fromCharCode(97 + index);
                return `#${slideNumber}${letter}`;
              }),
            );
          } else {
            fields.push(`#${slideNumber}`);
          }

          // Creates the answer key for each element
          if (questionAnswers.length > 0) {
            answerKey = answerKey.concat(
              questionAnswers.map((element) => {
                return element.answerDisplay;
              }),
            );
          } else {
            answerKey.push('');
          }
        });

        let promises = userRosterMaps.map(({ members, roster }) => {
          let membersArray = Array.from(members.values()).sort((a, b) =>
            Sorts.NAME_ASC(a.name, b.name),
          );

          return this.$q.all(
            membersArray.map((member) => {
              let work = works.find(
                (work) =>
                  work.ownerId === member.id && work.rosterId === roster.id,
              );
              return this._createUserRowForAssignmentAnswers(
                member,
                roster,
                assignment,
                work,
              );
            }),
          );
        });

        return this.$q.all(promises);
      })
      .then((userRowsByRoster) => {
        let studentRows = userRowsByRoster.reduce(
          (acc, val) => acc.concat(val),
          [],
        );

        // Adds the answer key as the first row
        studentRows.unshift(answerKey);
        this._downloadExport(
          fields,
          studentRows,
          `${assignment.name}_answers_`,
        );
      });
  }

  /**
   * @param studentAssignmentOverviewItems {StudentOverviewAssignmentItem[]}
   * @param roster {Roster}
   * @param student {User}
   */
  exportAnswersByStudent(studentAssignmentOverviewItems, roster, student) {
    let csvFiles = [];

    return this.$q
      .all(
        studentAssignmentOverviewItems.map((assignmentItem) => {
          return this.getAnswersForSingleAssignment(
            assignmentItem,
            roster,
            student,
            csvFiles,
          ).then((file) => {
            csvFiles.push(file);
          });
        }),
      )
      .then(() => {
        if (csvFiles.length > 1) {
          this.saveAsZip(csvFiles, student);
        } else {
          saveAs(csvFiles[0]);
        }
      });
  }

  getAnswersForSingleAssignment(assignmentItem, roster, student, csvFiles) {
    let answerKey = [`${assignmentItem.assignment.name} ANSWER KEY`, ''];
    let answerRows = [];
    let fields = ['assignment', 'roster'];

    return this.$q
      .all(assignmentItem.assignment.questions.map((q) => q.allElements))
      .then((elementsByQuestion) => {
        elementsByQuestion.forEach((elements, index) => {
          let questionAnswers = elements.filter(
            (element) =>
              element.type === FillInTheBlankParent.type ||
              element.type === MultipleChoiceParent.type,
          );
          let slideNumber = (index + 1).toString();

          // Creates the headers fields for each element
          if (questionAnswers.length > 1) {
            fields = fields.concat(
              questionAnswers.map((element, index) => {
                let letter = String.fromCharCode(97 + index);
                return `#${slideNumber}${letter}`;
              }),
            );
          } else {
            fields.push(`#${slideNumber}`);
          }

          // Creates the answer key for each element
          if (questionAnswers.length > 0) {
            answerKey = answerKey.concat(
              questionAnswers.map((element) => {
                return element.answerDisplay;
              }),
            );
          } else {
            answerKey.push('');
          }
        });
      })
      .then(() => {
        return this._createAssignmentRowForAssignmentAnswers(
          assignmentItem.assignment,
          assignmentItem.assignmentWork &&
            assignmentItem.assignmentWork.assignment,
          roster,
        ).then((answerRow) => {
          answerRows.push(answerKey);
          answerRows.push(answerRow);

          let file = this._createCSVFileForAnswers(
            fields,
            answerRows,
            `${student.name}_${assignmentItem.assignment.name}_answers_`,
          );
          return file;
        });
      });
  }

  /**
   * @param files {File[]}
   * @param student {User}

   */
  saveAsZip(files, student) {
    this.toZip(files).then((zipFile) => {
      saveAs(zipFile, `${student.name} answers`);
    });
  }

  /**
   * @param files {File[]}
   * @return {Promise.<Base64>}
   */
  toZip(files) {
    let zip = new JSZip();

    files.forEach((file) => {
      zip.file(file.name, file, { base64: true });
    });

    return zip.generateAsync({ type: 'blob' });
  }

  /**
   * @param member {User}
   * @param roster {Roster}
   * @param assignment {Assignment}
   * @param work {AssignmentWork}
   */
  _createUserRowForAssignmentAnswers(member, roster, assignment, work) {
    // Get the elements for each question
    let promises = assignment.questions.map((q, i) => {
      let workQuestion = work && work.questionForIndex(i);
      return workQuestion ? workQuestion.allElements : this.$q.resolve([]);
    });

    return this.$q.all(promises).then((workElementsByQuestion) => {
      return [
        member.name || 'no name',
        member.firstName || '--',
        member.lastName || '--',
        roster.name || 'no roster name',
        ...workElementsByQuestion
          .map((workElements) => {
            let workAnswers = workElements.filter(
              (element) =>
                element.type === FillInTheBlankChild.type ||
                element.type === MultipleChoiceChild.type,
            );

            if (workAnswers.length > 0) {
              return workAnswers.map((element) => {
                return element.answerDisplay;
              });
            } else {
              return ['--'];
            }
          })
          .flat(),
      ];
    });
  }

  /**
   * @param assignment {Assignment}
   * @param work {AssignmentWork}
   * @param roster {Roster}
   */
  _createAssignmentRowForAssignmentAnswers(assignment, work, roster) {
    // Get the elements for each question
    let promises = assignment.questions.map((q, i) => {
      let workQuestion = work && work.questionForIndex(i);
      return workQuestion ? workQuestion.allElements : this.$q.resolve([]);
    });

    return this.$q.all(promises).then((workElementsByQuestion) => {
      return [
        assignment.name || 'no name',
        roster.name || 'no roster name',
        ...workElementsByQuestion
          .map((workElements) => {
            let workAnswers = workElements.filter(
              (element) =>
                element.type === FillInTheBlankChild.type ||
                element.type === MultipleChoiceChild.type,
            );

            if (workAnswers.length > 0) {
              return workAnswers.map((element) => {
                return element.answerDisplay;
              });
            } else {
              return ['--'];
            }
          })
          .flat(),
      ];
    });
  }

  /**
   * @param assignment {Assignment}
   * @param userRosterMaps {{members: Map.<User>, roster: Roster}[]}
   * @param works {AssignmentWork[]}
   */
  exportGradesByAssignment(assignment, userRosterMaps, works) {
    let fields = [
      'student',
      'email',
      'username',
      'first name',
      'last name',
      'roster',
      'total',
      'possible',
      'percentage',
      ...assignment.questions.map((q, index) => {
        let slideNumber = (index + 1).toString();
        return `#${slideNumber} - ${q.points} pts`;
      }),
    ];

    let userRowsByRoster = userRosterMaps.map(({ members, roster }) => {
      let membersArray = Array.from(members.values()).sort((a, b) =>
        Sorts.NAME_ASC(a.name, b.name),
      );

      return membersArray.map((member) => {
        let work = works.find(
          (work) => work.ownerId === member.id && work.rosterId === roster.id,
        );
        return this._createUserRowForAssignmentGrade(
          member,
          roster,
          assignment,
          work,
        );
      });
    });

    let studentRows = userRowsByRoster.reduce(
      (acc, val) => acc.concat(val),
      [],
    );

    this._downloadExport(fields, studentRows, `${assignment.name}_grades_`);
  }

  /**
   * @param studentAssignmentOverviewItems {StudentOverviewAssignmentItem[]}
   * @param roster {Roster}
   * @param student {User}
   */
  exportGradesByStudent(studentAssignmentOverviewItems, roster, student) {
    let fields = ['assignment', 'roster', 'total', 'possible', 'percentage'];

    let assignmentRows = studentAssignmentOverviewItems.map((assignmentItem) =>
      this._createAssignmentRowForGrade(
        assignmentItem.assignment,
        assignmentItem.assignmentWork &&
          assignmentItem.assignmentWork.assignment,
        roster,
      ),
    );

    this._downloadExport(fields, assignmentRows, `${student.name}_grades_`);
  }

  /**
   * @param assignment {Assignment}
   * @param work {AssignmentWork}
   * @param roster {Roster}
   */
  _createAssignmentRowForGrade(assignment, work, roster) {
    let totalPoints = work ? work.totalPoints() : 0;
    let totalPotentialPoints = assignment.totalPotentialPoints();
    let percentage = totalPoints / totalPotentialPoints;

    return [
      assignment.name || 'no name',
      roster.name || 'no roster name',
      angular.isNumber(totalPoints) ? totalPoints : '--',
      angular.isNumber(totalPotentialPoints) ? totalPotentialPoints : '--',
      this._formatPercentage(percentage),
    ];
  }

  /**
   * @param member {User}
   * @param roster {Roster}
   * @param assignment {Assignment}
   * @param work {AssignmentWork}
   */
  _createUserRowForAssignmentGrade(member, roster, assignment, work) {
    let totalPoints = work ? work.totalPoints() : 0;
    let totalPotentialPoints = assignment.totalPotentialPoints();
    let percentage = totalPoints / totalPotentialPoints;

    let workQuestions = assignment.questions.map((q, i) => {
      return work && work.questionForIndex(i);
    });

    return [
      member.name || 'no name',
      member.email || '--',
      member.username || '--',
      member.firstName || '--',
      member.lastName || '--',
      roster.name || 'no roster name',
      angular.isNumber(totalPoints) ? totalPoints : '--',
      angular.isNumber(totalPotentialPoints) ? totalPotentialPoints : '--',
      this._formatPercentage(percentage),
      ...workQuestions.map((q) => {
        return !q || q.points === AssignmentWorkQuestion.UNGRADED
          ? '--'
          : q.points;
      }),
    ];
  }

  /**
   * @param percentage {number}
   * @return {number|string}
   */
  _formatPercentage(percentage) {
    if (isNaN(percentage)) {
      return '--';
    } else {
      return Math.round(percentage * 10000) / 100;
    }
  }

  /**
   * @param assignmentAndWorks {{assignment: Assignment, works: AssignmentWork[]}[]}
   * @param usersOnRoster {User[]}
   * @param rosterName {string}
   */
  exportGradesByRoster(assignmentAndWorks, usersOnRoster, rosterName) {
    let fields = [
      'student name',
      'email',
      'username',
      ...assignmentAndWorks.map(({ assignment }) => {
        return assignment.name;
      }),
    ];

    let firstRow = [
      'assignment total',
      ...assignmentAndWorks.map(({ assignment }) => {
        return assignment.totalPotentialPoints();
      }),
    ];

    let userRows = usersOnRoster.map((user) => {
      return this._createUserRow(user, assignmentAndWorks);
    });

    this._downloadExport(
      fields,
      [firstRow, ...userRows],
      `${rosterName}_grades_`,
    );
  }

  /**
   * @param user {User}
   * @param assignmentAndWorks {{assignment: Assignment, works: AssignmentWork[]}[]}
   * @return {(string|number)[]}}
   */
  _createUserRow(user, assignmentAndWorks) {
    return [
      user.name,
      user.email,
      user.username,
      ...assignmentAndWorks.map(({ works }) => {
        let userWork = this._workForUser(works, user.id);
        return angular.isDefined(userWork) ? userWork.totalPoints() : '';
      }),
    ];
  }

  /**
   * @param works {AssignmentWork[]}
   * @param id {string}
   * @return {AssignmentWork|undefined}
   */
  _workForUser(works, id) {
    return works.find((work) => work.ownerId === id);
  }

  /**
   * @param headers {string[]}
   * @param rows {{assignmentName: number}[]}
   * @param fileName {string}
   */
  _downloadExport(headers, rows, fileName) {
    let csvData = this._unparse(headers, rows);
    let csvFile = this._createCsvFile(
      csvData,
      FileUtil.removeForbiddenCharacters(fileName),
    );
    saveAs(csvFile);
  }

  _createCSVFileForAnswers(headers, rows, fileName) {
    let csvData = this._unparse(headers, rows);
    return this._createCsvFile(
      csvData,
      FileUtil.removeForbiddenCharacters(fileName),
    );
  }

  /**
   * @param headers {string[]}
   * @param {object[]} rows to add to the .csv
   * @return {string}
   */
  _unparse(headers, rows) {
    return Papa.unparse({
      fields: headers,
      data: rows,
    });
  }

  /**
   * @param csvData {string}
   * @param label {string}
   * @return {File}
   */
  _createCsvFile(csvData, label) {
    let date = moment().format('YYYY-MM-DD');
    return new File([csvData], `${label}${date}.csv`, {
      type: 'text/csv;charset=utf-8',
    });
  }
}
