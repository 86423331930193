'use strict';

import moment from 'moment';
import StaticService from '../../services/static/static.service.js';

/**
 * Class encapsulates debouncing logic
 */
export default class Debouncer {
  /**
   *
   * @param  {number} minDelayMs      number of milliseconds after a call to tick() that the debouncer will wait to apply a change
   * @param  {number} maxDelayMs      number of milliseconds before a pending change must be applied
   * @param  {function} applyFunc     the function to apply
   * @param  {$scope} [$scope]          $scope object whose $destroy event we want to listen to
   * @param  {$window} [$window]       optional. if provided, will pop a dialog up before unload
   */
  constructor(minDelayMs, maxDelayMs, applyFunc, $scope, $window) {
    this._minDelayMs = minDelayMs;
    this._maxDelayMs = maxDelayMs;
    this._applyFunc = applyFunc;

    this._transientEnd = 0;
    this._changeDebounceTimer = undefined;
    this._lastEv = null;
    this._valid = true;

    /** @type {window} */
    this.$window = $window;
    this.$scope = $scope;

    if ($scope) {
      $scope.$on('$destroy', () => this.destroy());
    }
    if ($window) {
      // $timeout
      this._anonBeforeUnload = (event) => this._onBeforeUnload(event);
      $window.onbeforeunload.add(this._anonBeforeUnload);
    }
  }

  /**
   * Indicates whether there is a pending call to the apply function
   * @returns {boolean}
   */
  get pending() {
    return !!this._changeDebounceTimer;
  }

  /**
   * Trigger the start of a debounced event
   * @param ev {*} event supplied to the apply function
   */
  tick(ev) {
    if (this._changeDebounceTimer) {
      StaticService.get.$timeout.cancel(this._changeDebounceTimer);
    } else {
      this._transientEnd = moment().valueOf() + this._maxDelayMs;
    }

    let delay = Math.max(
      0,
      Math.min(this._minDelayMs, this._transientEnd - moment().valueOf()),
    );
    this._lastEv = ev;
    this._changeDebounceTimer = StaticService.get.$timeout(
      () => this.flush(),
      delay,
    );
  }

  /**
   * Flushes any pending event
   */
  flush() {
    if (this._changeDebounceTimer) {
      if (this._valid) {
        let ev = this._lastEv;
        this.cancel();
        this._applyFunc(ev);
      } else {
        this.tick(this._lastEv);
      }
    }
  }

  /**
   * Cancels any pending event
   */
  cancel() {
    StaticService.get.$timeout.cancel(this._changeDebounceTimer);
    this._changeDebounceTimer = undefined;
    this._lastEv = undefined;
  }

  /**
   *
   * @param event {object}
   * @returns {string}
   * @private
   */
  _onBeforeUnload(event) {
    let isPending = this.pending;

    this.flush();

    if (isPending) {
      let message =
        'There are unsaved changes on the page. If you leave you may lose those unsaved changes';
      event.message = message;
      return message;
    }
  }

  destroy() {
    if (this.$window) {
      this.$window.onbeforeunload.remove(this._anonBeforeUnload);
    }
    this.flush();
  }

  set valid(value) {
    this._valid = value;
  }

  get valid() {
    return this._valid;
  }
}
