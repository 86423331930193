'use strict';

import Control from '../control';

export default class TextboxSelection extends Control {
  /**
   * @param target {TextboxBase}
   * @param [info] {SelectionInfo}
   */
  constructor(target, info) {
    super('selection', 'selection');

    this._target = target;
    this._info = info;
  }

  createElement(snap) {
    return snap.group();
  }

  update(element) {
    let rects = element.selectAll('rect').items;

    if (this._info) {
      for (let i = 0; i < this._info.boxes.length; i++) {
        if (rects.length <= i) {
          rects.push(element.rect(0, 0, 0, 0));
        }

        rects[i].attr({
          x: this._info.boxes[i].location.x + this._target.offset.x,
          y: this._info.boxes[i].location.y + this._target.offset.y,
          width: this._info.boxes[i].width,
          height: this._info.boxes[i].height,
          fill: 'rgba(0, 128, 255, .2)',
          visibility: this.visibility,
        });
      }

      for (let i = this._info.boxes.length; i < rects.length; i++) {
        rects[i].remove();
      }
    }
  }

  /**
   * @returns {SelectionInfo}
   */
  get info() {
    return this._info;
  }

  /**
   * @param value {SelectionInfo}
   */
  set info(value) {
    this._info = value;
    this.tryUpdate();
  }
}

export class SelectionInfo {
  /**
   * @param start {CursorInfo}
   * @param end {CursorInfo}
   * @param startIndex {number}
   * @param endIndex {number}
   * @param boxes {Array.<Rect>}
   */
  constructor(start, end, startIndex, endIndex, boxes, cursor) {
    this.start = start;
    this.end = end;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.boxes = boxes;
    this.cursor = cursor;
  }

  get hasSelectedText() {
    return this.startIndex !== this.endIndex;
  }
}
