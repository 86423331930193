'use strict';

import IncentiveAssignmentLimitV1Controller from '../../components/incentive-assignment-limit-v1-dialog/incentive-assignment-limit-v1-dialog.controller';
import { UserRoles } from '../../model/domain/user';
import moment from 'moment';

export default class ReferController {
  constructor(
    $state,
    $rootScope,
    BreadcrumbService,
    AuthService,
    AssignmentService,
    $mdDialog,
    CacheService,
    $q,
    AnalyticsService,
    StorageService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$rootScope = $rootScope;
    this._breadcrumbService = BreadcrumbService;
    this._authService = AuthService;
    this._assignmentService = AssignmentService;
    this.$mdDialog = $mdDialog;
    this._cacheService = CacheService;
    this.$q = $q;
    this._analyticsService = AnalyticsService;
    this._storageService = StorageService;

    this.isIncentiveDialogVisible = false;
    this.isTeacher =
      this._authService.authData &&
      this._authService.authData.hasRole(UserRoles.TEACHER);

    // Check if user is a teacher, if not redirect to home
    if (!this.isTeacher) {
      this._breadcrumbService.go('root.account.home');
      return;
    }

    // Track page view
    this._analyticsService.sendEvent({
      eventTag: 'referPage_viewed',
      properties: {
        location: 'refer',
      },
    });
  }

  /**
   * Initialize the controller
   */
  $onInit() {
    if (!this.isTeacher) {
      return;
    }

    this.$q
      .all({
        user: this._cacheService.getUser(),
        userSchools: this._cacheService.getSchools(false),
      })
      .then(({ user, userSchools }) => {
        if (userSchools.length === 0) {
          this.closeDialog();
        } else {
          this._storageService.lastSeenIncentiveDialog = moment();

          IncentiveAssignmentLimitV1Controller.show(
            'referPage',
            this.$mdDialog,
            user,
            userSchools,
          ).finally(() => {
            this.closeDialog();
          });
        }
      });
  }

  /**
   * Close the dialog and redirect to assignments
   */
  closeDialog() {
    this.handleAssignmentAndRoster();
  }

  async handleAssignmentAndRoster() {
    // Wait for both promises to resolve
    const assignments = await this._assignmentService.getForUser(
      this._authService.currentUserId,
    );
    const currentAssignmentId = this._assignmentService.getAssignmentId(
      assignments,
      0,
    );
    this.$rootScope.showDialog = true;
    // After assignment is available, navigate
    this._breadcrumbService.go(
      'root.account.session.work',
      {
        assignmentId: currentAssignmentId,
        rosterId: '',
        sort: '',
      },
      {
        source: 'refer',
      },
      true,
    );
  }
}
