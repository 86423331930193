'use strict';

import GradebookIntegrationStatusCodec from '../../model/codec/gradebook-integration-status.codec';
import GradebookRosterIntegrationStatusCodec from '../../model/codec/gradebook-roster-integration-status-codec';
import GradebookRosterCodec from '../../model/codec/gradebook-roster-codec';
import GradebookRosterLinkCodec from '../../model/codec/gradebook-roster-link.codec';
import GradebookStudentsIntegrationStatusCodec from '../../model/codec/gradebook-students-integration-status-codec';
import GradebookAssignmentLinkStatusCodec from '../../model/codec/gradebook-assignment-link-status-codec';

export default class GradebookService {
  constructor(environment, HttpService, $q) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {FirebaseService} */
    this.$q = $q;

    this._gradebookIntegrationStatusCodec =
      new GradebookIntegrationStatusCodec();
    this._gradebookRosterIntegrationStatusCodec =
      new GradebookRosterIntegrationStatusCodec();
    this._gradebookAssignmentLinkStatusCodec =
      new GradebookAssignmentLinkStatusCodec();
    this._gradebookRosterCodec = new GradebookRosterCodec();
    this._gradebookRosterLinkCodec = new GradebookRosterLinkCodec();
    this._gradebookStudentsIntegrationStatusCodec =
      new GradebookStudentsIntegrationStatusCodec();
  }

  /**
   * Checks the status of a teacher's gradebook integration
   *
   * @param userId {string}
   * @returns {Promise.<*>}
   */

  gradebookIntegrationStatus(userId) {
    return this._httpService
      .authGet(this._uri(`/v1/gradebook/users/${userId}/integration-status`))
      .then((integrationStatus) => {
        return this._gradebookIntegrationStatusCodec.decode(integrationStatus);
      });
  }

  /**
   * @param userId {string}
   * @return {Promise}
   */
  requestGradebookIntegration(userId, email) {
    const data = {
      lms_email: email,
    };
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/gradebook/users/${userId}/integration-status`,
      data,
    );
  }

  /**
   * Send email to admin to request for integration
   *
   * @param email: string
   * @returns {Promise.<*>}
   */

  gradebookIntegrationInvite(email) {
    const adminEmail = { admin_email: email };
    return this._httpService.authPost(
      this._uri('/v1/gradebook/integration-invite'),
      adminEmail,
    );
  }

  /**
   * Checks the teacher's roster gradebook integration status
   *
   * @param rosterId {string}
   * @returns {Promise.<*>}
   */

  gradebookIntegrationRosterStatus(rosterId) {
    return this._httpService
      .authGet(
        this._uri(`/v1/gradebook/rosters/${rosterId}/integration-status`),
      )
      .then((response) => {
        return this._gradebookRosterIntegrationStatusCodec.decode(response);
      });
  }

  /**
   * Checks the assignment/roster link status and returns important values for the assignment on this roster
   *
   * @param rosterId {string}
   * @param assignmentId {string}
   * @returns {Promise.<*>}
   */

  gradebookAssignmentLink(rosterId, assignmentId) {
    return this._httpService
      .authGet(
        this._uri(
          `/v1/gradebook/roster/${rosterId}/assignment/${assignmentId}/assignment-link`,
        ),
      )
      .then((response) => {
        return this._gradebookAssignmentLinkStatusCodec.decode(response);
      });
  }

  /**
   * Creates the link for Gradebook roster
   *
   * @param rosterId {string}
   * @returns {Promise.<*>}
   */

  linkToGradebookRoster(rosterId, rosterDetails) {
    return this._httpService.authPost(
      this._uri(`/v1/gradebook/rosters/${rosterId}/link-integration`),
      rosterDetails,
    );
  }

  /**
   * Get existing link to roster Gradebook roster
   *
   * @param rosterId {string}
   * @returns {Promise.<*>}
   */

  getLinktoGradebookRoster(rosterId) {
    return this._httpService
      .authGet(this._uri(`/v1/gradebook/rosters/${rosterId}/link-integration`))
      .then((response) => {
        return this._gradebookRosterLinkCodec.decode(response);
      });
  }

  /**
   * Get Gradebook integration status for students associated to a roster
   *
   * @param rosterId {string}
   * @returns {Promise.<*>}
   */

  gradebookIntegrationRosterStatusForStudents(rosterId) {
    return this._httpService
      .authGet(
        this._uri(
          `/v1/gradebook/roster/${rosterId}/students/integration-status`,
        ),
      )
      .then((response) => {
        return this._gradebookStudentsIntegrationStatusCodec.decode(response);
      });
  }

  /**
   * Submit grades for student's work
   *
   * @param rosterId {string}
   * @param assignmentId {string}
   * @param gradebookStudentIds {string[]}
   * @param selectedAssignmentId {string|null}
   * @param selectedCategoryId {string|null}
   * @param scaledPoints {number|null}
   * @param dueDate {string|null}
   * @returns {Promise.<*>}
   */

  submitGradesForAssignment(
    rosterId,
    assignmentId,
    gradebookStudentIds,
    selectedAssignmentId,
    selectedCategoryId,
    scaledPoints,
    dueDate,
  ) {
    let points =
      scaledPoints &&
      (Math.round(scaledPoints) > 0 ? Math.round(scaledPoints) : 1);
    const requestBody = {
      selected_student_ids: gradebookStudentIds,
      selected_assignment_id: selectedAssignmentId,
      selected_category_id: selectedCategoryId,
      scaled_points: points,
      due_date: dueDate,
    };
    return this._httpService.authPost(
      this._uri(
        `/v1/gradebook/roster/${rosterId}/assignment/${assignmentId}/grade-submission`,
      ),
      requestBody,
    );
  }

  /**
   * Creates integration for user
   *
   * @param userId {string}
   * @param code {string}
   * @returns {Promise.<*>}
   */
  userIntegrationForGradebook(userId, code) {
    return this._httpService.authPost(
      this._uri(`/v1/gradebook/users/${userId}/link-integration`),
      { o: code },
    );
  }

  /**
   * Creates student integration with Gradebook
   *
   * @returns {Promise.<*>}
   */

  linkStudentToGradebook(studentId, lmsId) {
    const requestBody = { lms_id: lmsId };
    return this._httpService.authPost(
      this._uri(`/v1/gradebook/students/${studentId}/link-integration`),
      requestBody,
    );
  }

  /**
   * Remove student integration with Gradebook
   *
   * @returns {Promise.<*>}
   */

  removeStudentFromGradebook(studentId, lmsId) {
    return this._httpService.authDelete(
      this._uri(`/v1/gradebook/students/${studentId}/link-integration`),
    );
  }

  /**
   * Returns a string that specifies a uri given a path.
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
