'use strict';

import RosterLockDialogTemplate from './roster-lock-dialog.html';

/**
 * Dialog to show and config roster locking
 */
export default class RosterLockDialogController {
  /**
   * @ngInject
   */
  constructor($mdDialog, roster, lockRosterExplanation, lockDeviceExplanation) {
    this.$mdDialog = $mdDialog;
    this.roster = roster;
    this.lockRosterExplanation = lockRosterExplanation;
    this.lockDeviceExplanation = lockDeviceExplanation;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * Shows roster lock dialog
   *
   * @param $q
   * @param $mdDialog
   * @param $document
   * @param roster
   * @param lockRosterExplanation
   * @param lockDeviceExplanation
   * @returns {*}
   */
  static show(
    $q,
    $mdDialog,
    $document,
    roster,
    lockRosterExplanation,
    lockDeviceExplanation,
  ) {
    return $q((resolve) => {
      let config = {
        clickOutsideToClose: true,
        clickEscapeToClose: true,
        controller: RosterLockDialogController,
        controllerAs: 'ctrl',
        template: RosterLockDialogTemplate,
        locals: {
          roster: roster,
          lockRosterExplanation: lockRosterExplanation,
          lockDeviceExplanation: lockDeviceExplanation,
        },
        bindToController: true,
        onRemoving: () => resolve(),
      };

      $mdDialog.show(config);
    });
  }
}
