'use strict';

import HexColors from '../../css-constants';

/**
 * This class defines the score thresholds used to determine the color of
 * a graded assignment question.
 */
export default class GradingUtils {
  static get EXCEEDS() {
    return 0.9;
  }

  static get MEETS() {
    return 0.8;
  }

  static get APPROACHING() {
    return 0.6;
  }

  static get BELOW() {
    return 0;
  }

  /**
   * @param score {number}
   * @returns {string|undefined}
   */
  static colorForScore(score) {
    if (score >= GradingUtils.MEETS) {
      return HexColors.CK_GRADING_MEETS;
    } else if (score >= GradingUtils.APPROACHING) {
      return HexColors.CK_GRADING_APPROACHING;
    } else if (score >= GradingUtils.BELOW) {
      return HexColors.CK_GRADING_BELOW;
    }
  }

  static colorClassForScore(score) {
    if (score >= GradingUtils.MEETS) {
      return 'meets';
    } else if (score >= GradingUtils.APPROACHING) {
      return 'approaches';
    } else if (score >= GradingUtils.BELOW) {
      return 'below';
    }
  }

  /**
   * @param score {number}
   * @returns {string}
   */
  static textColorForScore(score) {
    if (angular.isNumber(score) && !isNaN(score)) {
      return HexColors.CK_ELEMENT_BLACK;
    } else {
      return HexColors.CK_HEADER_GREY;
    }
  }
}
