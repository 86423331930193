'use strict';

import ErrorDialogController from '../error-dialog/error-dialog.controller';
import Validation from '../../model/util/validation';
import InviteCoTeacherDialogTemplate from './invite-co-teacher-dialog.html';

export class InviteCoTeacherDialogStates {
  static get INVITE_TEACHER() {
    return 'invite-teacher';
  }

  static get LOADING() {
    return 'loading';
  }
}

export default class InviteCoTeacherDialogController {
  constructor(
    $mdDialog,
    $mdToast,
    $q,
    AnalyticsService,
    CoTeacherService,
    rosterId,
  ) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CoTeacherService} */
    this._coTeacherService = CoTeacherService;

    this._errorDialog = ErrorDialogController.show;

    this.emailRegex = Validation.EmailRegex;

    this.rosterId = rosterId;
    this.state = InviteCoTeacherDialogStates.INVITE_TEACHER;
    this.emails = '';
    this.error = false;
    this.mdrRecipientList = [];
  }

  /**
   * show the dialog
   * @param  {$mdDialog}  $mdDialog
   * @param  {assignment} assignment
   * @param  {string} rosterId
   * @return {Promise}
   */
  static show($mdDialog, rosterId) {
    let config = {
      controller: InviteCoTeacherDialogController,
      template: InviteCoTeacherDialogTemplate,
      controllerAs: 'ctrl',
      autoWrap: false,
      clickOutsideToClose: true,
      focusOnOpen: false,
      locals: {
        rosterId,
      },
    };

    return $mdDialog.show(config);
  }

  /**
   * tests whether email is valid
   * @return {Boolean} whether the email is valid or not
   */
  isEmailValid(email) {
    return this.emailRegex.test(email.trim());
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = this.emails.split(/[,;]/);

    emails = emails.concat(this.mdrRecipientList).filter((email) => email);
    let isValid = false;
    for (let email of emails) {
      isValid = this.isEmailValid(email);
      if (!isValid) return false;
    }

    return true;
  }

  /**
   * tests if manually entered emails are below a threshold as a security measure.
   * @return {Boolean} whether the email list is below threshold
   */
  get isInputEmailsBelowLimit() {
    let emails = this.emails.split(/[,;]/);
    if (emails.length > 10) {
      return false;
    }

    return true;
  }

  get isInviteTeacher() {
    return this.state === InviteCoTeacherDialogStates.INVITE_TEACHER;
  }

  get isLoading() {
    return this.state === InviteCoTeacherDialogStates.LOADING;
  }

  /**
   * set validity of email input
   */
  _displayToastMessage(message) {
    this._toastMessage = message;
    let toastClassName = 'referral-toast';
    const config = this.$mdToast
      .simple(this._toastMessage)
      .action(' ')
      .actionKey('x')
      .position('top right')
      .toastClass(toastClassName)
      .hideDelay(4000);
    this.$mdToast.show(config);
  }

  /**
   * close the dialog. rejects the promise returned from the show method
   */
  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * set validity of email input
   * @return {Boolean}
   */
  setEmailValidityForCoTeacher(scope) {
    scope.emailInviteCoTeacherForm.email.$setValidity(
      'max-emails',
      this.isInputEmailsBelowLimit,
    );
    scope.emailInviteCoTeacherForm.email.$setValidity(
      'pattern',
      this.isEmailListValid,
    );
  }

  /**
   * Sends email invite to teacher
   */
  async sendEmailToCoTeachers() {
    try {
      let errorMessages = [];
      if (!this.isInputEmailsBelowLimit) return;
      if (!this.isEmailListValid) return;

      this.state = InviteCoTeacherDialogStates.LOADING;

      let emailList = this.emails.split(/[,;]/);
      emailList = emailList
        .concat(this.mdrRecipientList)
        .filter((email) => email);

      const promises = emailList.map(async (email) => {
        try {
          this._analyticsService.inviteCoTeacher(this.rosterId);
          await this._coTeacherService.inviteCoTeacher(
            this.rosterId,
            email.trim().toLowerCase(),
          );
          return { success: true };
        } catch (error) {
          let errorMessage =
            'Please try again later or send an email to support@classkick.com';
          switch (error.message) {
            case 'Found an account with the email address but they are not a teacher.':
              errorMessage = `You entered an email - ${email} that cannot be invited to co-teach. Please try a different email.`;
              break;
            case 'Cannot assign self as coteacher to owned roster.':
              errorMessage = `You cannot invite yourself - ${email} as a co-teacher`;
              break;
          }
          return { success: false, message: errorMessage };
        }
      });

      const results = await this.$q.all(promises);

      this.state = InviteCoTeacherDialogStates.INVITE_TEACHER;
      this.$mdDialog.hide();

      // Collect all error messages
      errorMessages = results
        .filter((result) => !result.success)
        .map((result) => result.message);

      if (errorMessages.length) {
        this._errorDialog(
          this.$mdDialog,
          'Uh oh! An error occurred',
          errorMessages.join('\n'),
        );
      } else {
        this._displayToastMessage('Your invite has been sent!');
      }
    } catch (error) {
      this.state = InviteCoTeacherDialogStates.INVITE_TEACHER;
      this._errorDialog(
        this.$mdDialog,
        'Uh oh! An error occurred',
        'Please try again later or send an email to support@classkick.com',
      );
    }
  }
}
