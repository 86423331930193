import StaticService from '../../services/static/static.service';

export class FileUtil {
  /**
   * @param str {string}
   * @return {string}
   */
  static removeForbiddenCharacters(str) {
    if (StaticService.get.isWindows) {
      return str.replace(/(?!\.[^.]+$)\.|[^\w.]+/g, '-');
    } else {
      return FileUtil.removeSlashes(str);
    }
  }

  /**
   * @param str {string}
   * @return {string}
   */
  static removeSlashes(str) {
    return str.replace(/\//, '-');
  }
}
