'use strict';

import { AssignmentQuestionMetadata } from '../../components/assignment-question/assignment-question.directive';
import FirebaseElementSavingIndicator from '../../components/saving-indicator/firebase-element-saving-indicator';
import { UserRoles } from '../../model/domain/user';
import { ElementIntents } from '../../model/domain/element-metadata';
import { Notifications } from '../../services/toolbar/toolbar.service';
import SaveStates from '../../components/saving-indicator/save-states';
import { AssignmentToolbarConfig } from '../../components/assignment-toolbar/assignment-toolbar.directive';
import Debouncer from '../../model/util/debouncer';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';
import ShareDialogController from '../../components/share-dialog/share-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';

export default class AssignmentTeacherFeedbackController {
  constructor(
    $scope,
    $q,
    $stateParams,
    $state,
    $timeout,
    $mdDialog,
    $log,
    BreadcrumbService,
    AuthService,
    CacheService,
    AssignmentTrackingService,
    ToolbarService,
    AssignmentWorkService,
    FeedbackService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$log = $log;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;

    this.toolbarOptions = new AssignmentToolbarConfig();
    this.toolbarOptions.addSticker = true;
    this.toolbarOptions.resolveHelp = true;
    this.toolbarOptions.editScore = true;
    this.toolbarOptions.toggleContributors = true;
    this.toolbarOptions.coverSlide = true;

    this._savingIndicator = new FirebaseElementSavingIndicator(
      this.$timeout,
      this._assignmentTrackingService,
    );
    this._assignmentWork = undefined;
    this._sessionData = undefined;
    this._assignmentQuestionConfig = undefined;

    this._helpDialog = ViewHelpDialogController.show;
    this._shareDialog = ShareDialogController.show;

    $scope.$on('$destroy', () => this._destroy());

    this._firstFeedback = true;
    this._handleTeacherActionDebounce = new Debouncer(
      750,
      3000,
      this._handleTeacherAction.bind(this),
      $scope,
    );

    this._init();
  }

  /**
   * @return {string}
   */
  get saveState() {
    return this._savingIndicator && this._savingIndicator.saveState;
  }

  /**
   * @return {SessionData}
   */
  get data() {
    return this._sessionData;
  }

  /**
   * @return {AssignmentWork}
   */
  get assignmentWork() {
    return this._assignmentWork;
  }

  /**
   * @return {Array<User>}
   */
  get students() {
    return this.data && this.data.sortedStudents;
  }

  /**
   * @return {HelpInboxMetadata}
   */
  get helpInboxMetadata() {
    return this.data && this.data.helpInboxMetadata;
  }

  /**
   * @returns {string}
   */
  get helperId() {
    return this._authService.authData ? this._authService.authData.id : '';
  }

  /**
   * @return {User}
   */
  get helper() {
    return this.data && this.data.teacher;
  }

  /**
   * @return {string}
   */
  get helpeeId() {
    return this.assignmentWork && this.assignmentWork.ownerId;
  }

  /**
   * @return {User}
   */
  get helpee() {
    return (
      this.helpeeId &&
      this.students &&
      this.students.find((student) => student.id === this.helpeeId)
    );
  }

  /**
   * @return {AssignmentQuestionMetadata}
   */
  get assignmentQuestionConfig() {
    return this._assignmentQuestionConfig;
  }

  _init() {
    this._cacheService
      .getSessionData(
        this.$stateParams.assignmentId,
        this.$stateParams.rosterId,
        false,
        true,
      )
      .then((sessionData) => {
        this._sessionData = sessionData;

        // The assignment work in sessionData is being used to render the thumbnail in the background so we need to use a
        // separate instance in the feedback view
        return this._getWork(
          this._sessionData.assignment,
          this.$stateParams.assignmentWorkId,
        );
      })
      .then((assignmentWork) => {
        this._assignmentWork = assignmentWork;

        this._syncWorkQuestionScore();

        this._assignmentTrackingService.previewModified.subscribe(
          this._elementAddedOrUpdated,
          this,
        );
        this._toolbarService.notification.subscribe(
          this._handleToolbarNotification,
          this,
        );

        this._assignmentQuestionConfig = new AssignmentQuestionMetadata(
          this._assignmentWork,
          this.$stateParams.questionId,
          false,
          false,
          this.helperId,
          UserRoles.TEACHER,
          ElementIntents.FEEDBACK,
          undefined,
          this.data && this.data.openHelpRequests,
          undefined,
          this.data.realOrAnonNameForStudent.bind(this.data),
        );

        this._workQuestionSet = this.data.workQuestionSet(this.helpeeId);
        this._workQuestionSet.updated.subscribe(
          this._handleQuestionScoreUpdated,
          this,
        );
      })
      .catch((error) => {
        this.error = error;
      });
  }

  /**
   * Ensures the assignment work is most updated version
   * @param assignment {Assignment}
   * @param assignmentWorkId {string}
   * @return {Promise<AssignmentWork>}
   */
  _getWork(assignment, assignmentWorkId) {
    return this._cacheService
      .getAssignmentWork(assignment, assignmentWorkId, false)
      .then((assignmentWork) => {
        // if the assignment work doesn't have a corresponding question for each question in the assignment, refresh the work
        if (
          assignment.questions.some(
            (question) => !assignmentWork.questionForId(question.id),
          )
        ) {
          return this._cacheService.getAssignmentWork(
            assignment,
            assignmentWorkId,
            true,
          );
        } else {
          return assignmentWork;
        }
      });
  }

  /**
   * If the score of the current question was updated in the assignment work question from sessionData
   * then we should update it in the local assignment work
   */
  _syncWorkQuestionScore() {
    let work = this.data.workForStudent(this.helpeeId);
    let workQuestion = work && work.questionForId(this.$stateParams.questionId);
    workQuestion && this._setScore(workQuestion.points);
  }

  _handleQuestionScoreUpdated() {
    this._syncWorkQuestionScore();
  }

  _handleToolbarNotification(ev) {
    if (ev.type === Notifications.UPDATE_SCORED_POINTS) {
      this._savingIndicator.saveState = SaveStates.UNSAVED;

      let questionWork = this._setScore(ev.data.newScore);

      this._assignmentWorkService
        .updateQuestion(
          this.$stateParams.assignmentId,
          this.assignmentWork.id,
          questionWork,
        )
        .then(() => {
          this._savingIndicator.saveState = SaveStates.SAVED;
          this._handleTeacherActionDebounce.tick();
        })
        .catch(() => {
          this._savingIndicator.saveState = SaveStates.SAVE_ERROR;
        });
    } else if (ev.type === Notifications.RESOLVE_REQUEST) {
      this._handleStatus();
    }
  }

  _setScore(score) {
    let questionWork = this.assignmentWork.questions.get(
      this.$stateParams.questionId,
    );
    questionWork.points = score;
    return questionWork;
  }

  /**
   * @param event {{type: string, element: Element}}
   * @private
   */
  _elementAddedOrUpdated(event) {
    if (this._isTeacherFeedback(event.element)) {
      this._handleTeacherActionDebounce.tick();
    }
  }

  _handleTeacherAction() {
    this._handleStatus();

    if (this._toolbarService.helpCenter.helpRequestManager.currentHelpRequest) {
      this._toolbarService.helpCenter.lowerHelpRequest();
    }
  }

  /**
   * Updates the helpers status
   */
  _handleStatus() {
    if (this._firstFeedback) {
      this._cacheService.startHelping(
        this.assignmentWork.assignment,
        this.helperId,
        this.helper.name,
        UserRoles.TEACHER,
        this.helpeeId,
        this.$stateParams.assignmentId,
        this.$stateParams.questionId,
      );

      this._firstFeedback = false;
    }

    this._feedbackService.give(
      this.helpeeId,
      this.assignmentWork,
      this.$stateParams.questionId,
      this.helperId,
      this.helper.name,
    );
  }

  /**
   * @param element {Element}
   * @returns {boolean}
   * */
  _isTeacherFeedback(element) {
    return (
      element.metadata &&
      element.metadata.intent === ElementIntents.FEEDBACK &&
      element.metadata.role === UserRoles.TEACHER
    );
  }

  get teacherIsHelpingMessage() {
    if (this.helpee) {
      let studentName = this.data.realOrAnonNameForStudent(this.helpee);
      let questionNumber = this.assignmentWork.questionNumberForId(
        this.$stateParams.questionId,
      );
      return `You are helping ${studentName} on slide ${questionNumber}`;
    } else {
      return '';
    }
  }

  goBack() {
    if (!this.error) {
      this._breadcrumbService.goBack(this.parentState, {
        assignmentId: this.assignmentWork.assignment.id,
        rosterId: this.assignmentWork.rosterId,
      });
    } else {
      this._breadcrumbService.goBack('root.account.home');
    }
  }

  get parentState() {
    try {
      return this.$state.$current.parent.self.name;
    } catch (error) {
      this.$log.error(error);
      return 'root.account.home';
    }
  }

  /**
   * @param request {HelpRequest}
   */
  onSelect(request) {
    let assignmentWork = this.data.workForStudent(request.helpeeId);
    this._breadcrumbService.go('^.feedback', {
      assignmentId: request.assignmentId,
      questionId: request.questionId,
      assignmentWorkId: assignmentWork && assignmentWork.id,
    });
  }

  showHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.AssignmentTeacherFeedback);
  }

  _destroy() {
    if (this._assignmentTrackingService) {
      this._assignmentTrackingService.previewModified.unsubscribe(
        this._elementAddedOrUpdated,
        this,
      );
    }

    if (this._toolbarService.notification) {
      this._toolbarService.notification.unsubscribe(
        this._handleToolbarNotification,
        this,
      );
    }

    if (this._workQuestionSet) {
      this._workQuestionSet.updated.unsubscribe(
        this._handleQuestionScoreUpdated,
        this,
      );
    }

    this._cacheService.stopHelping();
  }

  shareAssignmentWork() {
    const studentName = this.data.realOrAnonNameForStudent(this.helpee);
    this._shareDialog(
      this.$mdDialog,
      this._assignmentWork.assignment,
      Locations.ASSIGNMENT_WORK_QUESTION,
      studentName,
      this._assignmentWork.id,
    );
  }
}
