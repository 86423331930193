'use strict';

import Point from './point';
import Size from './size';
import JSEvent from './../util/js-event';
import { DefaultPlacement } from './placement';

/**
 * Base class for a control rendered using SVG DOM elements
 */
export default class Control {
  /**
   * The id/selector to use
   * @param id {string}
   * @param type {string}
   */
  constructor(id, type) {
    this._id = id;
    this._type = type;

    this._location = new Point(0, 0);
    this._size = new Size(0, 0);
    this._minSize = new Size(0, 0);

    this._rotation = 0;

    this._hasFocus = false;
    this._editable = false;
    this._active = false;

    this._clicked = new JSEvent(this);
    this._resized = new JSEvent(this);
    this._moved = new JSEvent(this);
    this._rotated = new JSEvent(this);
    this._focused = new JSEvent(this);
    this._blurred = new JSEvent(this);
    this._visibilityUpdated = new JSEvent(this);
    this._opacityUpdated = new JSEvent(this);
    this._focusNextElement = new JSEvent(this);
    this._focusPrevElement = new JSEvent(this);

    this._domElement = undefined;
    this._domElementRoot = undefined;

    this._placement = new DefaultPlacement();

    this.loaded = new Promise((resolve) => {
      this.resolveLoaded = resolve;
    });
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @param value {string}
   */
  set id(value) {
    this._id = value;
  }

  /**
   * @returns {string}
   */
  get type() {
    return this._type;
  }

  /**
   * @returns {Point}
   */
  get location() {
    return this._location;
  }

  /**
   * Sets the location and raises the moved event
   *
   * @param value {Point}
   */
  set location(value) {
    if (this.location.x === value.x && this.location.y === value.y) {
      return;
    }

    var eventArgs = {
      oldLocation: this._location,
      location: value,
    };

    this._location = value;
    this.tryUpdate();

    this.moved.raise(eventArgs);
  }

  /**
   * Calculates the center point of this object
   * @returns {Point}
   */
  get center() {
    return this.location.plus(new Point(this.width / 2, this.height / 2));
  }

  /**
   * @returns {Size}
   */
  get size() {
    return this._size;
  }

  /**
   * Sets the size
   * @param value {Size}
   */
  set size(value) {
    var newWidth = Math.max(value.width, this._minSize.width);
    var newHeight = Math.max(value.height, this._minSize.height);

    if (newWidth === this.width && newHeight === this.height) {
      return;
    }

    let newSize = new Size(newWidth, newHeight);

    var eventArgs = {
      oldWidth: this.width,
      oldHeight: this.height,
      width: newWidth,
      height: newHeight,
      oldSize: this.size,
      size: newSize,
    };

    this._size = newSize;
    this.tryUpdate();

    this.resized.raise(eventArgs);
  }

  /**
   * Sets the size
   *
   * @param width {number}
   * @param height {number}
   */
  resize(width, height) {
    this.size = new Size(width, height);
  }

  /**
   * @returns {number}
   */
  get width() {
    return this._size.width;
  }

  /**
   * @returns {number}
   */
  get height() {
    return this._size.height;
  }

  /**
   * @param value {number}
   */
  set minWidth(value) {
    this._minSize._width = value;

    if (this.width < value) {
      this.resize(value, this.height);
    }
  }

  /**
   * @returns {number}
   */
  get minWidth() {
    return this._minSize.width;
  }

  /**
   * @param value {number}
   */
  set minHeight(value) {
    this._minSize._height = value;

    if (this.height < value) {
      this.resize(this.width, value);
    }
  }

  /**
   * @returns {number}
   */
  get minHeight() {
    return this._minSize.height;
  }

  /**
   * @returns {string}
   */
  get visibility() {
    return this._visibility;
  }

  /**
   * @param value {string}
   */
  set visibility(value) {
    if (this.visibility === value) {
      return;
    }

    let eventArgs = {
      oldVisibility: this.visibility,
      visibility: value,
    };

    this._domElementRoot &&
      this._domElementRoot.attr({
        visibility: value,
      });

    this._visibility = value;

    this.tryUpdate();

    this.visibilityUpdated.raise(eventArgs);
  }

  /**
   * @returns {number}
   */
  get opacity() {
    return this._domElementRoot ? this._domElementRoot.attr('opacity') : 1;
  }

  /**
   * Must be called after render
   * @param value {number}
   */
  set opacity(value) {
    if (!this._domElementRoot || this.opacity === value) {
      return;
    }

    let eventArgs = {
      oldOpacity: this.opacity,
      opacity: value,
    };

    this._domElementRoot.attr({
      opacity: value,
    });

    this.tryUpdate();

    this.opacityUpdated.raise(eventArgs);
  }

  /**
   * Indicates that a control can be modified
   */
  get editable() {
    return this._editable;
  }

  /**
   * Indicates that a control can be clicked / interacted with. Primarily for Link / AudioClip
   * @returns {boolean}
   */
  get active() {
    return this._active;
  }

  /**
   * {
   *  location: Point
   * }
   * @returns {JSEvent} which raises when the control is clicked
   */
  get clicked() {
    return this._clicked;
  }

  /**
   * Focus on this control
   */
  focus() {
    if (!this.editable) {
      return;
    }

    if (!this._hasFocus) {
      this._hasFocus = true;
      this.tryUpdate();
      this.focused.raise({});
    }
  }

  /**
   * Unfocus this control if it is focused
   */
  blur() {
    if (this._hasFocus) {
      this._hasFocus = false;
      this.tryUpdate();
      this.blurred.raise({});
    }
  }

  get hasFocus() {
    return this._hasFocus;
  }

  /**
   * {
   *  oldWidth: number,
   *  oldHeight: number,
   *  width: number,
   *  height: number
   * }
   * @returns {JSEvent} which raises when the control is resized
   */
  get resized() {
    return this._resized;
  }

  /**
   * {
   *  oldLocation: Point,
   *  location: Point
   * }
   *
   * @returns {JSEvent} which raises when the control is moved
   */
  get moved() {
    return this._moved;
  }

  /**
   * {
   * }
   * @returns {JSEvent} which raises when the control gains focus
   */
  get focused() {
    return this._focused;
  }

  /**
   * {
   * }
   * @returns {JSEvent} which raises when the control loses focus
   */
  get blurred() {
    return this._blurred;
  }

  /**
   * {
   *   oldVisibility: string,
   *   visibility: string
   * }
   * @returns {JSEvent} which raises when the visibility changes
   */
  get visibilityUpdated() {
    return this._visibilityUpdated;
  }

  /**
   * {
   *   oldOpacity: string,
   *   opacity: string
   * }
   * @returns {JSEvent} which raises when the opacity changes
   */
  get opacityUpdated() {
    return this._opacityUpdated;
  }

  /**
   * @return {JSEvent}
   */
  get focusNextElement() {
    return this._focusNextElement;
  }

  /**
   * @return {JSEvent}
   */
  get focusPrevElement() {
    return this._focusPrevElement;
  }

  /**
   * @returns {{userId: {string}, role: {string}, intent: {string}}}
   */
  get viewerMetadata() {
    return this._viewerMetadata;
  }

  /**
   * @return {string|object}
   */
  get viewerIntent() {
    return this.viewerMetadata && this.viewerMetadata.intent;
  }

  /**
   * @return {string|object}
   */
  get viewerRole() {
    return this.viewerMetadata && this.viewerMetadata.role;
  }

  /**
   * @return {SvgCanvas}
   */
  get canvas() {
    return this._canvas;
  }

  /**
   * @return {jQuery}
   */
  get hiddenTextInput() {
    return this.canvas && this.canvas.hiddenTextInput;
  }

  /**
   * @return {Assignment|AssignmentWork}
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * @return {boolean}
   */
  get thumbnail() {
    return this._thumbnail;
  }

  /**
   * @return {MoreOptionsManager}
   */
  get moreOptionsManager() {
    return this._moreOptionsManager;
  }

  /**
   * @param snap {Snap}
   * @param [editable] {boolean}
   * @param [active] {boolean}
   * @param [canvas] {SvgCanvas}
   * @param [assignment] {Assignment|AssignmentWork}
   * @param [viewerMetadata] {{userId: {string}, role: {string}, intent: {string}}}
   * @param [thumbnail] {boolean}
   * @param [moreOptionsManager] {MoreOptionsManager}
   */
  render(
    snap,
    editable,
    active,
    canvas,
    assignment,
    viewerMetadata,
    thumbnail,
    moreOptionsManager,
  ) {
    this._canvas = canvas;
    this._assignment = assignment;
    this._viewerMetadata = viewerMetadata;
    this._thumbnail = thumbnail;
    this._moreOptionsManager = moreOptionsManager;

    let element = snap.select('.' + this._id);

    if (element && !this._domElement) {
      element.select('.elementRoot').remove();
    }

    if (!element || !this._domElement) {
      this._domElement = element || snap.group().addClass(this._id);
      this._domElementRoot = this._domElement.group().addClass('elementRoot');
      this._editable = !!editable;
      this._active = this.editable || !!active;
      this._hasFocus = false;
      this._visibility = 'inherit';
      this.createElement(this._domElementRoot, this.editable, this.active);
    }

    this.update(this._domElementRoot, this.editable, this.active);

    this.resolveLoaded();
  }

  /**
   * Warns the user about deletion
   */
  warnBeforeDeletion() {
    throw new Error('editable=' + this._editable + ', active=' + this._active);
  }

  /**
   * Removes the control from the dom
   */
  remove() {
    if (this._domElement) {
      this._domElement.remove();
      this._domElement = undefined;
      this._domElementRoot = undefined;
    }
  }

  /**
   * @param root {Snap} the root Snap.svg group
   * @param editable {boolean}
   * @param [active] {boolean}
   */
  createElement(root, editable, active) {
    throw new Error(
      'not-implemented ' +
        root +
        ', editable=' +
        editable +
        ', active=' +
        active,
    );
  }

  /**
   * @param root {Snap} the root Snap.svg group
   * @param editable {boolean}
   * @param active {boolean}
   */
  update(root, editable, active) {
    throw new Error(
      'not-implemented ' +
        root +
        ', editable=' +
        editable +
        ', active=' +
        active,
    );
  }

  tryUpdate() {
    if (this._domElement) {
      this.update(this._domElement, this.editable, this.active);
    }
  }

  /**
   * @return {Placement}
   */
  get placement() {
    return this._placement;
  }
}
