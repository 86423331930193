import HexColors from '../../css-constants';
import ColorCodec from '../codec/color-codec';

const colorCodec = new ColorCodec();

const elementColors = [
  {
    name: 'red',
    hex: HexColors.CK_ELEMENT_RED,
  },
  {
    name: 'red-orange',
    hex: HexColors.CK_ELEMENT_RED_ORANGE,
  },
  {
    name: 'orange',
    hex: HexColors.CK_ELEMENT_ORANGE,
  },
  {
    name: 'yellow',
    hex: HexColors.CK_ELEMENT_YELLOW,
  },

  {
    name: 'lime',
    hex: HexColors.CK_ELEMENT_LIME_GREEN,
  },
  {
    name: 'mint',
    hex: HexColors.CK_ELEMENT_MINT_GREEN,
  },
  {
    name: 'green',
    hex: HexColors.CK_ELEMENT_GREEN,
  },
  {
    name: 'teal',
    hex: HexColors.CK_ELEMENT_TEAL,
  },

  {
    name: 'aqua',
    hex: HexColors.CK_ELEMENT_AQUA,
  },
  {
    name: 'turquoise',
    hex: HexColors.CK_ELEMENT_TURQUOISE,
  },
  {
    name: 'blue',
    hex: HexColors.CK_ELEMENT_BLUE,
  },
  {
    name: 'purple',
    hex: HexColors.CK_ELEMENT_PURPLE,
  },

  {
    name: 'mauve',
    hex: HexColors.CK_ELEMENT_MAUVE,
  },
  {
    name: 'violet',
    hex: HexColors.CK_ELEMENT_VIOLET,
  },
  {
    name: 'magenta',
    hex: HexColors.CK_ELEMENT_MAGENTA,
  },
  {
    name: 'pink',
    hex: HexColors.CK_ELEMENT_PINK,
  },

  {
    name: 'sand',
    hex: HexColors.CK_ELEMENT_SAND,
  },
  {
    name: 'tan',
    hex: HexColors.CK_ELEMENT_TAN,
  },
  {
    name: 'brown',
    hex: HexColors.CK_ELEMENT_BROWN,
  },
  {
    name: 'dark-brown',
    hex: HexColors.CK_ELEMENT_DARK_BROWN,
  },

  {
    name: 'black',
    hex: HexColors.CK_ELEMENT_BLACK,
    active: true,
  },
  {
    name: 'grey',
    hex: HexColors.CK_ELEMENT_GREY,
  },
  {
    name: 'light-grey',
    hex: HexColors.CK_ELEMENT_LIGHT_GREY,
  },
  {
    name: 'white',
    hex: HexColors.CK_ELEMENT_WHITE,
  },
];

const roster = [
  {
    name: 'blue',
    hex: HexColors.CK_ROSTER_BLUE,
  },
  {
    name: 'orange',
    hex: HexColors.CK_ROSTER_ORANGE,
  },
  {
    name: 'purple',
    hex: HexColors.CK_ROSTER_PURPLE,
  },
  {
    name: 'red_pink',
    hex: HexColors.CK_ROSTER_RED_PINK,
  },
  {
    name: 'yellow',
    hex: HexColors.CK_ROSTER_YELLOW,
  },
  {
    name: 'green',
    hex: HexColors.CK_ROSTER_GREEN,
  },
  {
    name: 'grey',
    hex: HexColors.CK_ROSTER_GREY,
  },
  {
    name: 'pink',
    hex: HexColors.CK_ROSTER_PINK,
  },
  {
    name: 'red',
    hex: HexColors.CK_ROSTER_RED,
  },
  {
    name: 'brown',
    hex: HexColors.CK_ROSTER_BROWN,
  },
  {
    name: 'light_blue',
    hex: HexColors.CK_ROSTER_LIGHT_BLUE,
  },
  {
    name: 'light_green',
    hex: HexColors.CK_ROSTER_LIGHT_GREEN,
  },
];

const whiteBorderColor = `1px solid ${HexColors.CK_DISABLED_GREY}`;

export class Colors {
  /**
   * @returns {{name: string, hex: string}[]}
   */
  static get ELEMENT_COLORS() {
    return elementColors;
  }

  /**
   * @returns {{name: string, hex: string}[]}
   */
  static get ROSTER_COLORS() {
    return roster;
  }

  /**
   * @param color {{name: string, hex: string}}
   */
  static penToHighlight(color) {
    return {
      name: color.name,
      hex: colorCodec.hex6ToRgba(color.hex),
    };
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  static borderColor(color) {
    if (color.name === 'white') {
      return whiteBorderColor;
    }
    return '0';
  }
}
