'use strict';

import { HelpRequestTypes } from '../../model/domain/help-request';
import HelpRequestSet from '../../model/domain/help-request-set';
import { Notifications } from './toolbar.service';

/**
 * Status codes are used to indicate the state of a help request in our UI
 */
export class HelpRequestStatusCodes {
  static get CHECK() {
    return 'status_check_request';
  }

  static get HELP() {
    return 'status_help_request';
  }

  static get CHAT() {
    return 'status_chat_request';
  }

  static get NO_REQUEST() {
    return 'status_no_request';
  }

  static get LOADING() {
    return 'status_loading_request';
  }
}

export default class HelpRequestManager {
  constructor(ToolbarService, HelpRequestService, AuthService, StorageService) {
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this.reset();
  }

  reset() {
    this._parentHelpRequestSet = undefined;
    this._helpRequestSet = undefined;
    this._loadingFor = undefined;
    this._anonAnimalNameForHelpee = undefined;
  }

  get loading() {
    return !this._helpRequestSet || !!this._loadingFor;
  }

  get isLoadingForCheck() {
    return this._loadingFor === HelpRequestStatusCodes.CHECK;
  }

  get isLoadingForHelp() {
    return this._loadingFor === HelpRequestStatusCodes.HELP;
  }

  set parentHelpRequestSet(value) {
    if (!this._parentHelpRequestSet || this._parentHelpRequestSet !== value) {
      this._parentHelpRequestSet = value;
    }
    this._init(this._parentHelpRequestSet);
  }

  get helpRequestSet() {
    return this._helpRequestSet;
  }

  set helpRequestSet(value) {
    this._helpRequestSet = value;
  }

  get currentHelpRequest() {
    return this._helpRequestSet && this._helpRequestSet.requests[0];
  }

  get anonAnimalNameForHelpee() {
    if (!this._anonAnimalNameForHelpee) {
      let feedbackHelpRequest = this._storageService.feedbackHelpRequest;
      this._anonAnimalNameForHelpee =
        feedbackHelpRequest && feedbackHelpRequest.anonAnimalNameForHelpee;
    }
    return this._anonAnimalNameForHelpee;
  }

  get status() {
    if (this.loading) {
      return HelpRequestStatusCodes.LOADING;
    }

    let currentHelpRequest = this.currentHelpRequest;

    if (!currentHelpRequest) {
      return HelpRequestStatusCodes.NO_REQUEST;
    } else if (currentHelpRequest.requestType === HelpRequestTypes.HELP) {
      return HelpRequestStatusCodes.HELP;
    } else if (currentHelpRequest.requestType === HelpRequestTypes.CHECK) {
      return HelpRequestStatusCodes.CHECK;
    } else if (currentHelpRequest.requestType === HelpRequestTypes.CHAT) {
      return HelpRequestStatusCodes.CHAT;
    } else {
      return '';
    }
  }

  /**
   * @returns {boolean}
   */
  get isHelpRequestLoading() {
    return this.status === HelpRequestStatusCodes.LOADING;
  }

  /**
   * @returns {boolean}
   */
  get isHelpRequestCheck() {
    return this.status === HelpRequestStatusCodes.CHECK;
  }

  /**
   * @returns {boolean}
   */
  get isHelpRequestHelp() {
    return this.status === HelpRequestStatusCodes.HELP;
  }

  /**
   * @returns {boolean}
   */
  get isHelpRequestChat() {
    return this.status === HelpRequestStatusCodes.CHAT;
  }

  /**
   * @returns {boolean}
   */
  get hasHelpRequest() {
    return (
      this.isHelpRequestHelp ||
      this.isHelpRequestCheck ||
      this.isHelpRequestChat
    );
  }

  /**
   * @param parentHelpRequestSet {HelpRequestSet}
   */
  _init(parentHelpRequestSet) {
    this._loadingFor = undefined;
    this._anonAnimalNameForHelpee = undefined;

    if (parentHelpRequestSet) {
      this._helpRequestSet = parentHelpRequestSet.createChild(
        HelpRequestSet.FILTER_FUNC_HELPEE_QUESTION_UNRESOLVED(
          this.helpeeId,
          this.questionId,
        ),
      );

      this._helpRequestSet.start();
    }
  }

  newHelpRequest_Help() {
    this._loadingFor = HelpRequestStatusCodes.HELP;
    this._notifyHelpRequest(Notifications.HELP_REQUEST);
    return this._createHelpRequest(HelpRequestTypes.HELP);
  }

  newHelpRequest_Check() {
    this._loadingFor = HelpRequestStatusCodes.CHECK;
    this._notifyHelpRequest(Notifications.CHECK_REQUEST);
    return this._createHelpRequest(HelpRequestTypes.CHECK);
  }

  newHelpRequest_Chat() {
    this._notifyHelpRequest(Notifications.CHAT_REQUEST);
    return this._createHelpRequest(HelpRequestTypes.CHAT);
  }

  lowerHelpRequest() {
    if (this.helpeeId === this.currentUserId) {
      return this.lowerHelpRequest_Cancel();
    } else {
      return this.lowerHelpRequest_Resolve();
    }
  }

  lowerHelpRequest_Resolve() {
    this._loadingFor = HelpRequestStatusCodes.NO_REQUEST;
    this._notifyHelpRequest(Notifications.RESOLVE_REQUEST);

    return this.resolve().then(() => {
      this._loadingFor = undefined;
    });
  }

  resolve() {
    const helpRequest = this.currentHelpRequest;
    return this._helpRequestService.resolve(helpRequest.id).then(() => {
      this._helpRequestSet.localResolve(helpRequest);
    });
  }

  lowerHelpRequest_Cancel() {
    this._loadingFor = HelpRequestStatusCodes.NO_REQUEST;
    this._notifyHelpRequest(Notifications.CANCEL_REQUEST);

    const helpRequest = this.currentHelpRequest;
    return this._helpRequestService.cancel(helpRequest.id).then(() => {
      this._helpRequestSet.localCancel(helpRequest);
      this._loadingFor = undefined;
    });
  }

  _createHelpRequest(type) {
    return this._helpRequestService
      .create(this.rosterId, this.assignmentId, this.questionId, type)
      .then((request) => {
        if (this._helpRequestSet) {
          this._helpRequestSet.localAdd(request);
          this._loadingFor = undefined;
        }
      });
  }

  _notifyHelpRequest(type) {
    this._toolbarService.notify(
      type,
      { questionId: this.questionId, needsFocusedElement: true },
      false,
    );
  }

  get currentUserId() {
    return this._authService.currentUserId;
  }

  get helpeeId() {
    return this.assignmentWork && this.assignmentWork.ownerId;
  }

  get assignmentWork() {
    return this._toolbarService.target;
  }

  get assignment() {
    return this.assignmentWork.assignment;
  }

  get questionId() {
    return this._toolbarService.contentQuestion.id;
  }

  get rosterId() {
    return this.assignmentWork.rosterId;
  }

  get assignmentId() {
    return this.assignment.id;
  }
}
