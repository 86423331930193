'use strict';

/**
 * All the errors that we have
 */
export default class ErrorCodes {
  /** @returns {string} */
  static get DUPLICATE_EMAIL() {
    return 'duplicate-email';
  }

  /** @returns {string} */
  static get DUPLICATE_USERNAME() {
    return 'duplicate-username';
  }

  /** @returns {string} */
  static get DUPLICATE_PHONE_NUMBER() {
    return 'duplicate-phone';
  }

  /** @returns {string} */
  static get ROSTER_LOCKED() {
    return 'roster-locked';
  }

  /** @returns {string} */
  static get CANT_CREATE_CLASS_CODE() {
    return 'cant-create-classcode';
  }

  /** @returns {string} */
  static get CANT_CREATE_INVITE_CODE() {
    return 'cant-create-invite-code';
  }

  /** @returns {string} */
  static get BAD_INVITE_CODE() {
    return 'bad-invite-code';
  }

  /** @returns {string} */
  static get INVITE_CODE_REQUIRED() {
    return 'invite-code-required';
  }

  /** @return {string} */
  static get INVITE_CODE_OR_CLASS_CODE_REQUIRED() {
    return 'invite-code-or-class-code-required';
  }

  /** @returns {string} */
  static get UNSUPPORTED_UPDATE_ANON_STUDENT_ACCOUNT() {
    return 'unsupported-update-anon-student-account';
  }

  /** @returns {string} */
  static get UNSUPPORTED_UPDATE_EMAIL() {
    return 'unsupported-update-email';
  }

  /** @returns {string} */
  static get UNSUPPORTED_UPDATE_PHONE() {
    return 'unsupported-update-phone-number';
  }

  /** @returns {string} */
  static get UNSUPPORTED_UPDATE_USERNAME() {
    return 'unsupported-update-username';
  }

  /** @returns {string} */
  static get INVALID_AUTHORIZATION() {
    return 'invalid-authorization';
  }

  /** @returns {string} */
  static get NOT_READABLE() {
    return 'not-readable';
  }

  /** @returns {string} */
  static get INVALID_ARGUMENTS() {
    return 'invalid-arguments';
  }

  /** @returns {string} */
  static get NOT_FOUND() {
    return 'not-found';
  }

  /** @returns {string} */
  static get INSUFFICIENT_PERMISSIONS() {
    return 'insufficient-permissions';
  }

  /** @returns {string} */
  static get INVALID_CREDENTIALS() {
    return 'invalid-credentials';
  }

  /** @returns {string} */
  static get TOO_MANY_REQUESTS() {
    return 'too-many-requests';
  }

  /** @returns {string} */
  static get PAYMENT_FAILED() {
    return 'payment-failed';
  }

  /** @returns {string} */
  static get INVALID_POINT_EMAIL() {
    return 'invalid-point-email';
  }

  /** @returns {string} */
  static get INTERNAL_SERVER_ERROR() {
    return 'internal-server-error';
  }

  /** @returns {string} */
  static get UNKNOWN() {
    return 'unknown';
  }

  /** @returns {string} */
  static get GOOGLE_AUTH_SETUP_FAILED() {
    return 'google-auth-setup-failed';
  }

  /** @returns {string} */
  static get GOOGLE_POPUP_CLOSED_BY_USER() {
    return 'popup_closed_by_user';
  }

  /** @returns {string} */
  static get GOOGLE_ACCESS_DENIED() {
    return 'access_denied';
  }

  /** @returns {string} */
  static get PASSWORD_NOT_CONFIGURED() {
    return 'password-not-configured';
  }

  /** @returns {string} */
  static get CLEVER_EMAIL_NOT_SET() {
    return 'clever-email-not-set';
  }

  /** @return {string} */
  static get FIREBASE_AUTH_INVALID_CUSTOM_TOKEN() {
    return 'auth/invalid-custom-token';
  }
}
