'use strict';

/**
 * A request to copy questions across assignments
 */
export default class CopyQuestionsRequest {
  /**
   * @param from_assignment_id {string} the id of the assignment where questions are copied from
   * @param question_ids {Array.<string>} the ids of questions
   */
  constructor(from_assignment_id, question_ids) {
    this.from_assignment_id = from_assignment_id;
    this.question_ids = question_ids;
  }
}
