import LocationUtil from './location-util';

export default class PreviewStudentUtil {
  static openStudentPreview({
    analyticsService,
    pageLocation,
    assignment,
    questionId,
    assignmentService,
    loadingDialog,
    $mdDialog,
    $location,
    $window,
  }) {
    analyticsService.openStudentPreview(pageLocation, assignment.id);
    const promise = assignmentService
      .generateStudentPreview(assignment)
      .then((data) => {
        const page = questionId
          ? data.assignmentWork.indexForQuestionId(questionId) + 1
          : 1;
        let url = `${LocationUtil.absRootUrl($location)}/preview/${assignment.id}/${data.classCode.classCode}/${data.roster.id}/${data.user.displayName}/${page}`;
        $window.open(url, '_blank');
      });
    loadingDialog($mdDialog, promise);
  }
}
