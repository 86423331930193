import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Snap from 'snapsvg-cjs';
import platform from 'platform';
import fs from 'fs';
import Helvetica from '!!raw-loader!pdfkit/js/data/Helvetica.afm';
fs.writeFileSync('data/Helvetica.afm', Helvetica);
import { FileUtil } from '../../model/util/file-util';
import HexColors from '../../css-constants';
import LocationUtil from '../../model/util/location-util';
import StaticService from '../../services/static/static.service';
import Order, { OrderStatus } from '../../model/domain/order';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import SelectPurchasePeriodDialogController from '../../components/select-purchase-period-dialog/select-purchase-period-dialog.controller';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import CkAnimations from '../../model/util/ck-animations';
import { OrderManager } from '../../model/domain/order-manager';
import FinancialAidDialogController from '../../components/financial-aid-dialog/financial-aid-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';

const fontSizeHeader = 16;
const fontSizeSubheader = 11;
const fontSizeRegular = 9.5;
const fontSizeSmall = 8.5;
const fontColorHeader = HexColors.CK_ELEMENT_BLACK;
const fontColorBody = HexColors.CK_ORDER_BODY_BLACK;
const invoiceGrey = '#d9d9d9';
import gothamRoundedBookFont from '../../../assets/fonts/GothamRnd-Book.otf';
import gothamRoundedMediumFont from '../../../assets/fonts/GothamRnd-Medium.otf';
import logoImage from '../../../assets/images/brand/classkick-logo-rgb_small.png';
import w9Icon from '../../../assets/images/order/w9-2024.png';
import {
  Locations,
  TrackingIds,
  TrackingLocations,
} from '../../services/mixpanel/mixpanel.service';
import Contract from '../../model/domain/contract';
import { OrganizationTypes } from '../../model/domain/organization';

export default class OrderDetailController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $log,
    $scope,
    $document,
    $state,
    $stateParams,
    $timeout,
    $filter,
    $location,
    $mdDialog,
    $mdToast,
    $window,
    hotkeys,
    CacheService,
    OrderService,
    OrganizationService,
    ImageEditService,
    HttpService,
    BreadcrumbService,
    StaticService,
    AuthService,
    StaticContentService,
    AnalyticsService,
    ContractService,
  ) {
    this.$q = $q;
    this.$filter = $filter;
    this.$log = $log;
    this.$scope = $scope;
    this.$document = $document;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$filter = $filter;
    this.$location = $location;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$window = $window;
    this._window = angular.element($window);

    this._hotkeys = hotkeys;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StaticService} */
    this._staticService = StaticService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ContractService} */
    this._contractService = ContractService;

    this._state = this.Loading;
    this._errorMessage = this.DefaultErrorMessage;

    this._order = undefined;
    this._organization = undefined;
    this._gothamRoundedBook = undefined;
    this._gothamRoundedMedium = undefined;
    this._logo = undefined;
    this._w9 = undefined;
    this._pdf = undefined;
    this._faqsSpacingY = undefined;
    this._shoutoutMetrics = {};
    this._mostActiveTeachers = [];

    this._orderPageLink = `${LocationUtil.absRootUrl(this.$location)}/order/${this.orderId}`;
    this._w9Link = 'https://classkick.com/w9';
    this._termsOfServiceLink = 'https://classkick.com/terms-of-service';
    this._faqsLink =
      'https://classkick.zendesk.com/hc/en-us/categories/360005756031-Billing-and-Payments';
    this._thirdPartyLink =
      'https://www.similarweb.com/top-websites/united-states/science-and-education/education/';
    this._researchLink =
      'https://educationendowmentfoundation.org.uk/education-evidence/teaching-learning-toolkit';
    this._videoLink =
      'https://www.youtube.com/watch?v=l5vza3HTW5I&feature=youtu.be';
    this._privacyPolicy = 'https://www.classkick.com/privacy-policy';

    this._orderManager = new OrderManager(
      $q,
      $log,
      $filter,
      $mdDialog,
      $location,
      AuthService,
      OrderService,
      OrganizationService,
      BreadcrumbService,
      CacheService,
      ContractService,
    );

    this._anonOnResize = this._onResize.bind(this);
    this._window.on('resize', this._anonOnResize);

    this.$scope.$on('$destroy', () => this._destroy());

    this._loadingDialog = LoadingDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._selectPurchasePeriodDialog =
      SelectPurchasePeriodDialogController.show;
    this._financialAidDialog = FinancialAidDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;

    this._initHotkeys(this.$scope, hotkeys);
    this._init();
  }

  get state() {
    return this._state;
  }

  get Loading() {
    return 'loading';
  }

  get Error() {
    return 'error';
  }

  get ViewQuote() {
    return 'view_quote';
  }

  get ViewRenewal() {
    return 'view_renewal';
  }

  get ViewInvoiced() {
    return 'view_invoiced';
  }

  get ViewPaid() {
    return 'view_paid';
  }

  get ViewPastDue() {
    return 'view_past_due';
  }

  get ViewCancelled() {
    return 'view_cancelled';
  }

  /**
   * @return {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  /**
   * @return {string}
   */
  get DefaultErrorMessage() {
    return 'Sorry, there was a problem loading your quote';
  }

  _initHotkeys(scope, hotkeys) {
    let printCombo = StaticService.get.isMac ? 'command+p' : 'ctrl+p';

    hotkeys.bindTo(scope).add({
      combo: printCombo,
      description: 'Print',
      callback: (event) => {
        event.preventDefault();
        this.exportAsPDF();
      },
    });
  }

  _onResize() {
    this.$timeout(() => {});
  }

  _destroy() {
    this._window.off('resize', this._anonOnResize);
  }

  _init() {
    // check that an order id is present or show error
    if (!this.orderId) {
      this._state = this.Error;
      return;
    }

    if (!this.$stateParams.isIframe) {
      this._analyticsService.viewOrderDetails(this.orderId);
    }

    return this._orderService
      .get(this.orderId)
      .then((order) => {
        this._order = order;

        return this.$q.all({
          organization: this._organizationService.get(
            this._order.organizationId,
          ),
          loadPDFData: this._loadPDFData(),
          user: this.isLoggedIn ? this._cacheService.getUser(true) : null,
          cachedOrganizations:
            this._authService.isLoggedIn &&
            this._cacheService.getOrganizations(),
        });
      })
      .then(({ organization, user, cachedOrganizations }) => {
        // build data object for tracking user activity
        let trackingInfos = [
          {
            trackingId: TrackingIds.QUOTE_PAGE_VIEW,
            userId: user ? user.id : null,
            source: Locations.ORDER_DETAILS,
            targets: [],
          },
        ];

        if (this.$stateParams.lock_in_price === 'true') {
          const lockInPriceSource = !this.$stateParams.source
            ? Locations.EMAIL
            : this.$stateParams.source;
          const userId = !this.$stateParams.uid
            ? user
              ? user.id
              : null
            : this.$stateParams.uid;
          trackingInfos.push({
            trackingId: TrackingIds.CONVERSION_VIEW,
            userId: userId,
            source: lockInPriceSource,
            targets: [],
          });
        }

        this._organization = organization;

        if (cachedOrganizations) {
          const matchingOrg = cachedOrganizations.find(
            (org) => org.id === this._order.organizationId,
          );
          this._contract = matchingOrg && matchingOrg.contract;
          this.daysLeft = Contract.contractCountdown(this.contract);
        } else if (organization.contractId) {
          this._contractService
            .get(organization.contractId)
            .then((contract) => {
              this._contract = contract;
              this.daysLeft = Contract.contractCountdown(this.contract);
            })
            .catch((err) => {
              this.$log.debug(
                'Order not associated with a valid contract, no countdown set.',
                err,
              );
            });
        }

        // use info to create PDF
        const finAidCouponIndex = this._order.couponIds.findIndex((couponId) =>
          couponId.includes('financial-aid'),
        );
        this._finAidCouponId = this._order.couponIds[finAidCouponIndex];

        return this.$q.all({
          pdf: this._createPDF(this._order, organization),
          track: this._orderService.trackAll(this._order.id, trackingInfos),
        });
      })
      .then(({ pdf, track }) => {
        this._pdf = pdf;

        return this._orderService
          .getTrackingDetails(this.orderId)
          .then((trackingDetails) => {
            this._trackingDetails = trackingDetails;
            this.getShoutoutCount();
          });
      })
      .catch((errors) => {
        this.$log.error(errors);
        this._state = this.Error;
      })
      .finally(() => {
        if (this._order.status === OrderStatus.Created) {
          if (this.$stateParams.lock_in_price === 'true') {
            const clickedFrom = !this.$stateParams.source
              ? Locations.EMAIL
              : this.$stateParams.source;
            const emailUserId = this.$stateParams.uid;
            this._orderManager.reservePrice(
              this._order,
              clickedFrom,
              emailUserId,
            );
          }
          return this._renderPDF(this._pdf);
        } else if (this._order.status === OrderStatus.Invoiced) {
          this._state = this.ViewInvoiced;
        } else if (this._order.status === OrderStatus.Paid) {
          this._state = this.ViewPaid;
        } else if (this._order.status === OrderStatus.PastDue) {
          this._state = this.ViewPastDue;
        } else if (this._order.status === OrderStatus.Canceled) {
          this._state = this.ViewCancelled;
        }
      });
  }

  _loadPDFData() {
    return this.$q
      .all({
        // pdfkit: this._loadScript('node_modules/pdfkit/js/pdfkit.js'),
        // blobStream: blobStream,
        // pdfkit requires fonts/media as ArrayBuffer so we're downloading them as such here
        gothamRoundedBook: this._getAsBuffer(gothamRoundedBookFont),
        gothamRoundedMedium: this._getAsBuffer(gothamRoundedMediumFont),
        logo: this._getAsBuffer(logoImage),
        W9: this._getAsBuffer(w9Icon),
      })
      .then(({ gothamRoundedBook, gothamRoundedMedium, logo, W9 }) => {
        this._gothamRoundedBook = gothamRoundedBook;
        this._gothamRoundedMedium = gothamRoundedMedium;
        this._logo = logo;
        this._W9 = W9;
      });
  }

  /**
   * Attempts to get the statistics and then creates a PDF for organization's order
   *
   * @param order
   * @param organization
   * @returns {Promise}
   * @private
   */
  _createPDF(order, organization) {
    let promise = this.isLoggedIn
      ? this._organizationService.getUsageStatsWithAuth(organization.id)
      : this._organizationService.getUsageStatsWithoutAuth(organization.id);

    return promise
      .then((stats) => {
        this._mostActiveTeachers = stats.activeTeachers.slice(0, 3);
        return this._createPDFWithStats(order, organization, stats);
      })
      .catch((err) => {
        this.$log.debug(
          'Unable to get organization stats, attempt to generate PDF without it',
          err,
        );
        return this._createPDFWithStats(order, organization);
      });
  }

  /**
   * Creates a PDF for an organization's order with optional statistics
   * @param order
   * @param organization
   * @param organizationStats (Optional)
   * @returns {*}
   * @private
   */
  _createPDFWithStats(order, organization, organizationStats = undefined) {
    if (this.order.total <= 0) {
      this._errorDialog(
        this.$mdDialog,
        'Sorry, there was an issue displaying your quote.',
        'If you are renewing with us, your district contact has been emailed about your renewal. <br><br>Please email partnerships@classkick.com for any questions.',
      );
      return;
    }

    if (this._staticService.isSafari || this._staticService.isEdge) {
      this._errorMessage = `Classkick is not supported in ${platform.name}, please view this quote in Chrome or Firefox`;
      return this.$q.reject();
    }

    let num_pages = 'thousands';
    let num_feedbacks = 'the many thousands of';
    if (
      angular.isDefined(organizationStats) &&
      organizationStats.organization_stats
    ) {
      const assignments_worked_on_this_year =
        organizationStats.organization_stats.assignments_worked_on_this_year;
      const help_requests_resolved_this_year =
        organizationStats.organization_stats.help_requests_resolved_this_year;
      if (assignments_worked_on_this_year > 1000) {
        num_pages = assignments_worked_on_this_year.toLocaleString();
      }
      if (help_requests_resolved_this_year > 1000) {
        num_feedbacks = help_requests_resolved_this_year.toLocaleString();
      }
    }

    let plan = order.plan;
    let filteredCoupons = order.coupons.filter(
      (coupon) =>
        coupon.id !== 'free-trial-for-v5-plans' ||
        coupon.id !== 'free-trial-for-v6-plans',
    );

    //if an existing school is signing up for 2 new years, the service sends back two coupons for 'promotional discount'
    //we only want to display it once in the PDF so it is filtered out here
    if (order.duration >= 2) {
      filteredCoupons = order.coupons.filter(
        (coupon) =>
          coupon.id !== 'free-trial-for-v5-plans' ||
          coupon.id !== 'free-trial-for-v6-plans',
      );
    }

    let coupons = Array.from(new Set(filteredCoupons));

    let deferred = this.$q.defer();
    let doc = new PDFDocument();
    let stream = doc.pipe(blobStream());

    doc.registerFont('GothamRoundedBook', this._gothamRoundedBook);
    doc.registerFont('GothamRoundedMedium', this._gothamRoundedMedium);

    // 612 is approx width
    let width = 612;
    let startX = 60;
    let startY = 50;
    let innerWidth = width - startX * 2;

    // First row
    doc.image(this._logo, startX, startY - 10, { fit: [100, 25] });

    doc
      .font('GothamRoundedMedium')
      .fontSize(36)
      .fillColor(invoiceGrey)
      .text('QUOTE', startX + 360, startY - 20, {
        width: 200,
      });

    // Second row
    let startSecondY = startY + 35;

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text('Vendor', startX, startSecondY);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text('CLASSWORK CO DBA Classkick', startX, startSecondY + 15)
      .text('Email: pro@classkick.com');

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(
        `${organization.type.slice(0, 1).toUpperCase()}${organization.type.slice(1)}`,
        startX + 190,
        startSecondY,
      );

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(organization.name, startX + 190, startSecondY + 15, {
        width: 166,
      })
      .text(organization.street || '', {
        width: 166,
      })
      .text(organization.displayLocation, {
        width: 166,
      });

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(`Quote #${order.quote}`, startX + 361, startSecondY);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(
        `Quote Issued: ${order.createdAsDate}`,
        startX + 361,
        startSecondY + 15,
      )
      .text(`Quote Expires: ${order.expiresAsDate}`);

    let startThirdY = startY + 100;

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeHeader)
      .fillColor(fontColorHeader)
      .text('Quote Order Details', startX, startThirdY);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .fillColor(fontColorBody)
      .text(
        `${plan.description} Memberships are active upon purchase. A ${organization.type} includes unlimited teachers and up to 1,500 students.`,
        startX,
        startThirdY + 30,
      );

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .fillColor(fontColorBody)
      .text(
        'In order to take advantage of any promotional discounts, this quote must be paid before the quote expiration date shown above.',
        startX,
        startThirdY + 75,
      );
    // Fourth row
    let detailsTableY = startThirdY + 110;

    if (this.showFinancialAidLink) {
      detailsTableY += 15;
    }

    this._createDetailsTable(
      doc,
      startX,
      detailsTableY,
      innerWidth,
      order,
      plan,
      coupons,
    );

    let schoolDetailsY = startY + 335;

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeRegular)
      .fillColor(fontColorHeader)
      .text('School Details', -125, startY + schoolDetailsY, {
        underline: false,
      });

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(`Teachers at ${organization.name}`, startX, schoolDetailsY + 70, {
        width: innerWidth,
        continued: true,
      })
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(
        ` this year gave students ${num_pages} of pages of Classkick work - saving what at most schools is about $1,598 on paper, ink, copier and printer costs. Each time, ${organization.name} students engaged with their work an average of 255% more minutes than the average of the world’s top 20 edtech apps, according to publicly available 3rd party data `,
        {
          continued: true,
        },
      )
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('here.', {
        link: this._thirdPartyLink,
        underline: true,
        continued: true,
      })
      .fillColor(fontColorBody)
      .text(
        ` What kept ${organization.name} students most engaged were ${num_feedbacks} times they received help, encouragement and feedback on Classkick from their teachers and classmates. `,
        {
          continued: true,
          underline: false,
        },
      )
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('Research ', {
        link: this._researchLink,
        underline: true,
        continued: true,
      })
      .fillColor(fontColorBody)
      .text(
        'connects this extra student feedback with 6 or more months of added learning per school year.',
        {
          underline: false,
        },
      );

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('Watch Video', width / 2 - 50, schoolDetailsY + 160, {
        link: this._videoLink,
        width: innerWidth,
        continued: true,
      });

    doc
      .moveTo(startX, startY + 530)
      .lineTo(startX + 492, startY + 530)
      .dash(10, { space: 10 })
      .stroke();

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeRegular)
      .fillColor(fontColorHeader)
      .text('1. Submit a purchase order', -10, startX + 530);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(HexColors.CK_LINK_BLUE)
      .undash()
      .text(this._orderPageLink, startX, startY + 555, {
        link: this._orderPageLink,
        underline: true,
      });

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeRegular)
      .fillColor(fontColorHeader)
      .text(
        '2. Then mail check if not paying by credit card',
        startX + innerWidth / 2,
        startY + 540,
      );

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text('CLASSWORK CO DBA Classkick', startX + innerWidth / 2, startY + 555)
      .text('PO Box 772728')
      .text(`Area ${this.order.quote}`)
      .text('Detroit, MI 48277-2728');

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(
        `In the check memo please include the Area number: ${this.order.quote}`,
        startX + innerWidth / 2,
        startY + 600,
      );

    // Fine print
    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(
        'Our W-9 below (page 2 of this quote) or you can download it at here: ',
        startX,
        startThirdY + 529,
        {
          continued: true,
        },
      )
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('classkick.com/w9.', {
        link: this._w9Link,
        underline: true,
        continued: true,
      })
      .fillColor(fontColorBody)
      .text(
        ' Membership renews automatically, unless canceled in writing or via software application system earlier than 30 days before end date. This Order is governed by the terms of Classkick’s Terms of Service found at ',
        {
          continued: true,
          underline: false,
        },
      )
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('Terms of Service.', {
        link: this._termsOfServiceLink,
        underline: true,
        continued: true,
      })
      .fillColor(fontColorBody)
      .text(' Please see our ', {
        continued: true,
        underline: false,
      })
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('Privacy Policy.', {
        link: this._privacyPolicy,
        underline: true,
        continued: true,
      });

    doc.addPage();

    doc.image(this._W9, 50, 0, { scale: 0.4 });

    doc.end();

    stream.on('finish', () => {
      let pdf = stream.toBlob('application/pdf');
      let file = new File([pdf], this.fileName);
      deferred.resolve(file);
    });

    return deferred.promise;
  }

  _createDetailsTable(doc, startX, startY, innerWidth, order, plan, coupons) {
    // Header box and text
    doc
      .rect(startX, startY, innerWidth, 25)
      .lineWidth(1)
      .fillAndStroke(invoiceGrey);

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fillColor(fontColorBody)
      .text('Description', startX + 7, startY + 7);

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fillColor(fontColorBody)
      .text('# of School Years', startX + 205, startY + 7);

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fillColor(fontColorBody)
      .text('Qty', startX + 367, startY + 7);

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fillColor(fontColorBody)
      .text('Quote Price', startX + 414, startY + 7, {
        width: 70,
        align: 'right',
      });

    // Item description, quantity, and price
    let lineItemBoxHeight = 45;

    // if plan description is greater than 3 words, then add a new line after 3 words.
    let planDescriptionSplit = plan.description.split(' ');
    if (
      plan.description !== 'Classkick Pro District School' &&
      plan.description !== 'Classkick Pro Virtual School'
    ) {
      if (planDescriptionSplit.length > 3) {
        planDescriptionSplit.splice(3, 0, '\n');
      }
    }

    doc
      .rect(startX, startY + 25, 360, lineItemBoxHeight)
      .lineWidth(1)
      .stroke(invoiceGrey);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .text(planDescriptionSplit.join(' '), startX + 7, startY + 37);

    // Duration box
    doc
      .rect(startX + 198, startY + 25, 162, lineItemBoxHeight)
      .lineWidth(1)
      .stroke(invoiceGrey);

    const yearDisplay = `${order.duration} school year${order.duration > 1 ? 's' : ''}`;
    let startDateDisplay = 'Today';

    if (order.start.isAfter(moment())) {
      startDateDisplay = `${order.start.format('MMM DD, YYYY')}`;
    }

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .text(yearDisplay, startX + 205, startY + 32)
      .text(`Start: ${startDateDisplay}`)
      .text(`End: ${order.end.format('MMM DD, YYYY')}`);

    // Quantity box
    doc
      .rect(startX + 360, startY + 25, 50, lineItemBoxHeight)
      .lineWidth(1)
      .stroke(invoiceGrey);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .text(order.quantity, startX + 367, startY + 37);

    doc
      .rect(startX + 410, startY + 25, 82, lineItemBoxHeight)
      .lineWidth(1)
      .stroke(invoiceGrey);

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .text(this.currency(order.totalPrice), startX + 414, startY + 37, {
        width: 70,
        align: 'right',
      });

    // Promotional discount
    let promotionRowHeight = 0;
    let promotionRowStartY = startY + lineItemBoxHeight + 25;

    if (coupons.length > 0 && order.totalDiscount > 0) {
      promotionRowHeight = 40 + (coupons.length - 1) * 9;
      this._createPromotionRow(
        doc,
        startX,
        promotionRowStartY,
        promotionRowHeight,
        order,
        coupons,
      );
    }

    // Total row
    let totalRowStartY = promotionRowStartY + promotionRowHeight;

    doc
      .rect(startX, totalRowStartY, 492, 30)
      .lineWidth(1)
      .fillAndStroke(invoiceGrey);

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fill(fontColorBody)
      .text(
        `${this.isDistrictInAppOrder ? 'Special: Buy-1-Year-Get-1-Free Total' : 'Total'}`,
        startX + 7,
        totalRowStartY + 10,
      );

    doc
      .font('GothamRoundedMedium')
      .fontSize(fontSizeSubheader)
      .fill(fontColorBody)
      .text(this.currency(order.total), startX + 414, totalRowStartY + 10, {
        width: 70,
        align: 'right',
      });

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text('Visit our ', startX, totalRowStartY + 45, {
        width: innerWidth,
        continued: true,
      })
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('FAQs', {
        link: this._faqsLink,
        underline: true,
        continued: true,
      })
      .fillColor(fontColorBody)
      .text(' for more details', {
        underline: false,
        continued: true,
      });

    if (coupons.length && order.totalDiscount > 0) {
      this._faqsSpacingY = totalRowStartY + 250;
      this._schoolDetailsY = this._faqsSpacingY + 90;
    } else {
      this._faqsSpacingY = totalRowStartY + 225;
      this._schoolDetailsY = this._faqsSpacingY + 160;
    }

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .fillColor(HexColors.CK_ELEMENT_GREEN)
      .text('Reserve Price', startX + 300, totalRowStartY + 35, {
        underline: true,
        continued: true,
      });
  }

  _createPromotionRow(doc, startX, startY, rowHeight, order, coupons) {
    doc.rect(startX, startY, 492, rowHeight).lineWidth(1).stroke(invoiceGrey);

    coupons.forEach((coupon, index) => {
      doc
        .font('GothamRoundedBook')
        .fontSize(fontSizeRegular)
        .text(`${coupon.description}`, startX + 7, startY + 11 + index * 12);
    });

    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeRegular)
      .text(
        this.currency(-1 * order.totalDiscount),
        startX + 414,
        startY + 11,
        {
          width: 70,
          align: 'right',
        },
      );
  }

  _renewalPolicy(doc, startX, startY, innerWidth) {
    doc
      .font('GothamRoundedBook')
      .fontSize(fontSizeSmall)
      .fillColor(fontColorBody)
      .text(
        'By submitting a payment or purchase order, you are agreeing to the Classkick Terms of Service found at ',
        startX,
        startY,
        {
          width: innerWidth,
          continued: true,
        },
      )
      .fillColor(HexColors.CK_LINK_BLUE)
      .text('classkick.com/terms', {
        link: this._termsOfServiceLink,
        underline: true,
        continued: true,
      })
      .fillColor(fontColorBody)
      .text(
        ', the terms and conditions of which are hereby expressly incorporated herein by reference.',
        {
          underline: false,
          continued: true,
        },
      );
  }

  _renderPDF(file) {
    this._imageEditService.pdfFromFile(file).then((data) => {
      data.getPage(1).then((page) => {
        let viewport = page.getViewport(1.5);
        let canvas = angular.element('.order-canvas')[0];
        let canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page.render({ canvasContext, viewport }).promise.then(() => {
          this._renderOverlay();
          this._state = this.ViewQuote;
          this.$timeout(() => {}).then(() => this.$timeout(() => {}));
        });
      });

      data.getPage(2).then((page) => {
        let viewport = page.getViewport(1.5);
        let canvas = angular.element('.w9-canvas')[0];
        let canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page.render({ canvasContext, viewport }).promise.then(() => {});
      });
    });
  }

  _renderOverlay() {
    let svg = angular.element('.order-overlay svg')[0];
    let snap = new Snap(svg);
    let root = snap.select('.root');

    // Ensures that previous overlay elements are removed when we reload after saving
    root.clear();

    if (!this.isDistrictInAppOrder) {
      root
        .text(510, this.showFinancialAidLink ? 550 : 448, 'Change this')
        .attr({
          fill: HexColors.CK_ORDER_BODY_BLACK,
          fontFamily: 'GothamRoundedMedium',
          fontSize: '13px',
          textDecoration: 'underline',
        });

      // Change purchase period overlay
      root
        .rect(500, 428, 120, 35)
        .attr({
          fill: 'transparent',
          cursor: 'pointer',
        })
        .click(() => this.updatePurchasePeriod());
    }

    // if (this.showFinancialAidLink) {
    //   root.text(90, 472, 'View Financial Aid Application')
    //     .attr({
    //       fill: HexColors.CK_GREEN,
    //       fontFamily: 'GothamRoundedMedium',
    //       fontSize: '14px',
    //       textDecoration: 'underline'
    //     });

    //   // View financial aid application overlay
    //   root.rect(90, 455, 215, 25)
    //     .attr({
    //       fill: 'transparent',
    //       cursor: 'pointer'
    //     })
    //     .click(() => this.openFinancialAidApplication());
    // }

    // Go to FAQs
    const faqLinkPlacement = this.isDistrictOrder
      ? this._faqsSpacingY - 20
      : this._faqsSpacingY;
    root
      .rect(90, faqLinkPlacement, 200, 20)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToFAQs());

    // Reserve Price link modal
    root
      .rect(700, this._faqsSpacingY - 40, 150, 60)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.reservePrice());

    // Go to 3rd pary link
    const thirdPartyLinkPlacement = this.isDistrictOrder
      ? this._schoolDetailsY + 40
      : this._schoolDetailsY;
    root
      .rect(90, thirdPartyLinkPlacement, 750, 30)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToThirdPartyLink());

    // Go to research link
    const researchLinkPlacement = this.isDistrictOrder
      ? this._schoolDetailsY + 75
      : this._schoolDetailsY + 35;
    root
      .rect(90, researchLinkPlacement, 750, 40)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToResearchLink());

    // Go to video link
    const videoLinkPlacement = this.isDistrictOrder
      ? this._schoolDetailsY + 130
      : this._schoolDetailsY + 90;
    root
      .rect(330, videoLinkPlacement, 200, 40)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToVideoLink());

    // Go to PO link
    root
      .rect(90, 900, 280, 20)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToOrderLink());

    // Go to W9
    root
      .rect(530, 1018, 120, 20)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToW9());

    // Go to TOS
    root
      .rect(560, 1045, 135, 20)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToTOS());

    // Go to Privacy Policy
    root
      .rect(90, 1060, 135, 20)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      })
      .click(() => this.goToPrivacyPolicy());
  }

  get overlayStyle() {
    return {
      width: this.canvasContainer.width(),
      height: this.canvasContainer.height(),
    };
  }

  get canvasContainer() {
    if (!this._canvasContainer) {
      this._canvasContainer = angular.element('.canvas-container');
    }
    return this._canvasContainer;
  }

  /**
   * @return {string}
   */
  get orderId() {
    return this.$stateParams.orderId;
  }

  /**
   * @return {Order}
   */
  get order() {
    return this._order;
  }

  /**
   * @return {Organization}
   */
  get organization() {
    return this._organization;
  }

  /**
   * @return {boolean}
   */
  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  /**
   * @return {OrderPlan}
   */
  get orderPlan() {
    return this._order && this._order.plan;
  }

  /**
   * @return {string}
   */
  get orderPlanName() {
    return this.orderPlan && this.orderPlan.description;
  }

  /**
   * @return {boolean}
   */
  get showFinancialAidLink() {
    // deactivate ability to receive financial aid
    // leaving the method if we want to re-enable ability to offer financial aid
    return false;
  }

  get isDistrictInAppOrder() {
    return (
      this.organization.type === OrganizationTypes.District &&
      this._order.duration === 2
    );
  }

  get isDistrictOrder() {
    return this.organization.type === OrganizationTypes.District;
  }

  /**
   * @return {string}
   */
  get contract() {
    return this._contract;
  }

  /**
   * @return {boolean}
   */
  get isActiveFreeTrial() {
    return !!(
      this.contract &&
      this.contract.isTrial &&
      !this.contract.isExpired
    );
  }

  /**
   * @return {Object}
   */
  get shoutoutMetrics() {
    return this._shoutoutMetrics;
  }

  /**
   * @return {OrderTracking[]}
   */
  get trackingDetails() {
    return this._trackingDetails;
  }

  /**
   * @return {string}
   */
  get pageViewsId() {
    return TrackingIds.QUOTE_PAGE_VIEW;
  }

  /**
   * @return {string}
   */
  get emailAdminId() {
    return TrackingIds.EMAIL_SENT;
  }

  /**
   * @return {string}
   */
  get conversionCommitmentId() {
    return TrackingIds.CONVERSION_COMMITMENT;
  }

  /**
   * @return {string}
   */
  get conversionViewId() {
    return TrackingIds.CONVERSION_VIEW;
  }

  /**
   * @return {string}
   */
  currency(amount) {
    return this.$filter('currency')(amount, '$', 0);
  }

  getTeacherName(teacher) {
    let name = '';
    if (teacher.display_name && teacher.display_name.includes(' ')) {
      // get prefix if it exists
      name += teacher.display_name.split(' ')[0];
    }
    teacher.first_name ? (name += ` ${teacher.first_name}`) : null;
    if (this.isLoggedIn && teacher.last_name) {
      name += ` ${teacher.last_name}`;
    }
    name = name.trim();
    return name && !name.toLowerCase().includes('undefined')
      ? name
      : 'Anonymous Teacher';
  }

  orderByCreditCard() {
    this._analyticsService.payWithCreditCard(this.orderId);
    this._breadcrumbService.go('root.payment-order', {
      orderId: this.orderId,
    });
  }

  orderByPurchaseOrder() {
    this._analyticsService.submitPurchaseOrder(this.orderId);
    this._breadcrumbService.go('root.purchase-order', {
      orderId: this.orderId,
    });
  }

  openFinancialAidApplication() {
    this._financialAidDialog(this.$mdDialog, this._order).then(
      ({ name, email, role }) => {
        this._submitFinancialAidApplication(name, email, role);
      },
    );
  }

  _submitFinancialAidApplication(name, email, role) {
    let promise = this.$q.all([
      this._updateOrder(this._order.start, this._order.end, true),
      this._organizationService.addAdmin(
        this._organization.id,
        name,
        email,
        role,
      ),
    ]);

    this._loadingDialog(this.$mdDialog, promise);

    promise.then(() => {
      const finAidCoupon = this._order._activeCoupons.find(
        (coupon) => coupon.id === this._finAidCouponId,
      );
      this._confirmDialog(
        this.$mdDialog,
        `Congratulations! We have approved your ${this._organization.type}'s financial aid application`,
        `We removed $${finAidCoupon.discount} per school per year from your current order`,
        true,
      );
    });
  }

  updatePurchasePeriod() {
    this._analyticsService.changeOrderDates(this.orderId);
    if (!this.order.renewal) {
      this._selectPurchasePeriodDialog(
        this.$mdDialog,
        this.organization,
        this.order,
      ).then(({ start, end }) => {
        let promise = this._updateOrder(start, end, this.order.financialAid);
        this._loadingDialog(this.$mdDialog, promise);
      });
    } else {
      this._errorDialog(
        this.$mdDialog,
        'Whoops, we are unable to change your renewal quote.',
        'Please contact us at partnerships@classkick.com',
      );
    }
  }

  _updateOrder(start, end, financialAid) {
    this.order.start = start;
    this.order.end = end;
    this.financialAid = financialAid;
    return this._orderService.update(this.order).then(() => {
      return this._init();
    });
  }

  goToOrderLink() {
    this._analyticsService.goToOrderLink(this.orderId);
    this.$window.open(this._orderPageLink, '_blank');
  }

  goToW9() {
    this._analyticsService.goToW9(this.orderId);
    this.$window.open(this._w9Link, '_blank');
  }

  goToPrivacyPolicy() {
    this._analyticsService.goToPrivacyPolicy(this.orderId);
    this.$window.open(this._privacyPolicy, '_blank');
  }

  goToTOS() {
    this._analyticsService.goToTOS(this.orderId);
    this.$window.open(this._termsOfServiceLink, '_blank');
  }

  goToFAQs() {
    this._analyticsService.goToFaqs(this.orderId);
    this.$window.open(this._faqsLink, '_blank');
  }

  goToThirdPartyLink() {
    this._analyticsService.goToThirdPartyLink(this.orderId);
    this.$window.open(this._thirdPartyLink, '_blank');
  }

  goToResearchLink() {
    this._analyticsService.goToResearchLink(this.orderId);
    this.$window.open(this._researchLink, '_blank');
  }

  goToVideoLink() {
    this._analyticsService.goToVideoLink(this.orderId);
    this.$window.open(this._videoLink, '_blank');
  }

  goToTeacherResourcesPage() {
    this._staticContentService.goToTeacherResourcesPage(false);
  }

  goToLogin() {
    this._breadcrumbService.go('root.account-login', {});
  }

  shareOrder() {
    this._analyticsService.emailQuote(this.orderId);
    this._orderManager.shareOrder(
      this._order,
      undefined,
      undefined,
      Locations.ORDER_DETAILS,
      this.isActiveFreeTrial,
    );
  }

  reservePrice() {
    this._analyticsService.reservePrice(this.orderId);
    this._orderManager.reservePrice(this._order, Locations.ORDER_DETAILS);
  }

  exportAsPDF() {
    this._analyticsService.printQuote(this.orderId);
    if (this._pdf) {
      saveAs(this._pdf, this._pdf.name);
    } else {
      this._errorDialog(
        this.$mdDialog,
        'Whoops, there was a problem exporting the .PDF of your quote',
        'If  you continue to have issues, please email support@classkick.com',
      );
    }
  }

  copyQuoteLink() {
    navigator.clipboard
      .writeText(this.$location.$$absUrl)
      .then(() => {
        this.displayToastMessage('Successfully copied to clipboard.');
        this._analyticsService.copyOrderLink(this.orderId);
      })
      .catch((error) => {
        this.$log.error(error);
        this.displayToastMessage('Sorry there was an error copying link');
      });
  }

  displayToastMessage(message) {
    const config = this.$mdToast
      .simple(message)
      .action(' ')
      .actionKey('x')
      .position('top right')
      .toastClass('order-toast')
      .hideDelay(4000);
    this.$mdToast.show(config);
  }

  // Helper methods

  /**
   * @return {string}
   */
  get fileName() {
    return FileUtil.removeForbiddenCharacters(`order-${this.orderId}.pdf`);
  }

  _loadScript(src) {
    let deferred = this.$q.defer();

    let script = this.$document[0].createElement('script');
    script.setAttribute('src', src);
    script.onload = () => deferred.resolve();
    script.onerror = () => deferred.reject();

    let view = angular.element('.order-detail')[0];

    if (view) {
      view.appendChild(script);
    } else {
      deferred.reject();
    }

    return deferred.promise;
  }

  _getAsBuffer(src) {
    return this._httpService.get(src, {
      responseType: 'arraybuffer',
    });
  }

  freeTrialCountdown() {
    return this.daysLeft > 1 ? `${this.daysLeft} Days` : `${this.daysLeft} Day`;
  }

  openMenuBar(menu, event) {
    menu.open(event);

    /* the menu element occurs outside of the regular app body so this only
    applies this style for this particular dropdown menu when
    it's clicked open */
    document.getElementsByClassName(
      'md-open-menu-container',
    )[0].style.borderRadius = '12px';
  }

  getShoutoutCount() {
    this._shoutoutMetrics = {};
    this._trackingDetails.map((event) => {
      if (this._shoutoutMetrics[event.trackingId]) {
        this._shoutoutMetrics[event.trackingId]++;
      } else {
        this._shoutoutMetrics[event.trackingId] = 1;
      }
    });
  }

  get mostActiveTeachers() {
    return this._mostActiveTeachers;
  }

  formatPlural(numberOfTeachers) {
    return numberOfTeachers > 1
      ? `are the ${numberOfTeachers} most active teachers`
      : 'is the most active teacher';
  }

  getCommitmentMetricTotal() {
    const conversionCommitment = this._shoutoutMetrics[
      this.conversionCommitmentId
    ]
      ? this._shoutoutMetrics[this.conversionCommitmentId]
      : 0;
    const conversionView = this._shoutoutMetrics[this.conversionViewId]
      ? this._shoutoutMetrics[this.conversionViewId]
      : 0;
    return conversionCommitment + conversionView;
  }

  goToHomepage() {
    this._staticContentService.goToHomePage();
  }
}
