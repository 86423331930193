'use strict';

import RosterCodec from '../../model/codec/roster-codec';
import UserCodec from '../../model/codec/user-codec';
import HexColors from '../../css-constants';

/**
 * Manages roster data
 */
export default class RosterService {
  constructor($q, environment, HttpService, FirebaseService, AuthService) {
    'ngInject';

    this.$q = $q;
    this._environment = environment;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {RosterCodec} */
    this._rosterCodec = new RosterCodec();
    /** @type {UserCodec} */
    this._userCodec = new UserCodec();
  }

  /**
   * Gets rosters for a user
   *
   * @returns {Promise.<Roster[]>}
   */
  getUserRosters() {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/users/${this._authService.authData.id}/rosters`,
      )
      .then((response) =>
        response.rosters.map((roster) => {
          return this._rosterCodec.decode(roster);
        }),
      );
  }

  /**
   * Gets rosters for a specified student
   *
   * @returns {Promise.<Roster[]>}
   */
  getStudentRosters(studentId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/users/${studentId}/student-rosters`,
      )
      .then((response) =>
        response.rosters.map((roster) => {
          return this._rosterCodec.decode(roster);
        }),
      );
  }

  /**
   * Creates a new roster
   *
   * @param roster {object}
   * @returns {Promise.<Roster>} the id of the new roster
   */
  create(roster) {
    return this._httpService
      .authPost(`${this._environment.serverUrlBase}/v1/rosters`, {
        name: roster.name,
        color: roster.color,
        allow_peer_help: roster.allowPeerHelp,
        allow_new_members: roster.allowNewMembers,
        allow_multi_login: roster.allowMultiLogin,
      })
      .then((response) => this._rosterCodec.decode(response));
  }

  /**
   * Gets a roster
   *
   * @param rosterId
   * @returns {*|Promise.<*>}
   */
  get(rosterId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/rosters/${rosterId}`)
      .then((response) => this._rosterCodec.decode(response));
  }

  /**
   * Updates roster
   *
   * @param roster {Roster}
   * @returns {Promise}
   */
  update(roster) {
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/rosters/${roster.id}`,
      this._rosterCodec.encode(roster),
    );
  }

  /**
   * deletes a roster with the given id
   *
   * @param rosterId
   * @returns {Promise.<boolean>} returns a promise that evals to true/false if the element was successfully deleted
   */
  delete(rosterId) {
    return this._httpService.authDelete(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}`,
    );
  }

  /**
   * Gets all users for roster
   *
   * @param rosterId
   * @returns {Promise.<Array.<User>>}
   */
  getUsersForRoster(rosterId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users`,
      )
      .then((response) =>
        response.users.map((user) => this._userCodec.decode(user)),
      );
  }

  /**
   * Gets single user for roster
   *
   * @param rosterId
   * @param userId
   */
  getUserForRoster(rosterId, userId) {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/${userId}`,
      )
      .then((response) => this._userCodec.decode(response));
  }

  /**
   * Adds a user to a roster
   *
   * @param rosterId {string}
   * @param userId {string}
   * @returns {Promise}
   */
  addMember(rosterId, userId) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users`,
      {
        user_id: userId,
      },
    );
  }

  /**
   * Removes user from roster
   *
   * @param rosterId
   * @param userId
   * @returns {Promise}
   */
  removeMember(rosterId, userId) {
    return this._httpService.authDelete(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/${userId}`,
    );
  }

  /**
   * @param rosterId {string}
   * @param [name] {string}
   * @return {Promise.<TResult>}
   */
  addAnonymousMember(rosterId, name) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/anonymous-student`,
      {
        name: name,
      },
    );
  }

  /**
   * Gets rosters that a user is a member of
   *
   * @returns {Promise.<{rosterOwners: User[], rosters: Roster[]}>}
   */
  getUserMemberRosters() {
    return this._httpService
      .authGet(
        `${this._environment.serverUrlBase}/v1/users/${this._authService.authData.id}/member-rosters`,
      )
      .then((response) => {
        return {
          rosterOwners: response.roster_owners.map((owner) =>
            this._userCodec.decode(owner),
          ),
          rosters: response.rosters.map((roster) =>
            this._rosterCodec.decode(roster),
          ),
        };
      });
  }

  addSelf(rosterId) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/self`,
      {},
    );
  }

  /**
   * Creates a new roster based on a Google Course
   * Information on the Google Classroom Course model can be found here: https://developers.google.com/classroom/reference/rest/v1/courses
   *
   * @param course Object - a Google Course
   * @return Promise<Roster>
   */
  createGoogleRoster(course) {
    return this._httpService
      .authPost(`${this._environment.serverUrlBase}/v1/rosters/google`, course)
      .then((response) => {
        return this._rosterCodec.decode(response);
      });
  }

  /**
   * Makes a request to the the server to update a Classkick roster with the students
   * from Google Classroom
   *
   * Information on googleStudents: https://developers.google.com/classroom/reference/rest/v1/courses.students/list
   *
   * @param rosterId {string} - The id of the Classkick Roster
   * @param googleStudents {object[]} - the response from Google Classroom that lists the students
   * @returns Promise<T>
   */
  updateClasskickRosterWithGoogleStudents(rosterId, googleStudents) {
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/google-sync`,
      {
        students: googleStudents,
      },
    );
  }
}
