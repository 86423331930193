import Codec from './codec';
import ElementCodec from './element-codec';
import StraightLine from '../ui/elements/straight-line';
import PointCodec from './point-codec';
import ColorCodec from './color-codec';

export default class StraightLineCodec extends Codec {
  constructor() {
    super();
    this._colorCodec = new ColorCodec();
    this._pointCodec = new PointCodec();
  }

  /**
   * @param value {StraightLine}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    let data = {
      type: value.type,
      start: this._pointCodec.encode(value.start),
      end: this._pointCodec.encode(value.end),
      color: this._colorCodec.encode(value.color),
      width: value.width,
    };
    return ElementCodec.firebaseElement(data, value.metadata);
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {StraightLine}
   */
  decode(value, key) {
    let metadata = ElementCodec.extractMetadata(value);

    return new StraightLine(
      key,
      metadata,
      this._pointCodec.decode(value.data.start),
      this._pointCodec.decode(value.data.end),
      this._colorCodec.decode(value.data.color),
      value.data.width,
    );
  }
}
