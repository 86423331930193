'use strict';

export default class UserListItem {
  constructor(
    user,
    userNotification,
    helpRequestSet,
    assignmentsCache,
    AssignmentService,
  ) {
    this._user = user;
    this._userNotification = userNotification;
    this._helpRequestSet = helpRequestSet;
    this._assignmentsCache = assignmentsCache;
    this._assignmentService = AssignmentService;
    this._status = null;
    this._activeAssignment = null;

    this._userNotification.updated.subscribe((status) => {
      if (
        status.online &&
        !!status.assignmentId &&
        (!this.activeAssignment ||
          this.activeAssignmentId !== status.assignmentId)
      ) {
        if (this._assignmentsCache.has(status.assignmentId)) {
          this._activeAssignment = this._assignmentsCache.get(
            status.assignmentId,
          );
        } else {
          this._assignmentService
            .get(status.assignmentId)
            .then((assignment) => {
              this._activeAssignment = assignment;
            });
        }
      } else if (!status.online || !status.assignmentId) {
        this._activeAssignment = null;
      }

      this._status = status;
    });

    this._helpRequestView = this._helpRequestSet.createChild(
      (x) => x.helpeeId === this._user.id,
    );
  }

  start() {
    this._userNotification.start();
    this._helpRequestSet.start();
    return this;
  }

  stop() {
    this._userNotification.stop();
    this._helpRequestSet.stop();
    return this;
  }

  get user() {
    return this._user;
  }

  get userId() {
    return this.user && this.user.id;
  }

  get userNotification() {
    return this._userNotification;
  }

  get assignmentsCache() {
    return this._assignmentsCache;
  }

  get activeAssignment() {
    return this._activeAssignment;
  }

  set activeAssignment(value) {
    this._activeAssignment = value;
  }

  get activeAssignmentId() {
    return this.activeAssignment && this.activeAssignment.id;
  }

  get helpRequests() {
    return this._helpRequestView;
  }

  getHelpRequestsByAssignmentId(assignmentId) {
    return this._helpRequestSet.createChild(
      (x) => x.helpeeId === this._user.id && x.assignmentId === assignmentId,
    );
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
  }

  get displayStatus() {
    return (
      (this._activeAssignment && this._activeAssignment.name) || 'Inactive'
    );
  }
}
