'use strict';

import moment from 'moment';
import {
  StatusActivities,
  StatusIdleTimes,
} from '../../model/domain/user-status-editor';
import HelpRequestSet from '../../model/domain/help-request-set';
import {
  HelpInboxMetadata,
  HelpInboxType,
} from '../../components/help-inbox/help-inbox.directive';
import { AssignmentQuestionMetadata } from '../../components/assignment-question/assignment-question.directive';
import { UserRoles } from '../../model/domain/user';
import { ElementIntents } from '../../model/domain/element-metadata';
import { AssignmentSheetMetadata } from '../../components/assignment-sheet/assignment-sheet.directive';
import ErrorDialog from '../../components/error-dialog/error-dialog.controller';
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.controller';
import StaticService from '../../services/static/static.service';

export default class StudentSessionData {
  constructor(
    assignmentWork,
    questionFeedbackList,
    assignmentNotification,
    assignmentRosterNotification,
    workQuestionSet,
    roster,
    userStatus,
    helpRequestSet,
    $q,
    studentCacheService,
    cacheService,
    classCode,
    user,
  ) {
    this.$q = $q;
    this._studentCacheService = studentCacheService;
    this._cacheService = cacheService;

    this._assignmentWork = assignmentWork;
    this._classCode = classCode;
    this._assignmentWork.classCode = this._classCode;
    this._questionFeedbackList = questionFeedbackList;
    this._assignmentNotification = assignmentNotification;
    this._assignmentRosterNotification = assignmentRosterNotification;
    this._workQuestionSet = workQuestionSet;
    this._roster = roster;
    this._userStatus = userStatus;
    this._helpRequestSet = helpRequestSet;
    this._user = user;

    this._init();
  }

  /**
   * @return {AssignmentWork}
   */
  get assignmentWork() {
    return this._assignmentWork;
  }

  /**
   * @return {Assignment}
   */
  get assignment() {
    return this._assignmentWork && this._assignmentWork.assignment;
  }

  /**
   * @return {AssignmentStatusNotification}
   */
  get assignmentNotification() {
    return this._assignmentNotification;
  }

  /**
   * @return {AssignmentRosterNotification}
   */
  get assignmentRosterNotification() {
    return this._assignmentRosterNotification;
  }

  /**
   * @return {QuestionFeedbackList}
   */
  get questionFeedbackList() {
    return this._questionFeedbackList;
  }

  /**
   * @return {string}
   */
  get userId() {
    return this._assignmentWork.ownerId;
  }

  /**
   * @return {User}
   */
  get user() {
    return this._user;
  }

  /**
   * @return {Roster}
   */
  get roster() {
    return this._roster;
  }

  get classCode() {
    return this._classCode;
  }

  _init() {
    if (this._assignmentNotification) {
      this._assignmentNotification.questionUpdated.subscribe(
        this._handleQuestionUpdated,
        this,
      );
      this._assignmentNotification.questionAdded.subscribe(
        this._handleAssignmentUpdated,
        this,
      );
      this._assignmentNotification.questionRemoved.subscribe(
        this._handleAssignmentUpdated,
        this,
      );
      this._assignmentNotification.start();
    }

    if (this._assignmentRosterNotification) {
      this._assignmentRosterNotification.metadata.subscribe(
        this._handleAssignmentRosterUpdated,
        this,
      );
      this._assignmentRosterNotification.start();
    }

    if (this._workQuestionSet) {
      this._workQuestionSet.start();
    }

    if (this._questionFeedbackList) {
      this._questionFeedbackList.start();
    }

    if (this._studentCacheService) {
      this._studentCacheService.userRosterUpdated.subscribe(
        this._handleRosterUpdated,
        this,
      );
    }
  }

  destroy() {
    if (this._assignmentNotification) {
      this._assignmentNotification.questionUpdated.unsubscribe(
        this._handleQuestionUpdated,
        this,
      );
      this._assignmentNotification.stop();
    }

    if (this._workQuestionSet) {
      this._workQuestionSet.stop();
    }

    if (this._assignmentRosterNotification) {
      this._assignmentRosterNotification.metadata.unsubscribe(
        this._handleAssignmentRosterUpdated,
        this,
      );
      this._assignmentRosterNotification.stop();
    }

    if (this._questionFeedbackList) {
      this._questionFeedbackList.stop();
    }

    if (this._studentCacheService) {
      this._studentCacheService.userRosterUpdated.unsubscribe(
        this._handleRosterUpdated,
        this,
      );
    }
  }

  static fetch(
    assignmentWork,
    $q,
    studentCacheService,
    notificationService,
    assignmentWorkService,
    helpRequestService,
    feedbackService,
    cacheService,
  ) {
    return $q
      .all({
        assignmentWork,
        rostersAndOwners: studentCacheService.getUserRostersAndOwners(),
        user: cacheService.getUser(),
      })
      .then((result) => {
        let assignmentWork = result.assignmentWork;
        let assignmentId = assignmentWork.assignment.id;
        let rosterId = assignmentWork.rosterId;
        let userId = assignmentWork.ownerId;

        let questionFeedbackList = feedbackService.getQuestionFeedbackList(
          userId,
          assignmentWork,
        );
        let assignmentNotification =
          notificationService.getAssignmentStatusNotification(
            assignmentWork.assignment,
            false,
          );
        let assignmentRosterNotification =
          notificationService.getAssignmentRosterNotification(
            assignmentWork.assignment,
            rosterId,
            true,
          );
        let workQuestionSet =
          assignmentWorkService.getAssignmentWorkQuestionSet(assignmentWork);

        let classCode = studentCacheService.getClassCodeForAssignmentAndRoster(
          assignmentId,
          rosterId,
          true,
        );

        let helpRequestSet = helpRequestService.getHelpRequestSetForPeers(
          assignmentWork.assignment.ownerId,
          assignmentId,
          rosterId,
        );
        let roster = result.rostersAndOwners.rosters.get(rosterId);
        let userStatus = notificationService.getUserStatusNotification(userId);
        let user = result.user;

        return $q.all({
          assignmentWork,
          questionFeedbackList,
          assignmentNotification,
          assignmentRosterNotification,
          workQuestionSet,
          helpRequestSet,
          roster,
          userStatus,
          classCode,
          user,
        });
      })
      .then((result) => {
        return new StudentSessionData(
          result.assignmentWork,
          result.questionFeedbackList,
          result.assignmentNotification,
          result.assignmentRosterNotification,
          result.workQuestionSet,
          result.roster,
          result.userStatus,
          result.helpRequestSet,
          $q,
          studentCacheService,
          cacheService,
          result.classCode,
          result.user,
        );
      });
  }

  /** Status **/

  validate() {
    return this.$q.all([
      this.validateStatus(),
      this.validateAssignmentRoster(),
    ]);
  }

  validateStatus() {
    return this._getStatus()
      .then((status) => this._validateStatus(status))
      .catch((error) => {
        if (error.message === StudentSessionData.singleDeviceLoginError) {
          throw error;
        } else {
          StaticService.get.$log.error(error);
        }
      });
  }

  /**
   * Loads and returns the user's status
   * @return {Promise.<UserStatus>}
   */
  _getStatus() {
    return this._userStatus.loadOnce().then((data) => {
      let key = Object.keys(data)[0];
      let status = data[key];
      return status;
    });
  }

  static get singleDeviceLoginError() {
    return 'singleDeviceLoginError';
  }

  static launchSingleDeviceErrorDialog($mdDialog, isPortfolio) {
    return ErrorDialog.show(
      $mdDialog,
      'Sorry, your work is open on another device.',
      'Your teacher only allows your work to be open on one device at a time. Please close your work on the other device to view it here.',
      [
        {
          text: 'Try Again',
          action: ErrorDialog.CANCEL,
        },
        {
          text: isPortfolio ? 'Go to Assignments' : 'Go to Login',
          action: ErrorDialog.CONFIRM,
        },
      ],
    );
  }

  /**
   * @param status {UserStatus}
   * @return {Promise}
   */
  _validateStatus(status) {
    if (
      status &&
      this.roster &&
      !this.roster.allowMultiLogin &&
      this._alreadyWorkingOnAssignment(status) &&
      !this._isIdle(status)
    ) {
      let error = new Error(StudentSessionData.singleDeviceLoginError);
      return this.$q.reject(error);
    }
    return this.$q.resolve({});
  }

  /**
   * @param status {UserStatus}
   * @returns {boolean}
   * @private
   */
  _alreadyWorkingOnAssignment(status) {
    return (
      status &&
      status.online &&
      status.assignment_id &&
      status.assignment_id === this.assignment.id
    );
  }

  /**
   * Asks if the moment parameter was more than specified limit
   * @param status {object}
   * @returns {boolean}
   */
  _isIdle(status) {
    let lastActivity = moment(status.t);
    let now = moment();
    let diff = now.diff(lastActivity, StatusIdleTimes.UNIT_OF_TIME);
    return diff > StatusIdleTimes.DURATION;
  }

  validateAssignmentRoster() {
    if (this.classCode && this.classCode.hideStudentWork) {
      let error = new Error(StudentSessionData.assignmentHiddenError);
      return this.$q.reject(error);
    } else {
      return this.$q.resolve();
    }
  }

  static get assignmentHiddenError() {
    return 'assignmentHiddenError';
  }

  static launchAssignmentHiddenErrorDialog($mdDialog, isPortfolio) {
    return ErrorDialog.show(
      $mdDialog,
      'This assignment is hidden.',
      'Please ask your teacher to un-hide it so you can continue.',
      [
        {
          text: 'Try Again',
          action: ErrorDialog.CANCEL,
        },
        {
          text: isPortfolio ? 'Go to Assignments' : 'Go to Login',
          action: ErrorDialog.CONFIRM,
        },
      ],
    );
  }

  /**
   * @param change {AssignmentStatusChange}
   */
  _handleQuestionUpdated(change) {
    if (angular.isNumber(change.points)) {
      let question = this._assignmentWork.assignment.questionForId(
        change.questionId,
      );
      question.points = change.points;
    }
  }

  _handleAssignmentRosterUpdated(data) {
    this._classCode.showStudentScores = data.showStudentScores;
    this._classCode.lockStudentWork = data.lockStudentWork;
    this._classCode.allowPdf =
      data.allowPdf === undefined ? true : data.allowPdf;

    if (this._assignmentWork.assignmentRoster) {
      this._assignmentWork.assignmentRoster.showStudentScores =
        data.showStudentScores;
      this._assignmentWork.assignmentRoster.lockStudentWork =
        data.lockStudentWork;
    }
  }

  /**
   * Handles the assignment being updated, either with a question added or question removed.
   */
  _handleAssignmentUpdated() {
    this._studentCacheService
      .getUserWork(this._assignmentWork.id, true)
      .then((assignmentWork) => {
        this._assignmentWork = assignmentWork;
      });
  }

  _handleRosterUpdated() {
    this._studentCacheService.getUserRostersAndOwners(false).then((result) => {
      this._roster = result.rosters.get(this.assignmentWork.rosterId);
    });
  }

  /**
   * @param questionId {string}
   * @param [readonly] {boolean}
   * @param [pageTraverser] {PageTraverser}
   * @return {AssignmentQuestionMetadata}
   */
  createQuestionConfig(questionId, readonly, pageTraverser) {
    return new AssignmentQuestionMetadata(
      this.assignmentWork,
      questionId,
      readonly,
      false,
      this.userId,
      UserRoles.STUDENT,
      ElementIntents.WORK,
      pageTraverser,
      this._helpRequestSet,
      this._questionFeedbackList,
    );
  }

  /**
   * @param questionId {string}
   * @return {AssignmentSheetMetadata}
   */
  createSheetConfig(questionId) {
    return new AssignmentSheetMetadata(
      this.assignmentWork,
      questionId,
      true,
      true,
      this.userId,
      UserRoles.STUDENT,
      ElementIntents.WORK,
    );
  }

  helpInboxMetadata(type) {
    if (!this._helpInboxMetadata) {
      this._helpInboxMetadata = new HelpInboxMetadata(
        this._helpRequestSet.createChild(
          HelpRequestSet.FILTER_FUNC_STUDENT_UNRESOLVED(this.userId),
        ),
        null,
        new Map([[this.userId, this.assignmentWork]]),
        type || HelpInboxType.STUDENT_ACCOUNT,
      );
    }

    return this._helpInboxMetadata;
  }

  /**
   * @param questionId {string}
   */
  setUserStatus(questionId) {
    this._cacheService.setUserStatus(
      this.assignment.id,
      questionId,
      StatusActivities.WORKING,
    );
  }

  /**
   * @returns {HelpRequestSet}
   */
  get helpRequestSet() {
    return this._helpRequestSet;
  }

  questionForId(questionId) {
    return this.assignment.questionForId(questionId);
  }

  workQuestionForId(questionId) {
    return this.assignmentWork.questionForId(questionId);
  }

  showStudentScore(questionId) {
    if (this.classCode && !this.classCode.showStudentScores) {
      return false;
    }

    let question = this.questionForId(questionId);
    let workQuestion = this.workQuestionForId(questionId);
    if (!question || !workQuestion) {
      return false;
    }
    return !!(
      question.points ||
      (angular.isNumber(workQuestion.points) && workQuestion.points >= 0)
    );
  }

  static launchAssignmentUpdatedDialog($mdDialog) {
    $mdDialog.cancel();

    let message =
      'Your teacher updated the assignment. Would you like to refresh to get the updated version?';
    return ConfirmDialog.show($mdDialog, message);
  }
}
