"use client"

import { useEffect, useState } from "react"

import { ColumnDef, createColumnHelper } from "@tanstack/react-table"

import { LoadingOverlay } from "@/components/LoadingOverlay"
import { Button } from "@/components/ui/Button"

import { Link } from "@/components/ui/Link"

import Order from "../../app/model/domain/order"
import Organization from "../../app/model/domain/organization"
import {
  OrganizationUsageStats,
  OrganizationsUsageStats,
} from "../../app/model/domain/organization-usage-stats"
import { DataTable, SortingState } from "./DataTable"

const columnHelper = createColumnHelper<OrganizationsUsageStats>()

export interface MetricsPageProps {
  statsTimePeriod: string
  errorMessage: string
  getOrganizationsUsageStats: () => Promise<OrganizationsUsageStats[]>
  getActiveOrder: (organizationId: string) => Promise<Order>
  isUserAdminOfAnyContract: () => boolean
  openShareModal: (
    organizationId: string,
    usageStats: OrganizationUsageStats,
    order: Order,
  ) => void
  openInviteModal: (organization: Organization) => void
  openNudgeModal: () => void
  onTableSort: (sortingState: SortingState) => void
}

export function MetricsPage({
  statsTimePeriod,
  errorMessage,
  getOrganizationsUsageStats,
  getActiveOrder,
  isUserAdminOfAnyContract,
  openShareModal,
  openInviteModal,
  openNudgeModal,
  onTableSort,
}: MetricsPageProps) {
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined>()
  const [data, setData] = useState<OrganizationsUsageStats[] | undefined>()
  const [isLoadingOverlayVisible, setIsLoadingOverlayVisible] = useState(false)
  const [sorting, setSorting] = useState<SortingState>([])

  useEffect(() => {
    getOrganizationsUsageStats()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [getOrganizationsUsageStats])

  async function handleShareClick(
    organizationId: string,
    usageStats: OrganizationUsageStats,
  ): Promise<void> {
    setIsLoadingOverlayVisible(true)
    const order = await getActiveOrder(organizationId)
    openShareModal(organizationId, usageStats, order)
    setIsLoadingOverlayVisible(false)
  }

  function handleInviteClick(organization: Organization) {
    openInviteModal(organization)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnDef<OrganizationsUsageStats, any>[] = [
    columnHelper.accessor("organization.name", {
      id: "name",
      header: "School",
    }),
    columnHelper.accessor("stats.teachersActiveThisYear", {
      id: "teachersActiveThisYear",
      header: "Active teachers",
      sortUndefined: -1,
      cell: ({ getValue }) => getValue()?.toLocaleString() ?? "–",
    }),
    columnHelper.accessor("stats.assignmentsWorkedOnThisYear", {
      id: "assignmentsWorkedOnThisYear",
      header: "Assignments worked",
      sortUndefined: -1,
      cell: ({ getValue }) => getValue()?.toLocaleString() ?? "–",
    }),
    columnHelper.accessor("stats.pagesWorkedOnThisYear", {
      id: "pagesWorkedOnThisYear",
      header: "Pages worked",
      sortUndefined: -1,
      cell: ({ getValue }) => getValue()?.toLocaleString() ?? "–",
    }),
    columnHelper.accessor("stats.approximateSavings", {
      id: "approximateSavings",
      header: "Savings on ink, copies, paper",
      sortUndefined: -1,
      cell: ({ row }) => row.original.stats.approximateSavingsDisplay ?? "–",
    }),
    columnHelper.display({
      header: "Share",
      cell: ({ row }) => (
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            const organizationId = row.original.organization.id
            const usageStats = row.original.stats
            return handleShareClick(organizationId, usageStats)
          }}
        >
          Share
        </Button>
      ),
    }),
    columnHelper.display({
      header: "Invite",
      cell: ({ row }) => (
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            const organization = row.original.organization
            handleInviteClick(organization)
          }}
        >
          Invite
        </Button>
      ),
    }),
  ]

  // The height calculation on the topmost element accounts for:
  //   - 60px Metrics header
  //   - Zoom of 1.6 on .react-container
  // TODO: find a way to add this to tailwind config
  return (
    <div className="tw-h-[calc((100vh-60px)/1.6)] tw-flex tw-flex-col tw-p-[1.4em]">
      <div className="tw-mb-4 tw-flex tw-justify-between">
        <div className="tw-my-auto">
          <div className="tw-whitespace-nowrap">
            <h2 className="tw-inline">All schools</h2>
            <span className="tw-ml-4 tw-text-sm tw-text-subtle">
              {statsTimePeriod} - Present
            </span>
          </div>
          <Link href="/account/profile?tab=school">Manage organizations</Link>
        </div>
        {isUserAdminOfAnyContract() && (
          <Button onClick={openNudgeModal}>Nudge My Teachers</Button>
        )}
      </div>

      {isLoading && <p className="tw-text-sm">Loading...</p>}
      {error && <p className="tw-text-sm tw-text-red-500">{errorMessage}</p>}
      {data && !isLoading && !error && (
        <DataTable
          columns={columns}
          data={data}
          state={{ sorting }}
          onSortingChange={(updater) => {
            const newSortingValue =
              updater instanceof Function ? updater(sorting) : updater
            onTableSort(newSortingValue)
            setSorting(updater)
          }}
        />
      )}

      {isLoadingOverlayVisible && <LoadingOverlay />}
    </div>
  )
}
