'use strict';

import SimpleCodec from '../codec/simple-codec';

/**
 * Subclass for items which represent a mapping to where a property is persisted
 */
export default class PropertyMapping {
  /**
   * @param uriTemplate {string} relative uri snippet, may include curly brace delineated replacements for {id} values
   * @param listenIndicator {function(instance)} callback used to indicate whether to subscribe to this property of the given instance
   * @param [codec] {Codec} used to encode/decode the values between the client and firebase
   */
  constructor(uriTemplate, listenIndicator, codec) {
    this._uriTemplate = uriTemplate;
    this._connectIndicator = listenIndicator;
    this._codec = codec;

    if (!this._codec) {
      this._codec = new SimpleCodec();
    }
  }

  /**
   * @returns {Codec}
   */
  get codec() {
    return this._codec;
  }

  /**
   * @returns {string}
   */
  get uriTemplate() {
    return this._uriTemplate;
  }

  /**
   * @returns {(function(instance))}
   */
  get connectIndicator() {
    return this._connectIndicator;
  }
}
