'use strict';

/**
 * Command class captures a changes to multiple elements, implements undo/redo functionality per element types
 */
export default class MultiChange {
  /**
   * @param changes {Change[]}
   */
  constructor(changes) {
    this._changes = changes;
    this._deleted = changes.every((change) => change.deleted);
  }

  /**
   * @return {boolean} True indicates elements were deleted
   */
  get deleted() {
    return this._deleted;
  }

  /**
   * Undoes the changes on the target elements, returns a MultiChange object reflecting the change made
   * @param question {AssignmentQuestion}
   * @param [undoCreate] {Function}
   * @param [undoRemove] {Function}
   * @param [undoChange] {Function}
   * @return {MultiChange}
   */
  undo(question, undoCreate, undoRemove, undoChange) {
    let changes = this._changes.map((change) =>
      change.undo(question, undoCreate, undoRemove, undoChange),
    );
    return new MultiChange(changes);
  }
}
