'use strict';

import Codec from './codec';
import GradebookAssignment from '../domain/gradebook-assignment';
/**
 * Encodes/decodes Gradebook integration status
 */
export default class GradebookAssignmentCodec extends Codec {
  /**
   * @param value {GradebookAssignment}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        title: value.title,
      };
    }
    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookAssignment}
   */
  decode(value) {
    if (value) {
      return new GradebookAssignment(
        value.id,
        value.title,
        value.scaled_points,
        value.due_date,
      );
    }

    return null;
  }
}
