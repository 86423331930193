import {
  Locations,
  PaywallSources,
  SessionSettingTypes,
} from '../../services/mixpanel/mixpanel.service';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ChooseRosterDialogController from '../assignment-rosters/choose-roster-dialog.controller';
import { SessionDataCodes } from '../../model/domain/session-data';
import { SelectStickerMenuController } from '../../components/select-sticker-menu/select-sticker-menu.controller';
import ShareWithStudentDialogController from '../../components/share-with-student-dialog/share-with-student-dialog.controller';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import AssignmentManager from '../../model/domain/assignment-manager';
import SlideForeground from '../../model/ui/elements/slide-foreground';
import ElementMetadata, {
  ElementIntents,
} from '../../model/domain/element-metadata';
import HexColors from '../../css-constants';
import { UserRoles } from '../../model/domain/user';
import ShareOrderDialogController from '../../components/share-order-dialog/share-order-dialog.controller';
import Contract from '../../model/domain/contract';
import Order from '../../model/domain/order';
import ReferralDialogController from '../../components/referral-dialog/referral-dialog.controller';
import PreviewStudentUtil from '../../model/util/preview-student-util';
import {
  BulkMenuOptions,
  SelectBulkUpdateMenuController,
} from '../../components/select-bulk-update-menu/select-bulk-update-menu.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import Sorts from '../../model/domain/sorts';
import GradeExportDialogController from '../../components/grade-export-dialog/grade-export-dialog.controller';
import ContextualPaywallDialogController, {
  ContextualPaywalls,
} from '../../components/contextual-paywall-dialog/contextual-paywall-dialog.controller';
import _ from 'lodash';

export default class SessionController {
  /**
   * @ngInject
   */
  constructor(
    $scope,
    $document,
    $filter,
    $location,
    $mdDialog,
    $mdPanel,
    $mdToast,
    $q,
    $state,
    $stateParams,
    $window,
    $log,
    $rootScope,
    AuthService,
    CacheService,
    AnalyticsService,
    AnswerExportService,
    GradeExportService,
    ExportService,
    BreadcrumbService,
    AssignmentService,
    RosterService,
    StorageService,
    OrderService,
    OrganizationService,
    BulkUpdateService,
  ) {
    this.$scope = $scope;
    this.$document = $document;
    this.$filter = $filter;
    this.$location = $location;
    this.$mdDialog = $mdDialog;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this.$mdToast = $mdToast;
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.$log = $log;
    this.$rootScope = $rootScope;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AnswerExportService} */
    this._answerExportService = AnswerExportService;
    /** @type {GradeExportService} */
    this._gradeExportService = GradeExportService;
    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {BulkUpdateService} */
    this._bulkUpdateService = BulkUpdateService;

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      this._gradeExportService,
      this._answerExportService,
      this._analyticsService,
      this._exportService,
    );

    this._assignmentId = this.$stateParams.assignmentId;
    this._rosterId = this.$stateParams.rosterId;

    this._error = null;
    this._userSchools = null;
    this._assignment = null;

    this.contracts = [];
    this.schoolsInDistrict = null;

    this._totalThumbnailsSelected = 0;

    /** @type {SessionData} */
    this._sessionData = null;

    this._shareWithStudentDialog = ShareWithStudentDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._chooseRosterDialog = ChooseRosterDialogController.show;
    this._selectStickerMenu = SelectStickerMenuController.show;
    this._selectBulkUpdateMenu = SelectBulkUpdateMenuController.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._shareOrderDialog = ShareOrderDialogController.show;
    this._referralDialog = ReferralDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._gradeExportDialog = GradeExportDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;

    if (this._authService.authData) {
      /** @type {boolean} */
      this.isStudent = this._authService.authData.isStudent;
      /** @type {boolean} */
      this.isTeacher = this._authService.authData.isTeacher;
      /** @type {boolean} */
      this.isFreeUser = this._authService.authData.isFree;
      /** @type {boolean} */
      this.isProUser = this._authService.authData.isPro;
    }

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._destroy());

    this._init();
    this.checkIfClassCodeDialogClosed();
    this.checkIfStudentAdded();
  }

  _init() {
    this.$q
      .all({
        data: this._cacheService.getSessionData(
          this._assignmentId,
          this._rosterId,
        ),
        rosters: this._cacheService.getRostersForUser(true),
        user: this._cacheService.getUser(),
        contracts: this._cacheService.getContracts(false, this.isTeacher),
        schools: this._cacheService.getSchools(false),
        assignment: this._cacheService.getAssignmentForUser(this._assignmentId),
      })
      .then(({ data, rosters, user, contracts, schools, assignment }) => {
        const ownedRosters = new Map();
        rosters.forEach((roster, key) => {
          if (roster.ownerId === user.id) {
            ownedRosters.set(key, roster);
          }
        });

        this._sessionData = data;
        this._rosters = ownedRosters;
        this._user = user;
        this.activeContract = Contract.ActiveContract(contracts);
        this.school = schools.find(
          (school) =>
            this.activeContract && school.contractId === this.activeContract.id,
        );
        this._userSchools = schools;
        this.contracts = contracts;
        this._assignment = assignment;
        this._initialNumberOfStudents = this._sessionData.numOfStudents;

        if (
          this._storageService.edlinkCode ||
          this._storageService.edlinkError
        ) {
          this.openGradebookExport();
        }

        if (this.school) {
          this._orderService
            .getForOrganization(this.school.id)
            .then((orders) => {
              this.activeOrder = Order.findActiveOrder(orders);
            });

          if (this.school.parentOrganizationId) {
            this.$q
              .all({
                districtOrders: this._orderService.getForOrganization(
                  this.school.parentOrganizationId,
                ),
                schoolsInDistrict:
                  this.school.parentOrganizationId &&
                  this._cacheService.getChildOrganizationCount(
                    this.school.parentOrganizationId,
                  ),
              })
              .then(({ districtOrders, schoolsInDistrict }) => {
                this.activeDistrictOrder =
                  Order.findActiveOrder(districtOrders);
                this.schoolsInDistrict = schoolsInDistrict.count;
              });
          }
        }

        if (!this.data.isComplete) {
          if (!this.data.hasRosters) {
            this._error = new Error(
              'There are no rosters associated with this assignment.',
            );
            this._error.noRosters = true;
            return undefined;
          } else {
            this.goToNewRoster(this.data.assignmentRosters[0].id);
            return undefined;
          }
        }

        this.data.sessionQuestionNumber = angular.isNumber(
          this.$state.params.questionNumber,
        )
          ? this.$state.params.questionNumber
          : this.data.ViewAllSlides;

        this.data.updated.subscribe(this._onSessionDataUpdated, this);

        if (this.data.teacher.showSessionRefactorHelpDialog) {
          //this.showSessionHelp();
          //this is to be reimplemented if KICK-1374 gets rolledback
          this.data.teacher.showSessionRefactorHelpDialog = false;
          this._cacheService.updateUser(this.data.teacher);
        }
        this._bulkUpdateService.updated.subscribe(
          this._updateTotalThumbnailsSelected,
          this,
        );

        if (this.$rootScope.showDialog) {
          this._analyticsService.welcomeDialogRosterAssignModal(
            this._rosterId,
            this._assignmentId,
          );
          this._shareWithStudentDialog(
            this.$mdDialog,
            this.data.assignment,
            this.data.roster,
            this.data.classCode,
            this.data.pro,
          );
        }
      })
      .catch((err) => {
        this._error = err;
        throw err;
      });
  }

  _destroy() {
    this._bulkUpdateService.updated.unsubscribe(
      this._updateTotalThumbnailsSelected,
      this,
    );
  }

  /**
   * @return {boolean}
   */
  isACoTeacher() {
    return (
      this._authService.isACoTeacher() &&
      this._authService.coTeacherAuthData &&
      this._authService.coTeacherAuthData.id !==
        (this.roster && this.roster.ownerId)
    );
  }

  //--------------- Data methods -------------------------

  /**
   * @returns {SessionData}
   */
  get data() {
    return this._sessionData;
  }

  _onSessionDataUpdated(event) {
    if (event && event.code === SessionDataCodes.STUDENT_SORT) {
      this._storageService.sessionSort = this.data.studentSort.name;
    }
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this.data && this.data.pro;
  }

  /**
   * @return {boolean}
   */
  get hasExportStudentWork() {
    return this.pro && this.pro.hasExportStudentWork;
  }

  /**
   * @return {boolean}
   */
  get hasGradeExport() {
    return this.pro && this.pro.hasGradeExport;
  }

  /**
   * @return {boolean}
   */
  get hasFoldersFeature() {
    return this.pro && this.pro.hasFoldersFeature;
  }

  /**
   * @returns {string}
   */
  get assignmentName() {
    if (this.data) {
      return this.data.assignment.name;
    } else {
      return 'Loading...';
    }
  }

  /**
   * @return {null|Error}
   */
  get error() {
    return this._error;
  }

  get loaded() {
    return !this.error && !this.loading;
  }

  get loading() {
    return !this.data && !this.error;
  }

  /**
   * Binding for roster selector
   * @returns {Roster}
   */
  get roster() {
    return this.data && this.data.roster;
  }

  /**
   * Binding for roster selector
   * @param value {Roster}
   */
  set roster(value) {
    if (!value) {
      return;
    }
    this.goToNewRoster(value.id);
  }

  /**
   * @returns {Roster[]}
   */
  get sortedAssignmentRosters() {
    return this.data.assignmentRosters.sort((a, b) =>
      Sorts.NAME_ASC(
        (a.name || '').toLowerCase(),
        (b.name || '').toLowerCase(),
      ),
    );
  }

  get showWork() {
    return !this.data.teacher.sessionViewShowWork;
  }

  /**
   * @param value {boolean}
   */
  set showWork(value) {
    this.data.teacher.sessionViewShowWork = !value;

    this._cacheService.updateUser(this.data.teacher);

    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.ShowWork,
      value,
    );

    if (this.data.teacher.sessionViewShowWork) {
      this.goToWorkView();
    } else {
      this.goToWatchView();
    }
  }

  get hideUnstartedThumbnails() {
    return this.data.hideUnstartedThumbnails;
  }

  set hideUnstartedThumbnails(value) {
    this.data.hideUnstartedThumbnails = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.HideUnstartedThumbnails,
      value,
    );
  }

  get hideOfflineStudents() {
    return this.data.hideOfflineStudents;
  }

  set hideOfflineStudents(value) {
    this.data.hideOfflineStudents = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.HideOfflineStudents,
      value,
    );
  }

  get hideUnstartedAssignmentWorks() {
    return this.data.hideUnstartedAssignmentWorks;
  }

  set hideUnstartedAssignmentWorks(value) {
    this.data.hideUnstartedAssignmentWorks = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.HideUnstartedAssignmentWorks,
      value,
    );
  }

  get hideStudentNames() {
    return this.data.hideStudentNames;
  }

  set hideStudentNames(value) {
    this.data.hideStudentNames = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.HideStudentNames,
      value,
    );
  }

  /**
   * @returns {boolean}
   */
  get studentHelpers() {
    return this.data.roster.allowPeerHelp;
  }

  /**
   * @param value {boolean}
   */
  set studentHelpers(value) {
    this.data.roster.allowPeerHelp = value;
    this._rosterService.update(this.data.roster);
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.ShowStudentHelpers,
      value,
    );
  }

  /**
   * @return {boolean}
   */
  get showStudentScores() {
    return this.data.showStudentScores;
  }

  /**
   * @param value {boolean}
   */
  set showStudentScores(value) {
    this.data.showStudentScores = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.ShowStudentScores,
      value,
    );
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    return this.data.lockStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set lockStudentWork(value) {
    this.data.lockStudentWork = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.LockStudentWork,
      value,
    );
  }

  /**
   * @return {boolean}
   */
  get allowPdfs() {
    return this.data.allowPdf;
  }

  /**
   * @param value {boolean}
   */
  set allowPdfs(value) {
    this.data.allowPdf = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.AllowPdf,
      value,
    );
  }

  /**
   * @return {boolean}
   */
  get hideStudentWork() {
    return this.data.hideStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set hideStudentWork(value) {
    this.data.hideStudentWork = value;
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.HideStudentWork,
      value,
    );
  }

  /**
   * @return {string}
   */
  get assignmentViewWork() {
    return Locations.ASSIGNMENT_VIEW_WORK;
  }

  /**
   * @return {boolean}
   */
  get isFreeTrial() {
    return this.activeContract && this.activeContract.isTrial;
  }

  /**
   * @return {boolean}
   */
  get isProSchool() {
    return this.activeContract && this.activeContract.isProSchool;
  }

  /**
   * @return {boolean}
   */
  get isProDistrict() {
    return this.activeContract && this.activeContract.isProDistrict;
  }

  //---------------------- Session Question Navigation -----------------------------

  /**
   * @return {number|string}
   */
  get sessionQuestionNumber() {
    return this.data && this.data.sessionQuestionNumber;
  }

  /**
   * @param value {number|string}
   */
  set sessionQuestionNumber(value) {
    if (!this.data) {
      return;
    }

    this.data.sessionQuestionNumber = value;

    if (angular.isNumber(value)) {
      this.goToQuestionView(value);
    } else {
      this.goToWorkView();
    }
  }

  //---------------------- Export Content, Work, Grades, and Answers -----------------------------

  exportContentToPDF() {
    this._assignmentManager.exportContentToPDF(this.data.assignment);
  }

  exportWorkToPDF() {
    this._assignmentManager.exportWorkToPDF(
      this.data.worksByStudent,
      this.data.sortedAndUnfilteredStudents,
      this.data.classCode,
      this.data.assignment,
      this.pro,
    );
  }

  exportGrades() {
    this._assignmentManager.exportGrades(
      this.data.assignment,
      this.data.assignmentRosters,
      this.pro,
    );
  }

  exportAnswers() {
    this._assignmentManager.exportAnswers(
      this.data.assignment,
      this.data.assignmentRosters,
      this.pro,
    );
  }

  //--------------- Navigation methods -------------------------

  goHome() {
    this._breadcrumbService.goBack('root.account.home', {}, true);
  }

  goEdit() {
    this._breadcrumbService.go(
      'root.account.assignment',
      {
        assignmentId: this._assignmentId,
        lastRosterId: this._rosterId,
      },
      true,
    );
  }

  goToAssignmentRosters() {
    this._breadcrumbService.go(
      'root.account.assignment-rosters',
      {
        assignmentId: this._assignmentId,
        lastRosterId: this._rosterId,
      },
      true,
    );
  }

  goToWatchView() {
    this._breadcrumbService.go(
      'root.account.session.watch',
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: this._rosterId,
      },
      true,
    );
  }

  goToWorkView() {
    this._breadcrumbService.go(
      'root.account.session.work',
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: this._rosterId,
      },
      true,
    );
  }

  goToRosterEdit() {
    this._breadcrumbService.go('root.account.nav.roster', {
      rosterId: this._rosterId,
    });
  }

  goToQuestionView(questionNumber = 1) {
    this._breadcrumbService.go(
      'root.account.session.question',
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: this._rosterId,
        questionNumber,
      },
      true,
    );
  }

  chooseAddRoster() {
    this._chooseRosterDialog(
      this.$mdDialog,
      this.$q,
      this._rosters,
      this.data.assignment,
      this.data.pro,
    ).then((result) => {
      this._addRoster(result.id);
    });
  }

  /**
   * @param rosterId {string}
   */
  _addRoster(rosterId) {
    let promise = this._assignmentService
      .addOrUpdateRoster(this.data.assignment.id, rosterId, false)
      .then(() => {
        this._cacheService.reset();
      });

    this._loadingDialog(this.$mdDialog, promise, this.$document);

    promise.then(() => {
      this.goToNewRoster(rosterId);
    });
  }

  goToNewRoster(rosterId) {
    this._breadcrumbService.go(
      this.$state.current.name,
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: rosterId,
      },
      true,
    );
  }

  goToFeedbackQuestion(userId, questionId) {
    this._breadcrumbService.go(`${this.$state.current.name}.feedback`, {
      assignmentId: this._assignmentId,
      questionId: questionId,
      assignmentWorkId: this.data.workForStudent(userId).id,
    });
  }

  /**
   * @return {boolean}
   */
  get isSessionQuestion() {
    return this.$state.is('root.account.session.question');
  }

  /**
   * @return {boolean}
   */
  get isSessionWork() {
    return this.$state.is('root.account.session.work');
  }

  /**
   * @return {boolean}
   */
  get isSessionWatch() {
    return this.$state.is('root.account.session.watch');
  }

  //--------------- Share, Move, Duplicate, and Delete Assignment -------------------------

  share() {
    this._assignmentManager.share(this.data.assignment);
  }

  shareWithStudentDialog() {
    this._shareWithStudentDialog(
      this.$mdDialog,
      this.data.assignment,
      this.data.roster,
      this.data.classCode,
      this.data.pro,
    );
  }

  moveAssignmentToFolder() {
    this._assignmentManager.moveAssignmentToFolder(this.data.assignment);
  }

  /**
   * Duplicates an assignment
   */
  duplicate() {
    this._assignmentManager.checkAssignmentLimit(
      Locations.ASSIGNMENT_VIEW_WORK_NAV,
      this.data.assignment.id,
      () => {
        this._assignmentManager.duplicate(
          this.data.assignment.id,
          this.data.assignment.name,
        );
      },
    );
  }

  /**
   * Deletes an assignment
   */
  delete() {
    this._assignmentManager.delete(this.data.assignment).then(() => {
      this.goHome();
    });
  }

  promoteRoster(roster) {
    if (!this._assignment.promotedRosters.includes(roster.id)) {
      this._assignment.promotedRosters =
        this._assignment.promotedRosters.concat([roster.id]);
      this._analyticsService.recommendAssignment(
        roster.id,
        this._assignment.id,
        false,
      );
    } else {
      this._assignment.promotedRosters =
        this._assignment.promotedRosters.filter(
          (rosterId) => rosterId !== roster.id,
        );
      this._analyticsService.recommendAssignment(
        roster.id,
        this._assignment.id,
        true,
      );
    }
    this._assignmentService.update(this._assignment).catch((err) => {
      this.$log.error(err);
      this._errorDialog(
        this.$mdDialog,
        'Sorry there was an error recommending this assignment, please try again',
        '',
      );
    });
  }

  isPromotedAssignmentRoster() {
    if (this._assignment.promotedRosters) {
      return this._assignment.promotedRosters.includes(this.roster.id);
    }
    return false;
  }

  //---------------------- Bulk Update Selection -----------------------------

  openBulkUpdateMenu(event) {
    this.stopPlacingStop(false);
    this.stopPlacingSticker();
    if (this.isSessionWatch) {
      let message =
        'Bulk update does not work when "Shrink thumbnails" is enabled.<br>Toggle off "Shrink thumbnails" to use this feature.';
      this._confirmDialog(this.$mdDialog, message, undefined, true, true);
    } else {
      this._selectBulkUpdateMenu(
        this.$mdPanel,
        this.$q,
        event,
        this.data.bulkUpdateOption,
      )
        .then(({ bulkUpdateOption }) => {
          if (
            bulkUpdateOption ===
            BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE
          ) {
            this.data.bulkUpdateOption = undefined;
            this._totalThumbnailsSelected = 0;
          } else {
            this.data.bulkUpdateOption = bulkUpdateOption;
          }
        })
        .catch((error) => {
          if (error !== undefined) {
            this.$log.error(error);
          }
        });
    }
  }

  showActiveBulkUpdate() {
    return (
      this.data.bulkUpdateOption !==
        BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE &&
      this.data.bulkUpdateOption !== undefined
    );
  }

  /**
   * @param value {{option: string, thumbnailsSelected: number}}
   */
  _updateTotalThumbnailsSelected(value) {
    this._totalThumbnailsSelected = value.thumbnailsSelected;
  }

  //---------------------- Place Sticker Logic -----------------------------

  openStickerMenu(event) {
    if (this.data.bulkUpdateOption && !this._totalThumbnailsSelected) {
      return this._errorDialog(
        this.$mdDialog,
        '<div>Before applying changes, please ensure you have selected<br> slides by checking for a green outline around them</div>',
        '',
      );
    }

    this.stopPlacingStop(false);
    this._checkIfHelpViewed(ViewHelps.PlaceStickerWand, () => {
      if (this.isSessionWatch) {
        let message =
          'The Sticker Wand does not work when "Shrink thumbnails" is enabled.<br>Toggle off "Shrink thumbnails" to use the Sticker Wand.';
        this._confirmDialog(this.$mdDialog, message, undefined, true, true);
      } else {
        this._selectStickerMenu(
          this.$mdPanel,
          this.$q,
          event,
          this.data.stickers,
          Locations.ASSIGNMENT_WORK,
        )
          .then(({ sticker }) => {
            this.data.placingSticker = sticker;
          })
          .catch((error) => {
            if (error !== undefined) {
              this.$log.error(error);
            }
          });
      }
    });
  }

  /**
   * @param helpId {string} place_sticker_wand
   * @param callback {Function}
   */
  _checkIfHelpViewed(helpId, callback) {
    let promise = this.$q.resolve();

    if (
      this.data &&
      this.data.teacher &&
      !this.data.teacher.getHelpDialogViewed(helpId)
    ) {
      promise = this._helpDialog(this.$mdDialog, helpId).catch(() => {
        this.data.teacher.setHelpDialogViewed(helpId, true);
        this._cacheService.updateUser(this.data.teacher);
      });
    }

    promise.finally(callback);
  }

  stopPlacingSticker() {
    this.data.placingSticker = undefined;
  }

  //---------------------- Stop Students Logic -----------------------------

  stopStudents(event) {
    if (this.data.bulkUpdateOption && !this._totalThumbnailsSelected) {
      return this._errorDialog(
        this.$mdDialog,
        'Before applying changes, please ensure you have selected<br> slides by checking for a green outline around them',
        '',
      );
    }

    this.stopPlacingSticker();
    this._analyticsService.stopWandToggled(true);

    if (this.isSessionWatch) {
      let message =
        'The Stop Wand does not work when "Shrink thumbnails" is enabled.<br>Toggle off "Shrink thumbnails" to use the Stop Wand.';
      this._confirmDialog(this.$mdDialog, message, undefined, true, true);
    } else {
      let slideForeground = new SlideForeground(
        'placeholder-id',
        new ElementMetadata(
          this.data.teacher.id,
          UserRoles.TEACHER,
          ElementIntents.CONTENT,
        ),
        HexColors.CK_WARN,
        undefined,
      );

      this.data.placingStop = slideForeground;
    }
  }

  stopPlacingStop(sendAnalytics = true) {
    if (sendAnalytics) {
      this._analyticsService.stopWandToggled(false);
    }
    this.data.placingStop = undefined;
  }

  //---------------------- Contextual Help Dialogs -----------------------------

  showSessionHelp() {
    if (this.isSessionQuestion) {
      this.showSessionQuestionHelp();
    } else if (this.isSessionWork) {
      this.showSessionWorkHelp();
    } else if (this.isSessionWatch) {
      this.showSessionWatchHelp();
    }
  }

  showSessionWorkHelp() {
    return this._showHelp(ViewHelps.SessionWork);
  }

  showSessionWatchHelp() {
    return this._showHelp(ViewHelps.SessionWatch);
  }

  showSessionQuestionHelp() {
    return this._showHelp(ViewHelps.SessionQuestion);
  }

  showHideStudentNamesHelp() {
    return this._showHelp(ViewHelps.HideStudentNames);
  }

  showAllowPeerHelpersHelp() {
    return this._showHelp(ViewHelps.AllowPeerHelpers);
  }

  showShowStudentScoresHelp() {
    return this._showHelp(ViewHelps.ShowStudentScores);
  }

  showLockAssignmentHelp() {
    return this._showHelp(ViewHelps.LockAssignment);
  }

  showHideAssignmentHelp() {
    return this._showHelp(ViewHelps.HideAssignment);
  }

  _showHelp(value) {
    this._helpDialog(this.$mdDialog, value);
  }
  openStudentPreview() {
    PreviewStudentUtil.openStudentPreview({
      analyticsService: this._analyticsService,
      pageLocation: Locations.ASSIGNMENT_VIEW_WORK_NAV,
      assignment: this._assignment,
      assignmentService: this._assignmentService,
      loadingDialog: this._loadingDialog,
      $mdDialog: this.$mdDialog,
      $location: this.$location,
      $window: this.$window,
    });
  }

  openGradebookExport() {
    if (!this.hasGradeExport) {
      this._contextualPaywallDialog(
        this.$mdDialog,
        ContextualPaywalls.GradebookIntegration,
        PaywallSources.GRADEBOOK_INTEGRATION,
      );
    } else {
      this._storageService.ckRosterId = this.$stateParams.rosterId;
      this._storageService.ckAssignmentId = this.$stateParams.assignmentId;
      this._analyticsService.gradebookOpened('assignmentWork');
      return this._gradeExportDialog(this.$mdDialog);
    }
  }

  //---------------------- Class Code -----------------------------
  checkIfClassCodeDialogClosed() {
    this.$scope.$watch(
      () => this.$rootScope.showDialog,
      (newValue, oldValue) => {
        if (newValue === false && oldValue === true) {
          if (this._initialNumberOfStudents === this.data.numOfStudents) {
            this.data.makeClassCodePulseAfterRegistration();
          }
        }
      },
    );
  }

  checkIfStudentAdded() {
    // We need to stop pulse and track only when a teacher has added the
    // student for the first time after registration.
    const handleStudentAdded = _.once(() => {
      this.data.stopClassCodePulseAfterRegistration();
      this._analyticsService.studentJoinedWithClassCode();
    });

    // Watch for changes in numOfStudents
    this.$scope.$watch(
      () => this.data?.numOfStudents,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          handleStudentAdded();
        }
      },
    );
  }
}
