'use strict';

import ErrorDialog from '../../components/error-dialog/error-dialog.controller';
import LazyVar from '../../model/util/lazy-var';
import StaticService from '../../services/static/static.service';
import LocationUtil from '../../model/util/location-util';

export class VideoUrl {
  static get gettingStartedUrl() {
    return 'https://www.youtube.com/watch?v=vho2a6t-adk';
  }
}

/**
 * Manages links to static content
 */
export default class StaticContentService {
  constructor(
    $q,
    $state,
    $log,
    $window,
    $location,
    $mdDialog,
    $http,
    environment,
    FirebaseService,
    StorageService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$log = $log;
    this.$window = $window;
    this.$location = $location;
    this.$mdDialog = $mdDialog;
    this.$http = $http;

    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {StaticService|undefined} */
    this._staticService = undefined;

    this._errorDialog = ErrorDialog.show;
    this._environment = environment;

    this._viewHelps = new LazyVar();
    this._contextualPaywalls = new LazyVar();
    this._features = new LazyVar();
  }

  goToHomePage() {
    this.$window.location.href = 'https://www.classkick.com';
  }

  goToOrdersPage(recordSession = false) {
    let url = `${LocationUtil.absRootUrl(this.$location)}/order`;

    if (recordSession) {
      url = url + `?active=${recordSession}`;
    }

    this.$window.open(url, '_blank');
  }

  goToOrder(orderId) {
    let url = `${LocationUtil.absRootUrl(this.$location)}/order/${orderId}`;

    this.$window.open(url, '_blank', 'noreferrer');
  }

  goToTeacherHelpCenter(newTab = true) {
    if (newTab) {
      this.$window.open('https://classkick.com/help', '_blank');
    } else {
      this.$window.location.href = 'https://classkick.com/help';
    }
  }

  goToTeacherPO() {
    this.$window.open(
      'https://drive.google.com/file/d/1T3Pd9rM9n6hJDnMvkbGGXLYbbSnWFN4u/view',
      '_blank',
    );
  }

  goToAssignmentsLimitBlogPost() {
    this.$window.open(
      'https://blog.classkick.com/updating-classkick-basic-and-pro-7d38c44fb67b',
      '_blank',
    );
  }

  goToAssignmentsLimitFAQ() {
    this.$window.open(
      'https://docs.google.com/document/d/1g-WIP22zbIk7o0z8PMWvAWQfj-6XjcDfjQfIlKqw3Ws/edit',
      '_blank',
    );
  }

  goToTermsOfServicePage() {
    this.$window.open('https://www.classkick.com/terms-of-service', '_blank');
  }

  goToPrivacyPolicyPage() {
    this.$window.open('https://www.classkick.com/privacy-policy', '_blank');
  }

  get staticService() {
    if (!this._staticService) {
      this._staticService = StaticService.get;
    }
    return this._staticService;
  }

  recommendBestBrowser() {
    if (
      (this.staticService.isIE ||
        this.staticService.isEdge ||
        this.staticService.isSafari ||
        this.staticService.isFirefox) &&
      !this.staticService.isClasskickIPadApp &&
      !this._storageService.hasRecommendedBrowser
    ) {
      this.recommendChrome();
      this._storageService.hasRecommendedBrowser = true;
    }
  }

  get CHROME_DOWNLOAD_ADDRESS() {
    return 'https://www.google.com/chrome/browser/';
  }

  recommendChrome() {
    let dialogButtons = [
      {
        text: 'Download Chrome',
        action: ErrorDialog.CANCEL,
      },
      {
        text: 'Continue to Classkick',
        action: ErrorDialog.CANCEL,
      },
    ];

    this._errorDialog(
      this.$mdDialog,
      'Classkick works best in the Google Chrome browser! ',
      'It will not work as well in Safari, Firefox, Internet Explorer, or other browsers',
      dialogButtons,
    ).catch((buttonConfig) => {
      if (buttonConfig && buttonConfig.text === dialogButtons[0].text) {
        this.$window.location.href = this.CHROME_DOWNLOAD_ADDRESS;
      }
    });
  }

  goToGettingStartedVideo() {
    this.$window.open('https://www.youtube.com/watch?v=vho2a6t-adk', '_blank');
  }

  goToGettingStartedVideoTwo() {
    this.$window.open('https://www.youtube.com/watch?v=W3qtvE42O8k', '_blank');
  }

  goToGettingStartedTutorialVideo() {
    this.$window.open(
      'https://www.youtube.com/watch?v=ui1uVCZPCcg&list=PLRxpSUDPIfZm8Cm1B0KUWjoKu0SUlg3cQ',
      '_blank',
    );
  }

  goToPortfolioStudentTutorialVideo() {
    this.$window.open('https://www.youtube.com/watch?v=iZ8wdU6qgo0', '_blank');
  }

  getViewHelps() {
    return this._viewHelps.value(() => {
      return this.$http.get(this.viewHelpsUrl).then((response) => {
        return response.data;
      });
    });
  }

  getContextualPaywalls() {
    return this._contextualPaywalls.value(() => {
      return this.$http.get(this.contextualPaywallsUrl).then((response) => {
        return response.data;
      });
    });
  }

  get viewHelpsUrl() {
    return `${this._environment.assetsBaseUrl}/configs/view_helps.json`;
  }

  get contextualPaywallsUrl() {
    return `${this._environment.assetsBaseUrl}/configs/contextual_paywalls.json`;
  }

  goToSalesBuddyV2Wizard(recordSession = false) {
    let url = this._environment.salesBuddyV2WizardUrl;

    if (recordSession) {
      url = url + `?active=${recordSession}`;
    }

    this.$window.open(url, '_blank');
  }

  goToSalesBuddyV2(recordSession = false) {
    let url = this._environment.salesBuddyV2Url;

    if (recordSession) {
      url = url + `?active=${recordSession}`;
    }

    this.$window.open(url, '_blank');
  }

  goToTeacherOrderPage(recordSession = false) {
    let url = `${LocationUtil.absRootUrl(this.$location)}/order?tab=teacher`;

    if (recordSession) {
      url = url + `?active=${recordSession}`;
    }

    this.$window.open(url, '_blank');
  }
}
