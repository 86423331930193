'use strict';

import ImportImageDialogController from '../import-image-dialog/import-image-dialog.controller';
import AddManipulativeImageDialogTemplate from './add-manipulative-image-dialog.html';

export default class AddManipulativeImageDialogController {
  /**
   * @ngInject
   */
  constructor($q, $mdDialog, questions, currentQuestionId, deferred) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;

    this._questions = questions;
    this._currentQuestionId = currentQuestionId;
    this._deferred = deferred;

    this._importImageDialog = ImportImageDialogController.show;
  }

  /**
   * Launches manipulative image dialog
   * @param $q
   * @param $mdDialog
   * @param questions {AssignmentQuestion[]}
   * @param currentQuestionId {string}
   */
  static show($q, $mdDialog, questions, currentQuestionId) {
    let deferred = $q.defer();

    $mdDialog.show({
      controller: AddManipulativeImageDialogController,
      template: AddManipulativeImageDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        questions,
        currentQuestionId,
        deferred,
      },
    });

    return deferred.promise;
  }

  selectFile() {
    return this._importImageDialog(
      this.$q,
      this.$mdDialog,
      this._questions,
      true,
      false,
      this._currentQuestionId,
      true,
    ).then((data) => {
      this._deferred.resolve(data);
    });
  }

  cancel() {
    this.$mdDialog.cancel();
    this._deferred.reject();
  }
}
