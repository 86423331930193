'use strict';

import Codec from './codec';
import ChatMessageRead from '../domain/chat-message-read';

export default class ChatMessageReadCodec extends Codec {
  /**
   * @param value {ChatMessageRead}
   * @return {object}
   */
  encode(value) {
    return {
      message_id: value.messageId,
      timestamp: value.timestamp && value.timestamp.toISOString(),
    };
  }

  /**
   * @param value {object}
   * @param key {string}
   * @return {ChatMessageRead|null}
   */
  decode(value, key) {
    if (value) {
      return new ChatMessageRead(key, value.message_id, value.timestamp);
    }
    return null;
  }
}
