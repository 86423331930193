'use strict';

/**
 * Encapsulates a lazy value
 *
 * Example use:
 *
 * class MyClass {
 *   constructor() {
 *     this._lazyNumber = new LazyVar();
 *   }
 *
 *   get number() {
 *     return this._lazyNumber.value(() => {
 *       return { number: 4000 / 7 }; // imagine this is very expensive calculation
 *     });
 *   }
 * }
 *
 * @template T
 */
export default class LazyVar {
  /**
   * @param [nullClear] {boolean} If provided, the clear value will be null instead of an empty object
   */
  constructor(nullClear) {
    this._clearValue = nullClear ? null : {};
    this.clear();
  }

  /**
   * @param [f] {function}
   * @returns {T}
   */
  value(f) {
    if (!this.isSet) {
      this._value = f();
    }
    return this._value;
  }

  /**
   * @returns {boolean}
   */
  get isSet() {
    return this._clearValue !== this._value;
  }

  /**
   * @param value {T}
   */
  set(value) {
    this._value = value;
  }

  /**
   * @param [f] {function} called with value prior to clearing if value is set
   */
  clear(f) {
    if (f && this.isSet) {
      f(this._value);
    }
    this._value = this._clearValue;
  }
}
