'use strict';

import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

export default class FormatPlayback {
  /**
   * prettifies seconds into a string of format mm:ss
   * @param  {number} playbackTimeInSeconds the number of seconds
   * @return {string}                       prettified string
   */
  static formatPlaybackTime(playbackTimeInSeconds) {
    if (isFinite(playbackTimeInSeconds)) {
      return moment
        .duration(playbackTimeInSeconds, 'seconds')
        .format('m:ss', { trim: false });
    }
    return '0:00';
  }
}
