'use strict';

export default class BuyerState {
  /**
   * @returns {string} the buyer state the user is in
   */
  get buyerState() {
    return this._buyerState;
  }

  /**
   * @returns {string} copy title
   */
  get title() {
    return this._title;
  }

  /**
   * @param buyerState {string} the buyer state the user is in
   * @param title {string} copy title
   * @param body {string} body copy
   * @param progressPercent {number} progress percent
   * @param progressLabel {string} label for the percentage
   * @param ctaColor {string} color for CTA
   * @param cta {string} link for CTA or string to indicate quote creation
   * @param ctaCopy {string} copy for CTA
   * @param metric {string} analytics tag for this buyer state
   * @param showOnIos {boolean} indicates whether it shows on iOS
   * @param organizationId {string} the organization id that is tied to the user's buyer state
   * @param orderId {string | undefined} order id for the user's organization
   * @param renewal {boolean} if this organization would be considered a renewal
   */
  constructor(
    buyerState,
    title,
    body,
    progressPercent,
    progressLabel,
    ctaColor,
    cta,
    ctaCopy,
    metric,
    showOnIos,
    organizationId,
    orderId = undefined,
    renewal = undefined,
  ) {
    this._buyerState = buyerState;
    this._title = title;
    this._body = body;
    this._progressPercent = progressPercent;
    this._progressLabel = progressLabel;
    this._ctaColor = ctaColor;
    this._cta = cta;
    this._ctaCopy = ctaCopy;
    this._metric = metric;
    this._showOnIos = showOnIos;
    this._organizationId = organizationId;
    this._orderId = orderId;
    this._renewal = renewal;
  }

  /**
   * @returns {string} body copy
   */
  get body() {
    return this._body;
  }

  /**
   * @returns {number} progress percent
   */
  get progressPercent() {
    return this._progressPercent;
  }

  /**
   * @returns {string} label for the percentage
   */
  get progressLabel() {
    return this._progressLabel;
  }

  /**
   * @returns {string} color for CTA
   */
  get ctaColor() {
    return this._ctaColor;
  }

  /**
   * @returns {string} link for CTA or string to indicate quote creation
   */
  get cta() {
    return this._cta;
  }

  /**
   * @returns {string} copy for CTA
   */
  get ctaCopy() {
    return this._ctaCopy;
  }

  /**
   * @returns {string} analytics tag for this buyer state
   */
  get metric() {
    return this._metric;
  }

  /**
   * @returns {string} indicates whether it shows on iOS
   */
  get showOnIos() {
    return this._showOnIos;
  }

  /**
   * @returns {string} the organization id that is tied to the user's buyer state
   */
  get organizationId() {
    return this._organizationId;
  }

  /**
   * @returns {string} order id for the user's organization
   */
  get orderId() {
    return this._orderId;
  }

  /**
   * @returns {boolean} if this organization would be considered a renewal
   */
  get renewal() {
    return this._renewal;
  }
}
