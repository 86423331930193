'use strict';

export class AnimalName {
  /**
   * @param name {string}
   * @param description {string}
   * @param partialFileName {string}
   * @param color {string}
   */
  constructor(name, description, partialFileName, color) {
    this._name = name;
    this._description = description;
    this._display = `${this._description} ${this._name}`;
    this._displayWithoutArticle = this._display.split(' ').slice(1).join(' ');
    this._partialFileName = partialFileName;
    this._color = color;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @returns {string}
   */
  get description() {
    return this._description;
  }

  /**
   * @returns {string}
   */
  get display() {
    return this._display;
  }

  /**
   * @return {string}
   */
  get displayWithoutArticle() {
    return this._displayWithoutArticle;
  }

  /**
   * @returns {string}
   */
  get partialFileName() {
    return this._partialFileName;
  }

  /**
   * @returns {string}
   */
  get color() {
    return this._color;
  }

  /**
   * @returns {string}
   */
  get file() {
    return `${this._partialFileName}-${this._color}`;
  }
}

let combinations;
let combinationsForHelper;
let combinationsForHelperWithoutColors;

let names = [
  {
    name: 'Bear',
    file: 'bear',
  },
  {
    name: 'Bookworm',
    file: 'bookworm',
  },
  {
    name: 'Bird',
    file: 'chicken',
  },
  {
    name: 'Dog',
    file: 'dog',
  },
  {
    name: 'Pig',
    file: 'pig',
  },
  {
    name: 'Rabbit',
    file: 'rabbit',
  },
  {
    name: 'Robot',
    file: 'robot',
  },
  {
    name: 'Giraffe',
    file: 'giraffe',
  },
  {
    name: 'Panda',
  },
  {
    name: 'Cat',
  },
  {
    name: 'Duck',
  },
  {
    name: 'Fish',
  },
  {
    name: 'Llama',
  },
  {
    name: 'Salamander',
  },
  {
    name: 'Elephant',
  },
  {
    name: 'Dinosaur',
  },
  {
    name: 'Tiger',
  },
  {
    name: 'Monkey',
  },
  {
    name: 'Dolphin',
  },
  {
    name: 'Otter',
  },
];

export default class AnimalNames {
  static get namesWithFiles() {
    return names.filter((x) => x.file);
  }

  static get names() {
    return names;
  }

  static get descriptions() {
    return {
      B: [
        'A Brave',
        'A Bold',
        'A Beaming',
        'A Brainy',
        'A Broadminded',
        'A Budding',
      ],
      C: ['A Creative', 'A Candid', 'A Clever', 'A Curious'],
      D: [
        'A Daring',
        'A Determined',
        'A Decisive',
        'A Devoted',
        'A Dazzling',
        'A Diligent',
      ],
      E: ['An Earnest', 'An Eager', 'An Energetic'],
      F: ['A Friendly', 'A Fearless', 'A Focused'],
      G: ['A Jazzy', 'A Jovial'],
      L: ['A Loving', 'A Learning', 'A Logical'],
      M: ['A Majestic', 'A Masterful', 'A Methodical', 'A Motivated'],
      O: ['An Overjoyed', 'An Optimistic', 'Open-Minded'],
      P: [
        'A Peppy',
        'A Proud',
        'A Passionate',
        'A Persistent',
        'A Plucky',
        'A Poised',
      ],
      R: [
        'A Rational',
        'A Resolute',
        'A Radiant',
        'A Reflective',
        'A Regal',
        'A Revered',
      ],
      S: ['A Savvy', 'A Selfless', 'A Sage'],
      T: ['A Terrific', 'A Teachable', 'A Tireless', 'A Trailblazing'],
    };
  }

  static get colors() {
    return ['green', 'purple', 'orange', 'blue', 'red', 'yellow'];
  }

  static generate(str) {
    let combinationIndex = AnimalNames.hashFunc(
      str,
      AnimalNames.combinations.length,
    );
    return AnimalNames.combinations[combinationIndex];
  }

  static generateForHelper(str) {
    let combinationIndex = AnimalNames.hashFunc(
      str,
      AnimalNames.combinationsForHelper.length,
    );
    return AnimalNames.combinationsForHelper[combinationIndex];
  }

  static generateForHelperWithoutColors(str) {
    let combinationIndex = AnimalNames.hashFunc(
      str,
      AnimalNames.combinationsForHelperWithoutColors.length,
    );
    return AnimalNames.combinationsForHelperWithoutColors[combinationIndex];
  }

  /**
   * @return {Array.<AnimalName>}
   */
  static get combinations() {
    if (!combinations) {
      combinations = AnimalNames.makeCombinations(AnimalNames.descriptions);
    }
    return combinations;
  }

  static get combinationsForHelper() {
    if (!combinationsForHelper) {
      combinationsForHelper = AnimalNames.makeCombinations(
        AnimalNames.descriptions,
      );
    }
    return combinationsForHelper;
  }

  static get combinationsForHelperWithoutColors() {
    if (!combinationsForHelperWithoutColors) {
      combinationsForHelperWithoutColors =
        AnimalNames.makeCombinationsWithoutColors(AnimalNames.descriptions);
    }
    return combinationsForHelperWithoutColors;
  }

  static makeCombinations(descriptions) {
    return AnimalNames.namesWithFiles
      .reduce((accum, animal) => {
        let firstLetter = animal.name[0];
        return accum.concat(
          descriptions[firstLetter].map((description) => {
            return {
              ...animal,
              description: description,
            };
          }),
        );
      }, [])
      .reduce((accum, animal) => {
        return accum.concat(
          AnimalNames.colors.map((color) => {
            return {
              ...animal,
              color: color,
            };
          }),
        );
      }, [])
      .map((animal) => {
        return new AnimalName(
          animal.name,
          animal.description,
          animal.file,
          animal.color,
        );
      });
  }

  static makeCombinationsWithoutColors(descriptions) {
    return AnimalNames.names
      .reduce((accum, animal) => {
        let firstLetter = animal.name[0];
        return accum.concat(
          descriptions[firstLetter].map((description) => {
            return {
              ...animal,
              description: description,
            };
          }),
        );
      }, [])
      .map((animal) => {
        return new AnimalName(
          animal.name,
          animal.description,
          animal.file,
          animal.color,
        );
      });
  }

  static hashFunc(s, max) {
    let hash = 0,
      i,
      chr,
      len;

    if (s.length === 0) {
      return hash;
    }

    for (i = 0, len = s.length; i < len; i++) {
      chr = s.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }

    return Math.abs(hash) % max;
  }
}
