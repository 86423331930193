'use strict';

import HexColors from '../../css-constants';
import AssignmentWorkQuestion from '../../model/domain/assignment-work-question';
import GradingUtils from '../../model/util/grading-utils';
import GradeInputDirectiveTemplate from './grade-input.html';

export default function GradeInputDirective() {
  return {
    restrict: 'E',
    require: 'ngModel',
    scope: {
      potentialScore: '=',
      readonly: '=',
    },
    template: GradeInputDirectiveTemplate,
    link: GradeInputDirectiveController.link,
    controller: GradeInputDirectiveController,
    controllerAs: 'ctrl',
  };
}

export class GradeInputDirectiveController {
  constructor($q, $timeout, hotkeys) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this._hotkeys = hotkeys;

    this._loading = true;
    this._gradeInput = undefined;
    this._hiddenSpan = undefined;
    this._grade = undefined;
  }

  static get MAX_GRADE() {
    return 100;
  }

  get GRADE_INPUT_SELECTOR() {
    return '.grade-input';
  }

  get HIDDEN_SPAN_SELECTOR() {
    return '.hidden-grade-span';
  }

  get POINT_VALUE_TOOLTIP_MESSAGE() {
    return 'Change potential points for this slide';
  }

  get EDITING_SCORE_TOOLTIP_MESSAGE() {
    return 'Give student points for this slide';
  }

  get VIEWING_SCORE_TOOLTIP_MESSAGE() {
    return 'Your points for this slide';
  }

  static link(scope, element, attrs, ngModel) {
    /** @type {GradeInputDirectiveController} */
    const ctrl = scope.ctrl;
    ctrl.ngModel = ngModel;
    ctrl._gradeInput = element.find(ctrl.GRADE_INPUT_SELECTOR);
    ctrl._hiddenSpan = element.find(ctrl.HIDDEN_SPAN_SELECTOR);

    scope.$watch('readonly', (value) => {
      ctrl.readonly = value;
    });

    scope.$watch('potentialScore', (value) => {
      if (angular.isNumber(value)) {
        ctrl.potentialScore = value;
      }
    });

    ctrl._initHotkeys(scope, ctrl._hotkeys);
  }

  get potentialScore() {
    return this._potentialScore;
  }

  set potentialScore(value) {
    this._potentialScore = value;
  }

  get potentialScoreDisplay() {
    return this.hasPotentialScore ? `/ ${this.potentialScore}` : '';
  }

  get hasPotentialScore() {
    return angular.isNumber(this.potentialScore);
  }

  get placeholder() {
    return this.hasPotentialScore ? '--' : 0;
  }

  get readonly() {
    return this._readonly;
  }

  set readonly(value) {
    this._readonly = value;
  }

  /**
   * @param value {NgModelController}
   */
  set ngModel(value) {
    this._ngModel = value;
    this._ngModel.$render = () => this._init();
  }

  _init() {
    if (angular.isNumber(this.ngModelValue)) {
      this._grade = this.ngModelValue.toString();
      this._setWidthFromNgModel();
      this._loading = false;
    } else if (this.ngModelValue === AssignmentWorkQuestion.UNGRADED) {
      this._grade = this.ngModelValue;
      this._setWidthFromNgModel();
      this._loading = false;
    }
  }

  /**
   * @returns {number|string}
   */
  get ngModelValue() {
    return this._ngModel.$viewValue;
  }

  /**
   * @returns {HTMLElement}
   */
  get hiddenSpan() {
    return this._hiddenSpan;
  }

  /**
   * @returns {HTMLElement}
   */
  get gradeInput() {
    return this._gradeInput;
  }

  /**
   * @returns {string}
   */
  get grade() {
    if (this._grade === AssignmentWorkQuestion.UNGRADED) {
      return '';
    } else {
      return this._grade;
    }
  }

  /**
   * @param value {string}
   */
  set grade(value) {
    if (value === '') {
      this._grade = AssignmentWorkQuestion.UNGRADED;
      this._ngModel.$setViewValue(this._grade);
      this._setWidthFromHTMLInputElement();
      return;
    }

    if (value === '.') {
      value = '0.';
      this.gradeInput.val(value);
    }

    if (this._isValid(value)) {
      this._grade = value;
      this._ngModel.$setViewValue(parseFloat(value));
      this._setWidthFromHTMLInputElement();
    }
  }

  /**
   * @param value {string}
   * @returns {boolean}
   */
  _isValid(value) {
    return (
      value <= GradeInputDirectiveController.MAX_GRADE &&
      this._fractionalDigits(value) < 3
    );
  }

  /**
   * @param value {string}
   * @returns {number}
   */
  _fractionalDigits(value) {
    let hasDecimal = value.indexOf('.') !== -1;
    return hasDecimal ? value.split('.')[1].length : 0;
  }

  get styles() {
    if (this._loading) {
      return this.loadingStyles;
    } else if (!this.hasPotentialScore) {
      return this.pointValueStyles;
    } else if (!this._isEditing) {
      return this.scoredPointsStyles;
    } else {
      return '';
    }
  }

  /**
   * @returns {string|undefined}
   */
  get tooltipMessage() {
    if (this.hasPotentialScore) {
      return this.readonly
        ? this.VIEWING_SCORE_TOOLTIP_MESSAGE
        : this.EDITING_SCORE_TOOLTIP_MESSAGE;
    } else {
      return this.POINT_VALUE_TOOLTIP_MESSAGE;
    }
  }

  get loadingStyles() {
    return {
      'border-color': 'transparent',
      color: 'transparent',
      'background-color': 'transparent',
    };
  }

  get pointValueStyles() {
    return {
      'border-color': this._isEditing
        ? HexColors.CK_GREEN
        : HexColors.BORDER_GREY,
    };
  }

  get scoredPointsStyles() {
    let currentScore = this.currentScore;
    let backgroundColor = GradingUtils.colorForScore(currentScore);
    let textColor = GradingUtils.textColorForScore(currentScore);

    return {
      'border-color': backgroundColor || HexColors.BORDER_GREY,
      'background-color': backgroundColor || 'white',
      color: textColor,
    };
  }

  /**
   * @returns {number}
   */
  get currentScore() {
    return parseFloat(this._grade) / this.potentialScore;
  }

  refreshBorderColor() {
    this.$timeout(() => {});
  }

  focusInput() {
    if (!this._isEditing && !this.readonly) {
      this.gradeInput.focus();
    }

    if (!this.readonly) {
      this.gradeInput.select();
    }
  }

  /**
   * @returns {boolean}
   */
  get _isEditing() {
    return !this.readonly && this.gradeInput && this.gradeInput.is(':focus');
  }

  /**
   * @param forceBlur {boolean}
   */
  onUpdated(forceBlur) {
    if (forceBlur) {
      this.gradeInput.blur();
    }
    this._setWidthFromHTMLInputElement();
  }

  /** Helper methods to resize input element **/

  /**
   * Sets input width based on value of NgModel
   */
  _setWidthFromNgModel() {
    this._setInputWidth(this.gradeInput, this.grade.toString());
  }

  /**
   * Sets input width based on value in HTMLInputElement
   * @private
   */
  _setWidthFromHTMLInputElement() {
    this._setInputWidth(this.gradeInput, this.gradeInput.val());
  }

  /**
   * @param input {HTMLElement}
   * @param points {string}
   */
  _setInputWidth(input, points) {
    if (points) {
      this.hiddenSpan.text(points);
      this.$timeout(() => {}, 100, false).then(() => {
        this._setInputToSpanWidth(input);
      });
    }
  }

  _setInputToSpanWidth(input) {
    let newInputWidth = this.hiddenSpan.width() + 5;
    input.width(newInputWidth);
  }

  _initHotkeys(scope, hotkeys) {
    hotkeys.bindTo(scope).add({
      combo: 'g',
      description: 'Focus grading input',
      callback: (ev) => {
        this.focusInput(ev);
      },
    });
  }
}
