'use strict';

export default class MdrOrganizationAdmin {
  constructor(email, firstName, lastName, prefTitle) {
    this._email =
      email && typeof email === 'string' ? email.toLowerCase() : null;
    this._firstName =
      firstName && typeof firstName === 'string'
        ? this._capitalizeWords(firstName)
        : null;
    this._lastName =
      lastName && typeof lastName === 'string'
        ? this._capitalizeWords(lastName)
        : null;
    this._prefTitle =
      prefTitle && typeof prefTitle === 'string'
        ? this._capitalizeWords(prefTitle)
        : null;
  }

  get email() {
    return this._email;
  }

  get firstName() {
    return this._firstName;
  }

  get lastName() {
    return this._lastName;
  }

  get prefTitle() {
    return this._prefTitle;
  }

  _capitalizeWords(input) {
    return input
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
}
