'use strict';

export default class HexColors {
  static get CK_GREEN() {
    return '#25C582';
  }

  static get CK_WARN() {
    return '#ff6c6c';
  }

  static get BACKGROUND_GREY() {
    return '#F7F7F7';
  }

  static get BORDER_GREY() {
    return '#eeeeef';
  }

  static get CK_HEADER_GREY() {
    return '#6D6E70';
  }

  static get CK_DISABLED_GREY() {
    return '#d8d8d8';
  }

  static get CK_ELEMENT_RED() {
    return '#FF5043';
  }

  static get CK_ELEMENT_RED_ORANGE() {
    return '#ff6e4e';
  }

  static get CK_ELEMENT_ORANGE() {
    return '#ffa800';
  }

  static get CK_ELEMENT_YELLOW() {
    return '#ffe600';
  }

  static get CK_ELEMENT_LIME_GREEN() {
    return '#ddf261';
  }

  static get CK_ELEMENT_MINT_GREEN() {
    return '#8ff261';
  }

  static get CK_ELEMENT_GREEN() {
    return '#17c638';
  }

  static get CK_ELEMENT_TEAL() {
    return '#05cec8';
  }

  static get CK_ELEMENT_AQUA() {
    return '#80f9f0';
  }

  static get CK_ELEMENT_TURQUOISE() {
    return '#00b7f9';
  }

  static get CK_ELEMENT_BLUE() {
    return '#3e3eff';
  }

  static get CK_ELEMENT_PURPLE() {
    return '#884aff';
  }

  static get CK_ELEMENT_MAUVE() {
    return '#c7a4ff';
  }

  static get CK_ELEMENT_VIOLET() {
    return '#bd57ff';
  }

  static get CK_ELEMENT_MAGENTA() {
    return '#ff558a';
  }

  static get CK_ELEMENT_PINK() {
    return '#ffaec7';
  }

  static get CK_ELEMENT_SAND() {
    return '#eebe95';
  }

  static get CK_ELEMENT_TAN() {
    return '#d4976b';
  }

  static get CK_ELEMENT_BROWN() {
    return '#9c7550';
  }

  static get CK_ELEMENT_DARK_BROWN() {
    return '#6b473d';
  }

  static get CK_ELEMENT_BLACK() {
    return '#404041';
  }
  static get CK_ELEMENT_GREY() {
    return '#878787';
  }
  static get CK_ELEMENT_LIGHT_GREY() {
    return '#d6d6d6';
  }

  static get CK_ELEMENT_WHITE() {
    return '#FFFFFF';
  }

  static get CK_GRADING_BELOW() {
    return '#F48A8C';
  }

  static get CK_GRADING_APPROACHING() {
    return '#FFBD7B';
  }

  static get CK_GRADING_MEETS() {
    return '#CAE097';
  }

  static get CK_ORDER_BODY_BLACK() {
    return '#45494B';
  }

  static get CK_ROSTER_BLUE() {
    return '#2DCCF7';
  }

  static get CK_ROSTER_ORANGE() {
    return '#FFA045';
  }

  static get CK_ROSTER_PURPLE() {
    return '#CD6EDE';
  }

  static get CK_ROSTER_RED_PINK() {
    return '#F04E8F';
  }

  static get CK_ROSTER_YELLOW() {
    return '#F5C631';
  }

  static get CK_ROSTER_GREEN() {
    return '#15CA9A';
  }

  static get CK_ROSTER_GREY() {
    return '#828F99';
  }

  static get CK_ROSTER_PINK() {
    return '#FFBBBB';
  }

  static get CK_ROSTER_RED() {
    return '#E9552D';
  }

  static get CK_ROSTER_BROWN() {
    return '#8C5225';
  }

  static get CK_ROSTER_LIGHT_BLUE() {
    return '#9DEEF2';
  }

  static get CK_ROSTER_LIGHT_GREEN() {
    return '#C8ED80';
  }

  static get CK_TRANSPARENT() {
    return '#00000000';
  }
  static get CK_LINK_BLUE() {
    return '#1E55CC';
  }
}
