'use strict';

/**
 * Allows students with accounts to give feedback to peers
 */
export default class StudentAccountAssignmentFeedbackController {
  /**
   * @ngInject
   */
  constructor(
    $scope,
    $stateParams,
    BreadcrumbService,
    StorageService,
    StudentCacheService,
    StudentAssignmentService,
  ) {
    this.$stateParams = $stateParams;

    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type{BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type{StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;

    this._model = this._studentAssignmentService.getStudentAssignmentFeedback();

    // add saving indicator
    $scope.$on('$destroy', () => this._model.destroy());

    this.init();
  }

  init() {
    this._studentCacheService
      .getUserWork(this.$stateParams.assignmentWorkId, false)
      .then((assignmentWork) => {
        return this._model.init(assignmentWork);
      });
  }

  /**
   * @return {StudentAssignmentFeedback}
   */
  get model() {
    return this._model;
  }

  goBack() {
    this._storageService.feedbackHelpRequest = null;
    this._breadcrumbService.goBack('^.student-assignment-work', {
      assignmentWorkId: this.model.assignmentWorkId,
    });
  }
}
