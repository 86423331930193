export class OrderPlans {
  static get PrometheusV1() {
    return 'prometheus-v1';
  }

  // deprecate by Feb, 2021
  static get PrometheusV3() {
    return 'prometheus-v3';
  }

  // deprecate by March 31, 2021
  static get PrometheusV4() {
    return 'prometheus-v4';
  }

  static get PrometheusV5() {
    return 'prometheus-v5';
  }

  static get PrometheusV6() {
    return 'prometheus-v6';
  }

  static get PrometheusV7() {
    return 'prometheus-v7';
  }

  static get PrometheusVirtualV1() {
    return 'prometheus-virtual-v1';
  }

  // deprecate by Feb, 2021
  static get PrometheusVirtualV3() {
    return 'prometheus-virtual-v3';
  }

  // deprecate by March 31, 2021
  static get PrometheusVirtualV4() {
    return 'prometheus-virtual-v4';
  }

  static get PrometheusVirtualV5() {
    return 'prometheus-virtual-v5';
  }

  static get PrometheusVirtualV6() {
    return 'prometheus-virtual-v6';
  }

  static get PrometheusVirtualV7() {
    return 'prometheus-virtual-v7';
  }

  static get Prometheus20192020V1() {
    return 'prometheus-2019-2020-v1';
  }

  static get Invincible20192020V1() {
    return 'invincible-2019-2020-v1';
  }

  static get InvincibleV1() {
    return 'invincible-v1';
  }

  static get InvincibleVirtualV1() {
    return 'invincible-virtual-v1';
  }

  // deprecate by Feb, 2021, skipped v2 because we forgot to change it for Nov, 2020
  static get InvincibleV3() {
    return 'invincible-v3';
  }

  // deprecate by March 31, 2021
  static get InvincibleV4() {
    return 'invincible-v4';
  }

  static get InvincibleV5() {
    return 'invincible-v5';
  }

  static get InvincibleV6() {
    return 'invincible-v6';
  }

  static get InvincibleV7() {
    return 'invincible-v7';
  }

  static get InvincibleV8() {
    return 'invincible-v8';
  }

  // deprecate by Feb, 2021, skipped v2 because we forgot to change it for Nov, 2020
  static get InvincibleVirtualV3() {
    return 'invincible-virtual-v3';
  }

  // deprecate by March 31, 2021
  static get InvincibleVirtualV4() {
    return 'invincible-virtual-v4';
  }

  static get InvincibleVirtualV5() {
    return 'invincible-virtual-v5';
  }

  static get InvincibleVirtualV6() {
    return 'invincible-virtual-v6';
  }

  static get InvincibleVirtualV7() {
    return 'invincible-virtual-v7';
  }

  static get InvincibleVirtualV8() {
    return 'invincible-virtual-v8';
  }
}

export default class OrderPlan {
  constructor(id, description, price, available) {
    this.id = id;
    this.description = description;
    this.price = price;
    this.available = available;
  }
}
