'use strict';

import LocationUtil from '../../model/util/location-util';

/**
 * Redirects to your default location as a logged in user
 */
export default class AccountHomeController {
  /**
   * @param $timeout
   * @param $stateParams
   * @param AuthService {AuthService}
   * @param BreadcrumbService {BreadcrumbService}
   * @param BootstrapService {BootstrapService}
   * @param StudentCacheService {StudentCacheService}
   * @param ClassCodeService {ClassCodeService}
   * @ngInject
   */
  constructor(
    $timeout,
    $scope,
    $state,
    $stateParams,
    $mdDialog,
    $location,
    $window,
    AuthService,
    BootstrapService,
    BreadcrumbService,
    StudentCacheService,
    ClassCodeService,
    StorageService,
  ) {
    if (BootstrapService.ready && AuthService.isLoggedIn) {
      $timeout().then(() => {
        if (AuthService.authData.isTeacher) {
          const redirectUrl = decodeURIComponent(
            this.extractStateEncodedValue($stateParams.oAuth),
          );
          const code = this.extractCodeFromUrl($stateParams.oAuth);
          const error = this.extractErrorFromUrl($stateParams.oAuth);
          if (code) {
            StorageService.edlinkCode = code;
          }
          if (error) {
            StorageService.edlinkError = error;
          }
          if (redirectUrl || code || error) {
            return ($window.location.href = `${LocationUtil.absRootUrl($location)}/${redirectUrl}`);
          }

          if (StorageService.sharedWorkPath) {
            const path = StorageService.sharedWorkPath;
            StorageService.sharedWorkPath = '';
            StorageService.ckRedirect = null;
            $mdDialog.cancel();
            return BreadcrumbService.goUrl(path, true);
          } else {
            BreadcrumbService.go('root.account.nav.assignments', {}, true);
          }
        } else if (!AuthService.authData.isAnon && !$stateParams.classCode) {
          BreadcrumbService.go(
            'root.account.nav.student-assignments-list',
            {},
            true,
          );
        } else if (!AuthService.authData.isAnon && $stateParams.classCode) {
          ClassCodeService.get($stateParams.classCode)
            .then((classCode) => {
              return StudentCacheService.getOrCreateWorkForSelf(
                classCode.assignmentId,
                classCode.rosterId,
              );
            })
            .then((assignmentWork) => {
              BreadcrumbService.go(
                'root.account.student-assignment-work',
                {
                  assignmentWorkId: assignmentWork.id,
                  questionId: assignmentWork.questionForIndex(0).id,
                },
                true,
              );
            });
        } else if ($state.current.name === 'root.shared-work') {
          BreadcrumbService.go('root.shared-work', {
            sharedWorkId: $stateParams.sharedWorkId,
          });
        } else {
          BreadcrumbService.go('root.login', {}, true);
        }
      });
    }
  }
  extractStateEncodedValue(url) {
    const startIndex = url.indexOf('state=') + 'state='.length;
    const endIndex = url.indexOf('&code');

    if (startIndex >= 0 && endIndex >= 0) {
      return url.substring(startIndex, endIndex);
    } else if (startIndex >= 0) {
      return url.substring(startIndex);
    } else {
      return null;
    }
  }

  extractCodeFromUrl(url) {
    if (url.includes('code')) {
      let startIndex = url.indexOf('code=') + 5;
      if (startIndex !== -1) {
        return url.substring(startIndex, url.length);
      } else {
        return null;
      }
    }
    return null;
  }

  extractErrorFromUrl(url) {
    if (url.includes('error')) {
      let startIndex = url.indexOf('error=') + 6;
      if (startIndex !== -1) {
        return url.substring(startIndex, url.length);
      } else {
        return null;
      }
    }
    return null;
  }
}
