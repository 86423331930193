import HexColors from '../../css-constants';
import { UserProperties } from '../../model/domain/user';
import { OrderStatus } from '../../model/domain/order';
import ErrorCodes from '../../model/domain/error-codes';
import Validation from '../../model/util/validation';
import gothamRoundedBookFont from '../../../assets/fonts/GothamRnd-Book.otf';

export default class SubmitOrderController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $stateParams,
    $timeout,
    $scope,
    $filter,
    OrderService,
    OrganizationService,
    StripeService,
    BreadcrumbService,
    environment,
  ) {
    this.$q = $q;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$filter = $filter;

    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StripeService} */
    this._stripeService = StripeService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    this._environment = environment;

    /** @type {Order} */
    this._order = undefined;
    /** @type {Organization} */
    this._organization = undefined;

    this._isLoading = true;
    this._emailPattern = Validation.EmailPattern;
    this._billingName = '';
    this._billingEmail = '';
    this._pointPersonEmail = '';
    this._organizationName = '';
    this._street = '';
    this._city = '';
    this._state = '';
    this._stateOptions = UserProperties.STATE_OPTIONS;
    this._country = '';
    this._countryOptions = UserProperties.COUNTRY_OPTIONS;
    this._purchaseOrder = '';

    this._isFocused = false;
    this._cardValue = undefined;
    this._cardInput = undefined;
    this._cardName = '';
    this._cardZip = '';

    if (this.isPayment) {
      this._createStripeCardElement();
    }
    this._init();
  }

  _init() {
    // check that an order id is present or show error
    if (!this.orderId) {
      this._error = new Error('Must include order id to submit order');
    }

    this._orderService
      .get(this.orderId)
      .then((order) => {
        this._validate(order);

        this._order = order;
        this._billingEmail = this._order.billingEmail;

        return this._organizationService.get(order.organizationId);
      })
      .then((organization) => {
        this._organization = organization;
        this._organizationName = organization.name;
        this._city = organization.city;
        this._state = organization.state;
        this._country = organization.country;
        this._isLoading = false;
      })
      .catch((error) => {
        this._error = error;
      });
  }

  _validate(order) {
    if (order.status !== OrderStatus.Created) {
      this.goToOrderDetail();
    }
  }

  goToOrderDetail() {
    this._breadcrumbService.goBack('root.order-detail', {
      orderId: this.orderId,
    });
  }

  /**
   * @return {string}
   */
  get orderId() {
    return this.$stateParams.orderId;
  }

  get isLoading() {
    return this._isLoading;
  }

  /**
   * @return {string}
   */
  get emailPattern() {
    return this._emailPattern;
  }

  get error() {
    return this._error;
  }

  get isPayment() {
    return this.$stateParams.isPayment;
  }

  get orgType() {
    return this._organization && this._organization.type;
  }

  get billingName() {
    return this._billingName;
  }

  set billingName(value) {
    this._billingName = value;
  }

  get billingEmail() {
    return this._billingEmail;
  }

  set billingEmail(value) {
    this._billingEmail = value;
  }

  get pointPersonEmail() {
    return this._pointPersonEmail;
  }

  set pointPersonEmail(value) {
    this._pointPersonEmail = value;
  }

  get organizationName() {
    return this._organizationName;
  }

  set organizationName(value) {
    this._organizationName = value;
  }

  get city() {
    return this._city;
  }

  set city(value) {
    this._city = value;
  }

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  get stateOptions() {
    return this._stateOptions;
  }

  get country() {
    return this._country;
  }

  set country(value) {
    this._country = value;
  }

  get countryOptions() {
    return this._countryOptions;
  }

  /**
   * @param search {string} the search term
   * @param array {string[]} the original array of options
   * @returns {string[]}
   */
  autocompleteFilter(search, array) {
    if (!search) {
      return array;
    }
    return array.filter((value) =>
      value.toLowerCase().includes(search.toLowerCase()),
    );
  }

  get cardName() {
    return this._cardName;
  }

  set cardName(value) {
    this._cardName = value;
  }

  get cardZip() {
    return this._cardZip;
  }

  set cardZip(value) {
    this._cardZip = value;
  }

  // Ensures the display-only input field for the card isn't given an autocomplete value
  get keepEmpty() {
    return '';
  }

  set keepEmpty(value) {}

  get startYear() {
    return this._order && this._order.start.year();
  }

  get endDate() {
    return this._order && this._order.end.format('LL');
  }

  get endYear() {
    return this._order && this._order.end.year();
  }

  get plan() {
    return this._order && this._order.plan;
  }

  get planDescription() {
    return this.plan && this.plan.description;
  }

  get quantity() {
    return this._order && this._order.quantity;
  }

  get total() {
    return this._order && this._currency(this._order.total);
  }

  get purchaseOrder() {
    return this._purchaseOrder;
  }

  set purchaseOrder(value) {
    this._purchaseOrder = value;
  }

  //-------- Stripe UI -----------

  _createStripeCardElement() {
    let fonts = [
      {
        family: 'GothamRoundedBook',
        src: gothamRoundedBookFont,
      },
    ];

    let options = {
      hidePostalCode: true,
      style: {
        base: {
          color: HexColors.CK_HEADER_GREY,
          lineHeight: '26px',
          fontFamily: 'GothamRoundedBook',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
        },
        invalid: {
          color: HexColors.CK_WARN,
          iconColor: HexColors.CK_WARN,
        },
      },
      classes: {
        focus: 'ck-card-focused',
        empty: 'ck-card-empty',
      },
    };

    this._cardInput = this._stripeService.stripe
      .elements({ fonts })
      .create('card', options);

    this._cardInput.mount('.card-element');

    this._cardInput.on('focus', () => {
      this._isFocused = true;
      this.$timeout(() => {}, 0);
    });

    this._cardInput.on('blur', () => {
      this._isFocused = false;
      this.$timeout(() => {}, 0);
    });

    this._cardInput.on('change', (value) => {
      this._cardValue = value;
      this.$timeout(() => {}, 0);
    });
  }

  get isFloating() {
    return {
      'md-input-focused': this._isFocused || this.notEmpty,
    };
  }

  /**
   * @returns {boolean|undefined}
   */
  get cardIsComplete() {
    return this._cardValue && this._cardValue.complete;
  }

  /**
   * @returns {boolean}
   */
  get notEmpty() {
    return this._cardValue && !this._cardValue.empty;
  }

  /**
   * @returns {boolean}
   */
  get cardHasError() {
    return !!this.cardError;
  }

  /**
   * @returns {{type:string, code:string, message:string}}
   */
  get cardError() {
    return this._cardValue && this._cardValue.error;
  }

  get formattedCardError() {
    if (this.cardError) {
      return {
        [this.cardError.code]: true,
      };
    }
    return {};
  }

  /**
   * @return {{error: {code: string}}}
   */
  get emptyCardValue() {
    return {
      empty: true,
      error: {
        code: 'required',
      },
    };
  }

  /**
   * @param value {boolean}
   */
  set invalidPointEmailValidity(value) {
    this.$scope.submitOrderForm.pointPersonEmailInput.$setValidity(
      'invalid-point-email',
      value,
    );
  }

  submit() {
    if (
      this.isPayment &&
      (angular.isUndefined(this._cardValue) || this._cardValue.empty)
    ) {
      this._cardValue = this.emptyCardValue;
      this._cardInput.focus();
      return;
    } else if (this.isPayment && !this.cardIsComplete) {
      return;
    }

    this._isLoading = true;

    this.$q
      .all({
        // update organization with any relevant new properties (e.g. point person, address)
        updates: this._saveOrganizationUpdates(),
        // If needed, create a Stripe token
        token: this.isPayment
          ? this._createToken(this._cardInput, this._cardName, this._cardZip)
          : null,
      })
      .then(({ token }) => {
        this._order.billingName = this._billingName;
        this._order.billingEmail = this._billingEmail;
        this._order.pointEmail = this._pointPersonEmail;
        this._order.purchaseOrder = this._purchaseOrder;

        return this._orderService.submit(this._order, token && token.id);
      })
      .then(() => {
        this.goToOrderDetail();
      })
      .catch((error) => {
        if (error.code === ErrorCodes.INVALID_POINT_EMAIL) {
          this.invalidPointEmailValidity = false;
          this._isLoading = false;
        } else {
          this._error = error;
        }
      });
  }

  _saveOrganizationUpdates() {
    this._organization.street = this._street;
    this._organization.city = this._city;
    this._organization.state = this._state;
    this._organization.country = this._country;

    return this._organizationService.update(this._organization);
  }

  /**
   * @param card {Stripe.Element}
   * @param name {string}
   * @param zip {string}
   * @return {Promise.<string>}
   */
  _createToken(card, name, zip) {
    return this._stripeService
      .createToken(card, {
        name: name,
        address_zip: zip,
      })
      .then(({ token }) => {
        return token;
      });
  }

  _currency(amount) {
    return this.$filter('currency')(amount, '$', 0);
  }
}
