'use strict';

import Codec from './codec';
import UserSlideBackground from '../domain/user-slide-background';

/**
 * Encodes/Decodes a user slide background
 */
export default class UserSlideBackgroundCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {UserSlideBackground}
   */
  encode(value) {
    return {
      id: value.id,
      type: value.type,
      url: value.url,
    };
  }

  /**
   * @param value {object}
   * @param id {string}
   * @returns {UserSlideBackground}
   */
  decode(value, id) {
    return new UserSlideBackground(id, value.type, value.url);
  }
}
