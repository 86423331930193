'use strict';

import ErrorDialogTemplate from './error-dialog.html';

export default class ErrorDialogController {
  constructor($mdDialog, primaryMessage, secondaryMessage, buttons) {
    'ngInject';

    this.primaryMessage = primaryMessage;
    this.secondaryMessage = secondaryMessage;
    this.buttons = buttons || [
      { text: 'Ok', action: ErrorDialogController.CONFIRM },
    ];
    this.$mdDialog = $mdDialog;
  }

  /**
   * @param $mdDialog
   * @param primaryMessage {string} html to display as primary message
   * @param secondaryMessage {string} html to display as secondary message
   * @param [buttons] {string|undefined} buttons to display in error dialog
   */
  static show($mdDialog, primaryMessage, secondaryMessage, buttons) {
    let config = {
      controller: ErrorDialogController,
      template: ErrorDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        primaryMessage,
        secondaryMessage,
        buttons,
      },
      bindToController: true,
    };

    return $mdDialog.show(config);
  }

  static get CONFIRM() {
    return 'ERROR_CONFIRM';
  }

  static get CANCEL() {
    return 'ERROR_CANCEL';
  }

  handleClick(buttonConfig) {
    if (buttonConfig.action === ErrorDialogController.CONFIRM) {
      this.$mdDialog.hide(buttonConfig);
    } else if (buttonConfig.action === ErrorDialogController.CANCEL) {
      this.$mdDialog.cancel(buttonConfig);
    }
  }

  buttonClass(action) {
    if (action === ErrorDialogController.CONFIRM) {
      return 'ck-primary';
    } else {
      return '';
    }
  }
}
