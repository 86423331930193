export default class GradebookRoster {
  /**
   * @param session {Session}
   * @param course {Course}
   * @param classElem {Class}
   *
   */
  constructor(session, course, classElem) {
    this._session = session;
    this._course = course;
    this._class = classElem;
  }

  get session() {
    return this._session;
  }

  get course() {
    return this._course;
  }

  get class() {
    return this._class;
  }
}
