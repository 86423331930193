'use strict';

import Order from '../../model/domain/order';
import Validation from '../../model/util/validation';

import {
  Locations,
  TrackingIds,
} from '../../services/mixpanel/mixpanel.service';

import ReserveLockOrderDialogTemplate from './reserve-lock-order-dialog.html';

export default class ReserveLocklDialogController {
  /**
   * @ngInject
   */

  constructor(
    $scope,
    $mdDialog,
    $mdToast,
    $location,
    OrderService,
    CacheService,
    AuthService,
    user,
    order,
    from,
    clickedFrom,
    $window,
    AnalyticsService,
    activeOrganization,
  ) {
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$mdToast = $mdToast;
    this.$location = $location;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._clickedFrom = clickedFrom;
    this._activeOrganization = activeOrganization;

    this._user = user;
    this._order = order;
    this._from = from || '';
    this.emailRegex = Validation.EmailRegex;
    this._toastMessage = '';
    this._invalidEmails = '';
  }

  /**
   * @return {Boolean}
   */
  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  /**
   * @return {string}
   */
  get invalidEmails() {
    return this._invalidEmails;
  }

  /**
   * @param value {string}
   */
  set invalidEmails(value) {
    this._invalidEmails = value;
  }

  get isFromOrderPage() {
    return (
      this._clickedFrom === Locations.ORDER_DETAILS ||
      this._clickedFrom === Locations.ORDER_PAGE
    );
  }

  get to() {
    return this._to;
  }

  set to(value) {
    this._to = value;
  }

  submitReserveOrderForm(scope) {
    if (!this.isEmailListValid) {
      scope.reserveOrderForm.toInput.$setValidity('pattern', false);
      return (this._invalidEmails = true);
    }

    this.sendReserveOrderTracking();

    this.displayToastMessage("You've successfully reserved this price", true);
    this.$mdDialog.cancel();
  }

  sendReserveOrderTracking() {
    let trackingInfo = {
      orderId: this._order.id,
      trackingId: TrackingIds.CONVERSION_COMMITMENT,
      userId: this._user ? this._user.id : !this._from ? null : this._from,
      source: this._clickedFrom,
      targets: this._to.split(/[,;]/).map((email) => email.trim()),
    };
    this._orderService.track(trackingInfo);
  }

  setEmailValidity(scope) {
    this.invalidEmails = false;
    scope.reserveOrderForm.toInput.$setValidity(
      'pattern',
      this.isEmailListValid,
    );
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = [];
    // catch errors when emails is undefined
    try {
      emails = this.to.split(/[,;]/);
    } catch (e) {
      return false;
    }

    if (emails.length > 10) {
      return false;
    }

    let isValid;

    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  /**
   * Launches reserve order dialog
   * @param $mdDialog
   * @param user {User}
   * @param order {Order}
   * @param [from] {string}
   */

  static show($mdDialog, user, order, from, clickedFrom, activeOrganization) {
    return $mdDialog.show({
      controller: ReserveLocklDialogController,
      template: ReserveLockOrderDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        user,
        order,
        from,
        clickedFrom,
        activeOrganization,
      },
    });
  }

  cancel() {
    this._analyticsService.closeReservePrice(this._order.id);
    this.$mdDialog.cancel();
  }

  displayToastMessage(message, isFromOrderPage) {
    this._toastMessage = message;

    let toastClassName = isFromOrderPage ? 'order-toast' : 'referral-toast';
    const config = this.$mdToast
      .simple(this._toastMessage)
      .action(' ')
      .actionKey('x')
      .position('top right')
      .toastClass(toastClassName)
      .hideDelay(4000);
    this.$mdToast.show(config);
  }
}
