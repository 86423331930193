'use strict';

import ScoreUtil from '../../model/util/score-utils';

export default class StickerManager {
  constructor(
    $q,
    $log,
    CacheService,
    ImageEditService,
    MediaService,
    AuthService,
  ) {
    this.$q = $q;
    this.$log = $log;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;
    /** @type {MediaService} */
    this._mediaService = MediaService;
    /** @type {AuthService} */
    this._authService = AuthService;

    this.reset();
  }

  init() {
    this._cacheService.getStickersForUser(false).then((stickers) => {
      this.stickers = stickers;
    });
  }

  /**
   * @return {UserSticker[]}
   */
  get stickers() {
    return this._stickers;
  }

  /**
   * @param value {UserSticker[]}
   */
  set stickers(value) {
    if (value !== this._stickers) {
      this.reset();
    }

    this._stickers = value;
  }

  /**
   * @return {UserSticker}
   */
  get selected() {
    return this._selected;
  }

  /**
   * @param value {UserSticker}
   */
  set selected(value) {
    this._selectedText = value && value.text;
    this._selectedScore = value && value.score;
    this._selectedImageUrl = value && value.imageUrl;
    this._selected = value;
  }

  get selectedText() {
    return this._selectedText;
  }

  set selectedText(value) {
    this._selectedText = value;
  }

  get selectedScore() {
    return ScoreUtil.formatScore(this._selectedScore);
  }

  set selectedScore(value) {
    this._selectedScore = ScoreUtil.formatScore(value);
  }

  get selectedImageUrl() {
    return this._selectedImageUrl;
  }

  set selectedImageUrl(value) {
    this._selectedImageUrl = value;
  }

  saveSelected() {
    let promise;

    if (this._selectedImageUrl.startsWith('data:')) {
      const blob = this._imageEditService.dataURItoBlob(this._selectedImageUrl);
      promise = this._uploadStickerMedia(blob);
    } else if (this._selectedImageUrl.startsWith('blob:')) {
      promise = this._imageEditService
        .objectUrlToBlob(this._selectedImageUrl)
        .then((blob) => {
          return this._uploadStickerMedia(blob);
        });
    } else {
      promise = this.$q.resolve(this._selectedImageUrl);
    }

    return promise
      .then((selectedImageUrl) => {
        this.selected.text = this._selectedText;
        this.selected.score = this._selectedScore;
        this.selected.imageUrl = selectedImageUrl;

        if (!this.selected.id) {
          return this._cacheService.createSticker(
            this.selected.imageUrl,
            this.selected.text,
            this.selected.tags,
          );
        } else {
          return this._save(
            this.selected,
            this._getBeforeStickerId(this.selected.id),
          );
        }
      })
      .then((sticker) => {
        this.partialReset();
        return sticker;
      });
  }

  /**
   * @param blob {Blob}
   * @return {Promise}
   */
  _uploadStickerMedia(blob) {
    // TODO figure out what to do about uploading media b/c current endpoint is designed for elements
    return this._mediaService
      .create(blob, 'feedback', this._authService.currentUserId)
      .then((result) => {
        return result.mediaLink;
      });
  }

  deleteSelected() {
    return this._cacheService.deleteSticker(this.selected.id).then(() => {
      this.partialReset();
    });
  }

  reset() {
    this._stickers = undefined;
    this.partialReset();
  }

  partialReset() {
    this._selected = undefined;
    this._selectedText = undefined;
    this._selectedScore = undefined;
    this._selectedImageUrl = undefined;
  }

  /**
   * @param stickerId {string}
   * @return {Promise<UserSticker>}
   */
  save(stickerId) {
    let sticker = this.stickers.find((sticker) => sticker.id === stickerId);
    let beforeStickerId = this._getBeforeStickerId(stickerId);
    return this._save(sticker, beforeStickerId);
  }

  /**
   * @param sticker {UserSticker}
   * @param [beforeStickerId] {string}
   * @return {Promise<UserSticker>}
   */
  _save(sticker, beforeStickerId) {
    return this._cacheService.updateSticker(sticker, beforeStickerId);
  }

  /**
   * @param stickerId
   * @return {string}
   */
  _getBeforeStickerId(stickerId) {
    let stickerIndex = this.stickers
      .map((sticker) => sticker.id)
      .indexOf(stickerId);
    let beforeSticker = this.stickers[stickerIndex + 1];
    return beforeSticker && beforeSticker.id;
  }
}
