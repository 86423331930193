'use strict';

import Element from './element';
import Point from '../point';
import Size from '../size';
import RemoveHandle from '../remove-handle';
import GestureManager from '../gesture-manager';
import HexColors from '../../../css-constants';
import MultipleChoiceOptionControl from '../multiple-choice-option-control';
import StaticService from '../../../services/static/static.service';

export class MultipleChoiceOption {
  /**
   * @param answer <string>
   * @param points <float>
   * @param correct <boolean>
   */
  constructor(answer, points = null, correct = null) {
    this._answer = answer;
    this._points = points;
    this._correct = correct;
  }

  get points() {
    return this._points;
  }

  set points(value) {
    this._points = value;
  }

  get correct() {
    return this._correct;
  }

  set correct(value) {
    this._correct = value;
  }

  get answer() {
    return this._answer;
  }
}

export default class MultipleChoiceParent extends Element {
  /**
   * @param id {string|null}
   * @param metadata {ElementMetadata}
   * @param location {Point}
   * @param size {Size}
   * @param options {MultipleChoiceOption[]}
   */
  constructor(id, metadata, location, size, options) {
    super(id, MultipleChoiceParent.type, metadata);

    this._location = location || new Point(100, 200);
    this._size = size || new Size(280, 84);
    this._options = options;
    this._renderedOptions = new Map();
    this._padding = 20;
  }

  static get type() {
    return 'mchoice_parent';
  }

  get typeDisplay() {
    return 'multiple choice';
  }

  get isParentManipulative() {
    return true;
  }

  get answerDisplay() {
    return this._options
      .filter((option) => option.correct)
      .map((option) => option.answer)
      .join(', ');
  }

  get options() {
    return this._options;
  }

  get points() {
    let correctOption = this._options.find((option) => option.correct);
    return correctOption && correctOption.points;
  }

  hoverIn() {
    this.tryUpdate();
  }

  hoverOut() {
    this.tryUpdate();
  }

  /**
   * @returns {Boolean}
   */
  get hovering() {
    return this._drag.hovering || this._removeHandle.hovering;
  }

  _repositionStart() {
    this._dragStartState = this.snapshot();
  }

  _repositionMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _repositionEnd() {
    this._onChanged(this._dragStartState);
  }

  createElement(root, editable, active) {
    this._base = root.group();
    this._background = this._base.rect(0, 0, 0, 0);
    this._pointValue = this._base.text(0, 0, '');

    this._interactive = root.group();
    this._touch = this._interactive
      .rect(0, 0, 0, 0)
      .addClass('touch-foreground');

    this._renderedOptions.clear();

    if (active) {
      this._gesture = new GestureManager(this, this.canvas);
      this._gesture._dragThreshold = 0;
      this._gesture.start(this._touch.node);
      this._gesture.click.subscribe(this._toggle, this);
    }
    if (editable) {
      this._removeHandle = new RemoveHandle(this);
      this._removeHandle.render(this._interactive);
      this._removeHandle.mouseEnter.subscribe(this.hoverIn, this);
      this._removeHandle.mouseLeave.subscribe(this.hoverOut, this);

      this._drag = new GestureManager(this, this.canvas);
      this._drag.start(this._touch.node);
      this._drag.click.subscribe(this.focus, this);
      this._drag.dragStart.subscribe(this._repositionStart, this);
      this._drag.dragMove.subscribe(this._repositionMove, this);
      this._drag.dragEnd.subscribe(this._repositionEnd, this);
      this._drag.dragEnd.subscribe(this.focus, this);
      this._drag.mouseEnter.subscribe(this.hoverIn, this);
      this._drag.mouseLeave.subscribe(this.hoverOut, this);
    }
  }

  update(root, editable, active) {
    this._background.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      ry: 4,
      fill: HexColors.BACKGROUND_GREY,
      stroke: HexColors.CK_HEADER_GREY,
    });

    let pointValue = this._options
      .filter((option) => option.correct)
      .map((option) => option.points)
      .pop();

    this._pointValue.attr({
      x: this.location.x + 8,
      y: this.location.y + 19,
      style: this.textStyle,
      text: angular.isNumber(pointValue)
        ? pointValue === 1
          ? `${pointValue} Pt`
          : `${pointValue} Pts`
        : '',
    });

    const columnDivisor = this._options.length;

    let colWidth = (this.width - this._padding - this._padding) / columnDivisor;

    this._options.forEach((option, index) => {
      let id = `${this.id}-${index}`;
      let renderedOption = this._renderedOptions.get(id);

      if (!renderedOption) {
        renderedOption = new MultipleChoiceOptionControl(id, this, option);
        renderedOption.render(
          this._interactive,
          false,
          false,
          this.canvas,
          this.assignment,
          this.viewerMetadata,
          this.thumbnail,
          this.moreOptionsManager,
        );
        this._renderedOptions.set(id, renderedOption);
      }

      renderedOption.location = new Point(
        this.location.x + colWidth * index + this._padding,
        this.location.y + this.height * 0.25,
      );

      renderedOption.tryUpdate();
    });

    this._touch.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      ry: 4,
      fill: 'transparent',
      cursor: 'move',
    });

    if (!editable && active) {
      this._touch.attr({
        x: this.location.x,
        y: this.location.y,
        width: this.width,
        height: this.height,
        rx: 4,
        ry: 4,
        fill: 'transparent',
        cursor: 'move',
      });
    } else if (editable) {
      this._background.attr({
        stroke:
          this.hovering || this.hasFocus
            ? HexColors.CK_GREEN
            : HexColors.CK_HEADER_GREY,
      });
      this._removeHandle.location = this.location.plus(
        new Point(this.width, 0),
      );
      this._removeHandle.visibility =
        this.hovering || this.hasFocus ? 'visible' : 'hidden';
    }
  }

  get textStyle() {
    return `
      pointer-events:none;
      font-family:GothamRoundedMedium;
      dominant-baseline:ideographic;
      line-height:115%;
      font-size:13px;
      fill: ${HexColors.CK_HEADER_GREY};
    `;
  }

  _toggle() {
    this.focus();
    this.tryUpdate();
  }

  warnBeforeDeletion() {
    this._background.attr({
      stroke: HexColors.CK_WARN,
    });
  }

  remove() {
    this._stopEvents();
    super.remove();
  }

  _stopEvents() {
    if (this._removeHandle) {
      this._removeHandle.remove();
    }
    if (this._drag) {
      this._drag.stop();
    }
    if (this._selectedDrag) {
      this._selectedDrag.stop();
    }
    if (this._resizers) {
      this._resizers.topLeft.remove();
      this._resizers.bottomLeft.remove();
      this._resizers.bottomRight.remove();
    }
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {MultipleChoiceParent}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;
    this._options = other._options || this._options;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _options: this._options,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {MultipleChoiceParent}
   */
  fromSnapshot(id, snapshot) {
    return new MultipleChoiceParent(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._options,
    );
  }
}
