'use strict';

import CollectionObserver from '../../model/firebase-mapping/collection-observer';
import Textbox from '../../model/ui/elements/textbox';
import Link from '../../model/ui/elements/link';
import SvgThumbnail from '../../model/ui/svg-thumbnail';
import Sticker from '../../model/ui/elements/sticker';
import AudioClip from '../../model/ui/elements/audio-clip';
import CkImage from '../../model/ui/elements/ckimage';
import StraightLine from '../../model/ui/elements/straight-line';
import SlideBackground from '../../model/ui/elements/slide-background';
import SlideForeground from '../../model/ui/elements/slide-foreground';
import Line from '../../model/ui/elements/line';
import Highlight from '../../model/ui/elements/highlight';
import FillInTheBlankParent from '../../model/ui/elements/fill-in-the-blank-parent';
import FillInTheBlankChild from '../../model/ui/elements/fill-in-the-blank-child';
import MultipleChoiceParent from '../../model/ui/elements/multiple-choice-parent';
import MultipleChoiceChild from '../../model/ui/elements/multiple-choice-child';
import ManipulativeImageParent from '../../model/ui/elements/manipulative-image-parent';
import ManipulativeImageChild from '../../model/ui/elements/manipulative-image-child';
import AssignmentThumbnailDirectiveTemplate from './assignment-thumbnail.html';

const nonManipulativeParents = new Set([
  AudioClip.type,
  CkImage.type,
  Line.type,
  Highlight.type,
  Link.type,
  Sticker.type,
  Textbox.type,
  MultipleChoiceChild.type,
  ManipulativeImageChild.type,
  FillInTheBlankChild.type,
  StraightLine.type,
  SlideBackground.type,
  SlideForeground.type,
]);

const manipulativeParents = new Set([
  MultipleChoiceParent.type,
  ManipulativeImageParent.type,
  FillInTheBlankParent.type,
]);

export class AssignmentThumbnailMetadata {
  /**
   * @param target {Assignment | AssignmentWork} the target assignment or work
   * @param questionId {string} the target question id
   * @param [userId] {string} The current user's ID
   * @param [userRole] {string} The current user's role (UserRoles)
   * @param [intent] {string} The intent for contained elements (ElementIntents)
   * @param [contentOpacity] {number}
   * @param [workOpacity] {number}
   * @param [elementsToShow] {Set<string>|undefined}
   */
  constructor(
    target,
    questionId,
    userId,
    userRole,
    intent,
    contentOpacity = 1,
    workOpacity = 1,
    elementsToShow,
  ) {
    this._metadata = {
      userId: userId,
      role: userRole,
      intent: intent,
    };

    this._contentOpacity = contentOpacity;
    this._workOpacity = workOpacity;

    this._target = target;
    this._questionId = questionId;
    this._questionIndex = target.indexForQuestionId(questionId);

    this._elementsToShow = elementsToShow;
  }

  /**
   * @returns {{userId: string, role: string, intent: string}}
   */
  get metadata() {
    return this._metadata;
  }

  /**
   * @returns {Assignment|AssignmentWork}
   */
  get target() {
    return this._target;
  }

  /**
   * @returns {string}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @returns {int}
   */
  get index() {
    return this._questionIndex;
  }

  /**
   * @returns {number}
   */
  get contentOpacity() {
    return this._contentOpacity;
  }

  /**
   * @returns {number}
   */
  get workOpacity() {
    return this._workOpacity;
  }

  /**
   * @return {Set<string>}
   */
  get elementsToShow() {
    return this._elementsToShow;
  }

  static get NonManipulativeParents() {
    return nonManipulativeParents;
  }

  static get ManipulativeParents() {
    return manipulativeParents;
  }
}

export default function AssignmentThumbnailDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      config: '=',
    },
    template: AssignmentThumbnailDirectiveTemplate,
    link: AssignmentThumbnailDirectiveController.link,
    controller: AssignmentThumbnailDirectiveController,
    controllerAs: 'ctrl',
  };
}

class Observer extends CollectionObserver {
  constructor(ctrl) {
    super();
    this.ctrl = ctrl;
  }

  onAdded(value) {
    this.ctrl.renderOne(value);
  }

  onRemoved(value) {
    this.ctrl.remove(value);
  }

  onChanged(value) {
    this.ctrl.renderOne(value);
  }
}

class AssignmentThumbnailDirectiveController {
  constructor($scope, $document, $timeout, $window) {
    'ngInject';

    this.$document = $document;
    this.$timeout = $timeout;
    this.$window = $window;

    $scope.$on('$destroy', () => this._destroy());

    /** @type {SvgThumbnail} */
    this._svgThumbnail = null;

    /** @type {AssignmentThumbnailMetadata} */
    this._config = null;
  }

  /**
   * the link function is run after the constructor for the controller
   * @param scope {$scope}
   * @param element {object}
   * @param attrs {object}
   * @param ctrl {AssignmentThumbnailDirectiveController}
   */
  static link(scope, element, attrs, ctrl) {
    ctrl.element = element;

    scope.$watch('config', (value) => {
      ctrl.config = value;
    });
  }

  get element() {
    return this._element;
  }

  set element(value) {
    this._element = value;
    this._svgThumbnail = new SvgThumbnail(this._element);
  }

  /**
   * @param value {AssignmentThumbnailMetadata}
   */
  set config(value) {
    if (value === this._config || value === null) {
      return;
    }

    if (this._setup) {
      this.$timeout.cancel(this._setup);
    }

    this._setup = this.$timeout(
      () => {
        this._init(() => {
          this._config = value;
        });
        this._setup = undefined;
      },
      0,
      false,
    );
  }

  /**
   * @returns {boolean}
   */
  get hasMetadata() {
    return this._config && this._config.metadata;
  }

  /**
   * @returns {string}
   */
  get _ownerId() {
    if (this.hasMetadata) {
      return this._config.metadata.userId;
    }
    return '';
  }

  /**
   * @returns {string}
   */
  get _role() {
    if (this.hasMetadata) {
      return this._config.metadata.role;
    }
    return '';
  }

  /**
   * @returns {string}
   */
  get _intent() {
    if (this.hasMetadata) {
      return this._config.metadata.intent;
    }
    return '';
  }

  /**
   * @returns {number}
   */
  get _index() {
    if (this._config) {
      return this._config.index;
    }
    return '';
  }

  /**
   * @returns {Assignment|AssignmentWork}
   */
  get _target() {
    return this._config && this._config.target;
  }

  /**
   * @return {Assignment}
   */
  get _assignment() {
    return this._isWork ? this._target.assignment : this._target;
  }

  /**
   * @return {AssignmentWork|null}
   */
  get _assignmentWork() {
    return this._isWork ? this._target : null;
  }

  /**
   * @returns {boolean}
   */
  get _isWork() {
    return this._target && this._target.isWork;
  }

  /**
   * @return {Set<string>}
   */
  get _elementsToShow() {
    return this._config && this._config.elementsToShow;
  }

  /**
   * @param f {function} executes modifications to controller state to surround by subscription logic
   * @private
   */
  _init(f) {
    if (this.workOrContent) {
      this.workOrContent.unsubscribe(this._observer);
    }

    f();

    this._svgThumbnail.layers.clear();

    if (angular.isNumber(this._index) && this._target && this._isInDom) {
      this._initOpacity();

      this._observer = new Observer(this);
      this.workOrContent.subscribe(this._observer);
      this.workOrContentQuestion.start();
    }
  }

  get _isInDom() {
    return this.$document[0].body.contains(this._element[0]);
  }

  /**
   * @private
   */
  _destroy() {
    if (this.workOrContent) {
      this.workOrContent.unsubscribe(this._observer);
      this.workOrContent.forEach((element) => {
        element.cleanUp();
      });
    }
  }

  /**
   * @returns {AssignmentQuestion}
   */
  get contentQuestion() {
    if (this._assignment) {
      return this._assignment.questions[this._index];
    }
    return undefined;
  }

  /**
   * @returns {FirebaseCollection.<Element>}
   */
  get content() {
    if (this.contentQuestion) {
      return this.contentQuestion.elements;
    }
    return undefined;
  }

  /**
   * @returns {AssignmentQuestion}
   */
  get workQuestion() {
    if (this._assignmentWork) {
      return this._assignmentWork.questionForIndex(this._index);
    }
    return undefined;
  }

  /**
   * @returns {FirebaseCollection.<Element>}
   */
  get work() {
    if (this.workQuestion) {
      return this.workQuestion.elements;
    }
    return undefined;
  }

  /**
   * @return {FirebaseCollection<Element>}
   */
  get workOrContent() {
    return this._isWork ? this.work : this.content;
  }

  /**
   * @return {AssignmentQuestion}
   */
  get workOrContentQuestion() {
    return this._isWork ? this.workQuestion : this.contentQuestion;
  }

  /**
   * Renders an element to the SVG DOM
   * @param element {Element}
   */
  renderOne(element) {
    if (this._elementsToShow.has(element.type)) {
      element.render(
        this._svgThumbnail.layers.pickLayer(element),
        false,
        false,
        this._svgThumbnail,
        this._target,
        this._config.metadata,
        true,
      );
      element.visibility = 'inherit';
    }
  }

  /**
   * @param element {Element}
   */
  remove(element) {
    if (element.remove) {
      element.remove();
    }
  }

  _initOpacity() {
    if (this._hasOpacity) {
      this._setOpacity();
    }
  }

  /**
   * @returns {boolean}
   */
  get _hasOpacity() {
    return (
      angular.isNumber(this._config.contentOpacity) &&
      angular.isNumber(this._config.workOpacity)
    );
  }

  /**
   * Sets content layer to the current content opacity configuration
   */
  _setOpacity() {
    this._svgThumbnail.layers.content.attr({
      opacity: this._config.contentOpacity,
    });
    this._svgThumbnail.layers.work.attr({ opacity: this._config.workOpacity });
  }
}
