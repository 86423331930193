import Element from './element';
import Line from './line';
import GestureManager from '../gesture-manager';
import HexColors from '../../../css-constants';
import Point from '../point';
import Trig from '../../util/trig';
import { DefaultPlacement } from '../placement';

class StraightLinePlacement extends DefaultPlacement {
  /**
   * @param root
   * @param initial
   * @param current
   */
  create(root, initial, current) {
    this._rootPath = root.path('');
  }

  /**
   * @param root
   * @param initial
   * @param current
   */
  update(root, initial, current) {
    const d = Line.svgPathFormat([initial, current]);

    this._rootPath.attr({
      d,
      stroke: HexColors.CK_GREEN,
      strokeWidth: 2,
      strokeLinecap: 'butt',
      fill: 'none',
      pointerEvents: 'none',
    });
  }

  remove() {
    this._rootPath.remove();
  }
}

export default class StraightLine extends Element {
  constructor(id, metadata, start, end, color, width) {
    super(id, StraightLine.type, metadata);

    this._start = start;
    this._end = end;
    this._color = color;
    this._width = width;

    this._placement = new StraightLinePlacement();
  }

  static get type() {
    return 'sline';
  }

  get typeDisplay() {
    return 'line';
  }

  /**
   * @return {Point}
   */
  get start() {
    return this._start;
  }

  set start(value) {
    this._start = value;
    this.tryUpdate();
  }

  /**
   * @return {Point}
   */
  get end() {
    return this._end;
  }

  /**
   * @param value {Point}
   */
  set end(value) {
    this._end = value;
  }

  /**
   * @return {string}
   */
  get color() {
    return this._color;
  }

  /**
   * @param value {string}
   */
  set color(value) {
    this._trackChange(() => {
      this._color = value;
    });
  }

  /**
   * @return {number}
   */
  get width() {
    return this._width;
  }

  /**
   * @param value {number}
   */
  set width(value) {
    this._trackChange(() => {
      this._width = value;
    });
  }

  /**
   * @param root {Snap} the root Snap.svg group
   * @param editable {boolean}
   */
  createElement(root, editable) {
    /** @type {Snap} */
    this._root = root;

    this._highlightPath = this._root.path('');
    this._rootPath = this._root.path('');
    this._highlightRect = this._root.rect(0, 0, 0, 0);
    this._interactivePath = this._root.path('');

    if (editable) {
      this._interactiveDrag = new GestureManager(this, this.canvas);
      this._interactiveDrag.start(this._interactivePath.node);
      this._interactiveDrag.click.subscribe(this._onClick, this);
      this._interactiveDrag.dragStart.subscribe(this._repositionStart, this);
      this._interactiveDrag.dragMove.subscribe(this._repositionMove, this);
      this._interactiveDrag.dragEnd.subscribe(this._repositionEnd, this);
      this._interactiveDrag.mouseEnter.subscribe(this._handleMouseEnter, this);
      this._interactiveDrag.mouseLeave.subscribe(this._handleMouseLeave, this);

      this._startHandle = this._root.circle(0, 0, 0);
      this._startHandleInteractive = this._root.circle(0, 0, 0);

      this._startDrag = new GestureManager(this, this.canvas);
      this._startDrag.start(this._startHandleInteractive.node);
      this._startDrag.dragStart.subscribe(this._startRepositionStart, this);
      this._startDrag.dragMove.subscribe(this._startRepositionMove, this);
      this._startDrag.dragEnd.subscribe(this._startRepositionEnd, this);
      this._startDrag.mouseEnter.subscribe(this._handleMouseEnter, this);
      this._startDrag.mouseLeave.subscribe(this._handleMouseLeave, this);

      this._endHandle = this._root.circle(0, 0, 0);
      this._endHandleInteractive = this._root.circle(0, 0, 0);

      this._endDrag = new GestureManager(this, this.canvas);
      this._endDrag.start(this._endHandleInteractive.node);
      this._endDrag.dragStart.subscribe(this._endRepositionStart, this);
      this._endDrag.dragMove.subscribe(this._endRepositionMove, this);
      this._endDrag.dragEnd.subscribe(this._endRepositionEnd, this);
      this._endDrag.mouseEnter.subscribe(this._handleMouseEnter, this);
      this._endDrag.mouseLeave.subscribe(this._handleMouseLeave, this);
    }
  }

  /**
   * @param root {Snap} the root Snap.svg group
   * @param editable {boolean}
   */
  update(root, editable) {
    const d = Line.svgPathFormat([this.start, this.end]);

    this._rootPath.attr({
      d,
      stroke: this.color,
      strokeWidth: this.width,
      strokeLinecap: 'butt',
      fill: 'none',
      pointerEvents: 'none',
    });

    if (editable) {
      this._highlightPath.attr({
        d,
        stroke: HexColors.CK_GREEN,
        strokeWidth: this.width + 2,
        strokeLinecap: 'butt',
        fill: 'none',
        pointerEvents: 'none',
        visibility: this.showSelected ? 'inherit' : 'hidden',
      });

      const { x, y, width, height } = this._highlightPath.getBBox();

      this._highlightRect.attr({
        x,
        y,
        width,
        height,
        fill: 'transparent',
        stroke: HexColors.CK_GREEN,
        strokeWidth: 1.5,
        pointerEvents: 'none',
        visibility: this.hasFocus ? 'inherit' : 'hidden',
      });

      this._interactivePath.attr({
        d,
        stroke: 'transparent',
        strokeWidth: 15,
        strokeLinecap: 'butt',
        fill: 'none',
        cursor: 'move',
      });

      this._updateHandle(
        this._startHandle,
        this._startHandleInteractive,
        this._start,
      );
      this._updateHandle(
        this._endHandle,
        this._endHandleInteractive,
        this._end,
      );
    }
  }

  _updateHandle(base, interactive, location) {
    base.attr({
      fill: HexColors.CK_GREEN,
      visibility: this.showSelected ? 'inherit' : 'hidden',
      cx: location.x,
      cy: location.y,
      r: 5,
    });

    interactive.attr({
      fill: 'transparent',
      cursor: 'pointer',
      visibility: true,
      cx: location.x,
      cy: location.y,
      r: 7,
    });
  }

  warnBeforeDeletion() {
    const stroke = { stroke: HexColors.CK_WARN };
    this._highlightPath.attr(stroke);
    this._highlightRect.attr(stroke);

    const fill = { fill: HexColors.CK_WARN };
    this._startHandle.attr(fill);
    this._endHandle.attr(fill);
  }

  _onClick() {
    this.focus();
  }

  _repositionStart() {
    this.focus();

    this._dragStartStart = this._start;
    this._dragStartEnd = this._end;
    this._dragStartState = this.snapshot();
  }

  _repositionMove(data) {
    this._start = this._dragStartStart.plus(data.delta);
    this._end = this._dragStartEnd.plus(data.delta);
    this.tryUpdate();
  }

  _repositionEnd() {
    this._onChanged(this._dragStartState);
  }

  _handleMouseEnter() {
    this.tryUpdate();
  }

  _handleMouseLeave() {
    this.tryUpdate();
  }

  _startRepositionStart() {
    this._dragStartStart = this._start;
    this._dragStartState = this.snapshot();
  }

  _startRepositionMove(data) {
    const origin = this._end;
    const newPosition = this._dragStartStart.plus(data.delta);

    if (data.originalEvent.shiftKey) {
      this._start = this._closestLocation(newPosition, origin, 15);
    } else {
      let degreeDifference = this._degreeDifference(newPosition, origin, 90);
      let absoluteDifference = Math.abs(degreeDifference);
      let withinThreshold = absoluteDifference < 1;
      this._start = withinThreshold
        ? Trig.rotate(newPosition, origin, degreeDifference)
        : newPosition;
    }

    this.tryUpdate();
  }

  _startRepositionEnd() {
    this._onChanged(this._dragStartState);
  }

  _endRepositionStart() {
    this._dragStartEnd = this._end;
    this._dragStartState = this.snapshot();
  }

  _endRepositionMove(data) {
    const origin = this._start;
    const newPosition = this._dragStartEnd.plus(data.delta);

    if (data.originalEvent.shiftKey) {
      this._end = this._closestLocation(newPosition, origin, 15);
    } else {
      let degreeDifference = this._degreeDifference(
        newPosition,
        this._start,
        90,
      );
      let absoluteDifference = Math.abs(degreeDifference);
      let withinThreshold = absoluteDifference < 1;
      this._end = withinThreshold
        ? Trig.rotate(newPosition, origin, degreeDifference)
        : newPosition;
    }

    this.tryUpdate();
  }

  _endRepositionEnd() {
    this._onChanged(this._dragStartState);
  }

  /**
   * @param location {Point}
   * @param origin {Point}
   * @param closestDegrees {number}
   * @return {Point}
   */
  _closestLocation(location, origin, closestDegrees) {
    let degreeDifference = this._degreeDifference(
      location,
      origin,
      closestDegrees,
    );
    return Trig.rotate(location, origin, degreeDifference);
  }

  /**
   * @param location {Point}
   * @param origin {Point}
   * @param degrees {number}
   * @return {number}
   */
  _degreeDifference(location, origin, degrees) {
    let change = this._deriveChange(
      origin.plus(new Point(50, 0)),
      location,
      origin,
    );
    let closestDegree = Trig.roundToNearest(change, degrees);
    return closestDegree - change;
  }

  /**
   * @param location1 {Point}
   * @param location2 {Point}
   * @param origin {Point}
   */
  _deriveChange(location1, location2, origin) {
    let vector1 = location1.minus(origin);
    let vector2 = location2.minus(origin);
    return vector1.clockwiseAngle(vector2);
  }

  get showSelected() {
    return this.hasFocus || this.hovering || this.dragging;
  }

  get hovering() {
    return (
      this._interactiveDrag.hovering ||
      this._startDrag.hovering ||
      this._endDrag.hovering
    );
  }

  get dragging() {
    return (
      this._interactiveDrag.dragging ||
      this._startDrag.dragging ||
      this._endDrag.dragging
    );
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {StraightLine}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._start = other._start || this._start;
    this._end = other._end || this._end;
    this._color = other._color || this._color;
    this._width = other._width || this._width;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _start: this._start,
      _end: this._end,
      _color: this._color,
      _width: this._width,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {StraightLine}
   */
  fromSnapshot(id, snapshot) {
    return new StraightLine(
      id,
      snapshot._metadata,
      snapshot._start,
      snapshot._end,
      snapshot._color,
      snapshot._width,
    );
  }
}
