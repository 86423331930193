'use strict';

import Codec from './codec';
import Assignment, { AssignmentTags } from '../domain/assignment';
import AssignmentQuestion from '../domain/assignment-question';
import ElementList from '../domain/element-list';
import moment from 'moment';

/**
 * Encodes/Decodes an assignment
 */
export default class AssignmentCodec extends Codec {
  constructor(FirebaseService) {
    super();
    this._firebaseService = FirebaseService;
  }

  /**
   * @param value {Assignment}
   * @returns {{id: (string|value.id|{type, name}), owner_id: (string|string|*), name: (string|string|string|string|string), brief: (*|string|string), access: (*|access|string|Data.access|null), last_modified: (moment|string|jQuery.lastModified|{}|lastModified), questions: Array, rosters: (*|Array.<string>), tags: (*|string|Array|string)}}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        owner_id: value.ownerId,
        name: value.name,
        brief: value.brief,
        access: value.access,
        last_modified: value.lastModified && value.lastModified.toISOString(),
        parent_id: value.parentId,
        parent_modified:
          value.parentModified && value.parentModified.toISOString(),
        questions: value.questions.map((question) => {
          return {
            id: question.id,
            title: question.title,
            element_list_id: question.elementListId,
            element_list_db: question.elementListDb,
            point_value: question.points,
          };
        }),
        rosters: value.rosters,
        tags: this.encodeTags(value),
      };
    }

    return null;
  }

  encodeTags(assignment) {
    let tags = [];

    tags.push({
      name: AssignmentTags.FOLDER,
      data: {
        parent: assignment.folder,
      },
    });

    if (angular.isDefined(assignment.isFolder)) {
      tags.push({
        name: AssignmentTags.FOLDER,
        data: {
          is_folder: assignment.isFolder,
        },
      });
    }

    if (angular.isDefined(assignment.grades)) {
      assignment.grades.forEach((grade, index) => {
        tags.push({
          name: AssignmentTags.GRADES,
          data: {
            [index]: grade,
          },
        });
      });
    }

    if (angular.isDefined(assignment.subjects)) {
      assignment.subjects.forEach((subject, index) => {
        tags.push({
          name: AssignmentTags.SUBJECTS,
          data: {
            [index]: subject,
          },
        });
      });
    }

    if (angular.isDefined(assignment.promotedRosters)) {
      assignment.promotedRosters.forEach((rosterId) => {
        const now = new Date();
        let data = {};
        data[rosterId] = now / 1000;
        tags.push({
          name: AssignmentTags.PROMOTED,
          data: data,
        });
      });
    }

    return tags;
  }

  /**
   * @param value {object}
   * @returns {Assignment|null}
   */
  decode(value) {
    if (value) {
      const folderTagData = this.extractTagData(
        value.tags,
        AssignmentTags.FOLDER,
      );
      const gradesTagData = this.extractTagData(
        value.tags,
        AssignmentTags.GRADES,
      );
      const subjectsTagData = this.extractTagData(
        value.tags,
        AssignmentTags.SUBJECTS,
      );
      const promotedTagData = this.extractTagData(
        value.tags,
        AssignmentTags.PROMOTED,
      );

      return new Assignment(
        value.id,
        value.name,
        value.owner_id,
        value.questions.map((question) => this.decodeQuestion(question)),
        value.brief,
        value.access,
        value.last_modified,
        value.rosters,
        folderTagData && folderTagData.is_folder,
        folderTagData && folderTagData.parent,
        gradesTagData ? this.objectToArray(gradesTagData) : [],
        subjectsTagData ? this.objectToArray(subjectsTagData) : [],
        value.parent_id,
        value.parent_modified,
        value.owner_name ? value.owner_name : undefined,
        promotedTagData ? this.decodePromotedTagData(promotedTagData) : [],
        value.class_code,
      );
    }

    return null;
  }

  /**
   * @param tags {{name: <string>, data: <object>}[]}
   * @param tagName {string} folder
   * @return {*}
   */
  extractTagData(tags, tagName) {
    let tag = tags && tags.find((tag) => tag.name === tagName);
    return tag && tag.data;
  }

  /**
   * @param obj {object}
   * @return {*[]}
   */
  objectToArray(obj) {
    return Object.keys(obj).map((key) => obj[key]);
  }

  /**
   * Parses the "promoted" assignment tag into an array of rosters using the timestamp for when it should be
   * active
   *
   * @param obj
   * @returns {string[]}
   */
  decodePromotedTagData(obj) {
    const timeNow = moment();
    return Object.keys(obj).filter((key) => {
      try {
        // roster promote date from seconds to milliseconds to date object
        const rosterPromotedDate = moment(new Date(obj[key] * 1000));

        // checks if time right now is still within 24 hours of promotion time
        return timeNow.diff(rosterPromotedDate, 'hours') <= 24;
      } catch (err) {
        // Problem converting to roster promotion unix timestamp
      }
      // If there was a problem, ignore this one
      return false;
    });
  }

  /**
   * @param question {id: <string>, element_list_id: {string}, element_list_db: {string}, point_value: <number>}
   * @return {AssignmentQuestion}
   */
  decodeQuestion(question) {
    return new AssignmentQuestion(
      question.id,
      new ElementList(
        question.element_list_id,
        question.element_list_db,
        this._firebaseService,
        'AssignmentQuestionElementList',
      ),
      question.point_value,
      question.title,
    );
  }
}
