'use strict';

import JSEvent from '../../model/util/js-event';
import { Colors } from '../../model/domain/colors';
import ZoomManager, { ZoomConstants } from './zoom-manager';
import {
  Feedback,
  FeedbackVisible,
  FeedbackInvisible,
  FeedbackNotification,
  FeedbackDisabled,
} from './feedback-manager';
import SidenavManager from './sidenav-manager';
import StickerManager from './sticker-manager';
import MultipleChoiceManager from './multiple-choice-manager';
import HelpCenterManager from './help-center-manager';
import ClipboardManager from './clipboard-manager';
import TextboxBase from '../../model/ui/elements/textbox-base';
import FillInTheBlankParent from '../../model/ui/elements/fill-in-the-blank-parent';
import FillInTheBlankChild from '../../model/ui/elements/fill-in-the-blank-child';
import FillInTheBlankManager from './fill-in-the-blank-manager';
import StraightLine from '../../model/ui/elements/straight-line';
import AssignmentWorkQuestion from '../../model/domain/assignment-work-question';
import { SlideBackgroundManager } from './slide-background-manager';
import Textbox from '../../model/ui/elements/textbox';
import SlideForeground from '../../model/ui/elements/slide-foreground';

let penWidths = [1, 2, 3, 4, 5];

let highlighterWidths = [10, 20, 30, 40, 50];

let straightLineWidths = [1, 2, 3, 4, 8, 16, 24];

export class ToolbarModes {
  static get Lines() {
    return 'lines';
  }

  static get Highlighter() {
    return 'highlighter';
  }

  static get Selector() {
    return 'selector';
  }

  static get Touch() {
    return 'touch';
  }

  static get Eraser() {
    return 'eraser';
  }

  static get PlaceTextbox() {
    return 'place_textbox';
  }

  static get PlaceStraightLine() {
    return 'place_straight_line';
  }

  static get PlaceFillInTheBlank() {
    return 'place_fill_in_the_blank';
  }

  static get PlaceSticker() {
    return 'place_sticker';
  }

  /**
   * @param mode {string}
   * @return {boolean}
   */
  static isPlacementMode(mode) {
    return (
      mode === ToolbarModes.PlaceTextbox ||
      mode === ToolbarModes.PlaceSticker ||
      mode === ToolbarModes.PlaceFillInTheBlank ||
      mode === ToolbarModes.PlaceStraightLine
    );
  }
}

let initialState = {
  mode: ToolbarModes.Selector,
  highlighter: {
    color: Colors.penToHighlight(
      Colors.ELEMENT_COLORS.filter((color) => {
        return color.name === 'yellow';
      })[0],
    ),
    width: highlighterWidths[2],
  },
  lines: {
    color: Colors.ELEMENT_COLORS.filter((color) => {
      return color.active;
    })[0],
    width: penWidths[4],
  },
  text: {
    color: TextboxBase.DEFAULT_TEXT_COLOR,
    backgroundColor: TextboxBase.DEFAULT_BACKGROUND_COLOR,
    borderColor: TextboxBase.DEFAULT_BORDER_COLOR,
    fontFamily: TextboxBase.DEFAULT_TEXT_FONT_FAMILY,
    size: 25,
  },
  eraser: {
    color: '#F3F3F3',
    size: 2,
  },
  straightLines: {
    color: Colors.ELEMENT_COLORS.filter((color) => color.active)[0],
    width: straightLineWidths[1],
  },
  stickers: {
    selectedId: '',
  },
};

export class Actions {
  /**
   * @returns {string}
   */
  static get UPDATE_MODE() {
    return 'TOOLBAR.UPDATE_MODE';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_COLOR() {
    return 'TOOLBAR.UPDATE_COLOR';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_PEN_WIDTH() {
    return 'TOOLBAR.UPDATE_PEN_WIDTH';
  }

  /**
   * @return {string}
   */
  static get UPDATE_HIGHLIGHTER_COLOR() {
    return 'TOOLBAR.UPDATE_HIGHLIGHTER_COLOR';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_HIGHLIGHTER_WIDTH() {
    return 'TOOLBAR.UPDATE_HIGHLIGHTER_WIDTH';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_TEXT_SIZE() {
    return 'TOOLBAR.UPDATE_TEXT_SIZE';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_TEXT_COLOR() {
    return 'TOOLBAR.UPDATE_TEXT_COLOR';
  }

  /**
   * @return {string}
   */
  static get UPDATE_TEXT_BACKGROUND_COLOR() {
    return 'TOOLBAR.UPDATE_TEXT_BACKGROUND_COLOR';
  }

  /**
   * @return {string}
   */
  static get UPDATE_TEXTBOX_BORDER_COLOR() {
    return 'TOOLBAR.UPDATE_TEXTBOX_BORDER_COLOR';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_TEXT_FONT_FAMILY() {
    return 'TOOLBAR.UPDATE_TEXT_FONT_FAMILY';
  }

  /**
   * @return {string}
   */
  static get UPDATE_STRAIGHT_LINE_COLOR() {
    return 'TOOLBAR.UPDATE_STRAIGHT_LINE_COLOR';
  }

  /**
   * @return {string}
   */
  static get UPDATE_STRAIGHT_LINE_WIDTH() {
    return 'TOOLBAR.UPDATE_STRAIGHT_LINE_WIDTH';
  }

  /**
   * @return {string}
   */
  static get UPDATE_SELECTED_STICKER() {
    return 'TOOLBAR.UPDATE_SELECTED_STICKER';
  }
}

export class Notifications {
  /**
   * @returns {string}
   */
  static get DELETE() {
    return 'delete';
  }

  /**
   * @returns {string}
   */
  static get MOUSE_ENTER_DELETE() {
    return 'mouse-enter-delete';
  }

  /**
   * @returns {string}
   */
  static get MOUSE_LEAVE_DELETE() {
    return 'mouse-leave-delete';
  }

  /**
   * @returns {string}
   */
  static get CREATE_TEXTBOX() {
    return 'create-textbox';
  }

  /**
   * @returns {string}
   */
  static get CREATE_IMAGE_FROM_FILE() {
    return 'create-image-from-file';
  }

  /**
   * @returns {string}
   */
  static get CREATE_IMAGE_FROM_CAMERA() {
    return 'create-image-from-camera';
  }

  /**
   * @return {string}
   */
  static get CREATE_EMOJI() {
    return 'create-emoji';
  }

  /**
   * @returns {string}
   */
  static get CREATE_AUDIO() {
    return 'create-audio';
  }

  /**
   * @returns {string}
   */
  static get CREATE_AI_ASSISTANT() {
    return 'create-ai-assistant';
  }

  /**
   * @return {string}
   */
  static get CREATE_MANIPULATIVE_IMAGE() {
    return 'create-manipulative-image';
  }

  /**
   * @return {string}
   */
  static get CREATE_FILL_IN_THE_BLANK() {
    return 'create-fill-in-the-blank';
  }

  /**
   * @returns {string}
   */
  static get CREATE_LINK() {
    return 'create-link';
  }

  /**
   * @returns {string}
   */
  static get CREATE_MULTIPLE_CHOICE() {
    return 'create-multiple-choice';
  }

  /**
   * @returns {string}
   */
  static get HELP_REQUEST() {
    return 'help';
  }

  /**
   * @returns {string}
   */
  static get CHECK_REQUEST() {
    return 'check';
  }

  /**
   * @returns {string}
   */
  static get CHAT_REQUEST() {
    return 'chat';
  }

  /**
   * @returns {string}
   */
  static get CANCEL_REQUEST() {
    return 'cancel';
  }

  /**
   * @returns {string}
   */
  static get RESOLVE_REQUEST() {
    return 'resolve';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_POINT_VALUE() {
    return 'update_point_value';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_SCORED_POINTS() {
    return 'update_scored_points';
  }

  /**
   * @returns {string}
   */
  static get HIDE_FEEDBACK() {
    return 'hide_feedback';
  }

  /**
   * @returns {string}
   */
  static get HIGHLIGHT_CONTRIBUTOR() {
    return 'highlight_contributor';
  }

  /**
   * @returns {string}
   */
  static get SHOW_ALL_CONTRIBUTORS() {
    return 'show_all_contributors';
  }

  /**
   * @returns {string}
   */
  static get HIDE_ALL_CONTRIBUTORS() {
    return 'hide_all_contributors';
  }

  /**
   * @returns {string}
   */
  static get SHOW_FEEDBACK() {
    return 'show_feedback';
  }

  /**
   * @returns {string}
   */
  static get UPDATE_TEXTBOX_SIZE() {
    return 'update_textbox_size';
  }

  /**
   * @return {string}
   */
  static get UPDATE_TEXTBOX_COLOR() {
    return 'update_textbox_color';
  }

  /**
   * @return {string}
   */
  static get UPDATE_TEXTBOX_BACKGROUND_COLOR() {
    return 'update_textbox_background_color';
  }

  /**
   * @return {string}
   */
  static get UPDATE_TEXTBOX_BORDER_COLOR() {
    return 'update_textbox_border_color';
  }

  /**
   * @return {string}
   */
  static get UPDATE_TEXT_FONT_FAMILY() {
    return 'update_text_font_family';
  }

  /**
   * @returns {string}
   */
  static get UNDO() {
    return 'undo_element';
  }

  /**
   * @returns {string}
   */
  static get REDO() {
    return 'redo_element';
  }

  /**
   * @returns {string}
   */
  static get TEXT_TO_SPEECH() {
    return 'text_to_speech';
  }

  /**
   * @returns {string}
   */
  static get COVER_SLIDE() {
    return 'cover_slide';
  }

  /**
   * @return {string}
   */
  static get ADD_STICKER() {
    return 'add_sticker';
  }

  /**
   * @return {string}
   */
  static get CLONE_ELEMENT() {
    return 'clone_element';
  }

  /**
   * @return {string}
   */
  static get CREATE_STRAIGHT_LINE() {
    return 'create_straight_line';
  }

  /**
   * @return {string}
   */
  static get UPDATE_STRAIGHT_LINE_COLOR() {
    return 'update_straight_line_color';
  }

  /**
   * @return {string}
   */
  static get UPDATE_STRAIGHT_LINE_WIDTH() {
    return 'update_straight_line_width';
  }

  /**
   * @return {string}
   */
  static get CLEAR_PLACEMENT() {
    return 'clear_placement';
  }

  /**
   * @return {string}
   */
  static get UPDATE_SLIDE_BACKGROUND() {
    return 'update_slide_background';
  }

  /**
   * @return {string}
   */
  static get CLEAR_ALL() {
    return 'clear_all';
  }
}

/*
  This is a simplified implementation of the Redux (Flux) Architecture

  Principles of Redux:
    -Single source of truth (we have one javascript object that represents the toolbar UI)
    -State is read-only (we don't directly manipulate the state)
    -Changes are made with pure functions (when changing state, we recreate new object)

  Check following for more info:
    -https://egghead.io/lessons/javascript-redux-implementing-store-from-scratch
    -http://redux.js.org/index.html
*/

export default class ToolbarService {
  constructor(
    $q,
    $rootScope,
    $timeout,
    $window,
    $document,
    $mdToast,
    $log,
    $mdDialog,
    hotkeys,
    AssignmentTrackingService,
    CacheService,
    HelpRequestService,
    AuthService,
    FocusManagerService,
    FirebaseService,
    StorageService,
    AssignmentWorkService,
    ImageEditService,
    MediaService,
    SlideBackgroundService,
    FeedbackService,
    AnalyticsService,
    AnalyticsMetaService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$document = $document;
    this.$mdToast = $mdToast;
    this.$log = $log;
    this.$mdDialog = $mdDialog;
    this._hotkeys = hotkeys;

    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;
    /** @type {MediaService} */
    this._mediaService = MediaService;
    /** @type {SlideBackgroundService} */
    this._slideBackgroundService = SlideBackgroundService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AnalyticsMetaService} */
    this._analyticsMetaService = AnalyticsMetaService;
    /** @type {FocusManagerService} */
    this._focusManager = FocusManagerService;

    /** @type {JSEvent} */
    this._stateUpdated = new JSEvent(this);
    /** @type {JSEvent} */
    this._notification = new JSEvent(this);

    //TODO : Incorporate this stuff into _state proper?
    /** @type {JSEvent} */
    this._extendedStateUpdated = new JSEvent(this);

    /** @type {StickerManager} */
    this._stickerManager = new StickerManager(
      this.$q,
      this.$log,
      this._cacheService,
      this._imageEditService,
      this._mediaService,
      this._authService,
    );
    /** @type {FillInTheBlankManager} */
    this._fillInTheBlankManager = new FillInTheBlankManager(
      this._assignmentTrackingService,
      this._cacheService,
      this.$rootScope,
    );
    /** @type {SidenavManager} */
    this._sidenavManager = new SidenavManager(
      this._stickerManager,
      this._fillInTheBlankManager,
    );
    /** @type {ZoomManager} */
    this._zoomManager = new ZoomManager(this._sidenavManager.updated, $timeout);
    /** @type {Feedback} */
    this._feedback = new Feedback($timeout, this);
    /** @type {MultipleChoiceManager} */
    this._multipleChoiceManager = new MultipleChoiceManager(this._cacheService);
    /** @type {HelpCenterManager} */
    this._helpCenterManager = new HelpCenterManager(
      this.$q,
      this.$timeout,
      this,
      this._helpRequestService,
      this._authService,
      this._firebaseService,
      this._cacheService,
      this._storageService,
      this._feedbackService,
      this._analyticsService,
      this._analyticsMetaService,
    );
    /** @type {ClipboardManager} */
    this._clipboardManager = new ClipboardManager(
      this.$log,
      this.$window,
      this.$document,
      this._hotkeys,
      this,
      this._imageEditService,
      this._focusManager,
    );
    /** @type {SlideBackgroundManager} */
    this._slideBackgroundManager = new SlideBackgroundManager(
      this.$mdDialog,
      this.$q,
      this.$document,
      this._slideBackgroundService,
      this,
      this._mediaService,
      this._authService,
      this._imageEditService,
    );

    this._aiAssistantEnabled = undefined;

    this._state = initialState;
    this._penWidths = penWidths;
    this._highlighterWidths = highlighterWidths;
    this._straightLineWidths = straightLineWidths;
    this._focusedElement = undefined;

    this._assignmentTrackingService.undoStackUpdated.subscribe((ev) =>
      this.extendedStateUpdated.raise(ev),
    );
    this._sidenavManager.updated.subscribe((ev) =>
      this.extendedStateUpdated.raise(ev),
    );
    this._authService.dataCleared.subscribe(() => this.reset());
    this._focusManager.updated.subscribe((ev) => this.updateFocusedElement(ev));
  }

  updateFocusedElement(ev) {
    this.focusedElement = ev;
    if (ev && (ev.constructor.name === 'Textbox' || ev.type === 'textbox')) {
      this._clipboardManager.currentSelectedTextbox = ev;
    }
  }

  enableAiAssistant() {
    if (this._aiAssistantEnabled === undefined) {
      this._aiAssistantEnabled = true;
    }
  }

  disableAiAssistant() {
    this._aiAssistantEnabled = false;
  }

  get isAiAssistantDisabled() {
    return !this._aiAssistantEnabled;
  }

  get state() {
    return this._state;
  }

  /**
   * TODO: Rename to penWidths
   * @returns {{width: number}[]}
   */
  get penWidths() {
    return this._penWidths;
  }

  get highlighterWidths() {
    return this._highlighterWidths;
  }

  get straightLineWidths() {
    return this._straightLineWidths;
  }

  /**
   * @returns {JSEvent}
   */
  get stateUpdated() {
    return this._stateUpdated;
  }

  /**
   * @returns {JSEvent}
   */
  get notification() {
    return this._notification;
  }

  /**
   * @returns {JSEvent}
   */
  get extendedStateUpdated() {
    return this._extendedStateUpdated;
  }

  /**
   * @param state {Object}
   * @param action {Object}
   * @returns {Object}
   */
  reducer(state, action) {
    switch (action.type) {
      case Actions.UPDATE_MODE:
        return {
          ...state,
          mode: action.mode,
        };
      case Actions.UPDATE_COLOR:
        return {
          ...state,
          lines: {
            ...state.lines,
            color: { ...action.color },
          },
        };
      case Actions.UPDATE_PEN_WIDTH:
        return {
          ...state,
          lines: {
            ...state.lines,
            width: action.newWidth,
          },
        };
      case Actions.UPDATE_HIGHLIGHTER_COLOR:
        return {
          ...state,
          highlighter: {
            ...state.highlighter,
            color: { ...action.color },
          },
        };
      case Actions.UPDATE_HIGHLIGHTER_WIDTH:
        return {
          ...state,
          highlighter: {
            ...state.highlighter,
            width: action.newWidth,
          },
        };
      case Actions.UPDATE_TEXT_SIZE:
        return {
          ...state,
          text: {
            ...state.text,
            size: action.newSize,
          },
        };
      case Actions.UPDATE_TEXT_COLOR:
        return {
          ...state,
          text: {
            ...state.text,
            color: action.newColor,
          },
        };
      case Actions.UPDATE_TEXT_BACKGROUND_COLOR:
        return {
          ...state,
          text: {
            ...state.text,
            backgroundColor: action.newBackgroundColor,
          },
        };
      case Actions.UPDATE_TEXTBOX_BORDER_COLOR:
        return {
          ...state,
          text: {
            ...state.text,
            borderColor: action.newBorderColor,
          },
        };
      case Actions.UPDATE_TEXT_FONT_FAMILY:
        return {
          ...state,
          text: {
            ...state.text,
            fontFamily: action.newFontFamily,
          },
        };
      case Actions.UPDATE_STRAIGHT_LINE_COLOR:
        return {
          ...state,
          straightLines: {
            ...state.straightLines,
            color: action.newColor,
          },
        };
      case Actions.UPDATE_STRAIGHT_LINE_WIDTH:
        return {
          ...state,
          straightLines: {
            ...state.straightLines,
            width: action.newWidth,
          },
        };
      case Actions.UPDATE_SELECTED_STICKER:
        return {
          ...state,
          stickers: {
            selectedId: action.selectedId,
          },
        };
      case Actions.TOGGLE_TEXT_TO_SPEECH:
        return {
          ...state,
          textToSpeech: {
            isActive: action.isActive,
          },
        };
      default:
        return state;
    }
  }

  dispatch(action) {
    let oldState = this.state;
    this._state = this.reducer(this.state, action);

    this._stateUpdated.raise({
      newState: this.state,
      oldState: oldState,
    });
    this.extendedStateUpdated.raise();
  }

  updateMode(mode) {
    this.dispatch({
      type: Actions.UPDATE_MODE,
      mode: mode || undefined,
    });
  }

  /**
   * @param type {string}
   * @param [data] {object}
   * @param [setToSelectorMode] {boolean}
   */
  notify(type, data = {}, setToSelectorMode = true) {
    if (setToSelectorMode) {
      this.updateMode(ToolbarModes.Selector);
    }

    this._notification.raise({
      type: type,
      data: data,
    });
  }

  /**
   * @param emoji {string} the emoji value
   */
  createEmoji(emoji) {
    this.notify(Notifications.CREATE_EMOJI, {
      ...emoji,
      needsFocusedElement: true,
    });
  }

  /**
   * @param fontFamily {string} the font value
   */
  updateFontFamily(font_selected) {
    this.dispatch({
      type: Actions.UPDATE_TEXT_FONT_FAMILY,
      newFontFamily: font_selected,
    });
    this.notify(Notifications.UPDATE_TEXT_FONT_FAMILY, {
      ...font_selected,
      needsFocusedElement: true,
    });
  }

  /**
   * @param stickerId {string} the user sticker id
   */
  updateSelectedUserSticker(stickerId) {
    this.dispatch({
      type: Actions.UPDATE_SELECTED_STICKER,
      selectedId: stickerId,
    });
  }

  updateTextColor(color) {
    this.dispatch({ type: Actions.UPDATE_TEXT_COLOR, newColor: color });
    this.notify(Notifications.UPDATE_TEXTBOX_COLOR, {
      ...color,
      needsFocusedElement: true,
    });
  }

  updateTextboxBackgroundColor(color) {
    this.dispatch({
      type: Actions.UPDATE_TEXT_BACKGROUND_COLOR,
      newBackgroundColor: color,
    });
    this.notify(Notifications.UPDATE_TEXTBOX_BACKGROUND_COLOR, {
      ...color,
      needsFocusedElement: true,
    });
  }

  updateTextboxBorderColor(color) {
    this.dispatch({
      type: Actions.UPDATE_TEXTBOX_BORDER_COLOR,
      newBorderColor: color,
    });
    this.notify(Notifications.UPDATE_TEXTBOX_BORDER_COLOR, {
      ...color,
      needsFocusedElement: true,
    });
  }

  updateTextSize(increment) {
    const newSize = Math.min(
      TextboxBase.FONT_SIZE_MAX,
      Math.max(
        TextboxBase.FONT_SIZE_MIN,
        this.state.text.size +
          TextboxBase.FONT_INCREMENT * (increment ? 1 : -1),
      ),
    );
    this.dispatch({ type: Actions.UPDATE_TEXT_SIZE, newSize: newSize });
    this.notify(Notifications.UPDATE_TEXTBOX_SIZE, {
      fontSize: newSize,
      needsFocusedElement: true,
    });
  }

  updateStraightLineColor(color) {
    this.dispatch({
      type: Actions.UPDATE_STRAIGHT_LINE_COLOR,
      newColor: color,
    });
    this.notify(Notifications.UPDATE_STRAIGHT_LINE_COLOR, {
      ...color,
      needsFocusedElement: true,
    });
  }

  updateStraightLineWidth(width) {
    this.dispatch({
      type: Actions.UPDATE_STRAIGHT_LINE_WIDTH,
      newWidth: width,
    });
    this.notify(Notifications.UPDATE_STRAIGHT_LINE_WIDTH, {
      width,
      needsFocusedElement: true,
    });
  }

  updateColor(color) {
    this.dispatch({ type: Actions.UPDATE_COLOR, color: color });
  }

  updatePenWidth(width) {
    this.dispatch({ type: Actions.UPDATE_PEN_WIDTH, newWidth: width });
  }

  updateHighlighterColor(color) {
    this.dispatch({ type: Actions.UPDATE_HIGHLIGHTER_COLOR, color: color });
  }

  updateHighlighterWidth(highlighterWidth) {
    this.dispatch({
      type: Actions.UPDATE_HIGHLIGHTER_WIDTH,
      newWidth: highlighterWidth,
    });
  }

  /** Zoom Methods **/

  /**
   * @return {ZoomManager}
   */
  get zoomManager() {
    return this._zoomManager;
  }

  zoomIn() {
    this._zoomManager.increment();
    this.extendedStateUpdated.raise();
  }

  zoomOut() {
    this._zoomManager.decrement();
    this.extendedStateUpdated.raise();
  }

  get zoomFactor() {
    return this.zoomManager && this._zoomManager.factor;
  }

  set zoomFactor(value) {
    this._zoomManager.setFactor(value);
    this.extendedStateUpdated.raise();
  }

  /**
   * @return {boolean}
   */
  get canZoomOut() {
    return this.zoomFactor > ZoomConstants.LOWER_LIMIT;
  }

  /**
   * @return {boolean}
   */
  get canZoomIn() {
    return this.zoomFactor < ZoomConstants.UPPER_LIMIT;
  }

  /**
   * @return {string}
   */
  get zoomPercent() {
    return this._zoomManager.percentage;
  }

  /** Assignment Methods **/

  /**
   * @param index {number}
   */
  set targetIndex(index) {
    this._targetIndex = index;
    this.extendedStateUpdated.raise();
  }

  /**
   * @returns {number}
   */
  get targetIndex() {
    return this._targetIndex;
  }

  get hasTargetIndex() {
    return this._targetIndex !== null;
  }

  clearTarget() {
    this._targetIndex = null;
  }

  /**
   * @returns {boolean}
   */
  get hasTarget() {
    return (
      this._assignmentTrackingService.target &&
      angular.isNumber(this._targetIndex)
    );
  }

  /**
   *
   * @returns {Assignment|AssignmentWork}
   */
  get target() {
    return this._assignmentTrackingService.target;
  }

  /**
   * @return {AssignmentQuestion|null}
   */
  get contentQuestion() {
    if (!this.hasTarget || !this.hasTargetIndex) {
      return null;
    }

    if (this.target.isWork) {
      return this.target.assignment.questions[this.targetIndex];
    } else {
      return this.target.questions[this.targetIndex];
    }
  }

  /**
   * @return {AssignmentWorkQuestion|null}
   */
  get workQuestion() {
    if (this.hasTarget && this.target.isWork && this.hasTargetIndex) {
      return this.target.questionForIndex(this.targetIndex);
    } else {
      return null;
    }
  }

  /**
   * @returns {boolean}
   */
  get canUndo() {
    return (
      this.hasTarget &&
      this._assignmentTrackingService.canUndo(
        this.targetIndex,
        this.target.isWork,
      )
    );
  }

  /**
   * @returns {boolean}
   */
  get canRedo() {
    return (
      this.hasTarget &&
      this._assignmentTrackingService.canRedo(
        this.targetIndex,
        this.target.isWork,
      )
    );
  }

  undo() {
    if (this.hasTarget) {
      let change = this._assignmentTrackingService.undo(
        this.targetIndex,
        this.target.isWork,
      );
      this.notify(
        Notifications.UNDO,
        { needsFocusedElement: true, change },
        false,
      );
    }
  }

  redo() {
    if (this.hasTarget) {
      let change = this._assignmentTrackingService.redo(
        this.targetIndex,
        this.target.isWork,
      );
      this.notify(
        Notifications.REDO,
        { needsFocusedElement: true, change },
        false,
      );
    }
  }

  toggleTextToSpeech() {
    if (this.hasTarget) {
      this.notify(
        Notifications.TEXT_TO_SPEECH,
        { needsFocusedElement: false },
        false,
      );
    }
  }

  toggleCoverSlide() {
    let coverSlide = null;
    if (this.hasTarget) {
      const elementMap = this._assignmentTrackingService.target.questions.get(
        this.contentQuestion.id,
      ).elements._objectMap;
      if (elementMap) {
        for (let element of elementMap) {
          if (element[1].type === SlideForeground.type) {
            coverSlide = element[1];
          }
        }
      }
      this.notify(
        Notifications.COVER_SLIDE,
        { needsFocusedElement: false, coverSlide },
        false,
      );
    }
  }

  set focusedElement(value) {
    if (
      this.focusedElementIsTextbox ||
      this.focusedElementIsFillInTheBlankChild
    ) {
      this.focusedElement.activeStateChanged.unsubscribe(
        this._handleActiveStateChanged,
        this,
      );
    }

    this._focusedElement = value;

    if (
      this.focusedElementIsTextbox ||
      this.focusedElementIsFillInTheBlankChild
    ) {
      this.focusedElement.activeStateChanged.subscribe(
        this._handleActiveStateChanged,
        this,
      );
    }

    if (
      this.focusedElementIsTextbox ||
      this.focusedElementIsFillInTheBlankChild
    ) {
      this.dispatch({
        type: Actions.UPDATE_TEXT_SIZE,
        newSize: this.focusedElement.fontSize,
      });
    }

    if (this.focusedElementIsStraightLine) {
      this.dispatch({
        type: Actions.UPDATE_STRAIGHT_LINE_COLOR,
        newColor: {
          hex: this.focusedElement.color,
        },
      });
      this.dispatch({
        type: Actions.UPDATE_STRAIGHT_LINE_WIDTH,
        newWidth: this.focusedElement.width,
      });
    }

    this.extendedStateUpdated.raise();
  }

  get focusedElement() {
    return this._focusedElement;
  }

  get focusedElementIsTextbox() {
    return this.focusedElement && this.focusedElement.type === Textbox.type;
  }

  get focusedElementIsStraightLine() {
    return (
      this.focusedElement && this.focusedElement.type === StraightLine.type
    );
  }

  get focusedElementIsFillInTheBlankParent() {
    return (
      this.focusedElement &&
      this.focusedElement.type === FillInTheBlankParent.type
    );
  }

  get focusedElementIsFillInTheBlankChild() {
    return (
      this.focusedElement &&
      this.focusedElement.type === FillInTheBlankChild.type
    );
  }

  _handleActiveStateChanged(ev) {
    if (
      this.focusedElementIsTextbox ||
      this.focusedElementIsFillInTheBlankChild
    ) {
      this.dispatch({
        type: Actions.UPDATE_TEXT_COLOR,
        newColor: ev.newColor,
      });
    }

    if (this.focusedElementIsTextbox) {
      this.dispatch({
        type: Actions.UPDATE_TEXT_BACKGROUND_COLOR,
        newBackgroundColor: ev.newBackgroundColor,
      });
      this.dispatch({
        type: Actions.UPDATE_TEXTBOX_BORDER_COLOR,
        newBorderColor: ev.newBorderColor,
      });
      this.dispatch({
        type: Actions.UPDATE_TEXT_FONT_FAMILY,
        newFontFamily: ev.newFontFamily,
      });
    }
  }

  get activeColor() {
    return this.state.text.color;
  }

  /** Copy/Paste Elements **/

  copyPasteElement() {
    this._clipboardManager.handleCommandCopyPaste();
  }

  /** Showing/Hiding Feedback **/

  /**
   * @returns {Feedback}
   */
  get feedback() {
    return this._feedback;
  }

  hideFeedback() {
    this.notify(
      Notifications.HIDE_FEEDBACK,
      { needsFocusedElement: false },
      false,
    );
  }

  showFeedback() {
    this.notify(
      Notifications.SHOW_FEEDBACK,
      { needsFocusedElement: false },
      false,
    );
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackVisible() {
    return this.feedback.state instanceof FeedbackVisible;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackInvisible() {
    return this.feedback.state instanceof FeedbackInvisible;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackDisabled() {
    return this.feedback.state instanceof FeedbackDisabled;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackNotification() {
    return this.feedback.state instanceof FeedbackNotification;
  }

  /** Sidenav **/

  /**
   * @return {SidenavManager}
   */
  get sidenavManager() {
    return this._sidenavManager;
  }

  /** Stickers **/

  /**
   * @return {StickerManager}
   */
  get stickerManager() {
    return this._stickerManager;
  }

  /**
   * @return {UserSticker}
   */
  get selectedSticker() {
    return this._stickerManager.selected;
  }

  saveSticker() {
    this.sidenavManager.startLoading();
    return this._stickerManager
      .saveSelected()
      .catch(() => {
        this.sidenavManager.setError('There was a problem saving your sticker');
      })
      .finally(() => {
        this.sidenavManager.closeState();
        this.sidenavManager.finishLoading();
      });
  }

  deleteSelected() {
    this.sidenavManager.startLoading();
    return this._stickerManager
      .deleteSelected()
      .catch(() => {
        this.sidenavManager.setError(
          'There was a problem deleting your sticker',
        );
      })
      .finally(() => {
        this.sidenavManager.closeState();
        this.sidenavManager.finishLoading();
      });
  }

  /**
   * Adds the score from a placed sticker to the current score for a question
   * @param value {number}
   */
  addStickerScore(value) {
    let workQuestion = this.workQuestion;
    let newScore = workQuestion.addScore(value);
    if (newScore !== workQuestion.points) {
      this.notify(
        Notifications.UPDATE_SCORED_POINTS,
        { needsFocusedElement: false, newScore },
        false,
      );
    }
  }

  /** Multiple Choice **/

  /**
   * @return {MultipleChoiceManager}
   */
  get multipleChoiceManager() {
    return this._multipleChoiceManager;
  }

  /** Help Center **/

  /**
   * @returns {HelpCenterManager}
   */
  get helpCenter() {
    return this._helpCenterManager;
  }

  /**
   * @return {ClipboardManager}
   */
  get clipboardManager() {
    return this._clipboardManager;
  }

  /**
   * @return {SlideBackgroundManager}
   */
  get slideBackgroundManager() {
    return this._slideBackgroundManager;
  }

  /**
   * @return {FillInTheBlankManager}
   */
  get fillInTheBlankManager() {
    return this._fillInTheBlankManager;
  }

  clearAll() {
    this.notify(Notifications.CLEAR_ALL, {}, false);
  }

  /**
   * Raises an event to tell assignment sheet to clear any active placements on the canvas
   */
  clearPlacement() {
    this.updateSelectedUserSticker('');
    this.notify(Notifications.CLEAR_PLACEMENT, {}, false);
  }

  /**
   * @param message {string}
   */
  toast(message) {
    let config = this.$mdToast
      .simple()
      .textContent(message)
      .position('bottom right');
    this.$mdToast.show(config);
  }

  reset() {
    this._state = initialState;
    this.feedback.reset();
    this.stickerManager.reset();
    this.sidenavManager.reset();
    this.helpCenter.reset();
  }
}
