'use strict';

/**
 * The DomObservabilityService uses current browsers' implementation of the MutationObserver and Angularjs location
 * change event hooks to generate DOM rendering timings as users navigate.
 *
 * For development, the Performance marks help identify when these events are triggered
 * without having to view the a third party (i.e., DataDog) in a browser's Performance tab inspector tool.
 *
 * Ref: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 * Ref: https://developer.mozilla.org/en-US/docs/Web/API/Navigation_timing_API
 *
 */
export default class DomObservabilityService {
  constructor(
    $document,
    $stateParams,
    $window,
    $timeout,
    $rootScope,
    $log,
    environment,
    DatadogRumService,
  ) {
    'ngInject';

    this.$log = $log;
    this.$document = $document;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this._environment = environment;
    this.$stateParams = $stateParams;

    if (
      !this._environment.observability.enabled ||
      this._environment.observability.enabled === 'false'
    ) {
      this.logTrace('disabled');
      return;
    }

    if (angular.isUndefined(this.$window.MutationObserver)) {
      this.logTrace('MutationObserver API unavailable');
      return;
    }

    // The timeout value used to determine the first DOM rendering on location change
    this._domWaitTimer = undefined;

    // The number of milliseconds to wait for DOM changes to have stopped on the location change
    this._millisWaitTimeout = 500;

    // The realtime user monitoring, aka RUM, service send observability measurements
    this._rumService = DatadogRumService;

    // Select the node that will be observed for mutations
    // i.e., const targetNode = document.getElementById('some-id');
    this._targetNode = this.$document[0].body;

    // Options for the observer (which mutations to observe)
    this._config = { attributes: false, childList: true, subtree: true };

    // Create an observer instance linked to the callback function
    this._observer = new MutationObserver(this.measureDomMutation.bind(this));

    // initializes the observer to begin watching for DOM mutations
    this.restartObserver();

    // Start watching the angularjs rootScope for location changes
    this.$rootScope.$on(
      '$locationChangeSuccess',
      this.locationChangeSuccess.bind(this),
    );
    this.$rootScope.$on(
      '$locationChangeStart',
      this.locationChangeStart.bind(this),
    );
  }

  /**
   * Callback for angularjs $locationChangeSuccess event
   * Timing: 'dom_render_loocation_change_success' measurement marking when a location is "loaded" for the user to see.
   *
   * @param evt      event triggered
   * @param next     url that was to be loaded
   * @param previous url that triggered event
   */
  locationChangeSuccess(evt, next, previous) {
    if (next !== previous) {
      this.logTrace('location change success', next, previous);
      this._rumService.addUserAndClassInfo(
        this.$stateParams.assignmentId,
        this.$stateParams.rosterId,
      );
      this.addTiming('dom_render_location_change_success');
    }
  }

  /**
   * Callback for angularjs $locationChangeStart event
   * Timing: 'dom_render_loocation_change_start' measurement marking when user is navigating away from location.
   *
   * @param evt      event triggered
   * @param next     url that was to be loaded
   * @param previous url that triggered event
   */
  locationChangeStart(evt, next, previous) {
    if (next !== previous) {
      this.logTrace('location change start', next, previous);
      this.restartObserver();
      this.addTiming('dom_render_location_change_start');
    }
  }

  /**
   * MutationObserver callback function to execute when mutations are observed.
   * Timing: 'dom_rendering' measurement when the MutationObserver starts seeing the DOM changing
   */
  measureDomMutation() {
    this.logTrace('measuring dom render');

    if (angular.isDefined(this._domWaitTimer)) {
      // mutation is still observed, reset the timeout timer
      this.$timeout.cancel(this._domWaitTimer);
    }

    this.addTiming('dom_rendering');
    this._domWaitTimer = this.$timeout(
      this.approximateFirstDomMutation.bind(this),
      this._millisWaitTimeout,
    );
  }

  /**
   * Callback from measureDomMutation that disconnects the observer after _millisWaitTimeout. This method helps identify
   * when the page has approximately changed into the DOM and ready for interaction by the user.
   *
   * Timing: 'dom_approximately_rendered' - an approximation because there could be large assets like images that are
   * taking a lot longer to update in the DOM but this measurement can be seen in maybe the resources tab of a
   * third-party tool.
   */
  approximateFirstDomMutation() {
    if (angular.isFunction(this.$window.performance.measure)) {
      this.$window.performance.measure('route_change', 'route_change_mark');
    }
    const now = Date.now();
    const timeadjust = now - this._millisWaitTimeout * 1000;
    this.addTiming('dom_approximately_rendered', timeadjust);
    this._observer.disconnect();
    this.logTrace('approximate last dom render measurement');
  }

  /**
   * Restart observing the target node for DOM mutations (adds/inserts)
   */
  restartObserver() {
    if (angular.isFunction(this.$window.performance.mark)) {
      this.$window.performance.mark('route_change_mark');
    }

    if (angular.isDefined(this._domWaitTimer)) {
      this.$timeout.cancel(this._domWaitTimer);
      this._domWaitTimer = undefined;
    }

    this.logTrace('start the mutation observer');
    this._observer.observe(this._targetNode, this._config);
  }

  /**
   * Sending measurement time and any other arguments along to the RUM service
   *
   * @param args name of the measurement but can pass along other arguments to the service receiving the measurement.
   */
  addTiming(...args) {
    this._rumService.addTiming(...args);
  }

  logTrace(...args) {
    if (this._environment.observability.traceLogging === 'true') {
      this.$log.debug('Observability:', ...args);
    }
  }
}
