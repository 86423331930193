export default class RosterStudent {
  /**
   * @param id { string }
   * @param name {string }
   * @param login {string }
   * @param lmsId { string | undefined }
   *
   */
  constructor(id, name, login, lmsId) {
    this._id = id;
    this._name = name;
    this._login = login;
    this._lmsId = lmsId;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get login() {
    return this._login;
  }

  get lmsId() {
    return this._lmsId;
  }
}
