'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import JsonCodec from './json-codec';
import ManipulativeImageChild from '../ui/elements/manipulative-image-child';

/**
 * Encodes/Decodes a manipulative image child element
 */
export default class ManipulativeImageChildCodec extends Codec {
  constructor() {
    super();
    this._jsonCodec = new JsonCodec();
  }

  /**
   * @param value {ManipulativeImageChild}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, () => {
      let transform = this._jsonCodec.encode({
        scale: value.parentScale,
        rotation: value.parentRotation,
      });

      let childTransform = this._jsonCodec.encode({
        scale: value.scale,
        rotation: value.rotation,
      });

      return {
        type: value.type,
        image_url: value.url,
        size: ElementCodec.sizeCodec.encode(value.imageSize),
        center: ElementCodec.pointCodec.encode(value.parentCenter),
        transform: transform,
        child_center: ElementCodec.pointCodec.encode(value.location),
        child_transform: childTransform,
      };
    });
  }

  /**
   * @param value {object}
   * @param id {string}
   * @returns {ManipulativeImageChild}
   */
  decode(value, id) {
    return ElementCodec.preDecode(value, (metadata, size, location, center) => {
      let transform = this._jsonCodec.decode(value.data.transform);

      let childCenter = value.data.child_center
        ? ElementCodec.pointCodec.decode(value.data.child_center)
        : undefined;
      let childScale;
      let childRotation;

      if (value.data.child_transform) {
        let childTransform = this._jsonCodec.decode(value.data.child_transform);
        childScale = childTransform.scale;
        childRotation = childTransform.rotation;
      }

      return new ManipulativeImageChild(
        id,
        metadata,
        center,
        size,
        transform.scale,
        transform.rotation,
        value.data.image_url,
        childCenter,
        childScale,
        childRotation,
      );
    });
  }
}
