export function themeConfig($mdThemingProvider) {
  'ngInject';

  $mdThemingProvider.definePalette('classkickPrimary', {
    50: '#ffffff',
    100: '#D3F3E6',
    200: '#25C582',
    300: '#25C582',
    400: '#25C582',
    500: '#25C582',
    600: '#25C582',
    700: '#25C582',
    800: '#25C582',
    900: '#25C582',
    A100: '#ffffff',
    A200: '#eff9e3',
    A400: '#25C582',
    A700: '#25C582',
    contrastDefaultColor: 'light',
    contrastDarkColors: '50 100 200 300 400 A100 A200 A400 A700',
    contrastLightColors: undefined,
  });

  $mdThemingProvider.definePalette('classkickWarn', {
    50: '#ffffff',
    100: '#ffffff',
    200: '#fff1f1',
    300: '#ffa9a9',
    400: '#ff8b8b',
    500: '#ff6c6c',
    600: '#ff4d4d',
    700: '#ff2f2f',
    800: '#ff1010',
    900: '#f10000',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ff8b8b',
    A700: '#ff2f2f',
    contrastDefaultColor: 'light',
    contrastDarkColors: '50 100 200 300 400 500 600 A100 A200 A400',
  });

  $mdThemingProvider
    .theme('default')
    .primaryPalette('classkickPrimary')
    .warnPalette('classkickWarn');

  $mdThemingProvider.theme('alert');
}
