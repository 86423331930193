/**
 * One section within the toolbar
 */

import LazyVar from '../../model/util/lazy-var';
import { Divider, IconButton, Select, TextButton } from './toolbar-item';

export class Section {
  /**
   * @param items {ToolbarItem[]}
   * @param priority {int} Low priority collapses first. -1 to never collapse.
   * @param [leftDivider] {boolean} default true
   * @param [forceExpandedWidth] {number} Static value to use for displayWidth when not collapsed
   */
  constructor(items, priority, leftDivider = true, forceExpandedWidth = 0) {
    this._items = items;
    /** @type {ToolbarItem[]} */
    this._displayItems = [];
    /** @type {ToolbarItem[]} */
    this._collapsedItems = [];
    /** @type {boolean} */

    /** @type {LazyVar.<int>} */
    this._displayWidth = new LazyVar();
    this._collapsed = false;
    this._priority = priority;
    this._leftDivider = leftDivider;
    this._forceExpandedWidth = forceExpandedWidth;
    this._visibility = true;

    this.collapsed = false;
  }

  /**
   * @returns {boolean}
   */
  get collapsed() {
    return this._collapsed;
  }

  /**
   * @param value {boolean}
   */
  set collapsed(value) {
    this._collapsed = value;
    if (this._collapsed) {
      this._displayItems = this._items.filter((x) => !x.collapsible);
      this._collapsedItems = this._items.filter((x) => x.collapsible);
    } else {
      this._displayItems = this._items.slice();
      this._collapsedItems = [];
    }
    if (this._shouldShowDividers()) {
      this._displayItems.unshift(new Divider());
    }
    this.invalidateDisplayWidth();
  }

  _shouldShowDividers() {
    return this._displayItems.length > 0 && this._leftDivider;
  }

  /**
   * @returns {ToolbarItem[]}
   */
  get displayItems() {
    return this._displayItems;
  }

  get hasVisibleDisplayItems() {
    return this.displayItems.findIndex((x) => x.visible) >= 0;
  }

  /**
   * @returns {ToolbarItem[]}
   */
  get collapsedItems() {
    return this._collapsedItems;
  }

  get hasVisibleCollapsedItems() {
    return this.collapsedItems.findIndex((x) => x.visible) >= 0;
  }

  /**
   * @returns {number}
   */
  get priority() {
    return this._priority;
  }

  /**
   * @return {boolean}
   */
  get visibility() {
    return this._visibility;
  }

  /**
   * @param value {boolean}
   */
  set visibility(value) {
    this._visibility = value;
  }

  /**
   * TODO: Don't hard code item widths?
   * @returns {number}
   */
  get displayWidth() {
    if (!this.collapsed && this._forceExpandedWidth) {
      return this._forceExpandedWidth;
    }
    return this._displayWidth.value(() => {
      return this._displayItems
        .filter((x) => x.visible)
        .reduce((a, v) => a + v.width, 0);
    });
  }

  /**
   * Call this if buttons' visibility is manually updated to recalculate the display width.
   */
  invalidateDisplayWidth() {
    this._displayWidth.clear();
  }

  /**
   * @returns {string}
   */
  get type() {
    return Section.TYPE_NORMAL;
  }

  /**
   * @returns {function(Section, Section)}
   */
  static get SORT_PRIORITY_ASC() {
    return (a, b) => {
      return a.priority > b.priority ? 1 : -1;
    };
  }

  /** @returns {string} */
  static get TYPE_NORMAL() {
    return 'section';
  }

  /** @returns {string} */
  static get TYPE_MORE() {
    return 'more';
  }
}

//-----------------------------------------------------

/**
 * A section of the toolbar that collapses to a Select button
 */
export class SelectSection extends Section {
  /**
   * @param values {{value: string, icon: string}[]}
   * @param onUpdate {function({value: string, icon: string}, SelectSection)}
   * @param priority {number}
   * @param [initialValue] {{value: string, icon: string}|null} default null
   * @param [leftDivider] {boolean} default true
   * @param [tooltip] {string}
   * @param [containerClass] {string}
   */
  constructor(
    values,
    priority,
    onUpdate,
    initialValue = null,
    leftDivider = true,
    tooltip = null,
    containerClass = '',
  ) {
    super([], priority, leftDivider);

    this._values = values;
    this._currentValue = initialValue;
    this._onUpdate = onUpdate;
    /** @type {Map.<value, Button>} */
    this._itemMap = this._initButtons(values, initialValue);
    this._items = [...this._itemMap.values()];
    this._select = new Select(
      values,
      (value) => (this.currentValue = value),
      initialValue,
      tooltip,
      containerClass,
    );

    this.collapsed = false;
  }

  /**
   * @returns {{value: string, icon: string}[]}
   */
  get values() {
    return this._values;
  }

  /**
   * @returns {{value: string, icon: string} | null}
   */
  get currentValue() {
    return this._currentValue;
  }

  /**
   * @param value {boolean}
   */
  set collapsed(value) {
    this._collapsed = value;
    if (this._collapsed) {
      this._displayItems = [this._select];
      this._collapsedItems = [];
    } else {
      this._displayItems = this._items.slice();
      this._collapsedItems = [];
    }
    if (this._shouldShowDividers()) {
      this._displayItems.unshift(new Divider());
    }
    this._displayWidth.clear();
  }

  /**
   * Have to override getter if setter is overridden :( b/c es6
   * @returns {boolean}
   */
  get collapsed() {
    return super.collapsed;
  }

  /**
   * @param value {{value: string, icon: string} | null}
   */
  set currentValue(value) {
    if (this._currentValue !== value) {
      if (this._currentValue) {
        this._itemMap.get(this._currentValue).toggled = false;
      }
      this._currentValue = value;
      if (this._currentValue) {
        this._itemMap.get(this._currentValue).toggled = true;
      }
      this._select.currentValue = value;
      this._onUpdate(value, this);
    }
  }

  /**
   * @param values {{value: string, icon: string, proTag: Point}[]}
   * @param initialValue {{value: string, icon: string, tooltip: string}}
   * @return {Map.<value, Button>}
   * @private
   */
  _initButtons(values, initialValue) {
    return new Map(
      values.map((val) => {
        const button = new IconButton(
          val.icon,
          null,
          val.tooltip,
          (ev, self) => {
            this.currentValue = val;
            self.toggled = true;
          },
          undefined,
          undefined,
          undefined,
          undefined,
        );
        if (val === initialValue) {
          button.toggled = true;
        }

        return [val, button];
      }),
    );
  }
}

//-----------------------------------------------------

/**
 * Special case More section - A "more" button that shows up if there are collapsed things
 */
export class MoreSection extends Section {
  /**
   * @param onClick {function(MouseEvent, Button)}
   */
  constructor(onClick) {
    super([], -1, true);

    this._moreButton = new TextButton(
      'More',
      'ck-carrot',
      null,
      onClick,
      null,
      null,
      false,
      70,
    );
    this.hasContent = false;
  }

  /**
   * @param value {boolean}
   */
  set collapsed(value) {
    this._collapsed = value;
    this._collapsed = false;
  }

  /**
   * Have to override getter if setter is overridden :( b/c es6
   * @returns {boolean}
   */
  get collapsed() {
    return super.collapsed;
  }

  /**
   * @param value {boolean}
   */
  set hasContent(value) {
    this._hasContent = value;
    if (this._hasContent) {
      this._displayItems = [this._moreButton];
      this._collapsedItems = [];
    } else {
      this._displayItems = [];
      this._collapsedItems = [];
    }
    if (this._shouldShowDividers()) {
      this._displayItems.unshift(new Divider());
    }
    this._displayWidth.clear();
  }

  /**
   * @returns {boolean}
   */
  get hasContent() {
    return this._hasContent;
  }

  /**
   * @returns {string}
   */
  get type() {
    return Section.TYPE_MORE;
  }
}
