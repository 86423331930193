'use strict';

export default class GoogleClassroomService {
  constructor($q, $log, environment, GoogleClientService, RosterService) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;

    /** @type {environment} */
    this._environment = environment;
    /** @type {GoogleClientService} */
    this._googleClientService = GoogleClientService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
  }

  /**
   * Gets the Google course information for the current user.
   *
   * @returns Promise<T>
   */
  getCourses() {
    return this._googleClientService.getCourses();
  }

  get hasCourseAndStudentPermissions() {
    return this._googleClientService.hasCourseAndStudentPermissions;
  }

  /**
   * Grants scopes to read courses, rosters, and student emails. See more about scopes here: https://developers.google.com/classroom/guides/auth
   *
   * @returns {*}
   */
  grantCourseAndStudentPermissions() {
    return this._googleClientService.grantCourseAndStudentPermissions();
  }

  /**
   *
   * Syncs a Classkick roster with its corresponding Google Course
   *
   * @param roster {Roster} A Classkick Roster
   * @returns Promise<Roster>
   */
  syncGoogleRoster(roster) {
    if (roster.googleCourseId) {
      let deferred = this.$q.defer();

      this._googleClientService
        .getGoogleCourseStudents(roster.googleCourseId)
        .then((students) => {
          return this._rosterService.updateClasskickRosterWithGoogleStudents(
            roster.id,
            students,
          );
        })
        .then(() => {
          deferred.resolve(roster);
        })
        .catch((error) => {
          deferred.reject(error);
        });

      return deferred.promise;
    } else {
      this.$log.error('Roster did not have a Google Course Id');
      return this.$q.reject();
    }
  }

  /**
   * Creates a roster given a Google Course. Updates the Classkick roster with
   * Classkick students based on Google Students.
   *
   * @param courses
   * @return Promise<T>
   */
  importGoogleCourses(courses) {
    return courses.reduce((accum, googleCourse) => {
      return accum.then(() => {
        return this._rosterService
          .createGoogleRoster(googleCourse)
          .then((roster) => {
            this._update = false;
            this.syncGoogleRoster(roster);
            if (roster.isHidden) {
              this.showRoster(roster);
              this._update = true;
            }

            if (roster.name !== googleCourse.name) {
              roster.name = googleCourse.name;
              this._update = true;
            }

            if (this._update) {
              this._rosterService.update(roster);
            }
          })
          .catch((err) => {
            this.$log.error('Error importing Google Rosters:', err);
          });
      });
    }, this.$q.resolve());
  }

  /**
   * Show selected roster by updating and saving their new property
   * @param roster {Roster}
   */
  showRoster(roster) {
    roster.properties = { ...roster.properties, is_hidden: 'unhidden' };
  }
}
