import moment from 'moment';
import QuestionFeedbackList from '../../model/domain/question-feedback-list';
import NotificationShardKeyFactory from '../../model/domain/notification-shard-key-factory.js';

export default class FeedbackService {
  /**
   * @ngInject
   */
  constructor(FirebaseService, NotificationShardKeyFactory) {
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {NotificationShardKeyFactory} */
    this._notificationShardKeyFactory = NotificationShardKeyFactory;
  }

  /**
   * @param receiverId {string}
   * @param work {AssignmentWork}
   * @return {QuestionFeedbackList}
   */
  getQuestionFeedbackList(receiverId, work) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forQuestionFeedbackList(work);
    return new QuestionFeedbackList(
      notificationShardKey,
      receiverId,
      work,
      this._firebaseService,
    );
  }

  /**
   * @param receiverId {string}
   * @param work {AssignmentWork}
   * @param questionId {string}
   * @param giverId {string}
   * @param giverName {string}
   */
  give(receiverId, work, questionId, giverId, giverName) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forQuestionFeedbackList(work);
    let firebaseInstanceId =
      this._firebaseService.firebaseNotificationInstanceIdForShardKey(
        notificationShardKey,
      );
    this._firebaseService
      .ref(
        `/notifications/v3/users/${receiverId}/feedbacks/receivers/works/${work.id}/questions/${questionId}/givers/${giverId}`,
        firebaseInstanceId,
      )
      .update({
        t: moment().toISOString(),
        n: giverName,
      });
  }

  /**
   * @param receiverId {string}
   * @param work {AssignmentWork}
   * @param questionId {string}
   */
  view(receiverId, work, questionId) {
    let notificationShardKey =
      this._notificationShardKeyFactory.forQuestionFeedbackList(work);
    let firebaseInstanceId =
      this._firebaseService.firebaseNotificationInstanceIdForShardKey(
        notificationShardKey,
      );
    this._firebaseService
      .ref(
        `/notifications/v3/users/${receiverId}/feedbacks/receivers/works/${work.id}/questions/${questionId}`,
        firebaseInstanceId,
      )
      .remove();
  }
}
