'use strict';

import Codec from './codec';
import BuyerState from '../domain/buyer-state';
import JsonCodec from './json-codec';

/**
 * Encodes/Decodes buyer state for a user
 */
export default class BuyerStateCodec extends Codec {
  constructor() {
    super();
    this._jsonCodec = new JsonCodec();
  }

  encode(value) {
    return {
      buyer_state: value.buyerState,
      buyer_config: {
        title: value.title,
        body: value.body,
        progress_percent: value.progress_percent,
        progress_label: value.progress_label,
        cta_color: value.cta_color,
        cta: value.cta,
        cta_copy: value.cta_copy,
        metric: value.metric,
        show_on_ios: value.show_on_ios,
        organization_id: value.organizationId,
        order_id: value.orderId,
        renewal: value.renewal,
      },
    };
  }

  decode(value) {
    if (!value.buyer_config) {
      return;
    }
    let buyerConfig = this._jsonCodec.decode(value.buyer_config);
    return new BuyerState(
      value.buyer_state,
      buyerConfig && buyerConfig.title,
      buyerConfig && buyerConfig.body,
      buyerConfig && buyerConfig.progress_percent,
      buyerConfig && buyerConfig.progress_label,
      buyerConfig && buyerConfig.cta_color,
      buyerConfig && buyerConfig.cta,
      buyerConfig && buyerConfig.cta_copy,
      buyerConfig && buyerConfig.metric,
      buyerConfig && buyerConfig.show_on_ios,
      buyerConfig && buyerConfig.organization_id,
      buyerConfig && buyerConfig.order_id,
      buyerConfig && buyerConfig.renewal,
    );
  }
}
