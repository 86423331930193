'use strict';

import Element from './element';
import Debouncer from '../../util/debouncer';
import GestureManager from '../gesture-manager';
import Point from '../point';
import Size from '../size';
import ImageView from './image-view';

/**
 * Represents a crop control
 */
export default class ImageCropper extends Element {
  /**
   * @param size {Size}
   * @param imageSrc {String}
   * @param imageSize {Size}
   * @param padding {Size}
   */
  constructor(size, imageSrc, imageSize, padding) {
    super('image-cropper', 'image-cropper', null);

    this._size = size;
    this._imageSize = imageSize;
    this._padding = padding;

    const imageFit = this._size.minus(this._padding.times(2)).fit(imageSize);
    const imageLocation = this._size.center(imageFit);

    /** @type {ImageView} */
    this._imageView = new ImageView(imageSrc, imageLocation, imageFit);

    this._debouncer = new Debouncer(250, 5000, () => this._flushChange());

    this._selectAll();
    this._interacted = false;
  }

  createElement(root) {
    this._image = root.g();
    this._shade = root.path();
    this._crop = root.rect(0, 0, 0, 0);
    this._interactRect = root.rect(0, 0, 0, 0);

    this._imageView.createElement(this._image);

    this._drag = new GestureManager(this, this._canvas);
    this._drag.start(this._interactRect.node);
    this._drag.click.subscribe(this._click, this);
    this._drag.dragStart.subscribe(this._dragStart, this);
    this._drag.dragMove.subscribe(this._dragMove, this);
    this._drag.dragEnd.subscribe(this._dragEnd, this);
  }

  update() {
    this._imageView.update(this._image);

    if (this._interacted) {
      this._shade.attr({
        d: this._shadePath(),
        fillRule: 'evenodd',
        fill: 'rgba(0, 0, 0, .5)',
      });

      this._crop.attr({
        x: this._selectedLocation.x,
        y: this._selectedLocation.y,
        width: this._selectedSize.width,
        height: this._selectedSize.height,
        fill: 'transparent',
        strokeWidth: 1,
        strokeDasharray: '5, 5',
        stroke: '#FFFFFF',
      });
    }

    this._interactRect.attr({
      width: this.width,
      height: this.height,
      fill: 'transparent',
    });
  }

  _click() {
    this.selectAll();
  }

  /**
   *
   * @param selection {CropResult}
   */
  setSelection(selection) {
    if (selection) {
      this._interacted = true;
      this._trackChange(() => {
        const scale = this._imageView.size.fitScale(selection.imageSize);
        this._selectedLocation = selection.selectedLocation
          .times(scale)
          .plus(this._imageView.location);
        this._selectedSize = selection.selectedSize.times(scale);
      });
    } else {
      this.selectAll();
    }
  }

  selectAll() {
    this._interacted = true;
    this._trackChange(() => {
      this._selectAll();
    });
  }

  _selectAll() {
    this._selectedLocation = new Point(
      this._imageView.location.x,
      this._imageView.location.y,
    );
    this._selectedSize = new Size(
      this._imageView.width,
      this._imageView.height,
    );
  }

  _dragStart(args) {
    this._interacted = true;
    this._beginDrag = args.mouseStart;
    this._endDrag = args.offsetLocation;

    this._measureDrag();
  }

  _dragMove(args) {
    this._endDrag = args.offsetLocation;

    this._measureDrag();
  }

  _dragEnd(args) {
    this._trackChange(() => {
      this._endDrag = args.offsetLocation;
      this._measureDrag();
    });
  }

  _measureDrag() {
    const minBound = this._imageView.location;
    const maxBound = this._imageView.location.plus(
      new Point(this._imageView.width, this._imageView.height),
    );

    this._selectedLocation = new Point(
      Math.min(
        maxBound.x - 5,
        Math.max(minBound.x, Math.min(this._beginDrag.x, this._endDrag.x)),
      ),
      Math.min(
        maxBound.y - 5,
        Math.max(minBound.y, Math.min(this._beginDrag.y, this._endDrag.y)),
      ),
    );

    const bottomRight = new Point(
      Math.min(
        maxBound.x,
        Math.max(minBound.x, Math.max(this._beginDrag.x, this._endDrag.x)),
      ),
      Math.min(
        maxBound.y,
        Math.max(minBound.y, Math.max(this._beginDrag.y, this._endDrag.y)),
      ),
    );

    this._selectedSize = new Size(
      Math.max(5, Math.abs(bottomRight.x - this._selectedLocation.x)),
      Math.max(5, Math.abs(bottomRight.y - this._selectedLocation.y)),
    );

    this.tryUpdate();
  }

  _shadePath() {
    return [
      `M${this._imageView.location.x} ${this._imageView.location.y}`,
      `v${this._imageView.height}`,
      `h${this._imageView.width}`,
      `v-${this._imageView.height}z`,
      `M${this._selectedLocation.x} ${this._selectedLocation.y}`,
      `h${this._selectedSize.width}`,
      `v${this._selectedSize.height}`,
      `h-${this._selectedSize.width}z`,
    ].join(' ');
  }

  /**
   * @returns {{imageSize: Size, selectedLocation: Point, selectedSize: Size}}
   */
  snapshot() {
    return {
      imageSize: this._imageView.size,
      selectedLocation: this._selectedLocation,
      selectedSize: this._selectedSize,
    };
  }

  /**
   * @returns {CropResult}
   */
  crop() {
    const inverseScale = this._imageSize.fitScale(this._imageView.size);

    return new CropResult(
      this._imageSize,
      this._selectedLocation
        .minus(this._imageView.location)
        .times(inverseScale),
      this._selectedSize.times(inverseScale),
    );
  }

  cleanUp() {
    super.cleanUp();
    this._debouncer.destroy();
  }

  resize(size, offset) {
    this._size = size;
    this._drag._canvas.absolutePosition = offset;

    const imageFit = this._size
      .minus(this._padding.times(2))
      .fit(this._imageSize);
    const imageLocation = this._size.center(imageFit);

    this._imageView._size = imageFit;
    this._imageView._location = imageLocation;

    this.selectAll();

    this.tryUpdate();
  }
}

export class CropResult {
  /**
   * @param imageSize {Size}
   * @param selectedLocation {Point}
   * @param selectedSize {Size}
   */
  constructor(imageSize, selectedLocation, selectedSize) {
    /** @type {Size} */
    this.imageSize = imageSize;
    /** @type {Point} */
    this.selectedLocation = selectedLocation;
    /** @type {Size} */
    this.selectedSize = selectedSize;
  }
}
