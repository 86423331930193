'use strict';

import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import DeleteAccountDialogTemplate from './delete-account-dialog.html';
import ConfirmDialogController from '../confirm-dialog/confirm-dialog.controller';

/**
 * Dialog to confirm account deletion
 */
export default class DeleteAccountDialogController {
  constructor(
    $mdDialog,
    $location,
    $mdToast,
    UserService,
    AuthService,
    GoogleFormService,
    AnalyticsService,
    user,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$location = $location;
    this.$mdToast = $mdToast;
    this._loadingDialog = LoadingDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;

    /** @type {User} */
    this.user = user;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {GoogleFormService} */
    this._googleFormService = GoogleFormService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {string[]} */
    this.deleteOptions = [
      'Wrong Account Type',
      'Pro Expired',
      "Don't Like Classkick",
    ];
    /** @type {string} */
    this.deleteReason = null;
  }

  static get AssignmentsWorkedOnThreshold() {
    return 5;
  }

  /**
   *
   * @param $mdDialog
   * @param user {User}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, user) {
    return $mdDialog.show({
      controller: DeleteAccountDialogController,
      template: DeleteAccountDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        user: user,
      },
      focusOnOpen: false,
    });
  }

  /**
   *
   * @param search {string} the search term
   * @param array {string[]} the original array of options
   * @returns {string[]}
   */
  autocompleteFilter(search, array) {
    if (!search) {
      return array;
    }
    return array.filter((value) =>
      value.toLowerCase().includes(search.toLowerCase()),
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  _logDeleteReason() {
    this._googleFormService.sendDeleteReason(this.user, this.deleteReason);
  }

  deleteAccount() {
    this._confirmDialog(
      this.$mdDialog,
      'Are you sure want to delete your account?',
      'You cannot recover anything after it is deleted.',
      false,
      false,
      true,
    ).then(() => {
      this._analyticsService.userDeleted();
      this._logDeleteReason();
      let promise = this._userService
        .delete(this.user.id)
        .then(() => this.user);
      this._loadingDialog(this.$mdDialog, promise)
        .then(() => {
          let config = this.$mdToast
            .simple()
            .textContent('Deleted Account')
            .position('bottom right');
          this.$mdToast.show(config);
        })
        .then(() => {
          this._authService.signOut();
        })
        .catch(() => {
          this._errorDialog(
            this.$mdDialog,
            'There was a problem deleting your account.',
          );
        });
    });
  }
}
