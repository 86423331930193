'use strict';

import CdnUtils from '../../model/util/cdn-utils';
import ViewHelpDialogTemplate from './view-help-dialog.html';

export class ViewHelps {
  static get SessionQuestion() {
    return 'session_question';
  }

  static get SessionWork() {
    return 'session_work';
  }

  static get SessionWatch() {
    return 'session_watch';
  }

  static get AssignmentsList() {
    return 'assignments_list';
  }

  static get RostersList() {
    return 'rosters_list';
  }

  static get PlaceTextbox() {
    return 'place_textbox';
  }

  static get PlaceFitb() {
    return 'place_fitb';
  }

  static get PlaceSline() {
    return 'place_sline';
  }

  static get PlaceSticker() {
    return 'place_sticker';
  }

  static get PlaceStickerWand() {
    return 'place_sticker_wand';
  }

  static get ContentStickers() {
    return 'content_stickers';
  }

  static get AssignmentEdit() {
    return 'assignment_edit';
  }

  static get AssignmentRosters() {
    return 'assignment_rosters';
  }

  static get AssignmentTeacherFeedback() {
    return 'assignment_teacher_feedback';
  }

  static get HideStudentNames() {
    return 'hide_student_names';
  }

  static get AllowPeerHelpers() {
    return 'allow_peer_helpers';
  }

  static get ShowStudentScores() {
    return 'show_student_scores';
  }

  static get LockAssignment() {
    return 'lock_assignment';
  }

  static get HideAssignment() {
    return 'hide_assignment';
  }

  static get PlaceStickersHelpText() {
    return 'place_stickers_help_text';
  }

  static get LatestFeatures() {
    return 'latest_features';
  }

  static get CustomStickers() {
    return 'custom_stickers';
  }

  static get OrderPage() {
    return 'order_page';
  }

  static get StudentAccountBasic() {
    return 'student_account_basic';
  }

  static get StudentAccountPortfolio() {
    return 'student_account_portfolio';
  }

  static get FirstSuccess() {
    return 'first_success';
  }

  static get GivingFeedback() {
    return 'giving_feedback';
  }

  static get TeacherHelpCenter() {
    return 'teacher_help_center';
  }

  static get BulkUpdate() {
    return 'bulk_update';
  }

  static get CoTeacher() {
    return 'co_teacher';
  }
}

export default class ViewHelpDialogController {
  constructor(
    $mdDialog,
    $window,
    $scope,
    $sce,
    StaticContentService,
    CacheService,
    hotkeys,
    viewName,
  ) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$sce = $sce;

    this._staticContentService = StaticContentService;
    this._cacheService = CacheService;

    this._viewName = viewName;
    this._index = 0;

    this._init();
    this._initHotkeys($scope, hotkeys);
  }

  _init() {
    this._staticContentService.getViewHelps().then((data) => {
      const view = data[this._viewName];
      this._title = view.title;
      this._showHelp = view.show_ask_us_anything;
      this._helps = Object.keys(view.helps).map((key) => view.helps[key]);
      this._helps.forEach((help) => {
        const transformedUrl = CdnUtils.urlTransform(help.url);
        help.sanitizedUrl = this.$sce.trustAsResourceUrl(transformedUrl);
      });
      this._helps.sort((a, b) => (a.order < b.order ? -1 : 1));
    });
  }

  _initHotkeys(scope, hotkeys) {
    hotkeys
      .bindTo(scope)
      .add({
        combo: 'left',
        description: 'View previous item',
        callback: () => {
          if (!this.onFirst) {
            this.prev();
          }
        },
      })
      .add({
        combo: 'right',
        description: 'View next item',
        callback: () => {
          if (!this.onLast) {
            this.next();
          }
        },
      });
  }

  get title() {
    return this._title;
  }

  get helps() {
    return this._helps;
  }

  get hideNavButtons() {
    return this.helps && this.helps.length < 2;
  }

  get currentHelp() {
    return this.helps && this.helps[this._index];
  }

  get currentTitle() {
    return this.currentHelp && this.currentHelp.title;
  }

  get currentImageSanitizedUrl() {
    return this.currentHelp && this.currentHelp.sanitizedUrl;
  }

  get currentImageIsYoutube() {
    return (
      this.currentHelp &&
      this.currentHelp.url &&
      this.currentHelp.url.indexOf('youtube') > -1
    );
  }

  get currentDescription() {
    return this.currentHelp && this.currentHelp.desc;
  }

  get onFirst() {
    return this._index === 0;
  }

  get onLast() {
    return this._helps && this._index === this._helps.length - 1;
  }

  prev() {
    this._index--;
  }

  next() {
    this._index++;
  }

  /**
   * @param help {url<string>, title<string>}
   */
  goTo(help) {
    this._index = this.indexForId(help.title);
  }

  /**
   * @param title {string}
   * @return {number}
   */
  indexForId(title) {
    let index = 0;
    this._helps.forEach((help, i) => {
      if (help.title === title) {
        index = i;
      }
    });
    return index;
  }

  get ImageHeight() {
    return 455;
  }

  get imagesStyle() {
    return {
      transform: `translateY(${this.ImageHeight * this._index * -1}px)`,
    };
  }

  /**
   * Shows the help and resources dialog
   *
   * @param $mdDialog
   * @param viewName {string} session_question | ?
   * @returns {Promise}
   */
  static show($mdDialog, viewName) {
    return $mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      template: ViewHelpDialogTemplate,
      controller: ViewHelpDialogController,
      controllerAs: 'ctrl',
      locals: {
        viewName,
      },
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  get showHelp() {
    return this._showHelp;
  }

  openHelpCenter() {
    this.$window.open('https://classkick.com/help', '_blank');
  }
}
