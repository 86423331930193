'use strict';

import Codec from './codec';
import ClassCode from '../domain/class-code';

/**
 * Encodes/Decodes a class code
 */
export default class ClassCodeCodec extends Codec {
  /**
   * @param value {ClassCode}
   * @returns {*}
   */
  encode(value) {
    if (value) {
      return {
        class_code: value.classCode,
        roster_id: value.rosterId,
        assignment_id: value.assignmentId,
        same_contract: value.sameContract,
        show_student_scores: value.showStudentScores,
        lock_student_work: value.lockStudentWork,
        hide_student_work: value.hideStudentWork,
        allow_pdf: value.allowPdf,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {ClassCode|null}
   */
  decode(value) {
    if (value) {
      return new ClassCode(
        value.class_code,
        value.roster_id,
        value.assignment_id,
        value.same_contract,
        value.show_student_scores,
        value.lock_student_work,
        value.hide_student_work,
        value.allow_pdf,
      );
    }

    return null;
  }
}
