'use strict';

import HexColors from '../../css-constants';
import Validation from '../../model/util/validation';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import {
  SubscriptionIntervals,
  SubscriptionPlans,
  SubscriptionStatus,
} from '../../model/domain/subscription';
import LazyVar from '../../model/util/lazy-var';
import ErrorCodes from '../../model/domain/error-codes';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import { SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';

export default class PaymentController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $log,
    $state,
    $stateParams,
    $timeout,
    $mdDialog,
    $mdToast,
    environment,
    BreadcrumbService,
    CacheService,
    StripeService,
    SubscriptionService,
    AuthService,
    AnalyticsService,
  ) {
    this.$q = $q;
    this.$log = $log;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this._environment = environment;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StripeService} */
    this._stripeService = StripeService;
    /** @type {SubscriptionService} */
    this._subscriptionService = SubscriptionService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    /**
     * @type {LazyVar.<Promise.<Subscription>>}
     * @private
     */
    this._createdSubscription = new LazyVar();

    this._errorDialog = ErrorDialogController.show;
    this._loadingDialog = LoadingDialogController.show;

    this._nameOnCard = '';
    this._email = '';
    this._postalCode = '';
    this._cardInput = undefined;
    this._cardValue = undefined;
    this._subscriptionPlan =
      this.$stateParams.subscriptionPlan || SubscriptionPlans.NiagaraAnnualV7;
    this._isFocused = false;
    this.emailPattern = Validation.EmailPattern;
    this._error = undefined;
    this._loading = true;
    this._disableAutocompleteString =
      'disable-autocomplete-string-' + Math.random();

    this.createStripeCardElement();
    this.init();
  }

  init() {
    this.$q
      .all({
        user: this._cacheService.getUser(),
        contracts: this._cacheService.getContracts(),
        subscriptions: this._cacheService.getSubscriptions(),
      })
      .then(({ user, contracts, subscriptions }) => {
        this._user = user;
        this._contracts = contracts;
        this._subscriptions = subscriptions;
        this._nameOnCard = user.firstLastCombo;
        this._email = user.email;
        this._setDisplayText();
        this._checkShouldNavToUpdate();
        this._loading = false;
        this._analyticsService.subscriptionFunnel(
          SubscriptionFunnel.ENTER_PAYMENT,
        );
      })
      .catch((error) => {
        this._error = error;
        this._loading = false;
      });
  }

  _setDisplayText() {
    this._isCreatePayment = this.$state.is('root.account.create-payment');
    this._title = this._isCreatePayment
      ? 'Upgrade to Pro Teacher'
      : 'Update Payment Information';
    this._planInfoDisplay = this._isCreatePayment
      ? ''
      : `${this._nameOnCard} - Pro Teacher ${SubscriptionIntervals.isMonthly(this._subscriptionPlan) ? 'Monthly' : 'Annual'}`;
  }

  _checkShouldNavToUpdate() {
    let pastDueSubscription = this._subscriptions.find(
      (sub) => sub.status === SubscriptionStatus.PastDue,
    );
    if (!this.isAlternative && pastDueSubscription) {
      this._breadcrumbService.go(
        'root.account.update-payment',
        {
          subscriptionPlan: pastDueSubscription.subscriptionPlan,
        },
        true,
      );
    }
  }

  createStripeCardElement() {
    let fonts = [
      {
        family: 'GothamRoundedBook',
        src: `url('${this._environment.assetsBaseUrl}/assets/fonts/GothamRnd-Book.otf') format('opentype')`,
      },
    ];

    let options = {
      hidePostalCode: true,
      style: {
        base: {
          color: HexColors.CK_HEADER_GREY,
          lineHeight: '26px',
          fontFamily: 'GothamRoundedBook',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
        },
        invalid: {
          color: HexColors.CK_WARN,
          iconColor: HexColors.CK_WARN,
        },
      },
      classes: {
        focus: 'ck-card-focused',
        empty: 'ck-card-empty',
      },
    };

    this._cardInput = this._stripeService.stripe
      .elements({ fonts })
      .create('card', options);

    this._cardInput.mount('.card-element');

    this._cardInput.on('focus', () => {
      this._isFocused = true;
      this.$timeout(() => {}, 0);
    });

    this._cardInput.on('blur', () => {
      this._isFocused = false;
      this.$timeout(() => {}, 0);
    });

    this._cardInput.on('change', (value) => {
      this._cardValue = value;
      this.$timeout(() => {}, 0);
    });
  }

  get isFloating() {
    return {
      'md-input-focused': this._isFocused || this.notEmpty,
    };
  }

  /**
   * @returns {{type:string, code:string, message:string}}
   */
  get cardError() {
    return this._cardValue && this._cardValue.error;
  }

  /**
   * @return {{error: {code: string}}}
   */
  get emptyCardValue() {
    return {
      empty: true,
      error: {
        code: 'required',
      },
    };
  }

  get formattedCardError() {
    if (this.cardError) {
      return {
        [this.cardError.code]: true,
      };
    }
    return {};
  }

  /**
   * @returns {boolean}
   */
  get cardHasError() {
    return !!this.cardError;
  }

  /**
   * @returns {boolean|undefined}
   */
  get cardIsComplete() {
    return this._cardValue && this._cardValue.complete;
  }

  /**
   * @returns {boolean}
   */
  get notEmpty() {
    return this._cardValue && !this._cardValue.empty;
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.home');
  }

  /**
   * @returns {string}
   */
  get nameOnCard() {
    return this._nameOnCard;
  }

  /**
   * @param value {string}
   */
  set nameOnCard(value) {
    this._nameOnCard = value;
  }

  /**
   * @returns {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @param value {string}
   */
  set email(value) {
    this._email = value;
  }

  /**
   * @returns {string}
   */
  get postalCode() {
    return this._postalCode;
  }

  /**
   * @param value {string}
   */
  set postalCode(value) {
    this._postalCode = value;
    this.paymentForm.postalCodeInput.$setValidity('incorrect_zip', true);
  }

  /**
   * @return {boolean}
   */
  get isCreatePayment() {
    return this._isCreatePayment;
  }

  get subscriptionPlan() {
    return this._subscriptionPlan;
  }

  set subscriptionPlan(value) {
    this._subscriptionPlan = value;
  }

  /**
   * @return {string}
   */
  get monthly() {
    return SubscriptionPlans.NiagaraMonthlyV7;
  }

  /**
   * @return {string}
   */
  get monthlyPriceBase() {
    return '$33.99';
  }

  /**
   * @return {string}
   */
  get yearly() {
    return SubscriptionPlans.NiagaraAnnualV7;
  }

  /**
   * @return {string}
   */
  get yearlyPriceBase() {
    return '$399';
  }

  get title() {
    return this._title;
  }

  get planInfoDisplay() {
    return this._planInfoDisplay;
  }

  get submitButtonDisplay() {
    return this._isCreatePayment
      ? `Submit Payment - ${this.subscriptionPlan === this.monthly ? `${this.monthlyPriceBase}/month` : `${this.yearlyPriceBase}/year`}`
      : 'Update';
  }

  onSubmit() {
    if (angular.isUndefined(this._cardValue) || this._cardValue.empty) {
      this._cardValue = this.emptyCardValue;
      this._cardInput.focus();
      return;
    } else if (!this.cardIsComplete) {
      return;
    }

    let promise;

    if (this._isCreatePayment) {
      promise = this._create(
        this._cardInput,
        this.nameOnCard,
        this.postalCode,
        this.email,
        this.subscriptionPlan,
      );
    } else {
      promise = this._update(
        this._cardInput,
        this.nameOnCard,
        this.postalCode,
        this.email,
      );
    }

    this._loadingDialog(this.$mdDialog, promise);

    promise.catch((error) => {
      this._handleServerError(error);
    });
  }

  /**
   * @param card {Stripe.Element}
   * @param name {string}
   * @param zip {string}
   * @param email {string}
   * @param subscriptionPlan {string}
   * @param [contractId] {string}
   * @return Promise
   */
  _create(card, name, zip, email, subscriptionPlan, contractId) {
    return this.$q
      .all({
        token: this._createToken(card, name, zip),
        subscription: this._createSubscription(subscriptionPlan, contractId),
      })
      .then(({ subscription, token }) => {
        return this.$q.all({
          subscription,
          token: this._activate(subscription.id, email, token.id),
        });
      })
      .then(({ subscription }) => {
        this._breadcrumbService.go(
          'root.account.payment-success',
          { subscriptionId: subscription.id },
          true,
        );
      });
  }

  /**
   * @param card {Stripe.Element}
   * @param name {string}
   * @param zip {string}
   * @return {Promise.<string>}
   */
  _createToken(card, name, zip) {
    return this._stripeService
      .createToken(card, {
        name: name,
        address_zip: zip,
      })
      .then(({ token }) => {
        return token;
      });
  }

  /**
   * @param plan {string}
   * @param contractId {string}
   * @returns {Promise.<Subscription>}
   * @private
   */
  _createSubscription(plan, contractId) {
    return this._createdSubscription.value(() => {
      return this._subscriptionService
        .create(plan, contractId)
        .then(({ subscription }) => subscription)
        .catch((err) => {
          this._createdSubscription.clear();
          throw err;
        });
    });
  }

  /**
   * @param card {Stripe.Element}
   * @param name {string}
   * @param zip {string}
   * @param email {string}
   * @return Promise
   */
  _update(card, name, zip, email) {
    return this.$q
      .all({
        subscription: this._getSubscription(),
        token: this._createToken(card, name, zip),
      })
      .then(({ subscription, token }) => {
        return this._activate(subscription.id, email, token.id);
      })
      .then(() => {
        this.goBack();
        this.showToast('Payment info updated');
      });
  }

  _handleServerError(error) {
    if (error.code === ErrorCodes.PAYMENT_FAILED) {
      if (error.data.data.code === 'incorrect_zip') {
        this.paymentForm.postalCodeInput.$setValidity('incorrect_zip', false);
      } else {
        this._cardValue = {
          empty: false,
          error: {
            code: error.data.data.code,
          },
        };
      }
    } else {
      this._errorDialog(
        this.$mdDialog,
        'There was an error updating your account, please try again.',
        '',
      );
    }
  }

  /**
   * @param subscriptionId {string}
   * @param email {string}
   * @param tokenId {string}
   * @param remainingRetries {int}
   * @return {Promise.<UserTokenInfo>}
   */
  _activate(subscriptionId, email, tokenId, remainingRetries = 3) {
    return this._subscriptionService
      .activate(subscriptionId, email, tokenId)
      .then(({ token }) => {
        if (token) {
          this._cacheService.reset();
          return this._authService.processTokenResult(
            token,
            this._authService.rememberMe,
          );
        } else {
          return this._authService.authData;
        }
      })
      .catch((err) => {
        if (err.code === ErrorCodes.PAYMENT_FAILED) {
          // Handle payment failed errors normally
          return this.$q.reject(err);
        } else if (remainingRetries <= 0) {
          // If we run out of retries, provide the user with some action to take
          return this._errorDialog(
            this.$mdDialog,
            'There was a connection issue while processing your payment info.',
            'Please check your connection and try again. If you continue to have problems, please contact support@classkick.com.',
            [
              {
                text: 'Cancel',
                action: ErrorDialogController.CANCEL,
              },
              {
                text: 'Retry',
                action: ErrorDialogController.CONFIRM,
              },
            ],
          )
            .then(() => {
              return this._activate(
                subscriptionId,
                email,
                tokenId,
                remainingRetries - 1,
              );
            })
            .catch(() => {
              return this.$q.reject(err);
            });
        } else {
          // Automatically retry unknown errors. Hopefully it was a connection issue and the next retry will work
          // If we fail here, the user's payment is in an unknown state. That is bad and might require manual reconciliation.
          return this.$timeout(() => {}, 1500, false).then(() => {
            // err.status < 0 indicates some connection failure
            return this._activate(
              subscriptionId,
              email,
              tokenId,
              remainingRetries - 1,
            );
          });
        }
      });
  }

  /**
   * @return {Promise.<Subscription>}
   */
  _getSubscription() {
    return this._cacheService.getSubscriptions().then((subscriptions) => {
      return subscriptions.find(
        (subscription) =>
          subscription.subscriptionPlan === this.subscriptionPlan,
      );
    });
  }

  /**
   * @param message {string}
   */
  showToast(message) {
    this.$mdToast.show(
      this.$mdToast.simple().textContent(message).position('bottom right'),
    );
  }

  /**
   * @return {boolean}
   */
  get isAlternative() {
    return (
      this._isCreatePayment &&
      (this._authService.authData.isStudent ||
        (this._authService.authData.isPro && !this.isTrial))
    );
  }

  get isTrial() {
    return !!this.activeTrialContract;
  }

  get activeTrialContract() {
    return (
      this._contracts &&
      this._contracts.find(
        (contract) => contract.isTrial && !contract.isExpired,
      )
    );
  }

  get trialContract() {
    return (
      this._contracts && this._contracts.find((contract) => contract.isTrial)
    );
  }

  get trialContractId() {
    let trial = this.trialContract;
    return trial && trial.id;
  }

  /**
   * @return {string}
   */
  get alternativeMessage() {
    if (this._authService.authData.isStudent) {
      return 'Sorry, students cannot upgrade to Pro accounts.';
    } else {
      return 'Whoops, it looks like you already have a Pro account.';
    }
  }

  /**
   * In order to disable the autocomplete on the hidden input field we're using to
   * gather credit card info, we need to create a random string to trick the browser
   * https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
   * @return {string}
   */
  get disableAutocompleteString() {
    return this._disableAutocompleteString;
  }

  /**
   * @return {Error}
   */
  get error() {
    return this._error;
  }

  /**
   * @return {boolean}
   */
  get loading() {
    return this._loading;
  }
}
