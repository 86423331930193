'use strict';

import moment from 'moment';
import JSEvent from './../util/js-event';
import Point from './point';
import StaticService from '../../services/static/static.service';

/**
 * Class to help manage
 */
export default class GestureManager {
  /**
   * @param [target] {Control}
   * @param canvas {SvgCanvas|{absolutePosition: Point, inverseScaleFactor: number}}
   */
  constructor(target, canvas) {
    this._target = target;
    this._click = new JSEvent(target);
    this._rightclick = new JSEvent(target);
    this._doubleclick = new JSEvent(target);
    this._tripleclick = new JSEvent(target);
    this._dragStart = new JSEvent(target);
    this._dragMove = new JSEvent(target);
    this._dragEnd = new JSEvent(target);
    this._mouseEnter = new JSEvent(target);
    this._mouseLeave = new JSEvent(target);

    this._canvas = canvas;

    this._hovering = false;
    this._dragging = false;
    this._clicking = false;
    this._rightclicking = false;

    this._startPoint = null;
    // -1 to disable drag
    this._dragThreshold = 5;
    // -1 to disable doubleclick
    this._doubleclickThreshold = 250;

    this._lastClick = moment().subtract(100, 'seconds');
    this._secondClick = moment().subtract(100, 'seconds');

    this._anonJqMouseDown = (data) => this._jqMouseDown(data);
    this._anonJqMouseUp = (data) => this._jqMouseUp(data);
    this._anonJqMouseMove = (data) => this._jqMouseMove(data);
    this._anonJqMouseEnter = (data) => this._jqMouseEnter(data);
    this._anonJqMouseLeave = (data) => this._jqMouseLeave(data);
    this._anonJqContextMenu = (data) => this._jqContextMenu(data);

    this._body = angular.element('body');
  }

  /**
   * Binds this gesture manager to a dom element
   * @param selector {object|string} a dom element or selector string
   */
  start(selector) {
    this.stop();

    this._element = angular.element(selector);

    this._element.on('touchstart', this._anonJqMouseDown);
    this._element.mousedown(this._anonJqMouseDown);
    this._element.mouseenter(this._anonJqMouseEnter);
    this._element.mouseleave(this._anonJqMouseLeave);
    this._element.on('contextmenu', this._anonJqContextMenu);
  }

  /**
   * Unbinds this gesture manager from the dom element pass to #start()
   */
  stop() {
    if (this._element) {
      this._element.off('touchstart', this._anonJqMouseDown);
      this._element.off('mousedown', this._anonJqMouseDown);
      this._element.off('mouseenter', this._anonJqMouseEnter);
      this._element.off('mouseleave', this._anonJqMouseLeave);
      this._element.off('contextmenu', this._anonJqContextMenu);

      this._body.off('mouseup', this._anonJqMouseUp);
      this._body.off('mousemove', this._anonJqMouseMove);
    }

    this._click.clear();
    this._dragStart.clear();
    this._dragMove.clear();
    this._dragEnd.clear();
    this._mouseEnter.clear();
    this._mouseLeave.clear();
  }

  _jqMouseDown(data) {
    if (this._isLeftClick(data) || this._isTouchEvent(data)) {
      data.preventDefault();
      data.stopPropagation();

      this._startPoint = this._point(data)
        .minus(this._canvas.absolutePosition)
        .times(this._canvas.inverseScaleFactor);
      this._targetStart = this._targetLocation;
      this._clicking = true;
      this._dragging = false;
    } else if (this._isRightClick(data)) {
      data.preventDefault();
      data.stopPropagation();

      this._rightclicking = true;
    }

    if (this._clicking || this._rightclicking) {
      this._body.mouseup(this._anonJqMouseUp);
      this._body.mousemove(this._anonJqMouseMove);
      this._element.on('touchend', this._anonJqMouseUp);
      this._element.on('touchmove', this._anonJqMouseMove);
    }
  }

  _jqMouseUp(data) {
    if (this._isLeftClick(data) || this._isTouchEvent(data)) {
      data.preventDefault();
      data.stopPropagation();

      if (this._dragging) {
        this.dragEnd.raise(this._eventArgs(data));
      } else if (this._clicking) {
        if (
          this._doubleclickThreshold >= 0 &&
          this._secondClick.isAfter(
            moment().subtract(this._doubleclickThreshold, 'milliseconds'),
          )
        ) {
          this.tripleclick.raise(this._eventArgs(data));
          this._secondClick = moment().subtract(100, 'seconds');
        } else if (
          this._doubleclickThreshold >= 0 &&
          this._lastClick.isAfter(
            moment().subtract(this._doubleclickThreshold, 'milliseconds'),
          )
        ) {
          this.doubleclick.raise(this._eventArgs(data));
          this._lastClick = moment().subtract(100, 'seconds');
          this._secondClick = moment();
        } else {
          this.click.raise(this._eventArgs(data));
          this._lastClick = moment();
        }
      }
      this._clicking = false;
      this._dragging = false;
    } else if (this._isRightClick(data)) {
      data.preventDefault();
      data.stopPropagation();

      this._rightclicking = false;
    }

    if (!this._clicking && !this._rightclicking) {
      this._body.off('mouseup', this._anonJqMouseUp);
      this._body.off('mousemove', this._anonJqMouseMove);
      this._element.off('touchend', this._anonJqMouseUp);
      this._element.off('touchmove', this._anonJqMouseMove);
    }
  }

  _jqMouseMove(data) {
    // Changing to this._clicking from this._isLeftClick(data) because on initial
    // right click and drag, the mousemove events are coming back with button = 0.
    // Our internal flag seems to be more correct
    if (this._clicking || this._isTouchEvent(data)) {
      data.preventDefault();

      let args = this._eventArgs(data);
      let point = args.offsetLocation;
      if (this._startPoint === null) {
        StaticService.get.$log.warn(
          'gesture-manager$_jqMouseMove _startPoint is null',
        );
        return;
      }
      let delta = new Point(
        point.x - this._startPoint.x,
        point.y - this._startPoint.y,
      );

      if (this._dragThreshold >= 0 && this._clicking && !this._dragging) {
        if (
          Math.max(
            Math.abs(point.x - this._startPoint.x),
            Math.abs(point.y - this._startPoint.y),
          ) > this._dragThreshold
        ) {
          this._dragging = true;
          args.absoluteLocation = point.minus(delta);
          args.relativeLocation = args.relativeLocation.minus(delta);
          this._dragStart.raise(args);
        }
      }

      if (this._dragging) {
        args.delta = delta;
        this.dragMove.raise(args);
      }
    }
  }

  _jqMouseEnter(data) {
    this._hovering = true;
    this.mouseEnter.raise(this._eventArgs(data));
  }

  _jqMouseLeave(data) {
    this._hovering = false;
    this.mouseLeave.raise(this._eventArgs(data));
  }

  _jqContextMenu(data) {
    data.preventDefault();
  }

  _eventArgs(data, args) {
    let result = args ? args : {};

    // xy coords of control at the beginning of gesture (inherently at offsetLocation)
    result.controlStart = this._targetStart;

    // offsetLocation at beginning of gesture
    result.mouseStart = this._startPoint;

    // xy coords of page
    result.absoluteLocation = this._point(data);

    // xy coords relative to our canvas
    result.offsetLocation = result.absoluteLocation
      .minus(this._canvas.absolutePosition)
      .times(this._canvas.inverseScaleFactor);

    // xy coords relative to target
    result.relativeLocation = result.offsetLocation.minus(this._targetLocation);

    // jQuery event object
    result.originalEvent = data;

    return result;
  }

  _point(data) {
    let coords =
      data.type.search(/touch/) > -1
        ? data.originalEvent.changedTouches[0]
        : data;
    return new Point(coords.pageX, coords.pageY);
  }

  _relativePoint(data) {
    return new Point(
      data.offsetX - this._targetLocation.x,
      data.offsetY - this._targetLocation.y,
    );
  }

  _offsetPoint(data) {
    return new Point(data.offsetX, data.offsetY);
  }

  get _targetLocation() {
    return this._target ? this._target.location : new Point(0, 0);
  }

  _isLeftClick(data) {
    return data.button === 0;
  }

  _isRightClick(data) {
    return data.button === 2;
  }

  _isTouchEvent(data) {
    return data.type.search(/touch/) > -1;
  }

  /**
   * Indicates whether the cursor is currently over the target element
   *
   * @returns {boolean}
   */
  get hovering() {
    return this._hovering;
  }

  /**
   * Indicates whether the mouse is being pressed over the target element
   * @returns {boolean}
   */
  get clicking() {
    return this._clicking;
  }

  /**
   * Indicates whether the target element is currently being dragged
   * @returns {boolean}
   */
  get dragging() {
    return this._dragging;
  }

  /**
   * Raised when the target element is clicked
   * @returns {JSEvent}
   */
  get click() {
    return this._click;
  }

  get rightclick() {
    return this._rightclick;
  }

  /**
   * Raised when the target element is double clicked. This will always follow a #click event
   * @returns {JSEvent}
   */
  get doubleclick() {
    return this._doubleclick;
  }

  /**
   * Raised when the target element is triple clicked. This will alwasy follow a click and doubleclick event
   * @return {JSEvent}
   */
  get tripleclick() {
    return this._tripleclick;
  }

  /**
   * Raised when a drag gesture begins on the target element
   * @returns {JSEvent}
   */
  get dragStart() {
    return this._dragStart;
  }

  /**
   * Raised when the cursor moves during a drag of the target element
   * @returns {JSEvent}
   */
  get dragMove() {
    return this._dragMove;
  }

  /**
   * Raised when a drag gesture completes for the target element
   * @returns {JSEvent}
   */
  get dragEnd() {
    return this._dragEnd;
  }

  /**
   * Raised when the cursor moves onto the target element
   * @returns {JSEvent}
   */
  get mouseEnter() {
    return this._mouseEnter;
  }

  /**
   * Raised when the cursor moves off of the target element
   * @returns {JSEvent}
   */
  get mouseLeave() {
    return this._mouseLeave;
  }
}
