'use strict';

import Line from './line';

export default class Highlight extends Line {
  constructor(id, metadata, points, color, width) {
    super(id, metadata, points, color, width);
  }

  static get type() {
    return 'highlight';
  }

  get type() {
    return Highlight.type;
  }

  static get PendingEraseColor() {
    return 'rgba(243, 243, 243, 0.3)';
  }

  get pendingEraseColor() {
    return Highlight.PendingEraseColor;
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {Highlight}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._points = other._points || this._points;
    this._color = other._color || this._color;
    this._width = other._width || this._width;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _points: this._points,
      _color: this._color,
      _width: this._width,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {Highlight}
   */
  fromSnapshot(id, snapshot) {
    return new Highlight(
      id,
      snapshot._metadata,
      snapshot._points,
      snapshot._color,
      snapshot._width,
    );
  }
}
