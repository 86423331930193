'use strict';

import StudentCodeDialogTemplate from './student-code-dialog.html';

export default class StudentCodeDialogController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $state,
    $mdDialog,
    ClassCodeService,
    StudentCacheService,
    RosterService,
    AssignmentWorkService,
    CacheService,
  ) {
    this.$q = $q;
    this.$state = $state;
    this.$mdDialog = $mdDialog;

    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {CacheService} */
    this._cacheService = CacheService;

    /** @type {{names: string[], allowNewMembers: boolean}} */
    this._classCodeData = undefined;
    /** @type {ClassCode} */
    this._classCode = undefined;
    this._code = '';
    this._codeIsValid = false;
    this._hasWork = false;
    this._showError = false;
  }

  static show($mdDialog) {
    return $mdDialog.show({
      controller: StudentCodeDialogController,
      template: StudentCodeDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      fullscreen: true,
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * @return {string}
   */
  get code() {
    return this._code;
  }

  /**
   * @param value {string}
   */
  set code(value) {
    this._code = value;
  }

  /**
   * @return {boolean}
   */
  get codeIsValid() {
    return this._codeIsValid;
  }

  /**
   * @return {boolean}
   */
  get showError() {
    return this._showError;
  }

  codeUpdated(scope) {
    this._reset(scope);

    this.code = this.code.replace(/[\s]/g, '').slice(0, 6).toUpperCase();

    if (this.code.length === 6) {
      this.checkClassCode(scope);
    }
  }

  _reset(scope) {
    scope.enterCodeForm.codeInput.$setValidity('class-code', true);
    scope.enterCodeForm.codeInput.$setValidity('locked', true);
    this._showError = false;

    this._code = this._code.toUpperCase();

    this._codeIsValid = false;
    this._classCodeData = undefined;
    this._classCode = undefined;
    this._user = undefined;
    this._work = undefined;
  }

  checkClassCode(scope) {
    this.$q
      .all({
        classCodeData: this._classCodeService.getUsernames(this._code),
        classCode: this._classCodeService.get(this._code),
        user: this._cacheService.getUser(),
        assignmentsAndWorks:
          this._studentCacheService.getUserAssignmentsAndWorks(),
      })
      .then((results) => {
        this._classCodeData = results.classCodeData;
        this._classCode = results.classCode;
        this._user = results.user;
        this._work = this.findWorkForClassCode(this._classCode, [
          ...results.assignmentsAndWorks.works.values(),
        ]);
        this._hasWork = !!this._work;

        let allowNewMembers =
          this._classCodeData && this._classCodeData.allowNewMembers;
        let hasNameOnRoster =
          this._classCodeData &&
          this._user &&
          this._classCodeData.names.some((name) => name === this._user.name);
        let hideStudentWork =
          this._classCodeData && this._classCodeData.hideStudentWork;

        // If the student has already started working or is on roster or the roster is unlocked
        let canContinue = allowNewMembers || hasNameOnRoster || this._hasWork;

        // The class code is valid if the current student can continue and the assignment is visible (aka not hidden)
        this._codeIsValid = canContinue && !hideStudentWork;

        scope.enterCodeForm.codeInput.$setValidity('locked', this._codeIsValid);
        this._showError = !this._codeIsValid;
      })
      .catch(() => {
        scope.enterCodeForm.codeInput.$setValidity('class-code', false);
        this._showError = true;
      });
  }

  submitCode() {
    if (this._hasWork) {
      this.goToWork(this._work);
    } else {
      this.startWork(this._classCode.assignmentId, this._classCode.rosterId);
    }
  }

  /**
   * @param classCodeData {ClassCode}
   * @param works {AssignmentWork[]}
   *
   * @return {AssignmentWork|undefined}
   */
  findWorkForClassCode(classCodeData, works) {
    return works.find((work) => {
      return (
        work.assignmentId === classCodeData.assignmentId &&
        work.rosterId === classCodeData.rosterId
      );
    });
  }

  /**
   * @param assignmentId {string}
   * @param rosterId {string}
   */
  startWork(assignmentId, rosterId) {
    this._rosterService
      .addSelf(rosterId)
      .then(() => {
        return this._assignmentWorkService.getOrCreateForSelf(
          assignmentId,
          rosterId,
        );
      })
      .then((work) => {
        this.goToWork(work);
      });
  }

  /**
   * @param code {string}
   */
  startAnonWork(code) {
    this._cacheService.getUser().then((user) => {
      this.navigate('root.anon-student-assignment-work', {
        classCode: code,
        name: user.name,
        question: 1,
      });
    });
  }

  /**
   * @param work {AssignmentWork}
   */
  goToWork(work) {
    this.navigate('root.account.student-assignment-work', {
      assignmentWorkId: work.id,
      questionId: work.assignment.questionIdForIndex(0),
    });
  }

  /**
   * @param state {string}
   * @param params {object}
   */
  navigate(state, params) {
    this.$mdDialog.hide();
    this.$state.go(state, params);
  }

  /**
   * @return {string}
   */
  get lockedOrHiddenMessage() {
    if (this._classCodeData && this._classCodeData.hideStudentWork) {
      return 'This assignment is hidden. Please ask your teacher to un-hide it so you can continue.';
    } else {
      return 'The roster for this class code is locked. Please ask your teacher to unlock it so you can continue.';
    }
  }
}
