import ToolbarEmojiMenuTemplate from './toolbar-emoji-menu.component.html';

class ToolbarEmojiMenuController {
  /**
   * @ngInject
   */
  constructor(StorageService, AnalyticsService) {
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
  }

  select(emoji) {
    this._analyticsService.sendEvent({
      eventTag: `asmt:${this.menuType}emojiPicker_selectedEmoji`,
      properties: {
        panel: this.menuType,
        name: emoji.name,
      },
      count: true,
    });

    this.onSelect({ emoji });
  }
}

export default {
  bindings: {
    emojis: '<',
    menuType: '@',
    onSelect: '&',
    transparentButtonText: '@',
  },
  template: ToolbarEmojiMenuTemplate,
  controller: ToolbarEmojiMenuController,
  controllerAs: 'ctrl',
};
