'use strict';

import FirebaseObject from '../firebase-mapping/firebase-object';
import ModelMapping from '../firebase-mapping/model-mapping';
import FirebaseCollectionMapping from '../firebase-mapping/firebase-collection-mapping';
import FirebaseCollection from '../firebase-mapping/firebase-collection';
import QuestionFeedbackCodec from '../codec/question-feedback-codec';
import QuestionFeedback from './question-feedback';
import CollectionObserver from '../firebase-mapping/collection-observer';
import NotificationShardKey from './notification-shard-key.js';
import JSEvent from '../util/js-event';

let mapping = new ModelMapping(
  '/notifications/v3/users/{receiverId}/feedbacks/receivers/works/{assignmentWorkId}',
  {
    questions: new FirebaseCollectionMapping(
      '/questions',
      (instance) => {
        return instance._listen;
      },
      (instance) => {
        return instance.feedbacks;
      },
      new QuestionFeedbackCodec(),
    ),
  },
);

class Observer extends CollectionObserver {
  constructor(feedbackList) {
    super();
    this._feedbackList = feedbackList;
  }

  onAdded(value) {
    this._feedbackList.handleUpdate(value);
  }

  onRemoved(value) {
    this._feedbackList.handleUpdate(value);
  }

  onChanged(value) {
    this._feedbackList.handleUpdate(value);
  }
}

export default class QuestionFeedbackList extends FirebaseObject {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param receiverId {string}
   * @param assignmentWork {AssignmentWork}
   * @param FirebaseService {FirebaseService}
   * @param listen {boolean}
   */
  constructor(
    notificationShardKey,
    receiverId,
    assignmentWork,
    FirebaseService,
    listen = true,
  ) {
    let firebaseInstanceId =
      FirebaseService.firebaseNotificationInstanceIdForShardKey(
        notificationShardKey,
      );
    let traceTag = `QuestionFeedbackList[instance=${firebaseInstanceId}, ${notificationShardKey}]`;

    super(
      new Map()
        .set('receiverId', receiverId)
        .set('assignmentWorkId', assignmentWork.id),
      mapping,
      firebaseInstanceId,
      FirebaseService,
      listen,
      undefined,
      traceTag,
    );

    this._assignmentWork = assignmentWork;

    this._updated = new JSEvent(this);
    this._feedbacks = new FirebaseCollection(QuestionFeedback.merge);
    this._feedbacks.subscribe(new Observer(this));

    this._logTrace('cstr complete');
  }

  /**
   * @return {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  /**
   * @return {FirebaseCollection}
   */
  get feedbacks() {
    return this._feedbacks;
  }

  /**
   * Only returns feedback for questions that are in assignment work
   */
  get validFeedbacks() {
    return this._feedbacks.filter((feedback) => {
      return !!this._assignmentWork.questionForId(feedback.questionId);
    });
  }

  /**
   * @return {number}
   */
  get feedbackCount() {
    return this.validFeedbacks.length;
  }

  /**
   * @param feedback {QuestionFeedback}
   */
  handleUpdate(feedback) {
    this._logTrace('handleUpdate questionId=' + feedback.questionId);
    if (this._assignmentWork.questionForId(feedback.questionId)) {
      this.updated.raise(feedback);
    }
  }
}
