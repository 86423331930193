export default class ScoreUtils {
  static scoreIsValid(score) {
    return angular.isNumber(score) && score <= 100 && score >= 0;
  }

  static formatScore(score) {
    if (!ScoreUtils.scoreIsValid(score)) {
      return null;
    }
    score = Number(score);
    score = Math.max(Math.min(100, score), 0);
    score = Math.round(score * 100) / 100.0;
    return score;
  }
}
