'use strict';

import Point from './point';

export default class Size {
  /**
   * Create a new size
   *
   * @param width {number}
   * @param height {number}
   */
  constructor(width, height) {
    this._width = width;
    this._height = height;
  }

  /**
   * @returns {number}
   */
  get width() {
    return this._width;
  }

  /**
   * @returns {number}
   */
  get height() {
    return this._height;
  }

  /**
   * Creates a new size with the specified dimensions added
   * @param size {Size}
   * @returns {Size}
   */
  plus(size) {
    return new Size(this.width + size.width, this.height + size.height);
  }

  /**
   * Creates a new size with the specified dimensions removed
   * @param size {Size}
   * @returns {Size}
   */
  minus(size) {
    return new Size(this.width - size.width, this.height - size.height);
  }

  /**
   * Creates a new size scaled relative to this by the specified value
   * @param multiplier {number}
   * @returns {Size}
   */
  times(multiplier) {
    return new Size(this.width * multiplier, this.height * multiplier);
  }

  /**
   * Determines the scale required to fit the paramater within this size, preserving aspect ratio.
   * @param size {Size}
   * @returns {number}
   */
  fitScale(size) {
    return Math.min(this.width / size.width, this.height / size.height);
  }

  /**
   * Scales the paramater to fit within this size, preserving aspect ratio.
   * @param size {Size}
   * @returns {Size}
   */
  fit(size) {
    return size.times(this.fitScale(size));
  }

  /**
   * Determines the top left point which would center the parameter size within this size. Assumes the parameter is smaller than this value.
   * @param size {Size}
   * @returns {Point}
   */
  center(size) {
    return this.minus(size).times(0.5).asPoint();
  }

  /**
   * Determines the middle point of this size, if it were a rectangle starting at 0,0
   */
  middle() {
    this.times(0.5).asPoint();
  }

  /**
   * @returns {Point}
   */
  asPoint() {
    return new Point(this.width, this.height);
  }
}
