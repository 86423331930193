'use strict';

import Snap from 'snapsvg-cjs';
import Element from './element';
import Point from '../point';
import Size from '../size';
import RemoveHandle from '../remove-handle';
import GestureManager from '../gesture-manager';
import FormatPlayback from '../../util/format-playback/format-playback';
import HexColors from '../../../css-constants';
import CdnUtils from '../../util/cdn-utils';
import playIcon from '../../../../assets/colored-icons/play.png';
import pauseIcon from '../../../../assets/colored-icons/pause.svg';

export default class AudioClip extends Element {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param location {Point}
   * @param size {Size}
   * @param url {string}
   */
  constructor(id, metadata, location, size, url) {
    super(id, AudioClip.type, metadata);

    this._location = location || new Point(100, 200);
    this._size = size || new Size(250, 55);
    this._url = CdnUtils.urlTransform(url) || '';

    this.formatPlaybackTime = FormatPlayback.formatPlaybackTime;
    this._shadowFilter = Snap.filter.shadow(
      0,
      0,
      3,
      HexColors.CK_DISABLED_GREY,
      1,
    );
  }

  /**
   * @returns {string}
   */
  static get type() {
    return 'audio';
  }

  /**
   * @returns {string}
   */
  get url() {
    return this._url;
  }

  /**
   * @param value {string}
   */
  set url(value) {
    this._url = value;
  }

  /**
   * @returns {Audio}
   */
  get audio() {
    if (!this._audio) {
      this._audio = new Audio(this.url);
      this._audio.oncanplay = () => {
        this.tryUpdate();
      };
    }
    return this._audio;
  }

  get playIconUri() {
    if (this.isPlaying) {
      return pauseIcon;
    }
    return playIcon;
  }

  cleanUp() {
    super.cleanUp();
    this.resetAudio();
  }

  resetAudio() {
    if (this.isPlaying) {
      this.audio.pause();
    }
    this.isPlaying = false;
    this.audio.currentTime = 0;
  }

  hoverIn() {
    this.tryUpdate();
  }

  hoverOut() {
    this.tryUpdate();
  }

  _repositionStart() {
    this._dragStartState = this.snapshot();
  }

  _repositionMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _repositionEnd() {
    this._onChanged(this._dragStartState);
  }

  /**
   * @returns {Boolean}
   */
  get hovering() {
    return (
      this._drag.hovering ||
      this._playIconHover.hovering ||
      this._removeHandle.hovering
    );
  }

  get playbackTime() {
    if (this.audio.currentTime > 0) {
      return this.formatPlaybackTime(this.audio.currentTime);
    }
    return this.formatPlaybackTime(this.audio.duration);
  }

  warnBeforeDeletion() {
    this._background.attr({
      stroke: HexColors.CK_WARN,
    });
  }

  createElement(root, editable, active) {
    this._base = root.group();
    this._interactive = root.group();

    this._touch = this._interactive
      .rect(0, 0, 0, 0)
      .addClass('touch-foreground');

    this._background = this._base.rect(0, 0, 0, 0);
    this._playIcon = this._base.image(this.playIconUri, 0, 0, 0, 0);
    this._textbox = this._base.text(0, 0, '');

    if (active) {
      this._gesture = new GestureManager(this, this.canvas);
      this._gesture._dragThreshold = 0;
      this._gesture.start(this._touch.node);
      this._gesture.click.subscribe(this._toggle, this);
    }
    if (editable) {
      this._removeHandle = new RemoveHandle(this);
      this._removeHandle.render(this._interactive);
      this._removeHandle.mouseEnter.subscribe(this.hoverIn, this);
      this._removeHandle.mouseLeave.subscribe(this.hoverOut, this);

      this._drag = new GestureManager(this, this.canvas);
      this._drag.start(this._touch.node);
      this._drag.click.subscribe(this.focus, this);
      this._drag.dragStart.subscribe(this._repositionStart, this);
      this._drag.dragMove.subscribe(this._repositionMove, this);
      this._drag.dragEnd.subscribe(this._repositionEnd, this);
      this._drag.dragEnd.subscribe(this.focus, this);
      this._drag.mouseEnter.subscribe(this.hoverIn, this);
      this._drag.mouseLeave.subscribe(this.hoverOut, this);

      this._playIconHover = new GestureManager(this, this.canvas);
      this._playIconHover.start(this._playIcon.node);
      this._playIconHover.mouseEnter.subscribe(this.hoverIn, this);
      this._playIconHover.mouseLeave.subscribe(this.hoverOut, this);
    }
  }

  update(root, editable, active) {
    this._background.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      ry: 4,
      fill: HexColors.BACKGROUND_GREY,
      stroke: HexColors.CK_DISABLED_GREY,
      strokeWidth: 2,
      filter: this._background.paper.filter(this._shadowFilter),
    });

    this._playIcon.attr({
      x: this.center.x - 10,
      y: this.center.y - 10,
      width: 20,
      height: 20,
      preserveAspectRatio: 'xMidYMid meet',
      href: this.playIconUri,
      cursor: 'pointer',
    });

    this._touch.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      ry: 4,
      fill: 'transparent',
      cursor: 'pointer',
    });

    this._textbox.attr({
      x: this.location.x + 30,
      y: this.location.y + 15,
      text: this.playbackTime,
      fontFamily: 'GothamRoundedBook',
      dominantBaseline: 'middle',
      fill: HexColors.CK_HEADER_GREY,
      fontSize: 16,
      textAnchor: 'middle',
      pointerEvents: 'none',
    });

    if (editable) {
      this._background.attr({
        stroke:
          this.hovering || this.hasFocus
            ? HexColors.CK_GREEN
            : HexColors.CK_DISABLED_GREY,
      });
      this._removeHandle.location = this.location.plus(
        new Point(this.width, 0),
      );
      this._removeHandle.visibility =
        this.hovering || this.hasFocus ? 'visible' : 'hidden';
    } else if (active) {
      this._touch.attr({
        x: this.location.x,
        y: this.location.y,
        width: this.width,
        height: this.height,
        rx: 4,
        ry: 4,
        fill: 'transparent',
        cursor: 'pointer',
      });
    }
  }

  _toggle() {
    this.focus();
    if (this.audio.paused || this.audio.ended) {
      this.audio.play();
      this.isPlaying = true;

      if (!this.audio.onended) {
        this.audio.onended = () => {
          this.isPlaying = false;
          this.tryUpdate();
          this.audio.onended = undefined;
        };
      }

      if (!this.audio.ontimeupdate) {
        this.audio.ontimeupdate = () => {
          this.tryUpdate();
        };
      }
    } else {
      this.audio.pause();
      this.isPlaying = false;
    }
    this.tryUpdate();
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {AudioClip}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;

    const oldUrl = this._url;
    this._url = other._url || this._url;

    if (this._url !== oldUrl) {
      this._audio = null;
    }

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _url: this._url,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {AudioClip}
   */
  fromSnapshot(id, snapshot) {
    return new AudioClip(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._url,
    );
  }

  remove() {
    this.audio.pause();
    super.remove();
  }
}
