/*global pendo:false */
'use strict';

import AuthService from '../auth/auth.service';

export default class PendoService {
  constructor(environment, AuthService, CacheService) {
    'ngInject';

    this._environment = environment;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
  }

  init() {
    this._cacheService.getSchools().then((schools) => {
      // For teachers that belong to more than one organization,
      // the best we can do is guess which organization is the right one.
      // Here, we take the first one
      let organization;
      if (schools.length) {
        organization = schools[0];
      }

      // Only initialize Pendo if the user is a teacher
      if (this._authService.authData.isTeacher) {
        pendo.initialize({
          visitor: {
            id: this._authService.currentUserId,
          },
          account: {
            id: organization
              ? organization.id
              : this._authService.currentUserId,
            name: organization ? organization.name : 'No organization',
          },
        });
      }
    });
  }
}
