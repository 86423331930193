import BaseImage from './base-image';

export default class CkImage extends BaseImage {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param center {Point} The center of the image
   * @param imageSize {Size} the size of the image on the canvas before scaling and rotation
   * @param scale {number} the relative scale of the image
   * @param rotation {number}
   * @param url {string}
   */
  constructor(id, metadata, center, imageSize, scale, rotation, url) {
    super(
      id,
      CkImage.type,
      metadata,
      center,
      imageSize,
      scale > 0 ? scale : 0.5,
      rotation,
      url,
    );
  }

  static get type() {
    return 'image';
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {CkImage}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._imageSize = other._imageSize || this._imageSize;
    this._rotation =
      other._rotation === 0 ? 0 : other._rotation || this._rotation;

    if (other._scale > 0) {
      this._scale = other._scale;
    } else {
      this._scale = this.scale > 0 ? this.scale : 0.5;
    }

    const oldUrl = this._url;
    this._url = other._url || this._url;

    if (this._url !== oldUrl) {
      if (this._image) {
        this._image.attr({ href: this.url });
      }
      this._preload();
    }

    this._size = this._imageSize.times(this._scale);
    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _imageSize: this._imageSize,
      _scale: this._scale,
      _rotation: this._rotation,
      _url: this._url,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {CkImage}
   */
  fromSnapshot(id, snapshot) {
    return new CkImage(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._imageSize,
      snapshot._scale,
      snapshot._rotation,
      snapshot._url,
    );
  }
}
