import { SessionSettingTypes } from '../../services/mixpanel/mixpanel.service';
import { RosterProperties } from '../../model/domain/roster';

export default class AssignmentRosterItem {
  /**
   * @param roster {Roster}
   * @param classCode {ClassCode}
   * @param onRosterUpdated {Function}
   * @param onClassCodeUpdated {Function}
   * @param analyticsService {AnalyticsService}
   */
  constructor(
    roster,
    classCode,
    onRosterUpdated,
    onClassCodeUpdated,
    analyticsService,
    disabled = false,
    assignmentId = null,
  ) {
    this._roster = roster;
    this._classCode = classCode;
    this._onRosterUpdated = onRosterUpdated;
    this._onClassCodeUpdated = onClassCodeUpdated;
    this._analyticsService = analyticsService;
    this._disabled = disabled;
    this._assignmentId = assignmentId;
  }

  get id() {
    return this._roster.id;
  }

  get name() {
    return this._roster.name;
  }

  get color() {
    return this._roster.color;
  }

  get classCode() {
    return this._classCode;
  }

  get assignmentId() {
    return this._assignmentId;
  }

  get isCleverRoster() {
    return this._roster.properties.provider === RosterProperties.CLEVER;
  }

  get showGrades() {
    return this.classCode && this.classCode.showStudentScores;
  }

  set showGrades(value) {
    this.classCode.showStudentScores = value;
    this._onClassCodeUpdated(this, this._assignmentId);
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.ShowStudentScores,
      value,
    );
  }

  get peerHelpers() {
    return this._roster.allowPeerHelp;
  }

  set peerHelpers(value) {
    this._roster.allowPeerHelp = value;
    this._onRosterUpdated(this._roster);
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.ShowStudentHelpers,
      value,
    );
  }

  get lockStudentWork() {
    return this.classCode && this.classCode.lockStudentWork;
  }

  set lockStudentWork(value) {
    this.classCode.lockStudentWork = value;
    this._onClassCodeUpdated(this, this._assignmentId);
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.LockStudentWork,
      value,
    );
  }

  get hideStudentWork() {
    return this.classCode && this.classCode.hideStudentWork;
  }

  set hideStudentWork(value) {
    this.classCode.hideStudentWork = value;
    this._onClassCodeUpdated(this, this._assignmentId);
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.HideStudentWork,
      value,
    );
  }

  get allowPdf() {
    return this.classCode && this.classCode.allowPdf;
  }

  set allowPdf(value) {
    this.classCode.allowPdf = value;
    this._onClassCodeUpdated(this, this._assignmentId);
    this._analyticsService.sessionSettingToggled(
      SessionSettingTypes.AllowPdf,
      value,
    );
  }
  /**
   * @return {boolean}
   */
  get disabled() {
    return this._disabled;
  }

  /**
   * @param value {boolean}
   */
  set disabled(value) {
    this._disabled = value;
  }
}
