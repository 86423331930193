'use strict';

import Codec from './codec';
import Message from '../domain/message';

export default class MessageCodec extends Codec {
  constructor() {
    super();
  }

  encode(value) {
    if (value) {
      return {
        id: value.id,
        sender_id: value.senderId,
        recipient_id: value.recipientId,
        text: value.text,
        actions: value.actions,
        created: value.createdDate,
        seen: value.seenDate,
        deleted: value.deletedDate,
      };
    }

    return null;
  }

  decode(value) {
    if (value) {
      return new Message(
        value.id,
        value.sender_id,
        value.recipient_id,
        value.text,
        value.actions,
        value.created,
        value.seen,
        value.deleted,
      );
    }

    return null;
  }
}
