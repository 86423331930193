export default class UserSlideBackground {
  constructor(id, type, url) {
    this._id = id;
    this._type = type;
    this._url = url;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get isStockPro() {
    return this._type === UserSlideBackgroundType.StockPro;
  }

  get isStockFree() {
    return this._type === UserSlideBackgroundType.StockFree;
  }

  get isCustom() {
    return this._type === UserSlideBackgroundType.Custom;
  }

  get url() {
    return this._url;
  }
}

export class UserSlideBackgroundType {
  static get StockFree() {
    return 'stock_free';
  }

  static get StockPro() {
    return 'stock_pro';
  }

  static get Custom() {
    return 'custom';
  }
}
