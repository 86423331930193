'use strict';

import FormatPlayback from '../../model/util/format-playback/format-playback';
import moment from 'moment';

export default class AudioTimer {
  constructor($interval) {
    this.$interval = $interval;
    this._formatPlaybackTime = FormatPlayback.formatPlaybackTime;

    this._time = 0;
    this._timer = null;
  }

  /**
   * @returns {number}
   */
  get time() {
    return this._formatPlaybackTime(this._time);
  }

  start() {
    this._startTime = moment();
    this._timer = this.$interval(() => {
      let currentTime = moment();
      this._time = currentTime.diff(this._startTime, 'seconds');
    }, 211);
  }

  stop() {
    if (this._timer) {
      this.$interval.cancel(this._timer);
      this._timer = null;
    }
  }
}
