'use strict';

import Codec from './codec';
import Helper from '../domain/helper';

export default class HelperCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {Helper}
   * @returns {{name: string, role: string, helpee_id: string, assignment_id: string, question_id: string, t: moment}}
   */
  encode(value) {
    return {
      name: value.name,
      role: value.role,
      helpee_id: value.helpeeId,
      assignment_id: value.assignmentId,
      question_id: value.questionId,
      t: value.time && value.time.toISOString(),
    };
  }

  /**
   * @param value
   * @param key
   * @returns {Helper}
   */
  decode(value, key) {
    if (value) {
      return new Helper(
        key,
        value.name,
        value.role,
        value.helpee_id,
        value.assignment_id,
        value.question_id,
        value.t,
      );
    }
  }
}
