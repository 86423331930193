'use strict';

import LinkDialogTemplate from './link-dialog.html';
import Link from '../../model/ui/elements/link';

export default class LinkDialogController {
  constructor($mdDialog, title, url) {
    'ngInject';

    this.$mdDialog = $mdDialog;

    this.linkTitle = title;
    this.linkUrl = url;
  }

  /**
   * @param $mdDialog
   * @param title {string}
   * @param url {string}
   */
  static show($mdDialog, title = '', url = '') {
    return $mdDialog.show({
      controller: LinkDialogController,
      template: LinkDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        title,
        url,
      },
    });
  }

  submit(scope) {
    this.linkUrl = Link.sanitizedUrl(this.linkUrl);

    let isTitleValid = this.isTitleValid;
    scope.addLinkForm.title.$setValidity('required', isTitleValid);

    let isUrlValid = this.isUrlValid;
    scope.addLinkForm.link.$setValidity('url', isUrlValid);

    if (isUrlValid && isTitleValid) {
      let result = {
        title: this.linkTitle,
        url: this.linkUrl,
      };
      this.$mdDialog.hide(result);
    }
  }

  /**
   * Use on the link change to show or hide the error message
   * @param form
   */
  validateUrl(form) {
    form.link.$setValidity('url', this.isUrlValid);
  }

  /**
   * Validates linkUrl
   *
   * @returns {boolean}
   */
  get isUrlValid() {
    const url = Link.sanitizedUrl(this.linkUrl);
    return Link.validateWebUrl(url);
  }

  get isTitleValid() {
    return !!this.linkTitle;
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
