'use strict';

import ContractCodec from '../../model/codec/contract-codec';
import Validation from '../../model/util/validation';
import LocationUtil from '../../model/util/location-util';
import OrganizationService from '../../services/organization/organization.service';
import AdminInvitesTeachersDialog from './admin-invites-teachers-dialog.html';

export default class AdminInvitesTeachersDialogController {
  /**
   * @ngInject
   */

  constructor(
    $q,
    $state,
    $mdDialog,
    $mdToast,
    $location,
    $log,
    AuthService,
    AnalyticsService,
    CacheService,
    StorageService,
    organizationId,
    clickedFrom,
    OrderService,
    OrganizationService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$location = $location;
    this.$log = $log;
    this._organizationId = organizationId;
    this._clickedFrom = clickedFrom;
    this._adminContracts = [];
    this._peers = [];
    this.isValidEmail = Validation.isValidEmail;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {CacheService} */
    this._cacheService = CacheService;

    this._contractCodec = new ContractCodec();

    this._state = this.Loading;
    this._defaultSubject = 'Join your District Classkick Account';
    this._subject = this._defaultSubject;
    this._to = '';
    this.emailRegex = Validation.EmailRegex;
    this._body = '';
    this._internalEmailCc = ['pro@classkick.com'];
    this._selectAllCheckboxInactiveUsers = true;
    this._selectAllCheckboxMDRUsers = true;
    this._checkedCount = 0;
    this._toastMessage;
    this._invalidEmails = '';
    this._formError = false;
    this._selectionError = false;

    this._contractId = '';
    this._schools = null;
    this._orgName = '';
    this._organizationMdrPromises = [];
    this._adminUser = {};

    this._usersInactiveMonths = 3;

    this._inactiveUsersAndMdrUsersInOrgHolder = [];
    this._mdrEmailsInOrg = [];
    this._mdrEmailsNotInContract = [];
    this._inactiveUserEmailsInContract = [];
    this._allUserEmailsInContract = [];
    this._activeUserStr = '';

    // manually create a contract to represent All Schools dropdown
    this._stubAllSchoolContractObj = {
      name: 'All Schools',
      id: 'all_schools_id',
    };
    // create Contract object
    this._stubAllSchoolContract = this._contractCodec.decode(
      this._stubAllSchoolContractObj,
    );

    this.configureAdminDisplay(true);

    this._analyticsService.remindInactiveTeachersDialogOpened(clickedFrom);
  }

  configureAdminDisplay(defaultChooseAllSchools = true) {
    if (defaultChooseAllSchools === false) {
      this._state = this.Loading;

      this.$q
        .all({
          adminUser: this._cacheService.getUser(),
          allUsersInContractMap: this._cacheService.getUsersForContract(
            this._contractId,
          ),
          contracts: this._cacheService.getContracts(),
          inactiveUsersInContractMap:
            this._cacheService.getInactiveUsersForContract(
              this._contractId,
              this._usersInactiveMonths,
            ),
          mdrUsersInOrgMap: this._organizationService.getMdrPeers(
            this._organizationId,
          ),
          schools: this._cacheService.getSchools(),
          usageStats: this._organizationService.getUsageStatsWithAuth(
            this._organizationId,
          ),
        })
        .then(
          ({
            adminUser,
            allUsersInContractMap,
            contracts,
            inactiveUsersInContractMap,
            mdrUsersInOrgMap,
            schools,
            usageStats,
          }) => {
            this._schools = schools;
            this._adminUser = adminUser;

            // ui dropdown of list of adminContracts
            const adminContracts = contracts.filter((contract) => {
              return (
                contract.isPro &&
                this._authService.authData.isContractAdmin(contract.id)
              );
            });
            this._adminContracts = [
              this._stubAllSchoolContract,
              ...adminContracts,
            ];

            this._selectedContract = contracts.find(
              (contract) => contract.id === this._contractId,
            );

            this._orgName = this._schools.find(
              (school) => school._id === this._organizationId,
            )._name;
            this._subject = `${this._defaultSubject} for ${this._orgName}`;

            this._inactiveUsersInContract = Array.from(
              inactiveUsersInContractMap.values(),
            );
            this._allUsersInContract = Array.from(
              allUsersInContractMap.values(),
            );

            this._allUserEmailsInContract = this._allUsersInContract.map(
              (user) => user.email,
            );

            this._mdrEmailsInOrg = mdrUsersInOrgMap.map((user) => user.email);

            this._inactiveUserEmailsInContract =
              this._inactiveUsersInContract.map((user) => user.email);

            this._mdrEmailsNotInContract = this._mdrEmailsInOrg
              .map((x) => x.toLowerCase())
              .filter(
                (x) =>
                  !this._allUserEmailsInContract
                    .map((email) => email.toLowerCase())
                    .includes(x),
              );
            this._inactiveUsersAndMdrUsersInOrgHolder =
              this._inactiveUserEmailsInContract.concat(
                this._mdrEmailsNotInContract,
              );

            this._activeUserStr = '';
            const activeUserStrText =
              'is one of the most active teachers using Classkick effectively; hope you will join them, too!';
            if (usageStats.activeTeachers.length) {
              this._activeUserStr = ` ${usageStats.activeTeachers[0].first_name} ${activeUserStrText}`;
            }

            this._body = this.defaultFormatBody();
            this._state = this.DraftEmail;
            this._to = this.selectEmailDisplay();
          },
        )
        .catch((error) => {
          this.$log.error(error);
          this.state = this.showError;
        });
    } else {
      this._state = this.Loading;

      this.$q
        .all({
          adminUser: this._cacheService.getUser(),
          contracts: this._cacheService.getContracts(),
          inactiveUsersMap: this._cacheService.getInactiveUsersForContracts(
            this._usersInactiveMonths,
          ),
          organizations: this._cacheService.getOrganizations(),
          schools: this._cacheService.getSchools(),
          usersMap: this._cacheService.getUsersForContracts(),
        })
        .then(
          ({
            adminUser,
            contracts,
            inactiveUsersMap,
            organizations,
            schools,
            usersMap,
          }) => {
            this._schools = schools;
            this._adminUser = adminUser;
            this._subject = this._defaultSubject;
            this._orgName = '';

            // check if organizations have a parent organization
            let parentOrgIds = this._schools
              .filter(
                (school) =>
                  school.parentOrganizationId &&
                  school.parentOrganizationId.trim() !== '',
              )
              .map((school) => school.parentOrganizationId);

            // set parent org name if all schools have the same parent org id
            if (
              parentOrgIds.length > 0 &&
              parentOrgIds.every((id) => id === parentOrgIds[0])
            ) {
              this._organizationService
                .get(parentOrgIds[0])
                .then((parentOrg) => {
                  this._orgName = parentOrg.name;
                  this._subject = `${this._defaultSubject} for ${this._orgName}`;
                });
            }

            // ui dropdown of list of adminContracts
            const adminContracts = contracts.filter((contract) => {
              return (
                contract.isPro &&
                this._authService.authData.isContractAdmin(contract.id)
              );
            });
            this._adminContracts = [
              this._stubAllSchoolContract,
              ...adminContracts,
            ];

            this._selectedContract = this._adminContracts[0];

            this._allUsersInContract = Array.from(usersMap.values());
            this._inactiveUsersInContract = Array.from(
              inactiveUsersMap.values(),
            );

            this._allUsersInContract.forEach((user) => {
              let dataHolder = Array.from(user.values());
              dataHolder.forEach((user) => {
                this._allUserEmailsInContract.push(user.email);
              });
            });

            this._inactiveUsersInContract.forEach((user) => {
              let dataHolder = Array.from(user.values());
              dataHolder.forEach((user) => {
                this._inactiveUserEmailsInContract.push(user.email);
              });
            });

            // mdr promises on all contracts
            this._organizationMdrPromises = organizations.map((org) =>
              this.getAllOrgMdrEmails(org.id),
            );

            if (this._organizationMdrPromises.length) {
              return Promise.allSettled(this._organizationMdrPromises)
                .then((mdrs) => {
                  mdrs.forEach((mdr) => {
                    if (mdr.status === 'fulfilled' && mdr.value.length) {
                      this._mdrEmailsInOrg.push(
                        mdr.value.map((mdrData) => mdrData.email),
                      );
                    }
                  });
                })
                .then(() => {
                  this._inactiveUserEmailsInContract = [
                    ...new Set(this._inactiveUserEmailsInContract),
                  ];
                  const mdrEmailsNotInContract = this._mdrEmailsInOrg
                    .flat()
                    .filter((x) => !this._allUserEmailsInContract.includes(x));
                  this._mdrEmailsNotInContract = [
                    ...new Set(
                      mdrEmailsNotInContract.map((email) =>
                        email.toLowerCase(),
                      ),
                    ),
                  ];
                })
                .catch((error) => {
                  this.$log.error(error);
                  this.state = this.showError;
                });
            }
          },
        )
        .catch((error) => {
          this.$log.error(error);
          this.state = this.showError;
        })
        .finally(() => {
          this._inactiveUsersAndMdrUsersInOrgHolder =
            this._inactiveUserEmailsInContract.concat(
              this._mdrEmailsNotInContract,
            );
          this._body = this.defaultFormatBody();
          this._state = this.DraftEmail;
          this._to = this.selectEmailDisplay();
        });
    }
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  /**
   * @return {string}
   */
  get invalidEmails() {
    return this._invalidEmails;
  }

  /**
   * @param value {string}
   */
  set invalidEmails(value) {
    this._invalidEmails = value;
  }

  get showError() {
    return 'error';
  }

  get DraftEmail() {
    return 'draft_email';
  }

  get EmailSent() {
    return 'email_sent';
  }

  get EmailError() {
    return 'email_error';
  }

  get Loading() {
    return 'loading';
  }

  get selectAllCheckboxInactiveUsers() {
    return this._selectAllCheckboxInactiveUsers;
  }

  set selectAllCheckboxInactiveUsers(value) {
    this._selectAllCheckboxInactiveUsers = value;
  }

  get selectAllCheckboxMDRUsers() {
    return this._selectAllCheckboxMDRUsers;
  }

  set selectAllCheckboxMDRUsers(value) {
    this._selectAllCheckboxMDRUsers = value;
  }

  get formError() {
    return this._formError;
  }

  set formError(value) {
    this._formError = value;
  }

  get selectionError() {
    return this._selectionError;
  }

  set selectionError(value) {
    this._selectionError = value;
  }

  get subject() {
    return this._subject;
  }

  set subject(value) {
    this._subject = value;
  }

  get to() {
    return this._to;
  }

  set to(value) {
    this._to = value;
  }

  get body() {
    return this._body;
  }

  set body(value) {
    this._body = value;
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = this.to;
    if (!Array.isArray(this.to)) {
      emails = this.to.split(/[,;]/);
    }

    let isValid;

    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  copyInviteText(elemCopied) {
    let copiedElemHolder = '';
    switch (elemCopied) {
      case 'emailBodyCopy':
        copiedElemHolder = this.body;
        break;
      case 'emailToCopy':
        copiedElemHolder = this._to;
        break;
    }

    navigator.clipboard
      .writeText(copiedElemHolder)
      .then(() => {
        this.displayToastMessage('Successfully copied to clipboard');
        this._analyticsService.adminInviteTextCopied(
          this._clickedFrom,
          elemCopied,
        );
      })
      .catch((error) => {
        this.$log.error(error);
        this.displayToastMessage('Sorry there was an error copying message');
      });
  }

  setValidSubjectLine(scope) {
    this.invalidSubjectLine = !this._subject.length > 0;
    scope.referralForm.subjectInput.$setValidity(
      'pattern',
      this._subject.length > 0,
    );
  }

  getAllOrgMdrEmails(orgId) {
    return this._organizationService.getMdrPeers(orgId).then((mdrs) => {
      return mdrs;
    });
  }

  selectEmailDisplay() {
    this._selectionError = false;
    let emailHolder = [];

    this._analyticsService.remindInactiveTeachersDialogEmailsCheckbox(
      this._clickedFrom,
    );

    if (
      this._selectAllCheckboxInactiveUsers === false &&
      this._selectAllCheckboxMDRUsers === true
    ) {
      emailHolder = this._mdrEmailsNotInContract;
    } else if (
      this._selectAllCheckboxInactiveUsers === true &&
      this._selectAllCheckboxMDRUsers === false
    ) {
      emailHolder = this._inactiveUserEmailsInContract;
    } else if (
      this._selectAllCheckboxInactiveUsers === true &&
      this._selectAllCheckboxMDRUsers === true
    ) {
      emailHolder = this._inactiveUsersAndMdrUsersInOrgHolder;
    }

    return (this._to = emailHolder);
  }

  selectAllInactiveUsersInfo() {
    let fullCount = this._inactiveUserEmailsInContract
      ? this._inactiveUserEmailsInContract.length
      : '';
    let labelText = '';
    if (fullCount) {
      labelText = `Invite All Recently Inactive Teachers (${fullCount})`;
    }
    return labelText;
  }

  selectAllMDRUsersInfo() {
    let fullCount = this._mdrEmailsNotInContract
      ? this._mdrEmailsNotInContract.length
      : '';
    let labelText = '';
    if (fullCount) {
      labelText = `Invite All Teachers Not In Classkick (${fullCount})`;
    }
    return labelText;
  }

  shouldDashmarkAppear() {
    return this._checkedCount > 0 && this._checkedCount < this._peers.length;
  }

  clearSearch() {
    this.formError = false;
  }

  formatBody() {
    return this.body.split('\n').join('<br>');
  }

  submitForm(scope) {
    if (!this.isEmailListValid) {
      if (scope.referralForm.toInput) {
        scope.referralForm.toInput.$setValidity('pattern', false);
      }
      return (this._invalidEmails = !this.isEmailListValid);
    }

    if (this.invalidSubjectLine) {
      return this.setValidSubjectLine(scope);
    } else if (!this.body) {
      return;
    }

    this.sendReferral();
  }

  sendReferral() {
    this._subject = this.subject;

    if (this.body === this.defaultFormatBody()) {
      this._body = this.defaultFormatBodyForEmail();
    } else {
      this._body = `${this.formatBody()}<br><br>${this.emailSignUpReferralLink()}<br><br>${this._adminUser.name}<br>${this._orgName}`;
    }

    let emailsTo = this.to;
    if (!Array.isArray(this.to)) {
      emailsTo = this.to.split(/[,;]/);
    }

    let order = { id: '000000' };

    this._state = this.Loading;
    this._fromName = `${this._adminUser.firstLastCombo} via Classkick`;
    this._from = this._adminUser.email;

    this._orderService
      .share(
        order.id,
        this._from,
        [],
        this._internalEmailCc,
        this._subject,
        this._body,
        this._fromName,
        {
          user_id: this._adminUser ? this._adminUser.id : null,
          event_tag: `${this._clickedFrom}:remindInactiveTeachersDialog_sent`,
        },
        emailsTo,
      )
      .then((data) => {
        this.displayToastMessage('You invited your colleagues to Classkick!');
        this.$mdDialog.cancel();
        this._state = this.EmailSent;

        this._analyticsService.remindInactiveTeachersDialogReferralSent(
          this._clickedFrom,
          this._contractId,
          emailsTo.length,
        );
      })
      .catch(() => {
        this._state = this.EmailError;
      });
  }

  setEmailValidity(scope) {
    this.invalidEmails = false;
    scope.referralForm.toInput.$setValidity('pattern', this.isEmailListValid);
  }

  displayToastMessage(message) {
    this._toastMessage = message;
    let toastClassName = 'referral-toast';
    const config = this.$mdToast
      .simple(this._toastMessage)
      .action(' ')
      .actionKey('x')
      .position('top right')
      .toastClass(toastClassName)
      .hideDelay(4000);
    this.$mdToast.show(config);
  }

  /**
   * show the dialog
   * @param  {$mdDialog}  $mdDialog
   * @param  {organizationId} {string}
   * @return {Promise}
   */
  static show($mdDialog, organizationId, clickedFrom) {
    let config = {
      controller: AdminInvitesTeachersDialogController,
      template: AdminInvitesTeachersDialog,
      controllerAs: 'ctrl',
      autoWrap: false,
      clickOutsideToClose: true,
      focusOnOpen: false,
      locals: {
        organizationId: organizationId,
        clickedFrom,
      },
    };

    return $mdDialog.show(config);
  }
  /**
   * close the dialog. rejects the promise returned from the show method
   */
  cancel() {
    this._analyticsService.remindInactiveTeachersDialogDismissed(
      this._clickedFrom,
    );
    this.$mdDialog.cancel();
  }

  selectContract(contract) {
    this._contractId = contract.id;
    this._selectedContract = this._adminContracts.find(
      (contract) => contract.id === this._contractId,
    );

    this._analyticsService.remindInactiveTeachersDialogEmailsContractDropdown(
      this._clickedFrom,
    );

    if (contract.name !== this._stubAllSchoolContractObj.name) {
      this._school = this._schools.find(
        (school) => school.contractId === this._contractId,
      );
      this._organizationId = this._school.id;
      this.configureAdminDisplay(false);
    } else {
      this._school = this._stubAllSchoolContractObj;
      this.configureAdminDisplay(true);
    }
  }

  defaultFormatBody() {
    let activeUserStr = this._activeUserStr ? this._activeUserStr : '';
    return `Hi there, \n\nGreat news - our school ${this._orgName} has upgraded to Classkick Pro and you now have unlimited access!${activeUserStr}\n\nJoin Classkick Pro here - ${LocationUtil.absRootUrl(this.$location)}/signup/teacher\n\nWe upgraded because Classkick lets you see your students thinking in real-time, engages kids, saves you time, and much more. Here's a video https://youtu.be/l5vza3HTW5I. Many teachers in our school love it, and I think you will too. Let me know if you need any support getting started or want to connect with a teacher who is already using it a lot.\n \n${this._adminUser.name}\n${this._orgName}`;
  }

  // format body so that the email template shows hyperlinks
  defaultFormatBodyForEmail() {
    let activeUserStr = this._activeUserStr ? this._activeUserStr : '';
    let referralSignUpLink = this.emailSignUpReferralLink();

    return `Hi there, <br><br>Great news - our school ${this._orgName} has upgraded to Classkick Pro and you now have unlimited access!${activeUserStr}<br><br>${referralSignUpLink}<br><br>We upgraded because Classkick lets you see your students thinking in real-time, engages kids, saves you time, and much more. Here's a video https://youtu.be/l5vza3HTW5I. Many teachers in our school love it, and I think you will too. Let me know if you need any support getting started or want to connect with a teacher who is already using it a lot.<br><br>${this._adminUser.name}<br>${this._orgName}`;
  }

  emailSignUpReferralLink() {
    let utmSource = this._adminUser.id;
    let utmMedium = 'email';
    let utmCampaign = 'admin_invites_teachers_dialog';
    let activeUserStr = this._activeUserStr ? this._activeUserStr : '';
    if (!this._school) {
      this._school = this._stubAllSchoolContractObj;
    }

    return `<a href="${LocationUtil.absRootUrl(this.$location)}/signup/teacher?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${this._school.id}" target="_blank">Join Classkick Pro here.</a>`;
  }
}
