'use strict';

// import ContractCodec from '../../model/codec/contract-codec';

export default class FreeTrialService {
  constructor(environment, HttpService) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;

    // this._contractCodec = new ContractCodec();
  }

  _selectOrganization(orgs) {
    // if there are no orgs, return undefined
    if (orgs.length === 0) {
      return undefined;
    }

    // if there is only one org, use that
    if (orgs.length === 1) {
      return orgs[0];
    }

    // if there are more than one, and one clearly has more teachers, use that
    // if there's a tie, pick the last one on the list
    let orgIndex = orgs.length - 1;
    let mostTeachers = orgs[orgIndex].members.length;
    for (let i = orgIndex; i > -1; i--) {
      let theseTeachers = orgs[i].members.length;
      if (theseTeachers > mostTeachers) {
        orgIndex = i;
        mostTeachers = theseTeachers;
      }
    }

    return orgs[orgIndex];
  }

  /**
   * @param orgId {string}
   * @return {Promise}
   */
  createFreeTrial(orgId) {
    return this._httpService.authPost(
      this._uri('/v1/contracts/free-trial'),
      orgId,
    );
  }

  /**
   * @param orgId {string}
   * @return {Promise} - data in the shape of { displayTrialButton: boolean }
   */
  checkAvailability(orgId) {
    return this._httpService.authGet(
      this._uri(`/v1/users/trial-button/${orgId}`),
    );
  }

  /**
   * Returns a string that specifies a uri given a path.
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
