'use strict';

import moment from 'moment';
export default class Helper {
  constructor(id, name, role, helpeeId, assignmentId, questionId, time) {
    this._id = id;
    this._name = name;
    this._role = role;
    this._helpeeId = helpeeId;
    this._assignmentId = assignmentId;
    this._questionId = questionId;
    this._time = moment(time);
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get role() {
    return this._role;
  }

  get helpeeId() {
    return this._helpeeId;
  }

  get assignmentId() {
    return this._assignmentId;
  }

  get questionId() {
    return this._questionId;
  }

  get time() {
    return this._time;
  }

  resetTime() {
    this._time = moment();
  }
}
