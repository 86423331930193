'use strict';

import Element from './element';
import Size from '../size';
import GestureManager from '../gesture-manager';
import Point from '../point';
import ResizeHandle from '../resize-handle';
import RemoveHandle from '../remove-handle';
import HexColors from '../../../css-constants';
import TextboxBase from './textbox-base';
import StaticService from '../../../services/static/static.service';
import { FitbAnswerTypes } from '../../../components/assignment-toolbar/assignment-toolbar.directive';

export class FillInTheBlankAnswer {
  /**
   * @param answer {string}
   * @param points {float}
   * @param selected {boolean}
   */
  constructor(answer, points = null, selected = false) {
    this._answer = answer;
    this._points = points;
    this._correct = true;
    this._selected = selected;
  }

  get points() {
    return this._points;
  }

  set points(value) {
    this._points = value;
  }

  get correct() {
    return this._correct;
  }

  set correct(value) {
    this._correct = value;
  }

  get answer() {
    return this._answer;
  }

  set answer(value) {
    this._answer = value;
  }

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
  }

  /**
   * @return {FillInTheBlankAnswer}
   */
  clone() {
    return new FillInTheBlankAnswer(this._answer, this._points, this._selected);
  }
}

export default class FillInTheBlankParent extends Element {
  constructor(
    id,
    metadata,
    location,
    size,
    answers,
    fontSize,
    format = FitbAnswerTypes.PLAIN.value,
  ) {
    super(id, FillInTheBlankParent.type, metadata);

    this._location = location;
    this._answers = answers || [];
    this._fontSize = fontSize || 25;
    this._padding = new Size(7, 7);
    this._lineHeightScale = StaticService.get.isWindows ? 1 : 1.2;
    this._initialBoxHeight = 50;
    this._minSize = new Size(
      40,
      TextboxBase.FONT_SIZE_MIN * this._lineHeightScale +
        this._padding.height +
        this._padding.height,
    );
    this._size = size || new Size(8 * this._fontSize, this._initialBoxHeight);
    this._format = format;
  }

  /**
   * @return {string}
   */
  static get type() {
    return 'fitb_parent';
  }

  get typeDisplay() {
    return 'fill in the blank';
  }

  /**
   * @return {boolean}
   */
  get isParentManipulative() {
    return true;
  }

  get answerDisplay() {
    return this._answers
      .filter((answer) => answer.correct)
      .map((answer) => answer.answer)
      .join(', ');
  }

  /**
   * @return {FillInTheBlankAnswer[]}
   */
  get answers() {
    return this._answers;
  }

  /**
   * @param value {FillInTheBlankAnswer[]}
   */
  set answers(value) {
    this._trackChange(() => {
      this._answers = value;
    });
  }

  /**
   * @return {string}
   */
  get fontSize() {
    return this._fontSize;
  }

  /**
   * @param {string}
   */
  set fontSize(value) {
    this._fontSize = value;
  }

  /**
   * @return {string}
   */
  get format() {
    return this._format;
  }

  /**
   * @param {string}
   */
  set format(value) {
    this._format = value;
  }

  createElement(root) {
    this._background = root.group().addClass('background');
    this._content = root.group().addClass('content');
    this._interactive = root.group().addClass('interactive');

    this._backgroundRect = this._background.rect(0, 0, 0, 0);
    this._contentRect = this._content.rect(0, 0, 0, 0);
    this._interactRect = this._interactive
      .rect(0, 0, 0, 0)
      .addClass('touch-foreground');

    this._removeHandle = new RemoveHandle(this);
    this._removeHandle.render(this._interactive);
    this._removeHandle.mouseEnter.subscribe(this.hoverIn, this);
    this._removeHandle.mouseLeave.subscribe(this.hoverOut, this);

    this._resizers = {
      topLeft: this.createHandle(this._interactive, 1, 1, -1, -1),
      bottomLeft: this.createHandle(this._interactive, 1, 0, -1, 1),
      bottomRight: this.createHandle(this._interactive, 0, 0, 1, 1),
    };

    this._drag = new GestureManager(this, this.canvas);
    this._drag.start(this._interactRect.node);
    this._drag.click.subscribe(this._click, this);
    this._drag.mouseEnter.subscribe(this.hoverIn, this);
    this._drag.mouseLeave.subscribe(this.hoverOut, this);
    this._drag.dragStart.subscribe(this._dragStart, this);
    this._drag.dragMove.subscribe(this._dragMove, this);
    this._drag.dragEnd.subscribe(this._dragEnd, this);
  }

  createHandle(layer, scaleX, scaleY, scaleWidth, scaleHeight) {
    let result = new ResizeHandle(
      this,
      scaleX,
      scaleY,
      scaleWidth,
      scaleHeight,
    );
    result.resizeStarted.subscribe(this._dragStart, this);
    result.resizeComplete.subscribe(this._dragEnd, this);
    result.size = new Size(14, 14);
    result.render(layer);

    return result;
  }

  update(root, editable) {
    const height = Math.max(this.height, this._minSize.height);

    const locationAndSize = {
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height,
    };

    let backgroundFill = 'rgba(255, 255, 255, 0)';
    if (this.hasFocus) {
      backgroundFill = 'rgba(255, 255, 255, .9)';
    } else if (this._drag.dragging) {
      backgroundFill = 'rgba(255, 255, 255, .25)';
    } else if (this.hovering) {
      backgroundFill = 'rgba(255, 255, 255, .5)';
    }

    this._backgroundRect.attr({
      ...locationAndSize,
      fill: backgroundFill,
    });

    this._contentRect.attr({
      ...locationAndSize,
      fill: 'transparent',
      stroke: HexColors.CK_GREEN,
      strokeWidth: '2px',
      strokeDasharray: '5,5',
    });

    if (editable) {
      this._interactRect.attr({
        ...locationAndSize,
        fill: 'transparent',
        cursor: 'move',
      });
    }

    this._removeHandle.location = this.location.plus(new Point(this.width, 0));
    this._removeHandle.visibility =
      editable && (this.hasFocus || this.hovering) ? 'visible' : 'hidden';

    this._resizers.topLeft.location = this.location;
    this._resizers.bottomLeft.location = this.location.plus(
      new Point(0, height),
    );
    this._resizers.bottomRight.location = this.location.plus(
      new Point(this.width, height),
    );

    this._resizers.topLeft.visibility = this.hasFocus ? 'inherit' : 'hidden';
    this._resizers.bottomLeft.visibility = this.hasFocus ? 'inherit' : 'hidden';
    this._resizers.bottomRight.visibility = this.hasFocus
      ? 'inherit'
      : 'hidden';

    this._resizers.topLeft.tryUpdate();
    this._resizers.bottomLeft.tryUpdate();
    this._resizers.bottomRight.tryUpdate();
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {FillInTheBlankParent}
   * @param forceUpdate {boolean}
   */
  merge(other, forceUpdate) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;
    // Use other answers when undoing/redoing (aka forceUpdate), but not when getting round-trip update from web services
    this._answers =
      angular.isDefined(other._answers) && forceUpdate
        ? other._answers
        : this._answers;
    this._fontSize = other._fontSize || this._fontSize;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _answers: this._answers,
      _fontSize: this._fontSize,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {FillInTheBlankParent}
   */
  fromSnapshot(id, snapshot) {
    return new FillInTheBlankParent(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._answers,
      snapshot._fontSize,
      snapshot._format,
    );
  }

  /**
   * Click handler
   */
  _click() {
    this.focus();
  }

  warnBeforeDeletion() {
    this._contentRect.attr({
      fill: 'rgba(255, 255, 255, .9)',
      stroke: HexColors.CK_WARN,
    });
    this._resizers.topLeft.warnBeforeDeletion();
    this._resizers.bottomLeft.warnBeforeDeletion();
    this._resizers.bottomRight.warnBeforeDeletion();
  }

  hoverIn() {
    this.tryUpdate();
  }

  hoverOut() {
    this.tryUpdate();
  }

  remove() {
    this._stopEvents();
    super.remove();
  }

  _stopEvents() {
    if (this._removeHandle) {
      this._removeHandle.remove();
    }
    if (this._drag) {
      this._drag.stop();
    }
    if (this._resizers) {
      this._resizers.topLeft.remove();
      this._resizers.bottomLeft.remove();
      this._resizers.bottomRight.remove();
    }
  }

  _dragStart() {
    this._dragStartState = this.snapshot();
  }

  _dragMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _dragEnd() {
    this._onChanged(this._dragStartState);
  }

  /**
   * @returns {Boolean}
   */
  get hovering() {
    return this._drag.hovering || this._removeHandle.hovering;
  }
}
