'use strict';

import AssigmentToolBarItemTemplate from './assignment-toolbar-item.html';

export default function AssignmentToolbarItemDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: false,
    template: AssigmentToolBarItemTemplate,
  };
}
