'use strict';

import Notification from './notification';
import NotificationShardKey from './notification-shard-key.js';
import JSEvent from '../util/js-event';
import HelpRequest from './help-request';

export class HelpRequestUpdateNotifier {
  start() {
    throw new Error('not implemented');
  }

  stop() {
    throw new Error('not implemented');
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get created() {
    throw new Error('not implemented');
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get resolved() {
    throw new Error('not implemented');
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get canceled() {
    throw new Error('not implemented');
  }
}

export class MultiHelpRequestUpdateNotifier extends HelpRequestUpdateNotifier {
  /**
   *
   * @param notifications {[HelpRequestUpdateNotifier|HelpRequestNotification]}
   */
  constructor(notifications) {
    super();

    this._notifications = notifications;

    this._created = new JSEvent(this);
    this._resolved = new JSEvent(this);
    this._canceled = new JSEvent(this);

    this._notifications.forEach((x) => {
      x.created.subscribe(this.created.raise, this.created);
      x.resolved.subscribe(this.resolved.raise, this.resolved);
      x.canceled.subscribe(this.canceled.raise, this.canceled);
    });
  }

  start() {
    this._notifications.forEach((x) => {
      x.start();
    });
  }

  stop() {
    this._notifications.forEach((x) => {
      x.stop();
    });
  }

  destroy() {
    this._notifications.forEach((x) => {
      x.created.unsubscribe(this.created.raise, this.created);
      x.resolved.unsubscribe(this.resolved.raise, this.resolved);
      x.canceled.unsubscribe(this.canceled.raise, this.canceled);
    });
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get created() {
    return this._created;
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get resolved() {
    return this._resolved;
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get canceled() {
    return this._canceled;
  }
}

export default class HelpRequestNotification extends Notification /*, HelpRequestUpdateNotifier*/ {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param url {string}
   * @param FirebaseService {FirebaseService}
   * @param showInitialNotification {boolean}
   */
  constructor(
    notificationShardKey,
    url,
    FirebaseService,
    showInitialNotification,
  ) {
    super(
      Notification.V3,
      notificationShardKey,
      url,
      FirebaseService,
      showInitialNotification,
      'HelpRequestNotification',
    );

    /** @type {HelpRequestService} */
    this._helpRequestService = null;

    this._created = new JSEvent(this);
    this._resolved = new JSEvent(this);
    this._canceled = new JSEvent(this);
    this._value = null;

    this._logTrace('cstr complete');
  }

  /**
   * @param value {HelpRequestService}
   */
  set helpRequestService(value) {
    this._helpRequestService = value;
  }

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param assignmentId {string}
   * @param FirebaseService {FirebaseService}
   * @param showInitialNotification {boolean}
   * @returns {HelpRequestNotification}
   */
  static forAssignment(
    notificationShardKey,
    assignmentId,
    FirebaseService,
    showInitialNotification,
  ) {
    return new HelpRequestNotification(
      notificationShardKey,
      `assignments/${assignmentId}/help_requests`,
      FirebaseService,
      showInitialNotification,
    );
  }

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param rosterId {string}
   * @param FirebaseService {FirebaseService}
   * @param showInitialNotification {boolean}
   * @returns {HelpRequestNotification}
   */
  static forRoster(
    notificationShardKey,
    rosterId,
    FirebaseService,
    showInitialNotification,
  ) {
    return new HelpRequestNotification(
      notificationShardKey,
      `rosters/${rosterId}/help_requests`,
      FirebaseService,
      showInitialNotification,
    );
  }

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param FirebaseService {FirebaseService}
   * @param showInitialNotification {boolean}
   * @returns {HelpRequestNotification}
   */
  static forPeers(
    notificationShardKey,
    assignmentId,
    rosterId,
    FirebaseService,
    showInitialNotification,
  ) {
    return new HelpRequestNotification(
      notificationShardKey,
      `assignments/${assignmentId}/help_requests_peers/rosters/${rosterId}`,
      FirebaseService,
      showInitialNotification,
    );
  }

  static get CREATED() {
    return 'created';
  }

  static get RESOLVED() {
    return 'resolved';
  }

  static get CANCELED() {
    return 'canceled';
  }

  /**
   * @returns {JSEvent}
   */
  get created() {
    return this._created;
  }

  /**
   * @returns {JSEvent}
   */
  get resolved() {
    return this._resolved;
  }

  /**
   * @returns {JSEvent}
   */
  get canceled() {
    return this._canceled;
  }

  _changed(value) {
    if (value) {
      const item = new HelpRequest(
        value.help_request_id,
        value.helpee_id,
        null,
        null,
        value.question_id,
        value.request_type,
      );

      this._logTrace(`_changed value=${angular.toJson(value)}`);

      if (value.change === HelpRequestNotification.CANCELED) {
        this._canceled.raise(item);
      } else {
        if (this._helpRequestService) {
          this._helpRequestService
            .get(value.help_request_id)
            .then((helpRequest) => {
              if (value.change === HelpRequestNotification.CREATED) {
                this._created.raise(helpRequest);
              } else if (value.change === HelpRequestNotification.RESOLVED) {
                this._resolved.raise(helpRequest);
              }
            });
        } else {
          if (value.change === HelpRequestNotification.CREATED) {
            this._created.raise(item);
          } else if (value.change === HelpRequestNotification.RESOLVED) {
            this._resolved.raise(item);
          }
        }
      }
    }
  }
}
