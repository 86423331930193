import SvgCanvasLayers from './svg-canvas-layers';

export default class SvgSheetLayers extends SvgCanvasLayers {
  /**
   * @param snap {Snap}
   */
  constructor(snap) {
    super(snap);

    // Interactive layers that are unique to assignment sheets
    this._lineCapture = undefined;
    this._newLine = undefined;
    this._panCapture = undefined;
    this._placementCapture = undefined;
  }

  get lineCapture() {
    if (!this._lineCapture) {
      this._lineCapture = this._snap.select('.root .lineCapture');
    }
    return this._lineCapture;
  }

  get newLine() {
    if (!this._newLine) {
      this._newLine = this._snap.select('.root .lineCapture .newLine');
    }
    return this._newLine;
  }

  get panCapture() {
    if (!this._panCapture) {
      this._panCapture = this._snap.select('.root .panCapture');
    }
    return this._panCapture;
  }

  get placementCapture() {
    if (!this._placementCapture) {
      this._placementCapture = this._snap.select('.root .placementCapture');
    }
    return this._placementCapture;
  }
}
