'use strict';

import ShareDialogController from '../../components/share-dialog/share-dialog.controller';
import SignupDialogController from '../../components/signup-dialog/signup-dialog';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import LoadingDialog from '../../components/loading-dialog/loading-dialog.controller';
import {
  Locations,
  ProfileViewFunnel,
} from '../../services/mixpanel/mixpanel.service';
import CkRedirect from '../../model/domain/ck-redirect';
import AssignmentManager from '../../model/domain/assignment-manager';

export default class PublicFolderController {
  constructor(
    $document,
    $log,
    $mdDialog,
    $mdPanel,
    $mdToast,
    $location,
    $q,
    $state,
    $stateParams,
    $window,
    AnalyticsService,
    AssignmentService,
    AuthService,
    BreadcrumbService,
    CacheService,
    StorageService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.$log = $log;
    this.$mdPanel = $mdPanel;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$window = $window;
    this.$document = $document;

    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._showSignupDialog = SignupDialogController.show;
    this._shareDialog = ShareDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      undefined,
      undefined,
      this._analyticsService,
      undefined,
    );

    this._loadingDialog = LoadingDialog.show;

    this.showAccountButton = this._authService.isLoggedIn;

    if (this.showAccountButton) {
      this._cacheService.getUser().then((user) => {
        this.userName = user.name;
      });
    }

    this._folderId = $stateParams.folderId;
    this._sharedFolderUsernameOrEmail = $stateParams.usernameOrEmail;
    this._organizationIdFromPublicAssignmentLibrary =
      this._storageService.assignmentLibrarySelection &&
      this._storageService.assignmentLibrarySelection._id;

    if (!this.isAuthenticated) {
      this._breadcrumbService.go('root.public-folder', {
        folderId: $stateParams.folderId,
        usernameOrEmail: $stateParams.usernameOrEmail,
      });
    } else {
      this._breadcrumbService.go('root.account.nav.public-folder', {
        folderId: $stateParams.folderId,
      });
    }

    let protocol = $location.protocol();
    let host = $location.host();
    let port = '';
    // If we're not on the default http or https ports, we'll include the port in the link
    if (!($location.port() === 80 || $location.port() === 443)) {
      port = `:${$location.port()}`;
    }

    let path = '';
    this._link = '';

    if (this._sharedFolderUsernameOrEmail) {
      path = `/users/${this.$stateParams.usernameOrEmail}/shared`;
      this._link = `${protocol}://${host}${port}${path}`;
    }

    this.state = '';

    this._init();
  }

  _init() {
    return this._assignmentService
      .getPublicFolder(this._folderId)
      .then((assignments) => {
        this.assignments = assignments.filter(
          (assignment) => assignment.isFolder === undefined,
        );
        this.folders = assignments.filter(
          (assignment) => assignment.isFolder === 'true',
        );

        this._assignmentService.get(this._folderId).then((assignment) => {
          this.currentFolder = assignment;

          this._analyticsService.publicAssignmentViewed(
            this._folderId,
            this._organizationIdFromPublicAssignmentLibrary,
            this.isFromAssignmentLibrary,
          );
        });
      })
      .catch((err) => {
        this.error = err;
      });
  }

  get isAuthenticated() {
    return this._authService.isLoggedIn;
  }

  get sharedFolderLink() {
    return this._link;
  }

  get COPYING() {
    return 'copying';
  }

  goToAssignment(assignment) {
    this._analyticsService.profileViewFunnel(
      ProfileViewFunnel.CLICKED_ASSIGNMENT,
      this.$stateParams.userId,
      this._authService.currentUserId,
      assignment.name,
    );

    if (this.isFolder(assignment)) {
      this.goToFolder(assignment);
    } else {
      const path = this._authService.isLoggedIn
        ? 'root.account.nav.public-assignment'
        : 'root.public-assignment';

      this._breadcrumbService.go(path, { assignmentId: assignment.id });
    }
  }

  goToFolder(folder) {
    const path = this._authService.isLoggedIn
      ? 'root.account.nav.public-folder'
      : 'root.public-folder';
    this._breadcrumbService.go(path, { folderId: folder.id });
  }

  isFolder(assignment) {
    return assignment.isFolder;
  }

  /** Folders **/
  /**
   * @return {string}
   */
  get currentFolderId() {
    return this.$stateParams.folderId;
  }

  /**
   * @return {boolean}
   */
  get showBreadcrumbs() {
    return this.currentFolderId;
  }

  startFolderAssignmentDuplication(folder, isTopLevelFolder) {
    // if top level folder then clean out parent tags
    if (isTopLevelFolder) {
      return this._assignmentService.duplicate(folder.id, folder.name);
    } else {
      return this._assignmentService.duplicateKeepTags(folder);
    }
  }

  recurseFolderAssignmentCreation(
    folderCopied,
    isTopLevelFolder = false,
    recurseLimit = 0,
  ) {
    this.state = this.COPYING;

    // Check if current depth exceeds arbitrary recursion limit
    if (recurseLimit >= 5000) {
      this.state = '';
      this.error = 'recursion reached';
      return Promise.reject(new Error('recursion reached'));
    }

    return this.startFolderAssignmentDuplication(folderCopied, isTopLevelFolder)
      .then((copiedFolder) => {
        return this._assignmentService
          .getPublicFolder(folderCopied.id)
          .then((folderContents) => {
            // Separate folders and assignments
            let _innerFolders = folderContents.filter(
              (value) => value.isFolder === 'true',
            );
            let _innerAssignments = folderContents.filter(
              (value) => value.isFolder === undefined,
            );

            // Create promises for assignments
            let assignmentPromises = _innerAssignments.map(
              (innerAssignment) => {
                innerAssignment.folder = copiedFolder.id;
                return this._assignmentService.duplicateKeepTags(
                  innerAssignment,
                );
              },
            );

            // Create promises for inner folders
            let folderPromises = _innerFolders.map((innerFolder) => {
              innerFolder.folder = copiedFolder.id;
              return this.recurseFolderAssignmentCreation(
                innerFolder,
                false,
                recurseLimit + 1,
              );
            });

            // Wait for all assignments and folders to be processed
            return Promise.all([...assignmentPromises, ...folderPromises]);
          });
      })
      .catch((error) => {
        this.$log.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * If logged in, go to the user's assignment list and copy the assignment there.
   * Otherwise, bring up the signup dialog.
   */

  copyFolderAndAssignments() {
    this._analyticsService.publicFolderCopyClicked(
      this.currentFolder.id,
      this._organizationIdFromPublicAssignmentLibrary,
      this.isFromAssignmentLibrary,
    );

    if (this._authService.isLoggedIn) {
      this._assignmentManager.checkAssignmentLimit(
        Locations.PUBLIC_ASSIGNMENT,
        this.currentFolder.id,
        () => {
          this.recurseFolderAssignmentCreation(this.currentFolder, true)
            .then(() => {
              this.state = '';
              this._breadcrumbService.go(
                'root.account.nav.assignments',
                {},
                { reload: true },
              );

              this.$mdToast.show(
                this.$mdToast
                  .simple()
                  .textContent(
                    `${this.currentFolder.name} has been added to your Assignments Dashboard`,
                  )
                  .position('bottom right'),
              );
            })
            .catch((error) => {
              this.$log.error(error);
              this._analyticsService.publicFolderCopyError(
                this.currentFolder.id,
              );
            });
        },
      );
    } else {
      this.showSignupDialog();
    }
  }

  /**
   * Bring up the signup dialog. When the button is clicked, go to the login page
   * with the optional redirect params set to return here after login.
   */
  showSignupDialog() {
    this._showSignupDialog(this.$mdDialog).then((stateName) => {
      this._storageService.ckRedirect = new CkRedirect(
        'root.account.nav.assignments',
        {},
      );

      this.$state.go(stateName, {
        referral: Locations.PUBLIC_ASSIGNMENT,
      });
    });
  }

  /**
   * open youtube video in new tab.
   */
  whatIsClasskick() {
    this.$window.open('https://www.youtube.com/watch?v=l5vza3HTW5I', '_blank');
  }

  /**
   * show the share dialog
   */
  showShareDialog() {
    this._shareDialog(
      this.$mdDialog,
      this.currentFolder,
      Locations.PUBLIC_ASSIGNMENT,
    );
  }

  /**
   * navigate to the given state, with given state params
   * @param  {string} stateName
   * @param  {object} stateParams
   */
  navigate(stateName, stateParams) {
    this._breadcrumbService.go(stateName, stateParams);
  }

  navToQuestion(questionId) {
    const path = this.isAuthenticated
      ? 'root.account.nav.public-assignment-question'
      : 'root.public-assignment-question';

    this._breadcrumbService.go(path, {
      assignmentId: this._assignmentId,
      questionId: questionId,
    });
  }

  navToLogin() {
    this._breadcrumbService.go('root.account-login', {});
  }

  get loading() {
    return !this.assignments && !this.error;
  }

  /**
   * Boolean that indicates whether the breadcrumb service can provide a path back
   * @return Boolean
   */
  get canGoBack() {
    return this._breadcrumbService.canGoBack;
  }

  get isFromAssignmentLibrary() {
    const stack = this._breadcrumbService.stack;
    if (
      stack.length &&
      (stack[stack.length - 1].to ===
        'root.account.nav.public-assignments-library' ||
        stack[stack.length - 1].to === 'root.public-assignments-library')
    ) {
      return true;
    }
    return false;
  }

  /**
   * Uses the breadcrumb service to go back.
   */
  goBack() {
    const stack = this._breadcrumbService.stack;
    if (
      stack[stack.length - 1].to === 'root.public-assignment-question' ||
      stack[stack.length - 1].to ===
        'root.account.nav.public-assignment-question'
    ) {
      this._breadcrumbService.go(
        stack[stack.length - 3].to,
        stack[stack.length - 3].params,
        true,
      );
    } else {
      this._breadcrumbService.goBack('root.account.home');
    }
  }
}
