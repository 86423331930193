'use strict';

import Codec from './codec';
import Point from '../ui/point';

export default class PointCodec extends Codec {
  /**
   * Encodes a point to a string value
   *
   * @param value {Point}
   * @returns {string}
   */
  encode(value) {
    return angular.toJson([Math.round(value.x), Math.round(value.y)]);
  }

  /**
   * Decodes a point from the given input, either JSON string or array of numbers
   *
   * @param value {string|Array.<number>}
   * @returns {Point}
   */
  decode(value) {
    var encoded = value;
    if (angular.isString(encoded)) {
      encoded = angular.fromJson(encoded);
    }

    return new Point(encoded[0], encoded[1]);
  }
}
