'use strict';

import StudentAssignmentFolderItem from './student-assignment-folder-item';
import Debouncer from '../../model/util/debouncer';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import StudentCodeDialog from '../../components/student-code-dialog/student-code-dialog.controller';
import { FeedbackMenuController } from '../../components/feedback-menu/feedback-menu.controller';
import moment from 'moment';

/**
 * Student dashboard controller
 */
export default class StudentAssignmentsListController {
  constructor(
    $q,
    $timeout,
    $state,
    $scope,
    $mdDialog,
    $mdPanel,
    $document,
    $mdSidenav,
    StudentCacheService,
    StorageService,
    AssignmentWorkService,
    NotificationService,
    FeedbackService,
    AuthService,
    AnalyticsService,
    ClassCodeService,
    CacheService,
    RosterService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this.$document = $document;
    this.$mdSidenav = $mdSidenav;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {RosterService} */
    this._rosterService = RosterService;

    this._loadingDialog = LoadingDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._enterCodeDialog = StudentCodeDialog.show;
    this._feedbackMenu = FeedbackMenuController.show;

    this._folders = null;
    this._filteredFolders = null;
    this._sort = null;
    this._error = null;
    /** @type {{names: string[], allowNewMembers: boolean}} */
    this._classCodeData = undefined;
    /** @type {ClassCode} */
    this._classCode = undefined;
    this._code = '';
    this._codeIsValid = false;
    this._hasWork = false;
    this._showClassCodeError = false;
    this._folderIndexes = new Map();
    this._rostersAndOwners = null;
    this._assignmentsAndWork = null;

    this.searchTerm = null;

    this._measureDebouncer = new Debouncer(
      100,
      5000,
      () => this._measureSections(),
      $scope,
    );
    this._anonOnAssignmentRosterUpdated = () => this._measureDebouncer.tick();

    this._studentCacheService.userRosterUpdated.subscribe(
      this._onUserRosterUpdated,
      this,
    );
    this.sort = this.SORT_NAME_ASC;

    $scope.$on('$destroy', () => this._destroy());

    this.init();
  }

  init() {
    const folderStatus = this._storageService.studentFolderStatus || {};

    return this.$q
      .all({
        rostersAndOwners: this._studentCacheService.getUserRostersAndOwners(),
        assignmentsAndWork:
          this._studentCacheService.getUserAssignmentsAndWorks(),
      })
      .then(({ rostersAndOwners, assignmentsAndWork }) => {
        this._rostersAndOwners = rostersAndOwners;
        this._assignmentsAndWork = assignmentsAndWork;

        if (this._folders) {
          this._folders.forEach((folder) => {
            folder.activeFolder.assignments.forEach((item) => {
              item.stop();
            });
            folder.inactiveFolder.assignments.forEach((item) => {
              item.stop();
            });
          });
        }

        this._folders = [...rostersAndOwners.rosters.values()].map((roster) => {
          const { activeFolder, inactiveFolder } =
            this._setUpActiveAndInactiveFolders(
              folderStatus,
              assignmentsAndWork,
              roster,
            );

          const rosterOwner = this._rostersAndOwners.rosterOwners.get(
            roster.ownerId,
          );
          const rosterOwnerName = rosterOwner ? `${rosterOwner.name} - ` : '';
          return {
            id: roster ? roster.id : 'orphaned',
            title: roster ? `${rosterOwnerName}${roster.name}` : 'Old Work',
            color: roster.color,
            activeFolder,
            inactiveFolder,
          };
        });

        this._folders.sort((a, b) => a.title.localeCompare(b.title));

        // Only show old work for a superuser
        if (this._authService.isSuperuser) {
          const orphans = [...assignmentsAndWork.works.values()].filter(
            (work) => {
              let studentIsOnRoster = rostersAndOwners.rosters.has(
                work.rosterId,
              );
              let rosterIsAssignedToAssignment =
                work.assignment.rosters.includes(work.rosterId);
              // A teacher marked the assignment-roster as hidden before removing the roster from the assignment
              let assignmentIsHidden =
                work.assignmentRoster && work.assignmentRoster.hideStudentWork;
              return (
                (!studentIsOnRoster || !rosterIsAssignedToAssignment) &&
                !assignmentIsHidden
              );
            },
          );
          if (orphans.length > 0) {
            let activeFolder = StudentAssignmentFolderItem.orphaned(
              orphans,
              assignmentsAndWork.assignmentRosters,
              this._anonOnAssignmentRosterUpdated,
              folderStatus,
              this._notificationService,
              this._assignmentWorkService,
              this._feedbackService,
            );
            let inactiveFolder = StudentAssignmentFolderItem.orphaned(
              [],
              assignmentsAndWork.assignmentRosters,
              this._anonOnAssignmentRosterUpdated,
              folderStatus,
              this._notificationService,
              this._assignmentWorkService,
              this._feedbackService,
            );

            this._folders.push({
              id: 'orphaned',
              title: 'Old Work',
              color: '#404041',
              activeFolder,
              inactiveFolder,
            });
          }
        }

        this._folders.map((folder, index) => {
          this._folderIndexes.set(folder.id, index);
        });

        this.sort = this.sort;

        this._measureDebouncer.tick();
      })
      .catch((err) => {
        this._error = err;
      });
  }

  get loading() {
    return !this.error && this.folders === null;
  }

  get error() {
    return !!this._error;
  }

  get sort() {
    return this._sort;
  }

  set sort(value) {
    this._sort = value;
    if (this._folders) {
      this._folders.forEach((f) => {
        f.activeFolder && f.activeFolder.sort(value);
        f.inactiveFolder && f.inactiveFolder.sort(value);
      });
    }
  }

  _setUpActiveAndInactiveFolders(folderStatus, assignmentsAndWork, roster) {
    const works = this.filterStudentWorks(
      assignmentsAndWork.works,
      assignmentsAndWork.assignments,
      roster,
    );
    const rosterOwner = this._rostersAndOwners.rosterOwners.get(roster.ownerId);

    const active = this.prepStudentFolderData(
      true,
      folderStatus,
      this._assignmentsAndWork,
      roster,
      rosterOwner,
      works.activeWork,
      works.unstartedAssignments,
    );

    const inactive = this.prepStudentFolderData(
      false,
      folderStatus,
      this._assignmentsAndWork,
      roster,
      rosterOwner,
      works.inactiveWork,
      works.unstartedAssignments,
    );

    let activeFolder = StudentAssignmentFolderItem.fromData(active);
    let inactiveFolder = StudentAssignmentFolderItem.fromData(inactive);

    return {
      activeFolder,
      inactiveFolder,
    };
  }

  filterStudentWorks(works, assignments, roster) {
    let activeWork = {};
    let inactiveWork = {};
    Array.from(works.values()).forEach((w) => {
      if (
        w.rosterId === roster.id &&
        w.assignment.rosters.includes(w.rosterId)
      ) {
        if (w.modified >= moment().subtract(1, 'years')) {
          activeWork[w.assignment.id] = w;
        } else if (w.modified < moment().subtract(1, 'years')) {
          inactiveWork[w.assignment.id] = w;
        }
      }
    });
    let unstartedAssignments = Array.from(assignments.values()).filter(
      (a) =>
        !inactiveWork[a.id] &&
        !activeWork[a.id] &&
        a.rosters.includes(roster.id),
    );
    return {
      activeWork: Object.values(activeWork),
      inactiveWork: Object.values(inactiveWork),
      unstartedAssignments,
    };
  }

  prepStudentFolderData(
    active,
    folderStatus,
    assignmentsAndWork,
    roster,
    rosterOwner,
    works,
    unstartedAssignments,
  ) {
    return {
      roster,
      rosterOwner,
      unstartedAssignments,
      works,
      assignmentRosters: assignmentsAndWork.assignmentRosters,
      onAssignmentRosterUpdated: this._anonOnAssignmentRosterUpdated,
      open: angular.isUndefined(folderStatus[roster.id])
        ? true
        : !!folderStatus[roster.id],
      notificationService: this._notificationService,
      assignmentWorkService: this._assignmentWorkService,
      feedbackService: this._feedbackService,
      activeWork: active,
    };
  }

  search() {
    if (this.searchTerm) {
      this._filteredFolders = this._folders
        .map((rosterFolder) => {
          let filtered = Object.assign({}, rosterFolder);
          filtered.activeFolder = filtered.activeFolder.filter(this.searchTerm);
          filtered.inactiveFolder = filtered.inactiveFolder.filter(
            this.searchTerm,
          );
          return filtered;
        })
        .filter(
          (roster) =>
            roster.activeFolder !== null || roster.inactiveFolder !== null,
        );
      this._measureDebouncer.tick();
    } else {
      this._filteredFolders = null;
    }
  }

  _measureSections() {
    this.folders.forEach((folder) => {
      if (folder.activeFolder) {
        folder.activeFolder.maxHeight =
          angular
            .element(
              `.student-assignments-list .accordion.${folder.activeFolder.id} .body md-list`,
            )
            .height() + 'px';
      }
      if (folder.inactiveFolder) {
        folder.inactiveFolder.maxHeight =
          angular
            .element(
              `.student-assignments-list .accordion.${folder.inactiveFolder.id} .body md-list`,
            )
            .height() + 'px';
      }
    });
  }

  /**
   * @returns {StudentAssignmentFolderItem[]}
   */
  get folders() {
    return this.searchTerm ? this._filteredFolders : this._folders;
  }

  /**
   * @param folder {StudentAssignmentFolderItem}
   * @param assignmentItem {StudentAssignmentItem}
   * @param [questionId] {string}
   */
  goToWork(folder, assignmentItem, questionId) {
    let getWork = this.$q.resolve(assignmentItem.assignment);
    if (!assignmentItem.isWork) {
      getWork = this._studentCacheService
        .createWork(assignmentItem.assignment, folder.roster)
        .then((work) => {
          assignmentItem.assignment = work;
          return work;
        });
      getWork = this._loadingDialog(
        this.$mdDialog,
        getWork,
        this.$document,
        '.student-assignments-list md-content.body',
      );
    }

    getWork
      .then((work) => {
        return this.$q.all({
          assignment: this._assignmentWorkService.getAssignment(work.id),
          work: work,
        });
      })
      .then(({ work }) => {
        this.$state.go('root.account.student-assignment-work', {
          assignmentWorkId: work.id,
          questionId: questionId || work.assignment.questionIdForIndex(0),
        });
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          'There was a problem opening your assignment',
          '',
        );
      });
  }

  /**
   * @param folder {StudentAssignmentFolderItem}
   */
  toggleFolder(folder) {
    folder.toggleOpen();

    if (!this.searchTerm) {
      const status = {};
      this.folders.forEach((f) => {
        status[f.activeFolder.id] = f.activeFolder.open;
        f.id !== 'orphaned'
          ? (status[f.inactiveFolder.id] = f.inactiveFolder.open)
          : null;
      });
      this._storageService.studentFolderStatus = status;
    }
  }

  sortByName() {
    if (this.sort === this.SORT_NAME_ASC) {
      this.sort = this.SORT_NAME_DESC;
    } else {
      this.sort = this.SORT_NAME_ASC;
    }
  }

  sortByScore() {
    if (this.sort === this.SORT_SCORE_ASC) {
      this.sort = this.SORT_SCORE_DESC;
    } else {
      this.sort = this.SORT_SCORE_ASC;
    }
  }

  sortByFeedback() {
    if (this.sort === this.SORT_FEEDBACK_ASC) {
      this.sort = this.SORT_FEEDBACK_DESC;
    } else {
      this.sort = this.SORT_FEEDBACK_ASC;
    }
  }

  _onUserRosterUpdated(data) {
    if (data.assignmentId) {
      this._updatePromotedAssignment(data);
    } else {
      this.init().then(() => {
        this.search();
      });
    }
  }

  _updatePromotedAssignment(data) {
    this._assignmentWorkService
      .getOrCreateForSelf(data.assignmentId, data.rosterId)
      .then((work) => {
        this._studentCacheService
          .getUserAssignmentsAndWorks()
          .then((assignmentsAndWork) => {
            //get back cached assignments and assignments work list and replace the work and assignment associated with the promoted assignment
            this._assignmentsAndWork = assignmentsAndWork;
            this._assignmentsAndWork.works.set(work.id, work);
            this._assignmentsAndWork.assignments.set(
              work.assignment.id,
              work.assignment,
            );
            const roster = this._rostersAndOwners.rosters.get(data.rosterId);
            const folderStatus = this._storageService.studentFolderStatus || {};

            const { activeFolder, inactiveFolder } =
              this._setUpActiveAndInactiveFolders(
                folderStatus,
                this._assignmentsAndWork,
                roster,
              );

            const newFolder = {
              id: roster && roster.id,
              title: `${this._rostersAndOwners.rosterOwners.get(roster.ownerId).name} - ${roster.name}`,
              color: roster.color,
              activeFolder,
              inactiveFolder,
            };
            this._folders[this._folderIndexes.get(data.rosterId)] = newFolder;
            this.sort = this.sort;
          });
      });
  }

  _destroy() {
    this._studentCacheService.userRosterUpdated.unsubscribe(
      this._onUserRosterUpdated,
      this,
    );
  }

  get SORT_NAME_ASC() {
    return StudentAssignmentFolderItem.SORT_NAME_ASC;
  }

  get SORT_NAME_DESC() {
    return StudentAssignmentFolderItem.SORT_NAME_DESC;
  }

  get SORT_SCORE_ASC() {
    return StudentAssignmentFolderItem.SORT_SCORE_ASC;
  }

  get SORT_SCORE_DESC() {
    return StudentAssignmentFolderItem.SORT_SCORE_DESC;
  }

  get SORT_FEEDBACK_ASC() {
    return StudentAssignmentFolderItem.SORT_FEEDBACK_ASC;
  }

  get SORT_FEEDBACK_DESC() {
    return StudentAssignmentFolderItem.SORT_FEEDBACK_DESC;
  }

  /**
   * @param ev
   * @param folder {StudentAssignmentFolderItem}
   * @param studentAssignmentItem {StudentAssignmentItem}
   */
  openFeedbackMenu(ev, folder, studentAssignmentItem) {
    this._feedbackMenu(
      this.$mdPanel,
      this.$q,
      ev,
      studentAssignmentItem.questionFeedbackList,
      studentAssignmentItem.assignment,
    ).then(({ questionId }) => {
      this.goToWork(folder, studentAssignmentItem, questionId);
    });
  }

  enterCode() {
    this._enterCodeDialog(this.$mdDialog);
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  gradeIsAboveNinety(assignment) {
    if (assignment.studentTotalScoreDisplay) {
      return (
        (assignment.studentTotalScore / assignment.totalPotentialPoints) *
          100 >=
        90
      );
    }
    return false;
  }

  /**
   * @return {string}
   */
  get whatIsAClassCodeModalId() {
    return 'what-is-a-class-code';
  }

  /**
   * @return {string}
   */
  get lockedOrHiddenMessage() {
    if (this._classCodeData && this._classCodeData.hideStudentWork) {
      return 'This assignment is hidden. Please ask your teacher to un-hide it so you can continue.';
    } else {
      return 'The roster for this class code is locked. Please ask your teacher to unlock it so you can continue.';
    }
  }

  /**
   * @return {boolean}
   */
  get showClassCodeError() {
    return this._showClassCodeError;
  }

  /**
   * @return {string}
   */
  get code() {
    return this._code;
  }

  /**
   * @param value {string}
   */
  set code(value) {
    this._code = value;
  }

  /**
   * @return {boolean}
   */
  get codeIsValid() {
    return this._codeIsValid;
  }

  showClassCodeModal() {
    this.$mdDialog.show({
      contentElement: `#${this.whatIsAClassCodeModalId}`,
      parent: angular.element(this.$document[0].body),
      onRemoving: () => {
        this._focusClassCode = true;
      },
    });
  }

  joinClassCode() {
    this._reset();

    this.code = this.code.replace(/[\s]/g, '').slice(0, 6).toUpperCase();

    if (this.code.length === 6) {
      this.checkClassCode();
    }
  }

  _reset() {
    this._showClassCodeError = false;
    this._code = this._code.toUpperCase();
    this._codeIsValid = false;
    this._classCodeData = undefined;
    this._classCode = undefined;
    this._user = undefined;
    this._work = undefined;
  }

  checkClassCode() {
    this.$q
      .all({
        classCodeData: this._classCodeService.getUsernames(this._code),
        classCode: this._classCodeService.get(this._code),
        user: this._cacheService.getUser(),
        assignmentsAndWorks:
          this._studentCacheService.getUserAssignmentsAndWorks(),
      })
      .then((results) => {
        this._classCodeData = results.classCodeData;
        this._classCode = results.classCode;
        this._user = results.user;
        this._work = this.findWorkForClassCode(this._classCode, [
          ...results.assignmentsAndWorks.works.values(),
        ]);
        this._hasWork = !!this._work;

        let allowNewMembers =
          this._classCodeData && this._classCodeData.allowNewMembers;
        let hasNameOnRoster =
          this._classCodeData &&
          this._user &&
          this._classCodeData.names.some((name) => name === this._user.name);
        let hideStudentWork =
          this._classCodeData && this._classCodeData.hideStudentWork;

        // If the student has already started working or is on roster or the roster is unlocked
        let canContinue = allowNewMembers || hasNameOnRoster || this._hasWork;

        // The class code is valid if the current student can continue and the assignment is visible (aka not hidden)
        this._codeIsValid = canContinue && !hideStudentWork;
        this._showClassCodeError = !this._codeIsValid;

        if (!this._codeIsValid) {
          this._errorDialog(
            this.$mdDialog,
            `${this.lockedOrHiddenMessage}`,
            '',
          );
        } else {
          this.submitCode();
        }
      })
      .catch(() => {
        this._errorDialog(this.$mdDialog, 'This class code is not valid', '');
        this._showClassCodeError = true;
      });
  }

  submitCode() {
    if (this._hasWork) {
      this.goToWorkFromClassCode(this._work);
    } else {
      this.startWork(this._classCode.assignmentId, this._classCode.rosterId);
    }
  }

  /**
   * @param classCodeData {ClassCode}
   * @param works {AssignmentWork[]}
   *
   * @return {AssignmentWork|undefined}
   */
  findWorkForClassCode(classCodeData, works) {
    return works.find((work) => {
      return (
        work.assignmentId === classCodeData.assignmentId &&
        work.rosterId === classCodeData.rosterId
      );
    });
  }

  /**
   * @param assignmentId {string}
   * @param rosterId {string}
   */
  startWork(assignmentId, rosterId) {
    this._rosterService
      .addSelf(rosterId)
      .then(() => {
        return this._assignmentWorkService.getOrCreateForSelf(
          assignmentId,
          rosterId,
        );
      })
      .then((work) => {
        this.goToWorkFromClassCode(work);
      });
  }

  /**
   * @param code {string}
   */
  startAnonWork(code) {
    this._cacheService.getUser().then((user) => {
      this.navigate('root.anon-student-assignment-work', {
        classCode: code,
        name: user.name,
        question: 1,
      });
    });
  }

  /**
   * @param work {AssignmentWork}
   */
  goToWorkFromClassCode(work) {
    this.navigate('root.account.student-assignment-work', {
      assignmentWorkId: work.id,
      questionId: work.assignment.questionIdForIndex(0),
    });
  }

  /**
   * @param state {string}
   * @param params {object}
   */
  navigate(state, params) {
    this.$mdDialog.hide();
    this.$state.go(state, params);
  }
}
