'use strict';

import CoTeacherCodec from '../../model/codec/co-teacher-codec';

export default class CoTeacherService {
  constructor($q, environment, HttpService, AuthService, StorageService) {
    'ngInject';

    this.$q = $q;
    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._coteacherCodec = new CoTeacherCodec();
  }

  /**
   * Invite teachers to co-teach on a roster
   *
   * @param rosterId {string}
   * @param emails {string}
   * @returns {Promise.<*>}
   */
  inviteCoTeacher(rosterId, email) {
    return this._httpService
      .authPost(this._uri('/v1/coteachers/invite'), {
        roster_id: rosterId,
        coteacher_email: email,
      })
      .then((data) => {
        return data;
      });
  }

  /**
   * Get list of all invited co-teachers for a roster
   *
   * @param rosterId {string}
   * @returns {Promise.<*>}
   */
  getInvitedCoTeachersForRosters(rosterId) {
    return this._httpService
      .authGet(this._uri(`/v1/coteachers/roster/${rosterId}`))
      .then((data) => {
        return data.coteachers.map((coteacher) =>
          this._coteacherCodec.decode(coteacher),
        );
      });
  }

  /**
   * Get access token for roster as a co-teacher
   *
   * @param rosterId {string}
   * @returns {Promise.<*>}
   */
  getAccessForRoster(rosterId) {
    return this._httpService
      .authPost(this._uri(`/v1/coteachers/roster/${rosterId}/access`))
      .then((data) => {
        if (!this._authService.coTeacherAuthData) {
          this._storageService.setCoTeacherInfo(this._authService.authData);
          this._authService.clearData();
        }
        return this._authService.processTokenResult(
          data.token,
          this._storageService.rememberMe,
        );
      });
  }

  /**
   * Delete co-teacher from roster
   *
   * @param coTeacherId {string}
   * @returns {Promise.<boolean>}
   */
  deleteCoTeacherFromRoster(coTeacherId) {
    return this._httpService
      .authDelete(this._uri(`/v1/coteachers/${coTeacherId}`))
      .then((data) => {
        return data;
      });
  }

  /**
   * Returns a string that specifies a uri given a path.
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
