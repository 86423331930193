'use strict';

import UserStickerCodec from '../../model/codec/user-sticker-codec';

export default class StickerService {
  constructor(environment, HttpService) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;

    this._userStickerCodec = new UserStickerCodec();
  }

  /**
   * @param userId {string}
   * @returns {Promise.<Sticker[]>}
   */
  getForUser(userId) {
    return this._httpService
      .authGet(this._uri(`/v1/users/${userId}/stickers`))
      .then((data) => {
        return data.stickers.map((httpSticker) => {
          return this._userStickerCodec.decode(httpSticker);
        });
      });
  }

  /**
   * Creates a new sticker for the given user
   *
   * @param imageUrl {sticker}
   * @param text {sticker}
   * @param tags {Array}
   * @returns {Promise}
   */
  create(imageUrl, text, tags) {
    return this._httpService
      .authPost(this._uri('/v1/stickers'), {
        image_url: imageUrl,
        text: text,
        tags: tags,
      })
      .then((httpSticker) => {
        return this._userStickerCodec.decode(httpSticker);
      });
  }

  /**
   * Overwrites the specified sticker
   *
   * @param sticker {UserSticker}
   * @param beforeStickerId {string}
   * @returns {Promise.<UserSticker>}
   */
  update(sticker, beforeStickerId) {
    return this._httpService
      .authPut(this._uri(`/v1/stickers/${sticker.id}`), {
        ...this._userStickerCodec.encode(sticker),
        before_sticker_id: beforeStickerId,
      })
      .then((httpSticker) => {
        return this._userStickerCodec.decode(httpSticker);
      });
  }

  /**
   * Deletes the specified sticker
   *
   * @param stickerId {string}
   * @returns {Promise.<boolean>}
   */
  delete(stickerId) {
    return this._httpService.authDelete(this._uri(`/v1/stickers/${stickerId}`));
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
