'use strict';

/**
 * Defines a contract
 */
export default class ContractMember {
  constructor(contractId, admin = false) {
    this._contractId = contractId;
    this._admin = admin;
  }

  /**
   * @returns {string}
   */
  get contractId() {
    return this._contractId;
  }

  /**
   * @returns {string}
   */
  get admin() {
    return this._admin;
  }
}
