'use strict';

import JSEvent from '../util/js-event';
import {
  HelpRequestUpdateNotifier,
  MultiHelpRequestUpdateNotifier,
} from './help-request-notification';

/**
 * A live updated Collection of HelpRequests, configurably indexed and filtered
 */
export default class HelpRequestSet extends HelpRequestUpdateNotifier {
  /**
   * @param sourceData {HelpRequest[]}
   * @param helpRequestUpdateNotifier {HelpRequestUpdateNotifier|HelpRequestNotification}
   * @param [filterFunc] {Function}
   * @param [indexFunc] {Function}
   */
  constructor(sourceData, helpRequestUpdateNotifier, filterFunc, indexFunc) {
    super();

    this._filterFunc = filterFunc || HelpRequestSet.FILTER_FUNC_ALL;
    this._indexFunc = indexFunc || HelpRequestSet.INDEX_FUNC_ID;

    this._requests = sourceData.filter(this._filterFunc);
    this._requestIndex = new Map(
      this._requests.map((x) => [this._indexFunc(x), x]),
    );

    this._created = new JSEvent(this);
    this._resolved = new JSEvent(this);
    this._canceled = new JSEvent(this);

    this._helpRequestUpdateNotifier = helpRequestUpdateNotifier;

    if (this._helpRequestUpdateNotifier) {
      this._helpRequestUpdateNotifier.created.subscribe((ev) =>
        this._handleCreated(ev),
      );
      this._helpRequestUpdateNotifier.resolved.subscribe((ev) =>
        this._handleResolved(ev),
      );
      this._helpRequestUpdateNotifier.canceled.subscribe((ev) =>
        this._handleCanceled(ev),
      );
    }
  }

  /**
   * Starts real-time updates for this any related (child or parent) sets.
   * Results in starting the OG HelpRequestNotification
   * @returns {*}
   */
  start() {
    if (this._helpRequestUpdateNotifier) {
      return this._helpRequestUpdateNotifier.start();
    }
  }

  /**
   * Stops real-time updates for this any related (child or parent) sets
   * Results in stopping the OG HelpRequestNotification
   * @returns {*}
   */
  stop() {
    if (this._helpRequestUpdateNotifier) {
      return this._helpRequestUpdateNotifier.stop();
    }
  }

  /**
   * @returns {HelpRequestUpdateNotifier|HelpRequestNotification}
   */
  get notifier() {
    return this._helpRequestUpdateNotifier;
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get created() {
    return this._created;
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get resolved() {
    return this._resolved;
  }

  /**
   * @returns {JSEvent.<HelpRequest>}
   */
  get canceled() {
    return this._canceled;
  }

  /**
   * @returns {Array.<HelpRequest>}
   */
  get requests() {
    return this._requests;
  }

  /**
   * @param key {*}
   * @returns {HelpRequest}
   */
  get(key) {
    return this._requestIndex.get(key);
  }

  /**
   * @returns {int}
   */
  get size() {
    return this._requests.length;
  }

  /**
   * @returns {int}
   */
  get total() {
    return this.size;
  }

  /**
   * @param filterFunc {function}
   * @returns {HelpRequest[]}
   */
  filter(filterFunc) {
    return this.requests.filter(filterFunc);
  }

  /**
   * Generates a new child HelpRequestSet that is a projection of the original using the
   * specified filter and index function.
   *
   * @param [filterFunc] {Function}
   * @param [indexFunc] {Function}
   * @returns {HelpRequestSet}
   */
  createChild(filterFunc, indexFunc) {
    return new HelpRequestSet(this.requests, this, filterFunc, indexFunc);
  }

  /**
   * @param helpRequest {HelpRequest}
   * @returns {*}
   */
  indexFor(helpRequest) {
    return this._indexFunc(helpRequest);
  }

  shouldContain(helpRequest) {
    return this._filterFunc(helpRequest);
  }

  isPresent(helpRequest) {
    return !!this._requests.find((request) => request.id === helpRequest.id);
  }

  localAdd(helpRequest) {
    this._handleCreated(helpRequest);
  }

  localCancel(helpRequest) {
    this._handleCanceled(helpRequest);
  }

  localResolve(helpRequest) {
    this._handleResolved(helpRequest);
  }

  /**
   * @param helpRequest {HelpRequest}
   */
  _handleCreated(helpRequest) {
    if (this.shouldContain(helpRequest) && !this.isPresent(helpRequest)) {
      this._add(helpRequest);
      this._created.raise(helpRequest);
    }
  }

  /**
   * @param helpRequest {HelpRequest}
   */
  _handleResolved(helpRequest) {
    const index = this._findIndex(helpRequest);
    if (index > -1) {
      if (!this.shouldContain(helpRequest)) {
        this._removeAt(index);
      } else {
        this._updateAt(index, helpRequest);
      }

      this._resolved.raise(helpRequest);
    } else if (this.shouldContain(helpRequest)) {
      this._add(helpRequest);

      this._resolved.raise(helpRequest);
    }
  }

  /**
   * @param helpRequest {HelpRequest}
   */
  _handleCanceled(helpRequest) {
    const index = this._findIndex(helpRequest);
    if (index > -1) {
      let item = this._removeAt(index);
      this._canceled.raise(item);
    }
  }

  /**
   * @param index {int}
   * @returns {HelpRequest}
   * @private
   */
  _removeAt(index) {
    let item = this._requests[index];
    this._requests.splice(index, 1);
    this._requestIndex.delete(this.indexFor(item));

    return item;
  }

  /**
   * @param helpRequest {HelpRequest}
   * @private
   */
  _add(helpRequest) {
    this._requests.push(helpRequest);
    this._requestIndex.set(this.indexFor(helpRequest), helpRequest);
  }

  /**
   * @param index {int}
   * @param helpRequest {HelpRequest}
   * @private
   */
  _updateAt(index, helpRequest) {
    let item = this._requests[index];
    this._requests[index] = helpRequest;
    this._requestIndex.delete(this.indexFor(item));
    this._requestIndex.set(this.indexFor(helpRequest), helpRequest);
  }

  _findIndex(helpRequest) {
    return this._requests.findIndex((x) => x.id === helpRequest.id);
  }

  /********  FILTER AND INDEX FUNCTIONS ***********************/

  /**
   * @returns {function(HelpRequest)}
   */
  static get FILTER_FUNC_ALL() {
    return () => {
      return true;
    };
  }

  /**
   * @returns {function(HelpRequest)}
   */
  static get FILTER_FUNC_UNRESOLVED() {
    return (x) => {
      return !x.resolved;
    };
  }

  /**
   * @returns {function(HelpRequest)}
   */
  static get INDEX_FUNC_ID() {
    return (request) => {
      return request.id;
    };
  }

  /**
   * @returns {function(HelpRequest)}
   */
  static get INDEX_FUNC_HELPEE_QUESTION() {
    return (request) => {
      return HelpRequestSet._INDEX_HELPEE_QUESTION(
        request.helpeeId,
        request.questionId,
      );
    };
  }

  /**
   * @param helpeeId {string}
   * @param questionId {string}
   * @returns {string}
   */
  static _INDEX_HELPEE_QUESTION(helpeeId, questionId) {
    return `${helpeeId}${questionId}`;
  }

  static FILTER_FUNC_STUDENT_UNRESOLVED(userId) {
    return (request) => {
      return (
        request.helpeeId !== userId && angular.isUndefined(request.resolved)
      );
    };
  }

  static FILTER_FUNC_HELPEE_QUESTION_UNRESOLVED(helpeeId, questionId) {
    return (request) => {
      return (
        angular.isUndefined(request.resolved) &&
        request.helpeeId === helpeeId &&
        request.questionId === questionId
      );
    };
  }

  static FILTER_FUNC_TYPES_UNRESOLVED(types) {
    return (request) => {
      return (
        angular.isUndefined(request.resolved) &&
        types.some((type) => type === request.requestType)
      );
    };
  }

  /**
   * @param helpRequestSets {HelpRequestSet[]}
   * @param [filterFunc] {function}
   * @param [indexFunc] {indexFunc}
   * @returns {HelpRequestSet}
   */
  static combine(helpRequestSets, filterFunc, indexFunc) {
    return new HelpRequestSet(
      helpRequestSets.map((x) => x.requests).reduce((a, v) => a.concat(v), []),
      new MultiHelpRequestUpdateNotifier(helpRequestSets),
      filterFunc || HelpRequestSet.FILTER_FUNC_ALL,
      indexFunc || HelpRequestSet.INDEX_FUNC_ID,
    );
  }
}
