'use strict';

import Control from '../../model/ui/control';
import SvgCanvas from '../../model/ui/svg-canvas';

export default class PanCapture extends Control {
  constructor() {
    super('panCapture');
  }

  createElement(root) {
    this.root = root
      .rect(0, 0, SvgCanvas.INITIAL_WIDTH, SvgCanvas.INITIAL_HEIGHT)
      .addClass(this.id)
      .attr({
        fill: 'transparent',
        cursor: 'pointer',
      });

    this.root.attr({
      visibility: 'hidden',
    });
  }

  update() {
    this.root.attr({
      visibility: this.visibility,
    });
  }
}
