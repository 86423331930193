'use strict';

import Control from './control';
import Size from './size';
import GestureManager from './gesture-manager';
import JSEvent from './../util/js-event';
import Trig from '../util/trig';
import HexColors from '../../css-constants';

export default class RotateImageHandle extends Control {
  constructor(target) {
    super(`${target.id}-rotate-handle`);

    this._target = target;
    this._size = new Size(16, 16);

    this._gestureManager = new GestureManager(undefined, target.canvas);
    this._gestureManager._dragThreshold = 0;

    this._rotationStarted = new JSEvent(this);
    this._rotationComplete = new JSEvent(this);
  }

  createElement(root /*, editable, active*/) {
    this._base = root.group();
    this._circle = this._base.circle(0, 0, 1);
    this._line = this._base.rect(0, 0, 0, 0);
    this._interactive = this._base.rect(0, 0, 0, 0);
    this._gestureManager.start(this._interactive.node);
    this._gestureManager.dragStart.subscribe(this._dragStartHandler, this);
    this._gestureManager.dragMove.subscribe(this._dragMoveHandler, this);
    this._gestureManager.dragEnd.subscribe(this._dragEndHandler, this);
  }

  update() {
    this._circle.attr({
      fill: '#25C582',
      cursor: 'pointer',
      visibility: this._visibility,
      cx: this.location.x,
      cy: this.location.y - 25,
      r: this.width / 2,
    });

    this._line.attr({
      x: this.location.x - 1,
      y: this.location.y - 20,
      visibility: this._visibility,
      width: 2,
      height: 20,
      fill: '#25C582',
      'pointer-events': 'none',
    });

    this._interactive.attr({
      x: this.location.x - 20,
      y: this.location.y - 40,
      visibility: this._visibility,
      width: 40,
      height: 40,
      fill: 'transparent',
    });
  }

  warnBeforeDeletion() {
    this._circle.attr({
      fill: HexColors.CK_WARN,
      visibility: this._visibility,
    });
    this._line.attr({
      fill: HexColors.CK_WARN,
      visibility: this._visibility,
    });
  }

  _dragStartHandler(data) {
    this._rotationStarted.raise({});
    this._initialRotation = this._normalize(this._target.rotation);
    this._rotationHandleLocation = data.relativeLocation;
  }

  _dragMoveHandler(data) {
    let change = this._deriveChange(
      this._rotationHandleLocation,
      data.relativeLocation,
      this._target.location,
    );
    let newRotation = this._normalize(this._initialRotation + change);

    if (newRotation % 90 < 2.2) {
      newRotation = Trig.roundToNearest(newRotation, 90);
    }

    this._target.rotation = newRotation;
  }

  /**
   * @param degrees {number}
   * @returns {number}
   */
  _normalize(degrees) {
    return (360 + (degrees % 360)) % 360;
  }

  /**
   * @param location1 {Point}
   * @param location2 {Point}
   * @param targetLocation {Point}
   * @private
   */
  _deriveChange(location1, location2, origin) {
    let vector1 = location1.minus(origin);
    let vector2 = location2.minus(origin);
    return vector1.clockwiseAngle(vector2);
  }

  _dragEndHandler() {
    this._rotationComplete.raise({});
  }

  get rotationStarted() {
    return this._rotationStarted;
  }

  get rotationComplete() {
    return this._rotationComplete;
  }

  remove() {
    this._gestureManager.stop();
    this._rotationStarted.clear();
    this._rotationComplete.clear();
    super.remove();
  }
}
