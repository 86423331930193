import moment from 'moment/moment';

export default class CoTeacher {
  /**
   * @param id {string}
   * @param rosterId {string}
   * @param coteacherEmail {string}
   * @param coteacherId {string | undefined}
   * @param lastAccessed {string | undefined}
   *
   */
  constructor(id, rosterId, coteacherEmail, coteacherId, lastAccessed) {
    this._id = id;
    this._rosterId = rosterId;
    this._coteacherEmail = coteacherEmail;
    this._coteacherId = coteacherId;
    this._lastAccessed = lastAccessed ? moment(lastAccessed) : undefined;
  }

  get id() {
    return this._id;
  }

  get rosterId() {
    return this._rosterId;
  }

  get coteacherEmail() {
    return this._coteacherEmail;
  }
  get coteacherId() {
    return this._coteacherId;
  }

  get lastAccessed() {
    return this._lastAccessed;
  }
}
