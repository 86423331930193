'use strict';

import moment from 'moment';
import Notification from './notification';
import NotificationShardKey from './notification-shard-key.js';
import JSEvent from '../util/js-event';

class RosterChange {
  constructor(change, userId, time) {
    this._change = change;
    this._userId = userId;
    this._time = moment(time);
  }

  static apply(obj) {
    if (obj) {
      return new RosterChange(obj.change, obj.user_id, obj.t);
    }
    return null;
  }

  /**
   * @returns {string}
   */
  get change() {
    return this._change;
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {moment}
   */
  get time() {
    return this._time;
  }
}

export default class RosterNotification extends Notification {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param rosterId {string}
   * @param FirebaseService {FirebaseService}
   */
  constructor(notificationShardKey, rosterId, FirebaseService) {
    super(
      Notification.V3,
      notificationShardKey,
      `rosters/${rosterId}/status`,
      FirebaseService,
      false,
      'RosterNotification',
    );

    this._userAdded = new JSEvent(this);
    this._userUpdated = new JSEvent(this);
    this._userRemoved = new JSEvent(this);
    this._metadata = new JSEvent(this);
    this._deleted = new JSEvent(this);

    this._logTrace('cstr complete');
  }

  static get USER_ADDED() {
    return 'userAdded';
  }

  static get USER_UPDATED() {
    return 'userUpdated';
  }

  static get USER_REMOVED() {
    return 'userRemoved';
  }

  static get METADATA() {
    return 'metadata';
  }

  static get DELETED() {
    return 'deleted';
  }

  /**
   * @returns {JSEvent}
   */
  get userAdded() {
    return this._userAdded;
  }

  /**
   * @returns {JSEvent}
   */
  get userUpdated() {
    return this._userUpdated;
  }

  /**
   * @returns {JSEvent}
   */
  get userRemoved() {
    return this._userRemoved;
  }

  /**
   * @returns {JSEvent}
   */
  get metadata() {
    return this._metadata;
  }

  /**
   * @returns {JSEvent}
   */
  get deleted() {
    return this._deleted;
  }

  /**
   * @returns {RosterChange|null}
   */
  get value() {
    return this._value;
  }

  _changed(value) {
    this._value = RosterChange.apply(value);
    if (value) {
      this._logTrace(
        '_changed change=' +
          this._value.change +
          ', userId=' +
          this._value.userId,
      );
      if (value.change === RosterNotification.USER_ADDED) {
        this._userAdded.raise(this._value);
      } else if (value.change === RosterNotification.USER_UPDATED) {
        this._userUpdated.raise(this._value);
      } else if (value.change === RosterNotification.USER_REMOVED) {
        this._userRemoved.raise(this._value);
      } else if (value.change === RosterNotification.METADATA) {
        this._metadata.raise(this._value);
      } else if (value.change === RosterNotification.DELETED) {
        this._deleted.raise(this._value);
      }
    }
  }
}
