import LazyVar from '../../model/util/lazy-var';

export default class IPStackService {
  /**
   * @ngInject
   */
  constructor(HttpService, environment) {
    this._httpService = HttpService;
    this._environment = environment;

    this._coordinates = new LazyVar();
  }

  getLocation() {
    return this._coordinates.value(() => {
      return this._httpService
        .get(
          `https://api.ipstack.com/check?access_key=${this._environment.ipstack}`,
        )
        .then((data) => {
          return {
            lat: data.latitude,
            lng: data.longitude,
          };
        });
    });
  }
}
