'use strict';

export class ElementIntents {
  /**
   * @returns {string}
   */
  static get WORK() {
    return 'work';
  }

  /**
   * @returns {string}
   */
  static get CONTENT() {
    return 'content';
  }

  /**
   * @returns {string}
   */
  static get FEEDBACK() {
    return 'feedback';
  }

  /**
   * @returns {string}
   */
  static get VIEW() {
    return 'VIEW';
  }
}

/**
 * Contains metadata about an element
 */
export default class ElementMetadata {
  /**
   * @param ownerId {string} id of owning user
   * @param role {string} student | teacher
   * @param intent {string} content | work | feedback
   * @param [version] {string}
   */
  constructor(ownerId, role, intent, version) {
    this._value = {
      owner_id: ownerId,
      role: role,
      intent: intent,
      version: version,
    };
  }

  /**
   * @returns {string} The string id of the owner
   */
  get ownerId() {
    return this._value.owner_id;
  }

  /**
   * @returns {string} UserRoles: teacher | student
   */
  get role() {
    return this._value.role;
  }

  /**
   * @returns {string} content | work | feedback
   */
  get intent() {
    return this._value.intent;
  }

  /**
   * @returns {string} The version of the data part of the element
   */
  get version() {
    return this._value.version ? this._value._version : '1';
  }

  /**
   * @returns {object}
   */
  get value() {
    return this._value;
  }

  /**
   * @returns {ElementMetadata} A deep copy of this element
   */
  copy() {
    return ElementMetadata.copy(this);
  }

  /**
   * @param value {object}
   * @returns {ElementMetadata}
   */
  static fromObject(value) {
    let newMetadata = new ElementMetadata();
    newMetadata._value = value;
    return newMetadata;
  }

  /**
   * @param value {ElementMetadata}
   * @returns {ElementMetadata}
   */
  static copy(value) {
    let newMetadata = new ElementMetadata();
    newMetadata._value = angular.copy(value._value);
    return newMetadata;
  }
}
