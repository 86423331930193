'use strict';

import Control from './control';
import Size from './size';
import GestureManager from './gesture-manager';
import HexColors from '../../css-constants';
import warningIcon from '../../../assets/ckicon/warning.svg';
import StaticService from '../../services/static/static.service';

export default class FITBHandle extends Control {
  constructor(target) {
    super(target.id);

    this._size = new Size(30, 30);
    this._iconDiameter = 9;
    this._target = target;
    this._gestureManager = new GestureManager(
      this._target,
      this._target.canvas,
    );
  }

  createElement(root) {
    this._base = root.group();
    this._background = this._base.circle(0, 0, 0);
    this._warningIcon = this._base.image(warningIcon, 0, 0, 0, 0);
    this._tooltipBackground = this._base.rect(0, 0, 0, 0, 0);
    this._tooltip = this._base.text(0, 0, '');

    this._interactive = root.group();
    this._touch = this._interactive
      .circle(0, 0, 0)
      .addClass('touch-foreground add-fitb-answer');

    this._gestureManager.start(this._interactive.node);
    this._gestureManager.click.subscribe(this._clickHandler, this);

    this.mouseEnter.subscribe(this._tooltipShow, this);
    this.mouseLeave.subscribe(this._tooltipHide, this);
  }

  update() {
    this._base.attr({
      visibility: this._visibility,
    });

    this._background.attr({
      cx: this.location.x + 40,
      cy: this.location.y + 40,
      r: this.width / 2,
      fill: HexColors.CK_ROSTER_RED,
    });

    if (angular.isNumber(this._target.rotation)) {
      this._warningIcon.node.setAttribute(
        'transform',
        `rotate(${360 - this._target.rotation}, ${this.location.x}, ${this.location.y})`,
      );
    }

    this._warningIcon.attr({
      x: this.location.x + 40 - (this._iconDiameter + 10) / 2,
      y: this.location.y + 40 - (this._iconDiameter + 10) / 2,
      width: this._iconDiameter + 10,
      height: this._iconDiameter + 10,
      preserveAspectRatio: 'xMidYMid meet',
      style: 'pointer-events:none;',
    });

    this._touch.attr({
      cx: this.location.x + 40,
      cy: this.location.y + 40,
      r: this.width / 2,
      fill: 'transparent',
      cursor: 'pointer',
    });
  }

  _tooltipShow() {
    this._tooltipBackground.attr({
      x: this.location.x + 15,
      y: this.location.y + 55,
      width: 280,
      height: 20,
      fill: HexColors.CK_ELEMENT_LIGHT_GREY,
    });
    this._tooltip.attr({
      x: this.location.x + 20,
      y: this.location.y + 70,
      text: 'Click to add to list of acceptable answers',
    });
  }

  _tooltipHide() {
    this._tooltipBackground.attr({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      fill: 0,
    });
    this._tooltip.attr({
      x: 0,
      y: 0,
      text: '',
    });
  }

  _clickHandler() {
    if (confirm('Add this answer to list of acceptable answers?') === true) {
      let fitbUpdatedData =
        this._target.trackIncorrectResponseToBeMarkedCorrect();

      let childManipulativeElementId = fitbUpdatedData['childElementId'];
      let newFillInTheBlankParent = fitbUpdatedData['newFillInTheBlankParent'];
      let assignmentId = fitbUpdatedData['assignmentId'];

      // update acceptable list of answers to the parent fill in the blank (FITB) element.
      // we have access to the child manipulative id but do not have access to the parent manipulative id
      // so first make the service request to get the full child data information that has parent manipulative id.
      StaticService.get.ManipulativeElementService.getChildData(
        assignmentId,
        childManipulativeElementId,
      ).then((resp) => {
        let parentManipulativeElementId = resp['parentManipulativeId'];
        let questionId = resp['questionId'];
        newFillInTheBlankParent.id = parentManipulativeElementId;

        StaticService.get.ManipulativeElementService.updateParent(
          assignmentId,
          questionId,
          newFillInTheBlankParent,
        )
          .catch(() => {
            alert('Something went wrong! Please try again later.');
          })
          .finally(() => {
            // reload the page so that changes are registered and show up immediately
            window.location.reload();
          });
      });
    }
  }

  remove() {
    this._gestureManager.stop();
    this._gestureManager.click.unsubscribe(this._clickHandler, this);
    super.remove();
  }

  get hovering() {
    return this._gestureManager.hovering;
  }

  get mouseEnter() {
    return this._gestureManager.mouseEnter;
  }

  get mouseLeave() {
    return this._gestureManager.mouseLeave;
  }
}
