'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import FillInTheBlankParent, {
  FillInTheBlankAnswer,
} from '../ui/elements/fill-in-the-blank-parent';

export default class FillInTheBlankParentCodec extends Codec {
  constructor() {
    super();
    this._fillInTheBlankAnswerCodec = new FillInTheBlankAnswerCodec();
  }

  /**
   * @param value {FillInTheBlankParent}
   * @return {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, (size, center) => {
      return {
        id: value.id,
        type: value.type,
        size,
        center,
        answers: angular.toJson(
          value.answers.map((answer) =>
            this._fillInTheBlankAnswerCodec.encode(answer),
          ),
        ),
        font_size: value.fontSize,
        format: value.format,
      };
    });
  }

  /**
   * @param value {object}
   * @param id {string}
   * @return {Element}
   */
  decode(value, id) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      return new FillInTheBlankParent(
        id,
        metadata,
        location,
        size,
        angular
          .fromJson(value.data.answers)
          .map((answer) => this._fillInTheBlankAnswerCodec.decode(answer)),
        value.data.font_size,
        value.data.format,
      );
    });
  }
}

export class FillInTheBlankAnswerCodec extends Codec {
  /**
   * @param value {FillInTheBlankAnswer}
   * @returns {Object}
   */
  encode(value) {
    return {
      answer: value.answer,
      points: value.points,
    };
  }

  /**
   *
   * @param value<Object>
   * @returns {FillInTheBlankAnswer}
   */
  decode(value) {
    return new FillInTheBlankAnswer(value.answer, value.points, false);
  }
}
