'use strict';

import { HelpInboxType } from '../../components/help-inbox/help-inbox.directive';
import StudentSessionData from '../../model/domain/student-session-data';
import { FeedbackMenuController } from '../../components/feedback-menu/feedback-menu.controller';

/**
 * Allows teacher to view all questions on an assignment as a student
 */
export default class PreviewStudentAssignmentOverviewController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $scope,
    $state,
    $stateParams,
    $mdDialog,
    $mdPanel,
    CacheService,
    BreadcrumbService,
    AuthService,
    StudentCacheService,
    StudentAssignmentService,
    AssignmentWorkService,
  ) {
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;

    this._feedbackMenu = FeedbackMenuController.show;

    this._model = this._studentAssignmentService.getStudentAssignmentOverview(
      HelpInboxType.STUDENT,
    );

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._model.destroy());

    this.init();
  }

  /**
   * @return {StudentAssignmentOverview}
   */
  get model() {
    return this._model;
  }

  /**
   * @return {string}
   */
  get formattedClassCode() {
    // don't return actual class code;
    // students were using this preview class code to do work.
    return '[CLASS CODE WILL DISPLAY HERE]';
  }

  /**
   * @return {string}
   */
  get classCode() {
    return this.$stateParams.classCode;
  }

  /**
   * @return {string}
   */
  get assignmentId() {
    return this.$stateParams.assignmentId;
  }

  /**
   * @return {string}
   */
  get rosterId() {
    return this.$stateParams.rosterId;
  }

  /**
   * @return {string}
   */
  get name() {
    return this.$stateParams.name;
  }

  /**
   * @return {boolean}
   */
  get hasExportStudentWork() {
    return this._authService.authData.isPro;
  }

  init() {
    return this._authService
      .authAnonStudent(this.classCode, this.name, true)
      .then(() => {
        return this._studentCacheService
          .getOrCreateWorkForSelf(this.assignmentId, this.rosterId)
          .then((assignmentWork) => {
            return this._model.init(assignmentWork, this.rosterId);
          })
          .catch((error) => {
            if (error.message === StudentSessionData.singleDeviceLoginError) {
              StudentSessionData.launchSingleDeviceErrorDialog(
                this.$mdDialog,
                false,
              )
                .then(() => {
                  this.goBack();
                })
                .catch(() => {
                  this.reload();
                });
            } else if (
              error.message === StudentSessionData.assignmentHiddenError
            ) {
              StudentSessionData.launchAssignmentHiddenErrorDialog(
                this.$mdDialog,
                false,
              )
                .then(() => {
                  this.goBack();
                })
                .catch(() => {
                  this.reload();
                });
            }
            this._error = error;
          });
      });
  }

  /**
   * Reloads this controller by navigating to the same state and passing reload as true
   */
  reload() {
    this.$state.go(
      'root.preview-student-assignment-overview',
      {
        assignmentId: this.assignmentId,
        classCode: this.classCode,
        rosterId: this.rosterId,
        name: this.name,
      },
      {
        reload: true,
      },
    );
  }

  /**
   * @param questionId {string}
   */
  goToQuestion(questionId) {
    this._breadcrumbService.go(
      'root.preview-student-assignment-work',
      {
        assignmentId: this.assignmentId,
        classCode: this.classCode,
        rosterId: this.rosterId,
        name: this.name,
        question:
          this.model.data.assignmentWork.indexForQuestionId(questionId) + 1,
      },
      true,
    );
  }

  /**
   * @param request {HelpRequest}
   */
  goToFeedback(request) {
    this._breadcrumbService.go('^.anon-student-assignment-feedback', {
      classCode: this.$stateParams.classCode,
      name: this.$stateParams.name,
      request: request,
    });
  }

  goBack() {
    this._cacheService.clearUserStatusDetails();
    this._breadcrumbService.goBack('root.login');
  }

  /**
   * Logs the user out and sends the user to the account login page
   */
  logOut() {
    this._authService.signOut();
  }

  /**
   * @param ev {$event}
   */
  openFeedbackMenu(ev) {
    this._feedbackMenu(
      this.$mdPanel,
      this.$q,
      ev,
      this.model.data.questionFeedbackList,
      this.model.data.assignmentWork,
    ).then(({ questionId }) => {
      this.goToQuestion(questionId);
    });
  }
}
