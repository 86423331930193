'use strict';
import moment from 'moment';
import ContractCodec from '../../model/codec/contract-codec';
import NewContractStudentCodec from '../../model/codec/new-contract-student-codec';
import UserCodec from '../../model/codec/user-codec';

export default class ContractService {
  constructor(environment, HttpService, $log, $q) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    this.$log = $log;
    this.$q = $q;

    this._contractCodec = new ContractCodec();
    this._newContractStudentCodec = new NewContractStudentCodec();
    this._userCodec = new UserCodec();
  }

  /**
   * @param contractId {string}
   * @returns {Promise.<contract>}
   */
  get(contractId) {
    return this._httpService
      .optionalAuthGet(this._uri(`/v1/contracts/${contractId}`))
      .then((data) => {
        return this._contractCodec.decode(data);
      });
  }

  /**
   * @param userId {string}
   * @param includeExpired {boolean}
   * @param excludeTrials {boolean}
   * @returns {Promise.<Contract[]>}
   */
  getContractsForUser(userId, includeExpired = false, excludeTrials = false) {
    let hasQueries = includeExpired || excludeTrials;
    let didAppendToQueryStr = false;
    let queryStr = hasQueries ? '?' : '';
    if (includeExpired) {
      queryStr += 'all=true';
      didAppendToQueryStr = true;
    }
    if (excludeTrials) {
      if (didAppendToQueryStr) {
        queryStr += '&';
      }
      queryStr += 'exclude_trials=true';
      didAppendToQueryStr = true;
    }
    return this._httpService
      .authGet(this._uri(`/v1/users/${userId}/contracts${queryStr}`))
      .then(({ contracts }) => {
        return contracts.map((contract) =>
          this._contractCodec.decode(contract),
        );
      });
  }

  /**
   * @param contractId {string}
   * @returns {Promise.<User[]>}
   */
  getUsers(contractId) {
    return this._httpService
      .authGet(this._uri(`/v1/contracts/${contractId}/users`))
      .then((response) =>
        response.users.map((user) => this._userCodec.decode(user)),
      );
  }

  /**
   * Returns users from contract who have not logged in over {monthsInactive}
   * @param contractId {string}
   * @param monthsInactive {string}
   * @returns {Promise.<User[]>}
   */
  getInactiveUsers(contractId, monthsInactive = 6) {
    return this._httpService
      .authGet(
        this._uri(
          `/v1/contracts/${contractId}/inactive-users?months_inactive=${monthsInactive}`,
        ),
      )
      .then((response) => response.users.map((user) => user));
  }

  /**
   * Removes the given user from the contract
   * @param contractId
   * @param userId
   * @returns {Promise.<T>}
   */
  removeUser(contractId, userId) {
    return this._httpService.authDelete(
      this._uri(`/v1/contracts/${contractId}/users/${userId}`),
    );
  }

  /**
   * @returns {Promise.<{features: string[], contractExpires: moment}>}
   */
  getFeatures() {
    return this._httpService.authGet(this._uri('/v1/features')).then((data) => {
      return {
        features: data.features,
        contractExpires: moment(data.contract_expires),
        assignmentLimit: data.assignment_limit,
      };
    });
  }

  /**
   * @param contractId {string}
   * @param students {NewContractStudent[]}
   * @return {Promise.<{object_name, field, rejected_value}[]>}
   */
  validateStudents(contractId, students) {
    return this._httpService
      .authPost(this._uri(`/v1/contracts/${contractId}/validate-students`), {
        students: students.map((student) =>
          this._newContractStudentCodec.encode(student),
        ),
      })
      .then((data) => {
        return data.errors;
      });
  }

  /**
   * @param contractId {string}
   * @param students {NewContractStudent[]}
   * @return {Promise}
   */
  addStudents(contractId, students) {
    return this._httpService
      .authPost(this._uri(`/v1/contracts/${contractId}/students`), {
        students: students.map((student) =>
          this._newContractStudentCodec.encode(student),
        ),
      })
      .then((data) => {
        return data.users.map((user) => this._userCodec.decode(user));
      });
  }

  /**
   * @param contractId {string}
   * @param userId {string}
   * @return {Promise}
   */
  addUser(contractId, userId) {
    return this._httpService.authPost(
      this._uri(`/v1/contracts/${contractId}/users`),
      {
        user_id: userId,
      },
    );
  }

  /**
   * @param contractId
   * @param userId
   * @param isAdmin
   * @returns {Promise}
   */
  makeUserContractAdmin(contractId, userId, isAdmin) {
    return this._httpService
      .authPut(this._uri(`/v1/contracts/${contractId}/users/${userId}/admin`), {
        admin: isAdmin,
      })
      .then((data) => {
        return {
          code: data.code,
          contractId: data.contract_id,
          rosterId: data.roster_id,
        };
      })
      .catch((error) => {
        this.$log.error(error);
      });
  }

  /**
   * Creates a trial and makes the user associated with the user id admin
   *
   * @param userId {string} the id of the user to receive the trial
   * @param contractName {string} the name of the contract
   * @returns {Promise<{token:string,contract:Contract}>}
   */
  createTrial(userId, contractName) {
    if (!userId) {
      return this.$q.reject(
        'Could not start trial. userId is null or undefined.',
      );
    }
    return this._httpService.authPost(this._uri('/v1/contracts/trial'), {
      user_id: userId,
      name: contractName,
    });
  }

  /**
   * Gets the expiration of a trial that starts at call time
   * @return {Promise<moment>}
   */
  getExpiration() {
    return this._httpService
      .authGet(this._uri('/v1/contracts/trial/expiration'))
      .then(({ expiration }) => {
        return moment(expiration);
      });
  }

  /**
   * Deletes a contract. Must be an admin to succeed.
   * @param contractId {string} the id of the contract to delete
   * @returns {Promise}
   */
  deleteContract(contractId) {
    return this._httpService.authDelete(
      this._uri(`/v1/contracts/${contractId}`),
    );
  }

  /**
   * Returns a string that specifies a uri given a path.
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
