'use strict';

import moment from 'moment';
import Notification from './notification';
import JSEvent from '../util/js-event';

/**
 * Notification for user rosters
 */
export default class UserRosterNotification extends Notification {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param userId {string}
   * @param FirebaseService {FirebaseService}
   */
  constructor(notificationShardKey, userId, FirebaseService) {
    super(
      Notification.V3,
      notificationShardKey,
      `users/${userId}/rosters`,
      FirebaseService,
      false,
      'UserRosterNotification',
    );

    this._userId = userId;

    /** @type {JSEvent.<{rosterId: string, change: string, t: moment}>} */
    this._updated = new JSEvent(this);

    this._changes = {
      USER_ADDED: 'userAdded',
      USER_REMOVED: 'userRemoved',
      METADATA: 'metadata',
      DELETED: 'deleted',
    };

    this._logTrace('cstr complete');
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {JSEvent.<{rosterId: string, change: string, t: moment}>}
   */
  get updated() {
    return this._updated;
  }

  /**
   * @param value {{roster_id: string, change: string, t: string}}
   * @private
   */
  _changed(value) {
    this._logTrace(
      '_changed change=' + value.change + ', rosterId=' + value.roster_id,
    );
    this._updated.raise({
      rosterId: value.roster_id,
      change: value.change,
      t: moment(value.t),
      assignmentId: value.assignment_id,
    });
  }

  /**
   * @returns {{USER_ADDED: string, USER_REMOVED: string, METADATA: string, DELETED: string}}
   */
  get CHANGES() {
    return this._changes;
  }
}
