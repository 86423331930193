'use strict';

import User from '../../model/domain/user';
import CreateContractStudentsDialog from '../create-contract-students-dialog/create-contract-students-dialog.controller';
import LoadingDialog from '../loading-dialog/loading-dialog.controller';
import AddAnonStudentDialog from '../add-anon-student-dialog/add-anon-student-dialog.controller';
import StudentAccountChoiceDialogController from '../student-account-choice-dialog/student-account-choice-dialog.controller';
import ErrorDialogController from '../error-dialog/error-dialog.controller';
import Contract from '../../model/domain/contract';
import AddProStudentsDialogTemplate from './add-pro-students-dialog.html';

/**
 * Adds pro students to a roster
 */
export default class AddProStudentsDialogController {
  /**
   * @ngInject
   */
  constructor(
    $q,
    $mdDialog,
    UserService,
    AuthService,
    RosterService,
    CacheService,
    pro,
    roster,
    rosterMembers,
    selectedUserIds,
    deferred,
  ) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;

    /** @type {UserService} */
    this._userService = UserService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {CacheService} */
    this._cacheService = CacheService;

    /** @type {ProInfo} */
    this._pro = pro;
    /** @type {Roster} */
    this._roster = roster;
    /** @type {Set.<User>} */
    this._rosterMembers = rosterMembers;
    /** @type {Set.<User>} */
    this._rosterMemberIds = new Set(
      [...rosterMembers.values()].map((user) => user.id),
    );
    /** @type {Set.<string>} */
    this._selectedUserIds = selectedUserIds;
    /** @type {$q.defer.<Set.<string>>} */
    this._deferred = deferred;
    /** @type {User[]} */
    this._students = null;
    this._schools = [];
    this._contracts = [];
    this._verifiedContracts = [];
    this.searchTerm = null;
    this._filteredStudents = null;
    this._noStudentMessage = '';
    this._selectedContract = '';

    this._createContractStudentsDialog = CreateContractStudentsDialog.show;
    this._showLoadingDialog = LoadingDialog.show;
    this._errorDialog = ErrorDialogController.show;
    this._showAddStudentDialog = AddAnonStudentDialog.show;
    this._showStudentAccountChoice = StudentAccountChoiceDialogController.show;

    this.init();
  }

  init() {
    this.$q
      .all({
        schools: this._cacheService.getSchools(false),
        contracts: this._cacheService.getContracts(false),
      })
      .then(({ schools, contracts }) => {
        this._schools = schools;
        this._contracts = contracts;
        this._verifiedContracts = this._getVerifiedContracts(
          this._contracts,
          this._schools,
          this._authService.currentUserId,
        );
        this._selectedContract = contracts[0];

        if (this._verifiedContracts.length) {
          this._selectedContract = this._verifiedContracts[0];
          return this._userService.getContractUsers(this._selectedContract.id);
        }
      })
      .then((contractUsers = []) => {
        this.displayStudentsList(contractUsers);
        this.search();
      });
  }

  /**
   Filters contractUsers list to only display students who are not part of the of the roster already
   */
  displayStudentsList(contractUsers) {
    this._students = contractUsers
      .filter((x) => {
        if (x.isStudent && !this._rosterMemberIds.has(x.id)) {
          if (this._selectedUserIds.has(x.id)) {
            x.toggled = true;
          }
        }

        return x.isStudent && !this._rosterMemberIds.has(x.id);
      })
      // Sort by name
      .sort(User.SORT_NAME_ASC);
  }

  /**
   * Gets all of the contracts the the user is allowed to view students for
   */
  _getVerifiedContracts(contracts, schools, currentUserId) {
    if (!contracts || !schools) {
      return [];
    }

    return contracts.filter((contract) => {
      let school = schools.find((school) => school.contractId === contract.id);
      let verified = school && school.isVerifiedFor(currentUserId);

      // If this contract does not belong to a school or it does and the user is 'verified', use the contract
      return !school || verified;
    });
  }

  get listMessage() {
    let schoolDisplay;
    if (this.loading || this.students.length > 0) {
      return '';
    }

    if (this.cantViewStudents()) {
      let school = this._schools.find(
        (school) =>
          school.contractId === this._selectedContract.id &&
          !school.isVerifiedFor(this._authService.currentUserId),
      );

      schoolDisplay = school && school.name ? ` at ${school.name}` : '';

      return `To ensure student data privacy${schoolDisplay}, Portfolio students can only be accessed by current and verified teachers. Please contact your school's admin to verify your teacher account. To see who your school admin is, navigate to your Profile and Organization tab.`;
    } else if (!this.searchTerm) {
      // TODO isn't accurate as if all students at school are on roster it still shows this message
      return this.noStudentsMessage;
    } else if (this.searchTerm) {
      return `No students match the search term '${this.searchTerm}'`;
    }
    return '';
  }

  cantViewStudents() {
    const isVerifiedOnSelectedContract = this._verifiedContracts.find(
      (contract) => contract.id === this._selectedContract.id,
    );
    return !this.loading && !isVerifiedOnSelectedContract;
  }

  /**
   * @return {string}
   */
  get noStudentsMessage() {
    if (!this._noStudentMessage && this._contracts) {
      let activeContract = Contract.ActiveContract(this._contracts);
      let orgDisplay =
        activeContract &&
        (activeContract.isProSchool || activeContract.isProDistrict)
          ? 'school'
          : 'classroom';
      this._noStudentMessage = `There are no students in your ${orgDisplay}.`;
    }

    return this._noStudentMessage;
  }

  search() {
    if (this.searchTerm && this._students) {
      const searchTerm = this.searchTerm.toLowerCase();
      this._filteredStudents = this._students.filter((student) => {
        const login = (student.login || '').toLowerCase() || '';
        return (
          student.name.toLowerCase().includes(searchTerm) ||
          login.includes(searchTerm)
        );
      });
    } else {
      this._filteredStudents = null;
    }
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return !this._students;
  }

  /**
   * @returns {$q.defer.<Set.<string>>}
   */
  get deferred() {
    return this._deferred;
  }

  /**
   * @returns {User[]}
   */
  get students() {
    return this._filteredStudents || this._students;
  }

  get rosterName() {
    return this._roster.name;
  }

  toggleStudent(student) {
    if (this._selectedUserIds.has(student.id)) {
      student.toggled = false;
      this._selectedUserIds.delete(student.id);
    } else {
      student.toggled = true;
      this._selectedUserIds.add(student.id);
    }
  }

  complete() {
    this.$mdDialog.hide();

    if (this._selectedUserIds.size === 0) {
      return;
    }

    const promise = this.$q.all(
      [...this._selectedUserIds.values()].map((userId) =>
        this._rosterService.addMember(this._roster.id, userId),
      ),
    );

    this._showLoadingDialog(this.$mdDialog, promise);

    promise
      .then(() => {
        this.deferred.resolve(this._selectedUserIds);
      })
      .catch((error) => {
        this._errorDialog(
          this.$mdDialog,
          `Oops! Something went wrong adding students to ${this._roster.name}`,
          'Please try again and if you continue to have problems send us an email at support@classkick.com',
        );
      });
  }

  cancel() {
    this.$mdDialog.cancel();
    this.deferred.reject();
  }

  goBackToSelection() {
    this._showStudentAccountChoice(
      this.$mdDialog,
      this._pro,
      this._roster,
      this._rosterMembers,
    );
  }

  createStudentAccounts() {
    this.$mdDialog.cancel();

    this._createContractStudentsDialog(this.$mdDialog).then((newUserIds) => {
      this.reopenCallback(newUserIds);
    });
  }

  /**
   * Generates a callback that can be used to reopen this dialog
   * if we go into the Create Student Accounts flow
   *
   * @returns {function(Set.<string>)}
   */
  get reopenCallback() {
    return (newUserIds) => {
      if (newUserIds) {
        newUserIds.forEach((id) => {
          this._selectedUserIds.add(id);
        });
      }

      return AddProStudentsDialogController.show(
        this.$mdDialog,
        this.$q,
        this._pro,
        this._roster,
        this._rosterMembers,
        this._selectedUserIds,
        this._deferred,
      );
    };
  }

  /**
   * Retrieves list of students and displays them by contract
   */
  selectContract(contract) {
    this._selectedContract = contract;
    this._userService.getContractUsers(contract.id).then((contractUsers) => {
      this.displayStudentsList(contractUsers);
    });
  }

  /**
   * @param $mdDialog
   * @param $q
   * @param pro {ProInfo}
   * @param roster {Roster}
   * @param [rosterMembers] {Set.<User>}
   * @param [selectedUserIds] {Set.<string>}
   * @param [completion] {$q.defer.<Set.<string>>}
   * @returns {Promise.<Set.<string>>}
   */
  static show(
    $mdDialog,
    $q,
    pro,
    roster,
    rosterMembers,
    selectedUserIds,
    completion,
  ) {
    // We're using a deferred promise because we know that this dialog can be closed without
    // the 'Add Students' process actually being completed. By working with a promise other
    // than the $mdDialog.show one, we can have more control over things.
    if (!completion) {
      completion = $q.defer();
    }

    $mdDialog.show({
      controller: AddProStudentsDialogController,
      template: AddProStudentsDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        pro: pro,
        roster: roster,
        rosterMembers: rosterMembers || new Set(),
        selectedUserIds: selectedUserIds || new Set(),
        deferred: completion,
      },
    });

    return completion.promise;
  }
}
