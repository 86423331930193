'use strict';

import HexColors from '../../css-constants';
import FinancialAidDialogTemplate from './financial-aid-dialog.html';

export default class FinancialAidDialogController {
  /**
   * @ngInject
   */
  constructor($mdDialog, $filter, order) {
    this.$mdDialog = $mdDialog;
    this.$filter = $filter;

    this._order = order;
    this._name = '';
    this._email = '';
    this._role = '';
    this._confirmation = false;
    this._signature = '';

    this._state = this.Application;
  }

  get state() {
    return this._state;
  }

  get Application() {
    return 'application';
  }

  get Loading() {
    return 'loading';
  }

  /**
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @param value {string}
   */
  set name(value) {
    this._name = value;
  }

  /**
   * @return {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @param value {string}
   */
  set email(value) {
    this._email = value;
  }

  /**
   * @return {string}
   */
  get role() {
    return this._role;
  }

  /**
   * @param value {string}
   */
  set role(value) {
    this._role = value;
  }

  /**
   * @return {boolean}
   */
  get confirmation() {
    return this._confirmation;
  }

  /**
   * @param value {boolean}
   */
  set confirmation(value) {
    this._confirmation = value;
  }

  /**
   * @return {string}
   */
  get signature() {
    return this._signature;
  }

  /**
   * @param value {string}
   */
  set signature(value) {
    this._signature = value;
  }

  get purchasePeriod() {
    return (
      this._order && this._order.start.year() + '-' + this._order.end.year()
    );
  }

  get confirmationStyle() {
    return this._confirmationStyle;
  }

  get perYearTotal() {
    return this._order.planPrice - this._order.unitDiscount;
  }

  get quoteTotal() {
    return this._order.total;
  }

  currency(amount) {
    return this.$filter('currency')(amount, '$', 0);
  }

  submit() {
    if (!this._confirmation) {
      this._confirmationStyle = { color: HexColors.CK_WARN };
      return;
    }

    this.$mdDialog.hide({
      name: this._name,
      email: this._email,
      role: this._role,
      signature: this._signature,
    });
  }

  static show($mdDialog, order) {
    return $mdDialog.show({
      controller: FinancialAidDialogController,
      template: FinancialAidDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        order,
      },
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
