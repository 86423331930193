'use strict';

import Validation from '../../model/util/validation';
import LocationUtil from '../../model/util/location-util';
import SelectPeersDialogController from '../select-peers-dialog/select-peers-dialog.controller';
import ProTrialConstants from '../../model/util/pro-trial-util';
import ReferralDialogTemplate from './referral-dialog.html';
import { Locations } from '../../services/mixpanel/mixpanel.service';
import { ABTest } from '../../services/ab-test/ab-test-service';
import Order from '../../model/domain/order';

export default class ReferralDialogController {
  /**
   * @ngInject
   */

  constructor(
    $scope,
    $mdDialog,
    $mdToast,
    $log,
    $location,
    OrderService,
    CacheService,
    AuthService,
    AnalyticsService,
    user,
    order,
    from,
    to,
    subject,
    body,
    clickedFrom,
    isProTrialReferral,
    isProSchoolOrDistrictReferral,
    activeOrganization,
  ) {
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$log = $log;
    this.$location = $location;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._state = this.DraftEmail;
    this._selectPeersDialog = SelectPeersDialogController.show;
    this._clickedFrom = clickedFrom;
    this._activeOrganization = activeOrganization;

    this._user = user;
    this._order = order;
    this._from = from || '';
    this._to = to || '';
    this.emailRegex = Validation.EmailRegex;
    this._subject = subject || '';
    this._body = body || '';
    this._internalEmailCc = ['pro@classkick.com'];
    this._fromName = undefined;
    this._isProTrialReferral = isProTrialReferral || false;
    this._isProSchoolOrDistrictReferral =
      isProSchoolOrDistrictReferral || false;
    this._toastMessage = '';
    this._invalidEmails = '';
    this.showStickerCopy = false;
    this.invalidSubjectLine = false;

    this.init();

    this._analyticsService.sendEvent({
      eventTag: 'referralDialog_viewed',
      properties: {
        location: this._clickedFrom,
      },
    });
  }

  init() {
    if (this.isLoggedIn) {
      this._cacheService
        .getTestSegment(ABTest.StickerReferrals)
        .then((result) => {
          this.showStickerCopy = result;
        });
    }
  }

  /**
   * @return {Boolean}
   */
  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  /**
   * @return {string}
   */
  get invalidEmails() {
    return this._invalidEmails;
  }

  /**
   * @param value {string}
   */
  set invalidEmails(value) {
    this._invalidEmails = value;
  }

  get Loading() {
    return 'loading';
  }

  get nav() {
    return Locations.NAV;
  }

  get header() {
    return Locations.HEADER;
  }

  get proTrialOverlay() {
    return ProTrialConstants.PRO_TRIAL_OVERLAY;
  }

  get DraftEmail() {
    return 'draft_email';
  }

  get EmailSent() {
    return 'email_sent';
  }

  get EmailError() {
    return 'email_error';
  }

  get mdrPid() {
    return this._activeOrganization ? this._activeOrganization.mdrPid : null;
  }

  get isFromAssignmentPage() {
    return (
      this._clickedFrom === Locations.ASSIGNMENT_EDIT ||
      this._clickedFrom === Locations.ASSIGNMENT_ASSIGN ||
      this._clickedFrom === Locations.ASSIGNMENT_VIEW_WORK
    );
  }

  get buttonText() {
    if (this.mdrPid) {
      return 'SELECT COLLEAGUES';
    }
    return 'SEND';
  }

  get isProTrialReferral() {
    return this._isProTrialReferral;
  }

  get isProSchoolOrDistrictReferral() {
    return this._isProSchoolOrDistrictReferral;
  }

  get from() {
    return this._from;
  }

  set from(value) {
    this._from = value;
  }

  get to() {
    return this._to;
  }

  set to(value) {
    this._to = value;
  }

  get activeOrganization() {
    return this._activeOrganization;
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = this.to.split(/[,;]/);

    if (emails.length > 10) {
      return false;
    }

    let isValid;

    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  get subject() {
    return this._subject;
  }

  set subject(value) {
    this._subject = value;
  }

  get body() {
    return this._body;
  }

  set body(value) {
    this._body = value;
  }

  get clickedFrom() {
    return this._clickedFrom;
  }

  setValidSubjectLine(scope) {
    this.invalidSubjectLine = !this._subject.length > 0;
    scope.referralForm.subjectInput.$setValidity(
      'pattern',
      this._subject.length > 0,
    );
  }

  submitForm(scope) {
    if (!this.mdrPid && !this.isEmailListValid) {
      if (scope.referralForm.toInput) {
        scope.referralForm.toInput.$setValidity('pattern', false);
      }
      return (this._invalidEmails = !this.isEmailListValid);
    }

    if (this.invalidSubjectLine) {
      return this.setValidSubjectLine(scope);
    } else if (!this.body) {
      return;
    }

    if (this.mdrPid) {
      this._analyticsService.selectPeersDialogOpened(this._clickedFrom);
    } else {
      if (this._clickedFrom === this.proTrialOverlay) {
        this._analyticsService.proTrialReferralSent(this.proTrialOverlay);
      }

      if (this._isProTrialReferral) {
        this._analyticsService.proTrialReferralSent(this._clickedFrom);
      }

      if (this._isProSchoolOrDistrictReferral) {
        this._analyticsService.proSchoolOrDistrictReferralSent(
          this._clickedFrom,
        );
      }
    }

    this.mdrPid ? this.showPeerDialog() : this.sendReferral();
  }

  showPeerDialog() {
    let body = this.formatBody();

    if (this._user) {
      this._fromName = `${this._user.firstLastCombo} via Classkick`;
    } else {
      this._fromName = `${this._from} via Classkick`;
    }

    let emailConfig = {
      orderId: '000000',
      from: this._from,
      internalEmail: this._internalEmailCc,
      subject: this._subject,
      body,
      fromName: this._fromName,
      user_id: this._user ? this._user.id : null,
    };

    this._selectPeersDialog(
      this.$mdDialog,
      this._activeOrganization.id,
      emailConfig,
      this._clickedFrom,
    );
  }

  sendReferral() {
    const body = this.formatBody();

    this._state = this.Loading;

    if (this._user) {
      this._fromName = `${this._user.firstLastCombo} via Classkick`;
    } else {
      this._fromName = `${this._from} via Classkick`;
    }

    let eventTag = null;
    if (this._clickedFrom === this.proTrialOverlay) {
      eventTag = `${this.proTrialOverlay}:proTrialReferralEmail_sent`;
    }

    if (this._isProTrialReferral) {
      eventTag = `${this._clickedFrom}:proTrialReferralEmail_sent`;
    }

    if (this._isProSchoolOrDistrictReferral) {
      eventTag = `${this._clickedFrom}:proSchoolOrDistrictReferralEmail_sent`;
    }

    this._orderService
      .share(
        this._order.id,
        this._from,
        this._to.split(/[,;]/).map((email) => email.trim()),
        this._internalEmailCc,
        this._subject,
        body,
        this._fromName,
        {
          user_id: this._user ? this._user.id : null,
          event_tag: eventTag,
        },
      )
      .then((data) => {
        if (this.isProTrialReferral) {
          this.displayToastMessage('You Gifted Classkick to a Colleague');
          this.$mdDialog.cancel();
        } else if (this.isProSchoolOrDistrictReferral) {
          this.displayToastMessage('You Shared Classkick With a Colleague');
          this.$mdDialog.cancel();
        } else {
          this._state = this.EmailSent;
        }
      })
      .catch(() => {
        this._state = this.EmailError;
      });
  }

  setEmailValidity(scope) {
    this.invalidEmails = false;
    scope.referralForm.toInput.$setValidity('pattern', this.isEmailListValid);
  }

  formatBody() {
    return (
      this._body.split('\n').join('<br>') +
      '<br><br><i>Sent from my Classkick Account</i></br></br><br><a href="https://classkick.com/" target="_blank">Create an account on Classkick</a>'
    );
  }

  cancel() {
    if (this._clickedFrom === this.proTrialOverlay) {
      this._analyticsService.proTrialReferralDismissed(this.proTrialOverlay);
    }

    if (this._isProTrialReferral) {
      this._analyticsService.proTrialReferralDismissed(this._clickedFrom);
    }
    if (this._isProSchoolOrDistrictReferral) {
      this._analyticsService.proSchoolOrDistrictReferralDismissed(
        this._clickedFrom,
      );
    }

    this.$mdDialog.cancel();
  }

  copyInviteText() {
    navigator.clipboard
      .writeText(this.body)
      .then(() => {
        this.displayToastMessage('Successfully copied to clipboard');
        this._analyticsService.inviteTextCopied(
          this._clickedFrom,
          this._isProSchoolOrDistrictReferral,
        );
      })
      .catch((error) => {
        this.$log.error(error);
        this.displayToastMessage('Sorry there was an error copying message');
      });
  }

  displayToastMessage(message) {
    this._toastMessage = message;
    let toastClassName = 'referral-toast';
    const config = this.$mdToast
      .simple(this._toastMessage)
      .action(' ')
      .actionKey('x')
      .position('top right')
      .toastClass(toastClassName)
      .hideDelay(4000);
    this.$mdToast.show(config);
  }

  displayTitle() {
    if (this.isProTrialReferral) {
      return 'Gift Classkick Pro to Your Colleagues for Free';
    } else {
      return 'Share Classkick Pro With Colleagues at Your School';
    }
  }

  /**
   * Launches share order dialog
   * @param $mdDialog
   * @param user
   * @param order {Order}
   * @param from {string}
   * @param to {string[]  | undefined}
   * @param subject {string}
   * @param body {string}
   * @param clickedFrom {string | undefined}
   * @param isProTrialReferral {boolean | undefined}
   * @param isProSchoolOrDistrictReferral {boolean | undefined}
   * @param activeOrganization {Organization | undefined}
   * @returns {*}
   */
  static show(
    $mdDialog,
    user,
    order,
    from,
    to,
    subject,
    body,
    clickedFrom,
    isProTrialReferral,
    isProSchoolOrDistrictReferral,
    activeOrganization,
  ) {
    return $mdDialog.show({
      controller: ReferralDialogController,
      template: ReferralDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        user,
        order,
        from,
        to,
        subject,
        body,
        clickedFrom,
        isProTrialReferral,
        isProSchoolOrDistrictReferral,
        activeOrganization,
      },
    });
  }

  static get ProTrialReferralTitle() {
    return 'Join Me on Classkick Pro for FREE Today!';
  }

  static get ProSchoolAndDistrictReferralTitle() {
    return 'Join Our School on Classkick Pro Today!';
  }

  static formatProTrialMessage(name, school, $location) {
    if (school) {
      return `Hi there, \n\nGreat news - our school has free trial access to Classkick Pro School! Try it by creating an account and joining ${school} in Classkick.\n${LocationUtil.absRootUrl($location)}/signup/teacher\n\nIt lets me see my students thinking in real-time and SO much more. Here's a video of some Classkick magic. I think you'll love it, let me know if you need any support getting started! \nhttps://youtu.be/l5vza3HTW5I \n\n${name}`;
    }
    return `Hi there, \n\nGreat news - our school has free trial access to Classkick Pro School! Try it by creating an account and joining Classkick.\n${LocationUtil.absRootUrl($location)}/signup/teacher\n\nIt lets me see my students thinking in real-time and SO much more. Here's a video of some Classkick magic. I think you'll love it, let me know if you need any support getting started! \nhttps://youtu.be/l5vza3HTW5I \n\n${name}`;
  }

  static formatProSchoolorDistrictReferralMessage(
    userId,
    name,
    school,
    $location,
  ) {
    // utm parameters for attribution to the inviting colleague (lexicon: k-factor)
    let utmSource = userId;
    let utmMedium = 'email';
    let utmCampaign = 'invite_colleagues';

    if (school) {
      return `Hi there, \n\nGreat news - our school has access to Classkick Pro! Try it by creating an account and joining ${school} in Classkick.\n\nClick to signup - ${LocationUtil.absRootUrl($location)}/signup/teacher?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}\n\nIt lets me see my students thinking in real-time and SO much more. Here's a video of some Classkick magic. I think you'll love it, let me know if you need any support getting started! \nhttps://youtu.be/l5vza3HTW5I \n\n${name}`;
    }
    return `Hi there, \n\nGreat news - our school has access to Classkick Pro! Try it by creating an account and joining Classkick.\n\nClick to signup - ${LocationUtil.absRootUrl($location)}/signup/teacher?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}\n\nIt lets me see my students thinking in real-time and SO much more. Here's a video of some Classkick magic. I think you'll love it, let me know if you need any support getting started! \nhttps://youtu.be/l5vza3HTW5I \n\n${name}`;
  }
}
