'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import MultipleChoiceChild from '../ui/elements/multiple-choice-child';
import Size from '../ui/size';
import StaticService from '../../services/static/static.service';

/**
 * Encodes/decodes a multiple choice child element
 */
export default class MultipleChoiceChildCodec extends Codec {
  encode(value) {
    return ElementCodec.preEncode(value, (size, center) => {
      return {
        type: value.type,
        center,
        size,
        options: angular.toJson(value.options),
        answer: value.answer,
      };
    });
  }

  decode(value, id) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      const options = angular.fromJson(value.data.options);
      const chosenSize = new Size(40 + options.length * 48, size.height);

      return new MultipleChoiceChild(
        id,
        metadata,
        location,
        chosenSize,
        options,
        value.data.answer,
      );
    });
  }
}
