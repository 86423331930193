'use strict';

import InputUtils from '../../model/util/input-utils';
import StaticService from '../../services/static/static.service';
import Textbox from '../../model/ui/elements/textbox';

export default class InputPipe {
  constructor() {
    /** @type {jQuery} */
    this._input = null;
    /** @type {Textbox} */
    this._textbox = null;
    this._body = angular.element('body');
    this._anonHandleInput = (ev) => this._handleInput(ev);
    this._anonHandleKeydown = (ev) => this._handleKeydown(ev);
    this._anonHandleSelect = (ev) => this._handleSelect(ev);
  }

  focus(textbox) {
    this._input = textbox.hiddenTextInput;
    this._textbox = textbox;

    this._syncVisibleToHidden(this._input, this._textbox);
    this._moveHiddenInput(this._input, this._textbox);

    this._input.on('input', this._anonHandleInput);
    this._input.on('select', this._anonHandleSelect);
    this._body.on('keydown', this._anonHandleKeydown);
    this._textbox.selectionChanged.subscribe(
      this._handleSelectionChanged,
      this,
    );
    this.focusInput();
  }

  blur() {
    if (this._input) {
      this._input.off('input', this._anonHandleInput);
      this._input.off('select', this._anonHandleSelect);
      this._body.off('keydown', this._anonHandleKeydown);
      this._textbox.selectionChanged.unsubscribe(
        this._handleSelectionChanged,
        this,
      );
      this._input.val('');
      this._input.blur();
    }
  }

  _handleInput() {
    this._syncHiddenToVisible(this._input, this._textbox);
    this._moveHiddenInput(this._input, this._textbox);
  }

  _handleSelect() {
    this._syncHiddenToVisibleSelection(this._input, this._textbox);
    this._moveHiddenInput(this._input, this._textbox);
  }

  _handleKeydown(ev) {
    if (this._isArrow(ev)) {
      ev.preventDefault();

      if (ev.shiftKey) {
        this._textbox.shiftArrowPressed(ev.which);
      } else {
        this._textbox.arrowPressed(ev.which);
      }

      this._syncVisibleToHidden(this._input, this._textbox);
      this._textbox.setActiveColor(this._startIndex);
    } else if (this._isTab(ev)) {
      ev.preventDefault();

      if (ev.shiftKey && this._textbox.shiftTabPressed) {
        this._textbox.shiftTabPressed();
      } else if (this._textbox.tabPressed) {
        this._textbox.tabPressed();
      }
    }
  }

  _handleSelectionChanged() {
    this._syncVisibleToHidden(this._input, this._textbox);
    this._textbox.setActiveColor(this._startIndex);
  }

  /**
   * Sets the text value, text selection, and caret position of hidden input to the respective values
   * of the visible input
   * @param input {object} a reference to the hidden input
   * @param textbox {object} a reference to the selected textbox
   * @private
   */
  _syncVisibleToHidden(input, textbox) {
    // set text content
    input.val(textbox.text);
    InputUtils.selectRange(input[0], textbox.startIndex, textbox.endIndex);
  }

  /**
   * Sets the text value, text selection, and caret position of visible input (our textbox in the svg dom)
   * to the respective values in the hidden input
   * @param input {object} a reference to the hidden input
   * @param textbox {object} a reference to the selected textbox
   * @private
   */
  _syncHiddenToVisible(input, textbox) {
    this._syncHiddenToVisibleInput(input, textbox);
    this._syncHiddenToVisibleSelection(input, textbox);
  }

  _syncHiddenToVisibleInput(input, textbox) {
    // text content
    let text = input.val();

    if (text.length <= Textbox.CHARACTER_LIMIT) {
      textbox.setText(text, this._startIndex, this._endIndex);
    } else {
      this._syncVisibleToHidden(input, textbox);
      return;
    }
  }

  _syncHiddenToVisibleSelection(input, textbox) {
    let startIndex = input.prop('selectionStart');
    let endIndex = input.prop('selectionEnd');
    textbox.setSelection(startIndex, endIndex);
  }

  _moveHiddenInput(input, textbox) {
    input.css({
      top: textbox.cursorLocation.y,
    });

    if (
      angular.isFunction(input[0].scrollIntoViewIfNeeded) &&
      !this._isMobileSafari
    ) {
      input[0].scrollIntoViewIfNeeded(true);
    } else if (angular.isFunction(input[0].scrollIntoView) && this._isMobile) {
      input[0].scrollIntoView();
    }
  }

  /**
   * @returns {boolean}
   * @private
   */
  get _isMobile() {
    return StaticService.get.isMobile;
  }

  /**
   * @returns {boolean}
   * @private
   */
  get _isMobileSafari() {
    return this._isMobile && StaticService.get.isSafari;
  }

  get _startIndex() {
    return this._input && this._input.prop('selectionStart');
  }

  get _endIndex() {
    return this._input && this._input.prop('selectionEnd');
  }

  focusInput() {
    this._input.focus();
  }

  _isArrow(ev) {
    return (
      ev.which === 37 || ev.which === 39 || ev.which === 38 || ev.which === 40
    );
  }

  _isTab(ev) {
    return ev.which === 9;
  }
}
