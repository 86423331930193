'use strict';

import MoveAssignmentDialogTemplate from './move-assignment-dialog.html';

export default class MoveAssignmentDialogController {
  /**
   * @ngInject
   */
  constructor($mdDialog, folders, assignment, currentFolder) {
    this.$mdDialog = $mdDialog;
    this._folders = folders;
    this._assignmentToMove = assignment;
    this._currentFolder = currentFolder;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * @returns {boolean}
   */
  _missingFolder(parentFolderId) {
    return (
      parentFolderId &&
      !this._folders.some((folder) => folder.id === parentFolderId)
    );
  }

  get folders() {
    return this._folders.filter((folder) => {
      let matchingFolder = folder.folder === this.currentFolderId;

      // check for missing parent folder if we're at the root level
      let missingFolder = false;
      if (!matchingFolder && !this.currentFolderId) {
        missingFolder = this._missingFolder(folder.folder);
      }

      let itemBeingMoved = folder.id === this._assignmentToMove.id;

      return (matchingFolder || missingFolder) && !itemBeingMoved;
    });
  }

  get hasFolders() {
    return this._folders && this._folders.length > 0;
  }

  get inFolder() {
    return angular.isDefined(this._currentFolder);
  }

  static get RootDirectory() {
    return 'root_directory';
  }

  static get rootDirectoryObject() {
    return {
      id: MoveAssignmentDialogController.RootDirectory,
      name: 'All Assignments (remove from folder)',
    };
  }

  get allowMoveHere() {
    return this.currentFolderId !== this._assignmentToMove.folder;
  }

  get assignmentToMoveName() {
    return this._assignmentToMove.name;
  }

  get currentFolderName() {
    return this._currentFolder
      ? this._currentFolder.name
      : 'Assignments Dashboard';
  }

  /**
   * @param $mdDialog
   * @param folders {Assignment[]}
   * @param assignment {Assignment}
   * @param currentFolder {Assignment}
   */
  static show($mdDialog, folders, assignment, currentFolder = undefined) {
    return $mdDialog.show({
      controller: MoveAssignmentDialogController,
      template: MoveAssignmentDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        folders: folders,
        assignment: assignment,
        currentFolder: currentFolder,
      },
    });
  }

  prev() {
    this.selectFolder(this.folderForFolderId(this._currentFolder.folder));
  }

  selectFolder(folder) {
    this._currentFolder = folder;
  }

  submit() {
    this.$mdDialog.hide(
      this._currentFolder
        ? this._currentFolder
        : MoveAssignmentDialogController.rootDirectoryObject,
    );
  }

  get currentFolderId() {
    return this._currentFolder ? this._currentFolder.id : undefined;
  }

  folderForFolderId(folderId) {
    return this._folders.find((folder) => folder.id === folderId);
  }
}
