'use strict';

import { AssignmentSheetMetadata } from '../assignment-sheet/assignment-sheet.directive';
import LoadingDialog from '../../components/loading-dialog/loading-dialog.controller';
import { ElementIntents } from '../../model/domain/element-metadata';
import { UserRoles } from '../../model/domain/user';
import CollectionObserver from '../../model/firebase-mapping/collection-observer';
import { Helpers } from '../../model/domain/question-helpers';
import AnimalNames from '../../model/domain/animal-names';
import AssignmentQuestionTemplate from './assignment-question.html';

export default function AssignmentQuestionDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      config: '=',
      students: '<',
    },
    link: AssignmentQuestionController.link,
    template: AssignmentQuestionTemplate,
    controller: AssignmentQuestionController,
    controllerAs: 'ctrl',
  };
}

export class PageTraverser {
  constructor(goToPrev, goToNext, goTo) {
    this._prev = goToPrev;
    this._next = goToNext;
    this._goTo = goTo;
  }

  /**
   * @param controller {AssignmentQuestionController}
   * @return {*} The path args for $state to get to the next page
   */
  next(controller) {
    return this._next(controller);
  }

  /**
   * @param controller {AssignmentQuestionController}
   * @return {*} The path args for $state to get to the previous page
   */
  previous(controller) {
    return this._prev(controller);
  }

  /**
   * @param controller {AssignmentQuestionController}
   * @return {*} The path args for $state to get to the target page
   */
  goTo(controller) {
    return this._goTo(controller);
  }
}

export class AssignmentQuestionMetadata extends AssignmentSheetMetadata {
  /**
   * @param target {Assignment | AssignmentWork} the target assignment or work
   * @param questionId {string} the target question id
   * @param [readonly] {boolean} is the sheet read only. default true
   * @param [thumbnail] {boolean} is the sheet a thumbnail (no elements are interactive). default true
   * @param [userId] {string} The current user's ID
   * @param [userRole] {string} The current user's role (UserRoles)
   * @param [intent] {string} The intent for contained elements (ElementIntents)
   * @param [traverser] {PageTraverser}
   * @param [helpRequests] {HelpRequestSet}
   * @param [feedbacks] {QuestionFeedbackList}
   * @param [realOrAnonNameForStudentFunc] {Function(User)}
   * @param [assignmentOwner] {SharedWorkUser | null}
   * @param [studentc] {SharedWorkUser | null}
   */
  constructor(
    target,
    questionId,
    readonly,
    thumbnail,
    userId,
    userRole,
    intent,
    traverser,
    helpRequests,
    feedbacks,
    realOrAnonNameForStudentFunc,
    assignmentOwner = null,
    student = null,
  ) {
    super(
      target,
      questionId,
      readonly,
      thumbnail,
      userId,
      userRole,
      intent,
      '.assignment-question .content',
      undefined,
      undefined,
      helpRequests,
    );

    this._traverser =
      traverser || AssignmentQuestionController.DEFAULT_TRAVERSER;
    this._feedbacks = feedbacks;
    this._realOrAnonNameForStudentFunc =
      realOrAnonNameForStudentFunc ||
      AssignmentQuestionMetadata.DefaultRealOrAnonNameForStudentFunc;
    this._assignmentOwner = assignmentOwner;
    this._student = student;
  }

  get assignmentOwner() {
    return this._assignmentOwner;
  }

  get student() {
    return this._student;
  }

  /**
   * @returns {PageTraverser}
   */
  get traverser() {
    return this._traverser;
  }

  /**
   * @return {QuestionFeedbackList}
   */
  get feedbacks() {
    return this._feedbacks;
  }

  /**
   * @return {function(User): string}
   */
  get realOrAnonNameForStudentFunc() {
    return this._realOrAnonNameForStudentFunc;
  }

  /**
   * @return {function(User): string}
   */
  static get DefaultRealOrAnonNameForStudentFunc() {
    return (user) => {
      return user.name;
    };
  }
}

class HelpersNotificationObserver extends CollectionObserver {
  constructor(ctrl) {
    super();
    this.ctrl = ctrl;
  }

  onAdded(value) {
    this.ctrl.helpersUpdated(value);
  }

  onRemoved(value) {
    this.ctrl.helpersUpdated(value);
  }

  onChanged(value) {
    this.ctrl.helpersUpdated(value);
  }
}

class AssignmentQuestionController {
  constructor(
    $scope,
    $state,
    $timeout,
    $mdDialog,
    $document,
    AuthService,
    AssignmentService,
    AssignmentTrackingService,
    StaticService,
    FirebaseService,
    CacheService,
    NotificationService,
    hotkeys,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$document = $document;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {StaticService} */
    this._staticService = StaticService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;

    /** @type {AssignmentSheetMetadata} */
    this._config = undefined;
    this._students = undefined;
    /** @type {QuestionFeedback[]} */
    this._feedbackNotifications = [];
    this._loading = true;
    this._loadingDialog = LoadingDialog.show;

    $scope.$on('$destroy', () => this._destroy());

    this._initHotkeys($scope, hotkeys);
  }

  /**
   * the link function is run after the constructor for the controller
   * @param scope {$scope}
   * @param element {object}
   * @param attrs {object}
   * @param ctrl {AssignmentSheetDirectiveController}
   */
  static link(scope, element, attrs, ctrl) {
    scope.$watch('config', (value) => {
      if (value) {
        ctrl.config = value;
      }
    });

    scope.$watch('students', (value) => {
      if (value) {
        ctrl._students = value;
      }
    });
  }

  static get DEFAULT_TRAVERSER() {
    return new PageTraverser(
      (controller) => {
        return {
          assignmentId: controller.assignmentId,
          questionId: controller.prevQuestionId,
        };
      },
      (controller) => {
        return {
          assignmentId: controller.assignmentId,
          questionId: controller.nextQuestionId,
        };
      },
      (controller) => {
        return {
          assignmentId: controller.assignmentId,
          questionId: controller.targetQuestionId,
        };
      },
    );
  }

  /**
   * @returns {AssignmentQuestionMetadata}
   */
  get config() {
    return this._config;
  }

  /**
   * @param value {AssignmentQuestionMetadata}
   */
  set config(value) {
    this._config = value;
    this._init();
  }

  _init() {
    this._assignmentTrackingService.target = this.target;
    this._loading = false;

    if (this.isWorkMode || this.isFeedbackMode || this.isViewMode) {
      let helpeeId =
        this.isFeedbackMode && !this.isViewMode
          ? this.target.ownerId
          : this.metadata.userId;
      this.initBanners(helpeeId);
    }
  }

  get helpers() {
    return this._helpersNotification.helpers;
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @returns {number}
   */
  get index() {
    return this.config && this.config.index ? this.config.index : 0;
  }

  /**
   * @returns {number}
   */
  get currentQuestionNumber() {
    return this.index + 1;
  }

  /**
   * @returns {number}
   */
  get numberOfQuestions() {
    return this.target
      ? this.target.isWork
        ? this.target.assignment.questions.length
        : this.target.questions.length
      : 0;
  }

  /**
   * @return {string}
   */
  get currentPagerDisplay() {
    return `${this.currentQuestionNumber}/${this.numberOfQuestions}`;
  }

  /**
   * @returns {boolean}
   */
  get hasNextQuestion() {
    return this.currentQuestionNumber < this.numberOfQuestions;
  }

  /**
   * @returns {boolean}
   */
  get hasPrevQuestion() {
    return this.index > 0;
  }

  get metadata() {
    return this.config && this.config.metadata;
  }

  get target() {
    return this.config && this.config.target;
  }

  get questions() {
    if (this.target) {
      return this.target.isWork
        ? this.target.assignment.questions
        : this.target.questions;
    } else {
      return [];
    }
  }

  get assignmentId() {
    return this.target && this.target.isWork
      ? this.target.assignment.id
      : this.target.id;
  }

  get questionId() {
    return this.config && this.config.questionId;
  }

  get rosterId() {
    return this.target && this.target.rosterId;
  }

  /**
   * @return {undefined|User[]}
   */
  get students() {
    return this._students;
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this.metadata && this.metadata.userId;
  }

  /**
   * @returns {string}
   */
  get role() {
    return this.metadata && this.metadata.role;
  }

  /**
   * @returns {string}
   */
  get intent() {
    return this.metadata && this.metadata.intent;
  }

  /**
   * @returns {string}
   */
  get currentStudentId() {
    return this.target && this.target.ownerId;
  }

  /**
   * @returns {User}
   */
  get currentStudent() {
    return (
      this.students &&
      this.currentStudentId &&
      this.students.find((student) => student.id === this.currentStudentId)
    );
  }

  /**
   * @param value {User}
   */
  set currentStudent(value) {
    if (value.id !== this.currentStudentId) {
      this._targetStudent = value;
    }
  }

  onStudentPagerClose() {
    if (
      this._targetStudent &&
      this._targetStudent.id !== this.currentStudentId
    ) {
      this.goToFeedbackQuestion(this._targetStudent);
    }
  }

  /**
   * @returns {HelpRequestSet}
   */
  get helpRequests() {
    return this.config && this.config.helpRequests;
  }

  /**
   * @returns {PageTraverser}
   */
  get traverser() {
    return (
      this.config.traverser || AssignmentQuestionController.DEFAULT_TRAVERSER
    );
  }

  goToNextQuestion() {
    if (this.hasNextQuestion) {
      this.$state.go(this.$state.current.name, this.traverser.next(this), {
        reload: this.$state.current.name,
      });
    }
  }

  goToPrevQuestion() {
    if (this.hasPrevQuestion) {
      this.$state.go(this.$state.current.name, this.traverser.previous(this), {
        reload: this.$state.current.name,
      });
    }
  }

  /**
   * @returns {boolean}
   */
  get hasPrevStudent() {
    return (
      this.students &&
      this.students[0] &&
      this.students[0].id !== this.currentStudentId
    );
  }

  /**
   * @returns {boolean}
   */
  get hasNextStudent() {
    return (
      this.students &&
      this.students.length > 0 &&
      this.students[this.students.length - 1].id !== this.currentStudentId
    );
  }

  goToPrevStudent() {
    let currentStudentIndex = this.students
      .map((user) => user.id)
      .indexOf(this.currentStudentId);
    let prevStudentIndex = currentStudentIndex - 1;
    this.goToFeedbackQuestion(this.students[prevStudentIndex]);
  }

  goToNextStudent() {
    let currentStudentIndex = this.students
      .map((user) => user.id)
      .indexOf(this.currentStudentId);
    let nextStudentIndex = currentStudentIndex + 1;
    this.goToFeedbackQuestion(this.students[nextStudentIndex]);
  }

  /**
   * @param user {User}
   */
  goToFeedbackQuestion(user) {
    // The body always has a "md-dialog-is-showing" class when a dialog is showing
    // Github issue with more info: https://github.com/angular/material/issues/5071
    if (
      angular.element(this.$document[0].body).hasClass('md-dialog-is-showing')
    ) {
      return;
    }

    this._cacheService
      .getSessionData(this.assignmentId, this.rosterId, false, true)
      .then((sessionData) => {
        return sessionData.hasWorkForStudent(user.id)
          ? sessionData
          : this._fetchDataForStudent(sessionData, user.id);
      })
      .then((sessionData) => {
        let assignmentWork = sessionData.workForStudent(user.id);
        this.$state.go(
          this.$state.current.name,
          {
            assignmentId: this.assignmentId,
            questionId: this.questionId,
            assignmentWorkId: assignmentWork.id,
          },
          { reload: this.$state.current.name },
        );
      });
  }

  /**
   * @param sessionData {SessionData}
   * @param studentId {string}
   * @return {Promise<SessionData>}
   */
  _fetchDataForStudent(sessionData, studentId) {
    let promise = sessionData.fetchDataForStudent(studentId);

    this._loadingDialog(this.$mdDialog, promise);

    return promise;
  }

  get nextQuestionId() {
    return this.target.questionIdForIndex(this.index + 1);
  }

  get prevQuestionId() {
    return this.target.questionIdForIndex(this.index - 1);
  }

  get nextQuestionIndex() {
    return this.index + 1;
  }

  get prevQuestionIndex() {
    return this.index - 1;
  }

  get isMobile() {
    return this._staticService.isMobile;
  }

  /**
   * @returns {boolean}
   */
  get showPaginator() {
    return !this.isStudentGivingFeedback;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackMode() {
    return this.intent === ElementIntents.FEEDBACK;
  }

  /**
   * @returns {boolean}
   */
  get isViewMode() {
    return this.intent === ElementIntents.VIEW;
  }

  /**
   * @return {boolean}
   */
  get isWorkMode() {
    return this.intent === ElementIntents.WORK;
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this.role === UserRoles.STUDENT;
  }

  /**
   * @returns {boolean}
   */
  get isStudentGivingFeedback() {
    return this.isFeedbackMode && this.isStudent;
  }

  /**
   * @returns {boolean}
   */
  get isTeacherGivingFeedback() {
    return (
      this.isFeedbackMode && this.role === UserRoles.TEACHER && !this.isViewMode
    );
  }

  /**
   * @returns {boolean}
   */
  get showStudentPaginator() {
    return !!(this.students && this.students.length > 1);
  }

  /**
   * @param user {User}
   * @returns {boolean}
   */
  hasRequest(user) {
    return !!this.helpRequests.requests.find(
      (x) => x.helpeeId === user.id && x.questionId === this.questionId,
    );
  }

  /**
   * @return {QuestionFeedbackList}
   */
  get feedbacks() {
    return this.config && this.config.feedbacks;
  }

  /**
   * @param student {User}
   * @return {string}
   */
  realOrAnonNameForStudent(student) {
    if (!student) {
      return '';
    }

    return this.config.realOrAnonNameForStudentFunc(student);
  }

  _initHotkeys(scope, hotkeys) {
    hotkeys
      .bindTo(scope)
      .add({
        combo: 'left',
        description: 'Go to previous question',
        callback: () => {
          if (!this.isStudentGivingFeedback) {
            this.goToPrevQuestion();
          }
        },
      })
      .add({
        combo: 'right',
        description: 'Go to next question',
        callback: () => {
          if (!this.isStudentGivingFeedback) {
            this.goToNextQuestion();
          }
        },
      })
      .add({
        combo: 'up',
        description: 'Go to the previous student',
        callback: () => {
          if (this.hasPrevStudent) {
            this.goToPrevStudent();
          }
        },
      })
      .add({
        combo: 'down',
        description: 'Go to the next student',
        callback: () => {
          if (this.hasNextStudent) {
            this.goToNextStudent();
          }
        },
      });
  }

  /** Helper banner **/

  initBanners(helpeeId) {
    if (this.isViewMode) {
      //listens for student on their assignment work page
      const studentId = this.target.ownerId;
      this._helpeeStatus = this._notificationService.getUserStatusNotification(
        studentId,
        true,
      );
      this._helpeeStatus.updated.subscribe(this._handleHelpeeStatus, this);
      this._helpeeStatus.start();

      //listens for teachers and other students entering the student's assignment work
      let assignment = this.target.isWork
        ? this.target.assignment
        : this.target;
      helpeeId = this.target.ownerId;
      this._helpersNotification =
        this._notificationService.getHelpersNotification(assignment, helpeeId);
      this._helpersNotificationObserver = new HelpersNotificationObserver(this);
      this._helpersNotification.helpers.subscribe(
        this._helpersNotificationObserver,
      );
      this._helpersNotification.start();
    } else {
      if (this.isTeacherGivingFeedback) {
        this._helpeeStatus =
          this._notificationService.getUserStatusNotification(helpeeId, true);
        this._helpeeStatus.updated.subscribe(this._handleHelpeeStatus, this);
        this._helpeeStatus.start();
      }
      if (!this._helpersNotification) {
        let assignment = this.target.isWork
          ? this.target.assignment
          : this.target;
        this._helpersNotification =
          this._notificationService.getHelpersNotification(
            assignment,
            helpeeId,
          );
        this._helpersNotificationObserver = new HelpersNotificationObserver(
          this,
        );
        this._helpersNotification.helpers.subscribe(
          this._helpersNotificationObserver,
        );
        this._helpersNotification.start();
      }
    }

    if (this.feedbacks) {
      this.feedbacks.updated.subscribe(this._handleFeedbackAdded, this);
    }
  }

  /**
   * @param feedback {QuestionFeedback}
   */
  _handleFeedbackAdded(feedback) {
    let feedbackQuestionId = feedback.questionId;
    let currentQuestionId = this.currentQuestion && this.currentQuestion.id;

    // Figure out if the feeback is for the current slide
    if (currentQuestionId !== feedbackQuestionId) {
      let feedbackMostRecent = feedback.mostRecent;

      this._feedbackNotifications = [
        // Filter out previously added feedback for updated question
        ...this._feedbackNotifications.filter(
          (feedback) => feedback.questionId !== feedbackQuestionId,
        ),
        feedback,
      ];

      this.$timeout(() => {
        // After 4 seconds, filter the feedbacks notifications so only the appropriate ones are showing
        this._feedbackNotifications = this._feedbackNotifications.filter(
          (feedback) => {
            return (
              feedback.questionId !== feedbackQuestionId ||
              feedback.mostRecent.isAfter(feedbackMostRecent)
            );
          },
        );
      }, 4000);
    }
  }

  /**
   * @return {QuestionFeedback[]}
   */
  get feedbackNotifications() {
    return this._feedbackNotifications;
  }

  /**
   * @param questionId {string}
   * @return {number}
   */
  questionNumber(questionId) {
    return this.target.questionNumberForId(questionId);
  }

  _handleHelpeeStatus(data) {
    if (data.online && data.questionId && data.questionId === this.questionId) {
      if (this.config.student && this.isViewMode) {
        return (this._studentHelpeeName = this.config.student.name);
      }
      let helpee =
        this.students && this.students.find((user) => user.id === data.userId);
      return (this._studentHelpeeName =
        this.students && helpee ? this.realOrAnonNameForStudent(helpee) : '');
    }
    return (this._studentHelpeeName = '');
  }

  helpersUpdated() {
    this.setHelpingMessage();
  }

  setHelpingMessage() {
    let studentHelpers = [];
    let teacherIsHelping = false;
    this.helpers.forEach((helper) => {
      if (
        this.questionId === helper.questionId &&
        this.assignmentId === helper.assignmentId
      ) {
        if (this.metadata.userId === helper.id) {
          return;
        }

        if (helper.role === UserRoles.STUDENT) {
          let displayName;

          if (this.isStudentGivingFeedback) {
            displayName = this.anonAnimalNameForHelper(helper.id);
          } else {
            displayName = this.realOrAnonNameForStudent(helper);
          }

          studentHelpers.push(displayName);
        } else if (!this.isTeacherGivingFeedback) {
          teacherIsHelping = true;
        }
      }
    });

    this._teacherIsHelping = teacherIsHelping;
    this._studentMessageConjugation =
      Helpers.formatHelpersConjugation(studentHelpers);
    this._studentHelperMessage = Helpers.formatHelpersList(studentHelpers);

    this.$timeout(() => {});
  }

  /**
   * @param helperId {string}
   * @return {string}
   */
  anonAnimalNameForHelper(helperId) {
    let animalName = AnimalNames.generateForHelper(
      `${this.questionId}${helperId}`,
    );
    return animalName.display.toLowerCase();
  }

  get teacherIsHelping() {
    return this._teacherIsHelping;
  }

  get studentHelpeeName() {
    return this._studentHelpeeName;
  }

  get studentHelperMessage() {
    return this._studentHelperMessage;
  }

  get studentMessageConjugation() {
    return this._studentMessageConjugation;
  }

  get currentQuestion() {
    return this.questions[this.index];
  }

  set currentQuestion(value) {
    if (value.id !== this.currentQuestion.id) {
      this._targetQuestion = value;
    }
  }

  get targetQuestionId() {
    return this._targetQuestion && this._targetQuestion.id;
  }

  get targetQuestionIndex() {
    return this.target.indexForQuestionId(this._targetQuestion.id);
  }

  onQuestionPagerClose() {
    if (this.shouldGoToNewQuestion) {
      this.$state.go(this.$state.current.name, this.traverser.goTo(this), {
        reload: this.$state.current.name,
      });
    }
  }

  /**
   * @return {boolean}
   */
  get shouldGoToNewQuestion() {
    return (
      this._targetQuestion &&
      this._targetQuestion.id !== this.currentQuestion.id &&
      !!this.target.questionForId(this._targetQuestion.id)
    );
  }

  _destroy() {
    if (this._helpeeStatus) {
      this._helpeeStatus.updated.unsubscribe(this._handleHelpeeStatus, this);
    }

    if (this._helpersNotification) {
      this._helpersNotification.helpers.unsubscribe(
        this._helpersNotificationObserver,
      );
    }

    if (this.feedbacks) {
      this.feedbacks.updated.unsubscribe(this._handleFeedbackAdded, this);
    }
  }
}
