'use strict';
import Codec from './codec';
import RosterStudent from '../domain/roster-student';
/**
 * Encodes/decodes roster student information for Gradebook integration
 */
export default class RosterStudentCodec extends Codec {
  /**
   * @param value { RosterStudent }
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        name: value.name,
        login: value.login,
        lms_id: value.lmsId ? value.lmsId : null,
      };
    }
    return null;
  }

  /**
   * @param value {object}
   * @returns { RosterStudent }
   */
  decode(value) {
    if (value) {
      return new RosterStudent(
        value.id,
        value.name,
        value.login,
        value.lms_id ? value.lms_id : null,
      );
    }
    return null;
  }
}
