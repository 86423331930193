class FeedbackState {
  given() {
    throw new TypeError('The given(value) method must be defined');
  }

  toggled() {
    throw new TypeError('The toggled(value) method must be defined');
  }
}

export class FeedbackDisabled extends FeedbackState {
  given(context) {
    context.setState(new FeedbackVisible());
    context.toolbarService.extendedStateUpdated.raise();
  }

  toggled() {}
}

export class FeedbackVisible extends FeedbackState {
  given() {}

  toggled(context) {
    context.setState(new FeedbackInvisible());
    context.toolbarService.hideFeedback();
    context.toolbarService.extendedStateUpdated.raise();
  }
}

export class FeedbackInvisible extends FeedbackState {
  given(context) {
    context.setState(new FeedbackNotification());
    context.toolbarService.extendedStateUpdated.raise();
  }

  toggled(context) {
    context.setState(new FeedbackVisible());
    context.toolbarService.showFeedback();
    context.toolbarService.extendedStateUpdated.raise();
  }
}

export class FeedbackNotification extends FeedbackState {
  given() {}

  toggled(context) {
    context.setState(new FeedbackVisible());
    context.toolbarService.showFeedback();
  }
}

export class Feedback {
  /**
   * @param $timeout
   * @param ToolbarService {ToolbarService}
   */
  constructor($timeout, ToolbarService) {
    // Remove this?
    this.$timeout = $timeout;
    this.toolbarService = ToolbarService;

    this.state = null;
    this.reset();
  }

  /**
   * Setter method for the state.
   * Normally only called by classes implementing the State interface.
   * @param newState the new state of this context
   */
  setState(newState) {
    this.state = newState;
  }

  given() {
    this.state.given(this);
  }

  toggled() {
    this.state.toggled(this);
  }

  reset() {
    this.setState(new FeedbackDisabled(this));
  }
}
