'use strict';

import { Notifications } from './toolbar.service';
import {
  ImageImport,
  QuestionDisplay,
} from '../../components/import-image-dialog/import-image-dialog.controller';
import Size from '../../model/ui/size';
import { CropResult } from '../../model/ui/elements/image-cropper';
import Point from '../../model/ui/point';
import ElementCodec from '../../model/codec/element-codec';
import StaticService from '../../services/static/static.service.js';

export default class ClipboardManager {
  constructor(
    $log,
    $window,
    $document,
    hotkeys,
    ToolbarService,
    ImageEditService,
    FocusManagerService,
  ) {
    this.$log = $log;
    this._window = angular.element($window);
    this._document = angular.element($document);
    this._hotkeys = hotkeys;

    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;
    /** @type {FocusManagerService} */
    this._focusManager = FocusManagerService;

    /** @type {ElementCodec} */
    this._elementCodec = new ElementCodec();

    this._handleCopyPasteClasskickElementFunc = undefined;
    this._handleCopyClasskickElementFunc = undefined;
    this._handlePasteFunc = undefined;
    this._currentSelectedTextbox = undefined;
  }

  /**
   * Called each time new canvas loads
   * @param scope
   */
  init(scope) {
    let pasteCombo = StaticService.get.isMac ? 'command+v' : 'ctrl+v';
    let copyCombo = StaticService.get.isMac ? 'command+c' : 'ctrl+c';
    let cutCombo = StaticService.get.isMac ? 'command+x' : 'ctrl+x';
    let undoCombo = StaticService.get.isMac ? 'command+z' : 'ctrl+z';
    let redoCombo = StaticService.get.isMac
      ? 'command+shift+z'
      : 'ctrl+shift+z';

    this._hotkeys
      .bindTo(scope)
      .add({
        combo: pasteCombo,
        description: 'Paste',
        callback: this._handleCommandPaste.bind(this),
      })
      .add({
        combo: copyCombo,
        description: 'Copy',
        callback: this._handleCommandCopy.bind(this),
      })
      .add({
        combo: cutCombo,
        description: 'Cut',
        callback: this._handleCommandCut.bind(this),
      })
      .add({
        combo: 'backspace',
        description: 'Delete',
        callback: this._handleDelete.bind(this),
      })
      .add({
        combo: undoCombo,
        description: 'Undo',
        callback: this._handleUndo.bind(this),
      })
      .add({
        combo: redoCombo,
        description: 'Redo',
        callback: this._handleRedo.bind(this),
      });
  }

  _handleCommandPaste() {
    if (StaticService.get.isSafari) {
      this._toolbarService.toast(
        'Sorry, pasting elements does not work in Safari.',
      );
      return;
    }

    this._handlePasteFunc = this._handlePaste.bind(this);
    this._document[0].addEventListener('paste', this._handlePasteFunc);
    this._document[0].execCommand('paste', false, null);
  }

  _handlePasteBase(ev) {
    if (ev.clipboardData && ev.clipboardData.items) {
      let clipboardItems = Array.from(ev.clipboardData.items);
      const updatedClipboardItems = clipboardItems.map((item) => {
        if (item.type === 'text/plain') {
          if (this.currentSelectedTextbox) {
            let encodedElement = this._elementCodec.encode(
              this.currentSelectedTextbox,
            );
            let json = angular.toJson(encodedElement);
            item = { type: 'text/classkick', json };
          }
        }
        return item;
      });

      let image = updatedClipboardItems.find((item) => {
        return item.type.indexOf('image') !== -1;
      });

      let classkickElement = updatedClipboardItems.find((item) => {
        return item.type === 'text/classkick';
      });

      let plainText = updatedClipboardItems.find((item) => {
        return item.type === 'text/plain';
      });

      if (image) {
        this._pasteImage(image);
      } else if (classkickElement) {
        this._pasteClasskickElement(classkickElement);
      } else if (plainText) {
        this._pastePlainText(plainText);
      }
    }

    ev.preventDefault();
  }

  _handlePaste(ev) {
    this._handlePasteBase(ev);
    this._document[0].removeEventListener('paste', this._handlePasteFunc);
  }

  /**
   * @param image {DataTransferItem}
   */
  _pasteImage(image) {
    let file = image.getAsFile();

    if (file) {
      this._createImageFromBlob(file);
    } else {
      image.getAsString((str) => {
        let blob = this._imageEditService.dataURItoBlob(str);
        this._createImageFromBlob(blob);
      });
    }
  }

  /**
   * @param value {File|Blob}
   */
  _createImageFromBlob(value) {
    this._imageEditService.imageFromFile(value).then((image) => {
      let imageImport = this._formatImageImport(image);
      this._sendCreateImageNotification(imageImport);
    });
  }

  /**
   * @param classkickElement {DataTransferItem}
   */
  _pasteClasskickElement(classkickElement) {
    if (classkickElement.constructor.name === 'DataTransferItem') {
      classkickElement.getAsString((string) => {
        let element = this._extractElement(string);
        if (element) {
          this._toolbarService.notify(Notifications.CLONE_ELEMENT, { element });
        }
      });
    } else {
      let element = this._extractElement(classkickElement.json);
      if (element) {
        this._toolbarService.notify(Notifications.CLONE_ELEMENT, { element });
      }
    }
  }

  /**
   * @param plainText {DataTransferItem}
   */
  _pastePlainText(plainText) {
    let blob = plainText.getAsFile();
    if (!blob) {
      plainText.getAsString((string) => {
        this._toolbarService.notify(Notifications.CREATE_TEXTBOX, {
          initialText: string,
          skipPlacement: true,
        });
      });
    }
  }

  /**
   * @param clipboardText {string}
   * @return {Element|undefined}
   */
  _extractElement(clipboardText) {
    try {
      let clipboardTextAsJson = angular.fromJson(clipboardText);
      return this._elementCodec.decode(clipboardTextAsJson, '');
    } catch (error) {
      this.$log.warn(error);
    }
  }

  _handleCommandCopy() {
    if (this.focusedElement && !this.focusedElement.isChildManipulative) {
      if (StaticService.get.isSafari) {
        this._toolbarService.toast(
          'Sorry, copying elements does not work in Safari.',
        );
        return;
      }

      this._handleCopyClasskickElementFunc =
        this._handleCopyClasskickElement.bind(this);
      this._document[0].addEventListener(
        'copy',
        this._handleCopyClasskickElementFunc,
      );
      this._document[0].execCommand('copy', false, null);
      this._toolbarService.toast(
        `Copied ${this.focusedElement.typeDisplay} to the clipboard.`,
      );
    }
  }

  handleCommandCopyPaste() {
    if (this.focusedElement && !this.focusedElement.isChildManipulative) {
      if (StaticService.get.isSafari) {
        this._toolbarService.toast(
          'Sorry, copying elements does not work in Safari.',
        );
        return;
      }

      this._handleCopyPasteClasskickElementFunc =
        this._handleCopyPasteClasskickElement.bind(this);
      this._document[0].addEventListener(
        'copy',
        this._handleCopyPasteClasskickElementFunc,
      );
      this._document[0].addEventListener(
        'paste',
        this._handleCopyPasteClasskickElementFunc,
      );
      this._document[0].execCommand('copy', false, null);
      this._document[0].execCommand('paste', false, null);
      this._toolbarService.toast(
        `Copied and pasted ${this.focusedElement.typeDisplay} to the assignment.`,
      );
    }
  }

  /**
   * @param ev {ClipboardEvent}
   */
  _handleCopyClasskickElementBase(ev) {
    if (this.focusedElement && !this.focusedElement.isChildManipulative) {
      let encodedElement = this._elementCodec.encode(this.focusedElement);
      let json = angular.toJson(encodedElement);

      ev.clipboardData.clearData();
      ev.clipboardData.setData('text/classkick', json);

      ev.preventDefault();
    }
  }

  /**
   * @param ev {ClipboardEvent}
   */
  _handleCopyClasskickElement(ev) {
    this._handleCopyClasskickElementBase(ev);
    this._document[0].removeEventListener(
      'copy',
      this._handleCopyClasskickElementFunc,
    );
  }

  /**
   * @param ev {ClipboardEvent}
   */
  _handleCopyPasteClasskickElement(ev) {
    this._handleCopyClasskickElementBase(ev);

    // specifially remove hotkey event listeners as well
    // as this was creating multiple copies
    // when hotkey copy + paste action was used
    this._document[0].removeEventListener(
      'copy',
      this._handleCopyClasskickElementFunc,
    );
    this._document[0].removeEventListener(
      'copy',
      this._handleCopyPasteClasskickElementFunc,
    );

    this._handlePasteBase(ev);

    this._document[0].removeEventListener('paste', this._handlePasteFunc);
    this._document[0].removeEventListener(
      'paste',
      this._handleCopyPasteClasskickElementFunc,
    );
  }

  /**
   * @param image {Image}
   * @return {ImageImport}
   */
  _formatImageImport(image) {
    const imageSize = new Size(image.naturalWidth, image.naturalHeight);

    const cropResult = new CropResult(imageSize, new Point(0, 0), imageSize);

    const imageSrc = this._imageEditService.crop(
      image,
      new Point(0, 0),
      imageSize,
    );

    const questionId = this._toolbarService.target.questionIdForIndex(
      this._toolbarService.targetIndex,
    );

    return new ImageImport(
      imageSrc,
      this._toolbarService.targetIndex,
      cropResult,
      [
        new QuestionDisplay(
          questionId,
          this._toolbarService.targetIndex,
          true,
          false,
          undefined,
        ),
      ],
    );
  }

  /**
   * @param imageImport {ImageImport}
   */
  _sendCreateImageNotification(imageImport) {
    this._toolbarService.notify(Notifications.CREATE_IMAGE_FROM_FILE, {
      needsFocusedElement: false,
      imageImport: imageImport,
    });
  }

  _handleDelete() {
    if (this._toolbarService.focusedElement) {
      this._toolbarService.notify(Notifications.DELETE, {
        needsFocusedElement: true,
      });
    }
  }

  _handleCommandCut() {
    this._handleCommandCopy();
    this._handleDelete();
  }

  set currentSelectedTextbox(value) {
    this._currentSelectedTextbox = value;
  }

  get currentSelectedTextbox() {
    return this._currentSelectedTextbox;
  }

  get focusedElement() {
    return this._focusManager.focusedElement;
  }

  _handleUndo() {
    if (this._toolbarService.canUndo) {
      this._toolbarService.undo();
    }
  }

  _handleRedo() {
    if (this._toolbarService.canRedo) {
      this._toolbarService.redo();
    }
  }
}
