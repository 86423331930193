'use strict';

import Point from '../ui/point';

let derad = 180 / Math.PI;
let torad = Math.PI / 180;

/**
 * Contains trigonometry utilities
 */
export default class Trig {
  /**
   * Converts degrees to radians
   * @param degrees {number}
   * @returns {number}
   */
  static toRad(degrees) {
    return torad * degrees;
  }

  /**
   * Converts radians to degrees
   * @param radians {number}
   * @returns {number}
   */
  static toDeg(radians) {
    return derad * radians;
  }

  static sin(degrees) {
    return Math.sin(Trig.toRad(degrees));
  }

  static cos(degrees) {
    return Math.cos(Trig.toRad(degrees));
  }

  static tan(degrees) {
    return Math.tan(Trig.toRad(degrees));
  }

  /**
   * @param vector1 {Point}
   * @param vector2 {Point}
   * @returns {number}
   */
  static clockwiseAngle(vector1, vector2) {
    let angle =
      Math.atan2(vector2.y, vector2.x) - Math.atan2(vector1.y, vector1.x);

    if (angle < 0) {
      angle += 2 * Math.PI;
    }

    return angle;
  }

  /**
   * @param pt1 {Point}
   * @param pt2 {Point}
   * @private
   */
  static findSlope(pt1, pt2) {
    let rise = pt1.y - pt2.y;
    let run = pt1.x - pt2.x;
    let slope = rise / run;
    return slope;
  }

  /**
   * @param pt1 {Point}
   * @param pt2 {Point}
   * @private
   */
  static findPerpendicularSlope(pt1, pt2) {
    let rise = pt1.y - pt2.y;
    let run = pt1.x - pt2.x;
    let slope = (-1 * run) / rise;
    return slope;
  }

  /**
   * @param slope {number}
   * @param pt {Point}
   * @returns {number}
   * @private
   */
  static findYIntercept(slope, pt) {
    return -1 * slope * pt.x + pt.y;
  }

  /**
   * @param slope1 {number}
   * @param yIntercept1 {number}
   * @param slope2 {number}
   * @param yIntercept2 {number}
   * @returns {Point} the ordered pair where the two lines intersect
   * @private
   */
  static findIntercept(slope1, yIntercept1, slope2, yIntercept2) {
    let x = Trig.findX(slope1, yIntercept1, slope2, yIntercept2);
    let y = Trig.findY(slope1, x, yIntercept1);
    return new Point(x, y);
  }

  /**
   * @param slope1 {number}
   * @param yIntercept1 {number}
   * @param slope2 {number}
   * @param yIntercept2 {number}
   * @returns {number}
   * @private
   */
  static findX(slope1, yIntercept1, slope2, yIntercept2) {
    return (yIntercept2 - yIntercept1) / (slope1 - slope2);
  }

  /**
   * @param slope {number}
   * @param x {number}
   * @param b {number}
   * @returns {number}
   * @private
   */
  static findY(slope, x, yIntercept) {
    return slope * x + yIntercept;
  }

  /**
   * @param currentLocation {Point}
   * @param startSlope {number}
   * @param startYIntercept {number}
   * @param perpendicularSlope {number}
   * @returns {Point} closest point on given line
   * @private
   */
  static closestPoint(
    currentLocation,
    startSlope,
    startYIntercept,
    perpendicularSlope,
  ) {
    let perpendicularYIntercept = Trig.findYIntercept(
      perpendicularSlope,
      currentLocation,
    );
    return Trig.findIntercept(
      startSlope,
      startYIntercept,
      perpendicularSlope,
      perpendicularYIntercept,
    );
  }

  /**
   * @param number {number}
   * @param multiple {number}
   * @returns {number}
   */
  static roundToNearest(number, multiple) {
    return Math.round(number / multiple) * multiple;
  }

  /**
   * @param point {Point}
   * @param origin {Point}
   * @param angle {number}
   * @return {Point}
   */
  static rotate(point, origin, angle) {
    let radians = Trig.toRad(angle);
    let cos = Math.cos(radians);
    let sin = Math.sin(radians);
    let dX = point.x - origin.x;
    let dY = point.y - origin.y;

    return new Point(
      cos * dX - sin * dY + origin.x,
      sin * dX + cos * dY + origin.y,
    );
  }
}
