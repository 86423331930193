import SvgCanvasLayers from './svg-canvas-layers';

export default class SvgThumbnailLayers extends SvgCanvasLayers {
  /**
   * @param snap {Snap}
   */
  constructor(snap) {
    super(snap);
  }
}
