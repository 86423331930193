import moment from 'moment';

export class OrderPrice {
  constructor(start, end, plan, coupon) {
    this._start = start ? moment.utc(start) : undefined;
    this._end = end ? moment.utc(end) : undefined;
    this._plan = plan;
    this._coupon = coupon;
  }

  /**
   * @return {moment}
   */
  get start() {
    return this._start;
  }

  /**
   * @return {moment}
   */
  get end() {
    return this._end;
  }

  /**
   * @return {OrderPlan}
   */
  get plan() {
    return this._plan;
  }

  /**
   * @return {OrderCoupon}
   */
  get coupon() {
    return this._coupon;
  }

  /**
   * @return {number}
   */
  get total() {
    return this.plan.price - this.coupon.discount;
  }
}
