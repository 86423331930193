'use strict';

import Codec from './codec';
import GradebookAssignmentCategory from '../domain/gradebook-assignment-category';
/**
 * Encodes/decodes Gradebook integration status
 */
export default class GradebookAssignmentCategoryCodec extends Codec {
  /**
   * @param value {GradebookAssignmentCategory}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        title: value.title,
      };
    }
    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookAssignmentCategory}
   */
  decode(value) {
    if (value) {
      return new GradebookAssignmentCategory(value.id, value.title);
    }

    return null;
  }
}
