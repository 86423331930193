'use strict';

import LoadingDialog from '../loading-dialog/loading-dialog.controller';
import StudentAccountChoiceDialogController from '../student-account-choice-dialog/student-account-choice-dialog.controller';
import AddAnonStudentDialogTemplate from './add-anon-student-dialog.html';

export default class AddAnonStudentDialogController {
  constructor($q, $mdDialog, RosterService, roster, rosterMembers, pro) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;

    this._rosterService = RosterService;

    this._names = '';
    this._roster = roster;
    this._rosterMembers = rosterMembers;
    this._rosterMemberNames = [...rosterMembers].map(
      (user) => user.displayName,
    );
    this._pro = pro;

    this._showLoadingDialog = LoadingDialog.show;
    this._showStudentAccountChoice = StudentAccountChoiceDialogController.show;
  }

  get rosterName() {
    return this._roster && this._roster.name;
  }

  get pro() {
    return this._pro;
  }

  set pro(value) {
    this._pro = value;
  }

  get names() {
    return this._names;
  }

  set names(value) {
    this._names = value;
  }

  goBackToSelection() {
    this._showStudentAccountChoice(
      this.$mdDialog,
      this._pro,
      this._roster,
      this._rosterMembers,
    );
  }

  submit() {
    let promises = this.$q.all(
      this._names
        .split(',')
        .map((name) => name.trim())
        .filter((newStudentName) => !this._hasName(newStudentName))
        .map((newStudentName) =>
          this._rosterService.addAnonymousMember(
            this._roster.id,
            newStudentName,
          ),
        ),
    );

    this._showLoadingDialog(this.$mdDialog, promises);

    this.$mdDialog.hide();
  }

  /**
   * @param name {string}
   * @return {boolean}
   */
  _hasName(name) {
    return this._rosterMemberNames.some((memberName) => {
      return memberName === name;
    });
  }

  /**
   * @param $mdDialog
   * @param roster {Roster}
   * @param rosterMembers {Set.<User>}
   * @param pro {ProInfo}
   * @return {Promise}
   */
  static show($mdDialog, roster, rosterMembers, pro) {
    return $mdDialog.show({
      controller: AddAnonStudentDialogController,
      template: AddAnonStudentDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        roster,
        rosterMembers,
        pro,
      },
    });
  }
}
