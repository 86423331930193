export default class Course {
  /**
   * @param id {string}
   * @param sessionId {string}
   * @param name {string}
   *
   */
  constructor(id, sessionId, name) {
    this._id = id;
    this._sessionId = sessionId;
    this._name = name;
  }

  get id() {
    return this._id;
  }

  get sessionId() {
    return this._sessionId;
  }

  get name() {
    return this._name;
  }
}
