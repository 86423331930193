export class RosterProperties {
  /**
   * @returns {string}
   */
  static get PROVIDER() {
    return 'provider';
  }

  /**
   * @returns {string}
   */
  static get CLEVER() {
    return 'clever-import';
  }

  /**
   * @returns {string}
   */
  static get GOOGLE() {
    return 'google';
  }

  /**
   * @returns {string}
   */
  static get PROVIDER_DATA() {
    return 'provider_data';
  }

  /**
   * @returns {string}
   */
  static get IS_HIDDEN() {
    return 'is_hidden';
  }

  /**
   * Google's key for its course id. See more at
   * @returns {string}
   */
  static get GOOGLE_COURSE_ID() {
    return 'course_id';
  }

  /**
   * @return {string}
   */
  static get STUDENT_ACCOUNT_PREFERENCE() {
    return 'student_account_preference';
  }
}

export class RosterStudentAccountPreferenceOptions {
  static get BASIC() {
    return 'basic';
  }

  static get PORTFOLIO() {
    return 'portfolio';
  }
}

export default class Roster {
  /**
   * Contains the information for a roster
   *
   * @param id {string} roster id
   * @param ownerId {string} owner id
   * @param name {string} roster name
   * @param color {string} roster color
   * @param [allowPeerHelp] {boolean} indicates if students can help other student from roster
   * @param [allowNewMembers] {boolean} indicates if new students can join this roster
   * @param [allowMultiLogin] {boolean} indicates if students can login from multiple devices
   * @param [properties] {object} the roster properties
   * @param [coteacherAccess] {boolean} indicates if this roster has been shared with co-teachers
   */
  constructor(
    id,
    ownerId,
    name,
    color,
    allowPeerHelp,
    allowNewMembers,
    allowMultiLogin,
    properties,
    coteacherAccess,
  ) {
    this._id = id;
    this._ownerId = ownerId;
    this._name = name;
    this._color = color;
    this._allowPeerHelp = allowPeerHelp;
    this._allowNewMembers = allowNewMembers;
    this._allowMultiLogin = allowMultiLogin;
    this._properties = properties || {};
    this._coteacherAccess = coteacherAccess;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @param value {string}
   */
  set name(value) {
    this._name = value ? value : '';
  }

  /**
   * @returns {string}
   */
  get color() {
    return this._color;
  }

  /**
   * @param value {string}
   */
  set color(value) {
    this._color = value;
  }

  /**
   * @returns {boolean}
   */
  get allowPeerHelp() {
    return this._allowPeerHelp;
  }

  /**
   * @param bool {boolean}
   */
  set allowPeerHelp(bool) {
    this._allowPeerHelp = bool;
  }

  /**
   * @returns {boolean}
   */
  get allowNewMembers() {
    return this._allowNewMembers;
  }

  /**
   * @param bool {boolean}
   */
  set allowNewMembers(bool) {
    this._allowNewMembers = bool;
  }

  /**
   * @returns {boolean}
   */
  get allowMultiLogin() {
    return this._allowMultiLogin;
  }

  /**
   * @param bool {boolean}
   */
  set allowMultiLogin(bool) {
    this._allowMultiLogin = bool;
  }

  /**
   * @returns {Object}
   */
  get properties() {
    return this._properties;
  }

  /**
   * @param value {Object}
   */
  set properties(value) {
    this._properties = value;
  }

  /**
   * @returns {boolean}
   */
  get disallowMultiLogin() {
    return !this._allowMultiLogin;
  }

  /**
   * @param bool {boolean}
   */
  set disallowMultiLogin(bool) {
    this._allowMultiLogin = !bool;
  }

  /**
   * @returns {boolean}
   */
  get isRosterLocked() {
    return !this._allowNewMembers;
  }

  /**
   * @param bool {boolean}
   */
  set isRosterLocked(bool) {
    this._allowNewMembers = !bool;
  }

  /**
   * Indicates whether the roster was created by importing from Clever
   * @returns {boolean}
   */
  get isCleverRoster() {
    return this.provider === RosterProperties.CLEVER;
  }

  /**
   * Indicates whether the roster was created by importing from Google Classroom
   * @returns {boolean}
   */
  get isGoogleRoster() {
    return this.provider === RosterProperties.GOOGLE;
  }
  /**
   * Indicates whether this roster has been shared for co-teaching
   * @returns {boolean}
   */
  get coteacherAccess() {
    return this._coteacherAccess;
  }

  //--------------------- Property Getters / Setters --------------------------//

  /**
   * @returns {string}
   */
  get provider() {
    return this.properties[RosterProperties.PROVIDER];
  }

  /**
   * @param value {string}
   */
  set provider(value) {
    this._setProperty(RosterProperties.PROVIDER, value);
  }

  /**
   * @returns {string}
   */
  get providerData() {
    return this.properties[RosterProperties.PROVIDER_DATA];
  }

  /**
   * @param value {string}
   */
  set providerData(value) {
    this._setProperty(RosterProperties.PROVIDER_DATA, value);
  }

  /**
   * @return {string|undefined}
   */
  get studentAccountPreference() {
    return this.properties[RosterProperties.STUDENT_ACCOUNT_PREFERENCE];
  }

  /**
   * @param value {string|undefined}
   */
  set studentAccountPreference(value) {
    this._setProperty(RosterProperties.STUDENT_ACCOUNT_PREFERENCE, value);
  }

  /**
   * @param key {string}
   * @param value {string}
   * @private
   */
  _setProperty(key, value) {
    this._properties[key] = value || null;
  }

  /**
   * @return {string|undefined}
   */
  get googleCourseId() {
    let googleProperties =
      this.providerData && angular.fromJson(this.providerData);
    return googleProperties[RosterProperties.GOOGLE_COURSE_ID];
  }

  /**
   * @returns {boolean}
   */
  get locked() {
    return !this._allowNewMembers || !this._allowMultiLogin;
  }

  /**
   * @returns {boolean}
   */
  get isHidden() {
    if (
      this.properties[RosterProperties.IS_HIDDEN] &&
      this.properties[RosterProperties.IS_HIDDEN] === 'hidden'
    ) {
      return true;
    }
    return false;
  }

  /**
   * Explains locking roster for new students
   * @returns {string}
   */
  static get LOCK_ROSTER_EXPLANATION() {
    return 'This prevents students who are not on the current roster from logging in. Rosters are always locked when using a third-party roster, such as Clever. Additionally, locking your roster keeps duplicate student accounts from being added.';
  }

  /**
   * Explains locking devices for a roster
   * @returns {string}
   */
  static get LOCK_DEVICE_EXPLANATION() {
    return "This prevents students from using the same account at the same time. It's helpful if a student is logging into a classmate's account and misbehaving.";
  }
}
