import Debouncer from '../../model/util/debouncer';
import Territories from '../../model/util/territories';
import MdrOrganization from '../../model/domain/mdr-organization';
import UserProfileController from '../../controllers/user-profile/user-profile.controller';
import SelectMdrOrgDialogTemplate from '../../components/select-mdr-org-dialog/select-mdr-org-dialog.html';

export default class SelectMdrOrgDialogController {
  /**
   * @ngInject
   */
  constructor(
    $mdDialog,
    $scope,
    $timeout,
    $window,
    BreadcrumbService,
    GooglePlacesService,
    OrganizationService,
    StorageService,
    organization,
    unverifiedOrganizations,
    userId,
    dismissable,
  ) {
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;
    this.$timeout = $timeout;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {GooglePlacesService} */
    this._googlePlacesService = GooglePlacesService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._loadingAutocompleteLocation = false;
    this._loadingSingleVerification = false;
    this._loadingSchoolNames = false;
    this._cityNotSelected = false;
    this._schoolNameNotSelected = false;

    this.cityQuery = '';
    this.schoolQuery = '';
    this.selectCity = '';
    this.selectSchool = null;
    this.selectState = '';
    this.state = this.SINGLE;
    this.stateOptions = Territories.fullList;
    this._matchingOrgs = [];
    this._providedOrg = organization;
    this._unverifiedOrganizations = unverifiedOrganizations;
    this._userId = userId;
    this._truncated = true;
    this._dismissable = dismissable;
    this._error = false;
    this._errorMessage = '';
    this._defaultOption = {
      institutionName: "I can't find this school/organization",
    };

    this._debouncer = new Debouncer(
      100,
      270,
      () => this.submitSearch(),
      $scope,
      $window,
    );

    this._init();
  }

  _init() {
    const { city, name, state } = this._providedOrg;

    if (this.inputIsValid(city, name, state)) {
      this.searchForMdrMatch(city, state, name).then((orgs) => {
        if (orgs.length) {
          this.fillForm(orgs[0]);
          this.selectSchool = orgs[0];
        }
      });
    }
  }

  get MULTI() {
    return 'multi';
  }

  get SINGLE() {
    return 'single';
  }

  get NAME() {
    return 'name';
  }

  get CITY() {
    return 'city';
  }

  get SINGLE_VIEW() {
    return 'single-view';
  }

  get unverifiedOrganizations() {
    return this._unverifiedOrganizations;
  }

  get dismissable() {
    return this._dismissable;
  }

  get error() {
    return this._error;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  get matchingOrgs() {
    return this._matchingOrgs;
  }

  get cityNotSelected() {
    return this._cityNotSelected;
  }

  get schoolNameNotSelected() {
    return this._schoolNameNotSelected;
  }

  get displayOrganizations() {
    let orgs = Array.from(this.unverifiedOrganizations.values());

    return this._truncated ? orgs.slice(0, 3) : orgs;
  }

  get isSubmitDisabled() {
    return this.selectSchool
      ? !this.inputIsValid(
          this.selectCity,
          this.selectSchool.institutionName,
          this.selectState,
        )
      : true;
  }

  get isSchoolNameInputDisabled() {
    return !(
      this.selectCity &&
      this.selectState &&
      this.selectState.length < 3
    );
  }

  isLoading(type) {
    switch (type) {
      case this.NAME:
        return this._loadingSchoolNames;
      case this.CITY:
        return this._loadingAutocompleteLocation;
      case this.SINGLE_VIEW:
        return this._loadingSingleVerification;
    }
  }

  inputIsValid(city, name, state) {
    return !!(city && name && state && name.length >= 2 && state.length < 3);
  }

  fieldIsValid(field) {
    this.resetErrors();
    if (field === this.CITY && !this.selectCity) {
      this._cityNotSelected = true;
    } else if (field === this.NAME && !this.selectSchool) {
      this._schoolNameNotSelected = true;
    }
  }

  resetErrors() {
    this._cityNotSelected = false;
    this._schoolNameNotSelected = false;
    this._error = false;
    this._errorMessage = '';
  }

  /**
   * @param search {string}
   * @return {Array|Promise<GooglePlacePrediction[]>}
   */
  autocompleteLocation(search) {
    if (!search || search.length < 2) {
      return [];
    } else {
      this._loadingAutocompleteLocation = true;
      return this._googlePlacesService
        .getCityPredictions(search)
        .then((result) => {
          this._loadingAutocompleteLocation = false;
          return result;
        });
    }
  }

  debounceSearchMdrOrg() {
    this._debouncer.tick();
  }

  submitSearch() {
    this._loadingSchoolNames = true;
    this.resetErrors();

    if (
      !this.inputIsValid(this.selectCity, this.schoolQuery, this.selectState)
    ) {
      this._error = true;
      this._errorMessage =
        'Please provide 3 or more characters to search for a school';
      this._loadingSchoolNames = false;
    } else {
      if (this.schoolQuery.length < 3) {
        // due to depricated design package, still show error here to cover for an off-by-one issue
        this._error = true;
        this._errorMessage =
          'Please provide 3 or more characters to search for a school';
        this._loadingSchoolNames = false;
      }
      this.searchForMdrMatch(
        this.selectCity,
        this.selectState,
        this.schoolQuery,
      ).then((orgs) => {
        this._loadingSchoolNames = false;
        this._matchingOrgs = [...orgs, this._defaultOption];
      });
    }
  }

  isOrg(item) {
    return item instanceof MdrOrganization;
  }

  /**
   * @param city {string}
   * @param state {string}
   * @param name {string}
   * @return {Array|<MdrOrganization>}
   */
  searchForMdrMatch(city, state, name) {
    return this._organizationService.getMdrOrgsByLocation(city, state, name);
  }

  submitForm(schoolNotFound = false) {
    let mdrPid = null;

    if (schoolNotFound) {
      mdrPid = 0;
    } else if (this.selectSchool) {
      mdrPid = this.selectSchool.pid;
    } else {
      this._error = true;
      this._errorMessage = `Select a school from the list. If you
      can't find this school, select "I can't find this school/organization"`;
      return;
    }

    this.saveMdrSelection(mdrPid);
  }

  saveMdrSelection(mdrPid) {
    this._organizationService
      .saveMdrSelection(this._providedOrg.id, this._userId, mdrPid)
      .then((response) => {
        this._unverifiedOrganizations.delete(this._providedOrg.id);
        this.selectSchool = null;
        this.fillForm(null);

        if (this._unverifiedOrganizations.size < 1 || this.dismissable) {
          this.hide(true);
        } else {
          this.state = this.MULTI;
        }
      });
  }

  fillForm(org) {
    if (!org) {
      org = { institutionName: '', name: '', city: '', state: '' };
      this._matchingOrgs = [this._defaultOption];
    }
    this.schoolQuery = org.institutionName || org.name;
    this.cityQuery = org.city;
    this.selectCity = org.city;
    this.selectState = org.state;
  }

  verifySingleOrg(org) {
    this._providedOrg = org;
    const { city, name, state } = org;

    if (this.inputIsValid(city, name, state)) {
      this._loadingSingleVerification = true;

      this.searchForMdrMatch(city, state, name).then((orgs) => {
        if (orgs.length) {
          this.fillForm(orgs[0]);
          this.selectSchool = orgs[0];
        }
        this._loadingSingleVerification = false;
      });
    } else {
      this.selectSchool = null;
      this.fillForm(null);
    }

    this.state = this.SINGLE;
  }

  showMore() {
    this._truncated = false;
  }

  skipForNow() {
    this._storageService.skipMdrForNow = true;
    this.hide(false);
  }

  manageSchools() {
    this.hide(false);

    this._breadcrumbService.go('root.account.nav.profile', {
      tab: UserProfileController.SchoolTab,
    });
  }

  static show(
    $mdDialog,
    organization,
    unverifiedOrganizations,
    userId,
    dismissable,
  ) {
    return $mdDialog.show({
      controller: SelectMdrOrgDialogController,
      template: SelectMdrOrgDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        organization,
        unverifiedOrganizations,
        userId,
        dismissable,
      },
    });
  }

  hide(result) {
    this.$mdDialog.hide(result);
  }
}
