'use strict';

import Roster from '../../model/domain/roster';
import ColorMenuController from '../../components/color-menu/color-menu.controller';
import { Colors } from '../../model/domain/colors';
import {
  Locations,
  PaywallSources,
} from '../../services/mixpanel/mixpanel.service';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ProInfoDialogController from '../../components/pro-info-dialog/pro-info-dialog.controller';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ImportGoogleRostersDialogController from '../../components/import-google-rosters-dialog/import-google-rosters-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import { GoogleClassroom } from '../../services/google/google-classroom';
import ChooseRosterDialogTemplate from './choose-roster-dialog.html';

/**
 * Dialog to support adding a roster to an assignment
 */
export default class ChooseRosterDialogController {
  constructor(
    $mdDialog,
    $mdToast,
    $mdMedia,
    $scope,
    $q,
    $mdPanel,
    $log,
    AnalyticsService,
    RosterService,
    AuthService,
    GoogleClassroomService,
    CacheService,
    rosters,
    assignment,
    pro,
    isNew,
    deferred,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$mdMedia = $mdMedia;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$q = $q;
    this.$mdPanel = $mdPanel;
    this.$log = $log;

    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {GoogleClassroomService} */
    this._googleClassroomService = GoogleClassroomService;
    /** @type {CacheService} */
    this._cacheService = CacheService;

    /** @type {Assignment} */
    this._assignment = assignment;
    /** @type {Map.<string, Roster>} */
    this._rosters = rosters;
    /** @type {ProInfo} */
    this._pro = pro;
    /** @type {boolean} */
    this._isNew = isNew;
    /** @type {$q.defer} */
    this._deferred = deferred;

    this.mode = this.MODE_CHOOSE;

    /** @type {Array.<Roster>} */
    this.availableRosters = [...rosters.values()].filter(
      (roster) =>
        !roster.isHidden &&
        !this._assignment.rosters.find((id) => id === roster.id),
    );

    this.rosterName = null;
    this.rosterColor = Colors.ROSTER_COLORS[Math.floor(Math.random() * 12)].hex;

    this._colorMenu = ColorMenuController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._proInfoDialog = ProInfoDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._importGoogleRostersDialog = ImportGoogleRostersDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    this._googleClassroom = new GoogleClassroom(
      this.$log,
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._authService,
      this._googleClassroomService,
    );
  }

  /**
   * @param $mdDialog
   * @param $q
   * @param rosters {Map.<string, Roster>}
   * @param assignment {Assignment}
   * @param pro {ProInfo}
   * @param [isNew] {boolean}
   * @param [completion] {$q.defer}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, $q, rosters, assignment, pro, isNew, completion) {
    // We're using a deferred promise because we know that this dialog can be closed without
    // the 'choose roster' process actually being completed. By working with a promise other
    // than the $mdDialog.show one, we can have more control over things.
    if (!completion) {
      completion = $q.defer();
    }

    $mdDialog.show({
      controller: ChooseRosterDialogController,
      template: ChooseRosterDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        rosters,
        assignment,
        pro,
        isNew,
        deferred: completion,
      },
    });

    return completion.promise;
  }

  get pro() {
    return this._pro;
  }

  set pro(value) {
    this._pro = value;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * Resolves the promise with the rosterId indicating the roster to add
   *
   * @param rosterId {string}
   */
  confirm(rosterId) {
    this.$mdDialog.hide();

    this._deferred.resolve({
      id: rosterId,
      isNew: this._isNew,
    });
  }

  toggleMode() {
    if (this.mode === this.MODE_CHOOSE) {
      this.mode = this.MODE_NEW;
    } else {
      this.mode = this.MODE_CHOOSE;
    }
  }

  get MODE_CHOOSE() {
    return 'choose';
  }

  get MODE_NEW() {
    return 'new';
  }

  fieldUpdated(field) {
    field.$touched = true;
  }

  newRoster() {
    this._rosterService
      .create({
        name: this.rosterName,
        color: this.rosterColor,
        allowPeerHelp: false,
        allowNewMembers: true,
        allowMultiLogin: true,
      })
      .then((roster) => {
        this._isNew = true;
        this.confirm(roster.id);
      });
    this._analyticsService.rosterCreatedFrom(Locations.ASSIGNMENT_EDIT);
  }

  showColorMenu() {
    this._colorMenu(
      this.$q,
      this.$mdPanel,
      this.$mdMedia,
      '.choose-roster-dialog .roster-color span',
    ).then((color) => {
      this.rosterColor = color.hex;
    });
  }

  /**
   * Imports Google Courses as Classkick Rosters
   * @returns {Promise}
   */
  importFromGoogleClassroom() {
    if (!this.pro.hasStudentAccounts) {
      this._proInfoDialog(this.$mdDialog, PaywallSources.IMPORT_GOOGLE_ROSTERS);
    } else if (!this._googleClassroom.isGoogleAuthed) {
      this._googleClassroom.showNoGoogleAuthError();
    } else {
      this._googleClassroom
        .getCourses()
        .then((courses) => {
          return this._googleClassroom.selectCourses(courses);
        })
        .then((courses) => {
          let promise = this._googleClassroom
            .importCourses(courses)
            .then(() => this._cacheService.getRostersForUser(true));

          this._loadingDialog(this.$mdDialog, promise);

          return promise;
        })
        .then((rosters) => {
          return ChooseRosterDialogController.show(
            this.$mdDialog,
            this.$q,
            rosters,
            this._assignment,
            this._pro,
            true,
            this._deferred,
          );
        })
        .catch(() => {
          this._showGoogleImportError();
        });
    }
  }

  _showGoogleImportError() {
    this._errorDialog(
      this.$mdDialog,
      'Whoops! There was an error importing your students from Google Classroom',
      'Please try again and if the issue persists send us a message at support@classkick.com',
    );
  }
}
