'use strict';

import LoadingDialog from '../../components/loading-dialog/loading-dialog.controller';
import { SessionDataCodes } from '../../model/domain/session-data';
import { PeerHelpInboxController } from '../../components/peer-help-inbox/peer-help-inbox.controller';
import { StudentInfoMenuController } from '../../components/student-info-menu/student-info-menu.controller';
import StudentAccountHelpDialogController from '../../components/student-account-help-dialog/student-account-help-dialog.controller';
import ViewHelpDialogController, {
  ViewHelps,
} from '../../components/view-help-dialog/view-help-dialog.controller';
import SlideForeground from '../../model/ui/elements/slide-foreground';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import { BulkMenuOptions } from '../../components/select-bulk-update-menu/select-bulk-update-menu.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';

export default class SessionWorkController {
  constructor(
    $q,
    $scope,
    $state,
    $location,
    $log,
    $mdToast,
    $stateParams,
    $document,
    $timeout,
    $mdDialog,
    $mdPanel,
    $window,
    CacheService,
    BreadcrumbService,
    hotkeys,
    PlatformHeaderService,
    AnalyticsService,
    BulkUpdateService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$location = $location;
    this.$log = $log;
    this.$mdToast = $mdToast;
    this.$stateParams = $stateParams;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this._window = angular.element($window);

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BulkUpdateService} */
    this._bulkUpdateService = BulkUpdateService;

    this._assignmentId = $stateParams.assignmentId;
    this._rosterId = $stateParams.rosterId;

    this._platformHeaderService.setAssignmentId(this._assignmentId);
    this._platformHeaderService.setRosterId(this._rosterId);
    this._showPQS = false;

    this._error = null;

    /** @type {SessionData} */
    this._sessionData = null;

    this._selectedStudentsAndQuestions = {};
    this._selectedStudents = {};
    this._hoverStudentsAndQuestions = {};
    this._coverSlideStatus = 0;

    this._loadingDialog = LoadingDialog.show;
    this._showPeerHelpInbox = PeerHelpInboxController.show;
    this._showStudentInfoMenu = StudentInfoMenuController.show;
    this._showStudentAccountHelpDialog =
      StudentAccountHelpDialogController.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    this._nullMethod = () => {};

    this._scrollLeft = 0;
    this._cellWidth = 0;
    this._selectedQuestionSummaryValue = '';

    this._resetScrollElementValues();

    $scope.$on('$destroy', () => this._destroy());

    this._initHotkeys($scope, hotkeys);
    this._init();
  }

  _initHotkeys(scope, hotkeys) {
    hotkeys
      .bindTo(scope)
      .add({
        combo: 'left',
        description: 'Scroll Left',
        callback: () => {
          if (this.canScrollLeft) {
            this.scrollLeft();
          }
        },
      })
      .add({
        combo: 'right',
        description: 'Scroll Right',
        callback: () => {
          if (this.canScrollRight) {
            this.scrollRight();
          }
        },
      });
  }

  _init() {
    this._cacheService
      .getSessionData(this._assignmentId, this._rosterId, false, true)
      .then((data) => {
        this._sessionData = data;

        if (!this.data.isComplete) {
          if (!this.data.hasRosters) {
            this._error = new Error(
              'There are no rosters associated with this assignment.',
            );
            this._error.noRosters = true;
            return undefined;
          } else {
            this.goToNewRoster(this.data.assignmentRosters[0].id);
            return undefined;
          }
        } else if (!this.data.teacher.sessionViewShowWork) {
          this.goToWatchView();
          this._sessionData = null;
          return undefined;
        }

        this.data.updated.subscribe(this._onSessionDataUpdated, this);
        this.data.start();

        this.data.configureSortFromStorage();

        this._initScrollSync();

        // HACK: Jam the student info DOM stuff into a specific location within the md-virtual-repeat-container so
        // that we can get the scroll behavior we want.
        const studentInfo = angular.element(
          '.session-work .body-content .student-info',
        );
        const scroller = angular.element(
          '.session-work .body-content .virtual-container .md-virtual-repeat-scroller',
        );
        scroller.prepend(studentInfo);
      })
      .catch((err) => {
        this._error = err;
        throw err;
      });
  }

  _destroy() {
    this._platformHeaderService.setAssignmentId(undefined);
    this._platformHeaderService.setRosterId(undefined);
    this._window.off('resize', this._anonOnResize);
  }

  _initScrollSync() {
    this._anonOnResize = this._onResize.bind(this);
    this._window.on('resize', this._anonOnResize);

    this._mainScroller = angular.element(
      '.session-work .body-head .horizontal-scroll',
    );
    this._studentScroller = angular.element(
      '.session-work .body-content .horizontal-scroll',
    );

    this._anonOnHScroll = this._onHScroll.bind(this);

    this._mainScroller.on('scroll', this._anonOnHScroll);
    this._studentScroller.on('scroll', this._anonOnHScroll);
  }

  //--------------- Scrolling methods -------------------------

  /**
   * How many cells to scroll when using next/back buttons or left/right keys
   * @return {number}
   */
  get ScrollItems() {
    return 3;
  }

  _onResize() {
    this._resetScrollElementValues();
    this._debounceApply();
  }

  _resetScrollElementValues() {
    this._mainScrollerWidth = undefined;
    this._mainScrollerScrollWidth = undefined;
  }

  _debounceApply() {
    if (this._debounceApplyPromise) {
      this.$timeout.cancel(this._debounceApplyPromise);
    }
    this._debounceApplyPromise = this.$timeout(this._nullMethod, 200);
  }

  _onHScroll(event) {
    let targetScrollLeft = angular.element(event.target).scrollLeft();
    if (targetScrollLeft !== this._scrollLeft) {
      this._scrollHorizontal(targetScrollLeft);
      this._debounceApply();
    }
  }

  /**
   * Scrolls the header and body scroll elements to the same position to keep in sync
   * @param left {number} the scroll position to place the elements
   */
  _scrollHorizontal(left) {
    this._scrollLeft = left;
    this._mainScroller.scrollLeft(left);
    this._studentScroller.scrollLeft(left);

    // CKW-1112 - If it didn't work just try again. Couldn't figure out root cause
    // Setting _studentScroller.scrollLeft just doesn't work sometimes
    let studentScrollLeft = this._studentScroller.scrollLeft();
    if (studentScrollLeft === 0 && studentScrollLeft !== left) {
      this.$timeout(() => this._scrollHorizontal(left), 10, false);
    }
  }

  _animateScrollHorizontal(left) {
    this._mainScroller.animate({ scrollLeft: left }, this.ScrollPeriod);
    this._studentScroller.animate({ scrollLeft: left }, this.ScrollPeriod);
  }

  /**
   * How many milliseconds the scroll animation should take
   * @return {number}
   */
  get ScrollPeriod() {
    return 300;
  }

  get canScrollLeft() {
    return this._scrollLeft > 0;
  }

  get cannotScrollLeft() {
    return !this.canScrollLeft;
  }

  scrollLeft() {
    let scroll = Math.max(
      0,
      this._mainScroller.scrollLeft() - this.ScrollItems * this.cellWidth,
    );
    let offset = scroll % this.cellWidth;
    if (offset !== 0) {
      scroll = scroll - offset + this.cellWidth;
    }
    this._animateScrollHorizontal(scroll);
  }

  get canScrollRight() {
    return this._scrollLeft <= this.remainingHScrollableSpace - 2;
  }

  get cannotScrollRight() {
    return !this.canScrollRight;
  }

  scrollRight() {
    let scroll = Math.min(
      this.remainingHScrollableSpace,
      this._mainScroller.scrollLeft() + this.ScrollItems * this.cellWidth,
    );
    let offset = scroll % this.cellWidth;
    if (scroll !== this.remainingHScrollableSpace && offset !== 0) {
      scroll = scroll - offset;
    }
    this._animateScrollHorizontal(scroll);
  }

  /**
   * @return {number|undefined}
   */
  get cellWidth() {
    if (!this._cellWidth) {
      let cell = angular.element(
        '.session-work .body-head .question-cell-header',
      );
      this._cellWidth = cell ? cell.outerWidth(true) : 0;
    }
    return this._cellWidth;
  }

  /**
   * Returns the remaining horizontal space that can be scrolled
   * @return {number}
   */
  get remainingHScrollableSpace() {
    if (this._mainScroller) {
      return this.mainScrollerScrollWidth - this.mainScrollerWidth;
    } else {
      return 0;
    }
  }

  /**
   * The viewable width of the scroller with the header cells
   * @return {number}
   */
  get mainScrollerWidth() {
    if (!this._mainScrollerWidth) {
      this._mainScrollerWidth = this._mainScroller
        ? this._mainScroller.width()
        : 0;
    }
    return this._mainScrollerWidth;
  }

  /**
   * The total width of all the header cells including those that are hidden
   * @return {number}
   */
  get mainScrollerScrollWidth() {
    if (!this._mainScrollerScrollWidth) {
      this._mainScrollerScrollWidth = this._mainScroller
        ? this._mainScroller[0].scrollWidth
        : 0;
    }
    return this._mainScrollerScrollWidth;
  }

  //--------------- Data methods -------------------------

  /**
   * @returns {SessionData}
   */
  get data() {
    return this._sessionData;
  }

  /**
   * @param event {{code: string}}
   * @private
   */
  _onSessionDataUpdated(event) {
    const { code, userIsNowOnline, userIsLoggingOnForFirstTime, type } = event;

    if (code === SessionDataCodes.ROSTER_MEMBER) {
      this.data.studentSortDebounce.tick();
    } else if (
      code === SessionDataCodes.STUDENT_STATUS &&
      (userIsNowOnline || userIsLoggingOnForFirstTime)
    ) {
      this.data.studentSortDebounce.tick();
    } else if (
      code === SessionDataCodes.BULK_UPDATE &&
      (type === BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE ||
        type === undefined)
    ) {
      this._selectedStudentsAndQuestions = {};
      this._selectedStudents = {};
    }

    this._debounceApply();
  }

  get error() {
    return this._error;
  }

  get loaded() {
    return !this.error && !this.loading;
  }

  get loading() {
    return !this.data && !this.error;
  }

  /**
   * Returns a config for the bottom layer, which shows a particular slide in the assignment
   * @param userId {string}
   * @param questionId {string}
   * @return {AssignmentThumbnailMetadata|null}
   */
  assignmentThumbnailConfig(userId, questionId) {
    if (!this.loaded) {
      return null;
    }

    return this.data.assignmentThumbnailConfig(userId, questionId);
  }

  /**
   * Returns the config for the top layer, which shows either the assignment work or an assignment with the manipulative parents
   * @param userId {string}
   * @param questionId {string}
   * @return {AssignmentThumbnailMetadata|null}
   */
  assignmentWorkThumbnailConfig(userId, questionId) {
    if (!this.loaded) {
      return null;
    }

    return this.data.assignmentWorkThumbnailConfig(userId, questionId);
  }

  //-------------------- Per Question Summary Methods ------------------------------

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  get showPQS() {
    return this._showPQS;
  }

  getQuestionSummaryValue(value, question) {
    if (this.showPQS) {
      if (value === 'Slide Point Value') {
        this._selectedQuestionSummaryValue = this.getSlidePointValue(question);
      } else {
        this._selectedQuestionSummaryValue = 1;
      }
    }
  }

  get selectedQuestionSummaryValue() {
    return this._selectedQuestionSummaryValue;
  }

  set selectedQuestionSummaryValue(value) {
    this._selectedQuestionSummaryValue = value;
  }

  getSlidePointValue(question) {
    return question.points === 1
      ? `${question.points}pt`
      : `${question.points}pts`;
  }

  //-------------------- Navigate Methods ------------------------------

  get viewType() {
    return this.$location.$$url.includes('work') ? 'wcv' : 'sqv';
  }

  get feedbackType() {
    return this.data.placingSticker ? 'sticker' : 'coverSlides';
  }

  goToWatchView() {
    this._breadcrumbService.go(
      'root.account.session.watch',
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: this._rosterId,
      },
      true,
    );
  }

  goToNewRoster(rosterId) {
    this._breadcrumbService.go(
      this.$state.current.name,
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: rosterId,
      },
      true,
    );
  }

  goToRosterEdit() {
    this._breadcrumbService.go('root.account.nav.roster', {
      rosterId: this._rosterId,
    });
  }

  /**
   * @param question {AssignmentQuestion}
   * @param event {jQuery.Event}
   */
  goToEditQuestion(question, event) {
    event.stopPropagation();

    this._breadcrumbService.go('root.account.assignment-question', {
      assignmentId: this._assignmentId,
      questionId: question.id,
    });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @param event {jQuery.Event}
   */
  handleThumbnailClicked(userId, questionId, event) {
    event.stopPropagation();

    if (
      this.data.bulkUpdateOption &&
      (this.data.placingSticker || this.data.placingStop)
    ) {
      if (!Object.keys(this._selectedStudentsAndQuestions).length) {
        this.data.placingSticker = undefined;
        this.data.placingStop = undefined;
        return this._errorDialog(
          this.$mdDialog,
          'Before applying changes, please ensure you have selected<br> slides by checking for a green outline around them',
          '',
        );
      }
      return this.applyBulkUpdate();
    } else if (
      this.data.bulkUpdateOption &&
      !this.data.placingSticker &&
      !this.data.placingStop
    ) {
      return this.selectThumbnails(userId, questionId);
    } else if (this.data.placingSticker) {
      return this.placeSticker(userId, questionId, this.data.placingSticker);
    } else if (this.data.placingStop) {
      return this.placeStop(userId, questionId, this.data.placingStop);
    } else {
      return this.goToFeedbackQuestion(userId, questionId);
    }
  }

  applyBulkUpdate() {
    this._analyticsService.bulkUpdateApplied({
      viewType: this.viewType,
      feedbackType: this.feedbackType,
      selectedBulkUpdateOption: this.data.bulkUpdateOption,
      feedbackTotal: Object.keys(this._selectedStudentsAndQuestions).length,
      studentTotal: Object.keys(this._selectedStudents).length,
      sticker:
        this.feedbackType === 'sticker' ? this.data.placingSticker : null,
    });

    let promises;
    //this is to avoid calling assignment-works endpoint too many times,
    // need to wait for promise to finish for getOrCreateWork before applying bulk updates
    if (
      this.data.bulkUpdateOption ===
        BulkMenuOptions.ONE_STUDENT_ALL_QUESTIONS.TYPE ||
      this.data.bulkUpdateOption ===
        BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS.TYPE
    ) {
      promises = Object.keys(this._selectedStudents).map((studentId) => {
        return this.getOrCreateWork(studentId).then(() => {
          return Object.values(this._selectedStudentsAndQuestions).forEach(
            (studentAndQuestion) => {
              if (studentAndQuestion.userId === studentId) {
                return this.tallySlidesCoveredAndApplyChanges(
                  studentAndQuestion,
                );
              }
            },
          );
        });
      });
    } else {
      promises = Object.values(this._selectedStudentsAndQuestions).map(
        (studentAndQuestion) => {
          this.tallySlidesCoveredAndApplyChanges(studentAndQuestion);
        },
      );
    }

    const promise = Promise.all(promises);

    this._loadingDialog(this.$mdDialog, promise);

    return promise
      .then(() => {
        this.data.placingSticker = undefined;
        this.data.placingStop = undefined;
        this._coverSlideStatus = 0;
        let config = this.$mdToast
          .simple()
          .textContent('Bulk update completed')
          .position('bottom right');
        this.$mdToast.show(config);
      })
      .catch((error) => {
        this.$log.error(error);
        return this._errorDialog(
          this.$mdDialog,
          'Sorry there was an issue with bulk updates, please try again',
          '',
        );
      });
  }

  /**
   * @param studentAndQuestion {object}
   */
  tallySlidesCoveredAndApplyChanges(studentAndQuestion) {
    this.getOrCreateWork(studentAndQuestion.userId).then((work) => {
      let workQuestion = work.questionForId(studentAndQuestion.questionId);
      let elements = workQuestion.elements;
      let currentSlideForeground = elements.find(
        (element) => element.type === SlideForeground.type,
      );
      if (currentSlideForeground) {
        this._coverSlideStatus++;
      } else {
        this._coverSlideStatus--;
      }
      return this.placeStickerOrHideSlide(
        studentAndQuestion.userId,
        studentAndQuestion.questionId,
      );
    });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   */
  selectThumbnails(userId, questionId) {
    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS.TYPE
    ) {
      if (this._selectedStudentsAndQuestions[`${userId}-${questionId}`]) {
        delete this._selectedStudentsAndQuestions[`${userId}-${questionId}`];
      } else {
        this._selectedStudentsAndQuestions[`${userId}-${questionId}`] = {
          userId,
          questionId,
        };
        this._selectedStudents[userId] = true;
      }
    }

    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.ALL_STUDENTS_ONE_QUESTION.TYPE
    ) {
      const allSelected =
        this.data.virtualRepeatSortedStudents._sortedStudents.every(
          (student) => {
            return !!this._selectedStudentsAndQuestions[
              `${student.id}-${questionId}`
            ];
          },
        );

      this.data.virtualRepeatSortedStudents._sortedStudents.map((student) => {
        if (
          this._selectedStudentsAndQuestions[`${student.id}-${questionId}`] &&
          allSelected
        ) {
          delete this._selectedStudentsAndQuestions[
            `${student.id}-${questionId}`
          ];
        } else {
          this._selectedStudentsAndQuestions[`${student.id}-${questionId}`] = {
            userId: student.id,
            questionId,
          };
          this._selectedStudents[student.id] = true;
        }
      });
    }

    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.ONE_STUDENT_ALL_QUESTIONS.TYPE
    ) {
      const allSelected = this.data.questions.every((questionItem) => {
        return !!this._selectedStudentsAndQuestions[
          `${userId}-${questionItem.question.id}`
        ];
      });

      this.data.questions.map((questionItem) => {
        if (
          this._selectedStudentsAndQuestions[
            `${userId}-${questionItem.question.id}`
          ] &&
          allSelected
        ) {
          delete this._selectedStudentsAndQuestions[
            `${userId}-${questionItem.question.id}`
          ];
        } else {
          this._selectedStudentsAndQuestions[
            `${userId}-${questionItem.question.id}`
          ] = {
            userId,
            questionId: questionItem.question.id,
          };
          this._selectedStudents[userId] = true;
        }
      });
    }

    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE
    ) {
      this._selectedStudentsAndQuestions = {};
      this._selectedStudents = {};
      this._coverSlideStatus = 0;
      this.data.bulkUpdateOption = undefined;
      this._bulkUpdateService.bulkUpdateChanged({
        option: this.data.bulkUpdateOption,
        thumbnailsSelected: Object.keys(this._selectedStudentsAndQuestions)
          .length,
      });
      return this.goToFeedbackQuestion(userId, questionId);
    }

    this._bulkUpdateService.bulkUpdateChanged({
      option: this.data.bulkUpdateOption,
      thumbnailsSelected: Object.keys(this._selectedStudentsAndQuestions)
        .length,
    });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   */
  placeStickerOrHideSlide(userId, questionId) {
    return this.data.placingSticker
      ? this.placeSticker(userId, questionId, this.data.placingSticker)
      : this.placeStop(userId, questionId, this.data.placingStop);
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   */
  goToFeedbackQuestion(userId, questionId) {
    this.getOrCreateWork(userId).then((work) => {
      this._navToFeedbackQuestion(work.id, questionId);
    });
  }

  /**
   * @param assignmentWorkId {string}
   * @param questionId {string}
   */
  _navToFeedbackQuestion(assignmentWorkId, questionId) {
    this._breadcrumbService.go(
      'root.account.session.work.feedback',
      {
        assignmentId: this._assignmentId,
        assignmentWorkId,
        questionId,
      },
      false,
      {
        reload: false,
      },
    );
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @param stop {SlideForeground}
   */
  placeStop(userId, questionId, stop) {
    return this.getOrCreateWork(userId).then((work) => {
      // check if there is an existing SlideForeground
      // if so, remove it, otherwise, add one
      let workQuestion = work.questionForId(questionId);
      let elements = workQuestion.elements;
      let currentSlideForeground = elements.find(
        (element) => element.type === SlideForeground.type,
      );

      if (this.data.bulkUpdateOption) {
        //if there are more cover slides than uncovered slides, un-hide them
        if (this._coverSlideStatus > 0) {
          if (currentSlideForeground) {
            this._analyticsService.coverSlideActivity(
              Locations.ASSIGNMENT_VIEW_WORK,
              questionId,
              'removed',
            );
            this.data.removeStop(work, questionId, currentSlideForeground);
          } else {
            return;
          }
        } else {
          // if there are less cover slides than uncovered slides
          // or there is an equal amount of covered and uncovered slides, hide them
          if (currentSlideForeground) {
            return;
          } else {
            this._analyticsService.coverSlideActivity(
              Locations.ASSIGNMENT_VIEW_WORK,
              questionId,
              'added',
            );
            this.data.placeStop(work, userId, questionId, stop);
          }
        }
      } else {
        if (currentSlideForeground) {
          this._analyticsService.coverSlideActivity(
            Locations.ASSIGNMENT_VIEW_WORK,
            questionId,
            'removed',
          );
          this.data.removeStop(work, questionId, currentSlideForeground);
        } else {
          this._analyticsService.coverSlideActivity(
            Locations.ASSIGNMENT_VIEW_WORK,
            questionId,
            'added',
          );
          this.data.placeStop(work, userId, questionId, stop);
        }
      }
    });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @param sticker {UserSticker}
   */
  placeSticker(userId, questionId, sticker) {
    return this.getOrCreateWork(userId).then((work) => {
      this.data.placeSticker(work, userId, questionId, sticker);
    });
  }

  /**
   * @param userId {string}
   * @return {Promise<AssignmentWork>}
   */
  getOrCreateWork(userId) {
    let work = this.data.workForStudent(userId);
    return work
      ? this.$q.resolve(work)
      : this.fetchDataForStudent(userId).catch((error) => {
          throw error;
        });
  }

  /**
   * @param userId {string}
   * @return {Promise<AssignmentWork>}
   */
  fetchDataForStudent(userId) {
    let promise = this.data
      .fetchDataForStudent(userId)
      .then((value) => {
        if (value instanceof Error) {
          throw value;
        } else {
          return this.data.workForStudent(userId);
        }
      })
      .catch((error) => {
        throw error;
      });

    if (!this.data.bulkUpdateOption) {
      this._loadingDialog(
        this.$mdDialog,
        promise,
        this.$document,
        '.session-work .body-content',
      );
    }

    return promise;
  }

  openPeerHelpInbox(event, user) {
    let helpHistory = this.data.studentHelpHistorySortedChronologically(
      user.id,
    );
    let helpers = this.data.studentActiveHelpers(user.id);

    this._showPeerHelpInbox(
      this.$mdPanel,
      this.$q,
      event,
      user,
      helpHistory,
      helpers,
      this.data.students,
      this.data.assignment,
    ).then(({ userId, questionId }) => {
      this.goToFeedbackQuestion(userId, questionId);
    });
  }

  showStudentInfoMenu(event, user) {
    this._showStudentInfoMenu(
      this.$mdPanel,
      this.$q,
      event,
      user,
      this.data.studentLastSeen(user.id),
      this.data.pro,
      this._rosterId,
    );
  }

  openStudentAccountHelpDialog() {
    this._showStudentAccountHelpDialog(this.$mdDialog, this.data.pro);
  }

  openGivingFeedbackHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.GivingFeedback);
  }

  isSelectedCell(userId, questionId) {
    return !!this._selectedStudentsAndQuestions[`${userId}-${questionId}`];
  }

  canHover() {
    if (
      this.data.bulkUpdateOption !==
        BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE &&
      this.data.bulkUpdateOption !== undefined
    ) {
      if (!this.data.placingStop && !this.data.placingSticker) {
        return true;
      }
    }
    return false;
  }

  isHovering(userId, questionId) {
    return (
      !!this._hoverStudentsAndQuestions[`${userId}-${questionId}`] &&
      !this.isSelectedCell(userId, questionId) &&
      this.canHover()
    );
  }

  highlightCells(userId, questionId) {
    if (
      this.data.bulkUpdateOption === undefined ||
      this.data.bulkUpdateOption ===
        BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE
    ) {
      return;
    }

    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.ALL_STUDENTS_ONE_QUESTION.TYPE
    ) {
      this._hoverStudentsAndQuestions = {};
      this.data.virtualRepeatSortedStudents._sortedStudents.map((student) => {
        this._hoverStudentsAndQuestions[`${student.id}-${questionId}`] = {
          userId: student.id,
          questionId,
        };
      });
    }

    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.ONE_STUDENT_ALL_QUESTIONS.TYPE
    ) {
      this._hoverStudentsAndQuestions = {};
      this.data.questions.map((questionItem) => {
        this._hoverStudentsAndQuestions[
          `${userId}-${questionItem.question.id}`
        ] = {
          userId,
          questionId: questionItem.question.id,
        };
      });
    }

    if (
      this.data.bulkUpdateOption ===
      BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS.TYPE
    ) {
      this._hoverStudentsAndQuestions = {};
      this._hoverStudentsAndQuestions[`${userId}-${questionId}`] = {
        userId,
        questionId,
      };
    }
  }

  clearHighlightedCells() {
    this._hoverStudentsAndQuestions = {};
  }
}
