'use strict';

import InviteTeacherDialog from '../../components/invite-teacher-dialog/invite-teacher-dialog.controller';
import CreateContractStudentsDialog from '../../components/create-contract-students-dialog/create-contract-students-dialog.controller';
import VerifyOrganizationMemberDialogController from '../../components/verify-organization-member-dialog/verify-organization-member-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import VirtualCollection from '../../model/domain/virtual-collection';

export class ContractUsersListColumns {
  static get FIRST_NAME() {
    return 'first name';
  }

  static get LAST_NAME() {
    return 'last name';
  }

  static get EMAIL() {
    return 'email';
  }

  static get ROLE() {
    return 'role';
  }

  static get VERIFIED() {
    return 'verified';
  }

  static get CLEVER() {
    return 'clever';
  }
}

export class FilterRoles {
  static get STUDENT() {
    return 'Student';
  }

  static get TEACHER() {
    return 'Teacher';
  }

  static get ADMIN() {
    return 'Admin';
  }
}

export class AdminActions {
  static get VERIFY() {
    return 'Verify membership';
  }

  static get DELETE() {
    return 'Remove from organization';
  }
}

/**
 * Contract Users List controller
 */
export default class ContractUsersListController {
  constructor(
    $q,
    $location,
    $state,
    $stateParams,
    $mdDialog,
    $mdSidenav,
    $log,
    $mdToast,
    AuthService,
    CacheService,
    ContractService,
    BreadcrumbService,
    OrganizationService,
    StorageService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$location = $location;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$mdSidenav = $mdSidenav;
    this.$log = $log;
    this.$mdToast = $mdToast;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._contractId = $stateParams.contractId;
    this._adminContracts = [];
    this._selectedContract = null;
    this._isCleverContract = false;
    this._loading = true;
    this._error = undefined;

    this._showFirstName = true;
    this._showLastName = true;
    this._showEmail = true;
    this._showRole = true;
    this._showVerified = false;
    this._showClever = false;

    this._orderBy = ContractUsersListColumns.LAST_NAME;
    this._isAscending = true;

    this._inviteTeacherDialog = InviteTeacherDialog.show;
    this._createContractStudentsDialog = CreateContractStudentsDialog.show;
    this._verifyOrganizationMemberDialog =
      VerifyOrganizationMemberDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._loadingDialog = LoadingDialogController.show;

    // configures the initial search bar behavior
    this._query = '';
    this._roleFilter = undefined;
    this._rolesPresentInList = new Set([
      FilterRoles.STUDENT,
      FilterRoles.TEACHER,
      FilterRoles.ADMIN,
    ]);
    this._actionsList = new Set([AdminActions.VERIFY, AdminActions.DELETE]);
    this._virtualUsers = undefined;
    this._selectedListObj = this.getStorageServiceSelectedUsers(
      $stateParams.contractId,
    );
    this._selectAllCheckbox = false;
    this._actionSelection = undefined;

    this._verifyUserId = this.$stateParams.verifyUserId;

    this._init();
  }

  _init() {
    this._loading = true;

    if (!this._contractId) {
      this.$q
        .resolve(this._cacheService.getContracts())
        .then((contracts) => {
          // Verify that current user is contract admin
          this._adminContracts = contracts.filter((contract) => {
            return (
              contract.isPro &&
              this._authService.authData.isContractAdmin(contract.id)
            );
          });

          if (this._adminContracts.length) {
            this.selectContract(this._adminContracts[0]);
          } else {
            throw new Error(this.currentUserIsNotAdminError);
          }
        })
        .catch((err) => {
          this.$log.error('#init', err);
          this._error = err;
          this._loading = false;
        });
    } else {
      this.$q
        .all({
          userMap: this._cacheService.getUsersForContract(
            this._contractId,
            true,
          ),
          schools: this._cacheService.getSchools(),
          contracts: this._cacheService.getContracts(),
        })
        .then(({ userMap, schools, contracts }) => {
          // Verify that current user is contract admin
          if (!this._authService.authData.isContractAdmin(this._contractId)) {
            throw new Error(this.currentUserIsNotAdminError);
          }

          this._adminContracts = contracts.filter((contract) => {
            return (
              contract.isPro &&
              this._authService.authData.isContractAdmin(contract.id)
            );
          });
          this._selectedContract = contracts.find(
            (contract) => contract.id === this._contractId,
          );

          this._users = Array.from(userMap.values());
          this._school = schools.find(
            (school) => school.contractId === this._contractId,
          );
          if (
            this._school &&
            this._school.properties &&
            this._school.properties.provider === 'clever-import'
          ) {
            this._isCleverContract = true;
          }
          this._showVerified = !!this._school;
          this._showClever = this._isCleverContract;
          this._virtualUsers = new VirtualCollection(this._users, 50);

          let userToVerify = this._users.find(
            (user) => user.id === this._verifyUserId,
          );

          // If contract is not associated with a school, show error message indicating that
          if (this._verifyUserId && !this._school) {
            this._errorDialog(
              this.$mdDialog,
              'Uh oh! An error occurred trying to verify this user for your organization',
              'Please try again and if you continue to see this issue reach out to support@classkick.com',
            );
          }
          // If user to verify is not on contract, show error message indicating that
          else if (this._verifyUserId && !userToVerify) {
            this._errorDialog(
              this.$mdDialog,
              'Uh oh! It looks like the user your are trying to verify is no longer a member of this organization',
              'If you have any questions, please reach out to support@classkick.com',
            );
          }
          // If the user to verify has already been confirmed for this organization
          else if (
            this._verifyUserId &&
            this._school.isVerifiedFor(userToVerify.id)
          ) {
            this._confirmDialog(
              this.$mdDialog,
              `${userToVerify.name} has already been verified as a member of ${this._school.name}`,
              'If you would like to remove them from your organization, click the "more options" button next to their name and "remove from organization"',
              true,
            ).then(() => this._clearQueryParams());
          } else if (this._verifyUserId) {
            this._verifyOrganizationMemberDialog(
              this.$mdDialog,
              userToVerify,
              this._school,
            ).then((result) => {
              this._clearQueryParams();

              if (result === true) {
                this._verifyMember(userToVerify);
              } else if (result === false) {
                this._removeMemberFromOrganization(userToVerify);
              }
            });
          }
          this._loading = false;
        })
        .then(() => {
          this._virtualUsers.loadedCollection.forEach((user) => {
            if (this._selectedListObj[user.id]) {
              this._selectedListObj[user.id] = this._selectedListObj[user.id];
            } else {
              this._selectedListObj[user.id] = false;
            }
          });
        })
        .catch((err) => {
          this.$log.error('#init', err);
          this._error = err;
          this._loading = false;
        });
    }
  }

  get errorMessage() {
    if (
      this._error &&
      this._error.message === this.currentUserIsNotAdminError
    ) {
      return 'In order to view/edit the pro users on a contract, you must be a contract admin.';
    } else {
      return 'Sorry, there was a problem loading the users for this contract';
    }
  }

  get currentUserIsNotAdminError() {
    return 'currentUserIsNotAdminError';
  }

  _clearQueryParams() {
    this.$location.search({});
    this._verifyUserId = undefined;
  }

  roleForUser(user) {
    if (user && user.isStudent) {
      return FilterRoles.STUDENT;
    } else if (user && user.isTeacher) {
      return user.contractMembers &&
        user.contractMembers.length > 0 &&
        user.contractMembers[0].admin === true
        ? FilterRoles.ADMIN
        : FilterRoles.TEACHER;
    }
  }

  /**
   *
   * @returns {boolean}
   */
  get isCleverContract() {
    return this._isCleverContract;
  }

  /**
   *
   * @returns {Array}
   */
  get roles() {
    return [...this._rolesPresentInList];
  }

  /**
   *
   * @returns {string}
   */
  get roleFilter() {
    return this._roleFilter;
  }

  /**
   *
   * @param value {string}
   */
  set roleFilter(value) {
    this._roleFilter = value;
    this.applyAllFilters();
  }

  showUserBasedOnRoleFilter(user) {
    if (!this.roleFilter) {
      return true;
    }
    return (
      this.roleForUser(user) &&
      this.roleForUser(user).toLowerCase() === this.roleFilter.toLowerCase()
    );
  }

  /**
   *
   * @returns {string}
   */
  get actionSelection() {
    return this._actionSelection;
  }

  /**
   *
   * @param value {string}
   */
  set actionSelection(value) {
    this._actionSelection = value;
  }

  /**
   *
   * @returns {Array}
   */
  get adminActions() {
    return [...this._actionsList];
  }

  selectAction(action) {
    if (action === AdminActions.DELETE) {
      this.bulkRemoveUsersFromOrganization();
    } else {
      this.bulkVerifyMembers();
    }
    this._actionSelection = undefined;
  }

  editUser(userId) {
    this._breadcrumbService.go('root.account.contract-user', {
      contractId: this.$stateParams.contractId,
      userId: userId,
    });
  }

  /**
   * gets the current value of the search bar query
   * @returns {string}
   */
  get query() {
    return this._query;
  }

  /**
   * sets the current value of the search bar query
   * @param value {string}
   */
  set query(value) {
    this._query = value;
    this.applyAllFilters();
  }

  /**
   * @returns {Function} returns a function that knows how to filter the list of assignments
   */
  searchFilter() {
    let query = this.query;
    let roleForUser = this.roleForUser;
    return function (user) {
      if (query.length > 0) {
        let nameMatch;
        let emailMatch;
        let usernameMatch;
        let roleMatch;
        if (user.firstName && user.lastName) {
          nameMatch =
            user.firstName.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            user.lastName.toLowerCase().indexOf(query.toLowerCase()) > -1;
        }
        if (user.email) {
          emailMatch =
            user.email.toLowerCase().indexOf(query.toLowerCase()) > -1;
        }
        if (user.username) {
          usernameMatch =
            user.username.toLowerCase().indexOf(query.toLowerCase()) > -1;
        }
        roleMatch =
          roleForUser(user).toLowerCase().indexOf(query.toLowerCase()) > -1;
        return nameMatch || emailMatch || usernameMatch || roleMatch;
      }
      return true;
    };
  }

  /**
   * Toggles the side menu.
   */
  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  /**
   * indicates if table should display user's first name column
   * @returns {boolean}
   */
  get showFirstName() {
    return this._showFirstName;
  }

  /**
   * indicates if table should display user's last name column
   * @returns {boolean}
   */
  get showLastName() {
    return this._showLastName;
  }

  /**
   * indicates if table should display user email column
   * @returns {boolean}
   */
  get showEmail() {
    return this._showEmail;
  }

  /**
   * indicates if table should display user role column
   * @returns {boolean}
   */
  get showRole() {
    return this._showRole;
  }

  /**
   * indicates if table should display user verified column
   * @return {*}
   */
  get showVerified() {
    return this._showVerified;
  }

  /**
   * indicates if table should display user clever column
   * @return {*}
   */
  get showClever() {
    return this._showClever;
  }

  /**
   * Indicates if initial load has completed
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  /**
   * indicates if currently selected column should be ascending or descending
   * @returns {boolean}
   */
  get isAscending() {
    return this._isAscending;
  }

  /**
   * indicates when to show carrot next to the first name column in table header
   * @returns {boolean}
   */
  get showFirstNameCarrot() {
    return this._orderBy === ContractUsersListColumns.FIRST_NAME;
  }

  /**
   * indicates when to show carrot next to the last name column in table header
   * @returns {boolean}
   */
  get showLastNameCarrot() {
    return this._orderBy === ContractUsersListColumns.LAST_NAME;
  }

  /**
   * indicates when to show carrot next to the email column in table header
   * @returns {boolean}
   */
  get showEmailCarrot() {
    return this._orderBy === ContractUsersListColumns.EMAIL;
  }

  /**
   * indicates when to show carrot next to the role column in table header
   * @returns {boolean}
   */
  get showRoleCarrot() {
    return this._orderBy === ContractUsersListColumns.ROLE;
  }

  /**
   * Indicates when to show carrot next to the verified column in table header
   * @returns {boolean}
   */
  get showVerifiedCarrot() {
    return this._orderBy === ContractUsersListColumns.VERIFIED;
  }

  /**
   * Indicates when to show carrot next to the clever column in table header
   * @returns {boolean}
   */
  get showCleverCarrot() {
    return this._orderBy === ContractUsersListColumns.CLEVER;
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (
      (this._storageService.lastSeenTrialConversionBanner &&
        this._storageService.lastSeenTrialConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenRenewalConversionBanner &&
        this._storageService.lastSeenRenewalConversionBanner.showBanner ===
          true) ||
      (this._storageService.lastSeenAssignmentNotificationBanner &&
        this._storageService.lastSeenAssignmentNotificationBanner.showBanner ===
          true)
    );
  }

  /**
   * handles the logic to change the focus and order of columns
   *
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggle(colName, shouldAscend) {
    if (this._orderBy !== colName) {
      this._orderBy = colName;
      this._isAscending = shouldAscend;
    } else {
      this._isAscending = !this._isAscending;
    }
    this.applyAllFilters();
  }

  /**
   * sets the list order to the name column and/or toggles ascending/descending
   */
  setOrToggleFirstName() {
    this.setOrToggle(ContractUsersListColumns.FIRST_NAME, true);
  }

  /**
   * sets the list order to the name column and/or toggles ascending/descending
   */
  setOrToggleLastName() {
    this.setOrToggle(ContractUsersListColumns.LAST_NAME, true);
  }

  /**
   * sets the list order to the email column and/or toggles ascending/descending
   */
  setOrToggleEmail() {
    this.setOrToggle(ContractUsersListColumns.EMAIL, true);
  }

  /**
   * sets the list order to the email column and/or toggles ascending/descending
   */
  setOrToggleRole() {
    this.setOrToggle(ContractUsersListColumns.ROLE, true);
  }

  /**
   * Sets the list order to the verified column and/or toggles ascending/descending
   */
  setOrToggleVerified() {
    this.setOrToggle(ContractUsersListColumns.VERIFIED, true);
  }

  /**
   * Sets the list order to the clever column and/or toggles ascending/descending
   */
  setOrToggleClever() {
    this.setOrToggle(ContractUsersListColumns.CLEVER, true);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  orderBy() {
    let orderBy = this._orderBy;
    let roleForUser = this.roleForUser;
    let isUnverified = this.isUnverified.bind(this);
    return function (user) {
      if (ContractUsersListColumns.FIRST_NAME === orderBy) {
        return user.firstName ? user.firstName.toLowerCase() : '';
      }
      if (ContractUsersListColumns.LAST_NAME === orderBy) {
        return user.lastName ? user.lastName.toLowerCase() : '';
      } else if (ContractUsersListColumns.EMAIL === orderBy) {
        return user.email
          ? user.email.toLowerCase()
          : user.username.toLowerCase();
      } else if (ContractUsersListColumns.ROLE === orderBy) {
        return roleForUser(user);
      } else if (ContractUsersListColumns.VERIFIED === orderBy) {
        return isUnverified(user);
      } else if (ContractUsersListColumns.CLEVER === orderBy) {
        return user.isClever;
      }
    };
  }

  /**
   * Applies all filters (search, order, role)
   */
  applyAllFilters() {
    let orderByFunction = this.orderBy();
    let sort = (a, b) => {
      let result;
      let propertyA = orderByFunction(a);
      let propertyB = orderByFunction(b);
      if (propertyA > propertyB) {
        result = 1;
      } else if (propertyA === propertyB) {
        result = 0;
      } else {
        result = -1;
      }
      return this._isAscending ? result : -result;
    };

    let roleFilter = this.showUserBasedOnRoleFilter.bind(this);
    let searchFilter = this.searchFilter();
    this._virtualUsers.fullCollection = this._users
      .filter(roleFilter)
      .filter(searchFilter)
      .sort(sort);
  }

  /**
   * The number of users needed to not show the help message under the students list
   * @returns {number}
   */
  get USERS_HELPER_LIMIT() {
    return 10;
  }

  get users() {
    return this._users;
  }

  get virtualUsers() {
    return this._virtualUsers;
  }

  hasSelectedUsers() {
    const selectedUsers = Object.keys(this._selectedListObj).filter(
      (user) => this._selectedListObj[user] === true,
    );
    return !!selectedUsers.length;
  }

  addTeacher() {
    this._inviteTeacherDialog(this.$mdDialog, this._contractId);
  }

  addStudent() {
    this._createContractStudentsDialog(this.$mdDialog, this._contractId).then(
      (students) => {
        if (students) {
          this._init();
        }
      },
    );
  }

  verify(user) {
    this._verifyOrganizationMemberDialog(
      this.$mdDialog,
      user,
      this._school,
    ).then((result) => {
      if (result === true) {
        this._verifyMember(user);
      } else if (result === false) {
        this._removeMemberFromOrganization(user);
      }
      this._selectedListObj = {};
    });
  }

  bulkVerifyMembers() {
    const selectedUsers = Object.keys(this._selectedListObj).filter(
      (userId) => {
        const user = this._users.find((user) => user.id === userId);
        return (
          this._selectedListObj[userId] === true && user && !user.isStudent
        );
      },
    );

    if (selectedUsers.length === 0) {
      this._confirmDialog(
        this.$mdDialog,
        'No teachers were selected and students are already verified.',
        'To remove students, select "Remove from organizaton" instead.',
        true,
      );
    } else if (selectedUsers.length === 1) {
      const singleSelectedUser = this._users.find(
        (user) => user.id === selectedUsers[0],
      );
      this.verify(singleSelectedUser);
    } else {
      this._verifyOrganizationMemberDialog(
        this.$mdDialog,
        undefined,
        this._school,
      ).then((result) => {
        if (result === true) {
          return this._bulkVerifyMembers(selectedUsers);
        } else if (result === false) {
          return this._bulkRemoveMembers(selectedUsers);
        }
      });
    }
  }

  verifiedDisplay(user) {
    return this.isUnverified(user) ? 'No' : 'Yes';
  }

  /**
   * @param user {User}
   * @return {boolean}
   */
  isUnverified(user) {
    if (user && user.isStudent) {
      return false;
    } else if (this._school) {
      if (user && user.id) {
        return !this._school.isVerifiedFor(user.id);
      }
    } else {
      return false;
    }
  }

  clearSelectedUsers() {
    this._selectedListObj = {};
    this._actionSelection = undefined;
    this._selectAllCheckbox = false;
    this.resetStorageServiceSelectUsers();
  }

  removeUserFromOrganization(user) {
    this._confirmDialog(
      this.$mdDialog,
      `Are you sure you want to remove ${user.name} from this organization?`,
      'This account will still exist for individual use.',
    ).then(() => {
      if (this._school) {
        this._removeMemberFromOrganization(user);
      } else {
        this._removeMemberFromContract(user);
      }
      this.clearSelectedUsers();
    });
  }

  bulkRemoveUsersFromOrganization() {
    const selectedUsers = Object.keys(this._selectedListObj).filter(
      (user) =>
        this._selectedListObj[user] === true && user !== this.currentUserId,
    );
    if (selectedUsers.length === 1) {
      const singleSelectedUser = this._users.find(
        (user) => user.id === selectedUsers[0],
      );
      this.removeUserFromOrganization(singleSelectedUser);
    } else {
      this._confirmDialog(
        this.$mdDialog,
        'Are you sure you want to remove these users from this organization?',
        'These accounts will still exist for individual use.',
      ).then(() => {
        return this._bulkRemoveMembers(selectedUsers);
      });
    }
  }

  get currentUserId() {
    return this._authService && this._authService.currentUserId;
  }

  _bulkRemoveMembers(users) {
    const promise = this._organizationService
      .bulkRemoveMembers(this._school.id, users)
      .then((data) => {
        this._init();
        this._toast('Users removed');
        this.clearSelectedUsers();
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          'Uh oh! An error occurred trying to remove these users from your organization',
          'If you continue to see this issue reach out to support@classkick.com.',
        );
      });

    this._loadingDialog(this.$mdDialog, promise);
    return promise;
  }

  _removeMemberFromOrganization(user) {
    return this._organizationService
      .removeMember(this._school.id, user.id)
      .then(() => {
        this._init();
        this._toast(`Removed ${user.name}`);
      });
  }

  _removeMemberFromContract(user) {
    this._contractService.removeUser(this._contractId, user.id).then(() => {
      this._init();
      this._toast(`Removed ${user.name}`);
    });
  }

  _verifyMember(user) {
    return this._organizationService
      .verifyMember(this._school.id, user.id, true)
      .then(() => {
        this._school.markVerifiedFor(user.id);
        this._toast(`Verified ${user.name}`);
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          'Uh oh! An error occurred trying to verify this user for your organization',
          `Make sure this user is a member of ${this._school.name} in ${this._school.displayLocation}. If you continue to see this issue reach out to support@classkick.com.`,
        );
      });
  }

  _bulkVerifyMembers(users) {
    const promise = this._organizationService
      .bulkVerifyMembers(this._school.id, users, true)
      .then((data) => {
        const status = Object.keys(data);
        status.forEach((userId) => {
          if (data[userId] === true) {
            this._school.markVerifiedFor(userId);
          }
        });
        this._toast('Users verified');
        this.clearSelectedUsers();
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          'Uh oh! An error occurred trying to verify these users for your organization',
          `Make sure these users are members of ${this._school.name} in ${this._school.displayLocation}. If you continue to see this issue reach out to support@classkick.com.`,
        );
      });

    this._loadingDialog(this.$mdDialog, promise);
    return promise;
  }

  /**
   * @param message {string}
   */
  _toast(message) {
    let config = this.$mdToast
      .simple()
      .textContent(message)
      .position('bottom right');
    this.$mdToast.show(config);
  }

  selectContract(contract) {
    if (contract.id !== this._contractId) {
      this.$state.go('^.contract-users', {
        contractId: contract.id,
      });
    }
  }

  updateSelection() {
    this.saveStorageServiceSelectUsers();
  }

  selectAllUsers() {
    if (this._roleFilter || this._query) {
      this._virtualUsers._fullCollection.forEach((user) => {
        this._selectedListObj[user.id] = false;
        if (user.id !== this.currentUserId) {
          if (user.isClever) {
            this._selectedListObj[user.id] = false;
          } else {
            this._selectedListObj[user.id] = this._selectAllCheckbox;
          }
        }
      });
    } else {
      this._virtualUsers.loadedCollection.forEach((user) => {
        //The virtualContainer will load 50 items even if a contract does not have 50 users
        if (user && user.id) {
          this._selectedListObj[user.id] = false;
          if (user.id !== this.currentUserId) {
            if (user.isClever) {
              this._selectedListObj[user.id] = false;
            } else {
              this._selectedListObj[user.id] = this._selectAllCheckbox;
            }
          }
        }
      });
    }
    this.saveStorageServiceSelectUsers();
  }

  getStorageServiceSelectedUsers(contractId) {
    if (this._storageService.selectedUsersAdminDashboard) {
      const selectUsers = {
        ...this._storageService.selectedUsersAdminDashboard,
      };
      return selectUsers[contractId] ? selectUsers[contractId] : {};
    } else {
      return {};
    }
  }

  saveStorageServiceSelectUsers() {
    const storageSelectedUsers = this._storageService
      .selectedUsersAdminDashboard
      ? { ...this._storageService.selectedUsersAdminDashboard }
      : {};

    storageSelectedUsers[this._contractId] = this._selectedListObj;
    this._storageService.selectedUsersAdminDashboard = storageSelectedUsers;
  }

  resetStorageServiceSelectUsers() {
    const storageSelectedUsers = {
      ...this._storageService.selectedUsersAdminDashboard,
    };
    delete storageSelectedUsers[this._contractId];
    this._storageService.selectedUsersAdminDashboard = storageSelectedUsers;
  }
}
