'use strict';

import Codec from './codec';
import UserSticker from '../../model/domain/user-sticker';

/**
 * Encodes/Decodes a user sticker
 */
export default class UserStickerCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {UserSticker}
   */
  encode(value) {
    return {
      id: value.id,
      text: value.text,
      owner_id: value.ownerId,
      image_url: value.imageUrl,
      created: value.created && value.created.toISOString(),
      tags: value.tags,
    };
  }

  /**
   * @param value {object}
   * @returns {UserSticker}
   */
  decode(value) {
    return new UserSticker(
      value.id,
      value.text,
      value.owner_id,
      value.image_url,
      value.created,
      value.tags,
    );
  }
}
