'use strict';
import Codec from './codec';
import Course from '../domain/course';
/**
 * Encodes/decodes Gradebook course information
 */
export default class CourseCodec extends Codec {
  /**
   * @param value {Course}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        session_id: value.sessionId ? value.sessionId : null,
        name: value.name,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {Course}
   */
  decode(value) {
    if (value) {
      return new Course(
        value.id,
        value.session_id ? value.session_id : null,
        value.name,
      );
    }

    return null;
  }
}
