'use strict';

import ConfirmDialogController from '../confirm-dialog/confirm-dialog.controller';
import ErrorDialogController from '../error-dialog/error-dialog.controller';
import ContractUserResetPasswordDialogTemplate from './contract-user-reset-password-dialog.html';

export default class ContractUserResetPasswordDialogController {
  constructor($timeout, $scope, $mdDialog, UserService, user, contractId) {
    'ngInject';

    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;

    this._user = user;
    this._contractId = contractId;
    this._userService = UserService;

    this.newPassword = null;
    this.showPassword = null;

    this._confirmDialog = ConfirmDialogController.show;
    this._errorDialog = ErrorDialogController.show;
  }

  /**
   * Launches change password dialog
   * @param $mdDialog
   * @param user
   * @param contractId
   */
  static show($mdDialog, user, contractId) {
    return $mdDialog.show({
      controller: ContractUserResetPasswordDialogController,
      template: ContractUserResetPasswordDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        user: user,
        contractId: contractId,
      },
    });
  }

  resetStudentPassword() {
    this._userService
      .updateContractUserPassword(
        this._user.id,
        this._contractId,
        this.newPassword,
      )
      .then(() => {
        this.$mdDialog.hide();
        this._confirmDialog(
          this.$mdDialog,
          `${this._user.name}'s password was updated successfully`,
          '',
          true,
        );
      })
      .catch(() => {
        this._errorDialog(
          this.$mdDialog,
          `Uh oh! There was a problem updating ${this._user.name}'s password`,
          '',
        );
      });
  }

  get user() {
    return this._user;
  }

  get inputType() {
    if (!this.showPassword) {
      return 'password';
    }
    return 'text';
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
