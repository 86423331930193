'use strict';

/**
 * Contains all data mapping a FirebaseObject to a Firebase database
 */
export default class ModelMapping {
  /**
   * @param uriTemplateBase {String} Base uri for a firebase object class. This is prepended to all property mapping uris
   * @param properties {{key: PropertyMapping, [...]}} firebase mappings for the active properties of a class
   */
  constructor(uriTemplateBase, properties) {
    this._uriBase = uriTemplateBase;
    this._urlTemplateToPropertyMap = new Map();
    this._propertyToUrlTemplateMap = new Map();

    this._properties = properties;

    angular.forEach(properties, (property) => {
      let uriTemplate = this.uriBase + property.uriTemplate;
      this.urlTemplateToPropertyMap.set(uriTemplate, property);
      this.propertyToUrlTemplateMap.set(property, uriTemplate);
    });
  }

  /**
   * @returns {{key: PropertyMapping, [...]}}
   */
  get properties() {
    return this._properties;
  }

  /**
   * @returns {String}
   */
  get uriBase() {
    return this._uriBase;
  }

  /**
   * @returns {Map.<String, PropertyMapping>}
   */
  get urlTemplateToPropertyMap() {
    return this._urlTemplateToPropertyMap;
  }

  /**
   * @returns {Map.<PropertyMapping, String>}
   */
  get propertyToUrlTemplateMap() {
    return this._propertyToUrlTemplateMap;
  }

  /**
   * Gets the full firebase url for a property of a firebase object
   * @param instance {FirebaseObject}
   * @param property {PropertyMapping}
   * @returns {string}
   */
  fullUrlForProperty(instance, property) {
    return this.fullUrlFromTemplate(
      instance,
      this.propertyToUrlTemplateMap.get(property),
    );
  }

  /**
   * Gets the property for a firebase uri
   * @param instance {FirebaseObject}
   * @param uri {string}
   * @returns {PropertyMapping}
   */
  propertyForFullUrl(instance, uri) {
    return this.urlTemplateToPropertyMap.get(
      this.urlTemplateFromFull(instance, uri),
    );
  }

  /**
   * Finds the uri template given a full uri and FirebaseObject
   * @param instance {FirebaseObject}
   * @param uri {string}
   * @returns {string}
   */
  urlTemplateFromFull(instance, uri) {
    var result = uri;
    let ids = instance.ids;
    ids.forEach((value, key) => {
      let keyToken = '{0}'.replace('0', key);
      result = result.replace(new RegExp(value, 'g'), keyToken);
    });
    return result;
  }

  /**
   * Finds the full uri given a uri template and FirebaseObject
   * @param instance {FirebaseObject}
   * @param uri {string}
   * @returns {string}
   */
  fullUrlFromTemplate(instance, uri) {
    var result = uri;
    let ids = instance.ids;
    ids.forEach((value, key) => {
      let keyToken = '{0}'.replace('0', key);
      result = result.replace(new RegExp(keyToken, 'g'), value);
    });
    return result;
  }
}
