'use strict';

export default class AssignmentListItem {
  constructor(assignment, newlyCopied) {
    this._assignment = assignment;
    this._newlyCopied = newlyCopied;
    this._helpRequestView = undefined;
    this._disabled = false;
  }

  get assignment() {
    return this._assignment;
  }

  get newlyCopied() {
    return this._newlyCopied;
  }

  /**
   * @return {HelpRequestSet}
   */
  get helpRequests() {
    return this._helpRequestView;
  }

  /**
   * @param value {HelpRequestSet}
   */
  set helpRequests(value) {
    this._helpRequestView = value;
  }

  /**
   * @return {boolean}
   */
  get disabled() {
    return this._disabled;
  }

  /**
   * @param value {boolean}
   */
  set disabled(value) {
    this._disabled = value;
  }
}
