'use strict';

import MdrAddressBookTemplate from './mdr-address-book.component.html';
import ValidationDomainEmailUtils from '../../model/util/validation-domain-email-utils';
import OrganizationService from '../../services/organization/organization.service';
import CacheService from '../../services/cache/cache.service';

class MdrAddressBookController {
  constructor(
    $log,
    AnalyticsService,
    AuthService,
    CacheService,
    OrganizationService,
  ) {
    'ngInject';

    this.$log = $log;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;

    this._state = this.Loading;

    this._userEmail = undefined;
    this._organizationId = undefined;

    this._peers = [];
    this._selectedListObj = {};
    this._selectedRecipientList = [];

    this.searchText = '';
    this.filterPeers = this.filterPeers.bind(this);
  }

  /**
   * Called after the bindings and component have been initialized
   * https://docs.angularjs.org/guide/component
   *
   * Sets up the selectable emails used for the md-checkboxes in component
   */
  $onInit() {
    this.componentTitle = this.componentTitle || 'Select your colleagues';
    this.componentTitleAnalytics =
      this.componentTitleAnalytics || 'addressBookDefault';
    this.toggleSwitch = this.defaultRecipientSelected;

    this._cacheService.getUser().then((userData) => {
      this._userEmail = userData.email;
    });

    this._organizationService
      .getForUser(this._authService.currentUserId)
      .then((orgs) => {
        this._organizationId = orgs[0].id;
        this.getMdrTeachersWithNames();
      });
  }

  /**
   * @return {string}
   */
  get Loading() {
    return 'loading';
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  get peers() {
    return this._peers;
  }

  set peers(value) {
    this._peers = value;
  }

  get selectedRecipientList() {
    return this._selectedRecipientList;
  }

  set selectedRecipientList(value) {
    this._selectedRecipientList = value;
  }

  toggleMdrEmails() {
    if (this.peers && this.peers.length) {
      this.peers.forEach((peer) => {
        this._selectedListObj[peer.email] = this.toggleSwitch;
      });
    }

    this.updateRecipientList();
    return this._selectedListObj;
  }

  updateRecipientList() {
    this._selectedRecipientList = Object.keys(this._selectedListObj).filter(
      (key) => this._selectedListObj[key] === true,
    );
  }

  titleCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  sortByNameAscending(data) {
    return data.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  _getEmailDomain(email) {
    return email.split('@')[1].toLowerCase();
  }

  isAStudentEmailDomain() {
    try {
      const domain = this._getEmailDomain(this._userEmail);
      return domain.includes('student');
    } catch (e) {
      return false;
    }
  }

  dontShowMdrData() {
    this._peers = [];
    this._selectedListObj = {};
    this.updateRecipientList();
  }

  filterPeers(peer) {
    if (!this.searchText) {
      return true;
    }
    this.searchText = this.searchText.toLowerCase();
    return (
      (peer.name && peer.name.toLowerCase().includes(this.searchText)) ||
      (peer.email && peer.email.toLowerCase().includes(this.searchText))
    );
  }

  sendAnalytics() {
    try {
      if (this.peers && this.peers.length > 0) {
        this._analyticsService.sendEvent({
          eventTag: `${this.componentTitleAnalytics}_peersShown`,
          properties: {
            peersCount: this.peers.length,
            orgId: this._organizationId,
            userEmail: this._userEmail,
          },
        });
      } else {
        this._analyticsService.sendEvent({
          eventTag: `${this.componentTitleAnalytics}_noPeersShown`,
          properties: {
            peersCount: 0,
            orgId: this._organizationId,
            userEmail: this._userEmail,
          },
        });
      }
    } catch (error) {
      this.$log.error(error);
    }
  }

  getMdrTeachersWithNames() {
    this._organizationService
      .getMdrPeersAndOrgMembers(this._organizationId)
      .then((peersWithNameAndEmail) => {
        if (peersWithNameAndEmail.length) {
          this._peers = peersWithNameAndEmail
            .filter(
              (peer) =>
                peer.email.toLowerCase() !== this._userEmail.toLowerCase(),
            )
            .map((v) => ({ name: v.name, email: v.email.toLowerCase() }));
          this._peers = this.sortByNameAscending(this._peers);
          this._peers.forEach((peer) => {
            this._selectedListObj[peer.email] = this.defaultRecipientSelected;
          });

          this.updateRecipientList();

          if (this.isAStudentEmailDomain()) {
            this.dontShowMdrData();
          }
        }
        this._state = undefined;
      })
      .catch((error) => {
        this.$log.error(error);
      })
      .finally(() => {
        this.sendAnalytics();
      });
  }
}

/**
 *
 * More information about the bindings:
 * https://docs.angularjs.org/api/ng/service/$compile#-scope-
 * https://docs.angularjs.org/guide/component
 */
export default {
  bindings: {
    componentTitle: '@', // <str> title for the address book widget
    componentTitleAnalytics: '@', // <str> title to track analytics
    defaultRecipientSelected: '<', // <bool> recipients selected by default
    selectedRecipientList: '=', // <list> recipient emails selected by the user
    peers: '=', // <list> all recipients
  },
  transclude: true,
  template: MdrAddressBookTemplate,
  controller: MdrAddressBookController,
  controllerAs: 'ctrl',
};
