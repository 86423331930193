'use strict';
/*global PDFDocument:false, blobStream:false, operative:false*/

//PDFDocument and blobstream are referencing line 150 and 151, do not try importing the modules
// because operative library only accepts script urls

import AssignmentExport from '../../model/domain/assignment-export';
import JSZip from 'jszip';

export default class ExportService {
  constructor($document, $window) {
    'ngInject';

    this.$document = $document;
    this.$window = $window;
    this._XMLSerializer = new XMLSerializer();
  }

  /**
   * Converts map of works and users, and classCode and assignment into AssignmentExports
   * @param workForStudent {Map.<string, Assignment|AssignmentWork>}
   * @param users {User[]|{name: string, id: string}[]}
   * @param classCode {ClassCode}
   * @param assignment {Assignment}
   * @param [showScore] {boolean}
   *
   * @return {AssignmentExport[]}
   */
  createAssignmentExports(
    workForStudent,
    users,
    classCode,
    assignment,
    showScore,
  ) {
    return users.map((user) => {
      let work = workForStudent.get(user.id);
      return new AssignmentExport(
        work || assignment,
        user,
        classCode,
        undefined,
        showScore,
      );
    });
  }

  /**
   * Converts map of works, map of virtual assignments, and user into AssignmentExports
   * @param worksForSingleStudent {Map.<string, AssignmentWork>}
   * @param user {User}
   * @param virtualAssignments {StudentOverviewAssignmentItem[]}
   *
   * @return {AssignmentExport[]}
   */
  createOverviewAssignmentExports(
    worksForSingleStudent,
    user,
    virtualAssignments,
  ) {
    return virtualAssignments.map((virtualAssignment) => {
      let work = worksForSingleStudent.get(virtualAssignment.assignment.id);
      return new AssignmentExport(
        work || virtualAssignment.assignment,
        user,
        '',
        undefined,
      );
    });
  }

  /**
   * @param svgDOM {SVGElement}
   * @return {string}
   */
  svgDOMtoString(svgDOM) {
    return this._XMLSerializer.serializeToString(svgDOM);
  }

  /**
   * Converts svgString to Data URL
   * @param svgString {string}
   * @param [height] {number}
   * @param [width] {number}
   * @return {Promise.<Blob>}
   */
  svgStringToDataURL(svgString, height, width) {
    //need to change foreign object to g element for PDF to render correctly
    const updatedString = svgString.replaceAll('foreignObject', 'g');

    height = height || 2008;
    width = width || 1004;

    return new Promise((resolve) => {
      let canvas = this.$document[0].createElement('canvas');
      canvas.height = height;
      canvas.width = width;

      let ctx = canvas.getContext('2d');

      // drawSvg is a polyfill from the canvg library
      return ctx.drawSvg(updatedString, 0, 0, width, height, {
        useCORS: true,
        renderCallback: () => {
          let png = canvas.toDataURL('image/png');
          resolve(png);
        },
      });
    });
  }

  /**
   * Converts assignmentExport object to PDF File
   * @param assignmentExport {AssignmentExport}
   * @return {Promise.<File>}
   */
  workToPDF(assignmentExport) {
    return this.workToPDFWorker(
      assignmentExport.questionDataURLs,
      assignmentExport.studentDisplay,
      assignmentExport.assignmentDisplay,
      assignmentExport.scoreDisplays,
      assignmentExport.classCodeDisplay,
      assignmentExport.fileName,
      assignmentExport.showMetadata,
      assignmentExport.showScore,
    );
  }

  get workToPDFWorker() {
    if (!this._workToPDFWorker) {
      this._workToPDFWorker = this.$window.operative(
        function (
          questionDataURLs,
          studentDisplay,
          assignmentDisplay,
          scoreDisplays,
          classCodeDisplay,
          fileName,
          showMetadata,
          showScore,
        ) {
          let deferred = this.deferred();

          let doc = new PDFDocument();
          let stream = doc.pipe(blobStream());
          let isFirstQuestion = true;

          questionDataURLs.forEach((dataURL, index) => {
            if (isFirstQuestion) {
              isFirstQuestion = false;
            } else {
              doc.addPage();
            }

            if (showMetadata) {
              doc.image(dataURL, 200, 0, { scale: 0.39 }); // make room for metadata
              let scoreDisplay = scoreDisplays[index];
              let pageDisplay = `Page ${index + 1} / ${scoreDisplays.length}`;

              let display = doc
                .fontSize(10)
                .text(studentDisplay, 15, 20)
                .moveDown()
                .text(assignmentDisplay)
                .moveDown()
                .text(pageDisplay)
                .moveDown();

              if (scoreDisplay && showScore) {
                display = display.text(scoreDisplay).moveDown();
              }

              display.text(classCodeDisplay);

              doc.fontSize(8).text('Printed from Classkick', 15, 700);
            } else {
              doc.image(dataURL, 0, 0, { scale: 0.6 });
            }
          });

          doc.end();

          stream.on('finish', () => {
            let pdf = stream.toBlob('application/pdf');
            let file = new File([pdf], fileName);
            deferred.fulfill(file);
          });
        },
        [
          //need to get both of these files added to CDN - the operate package only takes in scripts as dependencies
          'https://assets.classkick.com/js/pdfkit.js',
          'https://assets.classkick.com/js/blob-stream-v0.1.3.js',
        ],
      );
    }
    return this._workToPDFWorker;
  }

  /**
   * @param files {File}
   * @return {Promise.<Blob>}
   */
  toZip(files) {
    let zip = new JSZip();

    files.forEach((file) => {
      zip.file(file.name, file, { base64: true });
    });

    return zip.generateAsync({ type: 'blob' });
  }
}
