'use strict';

import HelpRequestIndicatorDirectiveTemplate from './help-request-indicator.html';

export default function HelpRequestIndicatorDirective() {
  return {
    restrict: 'E',
    scope: {
      helpRequests: '=',
      showHand: '<',
      version: '=',
    },
    template: HelpRequestIndicatorDirectiveTemplate,
    link: HelpRequestIndicatorDirectiveController.link,
    controller: HelpRequestIndicatorDirectiveController,
    controllerAs: 'ctrl',
  };
}

export class HelpRequestIndicatorDirectiveController {
  constructor($scope, $timeout) {
    'ngInject';

    this.$timeout = $timeout;

    /** @type {HelpRequestSet} */
    this._helpRequestView = null;
    this._showHand = false;

    $scope.$on('$destroy', () => this._destroy());
  }

  static link(scope) {
    /** @type {GradeInputDirectiveController} */
    const ctrl = scope.ctrl;

    scope.$watch('helpRequests', (value) => {
      ctrl.helpRequestView = value;
    });

    scope.$watch('showHand', (value) => {
      ctrl.showHand = value;
    });

    scope.$watch('version', (value) => {
      if (value) {
        ctrl.version = value;
      }
    });
  }

  get showHand() {
    return this._showHand;
  }

  set showHand(value) {
    this._showHand = value;
  }

  /**
   * @returns {HelpRequestSet}
   */
  get helpRequestView() {
    return this._helpRequestView;
  }

  /**
   * @param value {HelpRequestSet}
   */
  set helpRequestView(value) {
    if (this._helpRequestView) {
      this._helpRequestView.created.unsubscribe(this._onUpdate, this);
      this._helpRequestView.resolved.unsubscribe(this._onUpdate, this);
      this._helpRequestView.canceled.unsubscribe(this._onUpdate, this);
    }

    this._helpRequestView = value;

    if (this._helpRequestView) {
      this._helpRequestView.created.subscribe(this._onUpdate, this);
      this._helpRequestView.resolved.subscribe(this._onUpdate, this);
      this._helpRequestView.canceled.subscribe(this._onUpdate, this);
    }
  }

  _onUpdate() {
    this.$timeout(() => {});
  }

  get count() {
    if (this._helpRequestView) {
      return this._helpRequestView.total;
    } else {
      return '-';
    }
  }

  _destroy() {
    this.helpRequestSet = null;
  }
}
