'use strict';

import ConfirmDialogTemplate from './confirm-dialog.html';

/**
 * Dialog to confirm an action
 */
export default class ConfirmDialogController {
  /**
   * @param $mdDialog {$mdDialog}
   * @param message {string}
   * @param message {string}
   * @param secondaryMessage {string}
   * @param isAlert {string}
   */
  constructor($mdDialog, message, secondaryMessage, isAlert, isDeleteAccount) {
    'ngInject';
    this.message = message;
    this.secondaryMessage = secondaryMessage;
    this.isAlert = isAlert;
    this.$mdDialog = $mdDialog;
    this.isDeleteAccount = isDeleteAccount;
  }

  /**
   * Shows a confirm dialog with the provided message
   *
   * @param $mdDialog
   * @param message {string}
   * @param secondaryMessage {string}
   * @param isAlert {boolean}
   * @param fullscreen {boolean}
   * @returns {*|Promise|void}
   */
  static show(
    $mdDialog,
    message,
    secondaryMessage,
    isAlert = false,
    fullscreen = false,
    isDeleteAccount = false,
  ) {
    let config = {
      clickOutsideToClose: true,
      escapeToClose: true,
      template: ConfirmDialogTemplate,
      controller: ConfirmDialogController,
      controllerAs: 'ctrl',
      locals: {
        message,
        secondaryMessage,
        isAlert,
        isDeleteAccount,
      },
      fullscreen,
    };

    return $mdDialog.show(config);
  }

  confirm() {
    this.$mdDialog.hide();
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
