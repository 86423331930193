'use strict';

import moment from 'moment';
export class MessageActions {
  static get TYPE() {
    return 'type';
  }

  static get ACCEPT_ASSIGNMENT_SHARE() {
    return 'accept_assignment_share';
  }

  static get ASSIGNMENT_ID() {
    return 'assignment_id';
  }
}

export default class Message {
  /**
   * @param id {string}
   * @param senderId {string}
   * @param recipientId {string}
   * @param text {string}
   * @param actions {*[]}
   * @param createdDate {string}
   * @param seenDate {string}
   * @param deletedDate {string}
   */
  constructor(
    id,
    senderId,
    recipientId,
    text,
    actions,
    createdDate,
    seenDate,
    deletedDate,
  ) {
    this._id = id;
    this._senderId = senderId;
    this._recipientId = recipientId;
    this._text = text;
    this._actions = actions;
    this._createdDate = createdDate ? moment(createdDate) : undefined;
    this._seenDate = seenDate ? moment(seenDate) : undefined;
    this._deletedDate = deletedDate ? moment(deletedDate) : undefined;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get senderId() {
    return this._senderId;
  }

  /**
   * @returns {string}
   */
  get recipientId() {
    return this._recipientId;
  }

  /**
   * @returns {string}
   */
  get text() {
    return this._text;
  }

  /**
   * @returns {*[]}
   */
  get actions() {
    return this._actions;
  }

  /**
   * @returns {moment}
   */
  get createdDate() {
    return this._createdDate;
  }

  /**
   * @returns {moment}
   */
  get seenDate() {
    return this._seenDate;
  }

  /**
   * @param value {moment}
   */
  set seenDate(value) {
    this._seenDate = value;
  }

  /**
   * @returns {moment}
   */
  get deletedDate() {
    return this._deletedDate;
  }

  /**
   * @returns {moment}
   */
  get createdDateFromNow() {
    return this.createdDate.fromNow();
  }

  /**
   * Returns a new message given an object
   *
   * @param obj
   * @returns {Message} or null
   */
  static apply(obj, recipientId) {
    if (obj) {
      return new Message(
        obj.message_id,
        obj.sender_id,
        recipientId,
        obj.text,
        obj.actions,
        obj.created,
        undefined,
        undefined,
      );
    }
    return null;
  }
}
