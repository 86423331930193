export default class CkRedirect {
  constructor(to, params, url) {
    this.to = to;
    this.params = params;
    this.url = url;
  }

  /**
   * @param breadcrumbService {BreadcrumbService}
   */
  redirect(breadcrumbService) {
    if (this.url) {
      breadcrumbService.goUrl(this.url, true);
    } else {
      breadcrumbService.go(this.to, this.params, true);
    }
  }
}
