import { Colors } from '../../model/domain/colors';
import { Notifications } from './toolbar.service';
import ImportImageDialogController from '../../components/import-image-dialog/import-image-dialog.controller';
import LazyVar from '../../model/util/lazy-var';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import UserSlideBackground, {
  UserSlideBackgroundType,
} from '../../model/domain/user-slide-background';
import MimeTypes from '../../model/domain/mime-types';

export class SlideBackgroundManager {
  constructor(
    $mdDialog,
    $q,
    $document,
    SlideBackgroundService,
    ToolbarService,
    MediaService,
    AuthService,
    ImageEditService,
  ) {
    this.$mdDialog = $mdDialog;
    this.$q = $q;
    this.$document = $document;

    /** @type {SlideBackgroundService} */
    this._slideBackgroundService = SlideBackgroundService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {MediaService} */
    this._mediaService = MediaService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;

    this._importImageDialog = ImportImageDialogController.show;
    this._loadingDialog = LoadingDialogController.show;

    this._backgrounds = new LazyVar();
    this._backgroundItems = [];
  }

  init() {
    this.getBackgroundUrls().then((backgroundItems) => {
      this._backgroundItems = backgroundItems;
    });
  }

  getBackgroundUrls() {
    return this._backgrounds.value(() => {
      return this.$q
        .all({
          stockUrls: this._slideBackgroundService.getStockUrls(),
          customUrls: this._slideBackgroundService.getCustomUrls(),
        })
        .then(({ stockUrls, customUrls }) => {
          return [...customUrls, ...stockUrls];
        });
    });
  }

  /**
   * @return {UserSlideBackground[]}
   */
  get backgroundItems() {
    return this._backgroundItems;
  }

  removeBackground() {
    this._toolbarService.notify(
      Notifications.UPDATE_SLIDE_BACKGROUND,
      {},
      false,
    );
  }

  selectColor(color) {
    this._toolbarService.notify(
      Notifications.UPDATE_SLIDE_BACKGROUND,
      {
        hex: color.hex,
      },
      false,
    );
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  borderColor(color) {
    return Colors.borderColor(color);
  }

  addCustomBackground() {
    this._importImageDialog(
      this.$q,
      this.$mdDialog,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      MimeTypes.BackgroundImageSupport,
    )
      .then(({ images }) => {
        const image = images && images[0];
        const promise = this._addBackgroundImage(image);
        this._loadingDialog(
          this.$mdDialog,
          promise,
          this.$document,
          '.slide-background',
        );
        return promise;
      })
      .then(() => {
        this._backgrounds.clear();
        this.init();
      });
  }

  _addBackgroundImage(image) {
    const blob = this._imageEditService.dataURItoBlob(image.imageSrc);
    return this._mediaService
      .create(blob, 'content', this._authService.currentUserId)
      .then((result) => {
        let background = new UserSlideBackground(
          result.newElementId,
          UserSlideBackgroundType.Custom,
          result.mediaLink,
        );
        this.selectCustomBackgroundItem(background);
        return this._slideBackgroundService.setCustomUrl(background);
      });
  }

  /**
   * @param item {UserSlideBackground}
   */
  selectCustomBackgroundItem(item) {
    this._toolbarService.notify(
      Notifications.UPDATE_SLIDE_BACKGROUND,
      { url: item.url },
      false,
    );
  }

  /**
   * @param item {UserSlideBackground}
   * @param event
   */
  delete(item, event) {
    event.stopPropagation();

    this._slideBackgroundService.deleteCustomUrl(item.id);
    this._backgrounds.clear();
    this.init();
  }
}
