'use strict';

import Codec from './codec';
import PointCodec from './point-codec';
import SizeCodec from './size-codec';
import Textbox from '../ui/elements/textbox';
import ElementCodec from './element-codec';
import TextColorArrayCodec from './text-color-array-codec';
import Size from '../ui/size';

export default class TextboxCodec extends Codec {
  constructor() {
    super();
    this._pointCodec = new PointCodec();
    this._sizeCodec = new SizeCodec();
    this._colorsCodec = new TextColorArrayCodec();
    this._sizeDiff = TextboxCodec.sizeDiff;
  }

  /**
   * @returns {Size} The difference in effective Textbox size between iOS and web
   */
  static get sizeDiff() {
    return new Size(40, 48);
  }

  /**
   * @param value {Textbox}
   * @returns {object}
   */
  encode(value) {
    // TODO: Textbox sizes in iOS currently have a 45 px buffer on the right and bottom for tool buttons
    let hackSize = value.size.plus(this._sizeDiff);

    let data = {
      type: value.type,
      text: value.text,
      size: this._sizeCodec.encode(hackSize),
      center: this._pointCodec.encode(
        ElementCodec.centerFromLocationAndSize(value.location, hackSize),
      ),
      fontSize: value.fontSize,
      colors: this._colorsCodec.encode(value.colorSpans),
      backgroundColor: value.backgroundColor,
      borderColor: value.borderColor,
      fontFamily: value.fontFamily,
    };
    return ElementCodec.firebaseElement(data, value.metadata);
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {Textbox}
   */
  decode(value, key) {
    let metadata = ElementCodec.extractMetadata(value);
    let size = this._sizeCodec.decode(value.data.size);
    let center = this._pointCodec.decode(value.data.center);
    let location = ElementCodec.locationFromCenterAndSize(center, size);

    return new Textbox(
      key,
      metadata,
      value.data.text,
      location,
      size.minus(this._sizeDiff),
      value.data.fontSize,
      undefined,
      this._colorsCodec.decode(value.data.colors),
      undefined,
      value.data.backgroundColor,
      value.data.borderColor,
      value.data.fontFamily,
    );
  }
}
