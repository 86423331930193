'use strict';

export default class AssignmentRoster {
  constructor(
    assignmentId,
    rosterId,
    showStudentScores,
    lockStudentWork,
    hideStudentWork,
  ) {
    this._assignmentId = assignmentId;
    this._rosterId = rosterId;
    this._showStudentScores = showStudentScores;
    this._lockStudentWork = lockStudentWork;
    this._hideStudentWork = hideStudentWork;
  }

  /**
   * @returns {string}
   */
  get rosterId() {
    return this._rosterId;
  }

  /**
   * @returns {string|undefined}
   */
  get assignmentId() {
    return this._assignmentId;
  }

  /**
   * @return {boolean}
   */
  get showStudentScores() {
    return this._showStudentScores;
  }

  /**
   * @param value {boolean}
   */
  set showStudentScores(value) {
    this._showStudentScores = value;
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    return this._lockStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set lockStudentWork(value) {
    this._lockStudentWork = value;
  }

  /**
   * @return {boolean}
   */
  get hideStudentWork() {
    return this._hideStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set hideStudentWork(value) {
    this._hideStudentWork = value;
  }
}
