'use strict';
import Codec from './codec';
import Class from '../domain/class';
/**
 * Encodes/decodes Gradebook class information
 */
export default class ClassCodec extends Codec {
  /**
   * @param value {Class}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        course_id: value.courseId ? value.courseId : null,
        name: value.name,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {Class}
   */
  decode(value) {
    if (value) {
      return new Class(
        value.id,
        value.course_id ? value.course_id : null,
        value.name,
      );
    }

    return null;
  }
}
