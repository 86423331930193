'use strict';

import CdnUtils from '../../util/cdn-utils';
import Element from './element';
import Point from '../point';
import Size from '../size';
import TextboxArranger from './textbox-arranger';
import { ColorSpan } from './textbox-color-span-arranger';
import RemoveHandle from '../remove-handle';
import GestureManager from '../gesture-manager';
import HexColors from '../../../css-constants';
import { StickerMap } from '../../domain/user-sticker';
import StaticService from '../../../services/static/static.service';
import ScoreUtil from '../../util/score-utils';
import { DefaultPlacement } from '../placement';
import { UserRoles } from '../../domain/user';

class StickerPlacement extends DefaultPlacement {
  get cursor() {
    return 'pointer';
  }

  create() {
    // do nothing when placing a sticker
  }

  update() {
    // do nothing when placing a sticker
  }

  remove() {
    // do nothing when placing a sticker
  }
}

export default class Sticker extends Element {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param location {Point}
   * @param size {Size}
   * @param text {string}
   * @param url {string}
   * @param score {number}
   * @param viewedByStudent {boolean}
   */
  constructor(id, metadata, location, size, text, url, score, viewedByStudent) {
    super(id, Sticker.type, metadata);

    const transformedUrl = CdnUtils.urlTransform(url);

    this._location = location || new Point(100, 200);
    this._size = size || new Size(100, 130);
    this._url = transformedUrl || '';
    this._preloadUrl = this.preloadUrl;
    this._text = text || '';
    this._score = score || 0;
    this._viewedByStudent = viewedByStudent || false;

    this._textboxArranger = new TextboxArranger();
    this.loaded = this._preload();
    this._placement = new StickerPlacement();
  }

  /**
   * @returns {string}
   */
  static get type() {
    return 'sticker';
  }

  /**
   * @return {Point}
   */
  static get InitialStickerPosition() {
    return new Point(800, 50);
  }

  /**
   * @param image {Image}
   * @return {Size}
   */
  static createSizeFromImage(image) {
    let size = new Size(image.naturalWidth, image.naturalHeight);

    if (size.width >= Sticker.MaxWidth) {
      size = new Size(
        Sticker.MaxWidth,
        (size.height / size.width) * Sticker.MaxWidth,
      );
    }

    return size;
  }

  /**
   * @returns {string}
   */
  get url() {
    return this._url;
  }

  /**
   * @returns {string}
   */
  get mappedUrl() {
    return StickerMap[this.url] || this.url;
  }

  /**
   * @returns {string}
   */
  get text() {
    return this._text;
  }

  /**
   * This is the max width of text on iOS before it wraps to a new line
   * @return {number}
   * @constructor
   */
  static get MaxWidth() {
    return 210;
  }

  /**
   * @returns {number}
   */
  get score() {
    return ScoreUtil.formatScore(this._score);
  }

  /**
   * @return {string}
   */
  get displayText() {
    return this.hideScore ? this.text : `${this.text} +${this.score}`;
  }

  /**
   * @return {boolean}
   */
  get hideScore() {
    return this.score === 0 || (this.isStudent && !this.showStudentScores);
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this.viewerRole && this.viewerRole === UserRoles.STUDENT;
  }

  /**
   * @return {undefined|ClassCode}
   */
  get classCode() {
    return this.assignment && this.assignment.classCode;
  }

  /**
   * @return {undefined|boolean}
   */
  get showStudentScores() {
    return this.classCode && this.classCode.showStudentScores;
  }

  /**
   * @returns {boolean}
   */
  get viewedByStudent() {
    return this._viewedByStudent;
  }

  createElement(root, editable) {
    this._root = root;
    this._base = root.group();
    this._interactive = root.group();

    this._textboxArranger.invalidateDom();

    this._background = this._base.rect(0, 0, 0, 0);
    this._image = this._base.image(this.mappedUrl, 0, 0, 0, 0);
    this._textbox = this._base.text(0, 0, '');

    this._touch = this._interactive.rect(0, 0, 0, 0);

    if (editable) {
      this._removeHandle = new RemoveHandle(this);
      this._removeHandle.render(this._interactive);
      this._removeHandle.mouseEnter.subscribe(this.hoverIn, this);
      this._removeHandle.mouseLeave.subscribe(this.hoverOut, this);
      this._removeHandle.visibility = 'hidden';

      this._drag = new GestureManager(this, this.canvas);
      this._drag.start(this._touch.node);
      this._drag.click.subscribe(this.focus, this);
      this._drag.dragStart.subscribe(this._repositionStart, this);
      this._drag.dragMove.subscribe(this._repositionMove, this);
      this._drag.dragEnd.subscribe(this._repositionEnd, this);
      this._drag.mouseEnter.subscribe(this.hoverIn, this);
      this._drag.mouseLeave.subscribe(this.hoverOut, this);
    }

    if (this.classCode && this.classCode.showStudentScoresUpdated) {
      this.classCode.showStudentScoresUpdated.subscribe(
        this._showStudentScoresUpdated,
        this,
      );
    }
  }

  update(root, editable) {
    let imageWidth = Math.min(this.width, Sticker.MaxWidth);
    let imageHeight = (this.height / this.width) * imageWidth;

    if (!this.mappedUrl) {
      // These are the values that seem to align sticker text on iOS and web when no image is present
      imageWidth = 98;
      imageHeight = 80;
    }

    this._image.attr({
      x: this.location.x,
      y: this.location.y,
      width: imageWidth,
      height: imageHeight,
      preserveAspectRatio: 'xMidYMin meet',
    });

    let textLocation = new Point(
      this.location.x + imageWidth / 2,
      this.location.y + imageHeight,
    );

    this._textbox.attr({
      x: textLocation.x,
      y: textLocation.y,
      fontFamily: 'GothamRoundedMedium',
      fontSize: '18px',
      dominantBaseline: 'middle',
      textAnchor: 'middle',
      pointerEvents: 'none',
    });

    this._textboxArranger.renderText(
      textLocation,
      this._textbox,
      this.displayText,
      Sticker.MaxWidth,
      [new ColorSpan(0, null, HexColors.CK_HEADER_GREY)],
    );

    const textBounds = this._textbox.getBBox();

    const boundingSize = new Size(
      Math.max(imageWidth, textBounds.w + 20),
      imageHeight + textBounds.h + 10,
    );

    let touchLocation = new Point(
      this.location.x + imageWidth / 2 - boundingSize.width / 2,
      this.location.y,
    );

    this._touch.attr({
      x: touchLocation.x,
      y: touchLocation.y,
      width: boundingSize.width,
      height: boundingSize.height,
      stroke: 'transparent',
      strokeWidth: '2px',
      fill: 'transparent',
    });

    this._background.attr({
      x: touchLocation.x,
      y: touchLocation.y + imageHeight,
      width: boundingSize.width,
      height: textBounds.height + 10,
      rx: 4,
      fill: 'rgba(255, 255, 255, 0.85)',
    });

    if (editable) {
      this._touch.attr({
        stroke:
          this.hovering || this.hasFocus ? HexColors.CK_GREEN : 'transparent',
      });
      this._removeHandle.location = touchLocation.plus(
        new Point(boundingSize.width, 0),
      );
      this._removeHandle.visibility =
        this.hovering || this.hasFocus ? 'visible' : 'hidden';
    }
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {Sticker}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;
    this._text = other._text || this._text;
    this._url = other._url || this._url;
    this._score = other._score || this._score;
    this._viewedByStudent = other._viewedByStudent || this._viewedByStudent;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _text: this._text,
      _url: this._url,
      _score: this._score,
      _viewedByStudent: this._viewedByStudent,
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {Sticker}
   */
  fromSnapshot(id, snapshot) {
    return new Sticker(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._text,
      snapshot._url,
      snapshot._score,
      snapshot._viewedByStudent,
    );
  }

  hoverIn() {
    this.tryUpdate();
  }

  hoverOut() {
    this.tryUpdate();
  }

  _repositionStart() {
    this._dragStartState = this.snapshot();
  }

  _repositionMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _repositionEnd() {
    this._onChanged(this._dragStartState);
  }

  get hovering() {
    return this._drag.hovering || this._removeHandle.hovering;
  }

  _showStudentScoresUpdated() {
    // If there's not a score for this sticker, we don't need to update
    if (this._score) {
      this.tryUpdate();
    }
  }

  remove() {
    if (this.classCode) {
      this.classCode.showStudentScoresUpdated.unsubscribe(
        this._showStudentScoresUpdated,
        this,
      );
    }
    super.remove();
  }

  warnBeforeDeletion() {
    this._touch.attr({
      stroke: HexColors.CK_WARN,
    });
  }

  /**
   * Adds the current host as a query param to trick browser cache from
   * using cached response from different domain (e.g. if in app, then
   * prevents cached response from labs). Cached responses from different
   * domains were causing CORS issues and preventing stickers from being
   * placed on the canvas.
   *
   * @return {string}
   */
  get preloadUrl() {
    if (angular.isDefined(URL) && this.mappedUrl) {
      let url = new URL(this.mappedUrl);
      url.searchParams.set('ck_origin', StaticService.get.$location.host());
      return url.href;
    } else {
      return this.mappedUrl;
    }
  }

  /**
   * @returns {Promise.<Image>}
   * @private
   */
  _preload() {
    if (this._preloadUrl) {
      return StaticService.get.ImageEditService.loadImage(
        this._preloadUrl,
      ).then((result) => {
        let size = Sticker.createSizeFromImage(result.image);

        if (size.width !== this.width || size.height !== this.height) {
          this.size = size;
          this._onChanged(this.snapshot());
        }

        return result;
      });
    } else {
      return Promise.resolve();
    }
  }
}
