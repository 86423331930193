/* global LogRocket:false */
'use strict';

import LogRocket from 'logrocket';

export default class LogRocketService {
  /**
   * @ngInject
   */
  constructor($q, $log, constants, environment) {
    this.$q = $q;
    this.$log = $log;

    this._constants = constants;
    this._environment = environment;
    this._initialized = false;
    this._identifiedUserId = '';
  }

  init() {
    LogRocket.init('c9mpx9/classkick-web-production', {
      release: this._constants.version,
    });
  }

  /**
   * @param userId {string}
   * @param [name] {string}
   * @param [email] {string}
   * @param [username] {string}
   * @param [isPro] {boolean}
   * @param [override] {boolean}
   */
  identify(
    userId = '',
    name = '',
    email = '',
    username = '',
    isPro = false,
    override = false,
  ) {
    const isLogRocketEnvActive = this._environment.logRocket;

    if (isLogRocketEnvActive === 'true') {
      if (override || userId !== this._identifiedUserId) {
        this._identifiedUserId = userId;

        if (override || this.isSelectedUser(this.DefaultPercentage)) {
          if (!this._initialized) {
            this.init();
            this._initialized = true;
          }

          this.$log.info(`sending to logRocket! : ${userId}`);
          LogRocket.identify(userId, {
            name: name,
            email: email,
            username: username,
            isPro,
          });
        }
      }
    }
  }

  /**
   * This method will associate an id with the currently active Log Rocket session. This allows us to search
   * for LogRocket sessions by mixpanel events
   * @param id {string}
   */
  track(id) {
    LogRocket.track(id);
  }

  /**
   * @return {boolean}
   */
  get initialized() {
    return this._initialized;
  }

  /**
   * @return {boolean}
   */
  isSelectedUser(percentage) {
    let randomNumberBetween1And100 = Math.floor(Math.random() * 100);
    return randomNumberBetween1And100 < percentage;
  }

  get DefaultPercentage() {
    return 0.18;
  }

  /**
   * @param error {Error}
   */
  handleError(error) {
    if (this._initialized) {
      LogRocket.captureException(error);
    }
  }
}
