'use strict';

import Snap from 'snapsvg-cjs';
import Size from '../../model/ui/size';
import ImageCropper from '../../model/ui/elements/image-cropper';
import Point from '../../model/ui/point';
import Debouncer from '../../model/util/debouncer';
import ImageCropperDirectiveTemplate from './image-cropper.html';

/**
 * Canvas manager to handle cropping
 */
export default function ImageCropperDirective() {
  return {
    restrict: 'E',
    // The output is specified via an `ng-model` attribute. Format is {CropResult}
    require: 'ngModel',
    scope: {
      // Defines the source image -- expects an {Image} object
      source: '=',
    },
    template: ImageCropperDirectiveTemplate,
    link: ImageCropperDirectiveController.link,
    controller: ImageCropperDirectiveController,
    controllerAs: 'ctrl',
  };
}

export class ImageCropperDirectiveController {
  constructor($window, $scope, $timeout, ImageEditService) {
    'ngInject';

    this.$window = $window;
    this.$timeout = $timeout;
    this.$scope = $scope;

    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;

    /** @type {SVGElement} */
    this._svg = undefined;
    /** @type {Snap} */
    this._snap = undefined;
    this._imageContainer = undefined;
    this._cropContainer = undefined;

    this._ngModel = undefined;

    /** @type {Size} */
    this._size = undefined;
    this._padding = new Size(8, 0);
    /** @type {Size} */
    this._maxImageSize = undefined;
    /** @type {Size} */
    this._imageSize = undefined;
    /** @type {ImageView} */
    this._image = undefined;
    /** @type {ImageCropper} */
    this._crop = undefined;

    this.$scope.$on('$destroy', () => this._destroy());

    this._resizeDebouncer = new Debouncer(50, 750, () => {
      this.resize();
    });

    this._anonTickResize = () => this._resizeDebouncer.tick();

    angular.element(this.$window).on('resize', this._anonTickResize);
  }

  static link(scope, element, attrs, ngModel) {
    /** @type {ImageCropperDirectiveController} */
    const ctrl = scope.ctrl;

    ctrl.svg = element.find('svg');
    ctrl.ngModel = ngModel;

    scope.$watch('source', (value) => {
      ctrl.source = value;
    });
  }

  /**
   * @param value {SVGElement}
   */
  set svg(value) {
    this._svg = value;
    this._snap = new Snap(value[0]);
    this._imageContainer = this._snap.select('.image');
    this._cropContainer = this._snap.select('.crop');
  }

  /**
   * @param value {NgModelController}
   */
  set ngModel(value) {
    this._ngModel = value;
    this._ngModel.$render = () => {
      if (this._crop) {
        this._crop.setSelection(this._ngModel.$viewValue);
      }
    };
  }

  /**
   * @param value {Image}
   */
  set ngModelValue(value) {
    this._ngModel.$setViewValue(value);
  }

  /**
   * @param value {Image}
   */
  set source(value) {
    this._source = value;

    this.waitForLayoutComplete().then(() => this.render());
  }

  /**
   * Delays enough time so that things are finished laying out in the browser?
   * @returns {Promise.<TResult>}
   */
  waitForLayoutComplete() {
    return this.$timeout(() => {}, 10, false)
      .then(() => this.$timeout(() => {}, 10, false))
      .then(() => this.$timeout(() => {}, 10, false))
      .then(() => this.$timeout(() => {}, 10, false))
      .then(() => this.$timeout(() => {}, 10));
  }

  render() {
    this.resize();
    this._maxImageSize = this._size.minus(this._padding.times(2));

    this._cleanElements();

    if (this._source) {
      this._imageEditService.imageLoaded(this._source).then((image) => {
        this._imageSize = new Size(image.naturalWidth, image.naturalHeight);

        this._crop = new ImageCropper(
          this._size,
          image.src,
          this._imageSize,
          this._padding,
        );

        this._crop.render(this._cropContainer, true, true, {
          absolutePosition: this._offset,
          inverseScaleFactor: 1,
        });

        if (this._ngModel.$viewValue) {
          this._crop.setSelection(this._ngModel.$viewValue);
        }

        this._crop.previewChanged.subscribe(this._onCropChanged, this);
        this._onCropChanged(this._crop);
      });
    }
  }

  resize() {
    if (this._svg) {
      this._size = new Size(this._svg.width(), this._svg.height());
      this._offset = Point.fromOffset(this._svg.offset());

      if (this._crop) {
        this._crop.resize(this._size, this._offset);
      }
    }
  }

  /**
   * @param src {ImageCropper}
   * @private
   */
  _onCropChanged(src) {
    this.ngModelValue = src.crop();
  }

  _destroy() {
    angular.element(this.$window).off('resize', this._anonTickResize);
    this._cleanElements();
  }

  _cleanElements() {
    if (this._crop) {
      this._crop.previewChanged.unsubscribe(this._onCropChanged, this);
      this._crop.remove();
      this._crop.cleanUp();
      this._crop = null;
    }
  }
}
